<div class="min-w-screen min-h-screen py-12 bg-primary flex items-center justify-center">
    <app-backgrounds class="opacity-25"></app-backgrounds>
    <div class="mx-auto container">
        <img class="w-auto mx-auto" src="assets/images/metswift-horz-WHT@0.5x.png" srcset="assets/images/metswift-horz-WHT@0.5x.png 1x, assets/images/metswift-horz-WHT@0.5x.png 2x">
        <div class="auth-wrapper w-full mx-auto mt-5 xl:mt-8 max-w-lg xl:max-w-xl rounded-lg relative bg-white p-6 xl:p-8">
            <form class="form-signin" #form="ngForm" (ngSubmit)="onSubmit(form.value)">
                <h3 class="font-semibold text-lg text-primarylighter text-center mb-4">Forgot password</h3>
                <p class="text-md text-primarylighter text-center mb-4">Please enter your email id below.</p>
                <a class="absolute backtop-2 backright-2 text-md text-primarylighter px-3" routerLink="/login">Back to login</a>
                <div class="mt-1 mb-2 font-semibold text-sm text-orange-dark">
                    <p class="invalid-credential" [hidden]="!_forgotError"> {{ _errorMsg }}</p>
                    
                    <!-- <p *ngIf="errorMessage && signinForm.valid && signinformsubmit">{{errorMessage}}</p> -->
                </div>
                <div class="mt-1 mb-2 font-semibold text-sm text-green-dark">
                    <p class="success-msg" [hidden]="!_success"> {{ _successMsg }}</p>
                </div>
                <div class="mb-4 w-full">
                    <label for="username" class="mb-1 font-semibold text-gray-700 text-xs block cursor-pointer">Email <sup>*</sup></label>
                    <input type="email" name="email" (keyup)="trimEmail(email.value)" class="w-full block bg-gray-200 rounded px-4 py-2 cursor-pointer focus:bg-white border border-gray-200 font-sans tracking-wide focus:border-gray-300" id="email"
                      [(ngModel)]="model.email" #email="ngModel" email required maxlength="50" placeholder="Email ID"
                      pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])">
                    <!-- <input (keyup)="errorMessage=null" type="text" maxlength="50" class="w-full block bg-gray-200 rounded px-4 py-2 cursor-pointer focus:bg-white border border-gray-200 font-sans tracking-wide focus:border-gray-300" id="username" formControlName="username" placeholder="Enter username" required> -->
                    <div class="mt-1 mb-2 font-semibold text-sm text-orange-dark" *ngIf="form.submitted && email.invalid">
                        <p *ngIf="email.invalid && !_success">
                          Please enter a valid email id.
                        </p>
                        <p *ngIf="email.errors.maxlength">
                          Email can be at most 50 characters.
                        </p>
                      </div>
                </div>
                
                <button type="submit" class="btn-gradient block mt-6 w-full px-4 py-3 hover:opacity-75">Submit</button>

                <!-- <div class="element-response w-full text-sm mt-4 text-center"><strong class="font-semibold">Success!</strong>  Text response here</div> -->
            </form>
        </div>
        <div class="w-full mx-auto mt-5 xl:mt-8 max-w-lg xl:max-w-xl">
            <ul class="flex flex-wrap justify-center">
                <li class="mx-3">
                    <a href="http://metswift.com/" target="_blank" rel="noopener" class="text-white font-light border-b border-gray-700 hover:border-white text-sm sm:text-base">Main Website</a>
                </li>
                <li class="mx-3">
                    <a href="http://metswift.com/contact" target="_blank" rel="noopener" class="text-white font-light border-b border-gray-700 hover:border-white text-sm sm:text-base">Contact us</a>
                </li>
            </ul>
        </div>
    </div>
</div>
