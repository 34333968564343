import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-results-download-report-pdf',
  templateUrl: './results-download-report-pdf.component.html',
  styleUrls: ['./results-download-report-pdf.component.scss']
})
export class ResultsDownloadReportPdfComponent implements OnInit {

  constructor() { }

  @Output() downloadcombinedpdfaszip = new EventEmitter<string>();
  @Input() modelname:any;
  @Input() hourlytype:any;
  ngOnInit(): void {
  }
  downloadpdf(){
    this.downloadcombinedpdfaszip.emit();
  }
}
