<div (click)="ngxSmartModalService.getModal('searchReports').open()" class="button-search-reports cursor-pointer">
    <svg xmlns="http://www.w3.org/2000/svg" class="fill-current stroke-current w-3 h-3 lg:w-4 lg:h-4 lg:mr-3" viewBox="0 0 12 12"><line x1="11.5" y1="11.5" x2="8.328" y2="8.328" fill="none" stroke-linecap="round" stroke-linejoin="round"></line> <circle cx="5.5" cy="5.5" r="4" fill="none" stroke-linecap="round" stroke-linejoin="round"></circle></svg>
    <span class="hidden lg:block font-semibold text-sm relative overflow-hidden">Search reports</span>
</div>

<ngx-smart-modal [closable]="false" (onOpen)="elementFocus()" #searchReports identifier="searchReports">
    <div class="container max-w-xl mx-auto">
      <h3 class="mb-2 text-sm text-white font-medium md:text-xl text-center">
        Search your previous reports
        <svg  (click)="closeModal()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer inline-block float-right">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg> 
      </h3>     
      <form class="relative">
        <input
          #searchReportsInput
          type="text"
          name="search-reports"
          placeholder="Search your reports and press enter"
          class="block w-full py-3 px-6 pr-16 rounded-full border-0 bg-white focus:bg-white cursor-pointer placeholder-gray-500"
          required
        />
        <button type="submit" class="absolute button-search flex items-center" (click)="searchevent()">
            <svg xmlns="http://www.w3.org/2000/svg" class="fill-current text-white stroke-current w-3 h-3 lg:w-4 lg:h-4 mx-auto" viewBox="0 0 12 12"><line x1="11.5" y1="11.5" x2="8.328" y2="8.328" fill="none" stroke-linecap="round" stroke-linejoin="round"></line> <circle cx="5.5" cy="5.5" r="4" fill="none" stroke-linecap="round" stroke-linejoin="round"></circle></svg>
        </button>

      </form>
    </div>
</ngx-smart-modal>
