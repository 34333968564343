import { Component, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss']
})
export class ButtonToggleComponent {
  @Input() dataActive: boolean = false;
  @Input() dataDeactive: boolean = false;
  @Input() type: string;
  @Output() toggle: EventEmitter<any> = new EventEmitter();

  onClick() {
    this.toggle.emit(true);
  }

}
