import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENV } from './../app.config';

@Injectable()
export class EventService {
    constructor(
        private http: HttpClient
      ) { }

    fetchEvents(body:Object): Observable<any>{        
        Object.defineProperty(body, "uri", {value : '/stats_data/get_min_max_mean_daily_monthly',
                           writable : true,
                           enumerable : true,
                           configurable : true});
        body = JSON.stringify(body);
        return this.http.post<any>(ENV.WEATHER_API + '/stats_data/get_min_max_mean_daily_monthly', body);
    }

    fetchGroupEvents(body: Object): Observable<any> {
        body = JSON.stringify(body);
        return this.http.post<any>(ENV.BASE_API + '/api/insight/get_group_events', body);
    }

    public getEvents(): Observable<any> {
        const dateObj = new Date();
        const yearMonth = dateObj.getUTCFullYear() + '-' + (dateObj.getUTCMonth() + 1);
        
        let data: any = [{
            title: 'Min : 10',
            start: '2018-04-01',
            value:10
        },
        {
            title: 'Max : 20',
            start: '2018-04-01',
            value:20
        },
        {
            title: 'Mean : 15',
            start: yearMonth + '-01',
            value:15
        }
    ];
        return data;
        //return Observable.of(data);
    }

    getThresholdsOnly(body: Object): Observable<any> {
        return this.http.post<any>(ENV.BASE_API + '/api/insight/getthresholds', body);
    }
    updateEventName(body: Object): Observable<any> {
        return this.http.post(`${ENV.WEATHER_API}/stats_data/update_event_name`, body);
    }
    

      
}