<div class="lg:flex-grow h-full p-4 md:p-6 lg:p-12 relative overflow-hidden">
    <app-backgrounds></app-backgrounds>
    <div class="w-full h-full relative z-10">
      <div class="flex flex-wrap -mx-4 lg:-mx-6 justify-between mb-3 md:mb-5 lg:mb-8">
        <div class="px-4 lg:px-6 w-auto lg:w-1/2">
          <h1 class="font-semibold text-xl lg:text-2xl">{{ titleField }}</h1>
        </div>
      </div>
      <div class="w-full h-full mb-3 lg:mb-6">
        <div class="knowledge-base-iframe relative w-full h-full">
          <iframe src="https://v2-embednotion.com/67c44922343b428581d68928d4300b29" class="w-full h-full"></iframe>
        </div>
      </div>
    </div>
</div>

