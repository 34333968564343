import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnitConvertPipe } from "./../pipes/unit-convert.pipe";



@NgModule({
  declarations: [UnitConvertPipe],
  imports: [
    CommonModule
  ],
  exports: [UnitConvertPipe],
  providers: [UnitConvertPipe]
})
export class UnitConvertModule { }
