import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';
import { LogoutService } from 'src/app/services/logout.service';
import { Store } from '@ngrx/store';
import { AppState, selectAuthState } from 'src/app/store/app.states';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit  {
    getState: Observable<any>;
    titleField : string = 'Dashboard';
    title: string = 'Dashboard';
    unlimited:number = 1;
    
    constructor(
        private store: Store<AppState>,
        private titleService:Title,
        private LS:LogoutService,
        private loader:LoaderService,
        private router: Router,
    ) {
       this.getState = this.store.select(selectAuthState);
        this.titleService.setTitle('Dashboard - MetSwift');
    }

    ngOnInit() {
      this.getState.subscribe((state) => {
          this.unlimited = state.user.unlimited;
      });
        if (navigator.onLine == true) {
          //this.loader.display(true);
          this.LS.logOut({ title: "Dashboard", link: "#/dashboard" })
            .subscribe(
              data => {
              },
              error => {
                this.router.navigate(["/login"]);
                this.loader.display(false);
              }
            );
        } else {
          //this.connectionService.display(true);
          this.loader.display(false);
        }
        sessionStorage.clear();
    }


}
