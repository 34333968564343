import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { FormBuilder, FormGroup } from "@angular/forms";

import axios from 'axios';
import Litepicker from '../../../litepicker';
import * as moment from 'moment';
declare var google: any;
import { MapsAPILoader,  } from '@agm/core';
import { LoaderService } from '../../../services/loader.service';
@Component({
  selector: 'app-editreport-with-insights-widget',
  templateUrl: './editreport-with-insights-widget.component.html',
  styleUrls: ['./editreport-with-insights-widget.component.scss']
})
export class EditreportWidgetWithInsightsComponent implements OnInit, AfterViewInit {
  newReportWidget: FormGroup;
  startDate: string = 'qeqweqwe';
  placeholderStart: string = 'eqweqwedsfsf';
  placeholderEnd: string = 'we23323';
  endDate: string = 'werewrwe..';
  locationValue: string = '';
  locationResults: any = false;
  locationSelected: any = false;
  searchState: any = false;
  datepickerInput: any = false;
  nextcount = 0;
  public isLatLong:boolean=false;
  public isLatLongSearch:boolean=false;
  public searchedLatLong:any;
  @Input() prefillValues: any = null;
  @Input() isComplete: boolean = false;
  @Input() showSearchButton: boolean = false;
  @Input() resultsPage: boolean = false;
  @Output() changedDates: EventEmitter<any> = new EventEmitter();
  @Output() onComplete: EventEmitter<any> = new EventEmitter();
  @Output() onLocationChanged: EventEmitter<any> = new EventEmitter();
  @Output() onResetDate :EventEmitter<any> = new EventEmitter();
  @ViewChild('location', {static: false}) locationInput: ElementRef;
  @ViewChild('dateStart', {static: false}) dateStartInput: ElementRef;
  @ViewChild('dateEnd', {static: false}) dateEndInput: ElementRef;
  @ViewChild('containerDates', {static: false}) containerDates: ElementRef;

  constructor(
    private loader:LoaderService,
    public fb: FormBuilder,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
  ) {
    this.newReportWidget = this.fb.group({
      "location": ['asdasd'],
      "startDate": ['asdasd'],
      "endDate": ['asdasd'],
    })
  }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
      const app = this
      const form = this.newReportWidget
      let nextval = this.nextcount;
      //this.getGooglePlaces();
      if(!this.datepickerInput){

        // this.datepickerInput = new Litepicker({
        //     parentEl: this.containerDates.nativeElement,
        //     element: this.dateStartInput.nativeElement,
        //     elementEnd: this.dateEndInput.nativeElement,
        //     numberOfMonths: 2,
        //     numberOfColumns: 2,
        //     withInsights: true,
        //     allowRepick: true,
        //     minDate: app.isComplete && app.prefillValues ? moment(app.prefillValues.datestart, 'DD/MM/YYYY') : moment().add(7, 'day'),
        //     maxDate: app.isComplete && app.prefillValues ? moment(app.prefillValues.dateend, 'DD/MM/YYYY') : moment().add(5, 'years'),
        //     onSelect: function(start, end){
        //         const a = moment(end);
        //         const b = moment(start);

        //         form.controls.startDate.setValue(moment(start).format('DD/MM/YYYY'))
        //         form.controls.endDate.setValue(moment(end).format('DD/MM/YYYY'))
        //         app.changedDates.emit(a.diff(b, 'days') +1)
        //         let data: any = {
        //           datestart: form.controls.startDate.value,
        //           dateend: form.controls.endDate.value,
        //         }
        //         if(form.controls.location.value){
        //           data.location = app.locationSelected;
        //           data.place = app.locationValue;
        //         }
        //         if(nextval > 0){
        //           app.onComplete.emit(data)
        //         }
        //         nextval++;

        //     },
        //     onShow: () => { 
        //       if(!this.datepickerInput.getStartDate()){
        //         this.datepickerInput.gotoDate(moment().add(7, 'day'));
        //         nextval =0;  
        //       }
        //     },
        //     onHide: () =>{
        //       if(!this.datepickerInput.getStartDate()){
        //         app.onResetDate.emit();
        //         nextval =0;
        //       }
        //     }

        // })
      }
      console.log(this.prefillValues);
      //app.locationSelected = this.prefillValues.locationName
      form.controls.location.setValue(this.prefillValues.locationName)
      form.controls.startDate.setValue(this.prefillValues.startDate)
      form.controls.endDate.setValue(this.prefillValues.endDate)
      //this.datepickerInput.setDateRange(moment(this.prefillValues.datestart, 'DD/MM/YYYY'), moment(this.prefillValues.dateend, 'DD/MM/YYYY'))
  
  }
  onEditClick(){
    setTimeout(() => {
      if(!this.isComplete){
        this.locationInput.nativeElement.focus()
        this.locationInput.nativeElement.select()
      }
    })
  }

  getGooglePlaces() {
    let x = this;
    let recentplaceObj: any = {};
    let inputLoc = <HTMLInputElement>document.getElementById("inputLocation");
    let places;
    this.mapsAPILoader.load().then(() => {
    places = new google.maps.places.Autocomplete(this.locationInput.nativeElement, {});
      google.maps.event.addListener(places, 'place_changed', () => {
        // x.existPlace = false;
        this.resetEnquiryData();
        this.onResultsLocationClick(places.getPlace());
        // x.showDates = true;
        // if (x.place.geometry == undefined) {
        //   return;
        // }
        // for (let i = 0; i < x.place.address_components.length; i++) {
        //   if (x.place.address_components[i].types[0] == 'postal_code') {
        //     //x.postcode = x.place.address_components[i].long_name;
        //     //x.postCodeFlag = true;
        //   }
        //   if (x.place.address_components[i].types[0] == 'country') {
        //     //x.countryName = x.place.address_components[i].short_name;
        //     //x.countryLongName = x.place.address_components[i].long_name;
        //   }
        //   if (x.place.address_components[i].types[0] == 'administrative_area_level_1') {
        //     //x.state = x.place.address_components[i].long_name;
        //   }
        // }
        // if (!x.postCodeFlag) {
        //   let lati = x.place.geometry.location.lat();
        //   let longi = x.place.geometry.location.lng();
          //this.getCountryNameByLatLong(lati, longi, x);
          // this.preciseLocErr = true;
        //}
        // else
        //   this.setWeatherObj(x);
      });
    });
    //x.postCodeFlag = false;
  }


  changeLocation(value){
    this.resetEnquiryData();
    this.checkgooglepPlacesNetwork(value);
    // this.locationValue = value;
    // axios.get( `https://api.mapbox.com/geocoding/v5/mapbox.places/${this.locationValue}.json?access_token=pk.eyJ1IjoidXBtYWthbHJhIiwiYSI6ImNrcG52b3VyMzBmcTYydnA0enFqem1scjEifQ.A5K6Vw8H2SWbdueVz1Z-yw&autocomplete=true` )
    // .then( ( response ) => {
    //     if(response.data){
    //       this.locationResults = response.data
    //     }

    // } )
    // .catch( error => {
    //   // console.log(error)
    // });
  }

  callGooglePlaces(e) {
    if ( this.isLatLong == true || this.isLatLongSearch == true )
      return;
    //this.isSomethingChanged = true;
    //this.insightObj.place = e.target.value;
    //this.place = e.target.value;
    //this.insightView = false;
    //this.showCreateLast = false;
    //this.showUseSite = false;
    this.loader.display(true);
    //this.removeInsightMap();
    this.getGooglePlaces();
    setTimeout(() => {
      // this.getHurricaneName();
      // this.setCalenderDate();
      // this.checkIfThelocationHasPostcode();
      // this.callEndDate();
      this.loader.display(false);
    }, 1000);
  }

  checkgooglepPlacesNetwork(e) {
    // if (navigator.onLine == true) {
      // this.preciseLocErr = false;
      // this.grpDrop = false;
      // this.insightView = false;
      this.isLatLong = false;
      this.isLatLongSearch = false;
      this.locationValue = e.target.value;
      if ( location == undefined )
        return;
      if (this.locationValue.indexOf(",") > -1) {
        let latLongArr = this.locationValue.split(',');
        if( this.isLatitude(latLongArr[0].trim()) &&  this.isLongitude(latLongArr[1].trim()) ) {
          this.isLatLong = true;
          let code = (e.keyCode ? e.keyCode : e.which);
          if ( code == 13 )
            this.searchByLatLong(e);
        } else {
          this.isLatLong = false;
          this.isLatLongSearch = false;
        }
      } 
      //If location is filled then hide validation message
      // this.showDates = false;
      // this.maxStartDt = new Date();
      // this.maxEndDt = new Date();
      // this.reqLocErr = (this.insightObj.place == '') ? true : false;
      // if (this.reqLocErr)
      //   this.removeInsightMap();
    // } else {
    //   //this.connectionService.display(true);
    //   this.loader.display(false);
    //   document.getElementById("inputLocation").blur();
    // }

  }

  searchByLatLong(LatLongValue) {
    //if (navigator.onLine == true) {
      let x = this;
      x.isLatLongSearch = true;
      x.loader.display(true);
      x.isLatLong = false; // Setting the variable to the default value
      x.searchedLatLong = LatLongValue;
      if (LatLongValue.indexOf(",") > -1) {
        //this.preciseLocErr = false;
      } else {
        //this.preciseLocErr = true;
        x.loader.display(false);
        return;
      } 
      let LatLongArr = LatLongValue.split(',');
      // x.weatherObj.lat = parseFloat(LatLongArr[0].trim());
      // x.weatherObj.lon = parseFloat(LatLongArr[1].trim());
      let geocoder = new google.maps.Geocoder;
      let latlng = { lat:parseFloat(LatLongArr[0]), lng: parseFloat(LatLongArr[1]) };
      geocoder.geocode({ 'location': latlng }, function (results, status) {
        if (status === 'OK') {
          if (results.length > 0) {
            // x.removeInsightMap();
            //x.getGooglePlaces();
            x.loader.display(false);
            x.loader.display(false);
            for (let r = 0; r < results.length; r++) {
              for (let i = 0; i < results[r].address_components.length; i++) {
                let postCodeInd = results[r].address_components[i].types.indexOf('postal_code');
                let countryInd = results[r].address_components[i].types.indexOf('country');
                let stateInd = results[r].address_components[i].types.indexOf('administrative_area_level_1');

                // if (postCodeInd > -1) {
                //   x.showDates = true;
                //   x.postcode = results[r].address_components[i].long_name;
                //   x.postCodeFlag = true;
                //   x.place = results[0];
                // } else {
                //   x.showDates = true;
                //   x.postcode = 'xxxxxx';
                //   x.postCodeFlag = true;
                //   x.place = results[0];
                // }

                // if (countryInd > -1) {
                //   x.countryName = results[r].address_components[i].short_name;
                //   x.countryLongName = results[r].address_components[i].long_name;
                // }
                // if (stateInd > -1) {
                //   x.state = results[r].address_components[i].long_name;
                // }
                //x.insightObj.place = x.place.formatted_address;
                //x.preciseLocErr = false;
              }
            }
            x.resetEnquiryData();
            x.onResultsLocationClick(results[0]);
            
          } else {
            // x.preciseLocErr = true;
            // x.showDates = false;
            // return;
          }
        } else {
          // x.preciseLocErr = true;
          // x.showDates = false;
          x.loader.display(false);
          // return;
        }
      }); 
    // } else {
    //   //this.connectionService.display(true);
    //   this.loader.display(false);
    // }
  }

  isLatitude(lat) {
    return isFinite(lat) && Math.abs(lat) <= 90;
  }

  isLongitude(lng) {
    return isFinite(lng) && Math.abs(lng) <= 180;
  }

  closeResultsLocation() {
    setTimeout(() => {
      this.locationResults = []
    }, 300)
  }
  onResultsLocationClick(result){
    console.log(result);
    this.locationValue = this.locationInput.nativeElement.value;
    this.datepickerInput.show();
    if ( !this.isLatLongSearch )
        this.locationValue = result.formatted_address ? result.formatted_address : this.locationInput.nativeElement.value;
    else
        this.locationValue = this.searchedLatLong;
    this.newReportWidget.controls.location.setValue(this.locationValue);
    this.locationSelected = result
    let data: any = {
      location: this.locationSelected,
    }
    data.place = this.locationValue;
    if(this.newReportWidget.controls.startDate.value && this.newReportWidget.controls.endDate.value){
      const a = moment(this.newReportWidget.controls.endDate.value, 'DD/MM/YYYY')
      const b = moment(this.newReportWidget.controls.startDate.value, 'DD/MM/YYYY')
      this.changedDates.emit(a.diff(b, 'days') +1)
      data.datestart = this.newReportWidget.controls.startDate.value
      data.dateend = this.newReportWidget.controls.endDate.value
    }
    this.onComplete.emit(data)
  }
  onSubmit(){
    const values = this.newReportWidget.value
    if(!this.locationSelected && !this.isComplete){
      setTimeout(() => this.locationInput.nativeElement.focus())
      return false
    }
    setTimeout(() => this.datepickerInput.show());
    return false;
    // else if((!values.startDate || !values.endDate) && this.datepickerInput){
    //   setTimeout(() => this.datepickerInput.show())
    //   return false
    // }


    // this.router.navigate(['/new-report'], { state: { searchResults: {
    //   location: this.locationSelected,
    //   datestart: values.startDate,
    //   dateend: values.endDate,
    // } } })
  }

  checkIfLocationIsSelected(e) {
    if ( !this.locationSelected ) {
      this.datepickerInput.hide()
    }
  }

  resetEnquiryData() {
    this.datepickerInput.clearSelection();
    this.newReportWidget.controls.startDate.setValue('');
    this.newReportWidget.controls.endDate.setValue('');
    this.onLocationChanged.emit('Changed');
  }

}
