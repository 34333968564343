<div class="flex flex-wrap justify-between">
    <h3 class="uppercase text-sm text-primarylighter flex items-center font-bold">Latest news</h3>
    <a routerLink="/news" class="bg-primarylighter text-white flex items-center px-4 py-1 rounded-full text-xs font-bold">
        View all
        <svg xmlns="http://www.w3.org/2000/svg" class="w-2 h-2 fill-current stroke-current ml-5" viewBox="0 0 64 64"><polygon points="60 29 35 29 35 4 29 4 29 29 4 29 4 35 29 35 29 60 35 60 35 35 60 35 60 29"></polygon></svg>
    </a>
</div>
<div class="rounded-lg border boder-gray-100 p-4 mt-4 bg-white">
    <app-news-single-widget *ngFor="let data of news; let last = last;" [data]="data" [isLast]="last" [isInsideLatestNews]="true" ></app-news-single-widget>
</div>
