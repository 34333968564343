import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';

import { FormBuilder, FormGroup } from '@angular/forms';

import axios from 'axios';
import Litepicker from '../../../litepicker2';
import '../../../litepicker2/plugins/mobilefriendly';
import * as moment from 'moment';
import { LoaderService } from './../../../services/loader.service';
import { ConnectionService } from './../../../services/connection.service';
declare var google: any;
import { MapsAPILoader } from '@agm/core';


@Component({
  selector: 'app-newreport-widget',
  templateUrl: './newreport-widget.component.html',
  styleUrls: ['./newreport-widget.component.scss'],
})
export class NewreportWidgetComponent implements OnInit, AfterViewInit {
  public preciseLocErr: boolean = false;
  public grpDrop: boolean = false;
  public insightView = false;
  public isLatLong: boolean = false;
  public isLatLongSearch: boolean = false;
  public insightObj: any = {};
  public showDates: boolean = false;
  maxStartDt: Date;
  maxEndDt: Date;
  public reqLocErr: boolean = true;
  public searchedLatLong: any;
  public weatherObj: any = {};
  public postcode: any;
  public postCodeFlag: boolean = false;
  public place: any;
  public existPlace: boolean = false;
  public countryName: any;
  public state: any = '';
  public countryLongName: any = '';
  searchedLocation: any;



  newReportWidget: FormGroup;
  startDate: string = 'startDate';
  placeholderStart: string = 'Add start date';
  placeholderEnd: string = 'Add a end date';
  endDate: string = 'endDate';
  locationValue: string = '';
  locationResults: any = false;
  locationSelected: any = false;
  searchState: any = false;
  datepickerInput: any = false;
  @Input() prefillValues: any = null;
  @Input() isComplete: boolean = false;
  @Input() showSearchButton: boolean = false;
  @Input() resultsPage: boolean = false;
  @Output() changedDates: EventEmitter<any> = new EventEmitter();
  @Output() onComplete: EventEmitter<any> = new EventEmitter();

  @ViewChild('location', { static: false }) locationInput: ElementRef;
  @ViewChild('dateStart', { static: false }) dateStartInput: ElementRef;
  @ViewChild('dateEnd', { static: false }) dateEndInput: ElementRef;
  @ViewChild('containerDates', { static: false }) containerDates: ElementRef;

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private loader: LoaderService,
    private connectionService: ConnectionService,
    private mapsAPILoader: MapsAPILoader
  ) {
    this.newReportWidget = this.fb.group({
      "location": [''],
      "startDate": [''],
      "endDate": [''],
    })
  }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    const app = this
    const form = this.newReportWidget
    if (!this.datepickerInput) {

      this.datepickerInput = new Litepicker({
        parentEl: this.containerDates.nativeElement,
        element: this.dateStartInput.nativeElement,
        elementEnd: this.dateEndInput.nativeElement,
        autoRefresh: true,
        numberOfMonths: 2,
        numberOfColumns: 2,
        withInsights: false,
        allowRepick: true,
        minDate:
          app.isComplete && app.prefillValues
            ? moment(app.prefillValues.datestart, 'DD/MM/YYYY').toDate()
            : moment().add(7, 'day').toDate(),
        maxDate:
          app.isComplete && app.prefillValues
            ? moment(app.prefillValues.dateend, 'DD/MM/YYYY').toDate()
            : moment().add(5, 'years').toDate(),
        setup: (picker) => {
          picker.on('selected', (start, end) => {
            const a = moment(end);
            const b = moment(start);
            console.log(start, end);
            form.controls.startDate.setValue(start.getDate()+'/'+(start.getMonth()+1)+'/'+start.getFullYear());
            // form.controls.startDate.setValue('15/04/2022'
            //  moment(start,'DD/MM/YYYY')
            // );
            //form.controls.endDate.setValue(moment(end).format('DD/MM/YYYY'));
            form.controls.endDate.setValue(end.getDate()+'/'+(end.getMonth()+1)+'/'+end.getFullYear());
            app.changedDates.emit(a.diff(b, 'days') + 1);
            let data: any = {
              datestart: form.controls.startDate.value,
              dateend: form.controls.endDate.value,
            }
            if(form.controls.location.value){	
              data.location = app.locationSelected	
            }	
            app.onComplete.emit(data)
            console.log(data)
          });
          picker.on('show',(e)=>{
            console.log(e);
            if(!this.datepickerInput.getStartDate()){
                  this.datepickerInput.gotoDate(moment().add(7, 'day').toDate());
              }
          })
        },
        plugins: ['mobilefriendly'],
      });
    }
    if (this.prefillValues) {
      app.locationSelected = this.prefillValues.location;
      form.controls.location.setValue(this.prefillValues.location.place_name);
      form.controls.startDate.setValue(this.prefillValues.datestart);
      form.controls.endDate.setValue(this.prefillValues.dateend);
      this.datepickerInput.setDateRange(
        moment(this.prefillValues.datestart, 'DD/MM/YYYY'),
        moment(this.prefillValues.dateend, 'DD/MM/YYYY')
      );
    }
  }
  onEditClick() {
    setTimeout(() => {
      if (!this.isComplete) {
        this.locationInput.nativeElement.focus()
        this.locationInput.nativeElement.select()
      }
    })
  }

  // changeLocation(value: string){
  //   this.locationValue = value;
  //   axios.get( `https://api.mapbox.com/geocoding/v5/mapbox.places/${this.locationValue}.json?access_token=pk.eyJ1Ijoidml0YWxhZ2VuY3kiLCJhIjoiY2tkb3N0cmQ1MXV2ZjJ6cm9pdG1yZ3g5aiJ9.32PJ8demu-YJrkusdKfyHg&autocomplete=true` )
  //   .then( ( response ) => {
  //       if(response.data){
  //         this.locationResults = response.data
  //       }

  //   } )
  //   .catch( error => {
  //     // console.log(error)
  //   });
  // }

  closeResultsLocation() {
    setTimeout(() => {
      this.locationResults = [];
    }, 300);
  }

  // onResultsLocationClick(result){
  //   this.locationValue = result
  //   this.newReportWidget.controls.location.setValue(result.place_name);
  //   this.locationSelected = result
  //   let data: any = {
  //     location: this.locationSelected,
  //   }
  //   if(this.newReportWidget.controls.startDate.value && this.newReportWidget.controls.endDate.value){
  //     const a = moment(this.newReportWidget.controls.endDate.value, 'DD/MM/YYYY')
  //     const b = moment(this.newReportWidget.controls.startDate.value, 'DD/MM/YYYY')
  //     this.changedDates.emit(a.diff(b, 'days') +1)
  //     data.datestart = this.newReportWidget.controls.startDate.value
  //     data.dateend = this.newReportWidget.controls.endDate.value
  //   }
  //   this.onComplete.emit(data)
  // }

  callGooglePlaces(e) {
    if (this.isLatLong == true || this.isLatLongSearch == true)
      return;
    // this.isSomethingChanged = true;
    // this.insightObj.place = e.target.value;
    // this.place = e.target.value;
    // this.insightView = false;
    // this.showCreateLast = false;
    // this.showUseSite = false;
    this.loader.display(true);
    //this.removeInsightMap();
    this.getGooglePlaces();
    setTimeout(() => {
      // this.getHurricaneName();
      // this.setCalenderDate();
      // this.checkIfThelocationHasPostcode();
      // this.callEndDate();
      this.loader.display(false);
    }, 1000);
  }

  getGooglePlaces() {
    let x = this;
    let recentplaceObj: any = {};
    let inputLoc = <HTMLInputElement>document.getElementById("inputLocation");
    let places;
    this.mapsAPILoader.load().then(() => {
      places = new google.maps.places.Autocomplete(this.locationInput.nativeElement, {});
      google.maps.event.addListener(places, 'place_changed', () => {
        x.existPlace = false;
        x.place = places.getPlace();
        x.searchedLocation = x.place;
        x.newReportWidget.controls.location.setValue(x.searchedLocation.formatted_address);
        x.showDates = true;
        if (x.place.geometry == undefined) {
          return;
        }
        for (let i = 0; i < x.place.address_components.length; i++) {
          if (x.place.address_components[i].types[0] == 'postal_code') {
            x.postcode = x.place.address_components[i].long_name;
            x.postCodeFlag = true;
          }
          if (x.place.address_components[i].types[0] == 'country') {
            x.countryName = x.place.address_components[i].short_name;
            x.countryLongName = x.place.address_components[i].long_name;
          }
          if (x.place.address_components[i].types[0] == 'administrative_area_level_1') {
            x.state = x.place.address_components[i].long_name;
          }
        }
        if (!x.postCodeFlag) {
          let lati = x.place.geometry.location.lat();
          let longi = x.place.geometry.location.lng();
          this.getCountryNameByLatLong(lati, longi, x);
          // this.preciseLocErr = true;
        }
        x.datepickerInput.show();
      });
    });
    x.postCodeFlag = false;
  }

  // checkgooglepPlacesNetwork(e) {
  //   if (navigator.onLine == true) {
  //     this.preciseLocErr = false;
  //     this.grpDrop = false;
  //     this.insightView = false;
  //     this.isLatLong = false;
  //     this.isLatLongSearch = false;
  //     let location = this.insightObj.place;
  //     if ( location == undefined )
  //       return;
  //     if (location.indexOf(",") > -1) {
  //       let latLongArr = location.split(',');
  //       if( this.isLatitude(latLongArr[0].trim()) &&  this.isLongitude(latLongArr[1].trim()) ) {
  //         this.isLatLong = true;
  //         let code = (e.keyCode ? e.keyCode : e.which);
  //         if ( code == 13 )
  //           this.searchByLatLong(this.insightObj.place);
  //       } else {
  //         this.isLatLong = false;
  //         this.isLatLongSearch = false;
  //       }
  //     } 
  //     //If location is filled then hide validation message
  //     this.showDates = false;
  //     this.maxStartDt = new Date();
  //     this.maxEndDt = new Date();
  //     this.reqLocErr = (this.insightObj.place == '') ? true : false;
  //     // if (this.reqLocErr)
  //     //   this.removeInsightMap();
  //   } else {
  //     this.connectionService.display(true);
  //     this.loader.display(false);
  //     document.getElementById("inputLocation").blur();
  //   }
  // }

  // searchByLatLong(LatLongValue) {
  //   if (navigator.onLine == true) {
  //     let x = this;
  //     x.isLatLongSearch = true;
  //     x.loader.display(true);
  //     x.isLatLong = false; // Setting the variable to the default value
  //     x.searchedLatLong = LatLongValue;
  //     // if (LatLongValue.indexOf(",") > -1) {
  //     //   this.preciseLocErr = false;
  //     // } else {
  //     //   this.preciseLocErr = true;
  //     //   x.loader.display(false);
  //     //   return;
  //     // } 
  //     let LatLongArr = LatLongValue.split(',');
  //     x.weatherObj.lat = parseFloat(LatLongArr[0].trim());
  //     x.weatherObj.lon = parseFloat(LatLongArr[1].trim());
  //     let geocoder = new google.maps.Geocoder;
  //     let latlng = { lat:parseFloat(LatLongArr[0]), lng: parseFloat(LatLongArr[1]) };
  //     geocoder.geocode({ 'location': latlng }, function (results, status) {
  //       if (status === 'OK') {
  //         if (results.length > 0) {
  //           // x.removeInsightMap();
  //           //x.getGooglePlaces();
  //           setTimeout(() => {
  //             // x.getHurricaneName();
  //             // x.setCalenderDate();
  //             // x.checkIfThelocationHasPostcode();
  //             //x.callEndDate();
  //             x.loader.display(false);
  //           }, 1000);
  //           x.loader.display(false);
  //           // for (let r = 0; r < results.length; r++) {
  //           //   for (let i = 0; i < results[r].address_components.length; i++) {
  //           //     let postCodeInd = results[r].address_components[i].types.indexOf('postal_code');
  //           //     let countryInd = results[r].address_components[i].types.indexOf('country');
  //           //     let stateInd = results[r].address_components[i].types.indexOf('administrative_area_level_1');

  //           //     if (postCodeInd > -1) {
  //           //       x.showDates = true;
  //           //       x.postcode = results[r].address_components[i].long_name;
  //           //       x.postCodeFlag = true;
  //           //       x.place = results[0];
  //           //     } else {
  //           //       x.showDates = true;
  //           //       x.postcode = 'xxxxxx';
  //           //       x.postCodeFlag = true;
  //           //       x.place = results[0];
  //           //     }

  //           //     if (countryInd > -1) {
  //           //       x.countryName = results[r].address_components[i].short_name;
  //           //       x.countryLongName = results[r].address_components[i].long_name;
  //           //     }
  //           //     if (stateInd > -1) {
  //           //       x.state = results[r].address_components[i].long_name;
  //           //     }
  //           //     x.setWeatherObj(x);
  //           //     x.insightObj.place = x.place.formatted_address;
  //           //     x.preciseLocErr = false;
  //           //   }
  //           // }
  //         }
  //       } else {
  //         x.preciseLocErr = true;
  //         x.showDates = false;
  //         x.loader.display(false);
  //         // return;
  //       }
  //     }); 
  //   } else {
  //     this.connectionService.display(true);
  //     this.loader.display(false);
  //   }
  // }

  getCountryNameByLatLong(lati, longi, x) {
    let geocoder = new google.maps.Geocoder;
    let latlng = { lat: lati, lng: longi };
    geocoder.geocode({ 'location': latlng }, function (results, status) {
      if (status === 'OK') {
        if (results.length > 0) {
          x.searchedLocation = results[0];
          x.newReportWidget.controls.location.setValue(x.searchedLocation.formatted_address);
          for (let r = 0; r < results.length; r++) {
            for (let i = 0; i < results[r].address_components.length; i++) {
              let postCodeInd = results[r].address_components[i].types.indexOf('postal_code');
              let countryInd = results[r].address_components[i].types.indexOf('country');
              let stateInd = results[r].address_components[i].types.indexOf('administrative_area_level_1');

              if (postCodeInd > -1) {
                x.showDates = true;
                x.postcode = results[r].address_components[i].long_name;
                x.postCodeFlag = true;
                x.place = results[0];
              } else {
                x.showDates = true;
                x.postcode = 'xxxxxx';
                x.postCodeFlag = true;
                x.place = results[0];
              }

              if (countryInd > -1) {
                x.countryName = results[r].address_components[i].short_name;
                x.countryLongName = results[r].address_components[i].long_name;
              }
              if (stateInd > -1) {
                x.state = results[r].address_components[i].long_name;
              }
              x.setWeatherObj(x);
              x.insightObj.place = x.place.formatted_address;
              x.preciseLocErr = false;
            }
          }
        } else {
          // this.preciseLocErr = true;
          // this.showDates = false;
          // return;
        }
      } else {

        // this.preciseLocErr = true;
        // this.showDates = false;
        // return;
      }
    });
  }

  setWeatherObj(x) {
    this.preciseLocErr = false;
    let address = x.place.formatted_address;
    let latitude = x.place.geometry.location.lat();
    let longitude = x.place.geometry.location.lng();
    let str = x.place.formatted_address;
    let str_array = str.split(',');
    let userName = localStorage.getItem('user');
    if (userName) {
      userName = userName.replace(/['"]+/g, '');
    }
    if (!this.isLatLongSearch) {
      x.weatherObj.lat = latitude;
      x.weatherObj.lon = longitude;
    }
    x.weatherObj.postCode = x.postcode;
    x.weatherObj.country = x.countryName;
    x.weatherObj.username = userName;
    x.weatherObj.state = x.state;
    x.weatherObj.countryName = x.countryLongName;
  }

  isLatitude(lat) {
    return isFinite(lat) && Math.abs(lat) <= 90;
  }

  isLongitude(lng) {
    return isFinite(lng) && Math.abs(lng) <= 180;
  }

  onSubmit() {
    const values = this.newReportWidget.value
    // if(!this.locationSelected && !this.isComplete){
    //   setTimeout(() => this.locationInput.nativeElement.focus())
    //   return false
    // }
    // else if((!values.startDate || !values.endDate)){
    //   setTimeout(() => this.datepickerInput.show())
    //   return false
    // }

    console.log(values)
    if(!values.location && !this.locationSelected && !this.isComplete){
      setTimeout(() => this.locationInput.nativeElement.focus())
      return false
    }
    else if((!values.startDate || !values.endDate)){
      setTimeout(() => this.datepickerInput.show())
      return false
    }

    this.router.navigate(['/new-report'], {
      state: {
        searchResults: {
          location: JSON.parse(JSON.stringify(this.searchedLocation)),
          place: this.searchedLocation.formatted_address,
          datestart: values.startDate,
          dateend: values.endDate,
        }
      }
    })
  }

}
