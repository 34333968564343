import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConnectionService } from 'src/app/services/connection.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { LoaderService } from 'src/app/services/loader.service';
import { UserService } from 'src/app/services/user.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState, selectAuthState } from '../../store/app.states';
import { LogOut,ChangeSector } from '../../store/actions/auth.actions';
import { LogoutService } from 'src/app/services/logout.service';
import * as moment from 'moment';
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
    getState: Observable<any>;
    titleField : string = 'My account';
    avatar: string | null;
    public showDownload = false;
    public isAdmin;
    public searchuser: any = {};
    public showQuote: boolean = false;
    public months;
    public year;
    public years;
    quoteForm: FormGroup;
    public downloadQuoteErr: boolean = false;
    public downloadQuoteMessage;
    public myOrders: any = [];
    public numPages: number;
    public currentPage: number = 1;
    public userList: any = [];
    public pages: any = [];
    public interval: any;
    public setDefaultPopup: any = false;
    public tableHeight:any;
    public isTrialUser:boolean=false;
    public profileData;
    public imageData;
    public passwordOverlay:boolean = false;
    public changePasswordForm:FormGroup;
    public isCurrPassCorrect:boolean=false;
    public showPassErrorMessage:boolean=false;
    public isError:boolean=false;
    public errorArr:any;
    public isSuccess:boolean=false;
    public succMessage:any;
    public currPassIncorrMsg:any;
    public expandRowData = null;
    destroy$: Subject<any> = new Subject();
    @ViewChild('bodyTable', {static: false}) bodyTable: ElementRef;
    public isImage: boolean = false;
    public showprofileLoader = false;
    public image: any;
    form: FormGroup;
    public msgflag = false;
    public showMsg: string;
    public showNick: boolean = false;
    @ViewChild('fileInput', {static: true}) fileInput: ElementRef;
    showHideDropdown:boolean = false;
    currentModelName:any;
    constructor(
      private titleService:Title,
      public ngxSmartModalService: NgxSmartModalService,
      private loader: LoaderService,
      private connectionService: ConnectionService,
      private DashboardService: DashboardService,
      private userService :UserService,
      private fb: FormBuilder,
      private router: Router,
      private store: Store<AppState>,
      private sanitizer: DomSanitizer,
      private LS: LogoutService
    ) {
      this.getState = this.store.select(selectAuthState);
        this.titleService.setTitle('My account - MetSwift');
        this.showNick = true;
        this.getData();
        this.fetchUserOrdersList();
        this.fetchProfile();
        this.validateForm();
        this.months = [
          { "id": 1, "name": "Jan" },
          { "id": 2, "name": "Feb" },
          { "id": 3, "name": "Mar" },
          { "id": 4, "name": "Apr" },
          { "id": 5, "name": "May" },
          { "id": 6, "name": "Jun" },
          { "id": 7, "name": "Jul" },
          { "id": 8, "name": "Aug" },
          { "id": 9, "name": "Sep" },
          { "id": 10, "name": "Oct" },
          { "id": 11, "name": "Nov" },
          { "id": 12, "name": "Dec" }
        ];
    
        this.year = new Date().getFullYear();
        let range = [];
        range.push(this.year);
        for (var i = 1; i < 5; i++) {
          range.push(this.year - i);
        }
        this.years = range;
        this.loader.display(true);
      this.LS.logOut({ "title": "My Account", "link": "#/myaccount" })
        .subscribe(
          data => {
            let successData = data;
            this.isAdmin = successData.isAdmin;
            this.loader.display(false);
            //this.fetchUserOrdersList();
            if (successData.isAdmin == 0) {
              this.showDownload = false;
            } else {
              this.showDownload = true;
            }
            if ( successData.trial == 1 )
              this.isTrialUser = true;
          },
          error => {
            this.router.navigate(['/login']);
          }
        );
    }

    ngOnInit(): void {
        this.form = this.fb.group({
          avatar:['']
        })
        this.validatwQuoteForm();
    }
    getData() { 
      if (this.showNick == true && this.isImage == false) {
        this.getProfileImage();
      }
    }
    rowClick(index,elemnt): void {
      this.expandRowData = elemnt;
      const body = this.bodyTable.nativeElement
      if(body){
          body.querySelector("[data-header-index='" + index + "']").classList.toggle("active");
          body.querySelector("[data-index='" + index + "']").classList.toggle("active");
      }
      
    }
    validateForm() {
      this.changePasswordForm = this.fb.group({
        currPass: ['',Validators.required],
        newPass: ['',Validators.required],
        conNewPass: ['',Validators.required]
      });
    }
    checkIfPassIsCorrect() {
      let currPass = this.changePasswordForm.controls.currPass.value;
      if ( currPass.length < 2 )
        return;
      setTimeout(() => {
        let body = JSON.stringify({'pass': currPass});
        this.userService.checkIfPasswordIsCorrect(body)
        .subscribe(
          (data)=> {
            let resData = data;
            if ( resData.currPassExists == true )
              this.isCurrPassCorrect = true;
              this.showPassErrorMessage = false;
          },
          (error)=> {
            this.isCurrPassCorrect = false;
            let errorData = error.error;
            this.currPassIncorrMsg = errorData.message;
            this.showPassErrorMessage = true;
          }
      );
      }, 1000);
    }

    ChangePaswordAction() {
      let body = JSON.stringify({
        newPass: this.changePasswordForm.controls.newPass.value,
        conNewPass: this.changePasswordForm.controls.conNewPass.value,
      });
      this.userService.changePassword(body)
        .subscribe(
          (data)=> {
            let successData = data;
            this.isError = false;
            this.isSuccess = true;
            this.succMessage = successData.message;
            this.LS.logOut({ "title": "Change Password", "link": "#/myaccount" })
            .subscribe(
              data => {
                
              },
              error => {
              
              }
            );
            setTimeout(() => {
              this.store.dispatch(new LogOut());
              this.router.navigateByUrl('/log-in');
            }, 2000);
            setTimeout(() => {
              this.isSuccess = false;
              this.isCurrPassCorrect = false;
              this.showPassErrorMessage = true;
              this.validateForm();
            }, 1000);
          },
          (error)=> {
            this.isError = true;
            let errorData = error.error;
            this.errorArr = errorData.errors;
          }
        );
    }
    fetchProfile() {
      this.loader.display(true);
      this.userService.fetchuserProfile({})
        .subscribe(
          data => {
            this.loader.display(false);
            let successData = data;
            this.profileData = successData.profile;
          },
          error => {
            let errordata = error;
          });
    
  }

    fetchUserOrdersList() {
     
        this.loader.display(true);
        let request = JSON.stringify({
          "pageno": this.currentPage
        });
        this.DashboardService
        .fetchUserOrdersList(request)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
            data => {
              let successData = data;
              this.myOrders = successData.data;
              this.numPages = successData.numPages;
              this.userList = successData.list;
              let startPage: number, endPage: number;
              if (screen.width <= 767) {
                if (this.numPages <= 5) {
                  // less than 10 total pages so show all
                  startPage = 1;
                  endPage = this.numPages;
                } else {
                  if (this.currentPage <= 3) {
                    startPage = 1;
                    endPage = 5;
                  } else if (this.currentPage + 2 >= this.numPages) {
                    startPage = this.numPages - 4;
                    endPage = this.numPages;
                  } else {
                    startPage = this.currentPage - 2;
                    endPage = this.currentPage + 2;
                  }
                }
              } else {
                if (this.numPages <= 10) {
                  // less than 10 total pages so show all
                  startPage = 1;
                  endPage = this.numPages;
                } else {
                  if (this.currentPage <= 6) {
                    startPage = 1;
                    endPage = 10;
                  } else if (this.currentPage + 4 >= this.numPages) {
                    startPage = this.numPages - 9;
                    endPage = this.numPages;
                  } else {
                    startPage = this.currentPage - 5;
                    endPage = this.currentPage + 4;
                  }
                }
              }
              this.pages = [];
              this.pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
              this.loader.display(false);
            },
            error => {
              let errordata = error;
              this.loader.display(false);
            });
     
    }
  
    onPage(val) {
      this.currentPage = val;
      this.fetchUserOrdersList();
    }
  
    previous() {
        if (this.currentPage <= 1) {
          this.currentPage = 1;
        } else {
          this.currentPage = this.currentPage - 1;
        }
        this.fetchUserOrdersList();
    }
  
    first() {
      this.currentPage = 1;
      this.fetchUserOrdersList();
    }
  
    last() {
      this.currentPage = this.numPages;
      this.fetchUserOrdersList();
    }
  
    next() {
      if (this.currentPage < this.numPages) {
        this.currentPage = this.currentPage + 1;
      }
      this.fetchUserOrdersList();
    }

    getProfileImage() {
        this.showprofileLoader = true;
        this.userService.getProfileImage({})
          .subscribe(
            data => {
              let successData = data;
              if (successData.isImage == '0') {
                this.isImage = false;
                this.showprofileLoader = false;
                this.userService.imagepath(false);
                this.loader.display(false);
              } else {
                this.image = this.sanitizer.bypassSecurityTrustResourceUrl(successData.image);
                this.userService.imagepath(this.image);
                this.isImage = true;
                this.showprofileLoader = false;
                this.loader.display(false);
              }
            },
            error => {
              let errordata = error;
              this.loader.display(false);
            });
    }
  
    onFileChange(event) {
      if (event.target.files.length > 0) {
        let file = event.target.files[0];
        let filesize: any = (file.size / 1024).toFixed(2);
        if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg') {
          if (filesize <= 3072) {
            this.msgflag = false;
            this.form.get('avatar').setValue(file);
            this.prepareSave();
          } else {
            this.msgflag = true;
            this.showMsg = 'File size should be less than 3MB.';
          }
        } else {
          this.msgflag = true;
          this.showMsg = 'File format(s) png ,jpg or jpeg only are accepted.';
        }
      }
    }
  
    private prepareSave(): any {
      let input = new FormData();
      input.append('profileimage', this.form.get('avatar').value);
      console.log(this.form.get('avatar'));
      this.loader.display(true);
      this.userService.uploadImage(input)
        .subscribe(
          data => {
            let successData = data;
            this.getProfileImage();
          },
          error => {
            let errordata = error;
            this.loader.display(false);
          });
    }
  
    clearFile() {
      this.form.get('avatar').setValue(null);
      // let file = this.fileInput.nativeElement
      // if(file){file.value = '';}
      this.userService.removeImage({})
        .subscribe(
          data => {
            let successData = data;
            this.getProfileImage();
          },
          error => {
            let errordata = error;
          });
    }
  
    onUploadFinished(e) {
      let ImageURL = e.src;
      let block = ImageURL.split(";");
      // Get the content type of the image
      let contentType = e.file.type
      // get the real base64 content of the file
      let realData = block[1].split(",")[1];
      // Convert it to a blob to upload
      let blob = this.b64toBlob(realData, contentType, null);
      // Create a FormData and append the file with "image" as parameter name
      let formDataToUpload = new FormData();
      formDataToUpload.append("profileimage", blob);
      this.userService.uploadImage(formDataToUpload)
        .subscribe(
          data => {
            let successData = data;
          },
          error => {
            let errordata = error;
          });
    }
  
      /**
   * Convert a base64 string in a Blob according to the data and contentType.
   * 
   * @param b64Data {String} Pure base64 string without contentType
   * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
   * @param sliceSize {Int} SliceSize to process the byteCharacters
   * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
   * @return Blob
   */
  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  downloadQouteCsv() {
    let body = JSON.stringify({
      month: this.quoteForm.controls.month.value,
      year: this.quoteForm.controls.year.value
    });
    this.loader.display(true);
    this.userService.downloadQuoteCsv(body)
      .subscribe(
        data => {
          this.downloadFile(data, 'quote_'+ moment().format("DD-MM-YYYY")+'.csv');
          this.loader.display(false);
          setTimeout(() => {
            this.closeQuote();
          }, 1000);
        },
        error => {
          this.loader.display(false);
          let errordata = error;
          this.downloadQuoteErr = true;
          this.downloadQuoteMessage = 'There is no record available for the selected month and year.';
          setTimeout(() => {
            this.downloadQuoteErr = false;
          }, 3000);
        });
    
  }

  downloadFile(data: any, filename) {
    // let parsedResponse = data.text();
    let parsedResponse = data;
    let blob = new Blob([parsedResponse], { type: 'text/csv' });
    let url = window.URL.createObjectURL(blob);
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }

  downloadsQuote() {
    //this.ngxSmartModalService.getModal('changePassword').open();
    this.showQuote = true;
  }

  closeQuote() {
    //this.ngxSmartModalService.getModal('changePassword').close();
    this.showQuote = false;
  }

  validatwQuoteForm(): void {
    this.quoteForm = this.fb.group({
      month: ['1', [Validators.required]],
      year: [this.year, [Validators.required]],
    });
  }

  toogleDropdown(){
    this.showHideDropdown = !this.showHideDropdown;
  }
  changeModelData(model){
    console.log(model)
    this.currentModelName = model.name
  }
  changesector(val:any) {
    this.loader.display(true);
    this.getState.subscribe((state) => {
      let updatedUser = Object.assign({}, state.user);
      updatedUser.sector = val=='Insurance'?3:1;  
      console.log(val) 
      console.log(updatedUser)
      // this.store.dispatch(new ChangeSector(updatedUser));
      // console.log('l')
      // this.fetchProfile();
    })
    let vald:any = val=='Insurance'?3:1;  
    this.userService.changesector({'changesector':vald}).subscribe(
      data=>{
        localStorage.setItem('sector',vald);
        this.loader.display(false);
        this.fetchProfile();
      },
      error=>{
        this.loader.display(false);
      }
    )
  }
}

