<div class="min-w-screen min-h-screen py-12 bg-primary flex items-center justify-center">
    <app-backgrounds class="opacity-25"></app-backgrounds>
    <div class="mx-auto container">
        <img class="w-auto mx-auto" src="assets/images/metswift-horz-WHT@0.5x.png" srcset="assets/images/metswift-horz-WHT@0.5x.png 1x, assets/images/metswift-horz-WHT@0.5x.png 2x">
        <div class="auth-wrapper w-full mx-auto mt-5 xl:mt-8 max-w-lg xl:max-w-xl rounded-lg bg-white p-6 xl:p-8">
            <form class="form-signin" #form="ngForm" (ngSubmit)="onSubmit(form.value)">
                <h3 class="font-semibold text-lg text-primarylighter text-center mb-4">Set Password</h3>

                <div style="font-size: 12px;font-style: italic;line-height: initial;
                font-weight: 500;">
                  * Please set a memorable password for your account.<br>
                  * Must consist of 8-20 characters with a combination of one uppercase, one lower case, one number and one special character.<br></div>
                <div class="mt-1 mb-2 font-semibold text-sm text-orange-dark">
                    <h5 class="invalid-credential" [hidden]="!_forgotError"> {{ _errorMsg }}</h5>
                    <h5 class="success-msg" [hidden]="!_success"> {{ _successMsg }}</h5>
                    <!-- <p *ngIf="errorMessage && signinForm.valid && signinformsubmit">{{errorMessage}}</p> -->
                </div>
                <div class="mb-4 w-full relative">
                    <label for="username" class="mb-1 font-semibold text-gray-700 text-xs block">New Password <sup>*</sup></label>
                    <div class="mt-1 mb-2 font-semibold text-sm text-orange-dark">
                        <div *ngIf="form.submitted">
                            <p *ngIf="!formData.newpassword">
                              Password is required.
                            </p>
                            <p *ngIf="formData.newpassword && formData.newpassword.length>50">
                              Password can be at most 50 characters.
                            </p>
                            <p *ngIf="formData.newpassword && formData.newpassword.length<6">
                              Password should be at least 6 characters.
                            </p>
                        </div>
                        
                        
                    </div>

                    <input [type]="show1?'text':'password'" class="w-full block bg-gray-200 rounded px-4 py-2 focus:bg-white border border-gray-200 font-sans tracking-wide focus:border-gray-300" name="newpassword" ngModel="" #newpassword="ngModel"
                    id="newpassword" maxlength="50" minlength="6" placeholder="New Password" required>
                    <span class="absolute bottom-2 right-2 cursor-pointer">
                        <svg *ngIf="!show1" (click)="showPassword1()" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">

                            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                            <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                          </svg>
                          <svg *ngIf="show1" (click)="hidePassword1()" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clip-rule="evenodd" />
                            <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                          </svg>
                    </span>
                    <!-- <input (keyup)="errorMessage=null" type="text" maxlength="50" class="w-full block bg-gray-200 rounded px-4 py-2 cursor-pointer focus:bg-white border border-gray-200 font-sans tracking-wide focus:border-gray-300" id="username" formControlName="username" placeholder="Enter username" required> -->
                </div>
                <div class="mb-4 w-full relative">
                    <label for="password" class="mb-1 font-semibold text-gray-700 text-xs block">Confirm Password <sup>*</sup></label>
                    <div class="mt-1 mb-2 font-semibold text-sm text-orange-dark" *ngIf="form.submitted">
                        <p *ngIf="!formData.confirmpassword">
                          Password is required.
                        </p>
                        <p *ngIf="formData.confirmpassword && formData.confirmpassword.length>50">
                          Password can be at most 50 characters.
                        </p>
                        <p *ngIf="formData.confirmpassword && formData.confirmpassword.length<6">
                          Password should be at least 6 characters.
                        </p>                    
                    </div>

                    <input [type]="show2?'text':'password'" class="w-full block bg-gray-200 rounded px-4 py-2 focus:bg-white border border-gray-200 font-sans tracking-wide focus:border-gray-300" name="confirmpassword" ngModel=""
                    #confirmpassword="ngModel" id="confirmpassword" maxlength="50" minlength="6"
                    placeholder="Confirm Password" required>
                    <span class="absolute bottom-2 right-2 cursor-pointer">
                        <svg *ngIf="!show2" (click)="showPassword2()" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">

                            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                            <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                          </svg>
                          <svg *ngIf="show2" (click)="hidePassword2()" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clip-rule="evenodd" />
                            <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                          </svg>
                    </span>
                    <!-- <input (keyup)="errorMessage=null" type="password" maxlength="50" minlength="8" id="password" class="w-full block bg-gray-200 rounded px-4 py-2 cursor-pointer focus:bg-white border border-gray-200 font-sans tracking-wide focus:border-gray-300" formControlName="password" placeholder="Password"> -->
                </div>
                <!-- <div class="mb-4 w-full" *ngIf="tokenFlag">
                    <label for="password" class="mb-1 font-semibold text-gray-700 text-xs block cursor-pointer">Token <sup>*</sup></label>
                    
                    <input type="text" class="w-full block bg-gray-200 rounded px-4 py-2 cursor-pointer focus:bg-white border border-gray-200 font-sans tracking-wide focus:border-gray-300" name="token" ngModel="" #token="ngModel" id="token"
                    placeholder="Enter Token" required>
                    
                    <input (keyup)="errorMessage=null" type="password" maxlength="50" minlength="8" id="password" class="w-full block bg-gray-200 rounded px-4 py-2 cursor-pointer focus:bg-white border border-gray-200 font-sans tracking-wide focus:border-gray-300" formControlName="password" placeholder="Password">
                </div> -->
                
                <button type="submit" class="btn-gradient block mt-6 w-full px-4 py-3 hover:opacity-75"> Set </button>

                <!-- <div class="element-response w-full text-sm mt-4 text-center"><strong class="font-semibold">Success!</strong>  Text response here</div> -->
            </form>
        </div>
        <!-- <div class="w-full mx-auto mt-5 xl:mt-8 max-w-lg xl:max-w-xl">
            <ul class="flex flex-wrap justify-center">
                <li class="mx-3">
                    <a href="http://metswift.com/" target="_blank" rel="noopener" class="text-white font-light border-b border-gray-700 hover:border-white text-sm sm:text-base">Main Website</a>
                </li>
                <li class="mx-3">
                    <a href="http://metswift.com/contact" target="_blank" rel="noopener" class="text-white font-light border-b border-gray-700 hover:border-white text-sm sm:text-base">Contact us</a>
                </li>
            </ul>
        </div> -->
    </div>
</div>
