import { Action } from '@ngrx/store';


export enum AuthActionTypes {
  LOGIN = '[Auth] Login',
  LOGIN_SUCCESS = '[Auth] Login Success',
  LOGIN_FAILURE = '[Auth] Login Failure',
  LOGOUT = '[Auth] Logout',
  GET_STATUS = '[Auth] GetStatus',
  ACCEPT_TERM = '[Auth] Accept Term',
  ACCEPT_TERM_SUCCESS = '[Auth] Accept Term Success',
  CHANGE_SECTOR = '[Auth] Change Sector',
  CHANGE_SECTOR_SUCCESS = '[Auth] Change Sector Success'
}

export class LogIn implements Action {
  readonly type = AuthActionTypes.LOGIN;
  constructor(public payload: any) {}
}

export class LogInSuccess implements Action {
  readonly type = AuthActionTypes.LOGIN_SUCCESS;
  constructor(public payload: any) {}
}

export class LogInFailure implements Action {
  readonly type = AuthActionTypes.LOGIN_FAILURE;
  constructor(public payload: any) {}
}

export class LogOut implements Action {
  readonly type = AuthActionTypes.LOGOUT;
}

export class GetStatus implements Action {
  readonly type = AuthActionTypes.GET_STATUS;
}

export class ChangeSector implements Action {
  readonly type = AuthActionTypes.CHANGE_SECTOR;
  constructor(public payload: any) {}
}

export class ChangeSectorSuccess implements Action {
  readonly type = AuthActionTypes.CHANGE_SECTOR_SUCCESS;
  constructor(public payload: any) {}
}


export class AcceptTerm implements Action {
  readonly type = AuthActionTypes.ACCEPT_TERM;
  constructor(public payload: any) {}
}

export class AcceptTermSuccess implements Action {
  readonly type = AuthActionTypes.ACCEPT_TERM_SUCCESS;
  constructor(public payload: any) {}
}

export type All =
  | LogIn
  | LogInSuccess
  | LogInFailure
  | LogOut
  | GetStatus
  | AcceptTerm
  | AcceptTermSuccess
  | ChangeSector
  | ChangeSectorSuccess;
