<div class="lg:flex-grow p-4 md:p-6 lg:p-12 relative">
    <app-backgrounds></app-backgrounds>
    <div class="w-full relative z-10">
        <div class="flex flex-wrap -mx-4 lg:-mx-6 justify-between mb-3 md:mb-5 lg:mb-8">
            <div class="px-4 lg:px-6 w-auto lg:w-1/2">
                <h1 class="font-semibold text-xl lg:text-2xl">{{ title }}</h1>
                
                
            </div>
            <div class="px-4 lg:px-6 w-auto flex flex-wrap items-center">
                <!-- <app-search-reports-button></app-search-reports-button> -->
            </div>
        </div>

        <div class="flex flex-wrap -mx-4 lg:-mx-6 justify-between mb-3 md:mb-5 lg:mb-8 lg:mt-6">
            
            <div class="px-4 lg:px-6 w-full mt-2 md:mt-0 xl:w-1/2 flex flex-wrap justify-start">
                
                <div class="flex flex-wrap justify-start relative">
                    <div class="inline-block align-middle h-12 px-2 py-3 absolute top-0 left-28">
                        <div class="cursor-pointer" (click)="ngxSmartModalService.getModal('csvGuidelin').open()">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                            </svg>
                        </div>
                    </div>
                    <form class="flex items-center space-x-6 mb-2">
                        <label class="w-full lg:w-96" *ngIf="pagedata">
                            <span [class.disable_upload]="disable_upload"
                                class="disable_upload align-text-bottom inline-block rounded-full px-3 h-12 btn-gradient py-3 mr-10 text-base text-center text-sm font-light cursor-pointer">Upload
                                CSV</span>
                                
                            <input [class.disable_upload]="disable_upload" [disabled]="disable_upload"  type="file" onclick="this.value = null" (change)='saveFileUploadEvent($event)' id="file" accept=".csv"
                                class="disable_upload w-3/5 lg:w-auto align-text-bottom py-3 px-2 h-12 text-sm text-right text-black file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100" />
                            
                            
                        </label>
                    </form>
                </div>
                
                
                
            </div>
            <div class="px-4 lg:px-6 w-full xl:w-1/2 text-right mt-2 xl:mt-0">
                <a href="../assets/csvfile/Bulk_event_sample.csv"
                    class="bg-primarylighter text-white rounded-full text-sm font-semibold button-buy px-2 md:px-4 py-3 text-black inline-block  ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-6 h-6 inline-block">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                    </svg> Download Sample CSV
                </a>
                <a *ngIf="error_report_file" href="javascript:void(0)" (click)="downloads3file(error_report_file)"
                    class="bg-primarylighter text-white rounded-full text-sm font-semibold button-buy px-2 md:px-4 py-3 text-black inline-block ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-6 h-6 inline-block">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                    </svg> Error Report
                </a>
            </div>
        </div>
        <div class="w-full text-right">
            <!-- <button type="button" (click)="ngxSmartModalService.getModal('csvConfirmation').open()">Confirmation
            popup</button> -->
        </div>
        <!-- error message -->

        <div class="w-full " role="alert">
            <h3 class="text-md font-semibold py-3 mb-6 text-left" style="color: #f00f00">{{errorMsg}}</h3>
        </div>


        <!-- table -->
        <div class="csv-table w-full" *ngIf="pagedata">
            <div class="w-full text-right pr-2 text-2xl" *ngIf="processdata.length>0"><button (click)="refreshpage()"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
              </svg></button></div>
            <table class="w-full block relative overflow-hidden border border-gray-300 lg:border-0 rounded mb-5 ">
                <thead class="header-table bg-primary text-white">
                    <tr class="flex flex-col flex-no-wrap lg:table-row mb-2 lg:mb-0">
                        <th
                            class="header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">
                            S.no</th>
                        <th
                            class="header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">
                            File Name</th>
                        <th
                            class="header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">
                            Uploaded Date</th>
                        <th
                            class="header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">
                            # of total events to be created</th>
                        <th
                            class="header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">
                            # of events Failed</th>
                        <th
                            class="header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">
                            Status</th>
                    </tr>
                </thead>
                <tr class="hidden lg:flex flex-col flex-no-wrap border-b border-gray-300 lg:table-row mb-2 lg:mb-0 font-semibold text-center">
                    <td colspan="6" *ngIf="processdata.length<1">
                        To create events in bulk, download the sample csv file. Fill in the required details and upload the CSV
                    </td>
                </tr>
                <tbody *ngFor="let process of processdata;let i = index" #bodyTable 
                    class="block lg:table-row-group flex-1 lg:flex-none bg-white border-gray-300 lg:border-t-0 lg:border rounded rounded-t-none">
                    <tr class="hidden lg:flex flex-col flex-no-wrap border-b border-gray-300 lg:table-row mb-2 lg:mb-0">
                        <td class="text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">
                            {{i+1}}
                        </td>
                        <td class="text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">
                            <a href="javascript:void(0)" (click)="downloads3file(process.uploaded_name)" class="rounded-full h-12 text-sm font-semibold error-button px-4 py-3 text-black-base inline-block ">
                                {{process.name}}
                            </a>
                        </td>
                        <td class="text-xs space-nowrap xl:text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">{{process.created_date | date:'yyyy-MM-dd'}}
                        </td>
                        <td class="text-xs xl:text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2 max-w-xxs">
                            {{process.num_event_created?process.num_event_created:process.total_event}}</td>

                        <td class="text-xs xl:text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2 min-w-16">
                            {{process.fail_event}}
                        </td>
                        <td class="text-xs xl:text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2 min-w-16">
                            {{process.status=='Queued'?'Queued':((process.fail_event+process.success_event) == process.total_event)?"Completed":"In Progress"}}
                        </td>
                    </tr>
                    <tr class="block lg:hidden border-b border-gray-300">
                        <td class="block text-sm p-3">
                            <div class="mb-1 text-xs">
                                S.no: <strong class="ml-3 text-sm">{{i+1}}</strong>
                            </div>
                            <div class="mb-1 text-xs">
                                File Name: <strong class="ml-3 text-sm">
                                    <a href="javascript:void(0)" class="rounded-full h-12 text-sm font-semibold error-button px-4 py-3 text-black-base inline-block ">{{process.name}}</a>
                                </strong>
                            </div>
                            <div class="mb-1 text-xs">
                                Uploaded Date: <strong class="ml-3 text-sm">{{process.created_date | date:'dd-MM-yyyy'}}</strong>
                            </div>
                            <div class="mb-1 text-xs">
                                # of total events to be created: <strong class="ml-3 text-sm">{{process.num_event_created?process.num_event_created:process.total_event}}</strong>
                            </div>
                            <div class="mb-1 text-xs">
                                # of events Failed: <strong class="ml-3 text-sm">{{process.fail_event}}</strong>
                            </div>
                            <div class="mb-1 text-xs">
                                Status: <strong class="ml-3 text-sm">{{process.status=='Queued'?'Queued':((process.fail_event+process.success_event) == process.total_event)?"Completed":"In Progress"}}
                                </strong>
                            </div>
                    </tr>
                </tbody>


            </table>
        </div>
    </div>

</div>

<!-- question mark click to show csv details -->
<ngx-smart-modal [closable]="true" #csvGuidelin identifier="csvGuidelin">
    <div class="max-w-screen-2xl mx-auto my-12">

        <div class="bg-white rounded lg:rounded-lg p-4 lg:p-8">
            <h3 class="mb-2 lg:mb-5 text-black font-medium text-xl lg:text-2xl text-center">Guidelines for adding event
                details in the CSV</h3>
            <div class="page-content">
                <table class="w-full overflow-hidden border border-gray-300 lg:border-0 rounded mb-5">
                    <thead class="header-table bg-primary text-white">
                        <tr class="table-row mb-2 lg:mb-0">
                            <th
                                class="w-2/5 header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">
                                Column</th>
                            <th
                                class="header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">
                                Explanation</th>
                        </tr>
                    </thead>
                    <tbody #bodyTable
                        class="table-row-group flex-1 lg:flex-none bg-white border-gray-300 lg:border-t-0 lg:border rounded rounded-t-none">
                        <tr class="border-b border-gray-300 table-row mb-2 lg:mb-0">
                            <td class="w-2/5 text-sm align-top border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">
                                Location
                            </td>
                            <td class="text-sm border-r border-gray-300 px-2 py-2">
                                <ol class="list-decimal m-0">
                                    <li class="pb-1 ml-0">The name or address of the location of your event.</li>
                                    <li class="pb-1 ml-0">This can be left blank if a Latitude/Longitude is provided instead.</li>
                                    <li class="pb-1 ml-0">Please provide as precise a location as possible (eg. "Hyde Park, London, United
                                        Kingdom" instead of "Hyde Park").</li>
                                    <li>Imprecise locations may return data for a different area than you intened,
                                        please double check all locations on the confirmation screen.</li>
                                </ol>
                            </td>
                        </tr>
                        <tr class="border-b border-gray-300 table-row mb-2 lg:mb-0">
                            <td class="w-2/5 text-sm align-top border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">
                                Latitude, Longitude
                            </td>
                            <td class="text-sm border-r border-gray-300 px-2 py-2">
                                <ol class="list-decimal m-0">
                                    <li class="pb-1 ml-0">The latitude and longitude of the location of your event.</li>
                                    <li class="pb-1 ml-0">These columns can be left blank if a location name is provided instead.</li>
                                    <li>The latitude and longitude must be in decimal degrees, with a latitude value of
                                        between -90.0 and 90.0 and a longitude value of between -180.0 and 180.0.</li>
                                </ol>
                            </td>
                        </tr>
                        <tr class="border-b border-gray-300 table-row mb-2 lg:mb-0">
                            <td class="w-2/5 text-sm align-top border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">
                                Start Date, End Date
                            </td>
                            <td class="text-sm border-r border-gray-300 px-2 py-2">
                                <ol class="list-decimal m-0">
                                    <li class="pb-1 ml-0">The start and end date for the period you would like data.</li>
                                    <li class="pb-1 ml-0">A value must be provided in both columns in the format "YYYY-MM-DD".</li>
                                    <li class="pb-1 ml-0">The start date must be at least 7 days after the date you are uploading your
                                        file, and the end date can be no later than 5 years after the upload date.</li>
                                    <li>The start date must be before the end date.</li>
                                </ol>
                            </td>
                        </tr>
                        <tr class="border-b border-gray-300 table-row mb-2 lg:mb-0">
                            <td class="w-2/5 text-sm align-top border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">
                                Rainfall, Wind, Temperature, Snow Depth, Hail, Dust, Fog, Storm, Earthquakes, Tornadoes,
                                Tropical Cyclones, Wildifres
                            </td>
                            <td class="text-sm border-r border-gray-300 px-2 py-2">
                                <ol class="list-decimal m-0">
                                    <li class="pb-1 ml-0">These columns are to identify which weather/natural catastrophe risks you would
                                        like to view.</li>
                                    <li class="pb-1 ml-0">To select a risk, place the number 1 in the column. Any other values will be
                                        ignored and the risk will not be unlocked.</li>
                                    <li>If you wish to add a risk to an already created event, you can do so directly
                                        from the insight page. You do not need to upload the event again.</li>
                                </ol>
                            </td>
                        </tr>
                        <tr class="border-b border-gray-300 table-row mb-2 lg:mb-0">
                            <td class="w-2/5 text-sm align-top border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">
                                Rain Units, Wind Units, Temperature Units, Snow Units
                            </td>
                            <td class="text-sm border-r border-gray-300 px-2 py-2">
                                <ol class="list-decimal m-0">
                                    <li class="pb-1 ml-0">These columns identify the units in which you are defining your thresholds, and in which you would like your results to be displayed.</li>
                                    <li class="pb-1 ml-0">These columns require a value if you have placed a "1" in the respective risk column above.</li>
                                    <li class="pb-1 ml-0">For rain, the valid units are: "mm", "in" [Millimetres, Inches].</li>
                                    <li class="pb-1 ml-0">For wind, the valid units are: "mph", "ms" [Miles per Hour, Metres per Second].</li>
                                    <li class="pb-1 ml-0">For temperature, the valid units are: "C", "F" [Degrees Celsius, Degrees Farenheit].</li>
                                    <li>For snow, the valid units are: "cm", "in" [Centimetres, Inches].</li>
                                </ol>
                            </td>
                        </tr>
                        <tr class="border-b border-gray-300 table-row mb-2 lg:mb-0">
                            <td class="w-2/5 text-sm align-top border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">
                                Daily Rain Moderate, Daily Rain Severe, Weekly Rain Moderate, Weekly Rain Severe, Monthly Rain Moderate, Monthly Rain Severe
                            </td>
                            <td class="text-sm border-r border-gray-300 px-2 py-2">
                                <ol class="list-decimal m-0">
                                    <li class="pb-1 ml-0">These columns define your thresholds for rainfall risks and all require a value if you have entered a value of "1" in the "Rainfall" column.</li>
                                    <li class="pb-1 ml-0">If you have not entered a "1" in the "Rainfall" column, these columns can be left blank.</li>
                                    <li>Values must be numeric and cannot be negative.</li>
                                </ol>
                            </td>
                        </tr>
                        <tr class="border-b border-gray-300 table-row mb-2 lg:mb-0">
                            <td class="w-2/5 text-sm align-top border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">
                                Wind Speed Moderate, Wind Speed Severe, Gust Speed Moderate, Gust Speed Severe
                            </td>
                            <td class="text-sm border-r border-gray-300 px-2 py-2">
                                <ol class="list-decimal m-0">
                                    <li class="pb-1 ml-0">These columns define your thresholds for wind speed risks and all require a value if you have entered a value of "1" in the "Wind" column.</li>
                                    <li class="pb-1 ml-0">If you have not entered a "1" in the "Wind" column, these columns can be left blank.</li>
                                    <li>Values must be numeric and cannot be negative.</li>
                                </ol>
                            </td>
                        </tr>
                        <tr class="border-b border-gray-300 table-row mb-2 lg:mb-0">
                            <td class="w-2/5 text-sm align-top border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">
                                Minimum Temperature Moderate, Minimum Temperature Severe, Mean Temperature Moderate, Mean Temperature Severe, Maximum Temperature Moderate, Maximum Temperature Severe
                            </td>
                            <td class="text-sm border-r border-gray-300 px-2 py-2">
                                <ol class="list-decimal m-0">
                                    <li class="pb-1 ml-0">These columns define your thresholds for temperature risks and all require a value if you have entered a value of "1" in the "Temperature" column.</li>
                                    <li class="pb-1 ml-0">If you have not entered a "1" in the "Temperature" column, these columns can be left blank.</li>
                                    <li>Values must be numeric.
                                    </li>
                                </ol>
                            </td>
                        </tr>
                        <tr class="border-b border-gray-300 table-row mb-2 lg:mb-0">
                            <td class="w-2/5 text-sm align-top border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">
                                Snow Depth Moderate, Snow Depth Severe
                            </td>
                            <td class="text-sm border-r border-gray-300 px-2 py-2">
                                <ol class="list-decimal m-0">
                                    <li class="pb-1 ml-0">These columns define your thresholds for snow depth risks and all require a value if you have entered a value of "1" in the "Snow Depth" column.</li>
                                    <li class="pb-1 ml-0">If you have not entered a "1" in the "Snow Depth" column, these columns can be left blank.</li>
                                    <li>Values must be numeric and cannot be negative.</li>
                                </ol>
                            </td>
                        </tr>
                      
                    </tbody>


                </table>
            </div>
        </div>
    </div>
</ngx-smart-modal>

<!-- after upload show confirmation popup -->
<div class="cnfscreen">
<ngx-smart-modal [closable]="false" [dismissable]="false" [escapable]="false" #csvConfirmation
    identifier="csvConfirmation" >
    <div class="w-full mx-auto my-12" *ngIf="event_data">
        <div class="bg-white rounded lg:rounded-lg p-4 lg:p-8">
            <h3 class="mb-2 lg:mb-5 text-black font-medium text-xl lg:text-2xl text-center">Confirmation Screen</h3>
            <div class="w-full mb-4">
                <a href="javascript:void(0)" (click)="ngxSmartModalService.getModal('csvConfirmation').close()"
                    class="rounded-full text-sm font-semibold bg-primary button-buy px-4 py-3 text-white inline-block  ">
                    Go back & edit CSV
                </a>
                
                <a *ngIf="overall_insight>0" href="javascript:void(0)" (click)="ngxSmartModalService.getModal('confirmationPopup').open()"
                    class="rounded-full text-sm font-semibold btn-gradient button-buy px-4 py-3 text-white inline-block ml-2 ">
                    Create event
                </a>
                <span class="px-4" *ngIf="unlimited != 1"><b>Total Insights: {{overall_insight}}</b></span>
            </div>
            <div class="text-center">
                <p *ngIf="(duplicate_event_db.length == event_data.length)" [innerHTML]="htmlval"></p>
            </div>
            <div class="page-content">
                <div class="csv-table w-full">
                    <table
                        class="w-full table-fixed block relative overflow-hidden border border-gray-300 lg:border-0 rounded mb-5 ">
                        <thead class="header-table bg-primary text-white">
                            <tr class="flex flex-col flex-no-wrap lg:table-row mb-2 lg:mb-0">
                                <th
                                    class="w-12 header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">
                                    S.no</th>
                                <th
                                    class="header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2 text-center">
                                    User specified Location name</th>
                                <th
                                    class="header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2 text-center">
                                    User specified Latitude/Longitude values</th>
                                <th
                                    class="w-52 header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2 text-center">
                                    User specified Event dates</th>
                                <th
                                    class="header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2 text-center">
                                    System specified Location name</th>
                                <th
                                    class="header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2 text-center">
                                    Unavailable Weathers / Nat Cat Risks</th>

                            </tr>
                        </thead>
                        <tbody #bodyTable
                            class="block lg:table-row-group flex-1 lg:flex-none bg-white border-gray-300 lg:border-t-0 lg:border rounded rounded-t-none">
                            <tr *ngFor="let event of event_data;let i = index"
                                class="hidden lg:flex flex-col flex-no-wrap border-b border-gray-300 lg:table-row mb-2 lg:mb-0">
                                <td class="w-12 text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">
                                    {{i+1}}
                                </td>
                                <td class="text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2 text-center">
                                    {{event.event_data.user_input_event.locationName}}</td>
                                <td class="text-xs xl:text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">
                                    <p *ngIf="event.event_data.user_input_event.lat" class="mb-0">lat: {{event.event_data.user_input_event.lat}}</p>
                                    <p *ngIf="event.event_data.user_input_event.lon" class="mb-0">lon: {{event.event_data.user_input_event.lon}}</p>
                                <td class="w-52 text-xs xl:text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2 max-w-xxs text-center">
                                    <p class="mb-0">{{event.event_data.user_input_event.startDate}} to {{event.event_data.user_input_event.endDate}}</p>
                                </td>

                                <td
                                    class="text-xs xl:text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2 min-w-16 text-center">
                                    {{event.event_data.locationName}}
                                </td>
                                <td
                                    class="text-xs xl:text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2 min-w-16 text-center">
                                    {{event.event_data.duplicate?'This event already exists in your portfolio':event.no_weathers_data?event.no_weathers_data:event.data_not_for.length>0?event.data_not_for.join(","):'-'}}
                                </td>
                            </tr>
                            <tr class="block lg:hidden border-b border-gray-300">
                                <td class="block text-sm p-3">
                                    <div class="mb-1 text-xs">
                                        S.no: <strong class="ml-3 text-sm">1</strong>
                                    </div>
                                    <div class="mb-1 text-xs">
                                        User specified Location name: <strong class="ml-3 text-sm">
                                            California, USA
                                        </strong>
                                    </div>
                                    <div class="mb-1 text-xs">
                                        User specified Latitude/Longitude values: <strong class="ml-3 text-sm">
                                            lat: 29.7604267/lon: -95.3698028

                                        </strong>
                                    </div>
                                    <div class="mb-1 text-xs">
                                        User specified Event dates: <strong class="ml-3 text-sm">
                                            12/01/2023 to 25/06/2023

                                        </strong>
                                    </div>
                                    <div class="mb-1 text-xs">
                                        System specified Location name: <strong class="ml-3 text-sm">
                                            California, USA

                                        </strong>
                                    </div>
                                    <div class="mb-1 text-xs">
                                        Not Available Weathers / Nat Cat Risks: <strong class="ml-3 text-sm">
                                            Rain, Wind
                                        </strong>
                                    </div>

                            </tr>
                        </tbody>


                    </table>
                </div>
            </div>
        </div>
    </div>
</ngx-smart-modal>
</div>

<!--  -->
<ngx-smart-modal [closable]="false" #confirmationPopup identifier="confirmationPopup">
    <div class="max-w-screen-md mx-auto my-12">

        <div class="bg-white rounded lg:rounded-lg p-4 lg:p-8">
            <h3 class="mb-4 lg:mb-6 text-black font-medium text-xl lg:text-2xl text-center">{{unlimited != 1?"Insight Count":"Event Creation"}} Confirmation</h3>
            <div class="page-content">
                <p class="mb-2 lg:mb-5 text-black-base font-medium text-md lg:text-xl text-center" *ngIf="unlimited != 1">The insight count is <b>{{overall_insight}}</b>. Are you sure you want to create the events?</p>
                <p class="mb-2 lg:mb-5 text-black-base font-medium text-md lg:text-xl text-center" *ngIf="unlimited == 1">Are you sure you want to create the events?</p>

            </div>
            <div class="w-full mt-8 text-right">
                <a href="javascript:void(0)" (click)="confirmationPopup.close()"
                    class="rounded-full text-sm font-semibold bg-primary button-buy px-5 py-3 text-white inline-block  ">
                  Cancel
                </a>
                
                <a href="javascript:void(0)" (click)="create_events()" 
                    class="rounded-full text-sm font-semibold btn-gradient button-buy px-6 py-3 text-white inline-block ml-2 ">
                    Okay
                </a>
            </div>
        </div>
    </div>
</ngx-smart-modal>

<div *ngIf="isError" class="col-12 watchevent">
    <div class="watcheventwrp commonclr">
        <a (click)="closeOverlay();" class="closewatch">
            <img src="../assets/images/close-popup.png" alt="">
        </a>
        <div class="eventcost">
            <h3>Errors have been found with the uploaded file, please resolve these issues and reupload the file.</h3>
        </div>
    </div>
</div>