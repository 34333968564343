<div class="flex-wrap overflow-hidden relative p-4 md:p-6 lg:p-12" [ngClass]="!userdata.termsAndCond?'bg-primary':'mainpage'">
    <app-backgrounds></app-backgrounds>
    <div class="w-full relative z-10">
      <div class="flex flex-wrap -mx-4 lg:-mx-6 justify-between mb-3 md:mb-5 lg:mb-8">
        <div class="px-4 lg:px-6 w-auto lg:w-1/2">
            <h1 class="font-semibold text-xl lg:text-2xl">{{ titleField }}</h1>
        </div>
      </div>
      <div class="lg:mx-auto" [ngClass]="!userdata.termsAndCond?'lg:w-3/4':''">
        <div *ngIf="!userdata.termsAndCond" class="flex flex-wrap -mx-4 lg:-mx-6 justify-between mb-3 md:mb-5 lg:mb-8">
          <div class="px-4 w-full lg:px-6 sm:w-1/2">
            <img [src]="dephiLogo" alt="logo" />
          </div>
          <div class="px-4 w-full lg:px-6 sm:w-1/2 lg:flex flex-row-reverse text-right">
            <button type=""button class="btn-gradient py-2 text-base px-6 text-sm font-light mt-4 sm:mt-0" (click)="signOut(true)">
              Sign Out
            </button>
          </div>
        </div>
        <div class="w-full page-content" [ngClass]="!userdata.termsAndCond?'text-white':''">
          <h4 class="font-semibold mb-1 tex-base md:text-lg">
            SOFTWARE USAGE
          </h4>
          <h5 class="font-semibold mb-1 text-base md:text-base">
            Version: June 2019
          </h5>
          <p class="font-normal mb-1 text-sm md:text-base">
            This Agreement is made between MetSwift Services Limited, a limited
            company registered in England Number 09914375 whose office address
            is: Windsor House, Cornwall Road, Harrogate, HG1 2PW (“MetSwift”); and You or the
            Organisation that you represent.
          </p>
          <h4 class="font-semibold mb-1 tex-base md:text-lg">Definitions</h4>
          <ul class="font-normal mb-2 text-sm md:text-base pl-4">
            <li>
              “You” and “Your” shall mean both you in your personal capacity and
              the organisation that you represent.
            </li>
            <li>“Services” shall mean any of MetSwift product or services.</li>
            <li>
              “Subscription” shall mean the use of any of MetSwift’s Services,
              whether free, trial or paid for.
            </li>
            <li>
              “Documentation” shall be the Services help and support pages on
              MetSwift’s web site.
            </li>
            <li>
              “Customer Data” shall mean any of your data or documents that you
              upload onto the Services.
            </li>
            <li>
              “Price List” shall be the current prices and currencies as
              published on MetSwift’s web site.
            </li>
          </ul>
          <h4 class="font-semibold mb-1 tex-base md:text-lg">
            Representations
          </h4>
          <p class="font-normal mb-1 text-sm md:text-base">
            You represent that you are of legal age to enter into this binding
            agreement, that you do not work for a competitor either as an
            employee or as a subcontractor, that you will only use the
            Subscription for the purposes of evaluating or using the Services
            and that you will not use the Subscription for benchmarking or
            testing purposes or in the pursuit of any legal claim.
          </p>
          <h4 class="font-semibold mb-1 tex-base md:text-lg">
            Use of Services
          </h4>
          <p class="font-normal mb-1 text-sm md:text-base">
            By completing and signing our contract for services, and ticking the
            checkbox to accept these terms, or by using the Services, you are
            agreeing to this contract.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            MetSwift grants you use of the Services providing you have correctly
            and honestly completed a contract for services.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            Each licence sold is based on a single user basis, either as a
            primary or non-primary user representing a company or organisation.
            The use of the licence and the data within our software is not
            redistribution to anyone other than licence holder. The primary
            users have the overriding right to designate who should receive a
            licence for their company or organisation, and to what limit of
            spend they can have within the software. Any non-primary user will
            be able to discuss their spending restrictions with their primary
            user directly, then only after receiving written consent will
            MetSwift be able to change any restriction enforced by the primary
            user.
          </p>
          <h4 class="font-semibold mb-1 tex-base md:text-lg">Supplied Data</h4>
          <p class="font-normal mb-1 text-sm md:text-base">
            MetSwift retain all rights to use the data supplied to our system
            from a third party, for the purposes of providing a service and/or
            improving this service to that specific third party. To use this
            data outside of a reciprocal share of services between MetSwift and
            a third party, this must be agreed in writing and signed by the
            relevant parties.
          </p>
          <h4 class="font-semibold mb-1 tex-base md:text-lg">Our Commitment</h4>
          <p class="font-normal mb-1 text-sm md:text-base">
            MetSwift (‘we’ or ‘us’ or ‘our’) are committed to ensuring the
            security and protection of the personal information that we process,
            and to provide a compliant and consistent approach to data
            protection. We have a robust and effective data protection program
            in place which complies with existing law and abides by the data
            protection principles. However, we recognise our obligations in
            updating and expanding this program to meet the demands of the GDPR
            and the UK’s or other countries’ Data Protection Bills.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            MetSwift is dedicated to safeguarding the personal information under
            our remit and in developing a data protection regime that is
            effective, fit for purpose and demonstrates an understanding of, and
            appreciation of all appropriate Regulations.
          </p>
          <p>Our preparation includes: -</p>
          <ul class="font-normal mb-2 text-sm md:text-base pl-4">
            <li>
              Information Audit - carrying out a company-wide information audit
              to identify and assess what personal information we hold, where it
              comes from, how and why it is processed and if and to whom it is
              disclosed.
            </li>
            <li>
              Policies & Procedures - [revising/implementing new] data
              protection policies and procedures to meet the requirements and
              standards of the GDPR and any relevant data protection laws,
              including: -
            </li>
            <li>
              Data Protection – our main policy and procedure document for data
              protection has been overhauled to meet the standards and
              requirements of the GDPR. Accountability and governance measures
              are in place to ensure that we understand and adequately
              disseminate and evidence our obligations and responsibilities;
              with a dedicated focus on privacy by design and the rights of
              individuals.
            </li>
            <li>
              Data Retention & Erasure – Our retention policy and schedule is
              kept up-to-date to ensure that the ‘data minimisation’ and
              ‘storage limitation’ principles are satisfied and that personal
              information is stored, archived, and destroyed compliantly and
              ethically. We have dedicated erasure procedures in place to meet
              the new ‘Right to Erasure’ obligation and are aware of when this
              and other data subject’s rights apply; along with any exemptions,
              response timeframes and notification responsibilities.
            </li>
            <li>
              Data Breaches – our breach procedures ensure that we have
              safeguards and measures in place to identify, assess, investigate,
              and report any personal data breach at the earliest possible time,
              no later than 72 hours after becoming aware of it. Our procedures
              are robust and have been disseminated to all employees, making
              them aware of the reporting lines and steps to follow.
            </li>
            <li>
              Subject Access Request (SAR) – Our SAR procedures accommodate the
              30-day timeframe for providing the requested information and for
              making this provision free of charge. Our procedures detail how to
              verify the data subject, what steps to take for processing an
              access request, what exemptions apply and a suite of response
              templates to ensure that communications with data subjects are
              compliant, consistent, and adequate.
            </li>
            <li>
              Legal Basis for Processing - we are reviewing all processing
              activities to identify the legal basis for processing and ensuring
              that each basis is appropriate for the activity it relates to.
              Where applicable, we also maintain records of our processing
              activities, ensuring that our obligations under Article 30 of the
              GDPR and Schedule 1 of the Data Protection Bill are satisfied.
            </li>
            <li>
              Privacy Notice/Policy – Our Privacy Notice(s) to complies with the
              GDPR and ensures that all individuals whose personal information
              we process have been informed of why we need it, how it is used,
              what their rights are, to whom the information is disclosed and
              what safeguarding measures are in place to protect their
              information.
            </li>
            <li>
              Obtaining Consent - Our consent mechanisms for obtaining personal
              data ensure that individuals understand what they are providing,
              why and how we use it and giving clear, defined ways to consent to
              us processing their information. We have developed stringent
              processes for recording consent, making sure that we can evidence
              an affirmative opt-in, along with time and date records; and an
              easy to see and access way to withdraw consent at any time.
            </li>
            <li>
              Direct Marketing – Our policies and processes for direct marketing
              include clear opt-in mechanisms for marketing subscriptions; a
              clear notice and method for opting out and providing unsubscribe
              features on all subsequent marketing materials.
            </li>
            <li>
              Data Protection Impact Assessments (DPIA) – where we process
              personal information that is considered high risk, involves large
              scale processing, or includes special category/criminal conviction
              data; we have developed stringent procedures and assessment
              templates for carrying out impact assessments that comply fully
              with the GDPR’s Article 35 requirements. We have implemented
              documentation processes that record each assessment, allow us to
              rate the risk posed by the processing activity and implement
              mitigating measures to reduce the risk posed to the data
              subject(s).
            </li>
            <li>
              Processor Agreements – where we use any third-party to process
              personal information on our behalf (i.e. Payroll, Recruitment,
              Hosting etc.), we have drafted compliant Processor Agreements and
              due diligence procedures for ensuring that they (as well as we),
              meet and understand their/our GDPR obligations. These measures
              include initial and ongoing reviews of the service provided, the
              necessity of the processing activity, the technical and
              organisational measures in place and compliance with the GDPR.
            </li>
            <li>
              Special Categories Data - where we obtain and process any special
              category information, we do so in complete compliance with the
              Article 9 requirements and have high-level encryptions and
              protections on all such data. Special category data is only
              processed where necessary and is only processed where we have
              first identified the appropriate Article 9(2) basis or the Data
              Protection Bill Schedule 1 condition. Where we rely on consent for
              processing, this is explicit and is verified by a signature, with
              the right to modify or remove consent being clearly signposted.
            </li>
          </ul>

          <h4 class="font-semibold mb-1 tex-base md:text-lg">
            Data Subject Rights
          </h4>
          <p class="font-normal mb-1 text-sm md:text-base">
            In addition to the policies and procedures mentioned above that
            ensure individuals can enforce their data protection rights, we
            provide easy to access information via our contract for services or
            website of an individual’s right to access any personal information
            that MetSwift processes about them and to request information about:
            -
          </p>
          <ul class="font-normal mb-2 text-sm md:text-base pl-4">
            <li>What personal data we hold about them.</li>
            <li>The purposes of the processing.</li>

            <li>The categories of personal or client data concerned.</li>
            <li>The recipients to whom the data has/will be disclosed.</li>
            <li>How long we intend to store your personal/client data for.</li>
            <li>
              If we did not collect the data directly from them, information
              about the source.
            </li>
            <li>
              The right to have incomplete or inaccurate data about them
              corrected or completed and the process for requesting this.
            </li>
          </ul>
          <p class="font-normal mb-1 text-sm md:text-base">
            The right to request erasure of personal data (where applicable) or
            to restrict processing in accordance with data protection laws, as
            well as to object to any direct marketing from us and to be informed
            about any automated decision-making that we use.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            The right to lodge a complaint or seek judicial remedy and who to
            contact in such instances.
          </p>

          <h4 class="font-semibold mb-1 tex-base md:text-lg">
            Information Security & Technical and Organisational Measures
          </h4>
          <p class="font-normal mb-1 text-sm md:text-base">
            MetSwift takes the privacy and security of individuals, businesses,
            and their information very seriously and take every reasonable
            measure and precaution to protect and secure the personal or
            business data that we process. We have robust information security
            policies and procedures in place to protect personal information
            from unauthorised access, alteration, disclosure, or destruction and
            have several layers of security measures, including: using
            non-default ports for secure access, smart encrypted access, where
            after 5 failed attempts in 10 minutes a user’s IP address is blocked
            from connecting to our systems for 1 year. All our servers are
            backed up and any password is set up using complex alphanumeric
            password making it extremely difficult to be broken. The passwords
            are systematically changed to further increase the security of our
            systems. Our website is set up using secure socket layers (SSL).
          </p>

          <h4 class="font-semibold mb-1 tex-base md:text-lg">
            GDPR Roles and Company Awareness
          </h4>
          <p class="font-normal mb-1 text-sm md:text-base">
            MetSwift has designated Dave Parratt as our Data Protection Officer
            (DPO) to develop and implement our roadmap for complying with the
            new data protection Regulation. He is responsible for promoting
            awareness of the GDPR across the organisation, assessing our GDPR
            readiness, identifying any gap areas and implementing the new
            policies, procedures, and measures.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            MetSwift understands that continuous employee awareness and
            understanding is vital to the continued compliance of the GDPR and
            have involved our employees in our preparation plans. We review the
            necessity and benefit of employee training programs on this
            important topic.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            If you have any questions about our commitment to GDPR, please
            contact Data Protection Officer (DPO).
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            MetSwift uses best endeavours to keep Customer Data confidential and
            secure.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            MetSwift takes a snapshot backup of Customer Data once a day and
            stores the backup securely off-site.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            MetSwift maintains what is required to replicated the Customer Data
            for a potential switch over to a replacement service, should the
            production system fail.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            Customer Data for European users will be kept within the European
            Community in compliance with the EC Data Directive.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            Once deleted, Customer Data can only be restored on payment of an
            administration fee.
          </p>

          <h4 class="font-semibold mb-1 tex-base md:text-lg">Availability</h4>
          <p class="font-normal mb-1 text-sm md:text-base">
            MetSwift uses its best endeavours to make the Services available
            24/7/365.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            Should the Services need to be suspended for essential maintenance,
            MetSwift will give you fair warning by means of a notice on the
            Services.
          </p>

          <h4 class="font-semibold mb-1 tex-base md:text-lg">
            Email Marketing
          </h4>
          <p class="font-normal mb-1 text-sm md:text-base">
            MetSwift does not use the mass emailing functionality to send
            unsolicited emails in contravention of UK, EC or your own country’s
            laws. MetSwift reserve the right to terminate your ability to send
            mass emails if in MetSwift’s opinion, you are sending them in an
            unsolicited manner.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            MetSwift may delay sending your emails if sufficient numbers of them
            bounce or are marked as spam by the recipient.
          </p>

          <h4 class="font-semibold mb-1 tex-base md:text-lg">Payment</h4>
          <p class="font-normal mb-1 text-sm md:text-base">
            Payment will be made on an invoice basis with varying payment terms,
            all clients will be required to sign a contract of services and
            provide their bank details prior to use of a paid service. MetSwift
            will charge you monthly in accordance with the licence fee based on
            the length of term of your contract and services purchased with the
            software.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            If payment is not received before your renewal date, then you will
            not be able to log on to your system until payment is received.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            Once a payment has been made no refunds will be given for early
            termination of use.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            Pricing and charges will be in accordance with the current price
            list as shown in the MetSwift’s software. MetSwift reserve the right
            to change the prices of products or services.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            MetSwift will give you 30 days’ notice of intended changes to the
            pricing and charges.
          </p>

          <h4 class="font-semibold mb-1 tex-base md:text-lg">Publicity</h4>
          <p class="font-normal mb-1 text-sm md:text-base">
            You will allow your name to published on our web site and other
            marketing materials as a customer ours.
          </p>

          <h4 class="font-semibold mb-1 tex-base md:text-lg">
            Term and Termination
          </h4>
          <p class="font-normal mb-1 text-sm md:text-base">
            This agreement starts on the day you have signed our contract for
            services. For paid Subscriptions this agreement terminates 90 days
            after the Subscription expires. For trial Subscriptions it expires
            at the end of the trial, unless a paid or free Subscription is then
            taken up.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            MetSwift reserves the right to cancel a subscription at any time if
            it believes that you are using the system for any illegal activity,
            or against our terms and conditions, or that the information you
            have given us is false.
          </p>

          <h4 class="font-semibold mb-1 tex-base md:text-lg">
            Limited Warranties
          </h4>
          <p class="font-normal mb-1 text-sm md:text-base">
            MetSwift warrants that it has full power and authority to grant you
            the rights granted herein.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            MetSwift warrants to you that MetSwift owns or is licenced to use
            all intellectual property rights in the Service and Documentation.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            MetSwift shall defend, indemnify and hold you harmless from and
            against any liability under any final judgement or settlement made
            in relation to any proceedings brought against you in the event that
            such proceedings are based on a claim that the Service infringe a
            third party’s intellectual property rights provided that (a) you
            notify MetSwift in writing of the claim immediately after it has
            been made, (b) you turn over to MetSwift exclusive control over the
            defence and settlement of the claim, (c) you provide such assistance
            in defence of the proceeding as MetSwift may reasonably request, at
            MetSwift’s reasonable expense, (d) you make no admission in respect
            of any claim, and (e) you shall use all commercially reasonable
            efforts to mitigate any loss, damage or costs related to the claim.
            MetSwift’s obligations under this sub-clause will not apply to the
            extent that the infringement claim is based on (i) use of the
            Service after MetSwift notifies you to discontinue use due to such a
            claim, allegation or proceeding; (ii) the combining of the Service
            with a non- MetSwift product, data or business process; (iii) use
            of, or access to, the Service or Documentation other than in
            accordance with the terms of this Agreement; or (iv) a non- MetSwift
            employee or representative altering the Service. You will indemnify,
            keep indemnified and hold MetSwift harmless from and against any
            loss, damage, cost, expense, or liability arising from such actions.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            If your use of the Service is, or in MetSwift’s reasonable opinion
            is likely to become, enjoined as a result of a claim of infringement
            of any intellectual property right of any third party, then MetSwift
            shall at its sole option either: (i) procure the continuing right of
            Customer to use the Service; or (ii) replace or modify the Service
            in a functionally equivalent manner so that it no longer infringes;
            and if, despite its commercially reasonable efforts, MetSwift is
            unable to do so, MetSwift will (iii) terminate this Agreement with
            respect to the Service subject to the infringement claim and refund
            to you an amount equal to the subscription paid by you for such
            Service. The remedies provided above are your sole and exclusive
            remedies for any and all potential or actual claims of patent,
            copyright, trade mark or other intellectual property right
            infringement.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            You shall ensure that you obtain all necessary consents relating to
            the possession, operation or use of the Service and all acts
            relating thereto including without limitation appropriate consents
            required under any data protection legislation applicable to you.
            You shall indemnify, keep indemnified and hold harmless MetSwift
            from and against any loss, damage, cost, expense, or liability
            incurred by MetSwift because of your failure to obtain such consents
            or to comply with the obligations set out in this sub-clause.
            MetSwift warrants to you that the Service as delivered by MetSwift
            to you will perform substantially in accordance with the applicable
            specifications set out in the Documentation. If the Service fails to
            comply with the warranty set out in this clause and you provide
            written notice of the non-compliance to MetSwift, then MetSwift’s
            entire liability and your sole remedy for any non-conformance with
            the foregoing warranty shall be for MetSwift to use its reasonable
            endeavours to provide a fix to enable the Service to perform
            substantially in accordance with the applicable specifications set
            out in the Documentation.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            MetSwift does not warrant that the Service will be free from
            viruses, Trojan horses, bugs, omissions, or errors, or that
            deficiencies or defects in the Service will be corrected. The
            warranty in clause above is the sole and exclusive warranty made by
            MetSwift to you in respect of the performance of the Service.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            Except as otherwise expressly provided in this agreement, all other
            warranties, conditions, terms, and undertakings of any kind
            (including without limitation as to quality, performance, or fitness
            for purpose) in relation to the Service, express or implied, whether
            by statute, common law, custom, trade usage, course of dealings or
            otherwise are hereby excluded to the fullest extent permitted by
            law.
          </p>

          <h4 class="font-semibold mb-1 tex-base md:text-lg">
            Limitation of Liability
          </h4>
          <p class="font-normal mb-1 text-sm md:text-base">
            Except in the cases of death or personal injury caused by the
            negligence of MetSwift and loss or damage caused by the fraudulent
            misrepresentation of MetSwift, or in other circumstances where
            liability may not be so limited under applicable laws, the liability
            of MetSwift under or in connection with this agreement, whether
            arising in contract, tort, negligence, breach of statutory duty or
            otherwise, shall in no circumstances exceed the amount paid by you
            to MetSwift under this agreement for the year in which the claim
            arose or £100,000 (One Hundred Thousand British Pounds) whichever is
            the lesser, regardless of the form of action, even if that loss or
            damage was reasonably foreseeable or either party was aware of the
            possibility of that loss or damage arising.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            MetSwift shall not be liable to you in contract, tort, negligence,
            breach of statutory duty or otherwise for any loss, damage, costs or
            expenses of any nature whatsoever incurred or suffered by you of a
            special, indirect or consequential nature including without
            limitation any economic loss or other loss of turnover, profits,
            business, contracts, use, reputation or goodwill or any deletion,
            corruption, destruction, loss or removal of data, or any loss or
            damage suffered by you as a result of a claim brought by a third
            party even if such loss was reasonably foreseeable or one party had
            advised the other of the likelihood of their incurring the same.
          </p>

          <h4 class="font-semibold mb-1 tex-base md:text-lg">Miscellaneous</h4>
          <p class="font-normal mb-1 text-sm md:text-base">
            This Agreement contains the whole agreement and understanding
            between the parties with respect to the subject matter hereof and
            supersedes and replaces all prior or contemporaneous written,
            electronic, or oral agreements, representations, or understandings
            between them. The parties confirm that they have not entered into
            this Agreement on the basis of any representation that is not
            expressly incorporated into this Agreement. However, nothing in this
            Agreement purports to exclude liability for any fraudulent
            misrepresentation or act.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            MetSwift may modify these terms at any time upon notice delivered to
            you by email or on our web site. Should the change substantially
            affect your rights you may terminate the agreement within 30 days.
            You continued use of the Services after being notified of any change
            will be deemed acceptance of those changes.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            MetSwift is an independent contractor and neither MetSwift, nor its
            employees, subcontractors or consultants shall be deemed your
            employees and nothing in this Agreement shall constitute or imply
            any partnership, joint venture, agency, or fiduciary relationship
            between the parties.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            If any provision of this Agreement is prohibited by law or judged by
            a court to be unlawful, void, or unenforceable, the provision shall,
            to the extent required, be severed from the Agreement, and rendered
            ineffective as far as possible without modifying the remaining
            provisions of this Agreement, and shall not in any way affect any
            other circumstances of or the validity or enforcement of this
            Agreement.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            You shall not assign, delegate, subcontract, mortgage, charge or
            otherwise transfer all or any of your rights or obligations under
            this Agreement without the prior written consent of MetSwift, which
            shall not be unreasonably withheld. MetSwift may assign all or any
            of its rights or obligations under this Agreement. MetSwift shall be
            entitled in the normal course of business to use subcontractors or
            consultants for the performance of its obligations under this
            Agreement.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            The headings in this Agreement are inserted for convenience only,
            and do not affect its construction or interpretation.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            The governing language for this Agreement, for the transactions
            contemplated hereby, for any notices instruments or other documents
            or media transmitted or delivered hereunder, and for the negotiation
            and/or resolution of any dispute or other matter between the
            parties, shall be the English language; where a document relating to
            this Agreement is not available in English, an English language
            version shall be provided by you on request. In the event of any
            conflict between the provisions of any document in any other
            language and an English language version thereof, the provisions of
            the English language version shall prevail. You hereby waive all and
            any rights you may have under any law in any country or part thereof
            to have the Agreement written in any language other than English.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            All payments under this Agreement, unless expressly stated
            otherwise, shall be in the nominated currency for your country, as
            published on the current price list.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            No failure or delay by either party in exercising or enforcing its
            rights will prejudice or restrict the rights of that party, and no
            waiver of any such rights or of any breach of any contractual terms
            will be deemed to be a waiver of any other right or of any later
            breach.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            No amendment, waiver or variation of this Agreement shall be binding
            on the parties unless set out in writing, expressed to amend, or
            vary this Agreement and signed by an authorised representative of
            each of the parties.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            The obligations of each party under this Agreement shall be
            suspended during the period, and to the extent, that party is
            prevented or hindered from complying with them by any cause beyond
            its reasonable control including, without limitation, strikes,
            lock-outs, labour disputes, act of God, war, riot, civil commotion,
            terrorism, malicious damage, compliance with any law or governmental
            order, rule, regulation or direction, accident, breakdown of plant
            or machinery, fire, flood, storm, difficulty or increased expense in
            obtaining employees, materials, goods or raw materials in connection
            with the performance of this Agreement. In the event of either party
            being so hindered or prevented, the party concerned shall give
            notice of suspension as soon as reasonably possible to the other
            party stating the date and extent of the suspension and its cause
            and the omission to give such notice shall forfeit the rights of
            that party to claim force majeure. Any party whose obligations have
            been suspended as aforesaid shall resume the performance of those
            obligations as soon as reasonably possible after the removal of the
            cause and shall so notify the other party.
          </p>

          <h4 class="font-semibold mb-1 tex-base md:text-lg">Notices</h4>
          <p class="font-normal mb-1 text-sm md:text-base">
            Unless expressly stated elsewhere in this Agreement, any notice,
            invoice or other communication under this Agreement shall be in
            writing and shall be sent or delivered by hand, or by nationally
            recognised private courier (e.g., Federal Express), or by first
            class mail or air mail, or by fax (confirmed by first class mail or
            air mail), to the address or fax number of MetSwift or you (as
            applicable) set out in this Agreement, or to such other address or
            fax number as MetSwift or you (as applicable) may notify the other
            party.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            Notices sent or delivered as above shall be deemed to have been
            received upon delivery (in the case of delivery by hand or courier),
            upon the third working day after the date of posting (in the case of
            inland first class mail), upon the seventh working day after the
            date of posting (in the case of air mail), and on the next working
            day after the date of the fax (provided that a transmission report
            is generated by the sender’s fax machine recording a message from
            the recipient’s fax machine to the effect that all pages were
            successfully transmitted). This Agreement and performance under it
            shall be governed by and construed in accordance with the laws of
            England and Wales. Any dispute arising under or in connection with
            this Agreement shall be subject to the exclusive jurisdiction of the
            English courts in England to which each party hereby submits.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            If any proceeding is brought by either party to enforce or interpret
            any term or provision of this Agreement, the substantially
            prevailing party in such proceeding shall be entitled to recover, in
            addition to all other relief arising out of this Agreement, such
            party’s reasonable lawyer’s and other experts’ (including without
            limitation accountants) fees and expenses.
          </p>
          <p class="font-normal mb-1 text-sm md:text-base">
            The Contracts (Rights of Third Parties) Act 1999 shall not apply to
            this Agreement and nothing in this Agreement confers or purports to
            confer on any third party any benefit or any right to enforce any
            term of this Agreement or operates to give any third party the right
            to enforce any term of this Agreement except as expressly provided
            herein.
          </p>
        </div>
        <div class="w-full">
          <address class="text-center pt-8 text-orange-base font-normal mb-1 text-sm md:text-base not-italic">
            No. 1 Aire Street, Leeds, LS1 4PR<br />
            Co. No. 09914375<br />
            UK: +44 (0) 207 846 3277<br />

            E: info@metswift.com<br />
            www.metswift.com
          </address>
          <div class="AcceptDecline text-white">
            <input type="radio" id="accept" name="termcondition" value="1" (click)="acceptTerm()" class="" />
            <label for="accept" class="text-white"><span></span> Accept</label>

            <input type="radio" id="decline" name="termcondition" value="0" (click)="declineTerm()" class="" />
            <label for="decline" class="text-white"><span></span> Decline</label>
          </div>
        </div>
      </div>
    </div>
    </div>
  <ngx-smart-modal [closable]="false" #termDecline identifier="termDecline">
    <div class="w-full max-w-md mx-auto">
      <div class="p-6 overlay-color rounded">
        <h3 class="mb-8 text-sm text-white font-normal md:text-lg text-center">
          You need to accept the agreement to proceed. Are you sure of declining
          the agreement?
        </h3>
        <div class="w-full text-center">
          <button (click)="termNotAccept()" type="submit"
            class="rounded-full h-10 px-8 mr-2 bg-white text-primarylighter text-sm font-bold text-center">
            Ok
          </button>
          <button (click)="closeTermOverlay()" type="button"
            class="rounded-full h-10 px-8 bg-white text-primarylighter text-sm font-bold text-center">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </ngx-smart-modal>
