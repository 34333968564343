import { Component, OnInit, Input } from '@angular/core';
import { EventEmitter } from 'events';

@Component({
  selector: 'app-results-numeric-values',
  templateUrl: './results-numeric-values.component.html',
  styleUrls: ['./results-numeric-values.component.scss']
})
export class ResultsNumericValuesComponent implements OnInit {

  @Input() overviewRiskdata:any;
  @Input() riskName:any;
  @Input() riskMode;

  constructor() { }

  ngOnInit(): void {
  }

}
