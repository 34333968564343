<div [ngClass]="riskName" class="bg-white p-6 border border-gray-300 rounded-lg mb-4">
    <div class="w-full">
        <div class="block">
            <div class="flex flex-wrap items-center -mx-4">

                <div *ngFor="let cat of overviewRiskdata" class="elem-count flex-auto min-w-32 px-2">
                    <div class="text-center w-full">
                        <span class="block text-xs whitespace-nowrap lg:text-sm leading-snug text-primary py-1 px-3 rounded-full mb-2 bg-label">{{cat.title}}</span>
                        <span *ngIf="riskMode == 'Percentage'" class="text-value text-risk-high font-semibold text-3xl block leading-snug">{{ ( cat.value < 0.005 && cat.value != 0 ) ? '<0.01' : cat.value.toFixed(2) }}%</span>
                        <span *ngIf="riskMode == 'Count'" class="text-value text-risk-high font-semibold text-3xl block leading-snug">{{ cat.value }}</span>
                    </div>
                </div>

                <!-- <div class="elem-count flex-1 min-w-32 px-4">
                    <div class="text-center">
                        <span class="block text-sm leading-snug text-primary py-1 px-3 rounded-full mb-2 bg-label">Cat 1 Hu</span>
                        <span class="text-value text-hurricane-high font-semibold text-3xl block leading-snug">3</span>
                    </div>
                </div>

                <div class="elem-count flex-1 min-w-32 px-4">
                    <div class="text-center">
                        <span class="block text-sm leading-snug text-primary py-1 px-3 rounded-full mb-2 bg-label">Cat 2 Hu</span>
                        <span class="text-value text-hurricane-high font-semibold text-3xl block leading-snug">4</span>
                    </div>
                </div>

                <div class="elem-count flex-1 min-w-32 px-4">
                    <div class="text-center">
                        <span class="block text-sm leading-snug text-primary py-1 px-3 rounded-full mb-2 bg-label">Cat 3 Hu</span>
                        <span class="text-value text-hurricane-high font-semibold text-3xl block leading-snug">4</span>
                    </div>
                </div>

                <div class="elem-count flex-1 min-w-32 px-4">
                    <div class="text-center">
                        <span class="block text-sm leading-snug text-primary py-1 px-3 rounded-full mb-2 bg-label">Cat 4 Hu</span>
                        <span class="text-value text-hurricane-high font-semibold text-3xl block leading-snug">6</span>
                    </div>
                </div>

                <div class="elem-count flex-1 min-w-32 px-4">
                    <div class="text-center">
                        <span class="block text-sm leading-snug text-primary py-1 px-3 rounded-full mb-2 bg-label">Cat 5 Hu</span>
                        <span class="text-value text-hurricane-high font-semibold text-3xl block leading-snug">3</span>
                    </div>
                </div> -->


            </div>
        </div>


    </div>
</div>
