<div class="flex flex-wrap relative">
    <div class="p-4 md:p-6 lg:p-12 flex-1">
        <app-backgrounds></app-backgrounds>
        <div class="w-full relative z-10">


            <div class="flex flex-wrap -mx-4 lg:-mx-6">
                <div class="w-full px-6">
                    <!-- <app-results-vertical-bars-stack class="w-full mb-4 block"></app-results-vertical-bars-stack> -->
                    <app-results-vertical-bars class="w-full mb-4 block"></app-results-vertical-bars>
                    <!-- <app-results-numeric-value class="w-full mb-4 block"></app-results-numeric-value>
                    <app-results-numeric-values class="w-full mb-4 block"></app-results-numeric-values> -->
                    <!-- <app-results-table-advanced class="w-full mb-4 block"></app-results-table-advanced> -->
                    <!-- <app-results-graph-plot class="w-full mb-4 block"></app-results-graph-plot> -->
                    <!-- <app-results-map class="w-full mb-4 block"></app-results-map> -->
                </div>

            </div>

        </div>
    </div>
    <div class="sidebar-report bg-gray-200 rounded-tl-xl min-h-screen w-full lg:w-2/5">

    </div>

</div>





