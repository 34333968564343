import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, switchMap, mergeMap, catchError, tap, take, exhaustMap, delay } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from '../../services/auth.service';
import {
  AuthActionTypes,
  LogIn, LogInSuccess, LogInFailure,
  LogOut,
  AcceptTerm,
  AcceptTermSuccess,
  ChangeSector,
  ChangeSectorSuccess
} from '../actions/auth.actions';
import { LogoutService } from 'src/app/services/logout.service';
import { LoaderService } from 'src/app/services/loader.service';



@Injectable()
export class AuthEffects {

  constructor(
    private actions: Actions,
    private authService: AuthService,
    private router: Router,
    private userService:UserService,
    private LS:LogoutService,
    private loader:LoaderService,
  ) {
  }


  @Effect()
  LogIn: Observable<any> = this.actions.pipe(
        ofType(AuthActionTypes.LOGIN),
        mergeMap((action: LogIn) =>
          this.authService.logIn(action.payload).pipe(
            map( res => new LogInSuccess({ ...res.data.user }) ),
            catchError( error => of(new LogInFailure({ errorMessage: error.error.message })) )
          )
        )
  );

  @Effect({ dispatch: false })
  LogInSuccess: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_SUCCESS),
    tap((user) => {
      // localStorage.setItem('token', user.payload.token);
      this.LS.logOut({ title: "Login", link: "#/log-in" })
              .subscribe(
                data => {
                },
                error => {
                }
              );
      if(!user.payload.termsAndCond){
        this.router.navigateByUrl('/termandcondition');
      } else if(!user.payload.setpassword){
        this.router.navigateByUrl('/setpassword');
      }else{
        this.router.navigateByUrl('/dashboard');
      }
      
    })
  );

  @Effect({ dispatch: false })
  LogInFailure: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_FAILURE)
  );

  @Effect({ dispatch: false })
  public LogOut: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGOUT),
    tap((user) => {
      // localStorage.removeItem('token');
    })
  );

  @Effect()
  AcceptTerm: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.ACCEPT_TERM),
    exhaustMap((action: AcceptTerm) => 
      this.userService.termsAndCondition({'terms_condition': true}).pipe(
        map( res => new AcceptTermSuccess({ ...action.payload }) ),
        take(1)
      )
    )
  );

  @Effect({ dispatch: false })
  AcceptTermSuccess: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.ACCEPT_TERM_SUCCESS),
    tap((user) => {
        console.log(user)
        this.router.navigateByUrl('/setpassword');      
    })
  );

  @Effect()
  ChangeSector: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.CHANGE_SECTOR),
    exhaustMap((action: ChangeSector) => 
      this.userService.changesector({'changesector': action.payload.sector}).pipe(
        map( res => new ChangeSectorSuccess({...action.payload}) ),
        take(1)
      )
    )
  );

  @Effect({ dispatch: false })
  ChangeSectorSuccess: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.CHANGE_SECTOR_SUCCESS),
    tap((user) => {
        console.log(user)
        this.loader.display(false)      
    })
  );

  @Effect({ dispatch: false })
  GetStatus: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.GET_STATUS),
    switchMap(payload => this.authService.getStatus())
  );



}
