<div class="border border-gray-300 rounded lg:rounded-lg px-3 py-3 lg:px-8 lg:py-4 w-full bg-transparent bg-white">
    <div id="graph-plot" class="w-full h-full"></div>
    <div class="flex justify-center w-full mt-4">
        <ul class="pager flex flex-wrap items-center justify-center">

            <li (click)="previous()" class="activeprevious mr-3">
                <a class="border cursor-pointer border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700">
                    <svg class="w-3 h-3 text-gray-600 fill-current stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polyline fill="none" stroke-miterlimit="10" points="16,1 8,12 16,23 "></polyline></g></svg>
                </a>
            </li>
            <li (click)="next()" class="activenext ml-3">
                <a class="border cursor-pointer border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-600 fill-current stroke-current" viewBox="0 0 24 24"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polyline fill="none" stroke-miterlimit="10" points="8,1 16,12 8,23 "></polyline></g></svg>
                </a>
            </li>
        </ul>
    </div>
    <div class="flex flex-wrap -mx-2 font-semibold mt-6">
        <div class="w-full sm:w-auto sm:flex-1 px-2 mb-1 sm:mb-0">
            <app-badge-result [innerClass]="fetchedpredictData.lowClass">
                <div class="font-light">
                    Lowest
                </div>
                <div><span class="text-sm">{{fetchedpredictData.lowestTable}}</span><span class="text-sm" [innerHTML]="fetchedpredictData.unit"></span></div>
            </app-badge-result>

        </div>
        <div class="w-full sm:w-auto sm:flex-1 px-2 mb-1 sm:mb-0">
            <app-badge-result [innerClass]="fetchedpredictData.meanClass">
                <div class="font-light">
                    Mean
                </div>
                <div><span class="text-sm">{{fetchedpredictData.meanTable}}</span><span class="text-sm" [innerHTML]="fetchedpredictData.unit"></span></div>
            </app-badge-result>
        </div>
        <div class="w-full sm:w-auto sm:flex-1 px-2 mb-1 sm:mb-0">
            <app-badge-result [innerClass]="fetchedpredictData.highClass">
                <div class="font-light">
                    Highest
                </div>
                <div><span class="text-sm">{{fetchedpredictData.highestTable}}</span><span class="text-sm" [innerHTML]="fetchedpredictData.unit"></span></div>
            </app-badge-result>
        </div>
    </div>
    <div class="text-center text-gray-600 text-sm mt-4">
        Last updated on {{fetchedpredictData.lastUpdatedDate}}
    </div>
</div>
