import { Component, OnInit, ViewChildren, QueryList, ElementRef, AfterViewInit, Input, EventEmitter, Output, OnChanges, ViewChild } from '@angular/core';
import * as map from 'lodash/map';
import * as moment from 'moment';
import Litepicker from 'src/app/litepicker2';
import tippy, {followCursor} from 'tippy.js';

@Component({
  selector: 'app-results-table-data',
  templateUrl: './results-table-data.component.html',
  styleUrls: ['./results-table-data.component.scss']
})
export class ResultsTableDataComponent implements OnInit, AfterViewInit,OnChanges {
    
    @Input() tableData :any;
    @Input() graphpage:number;
    @Input() shownext:any;
    @Input() avarageData:any;
    @Input() weatherid:any;
    @Input() hourlytype:any;
    @Output() changegraphpage = new EventEmitter<string>();
    rows: any = [];
    @Output() selectDate = new EventEmitter<string>()
    @ViewChild('dateStartval', {static: false}) dateStartInputval: ElementRef;
    @ViewChild('containerDates', {static: false}) containerDates: ElementRef;
    @ViewChildren('tooltipYearsModElem') tooltipYearsModElem:QueryList<ElementRef>;
    @ViewChildren('tooltipYearsSevElem') tooltipYearsSevElem:QueryList<ElementRef>;
    date =''
    @Input() searchState:any;   
    datepickerInput: any = false;
    constructor() { }

    ngOnInit(): void {
       
    }
    nextpage() {
        if(this.shownext){
            this.changegraphpage.emit('next');
        }
        
    }
    prevpage() {
        if(this.graphpage > 1){
            this.changegraphpage.emit('prev');
        }    
    }
    ngAfterViewInit(){
        setTimeout(() => {
            if(!this.datepickerInput){
                const app = this
                this.datepickerInput = new Litepicker({
                    parentEl: this.containerDates.nativeElement,
                    element: this.dateStartInputval.nativeElement,
                    allowRepick: false,
                    singleMode: true,
                    minDate: moment(app.searchState.datestart, 'DD/MM/YYYY').toDate(),
                    maxDate: moment(app.searchState.dateend, 'DD/MM/YYYY').toDate(),
                    scrollToDate: true,
                    isCapped: true,
                    setup: (picker) => {
                    picker.on('selected',(start, end) => {
                        let dateval = start.getDate()+'/'+(start.getMonth()+1)+'/'+start.getFullYear()
                        app.selectDate.emit(dateval)
                    })
                  },
                })
            }},1000)
        setTimeout(()=>{
        map(this.tooltipYearsModElem.toArray(), item => {
            tippy(item.nativeElement, item.nativeElement.tooltipYearsMod)
        })
        map(this.tooltipYearsSevElem.toArray(), item => {
            tippy(item.nativeElement, item.nativeElement.tooltipYearsSev)
        })},2000);
        
    }
    ngOnChanges() {
        this.rows=[];
        this.date = this.tableData[0]['dateval']
        map(this.tableData, item => {
            if(this.weatherid != 5 && this.weatherid != 6 && this.weatherid != 7 && this.weatherid != 8){
                const itemData = {
                    checkdata: item.alertPercentage,
                    date: item.date,
                    chance: {
                        mod: item.alertPercentage ? item.alertPercentage + '%' : '0',
                        sev: item.severePercentage ? item.severePercentage + '%' : '0',
                    },
                    count: {
                        mod: item.alertCount,
                        sev: item.severeCount,
                    },
                    years: {
                        mod: item.alertYears ? item.alertYears : 'N/A',
                        sev: item.severeYears ? item.severeYears : 'N/A',
                    },
                    risktitle: null,
                    risksummary: null,
                    risksummaryClass: '',
                    tooltipYearsMod: {
                        allowHTML: true,
                        arrow: true,
                        animation: 'shift-away',
                        content: `
                            <div class="block text-center">
                                <div class="font-semibold mb-2">Statistical Years</div>
                                <div class="font-semibold text-sm text-yellow-dark break-words">${item.alertYears}</div>
                            </div>
                        `,
                    },
                    tooltipYearsSev: {
                        allowHTML: true,
                        animation: 'shift-away',
                        arrow: true,
                        content: `
                            <div class="block text-center">
                                <div class="font-semibold mb-2">Statistical Years</div>
                                <div class="font-semibold text-sm text-orange-dark break-words">${item.severeYears}</div>
                            </div>
                        `,
                    },
                }
                if(item.severePercentage > 0){
                    itemData.risktitle = 'Threshold 2'
                    itemData.risksummary = item.severePercentage + '%'
                    itemData.risksummaryClass = 'text-orange-dark'
                }
                else if(item.alertPercentage > 0){
                    itemData.risktitle = 'Threshold 1'
                    itemData.risksummary = item.alertPercentage + '%'
                    itemData.risksummaryClass = 'text-yellow-dark'
                } else {
                    itemData.risksummary = 'No risk'
                    itemData.risksummaryClass = 'text-green-light'
                }
                this.rows.push(itemData)
            
            } else{
                const itemData = {
                    date: item.date,
                    checkdata: item.extremePercentage,
                    chance: {
                        sev: item.extremePercentage ? item.extremePercentage + '%' : '0'
                    },
                    count: {
                        sev: (item.extremeYears != '') ? item.extremeYears.split(',').length : 0,
                    },
                    years: {
                        sev: item.extremeYears ? item.extremeYears : 'N/A'
                    },
                    risksummary: null,
                    risksummaryClass: '',
                    tooltipYearsSev: {
                        allowHTML: true,
                        animation: 'shift-away',
                        arrow: true,
                        content: `
                            <div class="block text-center">
                                <div class="font-semibold mb-2">Statistical Years</div>
                                <div class="font-semibold text-sm text-orange-dark break-words">${item.extremeYears}</div>
                            </div>
                        `,
                    },
                    
                }
                
                
                this.rows.push(itemData)
            }
        })
            
    } 
}
