<div class="flex flex-wrap relative">
    <div class="bg-gray-200 xl:rounded-tl-xl xl:order-2 xl:min-h-screen flex items-start w-full xl:w-2/5 relative z-10">
        <div class="py-6 px-3 lg:px-6 xl:py-12 xl:px-12 w-full text-center xl:min-h-screen sticky top-0 flex-col"
            *ngIf="profileData">

            <h3 class="text-2xl font-semibold mb-4">My profile</h3>


            <form [formGroup]="form">
                <!-- <div class="form-group">
                  <label for="avatar">
                    <span>
                      <i class="fas fa-camera-retro"></i>
                    </span>
                    <input type="file" form-control="avatar" id="avatar" accept="image/jpeg,image/png,image/jpg" size="60" (change)="onFileChange($event)" #fileInput>
                  </label>
                  <a *ngIf="isImage" href="javascript:void(0)" class="clearprofile" (click)="clearFile()">
                    <i class="far fa-times-circle"></i>
                  </a>
                </div> -->

                <div *ngIf="!isImage" class="relative avatar-img-container account">
                    <div class="avatar-img account bg-transparent border border-white flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg"
                            class="stroke-current fill-current text-gray-700 w-5 h-5" viewBox="0 0 12 12">
                            <g stroke-width="1">
                                <circle cx="6" cy="3" r="2.5" fill="none" stroke-linecap="round"
                                    stroke-linejoin="round"></circle>
                                <path d="M6,7.5c-3.038,0-4.5,1.895-4.5,3v1h9v-1C10.5,9.4,9.038,7.5,6,7.5Z" fill="none"
                                    stroke-linecap="round" stroke-linejoin="round"></path>
                            </g>
                        </svg>
                    </div>
                    <div class="absolute -mb-2 bottom-0 left-0 right-0 flex justify-center">
                        <label class="flex items-center cursor-pointer mx-2 bg-white rounded-full w-5 h-5 shadow-lg"
                            for="avatar">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                class="w-2 h-2 mx-auto fill-current stroke-current text-primary" viewBox="0 0 12 12">
                                <g stroke-width="1">
                                    <polyline points="2 4.5 6 0.5 10 4.5" fill="none" stroke-linecap="round"
                                        stroke-linejoin="round"></polyline>
                                    <line x1="6" y1="0.5" x2="6" y2="9.5" fill="none" stroke-linecap="round"
                                        stroke-linejoin="round"></line>
                                    <line x1="0.5" y1="11.5" x2="11.5" y2="11.5" fill="none" stroke-linecap="round"
                                        stroke-linejoin="round"></line>
                                </g>
                            </svg>
                            <input type="file" form-control="avatar" style="opacity: 0;display: none;" id="avatar"
                                accept="image/jpeg,image/png,image/jpg" size="60" (change)="onFileChange($event)"
                                #fileInput>
                        </label>

                    </div>
                </div>


                <div *ngIf="isImage" class="relative avatar-img-container account">
                    <img [src]="image" class="avatar-img account" />
                    <div class="absolute -mb-2 bottom-0 left-0 right-0 flex justify-center">
                        <label class="flex items-center cursor-pointer mx-2 bg-white rounded-full w-5 h-5 shadow-lg"
                            for="avatar">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                class="w-2 h-2 mx-auto fill-current stroke-current text-primary" viewBox="0 0 12 12">
                                <g>
                                    <path
                                        d="M4.707,8.707l6-6a1,1,0,0,0,0-1.414l-1-1a1,1,0,0,0-1.414,0l-6,6a1,1,0,0,0-.188.26L1.293,9.707,4.447,8.9A1,1,0,0,0,4.707,8.707Z">
                                    </path>
                                    <path d="M11.5,11H.5a.5.5,0,0,0,0,1h11a.5.5,0,0,0,0-1Z"></path>
                                </g>
                            </svg>
                            <input type="file" form-control="avatar" style="opacity: 0;display: none;" id="avatar"
                                accept="image/jpeg,image/png,image/jpg" size="60" (change)="onFileChange($event)"
                                #fileInput>
                        </label>
                        <button class="mx-2 bg-white rounded-full w-5 h-5 shadow-lg" (click)="clearFile()">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                class="w-2 h-2 mx-auto fill-current stroke-current text-primary" viewBox="0 0 12 12">
                                <g>
                                    <path
                                        d="M10.707,1.293a1,1,0,0,0-1.414,0L6,4.586,2.707,1.293A1,1,0,0,0,1.293,2.707L4.586,6,1.293,9.293a1,1,0,1,0,1.414,1.414L6,7.414l3.293,3.293a1,1,0,0,0,1.414-1.414L7.414,6l3.293-3.293A1,1,0,0,0,10.707,1.293Z">
                                    </path>
                                </g>
                            </svg>
                        </button>
                    </div>
                </div>
            </form>


            <div class="my-2">
                <strong class="font-light mr-3 text-sm">First name</strong>
                <span class="text-sm font-semibold">{{profileData.firstName}}</span>
            </div>
            <div class="my-2">
                <strong class="font-light mr-3 text-sm">Last Name</strong>
                <span class="text-sm font-semibold">{{profileData.lastName}}</span>
            </div>
            <div class="my-2">
                <strong class="font-light mr-3 text-sm">Email</strong>
                <span class="text-sm font-semibold">{{profileData.email}}</span>
            </div>
            <div class="my-2">
                <strong class="font-light mr-3 text-sm">Company</strong>
                <span class="text-sm font-semibold">{{profileData.organization}}</span>
            </div>
            <div class="my-2">
                <strong class="font-light mr-3 text-sm">Main Company Contact No</strong>
                <span class="text-sm font-semibold">{{profileData.companyNo}}</span>
            </div>
            <div class="my-2">
                <strong class="font-light mr-3 text-sm">Contact No</strong>
                <span class="text-sm font-semibold">{{profileData.contactNo}}</span>
            </div>
            <div class="my-2">
                <strong class="font-light mr-3 text-sm">Company Email</strong>
                <span class="text-sm font-semibold">{{profileData.companyEmail}}</span>
            </div>
            <div class="my-2">
                <strong class="font-light mr-3 text-sm">Sector</strong>
                <span class="text-sm font-semibold">{{profileData.sector}}</span>
            </div>
            <div class="my-2">
                <strong class="font-light mr-3 text-sm">Trial</strong>
                <span *ngIf="profileData.isTrail == 1" class="text-sm font-semibold">Yes</span>
                <span *ngIf="profileData.isTrail == 0" class="text-sm font-semibold">No</span>

            </div>
            
            <div class="w-auto mt-6" *ngIf="profileData.changesector && (profileData.sector == 'Insurance' || profileData.sector == 'Construction') ">
                <h5 class="text-primary text-sm font-semibold mb-3">Select My Sector</h5>
                <app-button-toggle (toggle)="ngxSmartModalService.getModal('changeSector').open()"  [dataActive]='profileData.sector == "Insurance"' class="ml-2 w-auto">Insurance</app-button-toggle>
                <app-button-toggle (toggle)="ngxSmartModalService.getModal('changeSector').open()"  [dataActive]='profileData.sector == "Construction"' class="ml-2 w-auto">Construction</app-button-toggle>
            </div>
            
            <div class="mt-6 max-w-xs mx-auto">
                <a (click)="ngxSmartModalService.getModal('changePassword').open()" class="w-full text-center cursor-pointer">
                    <span class="block btn-gradient">Change my password</span>
                </a>
            </div>
            
        </div>
    </div>
    <div class="p-4 md:p-6 lg:p-12 flex-1 xl:order-1 min-w-0">
        <app-backgrounds></app-backgrounds>
        <div class="w-full relative z-10">
            
            <div class="flex flex-wrap -mx-4 lg:-mx-6 justify-between mb-2 md:mb-3 lg:mb-4">
                <div class="px-4 lg:px-6 w-auto">
                    <h1 class="font-semibold text-xl lg:text-2xl mb-2">{{ titleField }}</h1>
                </div>
                <div class="px-4 lg:px-6 flex flex-wrap items-center">
                    <app-search-reports-button></app-search-reports-button>
                </div>
            </div>
            <div class="flex flex-wrap justify-between mb-2 md:mb-2 lg:mb-3">
                <div *ngIf="showDownload" class="dwnPurchase">
                    <button type="button" (click)="downloadsQuote()" class="block btn-gradient btn-quote mb-2">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-flex" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                        </svg> Quotes</button>
                </div>
               
            </div>
            <div class="flex flex-wrap">

                <div class="text-xs xl:text-sm w-full">


                    <table
                        class="w-full flex flex-row flex-no-wrap relative overflow-hidden border border-gray-300 lg:border-0 rounded mb-5">
                        <thead class="header-table bg-primary text-white">
                            <tr *ngFor="let i of [].constructor(10)"
                                class="flex flex-col flex-no-wrap lg:table-row mb-2 lg:mb-0">
                                <th
                                    class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1 lg:py-2">
                                    Order Id</th>
                                <th
                                    class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1 lg:py-2">
                                    Contract Id</th>
                                <th
                                    class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1 lg:py-2">
                                    Model Name</th>
                                <th
                                    class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1 lg:py-2">
                                    Period</th>
                                <th
                                    class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1 lg:py-2">
                                    Purchase Date</th>
                                <th
                                    class="header-table__elem text-left text-white font-light border-r border-primary px-3 py-1 lg:py-2">
                                    Amount</th>
                            </tr>
                        </thead>
                        <tbody #bodyTable
                            class="flex-1 lg:flex-none bg-white border-gray-300 lg:border-t-0 lg:border rounded rounded-t-none">
                            <tr>
                                <td colspan="7" *ngIf="myOrders.length==0">No data found</td>
                            </tr>
                            <ng-template ngFor let-element [ngForOf]="myOrders" let-index="index">
                                <tr [attr.data-header-index]="index" (click)="rowClick(index,element)"
                                    class="expanded-header cursor-pointer hover:bg-gray-200 flex flex-col flex-no-wrap border-b border-gray-300 lg:table-row mb-2 lg:mb-0">
                                    <td class="border-r border-gray-300 px-1 py-1 lg:py-2">
                                        {{element.orderId}}
                                    </td>
                                    <td
                                        class="w-sm text-xs xl:text-sm border-r border-gray-300 px-1 py-1 lg:py-2 max-w-xxs">
                                        {{element.contractId}}</td>
                                    <td
                                        class="text-xs xl:text-sm border-r border-gray-300 px-1 py-1 lg:py-2 font-semibold">
                                        {{element.modelType}} {{element.modelName}}</td>
                                    <td class="min-w-xxs text-xs xl:text-sm border-r border-gray-300 px-1 py-1 lg:py-2">
                                        {{element.startDate|txtMonthToNum}} - {{element.endDate|txtMonthToNum}}</td>
                                    <td class="text-xs xl:text-sm border-r border-gray-300 px-1 py-1 lg:py-2">
                                        {{element.purchaseDate|txtMonthToNum}}</td>
                                    <td class="text-xs xl:text-sm border-r border-gray-300 px-1 py-1 lg:py-2">
                                        <div class="flex justify-center items-center">
                                            <ng-container
                                                *ngIf="isTrialUser == true then hideAmtForNonTrialUserTemplate; else showAmtForNonTrialUserTemplate">
                                            </ng-container>
                                            <ng-template #hideAmtForNonTrialUserTemplate>
                                    <td>NA</td>
                            </ng-template>
                            <ng-template #showAmtForNonTrialUserTemplate>
                                <span class="inline-block align-text-top">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path fill-rule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-14a3 3 0 00-3 3v2H7a1 1 0 000 2h1v1a1 1 0 01-1 1 1 1 0 100 2h6a1 1 0 100-2H9.83c.11-.313.17-.65.17-1v-1h1a1 1 0 100-2h-1V7a1 1 0 112 0 1 1 0 102 0 3 3 0 00-3-3z"
                                            clip-rule="evenodd" />
                                    </svg></span> {{element.amount}}
                            </ng-template>
                            <!-- <button type="button" class="w-6 h-6 bg-orange-base hover:bg-orange-dark text-white rounded-full inline-flex ml-3 items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="fill-current stroke-current mx-auto" width="10" height="10" viewBox="0 0 12 12"><g stroke-width="1"><line x1="6" y1="1" x2="6" y2="11" fill="none" stroke-linecap="round" stroke-linejoin="round"></line> <line x1="1" y1="6" x2="11" y2="6" fill="none" stroke-linecap="round" stroke-linejoin="round"></line></g></svg>
                                            </button> -->
                </div>
                </td>

                </tr>
                <tr [attr.data-index]="index"
                    class="expanded-row border-b border-gray-300 lg:bg-gray-100 block lg:table-row mb-2 lg:mb-0">
                    <td colspan="6" class="lg:border-r border-gray-300 p-3">
                        <div class="mb-1 text-xs">
                            Order Id <strong class="ml-3 text-sm">{{element.orderId}}</strong>
                        </div>
                        <div class="mb-1 text-xs">
                            Contract Id <strong class="ml-3 text-sm"> {{element.contractId}}</strong>
                        </div>
                        <div class="mb-1 text-xs">
                            Model Name <strong class="ml-3 text-sm">{{element.modelType}} {{element.modelName}}</strong>
                        </div>
                        <div class="mb-1 text-xs">
                            Period <strong class="ml-3 text-sm">{{element.startDate | txtMonthToNum}} -
                                {{element.endDate|txtMonthToNum}}</strong>
                        </div>
                        <div class="mb-1 text-xs">
                            Purchase Date <strong class="ml-3 text-sm">{{element.purchaseDate|txtMonthToNum}}</strong>
                        </div>
                        <div class="lg:mb-1 text-xs">
                            Amount <strong class="ml-3 text-sm">
                                <ng-container
                                    *ngIf="isTrialUser == true then hideAmtForNonTrialUserTemplate; else showAmtForNonTrialUserTemplate">
                                </ng-container>
                                <ng-template #hideAmtForNonTrialUserTemplate>
                    <td>NA</td>
                    </ng-template>
                    <ng-template #showAmtForNonTrialUserTemplate>
                        <span class="inline-block align-text-top">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20"
                                fill="currentColor">
                                <path fill-rule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-14a3 3 0 00-3 3v2H7a1 1 0 000 2h1v1a1 1 0 01-1 1 1 1 0 100 2h6a1 1 0 100-2H9.83c.11-.313.17-.65.17-1v-1h1a1 1 0 100-2h-1V7a1 1 0 112 0 1 1 0 102 0 3 3 0 00-3-3z"
                                    clip-rule="evenodd" />
                            </svg></span> {{element.amount}}
                    </ng-template>
                    </strong>
            </div>
            </td>
            </tr>
            </ng-template>

            </tbody>

            <tfoot class="footer-table bg-primary text-white" *ngIf="myOrders.length !=0">
                <tr class="flex flex-col flex-no-wrap lg:table-row mb-2 lg:mb-0">
                    <th
                        class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1 lg:py-2">
                        Order Id</th>
                    <th
                        class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1 lg:py-2">
                        Contract Id</th>
                    <th
                        class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1 lg:py-2">
                        Model Name</th>
                    <th
                        class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1 lg:py-2">
                        Period</th>
                    <th
                        class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1 lg:py-2">
                        Purchase Date</th>
                    <th
                        class="footer-table__elem text-left text-white font-light border-r border-primary px-3 py-1 lg:py-2">
                        Amount</th>
                </tr>
            </tfoot>
            </table>




        </div>

        <div class="flex justify-center w-full">
            <ul class="pager flex flex-wrap items-center justify-center" *ngIf="numPages>0">

                <li class="{{currentPage==1?'inactiveprevious':'activeprevious'}} mr-3">
                    <a (click)="previous()" href="javascript:void(0)"
                        class="border border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700">
                        <svg class="w-3 h-3 text-gray-600 fill-current stroke-current"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <g stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                                <polyline fill="none" stroke-miterlimit="10" points="16,1 8,12 16,23 "></polyline>
                            </g>
                        </svg>
                    </a>
                </li>
                <!-- <li class="mx-1">
                            <a class="block p-2 opacity-75 cursor-default">1</a>
                        </li>
                        <li class="mx-1">
                            <a class="block p-2 font-semibold hover:text-gray-600">2</a>
                        </li>
                        <li class="mx-1">
                            <a class="block p-2 font-semibold hover:text-gray-600">3</a>
                        </li>
                        <li class="mx-1">
                            <a class="block p-2 font-semibold hover:text-gray-600">4</a>
                        </li>
                        <li class="mx-1">
                            <a class="block p-2 font-semibold hover:text-gray-600">5</a>
                        </li>
                        <li class="mx-1">
                            <a class="block p-2 font-semibold hover:text-gray-600">6</a>
                        </li>
                        <li class="mx-1">
                            <a class="block p-2 font-semibold hover:text-gray-600">7</a>
                        </li> -->

                <li class="mx-1" *ngFor="let page of pages;let i = index">
                    <a (click)="onPage(page)" href="javascript:void(0)"
                        class="{{(currentPage)==page?'opacity-75 cursor-default':'font-semibold hover:text-gray-600'}} block p-2">{{page}}</a>
                </li>
                <li class="{{currentPage==numOfPages?'inactivenext':'activenext'}} ml-3">
                    <a (click)="next()" href="javascript:void(0)"
                        class="border border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700">
                        <svg xmlns="http://www.w3.org/2000/svg"
                            class="w-3 h-3 text-gray-600 fill-current stroke-current" viewBox="0 0 24 24">
                            <g stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                                <polyline fill="none" stroke-miterlimit="10" points="8,1 16,12 8,23 "></polyline>
                            </g>
                        </svg>
                    </a>
                </li>
            </ul>
        </div>
    </div>


</div>
</div>

</div>



<ngx-smart-modal [closable]="false" #changePassword identifier="changePassword">
    <div class="container max-w-4xl mx-auto">
        <div class="flex justify-end mb-4">
            <a (click)="ngxSmartModalService.getModal('changePassword').close()"
                class="flex cursor-pointer items-center justify-center w-12 h-8 lg:w-12 lg:h-8 text-white">
                <svg xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 lg:w-5 lg:h-5 mx-auto stroke-current fill-current" viewBox="0 0 16 16">
                    <g stroke-width="1">
                        <line fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"
                            x1="13.5" y1="2.5" x2="2.5" y2="13.5"></line>
                        <line fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="2.5"
                            y1="2.5" x2="13.5" y2="13.5"></line>
                    </g>
                </svg>
            </a>
        </div>
        <div class="w-full mx-auto mt-2 xl:mt-4 rounded-lg bg-white p-6 xl:p-8">
            <form class="form-password" [formGroup]="changePasswordForm" (ngSubmit)="ChangePaswordAction()">
                <h3 class="font-semibold text-lg text-primarylighter text-center mb-4">Change my password</h3>
                <div class="mb-4 w-full">
                    <label for="current-password"
                        class="mb-1 font-semibold text-gray-700 text-xs block cursor-pointer">Current password
                        <sup>*</sup></label>
                    <!-- <div class="mt-1 mb-2 font-semibold text-sm text-orange-dark">Text required or error here</div> -->
                    <div *ngIf="isError && errorArr.cnpassword"
                        class="mt-1 mb-2 font-semibold text-sm text-orange-dark">
                        <p *ngFor="let err of errorArr.cnpassword;">{{err}}</p>
                    </div>
                    <div *ngIf="isError && errorArr.passwordSafe"
                        class="mt-1 mb-2 font-semibold text-sm text-orange-dark">
                        <p *ngFor="let err of errorArr.passwordSafe;">{{err}}</p>
                    </div>
                    <input type="password" formControlName="currPass" (keyup)="checkIfPassIsCorrect()"
                        class="w-full block bg-gray-200 rounded px-4 py-2 cursor-pointer focus:bg-white border border-gray-200 font-sans tracking-wide focus:border-gray-300"
                        id="current-password" placeholder="Enter current password" required>
                </div>
                <div *ngIf="isCurrPassCorrect">
                    <div class="mb-4 w-full">
                        <label for="password" class="mb-1 font-semibold text-gray-700 text-xs block cursor-pointer">New
                            Password <sup>*</sup></label>
                        <input formControlName="newPass" type="password" id="password"
                            class="w-full block bg-gray-200 rounded px-4 py-2 cursor-pointer focus:bg-white border border-gray-200 font-sans tracking-wide focus:border-gray-300"
                            placeholder="New password">
                    </div>
                    <div class="form-group">
                        <label>Confirm password<sup>*</sup></label>
                        <input formControlName="conNewPass" type="password" placeholder="Confirm Password"
                            class="w-full block bg-gray-200 rounded px-4 py-2 cursor-pointer focus:bg-white border border-gray-200 font-sans tracking-wide focus:border-gray-300">
                    </div>
                    <button type="submit"
                        class="btn-gradient block mt-6 w-full px-4 py-3 hover:opacity-75">Change</button>

                    <div *ngIf="isSuccess" class="element-response w-full text-sm mt-4 text-center">{{succMessage}}
                    </div>

                </div>
            </form>
        </div>
    </div>
</ngx-smart-modal>

<ngx-smart-modal [closable]="false" #changeSector identifier="changeSector">    
    <div class="container max-w-md mx-auto">
        <div class="p-6 bg-white rounded">
            <h3 class="mb-8 text-sm text-primary font-normal md:text-lg text-center">Are you sure you want to change the sector?</h3>
            <div class="w-full text-center">
            <button (click)="changesector(profileData.sector);ngxSmartModalService.getModal('changeSector').close()" type="submit" class="rounded-full h-10 px-8 mr-2 bg-primary text-white text-sm font-bold text-center">Yes</button>
            <button (click)="ngxSmartModalService.getModal('changeSector').close()" type="button" class="rounded-full h-10 px-8 ml-2 bg-primary text-white text-sm font-bold text-center">No</button>
        </div>
        </div>
    </div>
</ngx-smart-modal>

<!-- Download Quote modal -->

<div *ngIf="showQuote" class="col-12 watchevent">
    <div class="watcheventwrp commonclr">
        <a (click)="closeQuote()" class="closewatch">
            <img src="../assets/images/close-popup.png" alt="">
        </a>
        <div class="container max-w-sm mx-auto py-4 px-4">
            <h3 class="mb-3 text-md text-white font-normal md:text-xl text-center">Download Quotes</h3>
            <p *ngIf="downloadQuoteErr" class="text-white rounded-lg p-2 mb-3">{{downloadQuoteMessage}}</p>
            <form [formGroup]="quoteForm" name="quoteForm" (ngSubmit)="downloadQouteCsv()" class="w-full max-w-lg">
                <div class="flex flex-wrap -mx-3 mb-6">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <select formControlName="month"
                            class="mx-auto py-3 px-5 rounded-lg shadow-md bg-white cursor-pointer tracking-wider uppercase font-semibold text-xs text-gray-600 flex w-full justify-between max-w-xs items-center">
                            <option *ngFor="let mon of months;" value="{{mon.id}}">{{mon.name}}</option>
                        </select>

                    </div>
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <select formControlName="year"
                            class="mx-auto py-3 px-5 rounded-lg shadow-md bg-white cursor-pointer tracking-wider uppercase font-semibold text-xs text-gray-600 flex w-full justify-between max-w-xs items-center">
                            <option *ngFor="let year of years" value="{{year}}">{{year}}</option>
                        </select>
                    </div>
                </div>
                <div class="flex flex-wrap">
                    <button
                        class="rounded-full text-sm w-full font-semibold bg-primary button-buy px-4 py-3 text-white flex items-center justify-center"
                        [disabled]="!quoteForm.valid" type="submit">Download</button>
                </div>
            </form>


        </div>
    </div>
</div>