<a routerLink="/knowledge-base" class="relative data-sources-block block rounded-lg overflow-hidden">
    <img src="assets/images/knowledge-base.jpg" class="w-full h-full object-center object-cover" />
    <div class="absolute inset-0 z-10 p-6 flex flex-col items-end">
        <div class="w-full text-white font-semibold text-lg">
            Knowledge base
        </div>
        <div class="mt-auto w-full">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-white stroke-current fill-current" viewBox="0 0 48 48"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="3" transform="translate(0.5 0.5)"><line fill="none" stroke-miterlimit="10" x1="2" y1="24" x2="45" y2="24"></line> <polyline fill="none" stroke-miterlimit="10" points="31,10 45,24 31,38 "></polyline></g></svg>
        </div>
    </div>
</a>
