import { LPCore } from './core';
import { DateTime } from './datetime';
import { ILPConfiguration } from './interfaces';
// import * as style from './scss/main.scss';
import { dateIsLocked, findNestedMonthItem } from './utils';
import * as moment from 'moment';

export class Calendar extends LPCore {
  constructor(options: ILPConfiguration) {
    super(options);
    //
  }

  protected render() {
    this.emit('before:render', this.ui);

    const mainBlock = document.createElement('div');
    mainBlock.className = 'container__main';
    const months = document.createElement('div');
    months.className = 'container__months';

    if (`columns-${this.options.numberOfColumns}`) {
      months.classList.remove('columns-2', 'columns-3', 'columns-4');
      months.classList.add(`columns-${this.options.numberOfColumns}`);
    }

    if (this.options.splitView) {
      months.classList.add('split-view');
    }

    if (this.options.showWeekNumbers) {
      months.classList.add('show-week-numbers');
    }

    const startDate = this.calendars[0].clone();
    const startMonthIdx = startDate.getMonth();
    const totalMonths = startDate.getMonth() + this.options.numberOfMonths;

    let calendarIdx = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let idx = startMonthIdx; idx < totalMonths; idx += 1) {
      let dateIterator = startDate.clone();
      dateIterator.setDate(1);
      dateIterator.setHours(0, 0, 0, 0);

      if (this.options.splitView) {
        dateIterator = this.calendars[calendarIdx].clone();
      } else {
        dateIterator.setMonth(idx);
      }

      months.appendChild(this.renderMonth(dateIterator, calendarIdx));

      calendarIdx += 1;
    }

    this.ui.innerHTML = '';

    let calculateDates = []
    if(this.datePicked.length === 2){
      calculateDates = [this.datePicked[0].toDateString(), this.datePicked[1].toDateString()]
    }
    this.insightsNumber = Math.ceil(moment(calculateDates[1]).diff(moment(calculateDates[0]), 'days')/30)
    if(this.options.withInsights && this.insightsNumber > 0){
      const insightsblock = document.createElement('div');
      insightsblock.className += 'container__insights relative mb-3';
      this.ui.appendChild(insightsblock);
      const clearButton = document.createElement('button');
      const clearButtonText = document.createTextNode('Clear');
      clearButton.appendChild(clearButtonText);
      clearButton.className += 'clear-selection ml-auto lg:ml-0 lg:absolute lg:top-0 lg:right-0 px-3 py-1 lg:mr-1 bg-orange-base rounded-full hover:bg-primary text-white flex items-center justify-center text-xs';
      const theDiv = document.createElement('div');
      theDiv.classList.add(`insights-periods-selector`);
      const contenttheDiv = document.createTextNode("Selected");
      const theSpan = document.createElement('span');
      const theNumber = document.createTextNode(this.insightsNumber);
      theSpan.appendChild(theNumber);
      theSpan.classList.add(`number-insights-periods`);
      const contenttheDiv2 = document.createTextNode("insights periods");
      const thePlus = document.createElement('button');
      thePlus.classList.add(`insights-periods__more`);
      thePlus.className += ' w-5 h-5 bg-gray-200 inline-flex rounded-full items-center justify-center mx-2';
      const contentthePlus = document.createTextNode("+");
      thePlus.append(contentthePlus);
      const theMinus = document.createElement('button');
      theMinus.classList.add(`insights-periods__less`);
      theMinus.className += ' w-5 h-5 bg-gray-200 inline-flex rounded-full items-center justify-center mx-2';
      const contenttheMinus = document.createTextNode("-");
      theMinus.append(contenttheMinus);
      theDiv.appendChild(contenttheDiv);
      theDiv.appendChild(theMinus);
      theDiv.appendChild(theSpan);
      theDiv.appendChild(thePlus);
      theDiv.appendChild(contenttheDiv2);
      insightsblock.appendChild(theDiv);
      insightsblock.appendChild(clearButton);
    }
    mainBlock.appendChild(months);

    if (this.options.resetButton) {
      let resetButton;
      if (typeof this.options.resetButton === 'function') {
        resetButton = this.options.resetButton.call(this);
      } else {
        resetButton = document.createElement('button');
        resetButton.type = 'button';
        resetButton.className = 'reset-button';
        resetButton.innerHTML = this.options.buttonText.reset;
      }

      resetButton.addEventListener('click', (e) => {
        e.preventDefault();

        // tslint:disable-next-line: no-string-literal
        this['clearSelection']();
      });

      mainBlock
        .querySelector(`.month-item:last-child`)
        .querySelector(`.month-item-header`)
        .appendChild(resetButton);
    }

    this.ui.appendChild(mainBlock);

    if (!this.options.autoApply || this.options.footerHTML) {
      this.ui.appendChild(this.renderFooter());
    }

    if (this.options.showTooltip) {
      this.ui.appendChild(this.renderTooltip());
    }

    this.ui.dataset.plugins = (this.options.plugins || []).join('|');

    this.emit('render', this.ui);
  }

  protected renderMonth(date: DateTime, calendarIdx: number) {
    const startDate = date.clone();

    const totalDays = 32 - new Date(startDate.getFullYear(), startDate.getMonth(), 32).getDate();

    const month = document.createElement('div');
    month.className = 'month-item';

    const monthHeader = document.createElement('div');
    monthHeader.className = 'month-item-header';

    const monthAndYear = document.createElement('div');

    if (this.options.dropdowns.months) {
      const selectMonths = document.createElement('select');
      selectMonths.className = 'month-item-name';

      for (let x = 0; x < 12; x += 1) {
        const option = document.createElement('option');
        // day 2 because iOS bug with `toLocaleString`
        // https://github.com/wakirin/Litepicker/issues/113
        const monthName = new DateTime(new Date(date.getFullYear(), x, 2, 0, 0, 0));
        const optionMonth = new DateTime(new Date(date.getFullYear(), x, 1, 0, 0, 0));

        option.value = String(x);
        option.text = monthName.toLocaleString(this.options.lang, { month: 'long' });
        option.disabled = (this.options.minDate
          && optionMonth.isBefore(new DateTime(this.options.minDate), 'month'))
          || (this.options.maxDate && optionMonth.isAfter(new DateTime(this.options.maxDate), 'month'));
        option.selected = optionMonth.getMonth() === date.getMonth();

        selectMonths.appendChild(option);
      }

      selectMonths.addEventListener('change', (e) => {
        const target = e.target as HTMLSelectElement;

        let idx = 0;

        if (this.options.splitView) {
          const monthItem = target.closest(`.month-item`);
          idx = findNestedMonthItem(monthItem);
        }

        this.calendars[idx].setMonth(Number(target.value));
        this.render();

        this.emit('change:month', this.calendars[idx], idx, e);
      });

      monthAndYear.appendChild(selectMonths);
    } else {
      const monthName = document.createElement('strong');
      monthName.className = 'month-item-name';
      monthName.innerHTML = date.toLocaleString(this.options.lang, { month: 'long' });
      monthAndYear.appendChild(monthName);
    }

    if (this.options.dropdowns.years) {
      const selectYears = document.createElement('select');
      selectYears.className = 'month-item-year';

      const minYear = this.options.dropdowns.minYear;
      const maxYear = this.options.dropdowns.maxYear
        ? this.options.dropdowns.maxYear
        : (new Date()).getFullYear();

      if (date.getFullYear() > maxYear) {
        const option = document.createElement('option');
        option.value = String(date.getFullYear());
        option.text = String(date.getFullYear());
        option.selected = true;
        option.disabled = true;

        selectYears.appendChild(option);
      }

      for (let x = maxYear; x >= minYear; x -= 1) {
        const option = document.createElement('option');
        const optionYear = new DateTime(new Date(x, 0, 1, 0, 0, 0));
        option.value = String(x);
        option.text = String(x);
        option.disabled = (this.options.minDate
          && optionYear.isBefore(new DateTime(this.options.minDate), 'year'))
          || (this.options.maxDate
            && optionYear.isAfter(new DateTime(this.options.maxDate), 'year'));
        option.selected = date.getFullYear() === x;

        selectYears.appendChild(option);
      }

      if (date.getFullYear() < minYear) {
        const option = document.createElement('option');
        option.value = String(date.getFullYear());
        option.text = String(date.getFullYear());
        option.selected = true;
        option.disabled = true;

        selectYears.appendChild(option);
      }

      if (this.options.dropdowns.years === 'asc') {
        const childs = Array.prototype.slice.call(selectYears.childNodes);
        const options = childs.reverse();
        selectYears.innerHTML = '';
        options.forEach((y) => {
          y.innerHTML = y.value;
          selectYears.appendChild(y);
        });
      }

      selectYears.addEventListener('change', (e) => {
        const target = e.target as HTMLSelectElement;

        let idx = 0;

        if (this.options.splitView) {
          const monthItem = target.closest(`.month-item`);
          idx = findNestedMonthItem(monthItem);
        }

        this.calendars[idx].setFullYear(Number(target.value));
        this.render();

        this.emit('change:year', this.calendars[idx], idx, e);
      });

      monthAndYear.appendChild(selectYears);
    } else {
      const monthYear = document.createElement('span');
      monthYear.className = 'month-item-year';
      monthYear.innerHTML = String(date.getFullYear());
      monthAndYear.appendChild(monthYear);
    }

    const previousMonthButton = document.createElement('button');
    previousMonthButton.type = 'button';
    previousMonthButton.className = 'button-previous-month';
    previousMonthButton.innerHTML = this.options.buttonText.previousMonth;

    const nextMonthButton = document.createElement('button');
    nextMonthButton.type = 'button';
    nextMonthButton.className = 'button-next-month';
    nextMonthButton.innerHTML = this.options.buttonText.nextMonth;

    monthHeader.appendChild(previousMonthButton);
    monthHeader.appendChild(monthAndYear);
    monthHeader.appendChild(nextMonthButton);

    if (this.options.minDate
      && startDate.isSameOrBefore(new DateTime(this.options.minDate), 'month')) {
      month.classList.add('no-previous-month');
    }

    if (this.options.maxDate
      && startDate.isSameOrAfter(new DateTime(this.options.maxDate), 'month')) {
      month.classList.add('no-next-month');
    }

    const weekdaysRow = document.createElement('div');
    weekdaysRow.className = 'month-item-weekdays-row';

    if (this.options.showWeekNumbers) {
      weekdaysRow.innerHTML = '<div>W</div>';
    }

    for (let w = 1; w <= 7; w += 1) {
      // 7 days, 4 is «Thursday» (new Date(1970, 0, 1, 12, 0, 0, 0))
      const dayIdx = 7 - 4 + this.options.firstDay + w;
      const weekday = document.createElement('div');
      weekday.innerHTML = this.weekdayName(dayIdx);
      weekday.title = this.weekdayName(dayIdx, 'long');
      weekdaysRow.appendChild(weekday);
    }

    const days = document.createElement('div');
    days.className = 'container__days';

    const skipDays = this.calcSkipDays(startDate);

    if (this.options.showWeekNumbers && skipDays) {
      days.appendChild(this.renderWeekNumber(startDate));
    }

    for (let idx = 0; idx < skipDays; idx += 1) {
      const dummy = document.createElement('div');
      days.appendChild(dummy);
    }

    // tslint:disable-next-line: prefer-for-of
    for (let idx = 1; idx <= totalDays; idx += 1) {
      startDate.setDate(idx);

      if (this.options.showWeekNumbers && startDate.getDay() === this.options.firstDay) {
        days.appendChild(this.renderWeekNumber(startDate));
      }

      days.appendChild(this.renderDay(startDate));
    }

    month.appendChild(monthHeader);
    month.appendChild(weekdaysRow);
    month.appendChild(days);

    this.emit('render:month', month, date);

    return month;
  }

  protected renderDay(date: DateTime) {
    date.setHours();

    const day = document.createElement('div');
    day.className = 'day-item';
    day.innerHTML = String(date.getDate());
    day.dataset.time = String(date.getTime());

    if (date.toDateString() === (new Date()).toDateString()) {
      day.classList.add('is-today');
    }

    if (this.datePicked.length) {
      if (this.datePicked[0].toDateString() === date.toDateString()) {
        day.classList.add('is-start-date');

        if (this.options.singleMode) {
          day.classList.add('is-end-date');
        }
      }

      if (this.datePicked.length === 2
        && this.datePicked[1].toDateString() === date.toDateString()) {
        day.classList.add('is-end-date');
      }

      if (this.datePicked.length === 2) {
        if (date.isBetween(this.datePicked[0], this.datePicked[1])) {
          day.classList.add('is-in-range');
        }
      }
    } else if (this.options.startDate) {
      const startDate = this.options.startDate as DateTime;
      const endDate = this.options.endDate as DateTime;

      if (startDate.toDateString() === date.toDateString()) {
        day.classList.add('is-start-date');

        if (this.options.singleMode) {
          day.classList.add('is-end-date');
        }
      }

      if (endDate && endDate.toDateString() === date.toDateString()) {
        day.classList.add('is-end-date');
      }

      if (startDate && endDate) {
        if (date.isBetween(startDate, endDate)) {
          day.classList.add('is-in-range');
        }
      }
    }

    if (this.options.minDate && date.isBefore(new DateTime(this.options.minDate))) {
      day.classList.add('is-locked');
    }

    if (this.options.maxDate && date.isAfter(new DateTime(this.options.maxDate))) {
      day.classList.add('is-locked');
    }

    if (this.options.minDays > 1
      && this.datePicked.length === 1) {
      const minDays = this.options.minDays - 1; // subtract selected day
      const left = this.datePicked[0].clone().subtract(minDays, 'day');
      const right = this.datePicked[0].clone().add(minDays, 'day');

      if (date.isBetween(left, this.datePicked[0], '(]')) {
        day.classList.add('is-locked');
      }

      if (date.isBetween(this.datePicked[0], right, '[)')) {
        day.classList.add('is-locked');
      }
    }

    if (this.options.maxDays
      && this.datePicked.length === 1) {
      const maxDays = this.options.maxDays;
      const left = this.datePicked[0].clone().subtract(maxDays, 'day');
      const right = this.datePicked[0].clone().add(maxDays, 'day');

      if (date.isSameOrBefore(left)) {
        day.classList.add('is-locked');
      }

      if (date.isSameOrAfter(right)) {
        day.classList.add('is-locked');
      }
    }

    if (this.options.selectForward
      && this.datePicked.length === 1
      && date.isBefore(this.datePicked[0])) {
      day.classList.add('is-locked');
    }

    if (this.options.selectBackward
      && this.datePicked.length === 1
      && date.isAfter(this.datePicked[0])) {
      day.classList.add('is-locked');
    }

    const locked = dateIsLocked(date, this.options, this.datePicked);

    if (locked) {
      day.classList.add('is-locked');
    }

    if (this.options.highlightedDays.length) {
      const isHighlighted = this.options.highlightedDays
        .filter((d) => {
          if (d instanceof Array) {
            return date.isBetween(d[0], d[1], '[]');
          }

          return d.isSame(date, 'day');
        }).length;

      if (isHighlighted) {
        day.classList.add('is-highlighted');
      }
    }

    // fix bug iOS 10-12 - https://github.com/wakirin/Litepicker/issues/124
    day.tabIndex = !day.classList.contains('is-locked') ? 0 : -1;

    this.emit('render:day', day, date);

    return day;
  }

  protected renderFooter() {
    const footer = document.createElement('div');
    footer.className = 'container__footer';

    if (this.options.footerHTML) {
      footer.innerHTML = this.options.footerHTML;
    } else {
      footer.innerHTML = `
      <span class="preview-date-range"></span>
      <button type="button" class="button-cancel">${this.options.buttonText.cancel}</button>
      <button type="button" class="button-apply">${this.options.buttonText.apply}</button>
      `;
    }

    if (this.options.singleMode) {
      if (this.datePicked.length === 1) {
        const startValue = this.datePicked[0].format(this.options.format, this.options.lang);
        footer.querySelector(`.preview-date-range`).innerHTML = startValue;
      }
    } else {
      if (this.datePicked.length === 1) {
        footer.querySelector(`.button-apply`).setAttribute('disabled', '');
      }

      if (this.datePicked.length === 2) {
        const startValue = this.datePicked[0].format(this.options.format, this.options.lang);
        const endValue = this.datePicked[1].format(this.options.format, this.options.lang);

        footer.querySelector(`.preview-date-range`)
          .innerHTML = `${startValue}${this.options.delimiter}${endValue}`;
      }
    }

    this.emit('render:footer', footer);

    return footer;
  }

  protected renderWeekNumber(date) {
    const wn = document.createElement('div');
    const week = date.getWeek(this.options.firstDay);
    wn.className = 'week-number';
    wn.innerHTML = week === 53 && date.getMonth() === 0 ? '53 / 1' : week;

    return wn;
  }

  protected renderTooltip() {
    const t = document.createElement('div');
    t.className = 'container__tooltip';

    return t;
  }

  private weekdayName(day, representation = 'short') {
    return new Date(1970, 0, day, 12, 0, 0, 0)
      .toLocaleString(this.options.lang, { weekday: representation });
  }

  private calcSkipDays(date) {
    let total = date.getDay() - this.options.firstDay;
    if (total < 0) total += 7;

    return total;
  }
}
