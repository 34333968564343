import { Component, ViewChild, ElementRef, AfterViewInit, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import tippy from 'tippy.js';
import * as map from 'lodash/map';
import { DashboardService } from '../../services/dashboard.service';
import { ConnectionService } from '../../services/connection.service';
import { LoaderService } from 'src/app/services/loader.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LogoutService } from 'src/app/services/logout.service';

declare var turf: any;

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})

export class NewsComponent implements AfterViewInit, OnInit {

    titleField : string = 'News';
    news: any = [];
    isNoNews = false;
    public feedType: any = [];
    public selFeedType = '';
    changeCategoryShow: string = 'All categories';
    @ViewChild('changeCategory', {static: false }) changeCategory: ElementRef;
    destroy$: Subject<any> = new Subject();
    public categoryType: any = [];
    public selCategoryType = 'ALL';
    changeAlertShow: string = 'All alerts';
    @ViewChild('changeAlert', {static: false }) changeAlert: ElementRef;
    public alertType: any = [];
    public selAlertType = 'ALL';
    public currentPage: number = 1;
    public pages = [];
    public numPages: number;


    constructor(private LS:LogoutService,
      private router: Router, private titleService: Title, private dashboardService: DashboardService, private connectionService: ConnectionService, private loader: LoaderService, ) {
      this.titleService.setTitle('News - MetSwift');
      this.loader.display(true);
      this.getNews();
    }

    ngOnInit(){
      this.LS.logOut({"title":"News","link":"#/news"})
        .subscribe(
          data => {},
          error => {
            this.router.navigate(['/login']);
            this.loader.display(false);
          }
        );
      this.connectionService.display(true);
      //this.loader.display(false);
      //this.getNews();

    }

    ngAfterViewInit(): void {}

    onChangeCategoryType(changeTo: any, instance: any): void {
      this.changeCategoryShow = changeTo.textContent;
      this.selCategoryType = changeTo.id;
      this.currentPage = 1;
      this.getNews()
      instance.hide()
    }

    onChangeAlertType(changeTo: any, instance: any): void {
      this.changeAlertShow = changeTo.textContent;
      this.selAlertType = changeTo.id;
      this.currentPage = 1;
      this.getNews()
      instance.hide()
    }

    getNews() {        
      if (navigator.onLine == true) {
        this.loader.display(true);
        let selCategory = [];
        if (this.selCategoryType) {
          selCategory = this.selCategoryType === 'ALL' ? ['EQ','TC','WF','DR','FL','VO'] : [this.selCategoryType];
        }
        let selAlert = [];
        if (this.selAlertType) {
          selAlert = this.selAlertType === 'ALL' ? ['Green','Orange', 'Red'] : this.selAlertType === '> Orange' ? ['Orange', 'Red'] : [this.selAlertType];
        }
        this.dashboardService.getNews({
          "categoryId": selCategory,
          "alert": selAlert,
          "pageNumber": this.currentPage.toString(),
          "pageSize": '10'
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe (
          data => {
            this.loader.display(false);
            let successData = data;
console.log(successData)
            this.categoryType = successData.categoryType;
            this.news = [];
            if ( successData && successData.news ) {
              let len = successData.news.length;
              this.isNoNews = len === 0;
              for (let i = 0; i < len; i++) {
                if(successData.news[i]) {
                  let newdata = {category: '',thumbnail: '',defaultThumbnail: '',title: '',description: '',severity: '',severityValue: '',severityUnit: '',statLine: '',location: '',toDate: '',link:'',alert:'',isCurrent:''};
                  newdata.category = successData.news[i].category;
                  newdata.thumbnail = successData.news[i].mapUrl ?? successData.news[i].imageUrl;
                  newdata.defaultThumbnail = successData.news[i].defaultUrl ?? null;
                  newdata.title = successData.news[i].title;
                  newdata.description = successData.news[i].description;
                  newdata.severity = successData.news[i].severity;
                  newdata.severityValue = successData.news[i].severityValue;
                  newdata.severityUnit = successData.news[i].severityUnit;
                  newdata.statLine = successData.news[i].statLine;
                  let lat = Math.abs(successData.news[i].latitude).toFixed(3) + '°' + (successData.news[i].latitude >= 0 ? 'N' : 'S');
                  let long = Math.abs(successData.news[i].longitude).toFixed(3) + '°' + (successData.news[i].longitude >= 0 ? 'E' : 'W');
                  newdata.location = 'Location: ' + lat + ', ' + long;
                  newdata.toDate = new Date(successData.news[i].toDate).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', day: 'numeric', month: 'short', year: 'numeric', timeZone: 'UTC', hour12: false }) + '<b class="font-normal ml-1"> UTC</b>';
                  newdata.link = successData.news[i].link;
                  newdata.alert = successData.news[i].alertLevel;
                  newdata.isCurrent = successData.news[i].isCurrent;
                  this.news.splice(i, 0, newdata);
                }
              }

              this.numPages = successData.pagesInfo.numPages ?? 0;
              this.pages = successData.pagesInfo.pages ?? [];

              this.pagination();


              this.setupCategoryTippy();
              this.setupAlertTippy();

            }
          },
          error => {
            this.loader.display(false);
          }
        )
      } else {
        this.connectionService.display(true);
        this.loader.display(false);
      }
    }



    setupCategoryTippy(): void {
      let categories = `<button class="px-12 py-3 text-sm font-semibold text-primary w-full block border-b border-gray-300 hover:text-gray-600 select-category" id="ALL">All categories</button>`;
      const categoryType = [{id:'EQ',itemName:'Earthquake'},{id:'TC',itemName:'Tropical Cyclone'},{id:'WF',itemName:'Wildfire'},{id:'DR',itemName:'Drought'},{id:'FL',itemName:'Flood'},{id:'VO',itemName:'Volcano'}];
      categoryType.map(c => {
        categories += '<button class="px-12 py-3 text-sm font-semibold text-primary w-full block border-b border-gray-300 hover:text-gray-600 select-category" id="'+c.id+'">'+c.itemName+'</button>';
      });

      const app = this;
      if (this.changeCategory.nativeElement && !this.changeCategory.nativeElement._tippy) {
        tippy(this.changeCategory.nativeElement, {
          allowHTML: true,
          theme: 'light-border',
          placement: 'bottom',
          trigger: 'click',
          arrow: true,
          animation: 'shift-away',
          interactive: true,
          content: categories,
          onMount(instance) {
            const selector = instance.popper.querySelectorAll('.select-category');
            if (selector) {
              map(selector, elem => {
                if (!elem.dataset.listenerAdded) {
                  elem.addEventListener('click', (e) => {
                    app.onChangeCategoryType(e.target, instance);
                  });
                  elem.dataset.listenerAdded = 'true';
                }
              });
            }
          },
        });
      }
    }



    setupAlertTippy(): void {
      let alerts = `<button class="px-10 py-3 text-sm font-semibold text-primary w-full block border-b border-gray-300 hover:text-gray-600 select-alert" id="ALL">All alerts</button>`;
      const alertType = ['Red','> Orange','Orange','Green'];
      alertType.map(a => {
        alerts += '<button class="px-10 py-3 text-sm font-semibold text-primary w-full block border-b border-gray-300 hover:text-gray-600 select-alert" id="'+a+'">'+a+'</button>';
      });

      const app = this;
      if (this.changeAlert.nativeElement && !this.changeAlert.nativeElement._tippy) {
        tippy(this.changeAlert.nativeElement, {
          allowHTML: true,
          theme: 'light-border',
          placement: 'bottom',
          trigger: 'click',
          arrow: true,
          animation: 'shift-away',
          interactive: true,
          content: alerts,
          onMount(instance) {
            const selector = instance.popper.querySelectorAll('.select-alert');
            if (selector) {
              map(selector, elem => {
                if (!elem.dataset.listenerAdded) {
                  elem.addEventListener('click', (e) => {
                    app.onChangeAlertType(e.target, instance);
                  });
                  elem.dataset.listenerAdded = 'true';
                }
              });
            }
          },
        });
      }
    }



    pagination(): void {
      if (this.currentPage < 1) {
        this.currentPage = 1;
      } else if (this.currentPage > this.numPages) {
        this.currentPage = this.numPages;
      }
      let startPage: number, endPage: number;
      if (screen.width <= 767 || (screen.width >= 1024 && screen.width <= 1127)) {
        if (this.numPages <= 5) {
          startPage = 1;
          endPage = this.numPages;
        } else {
          if (this.currentPage <= 3) {
            startPage = 1;
            endPage = 5;
          } else if (this.currentPage + 2 >= this.numPages) {
            startPage = this.numPages - 4;
            endPage = this.numPages;
          } else {
            startPage = this.currentPage - 2;
            endPage = this.currentPage + 2;
          }
        }
      } else {
        if (this.numPages <= 10) {
          startPage = 1;
          endPage = this.numPages;
        } else {
          if (this.currentPage <= 6) {
            startPage = 1;
            endPage = 10;
          } else if (this.currentPage + 4 >= this.numPages) {
            startPage = this.numPages - 9;
            endPage = this.numPages;
          } else {
            startPage = this.currentPage - 5;
            endPage = this.currentPage + 4;
          }
        }
      }
      this.pages = [];
      this.pages = Array.from(
        Array(endPage + 1 - startPage).keys()
      ).map(i => startPage + i);
    }


    prevPage(): void {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getNews();
      }
    }


    nextPage(): void {
      this.currentPage++;
      this.getNews();
    }


    onPage(val): void {
      if (navigator.onLine == true) {
        this.currentPage = val;
        this.getNews();
      } else {
        this.loader.display(false);
      }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
      this.pagination();
    }


}
