import { Component } from '@angular/core';

@Component({
  selector: 'app-report-all',
  templateUrl: './report-all.component.html',
  styleUrls: ['./report-all.component.scss']
})


export class ReportAllComponent {

    constructor(
    ) {

    }

}
