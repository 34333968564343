import { Component, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-search-reports-button',
  templateUrl: './search-reports-button.component.html',
  styleUrls: ['./search-reports-button.component.scss']
})
export class SearchReportsButtonComponent {
  @ViewChild('searchReportsInput', {static: false}) searchReportsInput: ElementRef;

  constructor(
    private userSer: UserService,
    public ngxSmartModalService: NgxSmartModalService,
    private router:Router,
    private route:ActivatedRoute
  ) {
    this.userSer.setSearchText()
  }

  public elementFocus() {
    setTimeout(() => {
      this.searchReportsInput.nativeElement.value='';
      this.searchReportsInput.nativeElement.focus()
    }, 300)
  }
  
  searchevent(){
    console.log(this.router.url)
    this.userSer.setSearchText(this.searchReportsInput.nativeElement.value.trim())
    if(this.searchReportsInput.nativeElement.value.trim()){
      this.router.navigate(['/my-portfolio'],{ queryParams: {
        searchTxt: this.searchReportsInput.nativeElement.value.trim()
    } });
    this.ngxSmartModalService.getModal('searchReports').close();
    }
  }

  closeModal() {
    this.ngxSmartModalService.getModal('searchReports').close();
  }
}
