import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { LogoutService } from 'src/app/services/logout.service';

@Component({
  selector: 'app-data-sources',
  templateUrl: './data-sources.component.html',
  styleUrls: ['./data-sources.component.scss']
})
export class DataSourcesComponent {
    titleField : string = 'Data sources';
    constructor(private titleService:Title,private LS:LogoutService,
      private loader:LoaderService,private router: Router) {
        this.titleService.setTitle('Data Sources - MetSwift');
    }

    ngOnInit(): void {
      this.LS.logOut({"title":"Data Sources","link":"#/data-sources"})
        .subscribe(
          data => {
            this.loader.display(false);                        
          },
          error => {
            this.router.navigate(['/login']);
          }
        );
    }


}
