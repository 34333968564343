import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-metswift-hlp',
  templateUrl: './metswift-hlp.component.html',
  styleUrls: ['./metswift-hlp.component.scss']
})

export class MetswiftHLPComponent implements OnInit {
  titleField : string = 'Metswift HLP';

  constructor() { }

  ngOnInit(): void {
  }

}
