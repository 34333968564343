import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConnectionService } from 'src/app/services/connection.service';
import { LoaderService } from 'src/app/services/loader.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { UserService } from 'src/app/services/user.service';
import { LogoutService } from 'src/app/services/logout.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
    titleField : string = 'Contact us';
    contactUsForm: FormGroup;
    response: any = false;
    map: any;
    coords: any = [-1.5486905495554744, 53.79589126157508];
    public submitObj: any = {};
    public successMsg: string = '';
    public mailSuccess: boolean = false;
    public profileData;
    constructor(
        private titleService:Title,
        public fb: FormBuilder,
        private connectionService:ConnectionService,
        private loader:LoaderService,
        private dashboardService:DashboardService,
        private userService :UserService,
        private LS:LogoutService,
        private router: Router,
    ) {
      this.LS.logOut({"title":"Contact Metswift", "link": "#/contact-us"})
            .subscribe(
            data => {
              this.loader.display(false);
            },
            error => {
                this.loader.display(false);
                this.router.navigate(['/login']);
            }
            );
        this.titleService.setTitle('Contact us - MetSwift');
        this.fetchProfile();

    }

    ngOnInit(){
        this.contactForm();
    }

    fetchProfile() {
          this.loader.display(true);
          this.userService.fetchuserProfile({})
            .subscribe(
              data => {
                this.loader.display(false);
                let successData = data;
                this.profileData = successData.profile;
                this.contactUsForm.patchValue({ name: this.profileData.firstName });
                this.contactUsForm.patchValue({ email: this.profileData.email });
                this.contactUsForm.patchValue({ phone: this.profileData.contactNo });
              },
              error => {
                let errordata = error;
              });
        
      }

    contactForm() {
        this.contactUsForm = this.fb.group({
          "name": ['',Validators.required],
          "email": ['',[
            Validators.required,
            Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$')
          ]],
          "phone": ['',[Validators.required, Validators.minLength(6),Validators.maxLength(20)]],
          "message": ['',Validators.required]
        })
    }
    sendForm(): void {
        console.log('send form contact here')
        this.loader.display(true);
      this.submitObj = {
        'name': this.contactUsForm.get('name').value,
        'email': this.contactUsForm.get('email').value,
        'phone': this.contactUsForm.get('phone').value,
        'message': this.contactUsForm.get('message').value,
      }
      this.dashboardService.contactdelphi(this.submitObj)
        .subscribe(
          data => {
            this.loader.display(false);
            let successData = data;
            this.successMsg = successData.message;
            this.mailSuccess = true;
            this.contactUsForm.get('message').setValue('');
            this.response = '<strong class="font-semibold">'+this.successMsg+'</strong>';
            setTimeout(() => {
              this.mailSuccess = false;
              this.response = false;
              //this.contactForm();
            }, 3000);
          },
          error => {
            let errordata = error;
          });
        
    }
    onZoomOutClick(): void {
        this.map.flyTo({
            center: this.coords,
            zoom: 20,
            speed: 0.85,
            pitch: 0,
            bearing: 0,
        })
    }
    onClickMarker(): void {
        this.map.flyTo({
            center: this.coords,
            zoom: 20,
            speed: 0.85,
            pitch: 35,
            bearing: -5,
        })
    }

    
}
