import { Component, Input, Output, ViewChild, ElementRef, EventEmitter, AfterViewInit } from '@angular/core';
import tippy from 'tippy.js';

@Component({
  selector: 'app-button-type',
  templateUrl: './button-type.component.html',
  styleUrls: ['./button-type.component.scss']
})
export class ButtonTypeComponent implements AfterViewInit  {
  @Input() dataActive: boolean = false;
  @Input() dataNotActive: boolean = false;
  @Input() type: string = 'rain';
  @Input() hurricaneName: string='';
  @Input() info: any;
  @Output() activeType: EventEmitter<any> = new EventEmitter();
  @ViewChild('elementInfo', {static: false }) elementInfo: ElementRef;

  constructor() { }

  onClick() {
    this.activeType.emit({type: this.type, active: this.dataActive});
  }
  ngAfterViewInit(): void {
    const app = this
    if(this.elementInfo){
      tippy(this.elementInfo.nativeElement, {
          allowHTML: true,
          theme: 'light-border',
          placement: 'bottom-start',
          // trigger: 'click',
          arrow: true,
          animation: 'shift-away',
          interactive: true,
          content: `<div class="px-4 py-2 wd-72">${app.info}</div>`,
      })
    }
  }

}
