import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENV } from './../app.config';

@Injectable()
export class DashboardService {
    constructor(
        private http: HttpClient
      ) { }

  getNews(data: Object): Observable<any> {
    return this.http.post(`${ENV.BASE_API}/api/news/getnews`, data);
  }

  getPortfolio(): Observable<any> {
    return this.http.get(`${ENV.BASE_API}/api/brain/getPortfolioForDashboard`);
  }

  getRiskIconsData(data: Object): Observable<any> {
    Object.defineProperty(data, "uri", {value: '/api/predict_data/get_data_for_notification',writable: true,enumerable: true,configurable: true});
    return this.http.post(`${ENV.BASE_API}/api/predict_data/get_data_for_notification`, data);
  }

  mapRisk(data: Object): Observable<any> {
    return this.http.post(`${ENV.WEATHER_API}/stats_data/get_map_risk_data`, data);
  }
  
  fetchUserOrdersList(body: Object): Observable<any> {
    return this.http.post(ENV.BASE_API + '/api/order/get_my_orders', body);
  }
  contactdelphi(body: Object): Observable<any> {
    return this.http.post(`${ENV.BASE_API}/api/insight/contact`, body);
  }



}