<div class="border border-gray-300 rounded-lg px-8 py-4 w-full bg-transparent bg-white">
    <div id="bars-plot" class="w-full h-full"></div>
    <div class="flex justify-center w-full mt-4">
        <ul class="pager flex items-center justify-center">

            <li class="activeprevious mr-3">
                <a class="border cursor-pointer border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700">
                    <svg class="w-3 h-3 text-gray-600 fill-current stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polyline fill="none" stroke-miterlimit="10" points="16,1 8,12 16,23 "></polyline></g></svg>
                </a>
            </li>
            <li class="activenext ml-3">
                <a class="border cursor-pointer border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-600 fill-current stroke-current" viewBox="0 0 24 24"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polyline fill="none" stroke-miterlimit="10" points="8,1 16,12 8,23 "></polyline></g></svg>
                </a>
            </li>
        </ul>
    </div>

</div>
