import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter,HostListener } from '@angular/core';
import Plotly from 'plotly.js-dist'

@Component({
  selector: 'app-results-graph-plot',
  templateUrl: './results-graph-plot.component.html',
  styleUrls: ['./results-graph-plot.component.scss']
})
export class ResultsGraphPlotComponent implements OnInit, AfterViewInit {

    @Input() predGraphData;
    @Input() plotlyGraphLayout;
    @Input() fetchedpredictData;
    @Output() onNextPrevious: EventEmitter<any> = new EventEmitter();
    // @Output() onPrevious: EventEmitter<any> = new EventEmitter();
    currPage;
    more;

    layout: any = {
        autosize: true,
        updateOnLayoutChange: true,  
        margin: {
          l: 25,
          r: 25,
          b: 100,
          t: 25,
          pad: 20
        },
        yaxis: {fixedrange: true, Range: [10,50] },
        xaxis : {fixedrange: true},
        showlegend: false,
        shapes: [
          {
              type: 'line',
              xref: 'paper',
              x0: 0,
              y0: 30.0,
              x1: 1,
              y1: 30.0,
              line:{
                  color: '#FEB000',
                  width: 1,
              }
          },
          {
              type: 'line',
              xref: 'paper',
              x0: 0,
              y0: 40.0,
              x1: 1,
              y1: 40.0,
              line:{
                  color: '#C64B11',
                  width: 1,
              }
          },
        ],
        font: {
            family: 'neue-haas-grotesk-display',
        },
        hoverlabel: {
            bgcolor: "#1B242E",
            bordercolor: "#1B242E",
            font: {
                family: 'neue-haas-grotesk-display',
                color: '#fff',
            },
        },
       
    }
    config: any = {responsive: true, displayModeBar: false}
    // data: any;

    
    constructor() {

    }

    ngOnInit(): void {
      // console.log(this.fetchedpredictData);
      this.currPage = this.fetchedpredictData.page;
      this.more = this.fetchedpredictData.more;
      Plotly.newPlot('graph-plot', this.predGraphData,this.plotlyGraphLayout, this.config);
    }

    

    
    
    ngAfterViewInit(){
      
       // this.animateChart()
    }

   
  
    next() {
      if (this.more == 1) {
        this.onNextPrevious.emit('next'); 
      }
    }

    previous() {
      if ( this.currPage > 1 ) {
        this.onNextPrevious.emit('prev'); 
      }
    }

    


}
