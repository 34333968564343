import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProbConvertPipe } from './../pipes/prob-convert.pipe';


@NgModule({
  declarations: [ProbConvertPipe],
  imports: [
    CommonModule
  ],
  exports: [ProbConvertPipe]
})
export class ProbConvertModule { }
