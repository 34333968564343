import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENV } from './../app.config';

@Injectable()
export class EnquiriesService {
    constructor(
        private http: HttpClient
      ) { }

  getOverviewData(data: Object): Observable<any> {
    return this.http.post(`${ENV.BASE_API}/api/insight/testoverview`, data);
  }

  getThresholdsData(data: Object): Observable<any> {
    return this.http.post(`${ENV.BASE_API}/api/insight/getthresholds`, data);
  }

  updateThresholds(data: Object): Observable<any> {
    return this.http.post(`${ENV.BASE_API}/api/insight/updategraphthresholds`, data);
  }

  downloadoverviewPredPDF(data: Object): Observable<any> {
    let HTTPOptions:Object = {
      responseType: 'text'
   }
    return this.http.post(`${ENV.BASE_API}/api/insight/downloadOverviewPdf`, data, HTTPOptions);
  }

  downloadoverviewRiskPDF(data: Object): Observable<any> {
    let HTTPOptions:Object = {
      responseType: 'text'
   }
    return this.http.post(`${ENV.BASE_API}/api/insight/downloadOverviewPdf`, data, HTTPOptions);
  }

  overviewData(data: Object): Observable<any> {
    return this.http.post(`${ENV.WEATHER_API}/stats_data/overview_data`, data);
  }

  overviewPredictGet(data: Object): Observable<any> {
    return this.http.post(`${ENV.WEATHER_API}/predict_data/get_prediction_low_mean_high`, data);
  }

  overviewRiskGet(data: Object): Observable<any> {
    return this.http.post(`${ENV.WEATHER_API}/risk_data/get_risk_overview`, data);
  }

  getltfGraph(data: Object): Observable<any> {
    return this.http.post(`${ENV.WEATHER_API}/predict_data/get_ltf_graph`, data);
  }

  fetchRiskgraphdata(data: Object): Observable<any> {
    return this.http.post(`${ENV.WEATHER_API}/risk_data/get_risk_detail`, data);
  }

  getImage(data: Object): Observable<any> {
    Object.defineProperty(data, "uri", {
        value: '/risk_data/get_risk_image',
        writable: true,
        enumerable: true,
        configurable: true
    });
    return this.http.post(`${ENV.WEATHER_API}/risk_data/get_risk_image`, data);
  }

  updateGroupAndEventName(data: Object): Observable<any> {
    return this.http.post(`${ENV.BASE_API}/api/insight/updateGroupAndEventName`, data);
  }

      
}