import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { User } from '../models/user';

import { ENV } from './../app.config';

import { AppState, selectAuthState } from './../store/app.states';

@Injectable()
export class AuthService {
  getState: Observable<any>;
  errorMessage: string | null;
  constructor(
    private http: HttpClient,
    private store: Store<AppState>
  ) {
    this.getState = this.store.select(selectAuthState)
  }

  getToken(): string {
    let token = null
    this.getState.subscribe((state) => {
        if(state.user && state.user.token){
          token = state.user.token;
        }
        this.errorMessage = state.errorMessage;
    });
    return token
  }

  logIn(user: User): Observable<any> {
    return this.http.post<User>(`${ENV.BASE_API}/api/user/login`, user);
  }

  getStatus(): Observable<User> {
    const url = `${ENV.BASE_API}/status`;
    return this.http.get<User>(url);
  }
}
