import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENV } from './../app.config';

@Injectable()
export class LogoutService {
    constructor(private http: HttpClient) {}

    logOut(data: Object): Observable<any> {
      return this.http.post(`${ENV.BASE_API}/api/user/home`, data);
    }


    // logOut(body:Object){
    //     // body = JSON.stringify(body);
    //     // this._token=localStorage.getItem('token');       
    //     // let headers = new HttpHeaders({'Authorization': this._token,'Content-Type': 'application/json' });        
    //     return this.http.post<any>(ENV.BASE_API + '/api/user/home', body, { headers: headers }).pipe(
    //         catchError(this.handleError)
    //     );
    // }

    // handleError(error) {
    //     let errorMessage = '';
    //     if (error.error instanceof ErrorEvent) {
    //       // client-side error
    //       errorMessage = `Error: ${error.error.message}`;
    //     } else {
    //       // server-side error
    //       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    //     }
    //     return throwError(error);
    // }
}