import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { LogoutService } from 'src/app/services/logout.service';

@Component({
  selector: 'app-news-single-widget',
  templateUrl: './news-single-widget.component.html',
  styleUrls: ['./news-single-widget.component.scss']
})
export class NewsSingleWidgetComponent implements OnInit {
    @Input() data: any;
    @Input() isLast: any;
    @Input() isInsideLatestNews: boolean = false;
    //defaultThumbnail: string;

    constructor(private LS:LogoutService,private loaderService:LoaderService,private router: Router,) { }

    ngOnInit(): void {
    }

    iconImageUrl(): string {
      let imageUrl: string;
      const category = this.data.category === '' ? '-' : this.data.category.toLowerCase();
      switch (category) {
        case 'tropical cyclone': imageUrl = '/assets/images/icons-weather/hurricane.png'; break;
        case 'earthquake': imageUrl = '/assets/images/icons-weather/earthquakes.svg'; break;
        case 'flood': imageUrl = '/assets/images/icons-weather/rain.svg'; break;
        case 'drought': imageUrl = '/assets/images/icons-weather/temperature.svg'; break;
        case 'volcano': imageUrl = '/assets/images/icons-weather/volcano.png'; break;
        case '-': imageUrl = '/assets/images/transparency.png'; break;
        default: imageUrl = `/assets/images/icons-weather/${this.data.category.toLowerCase()}.svg`; break;
      }
      return imageUrl;
    }

    iconAlertClass(): string {
      let baseClass = `bg-${this.data.alert.toLowerCase()}-alert`;
      if (this.data.category.toLowerCase() === 'earthquake') {
        baseClass += ' pb-1';
      }
      if (this.data.isCurrent === 'false') {
        baseClass += ' not-current';
      }
      return baseClass;
    }

    overlayEQ(): string {
      return `text-${this.data.alert.toLowerCase()}-alert`;
    }

    overlayTC(): string {
      return `border-${this.data.alert.toLowerCase()}-alert bg-${this.data.alert.toLowerCase()}-alert-overlay`;
    }

    clickednews(data){
      console.log(data);
      this.LS.logOut({"title":"News Detail - "+data.title,"link":"#/news-detail"})
			  .subscribe(
			  data => {
					this.loaderService.display(false);
				},
			  error => {
				this.router.navigate(['/login']);
				this.loaderService.display(false);
			  }
			  );
    }

    invalidImageUrl(event: Event): void {
      const target = event.target as HTMLImageElement;
      if (this.data.defaultThumbnail && this.data.defaultThumbnail !== target.src) {
        target.src = this.data.defaultThumbnail;
      } else {
        target.src = '/assets/images/transparency.png';
      }
    }

}
