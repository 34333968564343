import { Theme, createPlugin } from '@fullcalendar/common'

export class CalendarResultsTheme extends Theme {
}

CalendarResultsTheme.prototype.classes = {
  root: 'calendar-results-theme',
  buttonGroup: 'flex items-center',
  // button: 'w-5 h-5 flex items-center mx-2',
  // buttonActive: 'active',
}

// CalendarResultsTheme.prototype.baseIconClass = 'fa'
// CalendarResultsTheme.prototype.iconClasses = {
//   close: 'fa-times',
//   prev: 'fa-chevron-left',
//   next: 'fa-chevron-right',
//   prevYear: 'fa-angle-double-left',
//   nextYear: 'fa-angle-double-right'
// }
// CalendarResultsTheme.prototype.rtlIconClasses = {
//   prev: 'fa-chevron-right',
//   next: 'fa-chevron-left',
//   prevYear: 'fa-angle-double-right',
//   nextYear: 'fa-angle-double-left'
// }

// CalendarResultsTheme.prototype.iconOverrideOption = 'bootstrapFontAwesome' // TODO: make TS-friendly. move the option-processing into this plugin
// CalendarResultsTheme.prototype.iconOverrideCustomButtonOption = 'bootstrapFontAwesome'
// CalendarResultsTheme.prototype.iconOverridePrefix = 'fa-'

let plugin = createPlugin({
  themeClasses: {
    CalendarResultsTheme: CalendarResultsTheme
  }
})

export default plugin
