import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input,Output, EventEmitter } from '@angular/core';
import Plotly from 'plotly.js-dist';
import * as colorConfig from '../../../../../../tailwind.config.js';


@Component({
  selector: 'app-results-vertical-bars-stack',
  templateUrl: './results-vertical-bars-stack.component.html',
  styleUrls: ['./results-vertical-bars-stack.component.scss']
})
export class ResultsVerticalBarsStackComponent implements OnInit, AfterViewInit {
    @ViewChild('legend', {static: false}) legend: ElementRef;
    @Input() riskGraphData;
    riskData;
    @Input() riskType;
    @Input() hurricaneCategoryLabel;
    currPage;
    more;
    labels:any;
    @Output() onNextPreviousRisk: EventEmitter<any> = new EventEmitter();
    YAxisHeight:any=0;

    layout: any = {
        barmode: 'stack',
        autosize: true,
        margin: {
          l: 30,
          r: 20,
          b: 50,
          t: 15,
          pad: 3
        },
        yaxis: {fixedrange: true, rangemode: 'tozero'},
        xaxis : {fixedrange: true},
        showlegend: false,
        pointerEvent: "none",
            cursor: "default",
        font: {
            family: 'neue-haas-grotesk-display',
        },
        hoverlabel: {
            bgcolor: "#1B242E",
            bordercolor: "#1B242E",
            
            font: {
                family: 'neue-haas-grotesk-display',
                color: '#fff',
            },
        },
    }

    config: any = {
        responsive: true,
        displayModeBar:false,
        scrollZoom: false
    }


    constructor() { }

    interpolateColor(color1, color2, factor) {
        if (arguments.length < 3) {
            factor = 0.5;
        }
        var result = color1.slice();
        for (var i = 0; i < 3; i++) {
            result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
        }
        return result;
    };

    interpolateColors(color1, color2, steps) {
        var stepFactor = 1 / (steps - 1),
            interpolatedColorArray = [];

        color1 = color1.match(/\d+/g).map(Number);
        color2 = color2.match(/\d+/g).map(Number);

        for(var i = 0; i < steps; i++) {
            interpolatedColorArray.push(this.interpolateColor(color1, color2, stepFactor * i));
        }

        return interpolatedColorArray;
    }
    hexToRgba(hex, opacity = 1){
        var c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length== 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+', ' + opacity + ')';
        }
        throw new Error('Bad Hex');
    }
    ngOnInit(): void {
      this.currPage = this.riskGraphData.page;
      this.more = this.riskGraphData.more;
      this.getYAxisHeight();
      this.riskData = this.riskGraphData.data;
        this.labels = this.getGraphLabels();
        let labelsLen = this.riskData[0].markerLabels.length;
        let traceData:any =[];
        let color;
        if (this.riskType == 'tornado') {
          color = '#ff8221'; 
        } else if ( this.riskType == 'hurricane' ) {
          color = '#0B8793';
        } else if ( this.riskType == 'wildfire' ) {
          color = '#FF7600';
        } else {
          color = '#C04848'; // Earthquake //colorConfig.theme.colors.earthquake.high;
        }
        let colorOpacity = 0.5;
        if (this.riskType == 'wildfire')
          colorOpacity = 1;
        if ( labelsLen > 0 ) {
          for ( let l=0;l<labelsLen;l++ ) {
            traceData.push(
              {
                x: this.labels,
                y: [null, null, null, null, null, null, null],
                name: ( this.riskType == 'hurricane' ) ? this.hurricaneCategoryLabel[l+1] : this.riskData[0].markerLabels[l],
                type: 'bar',
                marker:{
                  color: this.hexToRgba(color, colorOpacity),
                },
              }
            );
            colorOpacity = colorOpacity+0.1;
          }
        }
        // var trace1 = {
        //   x: ['Week 30', 'Week 31', 'Week 32', 'Week 33', 'Week 34', 'Week 35', 'Week 36'],
        //   y: [null, null, null, null, null, null, null],
        //   name: 'Trop St',
        //   type: 'bar',
        //   marker:{
        //     color: this.hexToRgba(colorConfig.theme.colors.hurricane.high, 1),
        //   },
        // };

        // var trace2 = {
        //   x: ['Week 30', 'Week 31', 'Week 32', 'Week 33', 'Week 34', 'Week 35', 'Week 36'],
        //   y: [null, null, null, null, null, null, null],
        //   name: 'Cat 1 Hu',
        //   type: 'bar',
        //   marker:{
        //     color: this.hexToRgba(colorConfig.theme.colors.hurricane.high, 0.9),
        //   },
        // };
        // var trace3 = {
        //   x: ['Week 30', 'Week 31', 'Week 32', 'Week 33', 'Week 34', 'Week 35', 'Week 36'],
        //   y: [null, null, null, null, null, null, null],
        //   name: 'Cat 2 Hu',
        //   type: 'bar',
        //   marker:{
        //     color: this.hexToRgba(colorConfig.theme.colors.hurricane.high, 0.8),
        //   },
        // };
        // var trace4 = {
        //   x: ['Week 30', 'Week 31', 'Week 32', 'Week 33', 'Week 34', 'Week 35', 'Week 36'],
        //   y: [null, null, null, null, null, null, null],
        //   name: 'Cat 3 Hu',
        //   type: 'bar',
        //   marker:{
        //     color: this.hexToRgba(colorConfig.theme.colors.hurricane.high, 0.7),
        //   },
        // };
        // var trace5 = {
        //   x: ['Week 30', 'Week 31', 'Week 32', 'Week 33', 'Week 34', 'Week 35', 'Week 36'],
        //   y: [null, null, null, null, null, null, null],
        //   name: 'Cat 4 Hu',
        //   type: 'bar',
        //   marker:{
        //     color: this.hexToRgba(colorConfig.theme.colors.hurricane.high, 0.6),
        //   },
        // };
        // var trace6 = {
        //   x: ['Week 30', 'Week 31', 'Week 32', 'Week 33', 'Week 34', 'Week 35', 'Week 36'],
        //   y: [null, null, null, null, null, null, null],
        //   name: 'Cat 5 Hu',
        //   type: 'bar',
        //   marker:{
        //     color: this.hexToRgba(colorConfig.theme.colors.hurricane.high, 0.5),
        //   },
        // };
        // var data = [trace1, trace2, trace3, trace4, trace5, trace6];


        Plotly.newPlot('bar-plot', traceData, this.layout, this.config);
    }
    animateChart() {

      let labelsLen = this.riskData[0].markerLabels.length;
      let tracesData:any = [];
      let color;
        if (this.riskType == 'tornado') {
          color = '#ff8221'; 
        } else if ( this.riskType == 'hurricane' ) {
          color = '#0B8793';
        } else if ( this.riskType == 'wildfire' ) {
          color = '#FF7600';  
        } else {
          color = '#C04848';
        }
        let colorOpacity = 0.5;
        if (this.riskType == 'wildfire')
          colorOpacity = 1;
      if ( labelsLen > 0 ) {
        for ( let t=0;t<labelsLen;t++ ) {
          let riskDataLen = this.riskData.length;
          let y=[];
          for ( let rd=0;rd<riskDataLen;rd++ ) {
            if (this.riskData[rd].markers[t] == 0) {
              y.push(null);
            } else {
              y.push(this.riskData[rd].markers[t]);
            }
          }
          if(this.legend && this.legend.nativeElement){
            var node = document.createElement('div')
            if ( this.riskType == 'hurricane' ) {
              let newt = t+1;
              var textnode = document.createTextNode(this.hurricaneCategoryLabel[newt])
            } else {
              var textnode = document.createTextNode(this.riskData[0].markerLabels[t])
            }
            node.className = 'mx-4 text-xs text-gray-700 flex items-center'
            var square = document.createElement('div')
            square.className = 'w-4 h-4 rounded-full mr-2 bg-hurricane-high'
            square.style.marginTop = '-1px'
            square.style.backgroundColor = this.hexToRgba(color, colorOpacity)
            node.appendChild(square)
            node.appendChild(textnode)
            this.legend.nativeElement.appendChild(node)
          }
          tracesData.push({"y": y});
          colorOpacity = colorOpacity + 0.1;
        }
      }
      if ( this.YAxisHeight > 0 ) {
        if(this.YAxisHeight<=0){
          this.YAxisHeight=0;
        }
        //this.YAxisHeight = this.YAxisHeight;
        this.layout.yaxis.fixedrange = true;
        this.layout.yaxis.range = [0,this.YAxisHeight]; 
      }
      // console.log('tracesData');
      // console.log(tracesData);
        // var trace1 = {
        //   y: [1, 1, null, 2, 1, 1, null],
        // };
        // var trace1 = {
        //   y: [1,2,1,1,1,1,1],
        // }
        // // var trace2 = {
        // //   y: [null, null, 1, null, 1, 1, null],
        // // };
        // var trace2 = {
        //   y: [1,1,null,null,null,null,null],
        // }
        // // var trace3 = {
        // //   y: [null, null, null, null, null, 1, 1],
        // // }
        // var trace3 = {
        //   y: [null,null,null,null,null,null,null],
        // }
        // // var trace4 = {
        // //   y: [null, null, null, 2, 1, null, 2],
        // // }
        // var trace4 = {
        //   y: [null,null,null,null,null,null,null],
        // }
        // // var trace5 = {
        // //   y: [null, null, null, null, 1, 1, 1],
        // // }
        // var trace5 = {
        //   y: [null,null,null,null,null,null,null],
        // }
        // // var trace6 = {
        // //   y: [null, null, null, null, 1, 1, 1],
        // // }
        // var trace6 = {
        //   y: [null,null,null,null,null,null,null],
        // }
        // if(this.legend && this.legend.nativeElement){
        //   var node = document.createElement('div')
        //   var textnode = document.createTextNode('Trop St')
        //   node.className = 'mx-4 text-xs text-gray-700 flex items-center'
        //   var square = document.createElement('div')
        //   square.className = 'w-4 h-4 rounded-full mr-2 bg-hurricane-high'
        //   square.style.marginTop = '-1px'
        //   square.style.backgroundColor = this.hexToRgba(colorConfig.theme.colors.hurricane.high, 1)
        //   node.appendChild(square)
        //   node.appendChild(textnode)
        //   this.legend.nativeElement.appendChild(node)
        // }
        
        // if(this.legend && this.legend.nativeElement){
        //   var node = document.createElement('div')
        //   var textnode = document.createTextNode('Cat 1 Hu')
        //   node.className = 'mx-4 text-xs text-gray-700 flex items-center'
        //   var square = document.createElement('div')
        //   square.className = 'w-4 h-4 rounded-full mr-2 bg-hurricane-high'
        //   square.style.marginTop = '-1px'
        //   square.style.backgroundColor = this.hexToRgba(colorConfig.theme.colors.hurricane.high, 0.9)
        //   node.appendChild(square)
        //   node.appendChild(textnode)
        //   this.legend.nativeElement.appendChild(node)
        // }
        
        // if(this.legend && this.legend.nativeElement){
        //   var node = document.createElement('div')
        //   var textnode = document.createTextNode('Cat 2 Hu')
        //   node.className = 'mx-4 text-xs text-gray-700 flex items-center'
        //   var square = document.createElement('div')
        //   square.className = 'w-4 h-4 rounded-full mr-2 bg-hurricane-high'
        //   square.style.marginTop = '-1px'
        //   square.style.backgroundColor = this.hexToRgba(colorConfig.theme.colors.hurricane.high, 0.8)
        //   node.appendChild(square)
        //   node.appendChild(textnode)
        //   this.legend.nativeElement.appendChild(node)
        // }
        
        // if(this.legend && this.legend.nativeElement){
        //   var node = document.createElement('div')
        //   var textnode = document.createTextNode('Cat 3 Hu')
        //   node.className = 'mx-4 text-xs text-gray-700 flex items-center'
        //   var square = document.createElement('div')
        //   square.className = 'w-4 h-4 rounded-full mr-2 bg-hurricane-high'
        //   square.style.marginTop = '-1px'
        //   square.style.backgroundColor = this.hexToRgba(colorConfig.theme.colors.hurricane.high, 0.8)
        //   node.appendChild(square)
        //   node.appendChild(textnode)
        //   this.legend.nativeElement.appendChild(node)
        // }
        
        // if(this.legend && this.legend.nativeElement){
        //   var node = document.createElement('div')
        //   var textnode = document.createTextNode('Cat 4 Hu')
        //   node.className = 'mx-4 text-xs text-gray-700 flex items-center'
        //   var square = document.createElement('div')
        //   square.className = 'w-4 h-4 rounded-full mr-2 bg-hurricane-high'
        //   square.style.marginTop = '-1px'
        //   square.style.backgroundColor = this.hexToRgba(colorConfig.theme.colors.hurricane.high, 0.7)
        //   node.appendChild(square)
        //   node.appendChild(textnode)
        //   this.legend.nativeElement.appendChild(node)
        // }
        
        // if(this.legend && this.legend.nativeElement){
        //   var node = document.createElement('div')
        //   var textnode = document.createTextNode('Cat 1 Hu')
        //   node.className = 'mx-4 text-xs text-gray-700 flex items-center'
        //   var square = document.createElement('div')
        //   square.className = 'w-4 h-4 rounded-full mr-2 bg-hurricane-high'
        //   square.style.marginTop = '-1px'
        //   square.style.backgroundColor = this.hexToRgba(colorConfig.theme.colors.hurricane.high, 0.6)
        //   node.appendChild(square)
        //   node.appendChild(textnode)
        //   this.legend.nativeElement.appendChild(node)
        // }
        // var dataLoad = [trace1, trace2, trace3, trace4, trace5, trace6];

        Plotly.animate('bar-plot', {
          data: tracesData,
          layout: this.layout
        }, {
          transition: {
            duration: 500,
            easing: 'cubic-in-out'
          },
          frame: {
            duration: 500
          }
        })
    }

    getGraphLabels() {
      let labelArr = [];
      let len = this.riskData.length;
      if ( len > 0 ) {
        for ( let i=0;i<len;i++ ) {
          if (this.riskType == 'earthquake') {
            labelArr.push(this.riskData[i].title);
          } else {
            labelArr.push('Week '+this.riskData[i].title);
          }
        }
      }
      return labelArr;
    }

    next() {
      if (this.more == 1) {
        this.onNextPreviousRisk.emit('next'); 
      }
    }

    previous() {
      if ( this.currPage > 1 ) {
        this.onNextPreviousRisk.emit('prev'); 
      }
    }

    getYAxisHeight() {
      let len = this.riskGraphData.data.length;
      let data = this.riskGraphData.data;
      let sum = 0;
      if ( len > 0 ) {
        for ( let i=0;i<len;i++ ) {
          sum = data[i].markers.reduce((a, b) => a + b, 0);
          console.log(sum)
          if ( sum > this.YAxisHeight ) {
            this.YAxisHeight = parseFloat(sum.toPrecision(2));
          }
        }
      }
      if(sum>=this.YAxisHeight){
        this.YAxisHeight = this.YAxisHeight+(this.YAxisHeight/10)
      }
      
      console.log(this.YAxisHeight);
    }

    ngAfterViewInit(){
        this.animateChart()
    }
}
