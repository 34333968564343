import { BehaviorSubject, Observable,throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENV } from './../app.config';
import { catchError } from 'rxjs/operators';

@Injectable()
export class UserService {
    private _token: string;
    isImage:BehaviorSubject<any> = new BehaviorSubject<any>(false);
    curSevnadMod:BehaviorSubject<any> = new BehaviorSubject<any>({mm:[],inches:[],ms:[],milesh:[],c:[],f:[],cm:[]})
    searchText:BehaviorSubject<any> = new BehaviorSubject<any>('')
    constructor(private http: HttpClient) {}
    
    fetchuserProfile(body: Object) : Observable<any> {
        return this.http.post(ENV.BASE_API + '/api/user/profile', body);
    }  

    checkIfPasswordIsCorrect(body: Object) : Observable<any> {
      return this.http.post<any>(ENV.BASE_API + '/api/user/checkPasswordOnProfile', body);
    }
    

    changePassword(body: Object) : Observable<any> {
      return this.http.post<any>(ENV.BASE_API + '/api/user/changePasswordOnProfile', body);
    }

    getProfileImage(body: Object): Observable<any> {
      return this.http.post<any>(ENV.BASE_API + '/api/user/getimage', body);
    }

    uploadImage(body: any): Observable<any> {
        return this.http.post<any>(ENV.BASE_API + '/api/user/profileimage', body);
    }

    removeImage(body: Object): Observable<any> {
        return this.http.post<any>(ENV.BASE_API + '/api/user/removeimage', body);
    }

    imagepath(val:any){
      this.isImage.next(val);
    }
    setcurSevnadMod(val:any={mm:[],inches:[],ms:[],milesh:[],c:[],f:[],cm:[]}){
      this.curSevnadMod.next(val);
    }

    termsAndCondition(body: Object) {
      return this.http.post<any>(ENV.BASE_API + '/api/user/terms_and_condition', body);
    }
    changesector(body: Object): Observable<any>{
      return this.http.post<any>(ENV.BASE_API + '/api/user/changesector', body);
    }

    setSearchText(val:any=''){
      this.searchText.next(val);
    }
  

    downloadQuoteCsv(body: Object) {
      return this.http.post<any>(ENV.BASE_API + '/api/order/getquotecsv', body);
    }

    forgot(body:Object){
      body = JSON.stringify(body);
      return this.http.post<any>(ENV.BASE_API + '/api/user/forgot', body);
    }

    reset(body:Object){
      body = JSON.stringify(body);
      return this.http.post<any>(ENV.BASE_API + '/api/user/reset', body);
    }

    setpassword(body:Object){
      body = JSON.stringify(body);
      return this.http.post<any>(ENV.BASE_API + '/api/user/setpassword', body);
    }

    
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(error);
    }

}