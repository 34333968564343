<form [formGroup]="newReportWidget" id="form-report-widget" (ngSubmit)="onSubmit()" class="w-full block bg-white border border-gray-100 rounded-lg border border-gray-300" autocomplete="off" *ngIf="showSearchButton && !resultsPage">
    <div class="flex flex-wrap">
        <div class="flex-1 border-r border-gray-300" *ngIf="!isComplete">
            <label class="py-3 px-4 lg:py-4 lg:px-6 block cursor-pointer relative">
                <div>

                    <div class="text-xs uppercase font-bold text-gray-600">Location</div>
                    <input type="text" #location (focus)="onEditClick()" (blur)="closeResultsLocation()" (change)="callGooglePlaces($event)" (keyup)="changeLocation($event)" placeholder="Where would you like to get a report from?" formControlName="location" class="w-full outline-none cursor-pointer py-1 placeholder-gray-800 text-gray-800 truncate" />
                </div>
                <div class="map-results" *ngIf="locationResults.features && locationResults.features.length">
                    <div *ngFor="let result of locationResults.features">
                        <button class="map-results__result" (click)="onResultsLocationClick(result)"><span class="relative">{{ result.place_name }}</span></button>
                    </div>
                </div>
                <!-- <div class="latlong" (click)="searchByLatLong(location.value)">
                    <a href="javascript:void(0)">{{location.value}}</a>
                  </div> -->
            </label>
        </div>
        <div #containerDates class="flex-1 flex flex-wrap relative">
            <div class="flex-1 border-r border-gray-300">
                <label class="py-4 px-6 block cursor-pointer">
                    <div>
                        <div class="text-xs uppercase font-bold text-gray-600">Start date</div>
                        <input #dateStart (click)="checkIfLocationIsSelected($e)" type="text" readonly="true" name="startDate" class="w-full outline-none cursor-pointer py-1 placeholder-gray-800 text-gray-800 truncate" placeholder="Add start date" />

                    </div>
                </label>
            </div>
            <div class="flex-1">
                <label class="py-4 px-6 block cursor-pointer">
                    <div>
                        <div class="text-xs uppercase font-bold text-gray-600">End date</div>
                        <input #dateEnd (click)="checkIfLocationIsSelected($e)" type="text" readonly="true" name="endDate" class=" w-full outline-none cursor-pointer py-1 placeholder-gray-800 text-gray-800 truncate" placeholder="Add end date" />

                    </div>
                </label>
            </div>
        </div>
        <div class="flex-grow-none p-4 w-40 h-20">
            <app-button>Search</app-button>
        </div>


    </div>
</form>

<form [formGroup]="newReportWidget" id="form-report-widget" (ngSubmit)="onSubmit()" class="w-full block bg-white lower" autocomplete="off" *ngIf="!showSearchButton && !resultsPage">
    <div class="border border-gray-100 rounded-lg border border-gray-300 relative" *ngIf="!isComplete">
        <div class="absolute top-0 right-0 mr-4 mt-4 pointer-events-none z-10 w-4 h-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#1b242e" stroke-linecap="round" stroke-linejoin="round" d="M5.5.5v13" data-name="Line 14"/><path fill="none" stroke="#1b242e" stroke-linecap="round" stroke-linejoin="round" d="M10.5 2.5v13" data-name="Line 15"/><path fill="none" stroke="#1b242e" stroke-linecap="round" stroke-linejoin="round" d="M.5 15.5v-13l5-2 5 2 5-2v13l-5 2-5-2z" data-name="Path 141"/></svg>
        </div>
        <label class="py-4 px-6 block cursor-pointer relative">
            <div>
                <div class="text-xs uppercase font-bold text-gray-600">Location</div>
                <input type="text" #location (focus)="onEditClick()" (blur)="closeResultsLocation()" (change)="callGooglePlaces($event)" (keyup)="changeLocation($event)" placeholder="Where would you like to get a report from?" formControlName="location" class="w-full outline-none cursor-pointer py-1 placeholder-gray-800 text-gray-800 truncate" />
            </div>
            <div class="map-results map-results" *ngIf="locationResults.features && locationResults.features.length">
                <div *ngFor="let result of locationResults.features">
                    <button class="map-results__result" (click)="onResultsLocationClick(result)"><span class="relative">{{ result.place_name }}</span></button>
                </div>
            </div>
        </label>
        <!-- <div class="latlong" *ngIf="isLatLong" (click)="searchByLatLong(location.value)">
                <a href="javascript:void(0)">{{location.value}}</a>
        </div> -->
    </div>

    <div #containerDates class="border border-gray-100 rounded-lg border border-gray-300 flex flex-wrap relative mt-4">
        <div class="absolute top-0 right-0 mr-4 mt-4 pointer-events-none z-10 w-4 h-4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19"><g fill="none" stroke="#1b242e" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" transform="translate(-2.035 -1.5)"><rect width="18" height="16" data-name="Rectangle 80" rx="4" transform="translate(2.535 4)"/><path d="M7.535 6V2" data-name="Line 19"/><path d="M15.535 6V2" data-name="Line 20"/><path d="M2.535 9h18" data-name="Line 21"/><path d="M5.535 13h12" data-name="Line 22"/></g></svg>
        </div>
        <div class="flex-1 border-r border-gray-300">
            <label class="py-4 px-6 block cursor-pointer">
                <div>
                    <div class="text-xs uppercase font-bold text-gray-600">Start date</div>
                    <input #dateStart (click)="checkIfLocationIsSelected($e)" type="text" readonly="true" name="startDate" class="w-full outline-none cursor-pointer py-1 placeholder-gray-800 text-gray-800 truncate" placeholder="Add start date" />

                </div>
            </label>
        </div>
        <div class="flex-1">
            <label class="py-4 px-6 block cursor-pointer">
                <div>
                    <div class="text-xs uppercase font-bold text-gray-600">End date</div>
                    <input #dateEnd (click)="checkIfLocationIsSelected($e)" type="text" readonly="true" name="endDate" class=" w-full outline-none cursor-pointer py-1 placeholder-gray-800 text-gray-800 truncate" placeholder="Add end date" />

                </div>
            </label>
        </div>
    </div>
</form>



