import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';


@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
  @Input() fixedClass: string = '';
    isOpened: boolean = false;
    @Output() toggledMenu: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  toggleView() {
    if(this.isOpened){
        this.fixedClass = 'mt-4 lg:mt-16 mb-3 lg:mb-0 hidden lg:block'
    } else {
        this.fixedClass = 'mt-4 lg:mt-16 mb-3 lg:mb-0 block'
    }
    this.isOpened = !this.isOpened;
    this.toggledMenu.emit(this.isOpened);
  }

}
