import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThresholdService {

    constructor() {
    }

    increaseMinData(minVal, minUnit, weatherHeading, wid) {
        if ( weatherHeading.toLowerCase() == 'daily' || (weatherHeading.toLowerCase() == 'hourly' && (minUnit == 'mm' || minUnit == 'inches') && wid == 1 ) ) {
            if ( minUnit == 'mm' ) {    
                if (minVal < 5) {
                    minVal = parseInt(minVal) + 1;
                } else {
                    minVal = parseInt(minVal) + 5;
                }
            } else {
                if (minVal < 0.2) {
                    minVal = parseFloat(minVal) + 0.1;
                } else {
                    minVal = parseFloat(minVal) + 0.2;
                }
                // if ( Number.isInteger(minVal) == false ) {
                //     minVal = minVal.toFixed(1);
                // }
                minVal = minVal.toFixed(1); // Set threshold values to float format
            }
        } else if ( weatherHeading.toLowerCase() == 'depth' || (weatherHeading.toLowerCase() == 'hourly' && (minUnit == 'cm' || minUnit == 'inches')) ) {
                if ( minUnit == 'cm' ) {
                    if (minVal < 10) {
                        minVal = parseFloat(minVal) + 0.5;
                    } else {
                        minVal = parseInt(minVal) + 5;
                    }
                } else {
                    if (minVal < 4) {
                        minVal = parseFloat(minVal) + 0.2;
                        minVal = minVal.toFixed(1);
                    } else {
                        minVal = parseInt(minVal) + 2;
                    }
                }
        } else if ( weatherHeading == 'weekly' || weatherHeading == 'Weekly' || weatherHeading == 'WEEKLY' ) {
            if ( minUnit == 'mm' ) {
                minVal = parseInt(minVal) + 5;
            } else {
                minVal = parseFloat(minVal) + 0.5;
                minVal = minVal.toFixed(1); // Set threshold values to float format
            }
        } else if (weatherHeading == 'monthly' || weatherHeading == 'Monthly' || weatherHeading == 'MONTHLY' ) {
            if ( minUnit == 'mm' ) {
                minVal = parseInt(minVal) + 10;
            } else {
                minVal = parseInt(minVal) + 1;
                minVal = minVal.toFixed(1); // Set threshold values to float format
            }
        } else if ( weatherHeading == 'Minimum' || weatherHeading == 'Mean' || weatherHeading == 'Maximum' || weatherHeading == 'minimum' || weatherHeading == 'mean' || weatherHeading == 'maximum' || weatherHeading == 'MINIMUM' || weatherHeading == 'MEAN' || weatherHeading == 'MAXIMUM' ||  weatherHeading.toLowerCase() == 'hourly') {
            if ( minUnit == 'c' || minUnit == 'C' ) {
                minVal++;    
            } else {
                minVal = parseInt(minVal) + 2; 
            }
        } else if ( weatherHeading == 'Speed' || weatherHeading == 'speed' || weatherHeading == 'Gust' || weatherHeading == 'gust' || weatherHeading.toLowerCase() == 'hourly_gust' || weatherHeading.toLowerCase() == 'hourly_speed' ) {
            if ( minUnit == 'm/s' ) {
                minVal = parseFloat(minVal) + 0.25;
                minVal = minVal.toFixed(2); // Set threshold values to float format
            } else {
                minVal++;
            }
        } else {
            minVal++;
        }
        return minVal;
    }

    decreaseMinData(minVal, minUnit, weatherHeading, wid) {
        if ( weatherHeading == 'daily' || weatherHeading == 'Daily' || weatherHeading == 'DAILY' || (weatherHeading.toLowerCase() == 'hourly' && (minUnit == 'mm' || minUnit == 'inches') && wid == 1 ) ) {
            if ( minUnit == 'mm' ) {
                if (minVal > 5) {
                    minVal = parseInt(minVal) - 5;
                } else {
                    minVal = parseInt(minVal) - 1;
                }
            } else {
                if ( minVal > 0.2 ) {
                    minVal = parseFloat(minVal) - 0.2;
                } else {
                    minVal = parseFloat(minVal) - 0.1;
                }
                minVal = minVal.toFixed(1); // Set threshold values to float format
            }
            // if ( Number.isInteger(minVal) == false ) {
            //     minVal = minVal.toFixed(1);
            // }
            if (minVal < 0) {
                minVal = 0;
            }
        } else if ( weatherHeading == 'depth' || weatherHeading == 'Depth'|| weatherHeading == 'DEPTH' || (weatherHeading.toLowerCase() == 'hourly' && (minUnit == 'cm' || minUnit == 'inches')) ) {
            if ( minUnit == 'cm' ) {
                if (minVal > 10) {
                    minVal = parseInt(minVal) - 5;
                } else {
                    minVal = parseFloat(minVal) - 0.5;
                }
            } else {
                if (minVal > 4) {
                    minVal = parseInt(minVal) - 2;
                } else {
                    minVal = parseFloat(minVal) - 0.2;
                    minVal = minVal.toFixed(1);
                }
            }
            if (minVal < 0 || minVal == 0) {
                minVal = 0;
            }    
        } else if ( weatherHeading == 'weekly' || weatherHeading == 'Weekly' || weatherHeading == 'WEEKLY' ) {
            if ( minUnit == 'mm' ) {
                minVal = parseInt(minVal) - 5;
            } else {
                minVal = parseFloat(minVal) - 0.5;
                minVal = minVal.toFixed(1); // Set threshold values to float format
            }
            if (minVal < 0) {
                minVal = 0;
            }
        } else if (weatherHeading == 'monthly' || weatherHeading == 'Monthly' || weatherHeading == 'MONTHLY' ) {
            if ( minUnit == 'mm' ) {
                minVal = parseInt(minVal) - 10;
            } else {
                minVal = parseInt(minVal) - 1;
                minVal = minVal.toFixed(1); // Set threshold values to float formats
            }
            if (minVal < 0) {
                minVal = 0;
            }
        } else if ( weatherHeading == 'Minimum' || weatherHeading == 'Mean' || weatherHeading == 'Maximum' || weatherHeading == 'minimum' || weatherHeading == 'mean' || weatherHeading == 'maximum' || weatherHeading == 'MINIMUM' || weatherHeading == 'MEAN' || weatherHeading == 'MAXIMUM' || weatherHeading.toLowerCase() == 'hourly' ) {
            if ( minUnit == 'c' || minUnit == 'C' ) {
                minVal--;
            } else {
                minVal = parseInt(minVal) - 2;
            }
        } else if ( weatherHeading == 'Speed' || weatherHeading == 'speed' || weatherHeading == 'Gust' || weatherHeading == 'gust' || weatherHeading.toLowerCase() == 'hourly_gust' || weatherHeading.toLowerCase() == 'hourly_speed' ) {    
            if ( minUnit == 'm/s' ) {
                minVal = parseFloat(minVal) - 0.25;
                minVal = minVal.toFixed(2); // Set threshold values to float format
            } else {
                minVal--;
            }
            if (minVal < 0) {
                minVal = 0;
             }
        } else {
            minVal--;
            // if ( weatherHeading == 'speed' || weatherHeading == 'gust' ) {
            //     if (minVal < 0) {
            //        minVal = 0;
            //     }
            // }
        }
        return minVal;
    }

}