
<div class="relative">
    <div class="w-full flex flex-wrap absolute inset-0 w-full">
        <div class="w-16"></div>
        <div class="flex-1 guides relative">
            <span class="pos-25"></span>
            <span class="pos-50"></span>
            <span class="pos-75"></span>
        </div>
    </div>
    <div class="w-full flex flex-wrap my-2" *ngFor="let bar of bars;">
        <div class="w-16 font-semibold text-sm">
            {{ bar.title }}
        </div>
        <div class="flex-1 flex items-center" *ngIf="bar.sev=='N/A'">
            <div class="bar-time" #tooltipElem [tooltip]="bar.tooltip">
                <span class="bar-time__bgray"></span>
            </div>
        </div>
        <div class="flex-1 flex items-center" *ngIf="bar.sev !='N/A'">
            <div class="bar-time" #tooltipElem [tooltip]="bar.tooltip">
                <span class="bar-time__bg"></span>
                <span #barsev data="{{ bar.sev }}" class="bar-time__elem bar-time__elem--sev"></span>
                <span #barmod data="{{ bar.mod }}" class="bar-time__elem bar-time__elem--mod"></span>
            </div>
        </div>
    </div>
    <div class="w-full flex flex-wrap absolute bottom-0 w-full">
        <div class="w-16"></div>
        <div class="flex-1 flex axis-bottom text-xs text-gray-500 font-semibold bg-white pt-1 mt-4">
            <span class="pos-25">25%</span>
            <span class="pos-50">50%</span>
            <span class="pos-75">75%</span>
        </div>
    </div>

</div>
<div class="flex justify-center w-full mt-12 relative">
    <ul class="pager flex items-center justify-center">

        <li class="activeprevious mr-3">
            <a (click) = "prevpage()" class="border cursor-pointer border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700">
                <svg class="w-3 h-3 text-gray-600 fill-current stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polyline fill="none" stroke-miterlimit="10" points="16,1 8,12 16,23 "></polyline></g></svg>
            </a>
        </li>
        
        <li *ngIf="hourlytype=='hourly'">
            
            <input #dateStartval type="text" readonly="true" class="text-center max-w-100" placeholder="Date" value="{{date}}" />
            
        </li>
        <li class="activenext ml-3">
            <a (click) ="nextpage()" class="border cursor-pointer border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-600 fill-current stroke-current" viewBox="0 0 24 24"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polyline fill="none" stroke-miterlimit="10" points="8,1 16,12 8,23 "></polyline></g></svg>
            </a>
        </li>
    </ul>
    <div #containerDates class="calContnr"></div>
</div>
