<ng-container *ngIf="currentType != 'wildfire'">
    <div class="flex flex-wrap justify-between items-center -mx-3 mb-3 mt-6">
        <div class="px-3 font-semibold text-lg">
            <span class="text-gray-500 text-sm mr-5 font-light">Map</span>
            {{weekText}}
        </div>
        <div class="ml-3 px-3 flex items-center">
            <button *ngIf="riskImgNames.length > 1 && riskindex > 0" (click)="prev('prev')" class="prev-button">
            </button>
            <button *ngIf="riskImgNames.length > 1 && showRightScroll" (click)="next(next)" class="next-button">
            </button>
    
        </div>
    </div>
    
    <div class="border border-gray-300 rounded-lg relative overflow-hidden">
            <!-- <img class="w-auto mx-auto" src="assets/images/imagestest/map.jpg" class="w-full h-auto"> -->
            <img class="w-auto mx-auto" [src]="imgPath" class="w-full h-auto">
    </div>
</ng-container>
<ng-container *ngIf="currentType == 'wildfire'">
    <div class="border border-gray-300 rounded-lg relative overflow-hidden">
        <span>No Map view for Wildfire</span>
    </div>
</ng-container>