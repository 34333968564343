import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { routeTransitionAnimations } from './animations/pageAnimations';
import { AppState, selectAuthState } from './store/app.states';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LoaderService } from './services/loader.service';
import { UserService } from './services/user.service';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    routeTransitionAnimations
  ],
})


export class AppComponent implements OnInit  {
    title = 'MetSwift';
    isTitleEditable: boolean = false;
    getState: Observable<any>;
    isAuthenticated: boolean = false;
    showLoader: boolean;
    termcond:any = null;
    signpage:any = null;
    setpassword:any=null;
    setpasswordpage=false;
    profileData;
    constructor(
      private userService :UserService,
      private store: Store<AppState>,
      private loader: LoaderService,
      private router:Router
    ) {
      this.getState = this.store.select(selectAuthState);
      this.getState.subscribe((state) => {
        this.isAuthenticated = state.isAuthenticated;
        if(state.user){
          this.fetchProfile()
          this.termcond = state.user.termsAndCond;
          this.setpassword = state.user.setpassword;
        }
        
      })
      this.loader.status.subscribe((val: boolean) => {
        this.showLoader = val;
      });
      
    }

    ngOnInit() {
      this.setpasswordpage=false;
      this.getState.subscribe((state) => {
        if(state.user){
          this.setpassword = state.user.setpassword;
        }
      })
    }

    onActivate(page) {
        this.isTitleEditable = page.showEditableTitle
        if(page && page.titleField){
            this.title = page.titleField
        }
        window.scroll(0,0);
    }

    prepareRoute(outlet: RouterOutlet) {      
      this.getState.subscribe((state) => {
        let month = 0;


        if(month){
            let a=moment(this.profileData.passwordupdateddate);
            let b=moment();
            let month1 = b.diff(a, 'months');
        }

        if(this.profileData){
          if(this.profileData.passwordupdateddate){
            let a=moment(this.profileData.passwordupdateddate);
            let b=moment();
            month = b.diff(a, 'months');
          }
        }
        
        
        if(state.isAuthenticated){
          if(!state.user.termsAndCond){
            if(outlet.activatedRouteData['animationState'] != "TermAndCondition"){
              this.router.navigateByUrl('/termandcondition');
            }
          } else if(state.user.termsAndCond == 1 && ((state.user.setpassword != 1 && this.profileData.setpassword != 1) || month > 6)){
            if(outlet.activatedRouteData['animationState'] != "Setpassword"){
              this.router.navigateByUrl('/setpassword');
              this.setpasswordpage = true;
            }
          }
          if(((state.user.setpassword == 1 || this.profileData.setpassword== 1) && month<6) && outlet.activatedRouteData['animationState'] == "Setpassword"){
            this.router.navigateByUrl('/dashboard');
          }

        }
        if(outlet.activatedRouteData['animationState'] == "SignIn"){
          this.signpage = true;
        } else{
          this.signpage = null;
        }
      })
      
      return outlet &&
        outlet.activatedRouteData &&
        outlet.activatedRouteData['animationState'];
    }

    fetchProfile() {
      this.loader.display(true);
      this.userService.fetchuserProfile({})
        .subscribe(
          data => {
            this.loader.display(false);
            let successData = data;
            this.profileData = successData.profile;
            if(!this.setpassword){
              console.log(this.profileData.setpassword)
              this.setpassword = this.profileData.setpassword;
            }
            let month = 0;
            if(this.profileData.passwordupdateddate){
              let a=moment(this.profileData.passwordupdateddate);
              let b=moment();
              month = b.diff(a, 'months');
            }
            if(month>=6){
              this.setpassword =null;
            }
          },
          error => {
            let errordata = error;
          });
    
  }

    closeGraphOverlay(){}
}
