<div *ngIf="1" class="lg:flex-grow p-4 md:p-6 lg:p-12 relative">
  <app-backgrounds></app-backgrounds>
  <div class="w-full relative z-10">
    <div
      class="flex flex-wrap -mx-4 lg:-mx-6 justify-between mb-3 md:mb-5 lg:mb-8"
    >
      <div class="px-4 lg:px-6 w-auto lg:w-1/2">
        <h1 class="font-semibold text-xl lg:text-2xl">{{ titleField }}</h1>
      </div>
      <div class="px-4 lg:px-6 w-auto flex flex-wrap items-center">
        <app-search-reports-button></app-search-reports-button>
      </div>
    </div>
    <div class="w-full page-content">
      <h4 class="font-semibold mb-1 text-orange-base">
        {{(unlimited != 1)?"Insight definition":"Available data"}}
      </h4>
      <p class="mb-2" *ngIf="unlimited != 1">
        An ‘Insight’ is defined as a specific number of days of data provided for a specified peril type.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base" *ngIf="unlimited != 1">
        For weather perils, this period is 31 days. Weather perils are as follows: rainfall, wind, temperature, snow depth, hail, dust, fog, storm.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base" *ngIf="unlimited != 1">
        For Natural Catastrophe (NatCat) perils, this period is 7 days. NatCat perils are as follows: earthquake, tropical cyclone, tornado, wildfire.
      </p>
      <table class="tg1">
        <thead>
          <tr>
            <th class="tg-0lax1 bgnbold" rowspan="2">Model</th>
            <th class="tg-0lax1" colspan="2">Statistical</th>
            <th class="tg-0lax1" rowspan="2">NatCat<br /></th>
          </tr>
          <tr>
            <th class="tg-0lax1">Standard</th>
            <th class="tg-0lax1">Advanced</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="tg-0lax1 bgnbold">Data Period</td>
            <td class="tg-0lax1">5 Years</td>
            <td class="tg-0lax1">
              2 Years
            </td>
            <td class="tg-0lax1">5 Years</td>
          </tr>
          <tr *ngIf="unlimited != 1">
            <td class="tg-0lax1 bgnbold">Insight Period</td>
            <td class="tg-0lax1" colspan="2">31 days</td>
            <td class="tg-0lax1">7 days</td>
          </tr>
          <tr>
            <td class="tg-0lax1 bgnbold">Available Datasets</td>
            <td class="tg-0lax1">All years <br/>Last 10 years</td>
            <td class="tg-0lax1">Climatologically <br/> similar years</td>
            <td class="tg-0lax1">All years <br/>Since 2000* <br/>Since 1900**</td>
          </tr>
          <tr>
            <td class="tg-0lax1 bgnbold">Perils</td>
            <td class="tg-0lax1" colspan="2">
              <span style="text-decoration: underline;">Parametric</span> <br/>Rain, Temperature, Wind, Snow<br /><span style="text-decoration: underline;">Occurrence</span> <br/>Hail, Fog,
              Dust, Storm
            </td>
            <td class="tg-0lax1">
              <span style="text-decoration: underline;">Occurrence</span> <br/>Earthquakes, Tropical Cyclone, Tornado, Wildfire
            </td>
          </tr>
          <tr>
            <td class="tg-0lax1 bgnbold">Units</td>
            <td class="tg-0lax1" colspan="2">
              Rainfall: mm or inches<br />Wind: mph or ms<br />Temperature: C or F<br />Snow Depth: cm
              or inches<br />
            </td>
            
            <td class="tg-0lax1">Categories based on locally used system</td>
          </tr>
          <tr>
            <td class="tg-0lax1 bgnbold">Peril Sub-Types</td>
            <td class="tg-0lax1" colspan="2">
              Rainfall: Daily / Weekly / Monthly Total<br />Wind: Wind Speed / Wind Gust <br />Temperature: Minimum / Mean / Maximum<br />
            </td>
            <td class="tg-0lax1">N/A</td>
          </tr>
          <tr>
            <td class="tg-0lax1 bgnbold">View</td>
            <td class="tg-0lax1" colspan="2">Overview / Graph / Table</td>
            <td class="tg-0lax1">Overview / Graph / Table / Map</td>
          </tr>
          <tr>
            <td class="tg-0lax1 bgnbold">PDF/CSV Export</td>
            <td class="tg-0lax1" colspan="3">Yes</td>
          </tr>
        </tbody>
      </table>
      *Since 2000 option is only available for tropical cyclone, wildfire and tornado data <br> 
      ** Since 1900 option is only available for earthquake data
      <h4 class="font-semibold mb-1 text-orange-base">
        About our analysis
      </h4>
      <p class="font-normal mb-1 text-sm md:text-base">
        Our platform provides you with a risk score for a given peril anywhere in the world for any dates within the data period (see table above). This is based on assessments of long-term observational datasets and, for the statistical advanced model, climatological analysis of large scale atmospheric features.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        All of our analysis is quantitative and allows you to request specific date ranges and threshold limits to determine the risk score that best represents your request.
      </p>
      
      <h4 class="font-semibold text-orange-base" style="margin-bottom: 0;">The analysis suite</h4>
      
      <h5 class="font-semibold text-base">Statistical Standard</h5>

      <p class="font-normal mb-1 text-sm md:text-base">
        The statistical standard model analyses the entire historical dataset of observations from the weather station selected for your search. By comparing against your provided thresholds, it returns a climatological baseline risk that does not include any climate trend or atmospheric prediction component. Data can also be filtered to only evaluate based on the last 10 years, rather than the entire dataset.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        This value is therefore useful for determining a typical range of conditions, and for comparing to the statistical advanced model outputs to identify if the climatological analysis is trending towards higher or lower risk for your period.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">In addition, the lowest, mean, and highest observed values in the entire dataset are provided.</p>
      
      <p class="font-normal mb-1 text-sm md:text-base">Thresholds are not available for hail, fog, dust, or storm risk options, as these are calculated on an occurrence basis and have no parametric factor.</p>

      <h5 class="font-semibold text-base">Statistical Advanced</h5>

      <p class="font-normal mb-1 text-sm md:text-base">
        This model gives higher weighting to observations from a set of historical years determined to be most useful for predicting your dates of interest.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        This is achieved using an in-depth analysis of large-scale phenomena, such as the El Niño Southern Oscillation (ENSO), North Atlantic Oscillation (NAO), and solar cycle. All of which have a high degree of long-term predictability.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        Data is presented in the same format as the statistical standard model.
      </p>
      
      <h5 class="font-semibold text-base">Standard Risk – Tropical Cyclones</h5>

      <p class="font-normal mb-1 text-sm md:text-base">
        Historical tracks for tropical cyclones across the globe are mapped, and their impacts evaluated, to provide a location specific risk value on a week-by-week basis. These tracks are available for storms in the past 7 to 16 decades (varies by region). Data can also be filtered to only use data since 2000.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        Categories are given on a regional basis, (for example, a “Category 5 Hurricane” in the United States or a “Super Typhoon” in China), with risks calculated for each individual category and for any category separately.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        Maps are also given for the country, or region of the country, that you are interested in, showing weekly evaluated areas of risk.
        </p>

      <p class="font-normal mb-1 text-sm md:text-base">
        This data is only available if your location has non-zero risk during your event, with the option appearing greyed out if the location has risk outside of the dates of your event, but not during it.
      </p>
      
      <h5 class="font-semibold text-base">Standard Risk – Tornadoes</h5>

      <p class="font-normal mb-1 text-sm md:text-base">
        This data is only available within the United States of America.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        Historical tornado tracks are mapped, and their impacts evaluated, to provide location specific risk values on a week-by-week basis. These tracks are available for tornadoes since 1950. Data can also be filtered to only use data since 2000.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        Due to the nature of tornadoes affecting only a relatively narrow area directly, a location is considered as being “impacted” if it would have been close enough to the path of the tornado to have likely been placed under a tornado warning. This is the point at which people in the area are advised to take action, such as evacuating to a shelter.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        Each category within the <a class="text-rainfall-high" href="https://www.weather.gov/oun/efscale" target="_blank">Enhanced Fujita scale</a> is evaluated individually, alongside an “any category” risk.
      </p>
      

      <p class="font-normal mb-1 text-sm md:text-base">
        Weekly risk maps are also provided for the region of the United States in which your location can be found.
      </p>

      <p class="font-normal mb-1 text-sm md:text-base">
        This data is only available if your location has non-zero risk during your event dates, and is shown as a greyed out option if your location has risk outside of your dates, but not during.
      </p>

      <h5 class="font-semibold text-base">Standard Risk – Earthquakes</h5>

      <p class="font-normal mb-1 text-sm md:text-base">
        Historical records of earthquakes across the world, extending back to 2150BCE, are assessed for your location to provide an evaluation of the risk of a significant earthquake affecting your location at any point during your event dates. Data can also be filtered to only use data since 1900.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        Since earthquakes have no seasonality, this is calculated as a single risk value for the year, which is then converted to a value for event based on the length of your event.
      </p>

      <p class="font-normal mb-1 text-sm md:text-base">
        The categories used are from the <a class="text-rainfall-high" target="_blank" href="https://www.usgs.gov/faqs/moment-magnitude-richter-scale-what-are-different-magnitude-scales-and-why-are-there-so-many">Moment Magnitude Scale</a> and risks have been calculated for earthquakes of magnitude 4 or above.</p>

      <p class="font-normal mb-1 text-sm md:text-base">A map is also provided for the country or region of a country in which your event is located, showing areas of risk.</p>
        <p class="font-normal mb-1 text-sm md:text-base">This data is only available if your location has risk.</p>


      <h5 class="font-semibold text-base">Standard Risk – Wildfires</h5>

      <p class="font-normal mb-1 text-sm md:text-base">
        Utilising a model created by analysing global atmospheric re-analysis data, ground and topographical factors, and recorded instances of wildfire in the United States of America, risk values have been estimated on a week-by-week basis.</p>
      <p class="font-normal mb-1 text-sm md:text-base">
        The risk value represents the likelihood of an existing fire developing into a wildfire (covering at least 10 acres). This does not provide any estimation of the risk of a fire starting in the first place, as these are often not caused by atmospheric phenomena.
      </p>
    </div>
  </div>
</div>

<div *ngIf="0" class="lg:flex-grow p-4 md:p-6 lg:p-12 relative">
  <app-backgrounds></app-backgrounds>
  <div class="w-full relative z-10">
    <div
      class="flex flex-wrap -mx-4 lg:-mx-6 justify-between mb-3 md:mb-5 lg:mb-8"
    >
      <div class="px-4 lg:px-6 w-auto lg:w-1/2">
        <h1 class="font-semibold text-xl lg:text-2xl">{{ titleField }}</h1>
      </div>
      <div class="px-4 lg:px-6 w-auto flex flex-wrap items-center">
        <app-search-reports-button></app-search-reports-button>
      </div>
    </div>
    <div class="w-full page-content">
      <h4 class="font-semibold mb-1 text-orange-base">
        Insight Definition
      </h4>
      <p class="mb-2">
        A platform ‘Insight’ relates to a specific timespan of information
        provided, based on the risk model and peril selected.
      </p>
      <h5 class="font-semibold text-base">Weather Insights= 31 days</h5>
      <p class="font-normal mb-1 text-sm md:text-base">
        [Rain, Temperature, Wind, Snow, Hail, Fog, Dust, and T-Storm]These can
        be STATISTICAL or PREDICTION insights.
      </p>
      <h5 class="font-semibold text-base">NatCat Insights = 7 days</h5>
      <p class="font-normal mb-1 text-sm md:text-base">
        [Earthquake, Wildfire, Tropical Cyclone, and Tornado]These (currently)
        only include a single type of model which Is called a RISK model.
      </p>
      <table class="tg">
        <thead>
          <tr>
            <th class="tg-0lax bgnbold" rowspan="2">Model</th>
            <th class="tg-0lax" colspan="5">WEATHER INSIGHT</th>
            <th class="tg-0lax" rowspan="2">NATCAT INSIGHT<br /></th>
          </tr>
          <tr>
            <th class="tg-0lax" colspan="2">STATISTICAL</th>
            <th class="tg-0lax" colspan="3">PREDICTION</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="tg-0lax bgnbold">Option</td>
            <td class="tg-0lax">Standard</td>
            <td class="tg-0lax">
              Advanced<br />(which includes the standard model for free)
            </td>
            <td class="tg-0lax">Standard</td>
            <td class="tg-0lax">Advanced</td>
            <td class="tg-0lax">Advanced AI</td>
            <td class="tg-0lax">Risk</td>
          </tr>
          <tr>
            <td class="tg-0lax bgnbold">Insight Period</td>
            <td class="tg-0lax">31 days</td>
            <td class="tg-0lax">31 days</td>
            <td class="tg-0lax">31 days</td>
            <td class="tg-0lax">31 days</td>
            <td class="tg-0lax">31 days</td>
            <td class="tg-0lax">7 days</td>
          </tr>
          <tr>
            <td class="tg-0lax bgnbold">Data</td>
            <td class="tg-0lax">All years or last 10 years</td>
            <td class="tg-0lax">similar Years</td>
            <td class="tg-0lax">All years</td>
            <td class="tg-0lax">Similar years</td>
            <td class="tg-0lax">Blended</td>
            <td class="tg-0lax">
              All years & (since 1900 since 2000 since 2000 NA)
            </td>
          </tr>
          <tr>
            <td class="tg-0lax bgnbold">Perils</td>
            <td class="tg-0lax" colspan="2">
              Parametric:Rain Temperature Wind Snow<br />Occurrence:Hail Fog
              Dust Storm
            </td>
            <td class="tg-0lax" colspan="3">Rain Temperature Wind</td>
            <td class="tg-0lax">
              Earthquakes Tropical Cyclone Tornado Wildfire
            </td>
          </tr>
          <tr>
            <td class="tg-0lax bgnbold">Units</td>
            <td class="tg-0lax" colspan="2">
              Rain:mm to inches<br />Temp:C to F<br />Wind:mph to ms<br />Snow:cm
              to inches<br />Extreme:%
            </td>
            <td class="tg-0lax" colspan="3">
              Rain:mm to inches<br />Temp:C to F<br />Wind:mph to ms
            </td>
            <td class="tg-0lax">Percentage Count</td>
          </tr>
          <tr>
            <td class="tg-0lax bgnbold">Type</td>
            <td class="tg-0lax" colspan="2">
              Rain:Daily-Weekly-Monthly<br />Temp:Min-Mean-Max<br />Wind:Speed-Gust<br />Snow:Depth<br />Extreme:Daily
            </td>
            <td class="tg-0lax" colspan="3">Weekly-Monthly</td>
            <td class="tg-0lax">Weekly</td>
          </tr>
          <tr>
            <td class="tg-0lax bgnbold">View Options</td>
            <td class="tg-0lax" colspan="2">Overview Graph Table</td>
            <td class="tg-0lax" colspan="3">Overview Graph Table</td>
            <td class="tg-0lax">Overview Graph Table Map</td>
          </tr>
          <tr>
            <td class="tg-0lax bgnbold">PDF/CSV Export</td>
            <td class="tg-0lax" colspan="2">Yes</td>
            <td class="tg-0lax" colspan="3">Yes</td>
            <td class="tg-0lax">Yes</td>
          </tr>
          <tr>
            <td class="tg-0lax bgnbold">Period</td>
            <td class="tg-0lax" colspan="2">5 Years(2 Years - Advanced)</td>
            <td class="tg-0lax" colspan="3">5 Years(2 Years - Advanced AI)</td>
            <td class="tg-0lax">1 Year</td>
          </tr>
        </tbody>
      </table>

      <h4 class="font-semibold mb-1 text-orange-base">
        ABOUT OUR ANALYSIS
      </h4>
      <p class="font-normal mb-1 text-sm md:text-base">
        This platform provides the peril risk score for anywhere in the world
        driven by assessment of all years, climate similar ‘most useful’ years
        and the most recent decade to provide varying risk assessments driven by
        data selection. All analysis is quantitative and user specific - enabled
        via user input options to select a date range and set limits whereby
        loss/impact is deemed to occur. Specific data summaries are also fed
        into an AI predictive model which learns from the most relevant
        behaviour and applies climate change logic, locally providing a weekly
        or monthly estimate for the weather extremes for Rain, Temperature and
        Wind.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        Via an adaptation of a mapped based search engine, users can prescribe
        building level information to return accurate, local information
        about each search including Height ASL – in important factor for
        determining weather risk.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        Results for all analysis is available in under 30 seconds.
      </p>
      <h4 class="font-semibold text-orange-base" style="margin-bottom: 0;">THE ANALYSIS SUITE</h4>
      <h4 class="font-semibold mb-1 text-gray-600" style="margin-top: 0;">
        WEATHER CONDITIONS
      </h4>
      <h5 class="font-semibold text-base">STANDARD STATISTICAL</h5>

      <p class="font-normal mb-1 text-sm md:text-base">
        Our statistical models analyse historical data to ascertain the risk
        that conditions cross your moderate or severe impact thresholds. They
        also determine the lowest, mean, and highest observed values.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        In standard guise, all available location-specific historical data is
        assessed. This provides a ‘climate baseline’, which is useful for
        establishing the typical range of conditions but has relatively limited
        predictive value.
      </p>
      <h5 class="font-semibold text-base">ADVANCED STASTICAL</h5>

      <p class="font-normal mb-1 text-sm md:text-base">
        The advanced version weights the analysis toward ‘most useful’
        historical years, determined through assessment of large-scale phenomena
        that drive global weather patterns.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        Specialist climatological research has been employed to create a model
        that has proven skill out to long lead times:
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        “In May 2020, testing found that out to two year’s lead time, our
        Advanced Statistical model predictions are <b>more accurate overall for 98%
        of 153 countries</b> when tested against a 2000-2020 average for predictions
        initialised in each month of 2001-2019”.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        This provides you with a more accurate risk perception, when compared to
        the standard model.
      </p>
      <h5 class="font-semibold text-base">AI PREDICTION</h5>

      <p class="font-normal mb-1 text-sm md:text-base">
        MetSwift have developed a distinctive artificial intelligence that
        understands hyperlocal weather behaviour and how it relates to both
        climate trends and shorter-term cycles.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        This continually learning neutral network has been developed as a
        combined effort by meteorologists, data practitioners, and business
        professionals. Through self-assessment, it repeatedly refines itself
        until the best performance is achieved.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        Predictions are available on a weekly basis out to 52 weeks ahead, and
        monthly basis out to 60 months.
      </p>

      <h5 class="font-semibold text-base">ADVANCED AI PREDICTION</h5>

      <p class="font-normal mb-1 text-sm md:text-base">
        Here, the Advanced AI model meets the research underpinning our Advanced
        Statistical model. With this additional information, predictions achieve
        even greater accuracy.
      </p>
      <h5 class="font-semibold text-base mb-0 uppercase">Natural Catastrophes</h5>
      <p class="font-normal mb-1 text-sm md:text-base">
        
        MetSwift offer instantaneous insights into the risk of impact from
        tropical cyclones (using regional categories e.g. hurricanes, typhoons…)
        earthquakes, and wildfires. For the USA, we also include tornadoes.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        On our platform, you can Access hyperlocal probabilities and historical
        event details, and assess the regional risk level using regional mapped
        views.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        Currently purely statistical, but with predictive developments coming
        soon.
      </p>
      <h5 class="font-semibold text-base">TROPICAL CYCLONES AND TORNADOES</h5>

      <p class="font-normal mb-1 text-sm md:text-base">
        Historical impacts are aggregated and reported as the risk of at least
        one occurring within the week(s) of your enquiry. Tropical cyclone hits
        are graded according to locally accepted classifications (e.g.
        <a class="text-rainfall-high font-normal" href="https://www.nhc.noaa.gov/aboutsshws.php#:~:text=The%20Saffir%2DSimpson%20Hurricane%20Wind,Scale%20estimates%20potential%20property%20damage." target="_blank">Saffir-Simpson Scale</a> in the US), while the <a class="text-rainfall-high" href="https://www.weather.gov/oun/efscale" target="_blank">Enhanced Fujita Scale</a> is
        applied to tornadoes.
      </p>

      <p class="font-normal mb-1 text-sm md:text-base">
        Tropical cyclone data extends back to between 1857 and 1949, depending
        on the region.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        US Tornado data reaches back to 1950.
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        Such long datasets warrant a high level of confidence to your risk
        assessment.
      </p>
      <h5 class="font-semibold text-base">EARTHQUAKES</h5>

      <p class="font-normal mb-1 text-sm md:text-base">
        Aggregated across all historical years and reported as the risk of at
        least one occurring within the week(s) relating to your enquiry. The
        severity is graded according to the <a class="text-rainfall-high" href="https://www.usgs.gov/faqs/moment-magnitude-richter-scale-what-are-different-magnitude-scales-and-why-are-there-so-many" target="_blank"> Moment Magnitude Scale.</a>
      </p>
      <p class="font-normal mb-1 text-sm md:text-base">
        Data extends back to 2150 BC and is filtered to ‘significant’
        earthquakes: Magnitude 4 and above.
      </p>
      <h5 class="font-semibold text-base">WILDFIRES</h5>

      <p class="font-normal mb-1 text-sm md:text-base">
        Risk values have been estimated worldwide using a model derived from the relation of historical US wildfires of at least 10 acres in size, to weather conditions and topographical factors.</p>
      <p class="font-normal mb-1 text-sm md:text-base">
        This risk value represents the likelihood of an existing fire developing into a wildfire event (at least 10 acres). This does not provide any estimation of the risk of a fire starting, only of a localised fire developing into a wildfire.
      </p>
    </div>
   
  </div>
</div>
