import { Component, OnInit, ViewChildren, QueryList, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import tippy from 'tippy.js'
import * as map from 'lodash/map'
import { LogoutService } from '../../services/logout.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { LoaderService } from '../../services/loader.service';
import { PortfolioService } from '../../services/portfolio.service';
import { DashboardService } from '../../services/dashboard.service';
import { NewEnquiryService } from '../../services/newenquiry.service';
import { selectAuthState } from '../../store/app.states';
import { Store } from '@ngrx/store'
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-my-portfolio',
  templateUrl: './my-portfolio.component.html',
  styleUrls: ['./my-portfolio.component.scss']
})

export class MyPortfolioComponent implements OnInit {
  titleField: string = 'My portfolio';
  showFilter: boolean = true;
  public isSuperAdOrAdmin: boolean = false;
  public isTrialUser: boolean = false;
  public loadData: boolean = false;
  public currentPage = 1;
  public filterObj: any = {};
  public viewas: string = "";
  public viewasUserName: string = "";
  public alertCount: any = 0;
  public severeCount: any = 0;
  public totalCount: any;
  public totalInsightCount: number = 0;
  public availFilterData: any;
  public recommendedFilterData: any;
  public resultData: any;
  public numOfPages;
  public pages = [];
  public overlayFlag = false;
  public totalRiskValue: any;
  public totalSpent: any;
  public showdata = false;
  public infoText: any;
  public showInfo: boolean = false;
  public currentDate: any = new Date();
  getState: Observable<any>;
  username: any;
  countryWiseHurricaneName: any;
  hurricaneName;
  hurricaneCategories: any;
  currentIndex: number;
  currentIndexMobile: number;

  groupFilterList = [];
  eventFilterList = [];
  contractFilterList = [];
  weatherFilterList = [];
  countryFilterList = [];
  stateFilterList = [];
  monthFilterList = [];
  statusFilterList = [];
  decisionFilterList = [];
  costItemFilterList = [];
  riskFilterList = [];
  eventTypes = [];
  reportItems = [{ id: "yes", itemName: "Yes" }, { id: "no", itemName: "No" }];

  selectedGroupFilterItems = [];
  selectedEventsFilterItems = [];
  selectedContractsFilterItems = [];
  selectedWeathersFilterItems = [];
  selectedModelsFilterItems = [];
  selectedPostcodesFilterItems = [];
  selectedCountriesFilterItems = [];
  selectedStatesFilterItems = [];
  selectedMonthsFilterItems = [];
  selectedRsiFilterItems = [];
  selectedDecisionFilterItems = [];
  selectedRiskFilterItems = [];
  
  selectedCountriesFilterItemsPrev = [];
  selectedStatesFilterItemsPrev = [];
  selectedMonthsFilterItemsPrev = [];
  selectedWeathersFilterItemsPrev = [];
  selectedRiskFilterItemsPrev = [];
  

  selectedStatusFilterItems = [];
  selectedCostItemsFilterItems = [];
  selectedEventTypes = [];
  selectedReportYesNo = "";
  selectedReport = [];
  dropdownList = [];
  selectedItems = [];
  countrySettings = {};
  weatherSettings = {};
  stateSettings = {};
  monthSettings = {};
  statusSettings = {};
  constidSettings = {};
  decisionSettings = {};

  public showGroups: boolean = false;
  public showEvents: boolean = false;
  public showPostcodes: boolean = false;
  public showCountries: boolean = false;
  public showStates: boolean = false;
  public showMonths: boolean = false;
  public showCostitems: boolean = false;
  public showRsi: boolean = false;
  public showDecision: boolean = false;
  public showRisk: boolean = false;
  public showStatus: boolean = false;
  public showWeathers: boolean = false;
  public showModels: boolean = false;
  public apllypagingfilter: boolean = false;
  buttoncolor = 'black';
  @ViewChildren('contentitem') contentitem: QueryList<ElementRef>;
  @ViewChild('clickonbody', { static: false }) clickonbody: ElementRef;
  destroy$: Subject<any> = new Subject();
  showSearchEvent: boolean = false;
  public unlimited:number = 1;
  constructor(
    private titleService: Title,
    private elem: ElementRef,
    private LS: LogoutService,
    private loader: LoaderService,
    private router: Router,
    private portfolioService: PortfolioService,
    private store: Store,
    private _activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService,
    private newEnquiryService: NewEnquiryService,
    private userSrv: UserService
  ) {
    this.loader.display(true);
    this.titleService.setTitle('My portfolio - MetSwift');
    this.getState = this.store.select(selectAuthState);
    this.getState.subscribe((state) => {
      this.username = state.user.username;
      this.unlimited = state.user.unlimited;
    });
    // this.userSrv.searchText.subscribe(data=>{
    //   this.filterObj.eventnames=data;
    //   if(data){
    //     this.fetchFilters();
    //     if(this.filterObj.eventnames){
    //       this.showSearchEvent=true;
    //     }
    //   }
    //  })

  }
  ngOnInit(): void {
    sessionStorage.clear();
    if (navigator.onLine == true) {
      // this.loader.display(true);
      this.LS.logOut({ title: "My portfolio", link: "#/my-portfolio" })
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            // this.loader.display(false);
            let dd = JSON.parse(JSON.stringify(data));
            let successData = dd;
            this.isSuperAdOrAdmin = successData.isAdmin == 1 || successData.supAdmin == 1 ? true : false;
            if (successData.trial == 1)
              this.isTrialUser = true;
          },
          error => {
            this.router.navigate(["/login"]);
            // this.loader.display(false);
          }
        );
    } else {
      //this.connectionService.display(true);
      this.loader.display(false);
    }
    this.getViewAs();
    this._activatedRoute.queryParams.subscribe(params => {
      console.log(params)
      if (params['searchTxt']) {
        this.filterObj.eventnames = params['searchTxt'];
      }
      if (this.filterObj.eventnames) {
        this.showSearchEvent = true;
      }
      if(this.showGroups|| this.showEvents|| this.showWeathers|| this.showDecision|| this.showStatus|| this.showRsi|| this.showCostitems|| this.showMonths|| this.showStates|| this.showCountries|| this.showPostcodes|| this.showModels|| this.showRisk){
        
      }else{
        // this.fetchFilters();
      } 
      this.buttoncolor = 'black';
      this.apllypagingfilter=false;
      this.currentPage=1;
      this.fetchFilters();
    });


    //  multiselect optons settings
    this.dropdownList = [
      { "id": 1, "itemName": "India" },
      { "id": 2, "itemName": "Singapore" },
      { "id": 3, "itemName": "Australia" },
      { "id": 4, "itemName": "Canada" },
      { "id": 5, "itemName": "South Korea" },
      { "id": 6, "itemName": "Germany" },
      { "id": 7, "itemName": "France" },
      { "id": 8, "itemName": "Russia" },
      { "id": 9, "itemName": "Italy" },
      { "id": 10, "itemName": "Sweden" }
    ];
    this.selectedItems = [
      { "id": 2, "itemName": "Singapore" },
      { "id": 3, "itemName": "Australia" },
      { "id": 4, "itemName": "Canada" },
      { "id": 5, "itemName": "South Korea" }
    ];
    this.countrySettings = {
      singleSelection: false,
      text: "Countries",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 1,
      maxHeight: 200,
      classes: "multiselect"
    };

    this.stateSettings = {
      singleSelection: false,
      text: "States",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 1,
      maxHeight: 200,
      classes: "multiselect"
    };
    this.monthSettings = {
      singleSelection: false,
      text: "Months",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 1,
      maxHeight: 200,
      classes: "multiselect"
    };
    this.statusSettings = {
      singleSelection: false,
      text: "Select Countries",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 1,
      maxHeight: 200,
      classes: "multiselect"
    };
    this.constidSettings = {
      singleSelection: false,
      text: "States",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 1,
      maxHeight: 200,
      classes: "multiselect"
    };
    this.decisionSettings = {
      singleSelection: false,
      text: "Local Risks",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 1,
      maxHeight: 200,
      classes: "multiselect"
    };
    this.weatherSettings= {
      singleSelection: false,
      text: "Weather Types",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      badgeShowLimit: 1,
      maxHeight: 200,
      classes: "multiselect"
    };
  }


  // multiselect
  onItemSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    console.log(item);
    console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onDeSelectAll(items: any) {
    console.log(items);
  }


  removesearch() {
    this.filterObj.eventnames = '';
    this.showSearchEvent = false;
    this.router.navigate(['/my-portfolio']);
    this.selectedStatesFilterItems=[];
    this.selectedWeathersFilterItems =[];
    this.selectedCountriesFilterItems=[];
    this.selectedRiskFilterItems =[];
    this.selectedMonthsFilterItems =[];
    this.showWeathers = false;
    this.showRisk = false;
    this.showMonths = false;
    this.showStates = false;
    this.showCountries = false;
    this.filterObj.weathers=[]
    this.filterObj.countries=[]
    this.filterObj.states=[]
    this.filterObj.months=[]
    this.filterObj.risk=[]
    this.currentPage=1;
    this.fetchFilters();
  }
  getViewAs() {
    //view as
    this._activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params['id'])
        this.viewas = params['id'];
      if (params['id']) this.viewas = params['id'];
      if (params['firstName'] && params['lastName'])
        this.viewasUserName = params['firstName'] + " " + params['lastName'];
    });
  }

  fetchotionfilter(val){
      this.loadData = false;
      if (navigator.onLine == true) {
        this.loader.display(true);
        let request = {
          pageno: this.currentPage,
          username: this.username,
          filters: this.filterObj,
          weathers: this.filterObj.weathers,
          models: this.filterObj.models,
          groups: this.filterObj.groups,
          events: this.filterObj.events,
          postcodes: this.filterObj.postcodes,
          countries: this.filterObj.countries,
          states: this.filterObj.states,
          months: this.filterObj.months,
          costitems: this.filterObj.costitems,
          "Rainfall Sensitivity Index": this.filterObj.rsi,
          status: this.filterObj.status,
          decisions: this.filterObj.decision,
          risks: this.filterObj.risk,
          eventnames: this.filterObj.eventnames
        };
        if (this.viewas.length)
          request["viewas"] = this.viewas;
        this.portfolioService
          .getFilters(request)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            data => {
              let emptyText = [];
              let dd = JSON.parse(JSON.stringify(data));
              let successData = dd;
              console.log(dd);
              if(val != 'w' || this.selectedWeathersFilterItems.length<1){
                this.weatherFilterList = successData.data.recommended.weathers
                ? successData.data.recommended.weathers
                : emptyText;
              }
              
              if(val != 'c' || this.selectedCountriesFilterItems.length<1){
              this.countryFilterList = successData.data.available.countries
                ? successData.data.available.countries
                : emptyText;
              }
              
              if(val != 's' || this.selectedStatesFilterItems.length<1){
              this.stateFilterList = successData.data.available.states
                ? successData.data.available.states
                :emptyText;
              }
              if(val != 'mo' || this.selectedMonthsFilterItems.length<1){
              this.monthFilterList = successData.data.available.months
                ? successData.data.available.months
                : emptyText;
              }
              if(val != 'ri' || this.selectedRiskFilterItems.length<1){
                this.riskFilterList = successData.data.available.risks
                ? successData.data.available.risks
                : emptyText;
              }
              this.loader.display(false);
            },
            error => {
              this.loader.display(false);
            })
        }
  }

  fetchFilters(page=null) {
    this.loadData = false;
    if (navigator.onLine == true) {
      this.loader.display(true);
      if(page){
        this.currentPage=page;
        this.buttoncolor='black';
      }

      let request = {
        pageno: this.currentPage,
        username: this.username,
        filters: this.filterObj,
        weathers: this.filterObj.weathers,
        models: this.filterObj.models,
        groups: this.filterObj.groups,
        events: this.filterObj.events,
        postcodes: this.filterObj.postcodes,
        countries: this.filterObj.countries,
        states: this.filterObj.states,
        months: this.filterObj.months,
        costitems: this.filterObj.costitems,
        "Rainfall Sensitivity Index": this.filterObj.rsi,
        status: this.filterObj.status,
        decisions: this.filterObj.decision,
        risks: this.filterObj.risk,
        eventnames: this.filterObj.eventnames
      };
      let emptyText = [];
      if (this.viewas.length)
        request["viewas"] = this.viewas;
      this.portfolioService
        .getFilters(request)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            let dd = JSON.parse(JSON.stringify(data));
            let successData = dd;
            if (successData.data.results.length > 0) {
              
              this.alertCount = successData.data.alertCount;
              this.severeCount = successData.data.severeCount;
              this.totalCount = successData.data.count;
              this.totalInsightCount = successData.data.insightCount;
              this.availFilterData = successData.data.available;
              this.recommendedFilterData = successData.data.recommended;
              // this.groupFilterList = successData.data.recommended.groups.length
              //   ? successData.data.recommended.groups
              //   : emptyText;
              // this.eventFilterList = successData.data.recommended.events.length
              //   ? successData.data.recommended.events
              //   : emptyText;
              // this.contractFilterList = successData.data.recommended.contracts;
              this.weatherFilterList = successData.data.recommended.weathers
                ? successData.data.recommended.weathers
                : emptyText;
              this.countryFilterList = successData.data.available.countries
                ? successData.data.available.countries
                : emptyText;
              this.stateFilterList = successData.data.available.states
                ? successData.data.available.states
                :emptyText;
              this.monthFilterList = successData.data.available.months
                ? successData.data.available.months
                : emptyText;
              // this.statusFilterList = successData.data.available.status.length
              //   ? successData.data.available.status
              //   : emptyText;
              // this.costItemFilterList = successData.data.available.costitems
              //   .length
              //   ? successData.data.available.costitems
              //   : emptyText;
              // this.decisionFilterList = successData.data.available.decisions
              //   .length
              //   ? successData.data.available.decisions
              //   : emptyText;
              this.riskFilterList = successData.data.available.risks
                ? successData.data.available.risks
                : emptyText;

              if(this.weatherFilterList.length>0){
                let weatherarr = [];
                for(let i=0;i<this.selectedWeathersFilterItems.length;i++){
                  if(this.weatherFilterList.map((a)=>{ return a.id}).includes(this.selectedWeathersFilterItems[i].id)){
                    weatherarr.push(this.selectedWeathersFilterItems[i].id);
                  } else{
                    this.selectedWeathersFilterItems.splice(this.selectedWeathersFilterItems.findIndex(a => a.id === this.selectedWeathersFilterItems[i].id) , 1);
                  }
                }
                this.filterObj.weathers = weatherarr;
              } else{
                this.filterObj.weathers = [];
              }

              if(this.stateFilterList.length>0){
                let statearr = [];
                for(let i=0;i<this.selectedStatesFilterItems.length;i++){
                  if(this.stateFilterList.map((a)=>{ return a.id}).includes(this.selectedStatesFilterItems[i].id)){
                    statearr.push("'" + this.selectedStatesFilterItems[i].id + "'");
                  } else{
                    this.selectedStatesFilterItems.splice(this.selectedStatesFilterItems.findIndex(a => a.id === this.selectedStatesFilterItems[i].id) , 1);
                  }
                }
                this.filterObj.states = statearr;
              } else{
                this.selectedStatesFilterItems=[];
                this.filterObj.states=[];
              }

              if(this.countryFilterList.length>0){
                let contryarr = [];
                for(let i=0;i<this.selectedCountriesFilterItems.length;i++){
                  if(this.countryFilterList.map((a)=>{ return a.id}).includes(this.selectedCountriesFilterItems[i].id)){
                    contryarr.push("'" + this.selectedCountriesFilterItems[i].id + "'");
                  } else{
                    this.selectedCountriesFilterItems.splice(this.selectedCountriesFilterItems.findIndex(a => a.id === this.selectedCountriesFilterItems[i].id) , 1);
                  }
                }
                this.filterObj.countries = contryarr;
              } else{
                this.selectedCountriesFilterItems=[];
                this.filterObj.countries =[];
              }

              if(this.monthFilterList.length>0){
                let montharr = [];
                for(let i=0;i<this.selectedMonthsFilterItems.length;i++){
                  if(this.monthFilterList.map((a)=>{ return a.id}).includes(this.selectedMonthsFilterItems[i].id)){
                    montharr.push(this.selectedMonthsFilterItems[i].id);
                  } else{
                    this.selectedMonthsFilterItems.splice(this.selectedMonthsFilterItems.findIndex(a => a.id === this.selectedMonthsFilterItems[i].id) , 1);
                  }
                }
                this.filterObj.months = montharr;
              } else{
                this.selectedMonthsFilterItems=[];
                this.filterObj.months=[];
              }

              if(this.riskFilterList.length>0){
                let riskarr = [];
                for(let i=0;i<this.selectedRiskFilterItems.length;i++){
                  if(this.riskFilterList.map((a)=>{ return a.id}).includes(this.selectedRiskFilterItems[i].id)){
                    riskarr.push(this.selectedRiskFilterItems[i].id);
                  } else{
                    this.selectedRiskFilterItems.splice(this.selectedRiskFilterItems.findIndex(a => a.id === this.selectedRiskFilterItems[i].id) , 1);
                  }
                }
                this.filterObj.risk = riskarr;
              } else{
                this.selectedRiskFilterItems=[];
                this.filterObj.risk = [];
              }
              this.selectedCountriesFilterItemsPrev = this.filterObj.countries?this.filterObj.countries:[];
              this.selectedStatesFilterItemsPrev = this.filterObj.states?this.filterObj.states:[];
              this.selectedMonthsFilterItemsPrev = this.filterObj.months?this.filterObj.months:[];
              this.selectedWeathersFilterItemsPrev = this.filterObj.weathers?this.filterObj.weathers:[];
              this.selectedRiskFilterItemsPrev = this.filterObj.risk?this.filterObj.risk:[];
              
              this.resultData = successData.data.results;
              this.totalRiskValue = parseFloat(successData.data.totalEventCost);
              this.totalSpent = parseFloat(successData.data.totalSpent);
              if (this.resultData.length > 0) {
                this.showdata = true;
                this.addEndDateObj();
              }
              let contractListt = [];

              for (let i = 0; i < this.resultData.length; i++) {
                let cst = null;
                if (this.resultData[i].costBreakDown != null)
                  cst = this.resultData[i].costBreakDown.split(",");
                if (cst != null) {
                  this.resultData[i]["costBreakdown"] = [];
                  for (let j = 0; j < cst.length; j++) {
                    let costItem = cst[j].split(":");
                    this.resultData[i]["costBreakdown"][j] = [
                      { costTitle: costItem[0], costValue: costItem[1] }
                    ];
                  }
                }
                let models = null;
                if (this.resultData[i].modelsBreakDown != null)
                  models = this.resultData[i].modelsBreakDown.split(",");
                if (models != null) {
                  this.resultData[i]["modelsBreakDown"] = [];
                  for (let j = 0; j < models.length; j++) {
                    let modelItem = models[j].split(":");
                    this.resultData[i]["modelsBreakDown"][j] = [
                      { modelName: modelItem[0], amount: modelItem[1] }
                    ];
                  }
                }
                this.resultData[i].showTr = false;
                this.resultData[i].summaryButton = "More";
                this.resultData[i].activeMore = false;
                let infoColor = "";
                contractListt[i] = { eventID: this.resultData[i].eventId };
                this.getRiskIcon(this.resultData[i]).then(a => {
                  this.resultData[i].infoColor = a;
                  if (new Date(this.resultData[i].endDate) > this.currentDate) {
                    this.resultData[i].showAbandon = true;
                  } else {
                    this.resultData[i].showAbandon = false;
                  }
                  if (this.resultData[i].status == 1) {
                    this.resultData[i].activeEvent = false;
                  } else {
                    this.resultData[i].activeEvent = true;
                  }
                });
              }
              // if ( this.filterObj.contracts.length == 0 && this.filterObj.countries.length == 0 && this.filterObj.events.length == 0  && this.filterObj.groups.length == 0 && this.filterObj.models.length == 0 && this.filterObj.postcodes.length == 0 && this.filterObj.weathers.length == 0 && this.filterObj.eventnames =='' && this.filterObj.report =='' ) {
              //  // this.fetchContracts();
              // } else {
              //   contractListt = [];
              //   for (let p = 0; p < this.resultData.length; p++) {
              //     contractListt[p] = { eventID: this.resultData[p].eventId };
              //   }
              //  // this.fetchRiskIconsData(contractListt);
              // }
              this.totalCount = successData.data.count;
              this.loadData = true;
              this.numOfPages = successData.data.numPages;
              if (this.currentPage < 1) {
                this.currentPage = 1;
              } else if (this.currentPage > this.numOfPages) {
                this.currentPage = this.numOfPages;
              }
              let startPage: number, endPage: number;
              if (screen.width <= 767) {
                if (this.numOfPages <= 5) {
                  // less than 10 total pages so show all
                  startPage = 1;
                  endPage = this.numOfPages;
                } else {
                  if (this.currentPage <= 3) {
                    startPage = 1;
                    endPage = 5;
                  } else if (this.currentPage + 2 >= this.numOfPages) {
                    startPage = this.numOfPages - 4;
                    endPage = this.numOfPages;
                  } else {
                    startPage = this.currentPage - 2;
                    endPage = this.currentPage + 2;
                  }
                }
              } else {
                if (this.numOfPages <= 10) {
                  // less than 10 total pages so show all
                  startPage = 1;
                  endPage = this.numOfPages;
                } else {
                  if (this.currentPage <= 6) {
                    startPage = 1;
                    endPage = 10;
                  } else if (this.currentPage + 4 >= this.numOfPages) {
                    startPage = this.numOfPages - 9;
                    endPage = this.numOfPages;
                  } else {
                    startPage = this.currentPage - 5;
                    endPage = this.currentPage + 4;
                  }
                }
              }
              this.pages = [];
              this.pages = Array.from(
                Array(endPage + 1 - startPage).keys()
              ).map(i => startPage + i);
            } else {
              this.loadData = true;
              this.overlayFlag = true;
              this.resultData = [];
              this.weatherFilterList = emptyText;
              this.countryFilterList = emptyText;
              this.stateFilterList = emptyText;
              this.monthFilterList = emptyText;
              this.riskFilterList = emptyText;
              this.selectedStatesFilterItems=[];
              this.selectedWeathersFilterItems =[];
              this.selectedCountriesFilterItems=[];
              this.selectedRiskFilterItems =[];
              this.selectedMonthsFilterItems =[];
              this.showWeathers = false;
              this.showRisk = false;
              this.showMonths = false;
              this.showStates = false;
              this.showCountries = false;
            }
            this.loader.display(false);
          },
          error => {
            this.weatherFilterList = emptyText;
            this.countryFilterList = emptyText;
            this.stateFilterList = emptyText;
            this.monthFilterList = emptyText;
            this.riskFilterList = emptyText;
            this.selectedStatesFilterItems=[];
            this.selectedWeathersFilterItems =[];
            this.selectedCountriesFilterItems=[];
            this.selectedRiskFilterItems =[];
            this.selectedMonthsFilterItems =[];
            this.showWeathers = false;
            this.showRisk = false;
            this.showMonths = false;
            this.showStates = false;
            this.showCountries = false;
            this.currentPage=1;
            // let errordata = error;
            this.loader.display(false);
            // let errObj: any = {};
            // this.loadData = true;
            this.resultData = [];
            // (errObj.code = error.status),
            //   (errObj.eventId = sessionStorage.getItem("eventId")),
            //   (errObj.url = "api/getrecommfilters"),
            //   (errObj.frontUrl = "/brain"),
            //   (errObj.apiBody = {
            //     username: userName,
            //     filters: this.filterObj,
            //     weathers: this.filterObj.weathers,
            //     models: this.filterObj.models,
            //     groups: this.filterObj.groups,
            //     events: this.filterObj.events,
            //     postcodes: this.filterObj.postcodes
            //   });
            // if (error.status == 404 || error.status == 500) {
            //   this.callErrorMail(errObj);
            // }
            // // this.getPopUpVal(true);
          }
        );
    } else {
      // this.connectionService.display(true);
      this.loader.display(false);
    }
  }

  async getRiskIcon(rowData) {
    this.loader.display(true);
    this.infoText = "";
    let riskType = "";
    this.showInfo = false;
    // let data = await this.insightService.asyncGetRiskIconsDataForEvent({
    //   data: [{ eventID: eventId }]
    // });
    // let adata = JSON.stringify(data);
    // let successData = JSON.parse(adata);
    if (rowData.temperatureSevere && rowData.temperatureSevere > 0) {
      return "severe";
    } else if (rowData.windSevere && rowData.windSevere > 0) {
      return "severe";
    } else if (rowData.rainSevere && rowData.rainSevere > 0) {
      return "severe";
    } else if (rowData.windAlert && rowData.windAlert > 0) {
      return "alert";
    } else if (rowData.temperatureAlert && rowData.temperatureAlert > 0) {
      return "alert";
    } else if (rowData.rainAlert && rowData.rainAlert > 0) {
      return "alert";
    }
    return "safe";
  }

  addEndDateObj() {
    for (let i = 0; i < this.resultData.length; i++) {
      this.resultData[i].endDateObj = new Date(this.resultData[i].endDate);
    }
  }

  onPage(val) {
    if (navigator.onLine == true) {
      this.currentPage = val;
      this.fetchFilters();
    } else {
      // this.connectionService.display(true);
      this.loader.display(false);
    }
  }

  previous() {
    if (navigator.onLine == true) {
      if (this.currentPage <= 1) {
        this.currentPage = 1;
      } else {
        this.currentPage = this.currentPage - 1;
      }
      this.fetchFilters();
    } else {
      // this.connectionService.display(true);
      this.loader.display(false);
    }
  }

  next() {
    if (navigator.onLine == true) {
      if (this.currentPage < this.numOfPages) {
        this.currentPage = this.currentPage + 1;
      }
      this.fetchFilters();
    } else {
      // this.connectionService.display(true);
      this.loader.display(false);
    }
  }

  // first() {
  //   if (navigator.onLine == true) {
  //     this.currentPage = 1;
  //     this.fetchFilters();
  //   } else {
  //     // this.connectionService.display(true);
  //     this.loader.display(false);
  //   }
  // }

  // last() {
  //   if (navigator.onLine == true) {
  //     this.currentPage = this.numOfPages;
  //     this.fetchFilters();
  //   } else {
  //     // this.connectionService.display(true);
  //     this.loader.display(false);
  //   }
  // }

  onBid(bid, data) {
    if (navigator.onLine == true) {
      let obj = { eventId: data.eventId, bid: bid };
      if (this.viewas.length) obj["viewas"] = this.viewas;
      this.portfolioService
        .brainBid(obj)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            this.LS.logOut({
              title: "Brain",
              link: "#/brain",
              description: "Bid"
            })
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                data => { },
                error => {
                  this.router.navigate(["/login"]);
                }
              );
            let dd = JSON.parse(JSON.stringify(data));
            let successData = dd;
            this.fetchFilters();
          },
          error => {
            // let errordata = error;
            this.loader.display(false);
            // let errObj: any = {};
            // (errObj.code = error.status),
            //   (errObj.eventId = sessionStorage.getItem("eventId")),
            //   (errObj.url = "insight/bid"),
            //   (errObj.frontUrl = "/brain"),
            //   (errObj.apiBody = { eventId: data.eventId, bid: bid });
            // if (error.status == 404 || error.status == 500) {
            //   this.callErrorMail(errObj);
            // }
            // this.getPopUpVal(true);
          }
        );
    } else {
      // this.connectionService.display(true);
      this.loader.display(false);
    }
  }

  onStatus(status, data) {
    if (navigator.onLine == true) {
      let obj = { eventId: data.eventId, status: status };
      if (this.viewas.length) obj["viewas"] = this.viewas;
      this.portfolioService
        .statusBid(obj)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            this.LS.logOut({
              title: "Brain",
              link: "#/brain",
              description: "Change status"
            })
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                data => { },
                error => {
                  this.router.navigate(["/login"]);
                }
              );
            let dd = JSON.parse(JSON.stringify(data));
            let successData = dd;
            this.fetchFilters();
          },
          error => {
            // let errordata = error;
            this.loader.display(false);
            // let errObj: any = {};
            // (errObj.code = error.status),
            //   (errObj.eventId = sessionStorage.getItem("eventId")),
            //   (errObj.url = "insight/eventstatus"),
            //   (errObj.frontUrl = "/brain"),
            //   (errObj.apiBody = { eventId: data.eventId, status: status });
            // if (error.status == 404 || error.status == 500) {
            //   this.callErrorMail(errObj);
            // }
            // this.getPopUpVal(true);
          }
        );
    } else {
      //this.connectionService.display(true);
      this.loader.display(false);
    }
  }

  showThisItem(event: any, i) {
    if (this.currentIndex == i && event.show == true) {
      event.show = false;
    } else {
      this.currentIndex = i;
      event.show = true;
      this.getRiskAlert(event);
    }
  }

  showThisItemMobile(event: any, i) {
    if (this.currentIndexMobile == i && event.showMobilePopup == true) {
      event.showMobilePopup = false;
    } else {
      this.currentIndexMobile = i;
      event.showMobilePopup = true;
      this.getRiskAlert(event);
    }
  }

  getRiskAlert = function (result) {
    if (navigator.onLine == true) {
      this.getRiskJSON(result);
      // this.getPopUpVal(true);
      this.loader.display(true);
      let eventId = result.eventId;
      this.infoText = "";
      let riskType = "";
      let alert = 0;
      let severe = 0;
      this.showInfo = false;
      let obs = this.dashboardService
        .getRiskIconsData({ data: [{ eventID: parseInt(eventId) }] })
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            this.LS.logOut({ title: "Get risk data for event id " + parseInt(eventId), link: "#/my-portfolio" })
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                data => {

                },
                error => {
                  this.router.navigate(["/login"]);
                  // this.loader.display(false);
                }
              );
            let dd = JSON.parse(JSON.stringify(data));
            let successData = dd;
            let msg = "";
            if (
              successData.temperature &&
              successData.temperature.severe &&
              successData.temperature.severe.length > 0
            ) {
              msg = "Temp has crossed Severe. ";
              severe++;
            } else if (
              successData.temperature &&
              successData.temperature.alert &&
              successData.temperature.alert.length > 0
            ) {
              msg = "Temp has crossed Moderate. ";
              alert++;
            }
            if (
              successData.wind &&
              successData.wind.severe &&
              successData.wind.severe.length > 0
            ) {
              msg += "Wind has crossed Severe. ";
              severe++;
            } else if (
              successData.wind &&
              successData.wind.alert &&
              successData.wind.alert.length > 0
            ) {
              msg += "Wind has crossed Moderate. ";
              alert++;
            }
            if (
              successData.rain &&
              successData.rain.severe &&
              successData.rain.severe.length > 0
            ) {
              msg += "Rain has crossed Severe. ";
              severe++;
            } else if (
              successData.rain &&
              successData.rain.alert &&
              successData.rain.alert.length > 0
            ) {
              msg += "Rain has crossed Moderate. ";
              alert++;
            }
            if (msg == "") msg = "All values are within the safe thresholds. ";
            this.infoText = msg;
          },
          error => {
            let errordata = error;
            this.loader.display(false);
          },
          () => {
            this.getriksiconsImage(result);
          }
        );
    } else {
      // this.connectionService.display(true);
      this.loader.display(false);
      this.getPopUpVal(false);
    }
  };

  getriksiconsImage = function (result) {
    if (navigator.onLine == true) {
      this.portfolioService
        .mapRisk({
          End_Date: result.End_Date,
          Start_Date: result.Start_Date,
          country: result.country,
          delphiRec: result.delphiRec,
          lat: result.lat,
          lon: result.lon,
          postCode: result.postCode,
          weathers: "",
          categories: this.hurricaneCategories
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            let dd = JSON.parse(JSON.stringify(data));
            let successData = dd;
            let riskData = successData.Risk;
            let riskValues = successData.riskValue;
            let msgRisk = "";
            if (riskData.earthquake > 0) {
              if (riskValues.earthquake) {
                msgRisk += '<li><img src="../../../assets/images/earthquake-red.png" alt = "earthquake"><span class="risktooltip">Earthquakes</span></li>';
              } else {
                msgRisk += '<li><img src="../assets/images/earthquake-yellow.png" alt = "earthquake"><span class="risktooltip">Earthquakes</span></li>';
              }
            }
            if (riskData.hurricanes > 0) {
              if (riskValues.hurricanes) {
                msgRisk += '<li><img src="../../../assets/images/hurricane-red.png" alt = "hurricane"><span class="risktooltip">Hurricanes</span></li>';
              } else {
                msgRisk += '<li><img src="../../../assets/images/hurricane-yellow.png" alt = "hurricane"><span class="risktooltip">Hurricanes</span></li>';
              }
            }
            if (riskData.tornadoes > 0) {
              if (riskValues.tornadoes) {
                msgRisk += '<li><img src="../../../assets/images/tornado-red.png" alt = "tornado"><span class="risktooltip">Tornadoes</span></li>';
              } else {
                msgRisk += '<li><img src="../../../assets/images/tornado-yellow.png" alt = "tornado"><span class="risktooltip">Tornadoes</span></li>';
              }
            }
            if (riskData.volcano > 0) {
              if (riskValues.volcano) {
                msgRisk += '<li><img src="../../../assets/images/volcano-red.png" alt = "volcano"><span class="risktooltip">Volcanoes</span></li>';
              } else {
                msgRisk += '<li><img src="../assets/images/volcano-yellow.png" alt = "volcano"><span class="risktooltip">Volcanoes</span></li>';
              }
            }
            if (riskData.wildfire > 0) {
              if (riskValues.wildfire) {
                msgRisk += '<li><img src="../../../assets/images/wildfire-red.png" alt = "wildfire"><span class="risktooltip">Wildfires</span></li>';
              } else {
                msgRisk += '<li><img src="../../../assets/images/wildfire-yellow.png" alt = "wildfire"><span class="risktooltip">Wildfires</span></li>';
              }
            }

            if (msgRisk == "") {
              this.infoText += "No local risks.";
            }
            else {
              this.infoText += "<br/> Local risks for your location are <ul>" + msgRisk + "</ul>";
            }
            this.loader.display(false);
          },
          error => {
            this.loader.display(false);
            // let errordata = error;
            // this.loaderService.display(false);
            // let errObj: any = {};
            // (errObj.code = error.status),
            //   (errObj.eventId = sessionStorage.getItem("eventId")),
            //   (errObj.url = "stats_data/get_map_risk_data"),
            //   (errObj.frontUrl = "/brain"),
            //   (errObj.apiBody = {
            //     End_Date: result.End_Date,
            //     Start_Date: result.Start_Date,
            //     country: result.country,
            //     delphiRec: result.delphiRec,
            //     lat: result.lat,
            //     lon: result.lon,
            //     postCode: result.postCode,
            //     weathers: ""
            //   });
            // if (error.status == 404 || error.status == 500) {
            //   this.callErrorMail(errObj);
            // }
            // this.getPopUpVal(true);
          }
        );
    } else {
      // this.connectionService.display(true);
      this.loader.display(false);
    }
  };

  async getRiskJSON(result) {
    let res = await this.newEnquiryService.getJSON();
    let strData = JSON.stringify(res);
    this.countryWiseHurricaneName = JSON.parse(strData);
    this.getHurricaneName(result);
  }

  getHurricaneName(result) {
    let countryName = result.country;
    let len = this.countryWiseHurricaneName.length;
    this.hurricaneName = "Hurricanes";
    for (let i = 0; i < len; i++) {
      if (this.countryWiseHurricaneName[i].code == countryName) {
        this.hurricaneName = this.countryWiseHurricaneName[i].title;
        this.hurricaneCategories = this.countryWiseHurricaneName[
          i
        ].category.split(",");
      }
    }
  }

  onClickReportGo(eventId) {
    this.router.navigate(['/report/' + eventId]);
  }

  onClickedOutside(event, i) {
    if (this.currentIndex == i) {
      this.infoText = '';
      event.show = false;
    }
  }

  onClickedOutsideMobile(event, i) {
    if (this.currentIndexMobile == i) {
      this.infoText = '';
      event.showMobilePopup = false;
    }
  }
  clickedShowFilter() {
    this.showFilter = !this.showFilter
  }

  feedData(clickedFilter){ 
    
    let emptyText = [{ id: 0, itemName: "NA" }];
    
    //   if( clickedFilter=='g')
    //     this.groupFilterList = this.recommendedFilterData.groups.length ? this.recommendedFilterData.groups: emptyText;
        
    //   else if( clickedFilter == 'e')
    //     this.eventFilterList = this.recommendedFilterData.events.length
    //       ? this.recommendedFilterData.events
    //       : emptyText;  
          
    //   else if( clickedFilter == 'w')
    //     this.weatherFilterList = this.recommendedFilterData.weathers.length
    //       ? this.recommendedFilterData.weathers
    //       : emptyText;
    //   else if (clickedFilter == 'c')
    //     this.countryFilterList = this.availFilterData.countries.length
    //       ? this.availFilterData.countries
    //       : emptyText;    
    // else if (clickedFilter == 's')
    //     this.stateFilterList = this.stateFilterList; 
    // else if (clickedFilter == 'mo')
    //     this.monthFilterList = this.availFilterData.months.length
    //     ? this.availFilterData.months
    //     : emptyText;  
    // else if (clickedFilter == 'ci')
    //     this.costItemFilterList = this.availFilterData.costitems.length
    //       ? this.availFilterData.costitems
    //       : emptyText;
    //   else if (clickedFilter == 'st')
    //     this.statusFilterList = this.availFilterData.status.length
    //       ? this.availFilterData.status
    //       : emptyText;    
    // else if (clickedFilter == 'd')
    //     this.decisionFilterList = this.availFilterData.decisions.length
    //       ? this.availFilterData.decisions
    //     : emptyText; 
    // else if (clickedFilter == 'ri')
    //   this.riskFilterList = this.availFilterData.risks.length
    //     ? this.availFilterData.risks
    //     : emptyText;              
            
                          
        
      
              // this.eventFilterList = successData.data.recommended.events.length
              //   ? successData.data.recommended.events
              //   : emptyText;
              // this.contractFilterList = successData.data.recommended.contracts;
              // this.weatherFilterList = successData.data.recommended.weathers
              //   .length
              //   ? successData.data.recommended.weathers
              //   : emptyText;
              // this.countryFilterList = successData.data.available.countries
              //   .length
              //   ? successData.data.available.countries
              //   : emptyText;
              // this.stateFilterList = successData.data.available.states.length
              //   ? successData.data.available.states
              //   : emptyText;
              // this.monthFilterList = successData.data.available.months.length
              //   ? successData.data.available.months
              //   : emptyText;
              // this.statusFilterList = successData.data.available.status.length
              //   ? successData.data.available.status
              //   : emptyText;
              // this.costItemFilterList = successData.data.available.costitems
              //   .length
              //   ? successData.data.available.costitems
              //   : emptyText;
              // this.decisionFilterList = successData.data.available.decisions
              //   .length
              //   ? successData.data.available.decisions
              //   : emptyText;
              // this.riskFilterList = successData.data.available.risks.length
              //   ? successData.data.available.risks
              //   : emptyText;
  }


  onItemSelectDeselect(item: any, val, filterClickedFromOut) {
    this.loader.display(true);
    this.currentPage = 1;
    if (typeof filterClickedFromOut == "undefined") filterClickedFromOut = 0;
    let arr = new Array();
    let fiterchange1 = true;
    let fiterchange2 = true;
    let fiterchange3 = true;
    let fiterchange4 = true;
    let fiterchange5 = true;
    let arr1=[]
    for (let i = 0; i < this.selectedCountriesFilterItems.length; i++)
    arr1.push("'" +this.selectedCountriesFilterItems[i].id+"'");
    fiterchange1 = this.checkdiffobj(this.selectedCountriesFilterItemsPrev,arr1);

    let arr2=[]
    for (let i = 0; i < this.selectedStatesFilterItems.length; i++)
    arr2.push("'" +this.selectedStatesFilterItems[i].id+"'");
    fiterchange2 = this.checkdiffobj(this.selectedStatesFilterItemsPrev,arr2);

    let arr3=[]
    for (let i = 0; i < this.selectedWeathersFilterItems.length; i++)
    arr3.push(this.selectedWeathersFilterItems[i].id);
    fiterchange3 = this.checkdiffobj(this.selectedWeathersFilterItemsPrev,arr3);

    let arr4=[]
    for (let i = 0; i < this.selectedMonthsFilterItems.length; i++)
    arr4.push(this.selectedMonthsFilterItems[i].id);
    fiterchange4 = this.checkdiffobj(this.selectedMonthsFilterItemsPrev,arr4);

    let arr5=[]
    for (let i = 0; i < this.selectedRiskFilterItems.length; i++)
    arr5.push(this.selectedRiskFilterItems[i].id);
    fiterchange5 = this.checkdiffobj(this.selectedRiskFilterItemsPrev,arr5);


    if (val == "g") {
      for (let i = 0; i < this.selectedGroupFilterItems.length; i++)
        arr.push(this.selectedGroupFilterItems[i].id);
      this.filterObj.groups = arr;
      this.showGroups =
        arr.length > 0 || filterClickedFromOut == 1 ? true : false;
      this.selectedPostcodesFilterItems = [];
      this.filterObj.postcodes = [];
      this.selectedEventsFilterItems = [];
      this.filterObj.events = [];
    } else if (val == "e") {
      for (let i = 0; i < this.selectedEventsFilterItems.length; i++)
        arr.push(this.selectedEventsFilterItems[i].id);
      this.filterObj.events = arr;
      this.showEvents =
        arr.length > 0 || filterClickedFromOut == 1 ? true : false;
      this.selectedPostcodesFilterItems = [];
      this.filterObj.postcodes = [];
    } else if (val == "w") {
      for (let i = 0; i < this.selectedWeathersFilterItems.length; i++)
        arr.push(this.selectedWeathersFilterItems[i].id);
      this.filterObj.weathers = arr;
      this.showWeathers =
        arr.length > 0 || filterClickedFromOut == 1 ? true : false;
    } else if (val == "p") {
      for (let i = 0; i < this.selectedPostcodesFilterItems.length; i++)
        arr.push("'" + this.selectedPostcodesFilterItems[i].id + "'");
      this.filterObj.postcodes = arr;
      this.showPostcodes =
        arr.length > 0 || filterClickedFromOut == 1 ? true : false;
    } else if (val == "c") {
      for (let i = 0; i < this.selectedCountriesFilterItems.length; i++)
        arr.push("'" + this.selectedCountriesFilterItems[i].id + "'");
      this.filterObj.countries = arr;
      this.showCountries =
        arr.length > 0 || filterClickedFromOut == 1 ? true : false;
    } else if (val == "s") {
      for (let i = 0; i < this.selectedStatesFilterItems.length; i++)
        arr.push("'" + this.selectedStatesFilterItems[i].id + "'");
      this.filterObj.states = arr;
      this.showStates =
        arr.length > 0 || filterClickedFromOut == 1 ? true : false;
    } else if (val == "mo") {
      for (let i = 0; i < this.selectedMonthsFilterItems.length; i++)
        arr.push("'" + this.selectedMonthsFilterItems[i].id + "'");
      this.filterObj.months = arr;
      this.showMonths =
        arr.length > 0 || filterClickedFromOut == 1 ? true : false;
    } else if (val == "ci") {
      for (let i = 0; i < this.selectedCostItemsFilterItems.length; i++)
        arr.push("'" + this.selectedCostItemsFilterItems[i].id + "'");
      this.filterObj.costitems = arr;
      this.showCostitems =
        arr.length > 0 || filterClickedFromOut == 1 ? true : false;
    } else if (val == "r") {
      for (let i = 0; i < this.selectedRsiFilterItems.length; i++)
        arr.push("'" + this.selectedRsiFilterItems[i].id + "'");
      this.filterObj.rsi = arr;
      this.showRsi = arr.length > 0 || filterClickedFromOut == 1 ? true : false;
    } else if (val == "st") {
      for (let i = 0; i < this.selectedStatusFilterItems.length; i++)
        arr.push("'" + this.selectedStatusFilterItems[i].id + "'");
      this.filterObj.status = arr;
      this.showStatus =
        arr.length > 0 || filterClickedFromOut == 1 ? true : false;
    } else if (val == "d") {
      for (let i = 0; i < this.selectedDecisionFilterItems.length; i++)
        arr.push("'" + this.selectedDecisionFilterItems[i].id + "'");
      this.filterObj.decision = arr;
      this.showDecision =
        arr.length > 0 || filterClickedFromOut == 1 ? true : false;
    } else if (val == "ri") {
      for (let i = 0; i < this.selectedRiskFilterItems.length; i++)
        arr.push("'" + this.selectedRiskFilterItems[i].id + "'");
      this.filterObj.risk = arr;
      this.showRisk =
        arr.length > 0 || filterClickedFromOut == 1 ? true : false;
    } else if (val == "et") {
      for (let i = 0; i < this.selectedEventTypes.length; i++)
        arr.push("'" + this.selectedEventTypes[i].id + "'");
      this.eventTypes = arr;
    } else if (val == "rep") {
      this.selectedReportYesNo = this.filterObj.report =
        this.selectedReport.length !== 0 ? this.selectedReport[0].id : "";
      this.selectedReport =
        this.selectedReport.length !== 0 ? this.selectedReport : [];
    } else {
      for (let i = 0; i < this.selectedModelsFilterItems.length; i++)
        arr.push(this.selectedModelsFilterItems[i].id);
      this.filterObj.models = arr;
      this.showModels =
        arr.length > 0 || filterClickedFromOut == 1 ? true : false;
    }
    if(this.showGroups|| this.showEvents|| this.showWeathers|| this.showDecision|| this.showStatus|| this.showRsi|| this.showCostitems|| this.showMonths|| this.showStates|| this.showCountries|| this.showPostcodes|| this.showModels|| this.showRisk){
      this.fetchotionfilter(val)
      // console.log(this.selectedCountriesFilterItemsPrev,this.selectedStatesFilterItemsPrev,this.selectedMonthsFilterItemsPrev,this.selectedWeathersFilterItemsPrev,this.selectedRiskFilterItemsPrev)
      // console.log(this.selectedCountriesFilterItems,this.selectedStatesFilterItems,this.selectedMonthsFilterItems,this.selectedWeathersFilterItems,this.selectedRiskFilterItems)
      console.log(fiterchange1,fiterchange2,fiterchange3,fiterchange4,fiterchange5);
      this.buttoncolor = fiterchange1&&fiterchange2&&fiterchange3&&fiterchange4&&fiterchange5?'black':'orange';
    } else{
      this.fetchFilters(1)
      this.buttoncolor = 'black';
    }
    
  }

  resetRemoveFilter = function(type) {
    switch (type) {
      case "g":
        (this.selectedGroupFilterItems = []) &&
          (this.filterObj.groups = []) &&
          (this.showGroups = false);
        break;
      case "e":
        (this.selectedEventsFilterItems = []) &&
          (this.filterObj.events = []) &&
          (this.showEvents = false);
        break;
      case "w":
        (this.selectedWeathersFilterItems = []) &&
          (this.filterObj.weathers = []) &&
          (this.showWeathers = false);
        break;
      case "m":
        (this.selectedModelsFilterItems = []) &&
          (this.filterObj.models = []) &&
          (this.showModels = false);
        break;
      case "p":
        (this.selectedPostcodesFilterItems = []) &&
          (this.filterObj.postcodes = []) &&
          (this.showPostcodes = false);
        break;
      case "c":
        (this.selectedCountriesFilterItems = []) &&
          (this.filterObj.countries = []) &&
          (this.showCountries = false);
        break;
      case "s":
        (this.selectedStatesFilterItems = []) &&
          (this.filterObj.states = []) &&
          (this.showStates = false);
        break;
      case "mo":
        (this.selectedMonthsFilterItems = []) &&
          (this.filterObj.months = []) &&
          (this.showMonths = false);
        break;
      case "ci":
        (this.selectedCostItemsFilterItems = []) &&
          (this.filterObj.costitems = []) &&
          (this.showCostitems = false);
        break;
      case "r":
        (this.selectedRsiFilterItems = []) &&
          (this.filterObj.rsi = []) &&
          (this.showRsi = false);
        break;
      case "d":
        (this.selectedDecisionFilterItems = []) &&
          (this.filterObj.decision = []) &&
          (this.showDecision = false);
        break;
      case "ri":
        (this.selectedRiskFilterItems = []) &&
          (this.filterObj.risk = []) &&
          (this.showRisk = false);
        break;
      case "st":
        (this.selectedStatusFilterItems = []) &&
          (this.filterObj.status = []) &&
          (this.showStatus = false);
        break;
      case "rep":
        this.selectedReportYesNo = this.filterObj.report = "";
        this.selectedReport = [];
        break;
      case "all":
        (this.selectedGroupFilterItems = []) &&
          (this.filterObj.groups = []) &&
          (this.showGroups = false);
        (this.selectedEventsFilterItems = []) &&
          (this.filterObj.events = []) &&
          (this.showEvents = false);
        (this.selectedWeathersFilterItems = []) &&
          (this.filterObj.weathers = []) &&
          (this.showWeathers = false);
        (this.selectedWeathersFilterItems = []) &&
          (this.filterObj.weathers = []) &&
          (this.showWeathers = false);
        (this.selectedModelsFilterItems = []) &&
          (this.filterObj.models = []) &&
          (this.showModels = false);
        (this.selectedPostcodesFilterItems = []) &&
          (this.filterObj.postcodes = []) &&
          (this.showPostcodes = false);
        (this.selectedCountriesFilterItems = []) &&
          (this.filterObj.countries = []) &&
          (this.showCountries = false);
        (this.selectedStatesFilterItems = []) &&
          (this.filterObj.states = []) &&
          (this.showStates = false);
        (this.selectedMonthsFilterItems = []) &&
          (this.filterObj.months = []) &&
          (this.showMonths = false);
        (this.selectedCostItemsFilterItems = []) &&
          (this.filterObj.costitems = []) &&
          (this.showCostitems = false);
        (this.selectedRsiFilterItems = []) &&
          (this.filterObj.rsi = []) &&
          (this.showRsi = false);
        (this.selectedStatusFilterItems = []) &&
          (this.filterObj.status = []) &&
          (this.showStatus = false);
        (this.selectedDecisionFilterItems = []) &&
          (this.filterObj.decision = []) &&
          (this.showDecision = false);
        (this.selectedRiskFilterItems = []) &&
          (this.filterObj.risk = []) &&
          (this.showRisk = false);
        // this.searchevent = "";
        // this.showSearchEvent = false;
        // this.filterObj.eventnames = "";
        this.selectedReportYesNo = this.filterObj.report = "";
        this.selectedReport = [];
        break;
    }
    let fiterchange1 = true;
    let fiterchange2 = true;
    let fiterchange3 = true;
    let fiterchange4 = true;
    let fiterchange5 = true;
    let arr1=[]
    for (let i = 0; i < this.selectedCountriesFilterItems.length; i++)
    arr1.push("'" +this.selectedCountriesFilterItems[i].id+"'");
    fiterchange1 = this.checkdiffobj(this.selectedCountriesFilterItemsPrev,arr1);

    let arr2=[]
    for (let i = 0; i < this.selectedStatesFilterItems.length; i++)
    arr2.push("'" +this.selectedStatesFilterItems[i].id+"'");
    fiterchange2 = this.checkdiffobj(this.selectedStatesFilterItemsPrev,arr2);

    let arr3=[]
    for (let i = 0; i < this.selectedWeathersFilterItems.length; i++)
    arr3.push(this.selectedWeathersFilterItems[i].id);
    fiterchange3 = this.checkdiffobj(this.selectedWeathersFilterItemsPrev,arr3);

    let arr4=[]
    for (let i = 0; i < this.selectedMonthsFilterItems.length; i++)
    arr4.push(this.selectedMonthsFilterItems[i].id);
    fiterchange4 = this.checkdiffobj(this.selectedMonthsFilterItemsPrev,arr4);

    let arr5=[]
    for (let i = 0; i < this.selectedRiskFilterItems.length; i++)
    arr5.push(this.selectedRiskFilterItems[i].id);
    fiterchange5 = this.checkdiffobj(this.selectedRiskFilterItemsPrev,arr5);
    // this.loader.display(true);
    if(type =="all"){
      this.buttoncolor = 'black';
      this.removesearch()
      this.currentPage=1;
      this.fetchFilters();
    }else{
      if(this.showGroups|| this.showEvents|| this.showWeathers|| this.showDecision|| this.showStatus|| this.showRsi|| this.showCostitems|| this.showMonths|| this.showStates|| this.showCountries|| this.showPostcodes|| this.showModels|| this.showRisk){
        this.buttoncolor=fiterchange1&&fiterchange2&&fiterchange3&&fiterchange4&&fiterchange5?'black':'orange';
        this.fetchotionfilter(null);
      } else{
        this.buttoncolor = 'black';
        this.currentPage=1;
        this.fetchFilters();
      }
      
    } 
    return true;
  };

  checkdiffobj=function(obj1,obj2){
    console.log(obj1,obj2);
    let check = obj1.length == obj2.length && obj1.every(function(element, index) {
      return obj1.includes(obj2[index]); 
    });
    return check;
  }
}