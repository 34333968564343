<div class="lg:flex-grow p-4 lg:p-6 xl:p-12 relative">
    <app-backgrounds></app-backgrounds>
    <div class="w-full relative my-portfolio z-10">
        <div class="flex flex-wrap -mx-4 lg:-mx-6 justify-between mb-3 md:mb-4 lg:mb-6">
            <div class="px-4 lg:px-6 w-auto lg:w-1/2">
                <h1 class="font-semibold text-xl lg:text-2xl" #clickonbody>{{ titleField }}</h1>
            </div>
            <div class="px-4 lg:px-6 w-auto flex flex-wrap items-center">
                <app-search-reports-button></app-search-reports-button>
            </div>
        </div>
        <div class="flex flex-wrap -mx-4 lg:-mx-6">
            <div *ngIf="showSearchEvent" class="w-full mb-4 px-4 lg:px-6">
                <p class="inline-block bg-gray-300 font-bold text-black rounded-full px-5 py-1">{{filterObj.eventnames}}</p>
                <button type="button" class="ml-2" (click)="removesearch()">
                    <svg xmlns="http://www.w3.org/2000/svg" class="fill-current stroke-current" width="12" height="12" viewBox="0 0 16 16"><g><path d="M14.7,1.3c-0.4-0.4-1-0.4-1.4,0L8,6.6L2.7,1.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L6.6,8l-5.3,5.3 c-0.4,0.4-0.4,1,0,1.4C1.5,14.9,1.7,15,2,15s0.5-0.1,0.7-0.3L8,9.4l5.3,5.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L9.4,8l5.3-5.3C15.1,2.3,15.1,1.7,14.7,1.3z"></path></g></svg>
                </button>
            </div>
        </div>

        <div class="flex flex-wrap -mx-4 lg:-mx-6 justify-between mb-3">
            <!-- <div class="lg:ml-auto px-4 lg:px-6 w-auto">
                <button (click)="clickedShowFilter()" class="button-filter">
                    <span *ngIf="!showFilter">Filter by</span>
                    <span *ngIf="showFilter">Hide filter by</span>
                </button>
            </div> -->
            <div *ngIf="showFilter" class="w-full mb-3 px-4 lg:px-6 mt-4">
                <div class="w-full block p-4 lg:rounded-lg bg-gray-200">
                    <h3 class="text-primary text-sm font-semibold mb-2">Available filters</h3>
                    <div class="flex flex-wrap -mx-3">
                        <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/5 flex items-center px-3 my-2">
                            <div class="flex-1 block relative">
                                <angular2-multiselect (click)="feedData('c')" [data]="countryFilterList" [(ngModel)]="selectedCountriesFilterItems"
                                    [settings]="countrySettings"
                                    (onSelect)="onItemSelectDeselect($event,'c',0)"
                                    (onDeSelect)="onItemSelectDeselect($event,'c',0)"
                                    (onSelectAll)="onItemSelectDeselect($event,'c',0)"
                                    (onDeSelectAll)="onItemSelectDeselect($event,'c',0)"></angular2-multiselect>
                            </div>
                            <button (click)="resetRemoveFilter('c')" class="w-8 h-8 rounded-full bg-refresh text-white ml-3 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 stroke-current fill-ccurrent" viewBox="0 0 12 12"><g stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M10.606,9.008a5.5,5.5,0,1,1,.679-4.535"></path><polyline points="11.5 0.5 11.5 4.5 7.531 4.007"></polyline></g></svg>
                            </button>
                        </div>
                        <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/5 flex items-center px-3 my-2">
                            <div class="flex-1 block relative">
                                <angular2-multiselect (click)="feedData('s')" [data]="stateFilterList" [(ngModel)]="selectedStatesFilterItems"
                                    [settings]="stateSettings"
                                    (onSelect)="onItemSelectDeselect($event,'s',0)"
                                    (onDeSelect)="onItemSelectDeselect($event,'s',0)"
                                    (onSelectAll)="onItemSelectDeselect($event,'s',0)"
                                    (onDeSelectAll)="onItemSelectDeselect($event,'s',0)"></angular2-multiselect>
                            </div>
                            <button (click)="resetRemoveFilter('s')" class="w-8 h-8 rounded-full bg-refresh text-white ml-3 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 stroke-current fill-ccurrent" viewBox="0 0 12 12"><g stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M10.606,9.008a5.5,5.5,0,1,1,.679-4.535"></path><polyline points="11.5 0.5 11.5 4.5 7.531 4.007"></polyline></g></svg>
                            </button>
                        </div>
                        <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/5 flex items-center px-3 my-2">
                            <div class="flex-1 block relative">
                                <angular2-multiselect (click)="feedData('w')" [data]="weatherFilterList" [(ngModel)]="selectedWeathersFilterItems"
                                    [settings]="weatherSettings"
                                    (onSelect)="onItemSelectDeselect($event,'w',0)"
                                    (onDeSelect)="onItemSelectDeselect($event,'w',0)"
                                    (onSelectAll)="onItemSelectDeselect($event,'w',0)"
                                    (onDeSelectAll)="onItemSelectDeselect($event,'w',0)"></angular2-multiselect>
                            </div>
                            <button (click)="resetRemoveFilter('w')" class="w-8 h-8 rounded-full bg-refresh text-white ml-3 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 stroke-current fill-ccurrent" viewBox="0 0 12 12"><g stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M10.606,9.008a5.5,5.5,0,1,1,.679-4.535"></path><polyline points="11.5 0.5 11.5 4.5 7.531 4.007"></polyline></g></svg>
                            </button>
                        </div>

                        <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/5 flex items-center px-3 my-2">
                            <div class="flex-1 block relative">
                                <angular2-multiselect (click)="feedData('mo')" [data]="monthFilterList" [(ngModel)]="selectedMonthsFilterItems"
                                    [settings]="monthSettings"
                                    (onSelect)="onItemSelectDeselect($event,'mo',0)"
                                    (onDeSelect)="onItemSelectDeselect($event,'mo',0)"
                                    (onSelectAll)="onItemSelectDeselect($event,'mo',0)"
                                    (onDeSelectAll)="onItemSelectDeselect($event,'mo',0)"></angular2-multiselect>
                            </div>
                            <button (click)="resetRemoveFilter('mo')" class="w-8 h-8 rounded-full bg-refresh text-white ml-3 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 stroke-current fill-ccurrent" viewBox="0 0 12 12"><g stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M10.606,9.008a5.5,5.5,0,1,1,.679-4.535"></path><polyline points="11.5 0.5 11.5 4.5 7.531 4.007"></polyline></g></svg>
                            </button>
                        </div>
                        <!-- <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 flex items-center px-3 my-2">
                            <div class="flex-1 block relative">
                                <angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems"
                                    [settings]="statusSettings"
                                    (onSelect)="onItemSelect($event)"
                                    (onDeSelect)="OnItemDeSelect($event)"
                                    (onSelectAll)="onSelectAll($event)"
                                    (onDeSelectAll)="onDeSelectAll($event)"></angular2-multiselect>
                            </div>
                            <button class="w-8 h-8 rounded-full bg-refresh text-white ml-3 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 stroke-current fill-ccurrent" viewBox="0 0 12 12"><g stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M10.606,9.008a5.5,5.5,0,1,1,.679-4.535"></path><polyline points="11.5 0.5 11.5 4.5 7.531 4.007"></polyline></g></svg>
                            </button>
                        </div> -->
                        <!-- <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 flex items-center px-3 my-2">
                            <div class="flex-1 block relative">
                                <angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems"
                                    [settings]="constidSettings"
                                    (onSelect)="onItemSelect($event)"
                                    (onDeSelect)="OnItemDeSelect($event)"
                                    (onSelectAll)="onSelectAll($event)"
                                    (onDeSelectAll)="onDeSelectAll($event)"></angular2-multiselect>
                            </div>
                            <button class="w-8 h-8 rounded-full bg-refresh text-white ml-3 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 stroke-current fill-ccurrent" viewBox="0 0 12 12"><g stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M10.606,9.008a5.5,5.5,0,1,1,.679-4.535"></path><polyline points="11.5 0.5 11.5 4.5 7.531 4.007"></polyline></g></svg>
                            </button>
                        </div> -->
                        <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/5 flex items-center px-3 my-2">
                            <div class="flex-1 block relative">
                                <angular2-multiselect (click)="feedData('ri')" [data]="riskFilterList" [(ngModel)]="selectedRiskFilterItems"
                                    [settings]="decisionSettings"
                                    (onSelect)="onItemSelectDeselect($event,'ri',0)"
                                    (onDeSelect)="onItemSelectDeselect($event,'ri',0)"
                                    (onSelectAll)="onItemSelectDeselect($event,'ri',0)"
                                    (onDeSelectAll)="onItemSelectDeselect($event,'ri',0)"></angular2-multiselect>
                            </div>
                            <button (click)="resetRemoveFilter('ri')" class="w-8 h-8 rounded-full bg-refresh text-white ml-3 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 stroke-current fill-ccurrent" viewBox="0 0 12 12"><g stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M10.606,9.008a5.5,5.5,0,1,1,.679-4.535"></path><polyline points="11.5 0.5 11.5 4.5 7.531 4.007"></polyline></g></svg>
                            </button>
                        </div>


                    </div>
                    <div class="flex flex-wrap -mx-3">
                      <div class="w-full text-right px-3">
                        <button *ngIf="showGroups||showEvents||showWeathers||showDecision||showStatus||showRsi||showCostitems||showMonths||showStates||showCountries||showPostcodes||showModels||showRisk" class="button-filter text-center mr-1" [ngStyle]="{'background':(buttoncolor=='orange'?'linear-gradient(to right, #EB8B38 0%, #E46B22 50%, #DD4E0E 100%)':'')}" [style.color]="buttoncolor=='orange'?'white':''" [style.font-weight]="buttoncolor=='orange'?'normal':'bold'" (click)="fetchFilters(1)">Apply Filters</button>
                        <button *ngIf="showGroups||showEvents||showWeathers||showDecision||showStatus||showRsi||showCostitems||showMonths||showStates||showCountries||showPostcodes||showModels||showRisk" class="button-filter text-center" (click)="resetRemoveFilter('all')">Clear All Filters</button>

                      </div>
                    </div>
                </div>

            </div>
        </div>

        <table class="w-full block relative overflow-hidden border border-gray-300 lg:border-0 rounded mb-5">
            <thead class="header-table bg-primary text-white">
                <tr *ngFor="let i of [].constructor(10)" class="flex flex-col flex-no-wrap lg:table-row mb-2 lg:mb-0">
                    <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">Event Name</th>
                    <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">
                        <div class="h-10 lg:h-auto my-1">Event</div>
                    </th>
                    <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">Date Range</th>
                    <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">Contract ID</th>
                    <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">Risk</th>
                    <th *ngIf="unlimited != 1" class="header-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">Usage</th>
                    <th class="header-table__elem text-left text-white font-light border-r border-primary px-2 xl:px-3 py-1 lg:py-2">Summary</th>
                </tr>
            </thead>
            <tbody #bodyTable class="block lg:table-row-group flex-1 lg:flex-none bg-white border-gray-300 lg:border-t-0 lg:border rounded rounded-t-none">
                <tr *ngIf="resultData.length<1" class="flex flex-col flex-no-wrap border-b-none border-gray-300 lg:table-row mb-0 lg:mb-0">
                    <td colspan="7" class="text-sm text-center border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">
                       <b> {{(showSearchEvent||showGroups||showEvents||showWeathers||showDecision||showStatus||showRsi||showCostitems||showMonths||showStates||showCountries||showPostcodes||showModels||showRisk)?"No events found matching search criteria":"No data found"}}</b>
                    </td>
                </tr>
                <ng-template ngFor let-event [ngForOf]="resultData" let-in="index">
                    <tr class="hidden lg:flex flex-col flex-no-wrap border-b border-gray-300 lg:table-row mb-2 lg:mb-0">
                        <td class="text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">
                            {{event.eventName ? event.eventName : 'NA'}}
                        </td>
                        <td class="text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">
                            <div class="flex flex-wrap items-center justify-start">
                                <div *ngIf="event.selectedWeather.includes('rain')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                    <app-weather-type-small [type]="'rain'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('wind')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                    <app-weather-type-small [type]="'wind'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('temperature')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                    <app-weather-type-small [type]="'temperature'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('snow')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                    <app-weather-type-small [type]="'snow'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('hail')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                    <app-weather-type-small [type]="'hail'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('storm')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                    <app-weather-type-small [type]="'storm'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('dust')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                    <app-weather-type-small [type]="'dust'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('fog')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                    <app-weather-type-small [type]="'fog'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('tornado')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                    <app-weather-type-small [type]="'tornado'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('hurricane')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                    <app-weather-type-small [type]="'hurricane'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('earthquake')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                    <app-weather-type-small [type]="'earthquake'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('wildfire')" class="w-10 h-10 mr-2 flex-shrink-0 my-1">
                                    <app-weather-type-small [type]="'wildfire'"></app-weather-type-small>
                                </div>
                            </div>
                        </td>
                        <td class="text-xs xl:text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">
                            {{event.Start_Date}} - {{event.End_Date}}
                        </td>
                        <td class="text-xs xl:text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2 max-w-xxs">
                            <a *ngIf="event.selectedWeather.join(',').length>2" (click)="onClickReportGo(event.eventId)" href="javascript:void(0)">{{event.contractName}}</a>
                            <a *ngIf="event.selectedWeather.join(',').length<2" style="cursor: auto;" href="javascript:void(0)">{{event.contractName}}</a>

                            <div *ngIf="event.selectedWeather.join(',').length>2" (click)="onClickReportGo(event.eventId)" href="javascript:void(0)" class="flex float-right w-6 h-6 rounded-full btn-gradient items-center justify-between text-white font-light cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mx-auto w-4 h-4 py-px">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                </svg>                                            
                            </div>
                        </td>
                        <td class="text-xs xl:text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2 min-w-16">
                            &pound; {{event.eventCost}}
                        </td>
                        <td *ngIf="unlimited != 1" class="text-xs xl:text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2 font-semibold text-orange-base">
                            <span class="btn-gradient rounded xl:rounded-full text-white py-1 text-xs px-3 inline-block mx-auto font-semibold">{{event.insightCount}}</span>
                        </td>
                        <td class="text-xs xl:text-sm border-r border-gray-300 px-2 xl:px-3 py-1 lg:py-2">
                            <div class="flex flex-wrap items-center">
                                <!-- Summary -->
                                <button (click)="showThisItem(event,in)" (clickOutside)="onClickedOutside(event, in)" class="{{event.infoColor}}" class="has-tooltip relative tooltipwrp ml-2" data-template="content-tooltip">
                                    <span class="bg-gray-300 text-primary rounded-full w-5 h-5 inline-flex items-center justify-center" style="margin-top: -1px">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="fill-current stroke-current" width="10" height="10" viewBox="0 0 16 16" class="relative fill-current"><path d="M9,7c0-0.6-0.4-1-1-1H5v2h2v6H5v2h6v-2H9V7z"></path><circle cx="7.5" cy="2.5" r="1.5"></circle></svg>
                                    </span>
                                    <div *ngIf="event.show && in==currentIndex" class="tooltip" id="content-tooltip">
                                        <div [innerHtml]="infoText" class="text-white tooltip_wrp mb-2"></div>
                                    </div>
                                </button>
                                <!-- Enable/Disable -->
                                <!-- <button *ngIf="event.showAbandon && event.status==2 && viewas==''" class="ml-2">
                                    <span (click)="onStatus(0,event)" class="bg-gray-300 text-primary rounded-full w-5 h-5 inline-flex items-center justify-center" style="margin-top: -1px">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="fill-current stroke-current" width="9" height="9" viewBox="0 0 16 16"><g><path d="M14.7,1.3c-0.4-0.4-1-0.4-1.4,0L8,6.6L2.7,1.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L6.6,8l-5.3,5.3 c-0.4,0.4-0.4,1,0,1.4C1.5,14.9,1.7,15,2,15s0.5-0.1,0.7-0.3L8,9.4l5.3,5.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L9.4,8l5.3-5.3C15.1,2.3,15.1,1.7,14.7,1.3z"></path></g></svg>
                                    </span>
                                </button>
                                <button *ngIf="event.showAbandon && event.status==1 && viewas==''" class="ml-2">
                                    <span (click)="onStatus(1,event)" class="btn-gradient text-white rounded-full w-5 h-5 inline-flex items-center justify-center" style="margin-top: -1px">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="fill-current stroke-current" width="9" height="9" viewBox="0 0 12 12"><g><path d="M10.293,1.293,4,7.586,1.707,5.293A1,1,0,0,0,.293,6.707l3,3a1,1,0,0,0,1.414,0l7-7a1,1,0,0,0-1.414-1.414Z"></path></g></svg>
                                    </span>
                                </button> -->
                                <!-- Enable/Disable -->
                                <!-- Bind/Unbind -->
                                <!-- Green -->
                                <!-- <button *ngIf="event.showAbandon && viewas=='' && event.bidStatus==4" class="ml-2">
                                    <span (click)="onBid(1,event)" class="text-primary w-5 h-5 inline-flex items-center justify-center" style="margin-top: 1px">
                                        <svg xmlns="http://www.w3.org/2000/svg"  class="fill-current stroke-current back-green w-4 h-4" style="position: relative; top:2px;" viewBox="0 0 12 12"><g stroke-width="1"><polygon points="10.5 11.5 1.5 11.5 1.5 0.5 7.5 0.5 10.5 3.5 10.5 11.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></polygon><polyline points="7.5 0.5 7.5 3.5 10.5 3.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></polyline><polygon points="7.5 0.5 7.5 3.5 10.5 3.5 7.5 0.5" data-stroke="none" stroke="none"></polygon></g></svg>
                                    </span>
                                </button> -->
                                <!-- Red -->
                                <!-- <button *ngIf="event.showAbandon && viewas=='' && event.bidStatus==3" class="ml-2">
                                    <span (click)="onBid(0,event)"class="text-primary w-5 h-5 inline-flex items-center justify-center" style="margin-top: 1px">
                                        <svg xmlns="http://www.w3.org/2000/svg"  class="fill-current stroke-current back-red w-4 h-4" style="position: relative; top:2px;" viewBox="0 0 12 12"><g stroke-width="1"><polygon points="10.5 11.5 1.5 11.5 1.5 0.5 7.5 0.5 10.5 3.5 10.5 11.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></polygon><polyline points="7.5 0.5 7.5 3.5 10.5 3.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></polyline><polygon points="7.5 0.5 7.5 3.5 10.5 3.5 7.5 0.5" data-stroke="none" stroke="none"></polygon></g></svg>
                                    </span>
                                </button> -->
                                <!-- Bind/Unbind -->
                            </div>
                        </td>
                    </tr>
                    <tr class="block lg:hidden border-b border-gray-300">
                        <td class="block text-sm p-3">
                            <div class="flex flex-wrap justify-start mb-2">
                                <div *ngIf="event.selectedWeather.includes('rain')" class="w-10 h-10 mr-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'rain'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('wind')" class="w-10 h-10 mr-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'wind'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('temperature')" class="w-10 h-10 mr-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'temperature'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('snow')" class="w-10 h-10 mr-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'snow'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('hail')" class="w-10 h-10 mr-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'hail'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('storm')" class="w-10 h-10 mr-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'storm'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('dust')" class="w-10 h-10 mr-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'dust'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('fog')" class="w-10 h-10 mr-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'fog'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('tornado')" class="w-10 h-10 mr-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'tornado'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('hurricane')" class="w-10 h-10 mr-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'hurricane'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('earthquake')" class="w-10 h-10 mr-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'earthquake'"></app-weather-type-small>
                                </div>
                                <div *ngIf="event.selectedWeather.includes('wildfire')" class="w-10 h-10 mr-2 flex-shrink-0">
                                    <app-weather-type-small [type]="'wildfire'"></app-weather-type-small>
                                </div>
                            </div>
                            <div class="mb-1 text-xs">
                                Event Name <strong class="ml-3 text-sm">{{event.eventName ? event.eventName : 'NA'}}</strong>
                            </div>
                            <div class="mb-1 text-xs">
                                Contract ID <strong class="ml-3 text-sm">{{event.contractName}}</strong>
                            </div>
                            <div class="mb-1 text-xs">
                                Period <strong class="ml-3 text-sm">{{event.Start_Date}} - {{event.End_Date}}</strong>
                            </div>
                            <div class="mb-1 text-xs">
                                Risk <strong class="ml-3 text-sm">&pound; {{event.eventCost}}</strong>
                            </div>
                            <div class="mb-1 text-xs" *ngIf="unlimited != 1">
                                Usage <strong class="ml-3 text-sm"><span class="btn-gradient rounded-full text-white py-1 text-xs px-3 inline-block mx-auto font-semibold">{{event.insightCount}}</span></strong>
                            </div>
                            <div class="lg:mb-1 text-xs">
                                Summary <strong class="ml-3 text-sm">
                                    <button (click)="showThisItemMobile(event,in)" (clickOutside)="onClickedOutsideMobile(event, in)" class="has-tooltip relative tooltipwrp ml-3" class="{{event.infoColor}}" data-template="content-tooltip-mobile">
                                        <span class="bg-gray-300 text-primary rounded-full w-5 h-5 inline-flex items-center justify-center" style="margin-top: -1px">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 16 16" class="relative fill-current"><path d="M9,7c0-0.6-0.4-1-1-1H5v2h2v6H5v2h6v-2H9V7z"></path><circle cx="7.5" cy="2.5" r="1.5"></circle></svg>
                                        </span>
                                        <div *ngIf="event.showMobilePopup && in==currentIndexMobile" class="tooltip mobile" id="content-tooltip-mobile">
                                            <div id="content-tooltip">
                                                <div [innerHtml]="infoText" class="text-white tooltip_wrp mb-2"></div>
                                            </div>

                                        </div>
                                    </button>

                                <!-- Enable/Disable -->
                                <!-- <button *ngIf="event.showAbandon && event.status==2 && viewas==''" class="ml-2">
                                    <span (click)="onStatus(0,event)" class="bg-gray-300 text-primary rounded-full w-5 h-5 inline-flex items-center justify-center" style="margin-top: -1px">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="fill-current stroke-current" width="9" height="9" viewBox="0 0 16 16"><g><path d="M14.7,1.3c-0.4-0.4-1-0.4-1.4,0L8,6.6L2.7,1.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L6.6,8l-5.3,5.3 c-0.4,0.4-0.4,1,0,1.4C1.5,14.9,1.7,15,2,15s0.5-0.1,0.7-0.3L8,9.4l5.3,5.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L9.4,8l5.3-5.3C15.1,2.3,15.1,1.7,14.7,1.3z"></path></g></svg>
                                    </span>
                                </button>
                                <button *ngIf="event.showAbandon && event.status==1 && viewas==''" class="ml-2">
                                    <span (click)="onStatus(1,event)" class="btn-gradient text-white rounded-full w-5 h-5 inline-flex items-center justify-center" style="margin-top: -1px">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="fill-current stroke-current" width="9" height="9" viewBox="0 0 12 12"><g><path d="M10.293,1.293,4,7.586,1.707,5.293A1,1,0,0,0,.293,6.707l3,3a1,1,0,0,0,1.414,0l7-7a1,1,0,0,0-1.414-1.414Z"></path></g></svg>
                                    </span>
                                </button> -->
                                <!-- Enable/Disable -->
                                <!-- Bind/Unbind -->
                                <!-- Green -->
                                <!-- <button *ngIf="event.showAbandon && viewas=='' && event.bidStatus==4" class="ml-2">
                                    <span (click)="onBid(1,event)" class="text-primary w-5 h-5 inline-flex items-center justify-center" style="margin-top: 1px">
                                        <svg xmlns="http://www.w3.org/2000/svg"  class="fill-current stroke-current back-green w-4 h-4" style="position: relative; top:2px;" viewBox="0 0 12 12"><g stroke-width="1"><polygon points="10.5 11.5 1.5 11.5 1.5 0.5 7.5 0.5 10.5 3.5 10.5 11.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></polygon><polyline points="7.5 0.5 7.5 3.5 10.5 3.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></polyline><polygon points="7.5 0.5 7.5 3.5 10.5 3.5 7.5 0.5" data-stroke="none" stroke="none"></polygon></g></svg>
                                    </span>
                                </button> -->
                                <!-- Red -->
                                <!-- <button *ngIf="event.showAbandon && viewas=='' && event.bidStatus==3" class="ml-2">
                                    <span (click)="onBid(0,event)"class="text-primary w-5 h-5 inline-flex items-center justify-center" style="margin-top: 1px">
                                        <svg xmlns="http://www.w3.org/2000/svg"  class="fill-current stroke-current back-red w-4 h-4" style="position: relative; top:2px;" viewBox="0 0 12 12"><g stroke-width="1"><polygon points="10.5 11.5 1.5 11.5 1.5 0.5 7.5 0.5 10.5 3.5 10.5 11.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></polygon><polyline points="7.5 0.5 7.5 3.5 10.5 3.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></polyline><polygon points="7.5 0.5 7.5 3.5 10.5 3.5 7.5 0.5" data-stroke="none" stroke="none"></polygon></g></svg>
                                    </span>
                                </button> -->
                                <!-- Bind/Unbind -->
                                </strong>
                            </div>

                            <div class="mt-4">
                                <button (click)="onClickReportGo(event.eventId)" class="bg-primary block rounded-full text-sm font-semibold text-white px-3 py-2 w-full hover:bg-orange-base">
                                    View report
                                </button>
                            </div>
                        </td>

                    </tr>
                </ng-template>
            </tbody>

            <tfoot class="footer-table bg-primary text-white">
                <tr class="flex flex-col flex-no-wrap lg:table-row mb-2 lg:mb-0">
                    <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">Group</th>
                    <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">Event</th>
                    <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">Date Range</th>
                    <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">Contract ID</th>
                    <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">Risk</th>
                    <th *ngIf="unlimited != 1" class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-2 xl:px-3 py-1 lg:py-2">Usage</th>
                    <th class="footer-table__elem text-left text-white font-light border-r border-primary px-2 xl:px-3 py-1 lg:py-2">Summary</th>
                </tr>
            </tfoot>
        </table>
        <!-- Pagination -->
        <div class="flex justify-center w-full" *ngIf="resultData.length>0">
            <ul class="pager flex items-center justify-center">

                <li class="{{currentPage==1?'inactiveprevious':'activeprevious'}}" class="mr-3">
                    <a (click)="previous()" href="javascript:void(0)" class="border border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700">
                        <svg class="w-3 h-3 text-gray-600 fill-current stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polyline fill="none" stroke-miterlimit="10" points="16,1 8,12 16,23 "></polyline></g></svg>
                    </a>
                </li>
                <li *ngFor="let page of pages;let i = index" class="mx-1">
                    <a (click)="onPage(page)" href="javascript:void(0)" class="{{(currentPage)==page?'opacity-75 cursor-default':'font-semibold hover:text-gray-600'}}" class="block p-2">{{page}}</a>
                </li>
                <li class="{{currentPage==numOfPages?'inactivenext':'activenext'}}" class="ml-3">
                    <a (click)="next()" href="javascript:void(0)" class="border border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-600 fill-current stroke-current" viewBox="0 0 24 24"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polyline fill="none" stroke-miterlimit="10" points="8,1 16,12 8,23 "></polyline></g></svg>
                    </a>
                </li>
            </ul>
        </div>
        <!-- Pagination -->

        

    </div>
</div>


