<div [className]="isLast ? 'flex flex-wrap sm:flex-no-wrap h-full -mx-2 m-w-full' : 'flex flex-wrap sm:flex-no-wrap h-full -mx-2 mb-4 m-w-full'">
    <!--<div class="w-full sm:w-auto xl:w-2/5 xl:max-w-max-content sm:flex-grow-0 flex items-stretch w-auto px-2">-->
    <div class="w-2/5 max-w-max-content flex items-stretch mx-2 relative  bg-gray-100 thumbnail-container">
        <img *ngIf="data.thumbnail" src="{{ data.thumbnail }}" (error)="invalidImageUrl($event)" class="rounded w-full h-full object-center object-cover image-thumbnail" />
        <img *ngIf="!data.thumbnail && data.defaultThumbnail" src="{{ data.defaultThumbnail }}" (error)="invalidImageUrl($event)" class="rounded w-full h-full object-center object-cover image-thumbnail" />
        <div *ngIf="data.category === 'Earthquake'" class="absolute flex top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-7 h-7 rounded-full justify-center items-center ">
            <span class="text-sm font-extrabold" [ngClass]="overlayEQ()">{{ this.data.severityValue }}</span>
        </div>
        <div *ngIf="data.category === 'Tropical Cyclone'" class="absolute flex top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-7 h-7 justify-center items-center">
            <img class="w-8 max-w-none" [src]="'/assets/images/hurricane-' + this.data.alert.toLowerCase() + '.png'">
            <span class="absolute text-xs text-white font-extrabold">{{ this.data.severityValue }}</span>
        </div>
        <div *ngIf="data.category === 'Wildfire' || data.category === 'Drought'" class="absolute flex mt-1 ml-1 px-1 w-auto h-4 border-1 rounded-sm justify-center items-center" [ngClass]="overlayTC()">
            <span class="text-xs text-white font-extrabold">{{ this.data.severityValue }} {{ this.data.severityUnit }}</span>
        </div>
    </div>
    <!--<div class="w-full sm:w-auto xl:w-3/5 sm:flex-grow px-2 py-0 flex items-start">-->
    <div class="flex flex-1 px-2 py-0 items-start overflow-hidden">
        <div class="w-full flex flex-col h-full">
            <div class="flex justify-between items-end" ><!--*ngIf="data.category">-->
                <!--<a routerLink="{{ data.category.link }}" class="link-category" *ngIf="data.category.link && data.category.title">
                    <span class="text-sm">{{ data.category.title }}</span>
                </a>-->
                <!--<span class="text-sm" *ngIf="!data.category.link && data.category.title">{{ data.category.title }}</span>-->
                <span class="text-sm">{{ data.category }}</span>
                <span class="text-sm ml-auto mr-3 font-medium">{{ data.isCurrent === 'true' ? 'Active' : data.isCurrent === 'false' ? 'Inactive' : '' }}</span>
                <div class="flex w-8 h-8 bg-gray-200 rounded justify-center items-center" [ngClass]="iconAlertClass()">
                    <img class="w-6 h-6" [src]="iconImageUrl()"/>
                </div>
            </div>
            <div class="flex flex-col h-full justify-between">
                <span class="block font-semibold text-md text-hidden-ellipsis"><a href="{{data.link}}" (click)="clickednews(data)" target="_blank">{{ data.title }}</a></span>
                <span *ngIf="!isInsideLatestNews" class="block mb-1 desc-text" [innerHTML]="data.description"></span>
                <span class="block stats-text mt-auto" [innerHTML]="data.severity"></span>
                <span class="block stats-text" [innerHTML]="data.statLine"></span>        
                <span class="block stats-text" [innerHTML]="data.location"></span>
                <span class="inline-block to-date font-semibold mt-auto text-right" [innerHTML]="data.toDate"></span>
            </div>
        </div>


    </div>
</div>
