    <div class="lg:flex-grow p-4 md:p-6 lg:p-12 relative">
        <app-backgrounds></app-backgrounds>
        <div class="w-full relative z-10">
            <div class="flex flex-wrap -mx-4 lg:-mx-6 justify-between mb-3 md:mb-5 lg:mb-8">
                <div class="px-4 lg:px-6 w-auto lg:w-1/2">
                    <h1 class="font-semibold text-xl lg:text-2xl">{{ titleField }}</h1>
                </div>
                <!--<div class="px-4 lg:px-6 w-auto flex flex-wrap items-center">
                    <app-search-reports-button></app-search-reports-button>
                </div>-->
            </div>
            <div class="flex flex-wrap -mx-4 lg:-mx-6 justify-between mb-3 md:mb-5 lg:mb-8">
                <div class="px-4 lg:px-6 w-full lg:w-1/2">
                    <div class="w-full">
                        <h4 class="font-semibold mb-4 lg:mb-6">Send us a message and we will contact you shortly...</h4>
                        <form class="flex flex-wrap -mx-3" [formGroup]="contactUsForm" (ngSubmit)="sendForm()">
                            <div class="mb-4 w-full lg:w-1/2 px-3">
                                <label class="mb-1 font-semibold text-gray-700 text-xs block cursor-pointer" for="name">Your name <sup>*</sup></label>
                                <input type="text" class="w-full block bg-gray-200 rounded px-3 py-2 cursor-pointer focus:bg-white border border-gray-200 font-sans tracking-wide focus:border-gray-300" id="name" formControlName="name" placeholder="Your name *" required>
                                <div class="mt-1 mb-2 font-semibold text-sm text-orange-dark"><span [hidden]="contactUsForm.get('name').valid">Name is required.</span></div>
                                
                            </div>
                            <div class="mb-4 w-full lg:w-1/2 px-3">
                                <label class="mb-1 font-semibold text-gray-700 text-xs block cursor-pointer" for="email">Your email <sup>*</sup></label>
                                <input type="email" class="w-full block bg-gray-200 rounded px-3 py-2 cursor-pointer focus:bg-white border border-gray-200 font-sans tracking-wide focus:border-gray-300" id="email" formControlName="email" placeholder="Your email *">
                                <div class="mt-1 mb-2 font-semibold text-sm text-orange-dark"><span [hidden]="contactUsForm.get('email').valid">Email is required.</span></div>
                                
                            </div>
                            <div class="mb-4 w-full px-3">
                                <label class="mb-1 font-semibold text-gray-700 text-xs block cursor-pointer" for="phone">Your phone <sup>*</sup></label>
                                <input type="email" class="w-full block bg-gray-200 rounded px-3 py-2 cursor-pointer focus:bg-white border border-gray-200 font-sans tracking-wide focus:border-gray-300" id="phone" formControlName="phone" placeholder="Your phone *">
                                <div class="mt-1 mb-2 font-semibold text-sm text-orange-dark"><span [hidden]="contactUsForm.get('phone').valid">Please enter a valid no.</span></div>
                                
                            </div>
                            <div class="mb-4 w-full px-3">
                                <label class="mb-1 font-semibold text-gray-700 text-xs block cursor-pointer" for="message">Your message <sup>*</sup></label>
                                <div class="mt-1 mb-2 font-semibold text-sm text-orange-dark" [hidden]="contactUsForm.get('message').valid">Message is required.</div>
                                <textarea class="w-full min-h-textarea block bg-gray-200 rounded px-3 py-2 cursor-pointer focus:bg-white border border-gray-200 font-sans tracking-wide focus:border-gray-300 resize-none" id="message" formControlName="message" placeholder="Your message *"></textarea>
                            </div>
                            <div class="w-full px-3">
                                <button type="submit" class="btn btn-block" [ngClass]="contactUsForm.valid?'btn-gradient':' btn-gradient-dis'" [disabled]="!contactUsForm.valid" *ngIf="!response">Send message</button>

                                <div class="element-response w-full text-sm mt-4 text-center" *ngIf="response" [innerHTML]="response">
                                </div>

                            </div>

                        </form>
                    </div>
                </div>
                <div class="px-4 lg:px-6 w-full lg:w-1/2 mt-4 lg:mt-0">
                    <div class="flex flex-wrap -mx-3">
                        <div class="w-full sm:w-1/2 lg:w-full 2xl:w-1/2 px-3 flex flex-col items-stretch">
                            <a href="tel:+44 207 846 3277" class="block h-full w-full rounded-lg bg-call p-4 lg:p-6 mt-4">
                                <div class="flex flex-wrap items-center justify-between -mx-2">
                                    <div class="px-2 w-auto">
                                        <h3 class="text-white font-semibold text-xs uppercase tracking-wider mb-1 opacity-75">Call</h3>
                                        <div class="font-light text-white text-sm">+44 207 846 3277</div>
                                    </div>
                                    <div class="w-auto px-2">
                                    <div class="w-10 h-10 rounded-full border border-white flex items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 stroke-current fill-current text-white" viewBox="0 0 16 16"><title>phone-call</title><g stroke-width="1"><path d="M9.178,10.529,8.119,11.854A11.187,11.187,0,0,1,4.146,7.881L5.471,6.822a.9.9,0,0,0,.26-1.068L4.524,3.036A.9.9,0,0,0,3.475,2.53l-2.3.6a.9.9,0,0,0-.668,1A13.35,13.35,0,0,0,11.874,15.491a.9.9,0,0,0,1-.668l.6-2.3a.9.9,0,0,0-.506-1.048L10.246,10.27A.9.9,0,0,0,9.178,10.529Z" fill="none" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8.5,4A3.5,3.5,0,0,1,12,7.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8.5,1A6.5,6.5,0,0,1,15,7.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
                                    </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="w-full sm:w-1/2 lg:w-full 2xl:w-1/2 px-3 flex flex-col items-stretch">
                             <a href="mailto:letschat@metswift.com" class="block h-full w-full rounded-lg bg-email p-4 lg:p-6 mt-4">
                                <div class="flex flex-wrap items-center justify-between -mx-2">
                                    <div class="w-auto px-2">
                                        <h3 class="text-white font-semibold text-xs uppercase tracking-wider mb-1 opacity-75">Email</h3>
                                        <div class="font-light text-white text-sm">letschat@metswift.com</div>
                                    </div>
                                    <div class="w-auto px-2">
                                    <div class="w-10 h-10 rounded-full border border-white flex items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg"class="w-4 h-4 stroke-current fill-current text-white" viewBox="0 0 24 24"><title>send</title><g stroke-linecap="square" stroke-linejoin="miter" stroke-width="1.5"><polyline fill="none" stroke-miterlimit="10" points="22,2 7,14 7,21 10.6,16.7 " stroke-linecap="butt"></polyline> <polygon fill="none" stroke-miterlimit="10" points="2,10 22,2 18,22 "></polygon></g></svg>
                                    </div>
                                    </div>
                                </div>
                            </a>

                        </div>
                        <div class="w-full px-3">
                            <div class="rounded-lg border boder-gray-100 p-4 mt-4 bg-white">
                                <h3 class="text-primary font-semibold text-xs uppercase tracking-wider mb-1 opacity-75">Our office</h3>
                                <div class="font-light text-primary">No. 1 Aire Street, Leeds, LS1 4PR,  United Kingdom</div>

                                <div class="relative">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2356.640362434063!2d-1.5508808841445831!3d53.795888180075686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48795c1e5c5b95c9%3A0xd148a1da0d39775f!2s1%20Aire%20St%2C%20Leeds%20LS1%204AS%2C%20UK!5e0!3m2!1sen!2sin!4v1606127998134!5m2!1sen!2sin"
              width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen></iframe>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>


    </div>



