<div class="w-full flex flex-nowrap justify-start relative tooltipwrp ">
    <span class="hidden-value" #hiddenValue></span>
    <div [ngClass]="isEditing ? 'flex-grow' : 'flex-none custom-max-width'">
        <input (keydown.enter)="blurInput()" #inputTitle maxlength="50" type="text" class="input-title-editable border-primary" [ngClass]="isEditing ? 'border border-gray-300 focus:cursor-auto rounded-md pl-3 py-1 w-full' : 'border-0 pointer-events-none w-auto'" [readonly]="!isEditing" value="{{ title }}" 
        (change)="resizeInput()" />      
    </div>  
    <!-- <input #inputTitle maxlength="50" (blur)="blurInput()" (focus)="onClickEdit()" (change)="resizeInput()" type="text" class="flex-grow cursor-pointer input-title-editable truncate" value="{{ title }}"/> -->
    <div class="flex-none">
        <button type="button" (click)="onClickEdit()" *ngIf="!isEditing" class="editable-button w-10 h-10 rounded-full border border-gray-400 ml-3 flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current fill-current" viewBox="0 0 12 12"><g stroke-width="1"><line x1="0.5" y1="11.5" x2="11.5" y2="11.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></line> <polygon points="8.5 0.5 10.5 2.5 4.5 8.5 1.5 9.5 2.5 6.5 8.5 0.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></polygon></g></svg>
        </button>
        <button (click)="blurInput()" *ngIf="saveIt" type="button" class="save-button editable-button w-10 h-10 rounded-full border border-gray-400 mt-1 ml-8 flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 50 50">
                <path d="M 42.875 8.625 C 42.84375 8.632813 42.8125 8.644531 42.78125 8.65625 C 42.519531 8.722656 42.292969 8.890625 42.15625 9.125 L 21.71875 40.8125 L 7.65625 28.125 C 7.410156 27.8125 7 27.675781 6.613281 27.777344 C 6.226563 27.878906 5.941406 28.203125 5.882813 28.597656 C 5.824219 28.992188 6.003906 29.382813 6.34375 29.59375 L 21.25 43.09375 C 21.46875 43.285156 21.761719 43.371094 22.050781 43.328125 C 22.339844 43.285156 22.59375 43.121094 22.75 42.875 L 43.84375 10.1875 C 44.074219 9.859375 44.085938 9.425781 43.875 9.085938 C 43.664063 8.746094 43.269531 8.566406 42.875 8.625 Z"></path>
            </svg>
        </button>
    </div>
    <!--<div class="tooltip enqry" *ngIf="!isEditing">
        <p>{{ title }}</p>
    </div>-->
</div>
<div class="pt-1 text-sm" *ngIf="isEditing">
    Maximum 50 characters are allowed.
</div>
