<!-- <table class="w-full flex flex-row flex-no-wrap relative overflow-hidden rounded">
    <thead class="header-table bg-primary text-white">
        <tr *ngFor="let item of rows" class="flex flex-col flex-no-wrap lg:table-row mb-2 lg:mb-0">
            <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Date</th>
            <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Chance</th>
            <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Count</th>
            <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Years</th>
            <th class="header-table__elem text-left text-white font-light border-r border-primary px-3 py-2">Risk summary</th>
        </tr>
    </thead>
    <tbody class="flex-1 lg:flex-none">
        <tr class="flex flex-col flex-no-wrap border-b border-gray-300 lg:table-row mb-2 lg:mb-0" *ngFor="let item of rows">
            <td class="border-r border-l border-gray-300 px-3 py-2 text-sm font-semibold">{{ item.date }}</td>
            <td class="border-r border-gray-300 px-3 py-2">
                <div class="font-semibold text-yellow-dark text-sm">{{ item.chance.mod }}{{item.chance.mod==0?"%":''}}</div>
                <div class="font-semibold text-orange-dark text-sm">{{ item.chance.sev }}{{item.chance.sev==0?"%":''}}</div>
            </td>
            <td class="border-r border-gray-300 px-3 py-2">
                <div class="font-semibold text-yellow-dark text-sm">{{ item.count.mod }}</div>
                <div class="font-semibold text-orange-dark text-sm">{{ item.count.sev }}</div>
            </td>
            <td class="border-r border-gray-300 px-3 py-2 lg:max-w-xxs">
                <div><div class="relative line-years truncate font-semibold text-yellow-dark text-sm cursor-pointer hover:opacity-50" #tooltipYearsModElem [tooltipYearsMod]="item.tooltipYearsMod">{{  item.years.mod }}</div></div>
                <div><div class="relative line-years truncate font-semibold text-orange-dark text-sm cursor-pointer hover:opacity-50" #tooltipYearsSevElem [tooltipYearsSev]="item.tooltipYearsSev">{{ item.years.sev }}</div></div>
           </td>
            <td class="border-r border-gray-300 px-3 py-2 text-sm font-semibold {{ item.risksummaryClass }}">
                {{ item.risksummary }}
            </td>
        </tr>

    </tbody>
    <tfoot class="footer-table bg-primary text-white">
        <tr class="hidden lg:table-row lg:mb-0">
            <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1">Average</th>
            <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1">

                <div class="font-semibold text-yellow-dark text-sm leading-tight">{{ avarageData.prob[0].T1 }}%</div>
                <div class="font-semibold text-orange-dark text-sm leading-tight">{{ avarageData.prob[1].T2 }}%</div>
            </th>
            <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1">
                <div class="font-semibold text-yellow-dark text-sm leading-tight">{{ avarageData.count[0].T1 }}</div>
                <div class="font-semibold text-orange-dark text-sm leading-tight">{{ avarageData.count[1].T2 }}</div>
            </th>
            <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1">N/A</th>
            <th class="footer-table__elem text-left font-semibold text-orange-dark font-light border-r border-primary px-3 py-1">
                <span *ngIf="avarageData.prob[1].T2==0 && avarageData.prob[0].T1!=0"
                [ngStyle]="{color: '#FFFF00'}">Moderate
                {{avarageData.prob[0].T1}}%
              </span>
              <span
                *ngIf="avarageData.prob[1].T2>0 && avarageData.prob[0].T1!=0">Severe
                {{avarageData.prob[1].T2>=0.1?avarageData.prob[1].T2:(avarageData.prob[1].T2==0?'0':'<0.1')}}%</span>
              <span *ngIf="avarageData.prob[0].T1==0 && avarageData.prob[1].T2==0"
                [ngStyle]="{color: '#008000'}">No
                Risk
              </span>                
            </th>
        </tr>

    </tfoot>

</table>
<div class="flex justify-center w-full mt-4">
    <ul class="pager flex items-center justify-center">

        <li class="activeprevious mr-3">
            <a (click) = "prevpage()" class="border cursor-pointer border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700">
                <svg class="w-3 h-3 text-gray-600 fill-current stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polyline fill="none" stroke-miterlimit="10" points="16,1 8,12 16,23 "></polyline></g></svg>
            </a>
        </li>
        <li class="activenext ml-3">
            <a (click) ="nextpage()" class="border cursor-pointer border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-600 fill-current stroke-current" viewBox="0 0 24 24"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polyline fill="none" stroke-miterlimit="10" points="8,1 16,12 8,23 "></polyline></g></svg>
            </a>
        </li>
    </ul>
</div> -->
<ng-container
    *ngIf="weatherid != 5 && weatherid != 6 && weatherid != 7 && weatherid != 8 then showNonExtremeTableTemplate else showExtremeTableTemplate;">
</ng-container>
<ng-template #showNonExtremeTableTemplate>
    <table class="flex flex-row flex-no-wrap relative overflow-hidden rounded w-full border lg:border-0 border-gray-300">
        <thead class="header-table bg-primary text-white">
            <tr *ngFor="let item of rows" class="flex flex-col flex-no-wrap border-b lg:border-b-0 border-gray-700 lg:table-row mb-0">
                <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1 lg:py-2">Date</th>
                <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1 lg:py-2">Chance</th>
                <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1 lg:py-2">Count</th>
                <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1 lg:py-2">Years</th>
                <th class="header-table__elem text-left text-white font-light border-r border-primary px-3 py-1 lg:py-2"><span class="hidden sm:inline">Risk</span> Summary</th>
            </tr>
        </thead>
        <tbody class="flex-1 min-w-0 lg:flex-none overflow-hidden">
            <tr class="flex flex-col flex-no-wrap border-b border-gray-300 lg:table-row mb-0" *ngFor="let item of rows">
                <td class="lg:border-r lg:border-l border-gray-300 px-3 py-1 lg:py-2 text-sm font-semibold">{{ item.date }}</td>
                <td class="lg:border-r border-gray-300 px-3 py-1 lg:py-2 flex items-center lg:table-cell" *ngIf="item.checkdata == 'N/A'">
                    <div class="font-semibold text-gray text-sm mr-2 lg:mr-0">N/A</div>
                </td>
                <td class="lg:border-r border-gray-300 px-3 py-1 lg:py-2 flex items-center lg:table-cell" *ngIf="item.checkdata != 'N/A'">
                    <div class="font-semibold text-yellow-dark text-sm mr-2 lg:mr-0">{{ item.chance.mod }}{{item.chance.mod==0?"%":''}}</div>
                    <div class="font-semibold text-orange-dark text-sm">{{ item.chance.sev }}{{item.chance.sev==0?"%":''}}</div>
                </td>
                <td class="lg:border-r border-gray-300 px-3 py-1 lg:py-2 flex items-center lg:table-cell" *ngIf="item.checkdata == 'N/A'">
                    <div class="font-semibold text-gray text-sm mr-2 lg:mr-0">N/A</div>
                </td>
                <td class="lg:border-r border-gray-300 px-3 py-1 lg:py-2 flex items-center lg:table-cell" *ngIf="item.checkdata != 'N/A'">
                    <div class="font-semibold text-yellow-dark text-sm mr-2 lg:mr-0">{{ item.count.mod }}</div>
                    <div class="font-semibold text-orange-dark text-sm">{{ item.count.sev }}</div>
                </td>
                <td class="lg:border-r border-gray-300 px-3 py-1 lg:py-2 lg:max-w-xxs lg:w-auto flex lg:table-cell items-center min-w-0" *ngIf="item.checkdata == 'N/A'">
                     <div class="sm:w-64 lg:flex-1 min-w-0 max-w-50 lg:max-w-full truncate whitespace-nowrap pr-3 lg:px-0 lg:w-full mobile font-semibold text-gray text-sm">N/A</div>
                    
               </td>    
               <td class="lg:border-r border-gray-300 px-3 py-1 lg:py-2 lg:max-w-xxs lg:w-auto flex lg:table-cell items-center min-w-0" *ngIf="item.checkdata != 'N/A'">
                     <div class="sm:w-64 lg:flex-1 min-w-0 max-w-50 lg:max-w-full truncate whitespace-nowrap pr-3 lg:px-0 lg:w-full mobile"><div class="relative line-years truncate font-semibold text-yellow-dark text-sm cursor-pointer hover:opacity-50" #tooltipYearsModElem [tooltipYearsMod]="item.tooltipYearsMod">
                        {{  item.years.mod }}
                    </div></div>
                    <div class="sm:w-64 lg:flex-1 min-w-0 max-w-50 lg:max-w-full truncate whitespace-nowrap pr-3 lg:px-0 lg:w-full mobile"><div class="relative line-years truncate font-semibold text-orange-dark text-sm cursor-pointer hover:opacity-50" #tooltipYearsSevElem [tooltipYearsSev]="item.tooltipYearsSev">
                        {{ item.years.sev }}
                    </div></div>
               </td>
                <td class="lg:border-r border-gray-300 px-3 py-1 lg:py-2 text-sm font-semibold {{ item.risksummaryClass }}" *ngIf="item.checkdata != 'N/A'">
                    <span *ngIf="item.risktitle" class="text-primary mr-3 font-light" [innerHTML]="item.risktitle"></span>{{ item.risksummary }}
                </td>
                <td class="lg:border-r border-gray-300 px-3 py-1 lg:py-2 font-semibold text-gray text-sm" *ngIf="item.checkdata == 'N/A'">
                    No Data Available
                </td>
            </tr>
    
        </tbody>
        <tfoot class="footer-table bg-primary text-white">
            <tr class="hidden lg:table-row lg:mb-0">
                <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1">Average</th>
                <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1" *ngIf="avarageData.prob[0].T1 == 'N/A'">
    
                    <div class="font-semibold text-gray text-sm leading-tight">N/A</div>
                </th>
                <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1" *ngIf="avarageData.prob[0].T1 != 'N/A'">
    
                    <div class="font-semibold text-yellow-dark text-sm leading-tight">{{ avarageData.prob[0].T1 }}%</div>
                    <div class="font-semibold text-orange-dark text-sm leading-tight">{{ avarageData.prob[1].T2 }}%</div>
                </th>
                <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1" *ngIf="avarageData.prob[0].T1 == 'N/A'">
                    <div class="font-semibold text-gray text-sm leading-tight">N/A</div>
                </th>
                <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1" *ngIf="avarageData.prob[0].T1 != 'N/A'">
                    <div class="font-semibold text-yellow-dark text-sm leading-tight">{{ avarageData.count[0].T1 }}</div>
                    <div class="font-semibold text-orange-dark text-sm leading-tight">{{ avarageData.count[1].T2 }}</div>
                </th>
                <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1">N/A</th>
                <th class="footer-table__elem text-left font-semibold text-orange-dark font-light border-r border-primary px-3 py-1" *ngIf="avarageData.prob[0].T1 != 'N/A'">
                    <span *ngIf="avarageData.prob[1].T2==0 && avarageData.prob[0].T1!=0" [ngStyle]="{color: '#FFFF00'}">
                        <span class="text-white mr-3">Threshold 1</span>
                        {{avarageData.prob[0].T1}}%
                    </span>
                    <span *ngIf="avarageData.prob[1].T2>0 && avarageData.prob[0].T1!=0">
                        <span class="text-white mr-3">Threshold 2</span>
                        {{avarageData.prob[1].T2>=0.1?avarageData.prob[1].T2:(avarageData.prob[1].T2==0?'0':'&lt;0.1')}}%
                    </span>
                    <span *ngIf="avarageData.prob[0].T1==0 && avarageData.prob[1].T2==0" [ngStyle]="{color:'#008000'}">
                        No Risk
                    </span>  
                </th>
                <th class="footer-table__elem text-left font-semibold text-gray font-light border-r border-primary px-3 py-1" *ngIf="avarageData.prob[0].T1 == 'N/A'">
                    No Data Available
                </th>
            </tr>
    
        </tfoot>
    
    </table>
</ng-template>
<ng-template #showExtremeTableTemplate>
    <table class="flex flex-row flex-no-wrap relative overflow-hidden rounded w-full border lg:border-0 border-gray-300">
        <thead class="header-table bg-primary text-white">
            <tr *ngFor="let item of rows" class="flex flex-col flex-no-wrap border-b lg:border-b-0 border-gray-700 lg:table-row mb-0">
                <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1 lg:py-2">Date</th>
                <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1 lg:py-2">Chance</th>
                <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1 lg:py-2">Count</th>
                <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1 lg:py-2">Years</th>
                <!-- <th class="header-table__elem text-left text-white font-light border-r border-primary px-3 py-1 lg:py-2"><span class="hidden sm:inline">Risk</span> Summary</th> -->
            </tr>
        </thead>
        <tbody class="flex-1 min-w-0 lg:flex-none overflow-hidden">
            <tr class="flex flex-col flex-no-wrap border-b border-gray-300 lg:table-row mb-0" *ngFor="let item of rows">
                <td class="lg:border-r lg:border-l border-gray-300 px-3 py-1 lg:py-2 text-sm font-semibold">{{ item.date }}</td>
                <td class="lg:border-r border-gray-300 px-3 py-1 lg:py-2 flex items-center lg:table-cell">
                    <div class="font-semibold text-orange-dark text-sm" *ngIf="item.checkdata != 'NA'">{{ item.chance.sev }}{{item.chance.sev==0?"%":''}}</div>
                    <div class="font-semibold text-gray-dark text-sm" *ngIf="item.checkdata == 'NA'">N/A</div>

                </td>
                <td class="lg:border-r border-black px-3 py-1 lg:py-2 flex items-center lg:table-cell">
                    <div class="font-semibold text-orange-dark text-sm" *ngIf="item.checkdata != 'NA'">{{item.count.sev}}</div>
                    <div class="font-semibold text-gray text-sm" *ngIf="item.checkdata == 'NA'">NA</div>
                </td>
                <td class="lg:border-r border-gray-300 px-3 py-1 lg:py-2 lg:max-w-xxs lg:w-auto flex lg:table-cell items-center min-w-0" *ngIf="item.checkdata != 'NA'">
                    <div class="sm:w-64 lg:flex-1 min-w-0 max-w-50 lg:max-w-full truncate whitespace-nowrap pr-3 lg:px-0 lg:w-full mobile"><div class="relative line-years truncate font-semibold text-orange-dark text-sm cursor-pointer hover:opacity-50" #tooltipYearsSevElem [tooltipYearsSev]="item.tooltipYearsSev">
                        {{ item.years.sev }}
                    </div></div>
               </td>
               <td class="lg:border-r border-gray-300 px-3 py-1 lg:py-2 lg:max-w-xxs lg:w-auto flex lg:table-cell items-center min-w-0" *ngIf="item.checkdata == 'NA'">
                <div class="font-semibold text-gray-dark text-sm">
                    N/A
                </div>
           </td>
               
            </tr>
    
        </tbody>
        <tfoot class="footer-table bg-primary text-white">
            <tr class="hidden lg:table-row lg:mb-0">
                <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1">Average</th>
                <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1">
    
                    <div class="font-semibold text-orange-dark text-sm leading-tight">{{ avarageData.prob[1].T2 !='N/A'?avarageData.prob[1].T2+"%":"N/A" }}</div>
                </th>
                <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1">
                    <div class="font-semibold text-orange-dark text-sm leading-tight">{{ avarageData.count[1].T2 !='N/A'?avarageData.count[1].T2:"N/A" }}</div>
                </th>
                <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-1">N/A</th>
                
            </tr>
    
        </tfoot>
    
    </table>
</ng-template>

<div class="flex justify-center w-full mt-4 relative">
    <ul class="pager flex flex-wrap items-center justify-center">

        <li class="activeprevious mr-3">
            <a (click) = "prevpage()" class="border cursor-pointer border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700">
                <svg class="w-3 h-3 text-gray-600 fill-current stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polyline fill="none" stroke-miterlimit="10" points="16,1 8,12 16,23 "></polyline></g></svg>
            </a>
        </li>
        <li *ngIf="hourlytype=='hourly'">
            <input #dateStartval type="text" readonly="true" class="text-center max-w-100" placeholder="Date" value="{{date}}" />
        </li>
        <li class="activenext ml-3">
            <a (click) ="nextpage()" class="border cursor-pointer border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-600 fill-current stroke-current" viewBox="0 0 24 24"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polyline fill="none" stroke-miterlimit="10" points="8,1 16,12 8,23 "></polyline></g></svg>
            </a>
        </li>
    </ul>
    <div #containerDates class="calContnr"></div>
</div>
