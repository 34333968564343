<div class="w-full relative">
  <div *ngIf="info" #elementInfo
    class="bg-white z-20 text-primary rounded-full w-8 h-8 md:w-5 md:h-5 flex items-center justify-center absolute top-0 right-0 mr-3 my-3">
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 16 16" class="relative fill-current">
      <path d="M9,7c0-0.6-0.4-1-1-1H5v2h2v6H5v2h6v-2H9V7z"></path>
      <circle cx="7.5" cy="2.5" r="1.5"></circle>
    </svg>
  </div>
  <div (click)="onClick()"
    class="button-type-weather button-type-weather--{{ type }} cursor-pointer flex items-stretch flex-col"
    [class.active]="dataActive" [class.otheractive]="dataNotActive">

    <div class="button-type-weather__inner w-full h-full flex items-center flex-col">


      <div class="btn-risks w-full my-auto" *ngIf="type === 'rain'">
        <img src="assets/images/icons-weather/rain.svg" class="icon" />
        <div class="text flex items-center justify-center">
          Rainfall
        </div>
      </div>
      <div class="btn-risks w-full my-auto" *ngIf="type === 'wind'">
        <img src="assets/images/icons-weather/wind.svg" class="icon" />
        <div class="text flex items-center justify-center">
          Wind
        </div>
      </div>
      <div class="btn-risks w-full my-auto" *ngIf="type === 'temperature'">
        <img src="assets/images/icons-weather/temperature.svg" class="icon" />
        <div class="text flex items-center justify-center">
          Temperature
        </div>
      </div>
      <div class="btn-risks w-full my-auto" *ngIf="type === 'snow'">
        <img src="assets/images/icons-weather/snow.svg" class="icon" />
        <div class="text flex items-center justify-center">
          Snow&nbsp;
          depth
        </div>
      </div>
      <div class="btn-risks w-full my-auto" *ngIf="type === 'hail'">
        <img src="assets/images/icons-weather/hail.svg" class="icon" />
        <div class="text flex items-center justify-center">
          Hail
        </div>
      </div>
      <div class="btn-risks w-full my-auto" *ngIf="type === 'storm'">
        <img src="assets/images/icons-weather/storm.svg" class="icon" />
        <div class="text flex items-center justify-center">
          Storm
        </div>
      </div>
      <div class="btn-risks w-full my-auto" *ngIf="type === 'dust'">
        <img src="assets/images/icons-weather/dust.svg" class="icon" />
        <div class="text flex items-center justify-center">
          Dust
        </div>
      </div>
      <div class="btn-risks w-full my-auto" *ngIf="type === 'fog'">
        <img src="assets/images/icons-weather/fog.svg" class="icon" />
        <div class="text flex items-center justify-center">
          Fog
        </div>
      </div>
      <div class="btn-risks w-full my-auto" *ngIf="type === 'hurricane'">
        <img src="assets/images/icons-weather/hurricane.png" class="icon" />
        <div class="text flex items-center justify-center">
          {{hurricaneName}}
        </div>
      </div>
      <div class="btn-risks w-full my-auto" *ngIf="type === 'tornado'">
        <img src="assets/images/icons-weather/hurricane.svg" class="icon" />
        <div class="text flex items-center justify-center">
          Tornadoes
        </div>
      </div>
      <div class="btn-risks w-full my-auto relative" *ngIf="type === 'earthquake'">
        <img src="assets/images/icons-weather/earthquakes.svg" class="icon" />
        <div class="text flex items-center justify-center">
          Earthquakes
        </div>
      </div>
      <div class="btn-risks w-full my-auto relative" *ngIf="type === 'wildfire'">
        <img src="assets/images/icons-weather/wildfire.svg" class="icon" />
        <div class="text flex items-center justify-center">
          Wildfires
        </div>
      </div>
    </div>
  </div>
</div>
