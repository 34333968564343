import { Component, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-results-numeric-value',
  templateUrl: './results-numeric-value.component.html',
  styleUrls: ['./results-numeric-value.component.scss']
})
export class ResultsNumericValueComponent implements OnInit {

  @Input() overviewRiskdata:any;
  @Input() riskName:any;
  constructor() {}

  ngOnInit(): void {
  }
  
}
