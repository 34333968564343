import { Component, OnInit, AfterViewInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import tippy from 'tippy.js'
import * as map from 'lodash/map'

@Component({
  selector: 'app-results-table-risk',
  templateUrl: './results-table-risk.component.html',
  styleUrls: ['./results-table-risk.component.scss'],
  host: {
    "(window:click)": "onClick()"
  }
})
export class ResultsTableRiskComponent implements AfterViewInit {

  @Input() riskType;
  @Input() riskMode;
  @Input() risktableHead;
  @Input() tableRiskData;
  @Input() more;
  @Input() currPage;
  @Input() hurricaneCategoryLabel;
  @Output() onNextPreviousRisk: EventEmitter<any> = new EventEmitter();
  popupData:any={};
  public riskDataPopup:boolean = false;
  public months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  constructor(private elem: ElementRef) { }

  ngOnInit(): void {
    this.assignVisibilityOfPopUpData();
  }

  assignVisibilityOfPopUpData() {
    if (this.tableRiskData.length > 0) {
      for ( let i=0;i<this.tableRiskData.length;i++ ) {
        let visibilityArr = [];
        for ( let rd=0;rd<this.tableRiskData[i].riskData.length;rd++ ) {
          visibilityArr.push(0);
        }
        this.tableRiskData[i].visibility = visibilityArr;
      }
    }
  }

  ngAfterViewInit(): void {
    const app = this

    let elements = this.elem.nativeElement.querySelectorAll('.has-tooltip');
    map(elements, item => {
      tippy(item, {
          allowHTML: true,
          trigger: 'click',
          arrow: true,
          animation: 'shift-away',
          interactive: true,
          content(reference) {
            console.log(reference);
            const id = reference.getAttribute('data-template');
            console.log(id);
            const template = document.getElementById(id);
            return template.innerHTML;
          },
      })
    })
  }

  next() {
    if (this.more == 1) {
      this.onNextPreviousRisk.emit('next'); 
    }
  }

  previous() {
    if ( this.currPage > 1 ) {
      this.onNextPreviousRisk.emit('prev'); 
    }
  }

  // showPopupDetails(index, idx) {
  //   let months = [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec"
  //   ];
  //   let evtDetLen = this.tableRiskData[index].eventdetail.length;
    
  //   if ( evtDetLen > 0) {
  //     for (let i = 0; i < evtDetLen; i++) {
  //       if ( this.riskType == 'hurricane' ) {
          
  //       } else if ( this.riskType == 'tornado' ) {
  //           if ( this.risktableHead[idx] == this.tableRiskData[index].eventdetail[i].category ) {
  //             this.popupData.mag = 'Magnitude  (EF-'+this.tableRiskData[index].eventdetail[i]['Magnitude (EF-Scale)']+') occurred on '+ ( this.tableRiskData[index].eventdetail[i].monthname != undefined ) ? this.tableRiskData[index].eventdetail[i].monthname : months[this.tableRiskData[index].eventdetail[i].Month-1]+this.tableRiskData[index].eventdetail[i].Day+', '+this.tableRiskData[index].eventdetail[i].Year;
  //             this.popupData.estimPropLoss = this.tableRiskData[index].eventdetail[i]['Estimated Property Loss (Mn $)'];
  //             this.popupData.estimCropLoss = this.tableRiskData[index].eventdetail[i]['Estimated Crop Loss (Mn $)'];
  //             this.popupData.length = this.tableRiskData[index].eventdetail[i]['Length (km)'];
  //             this.popupData.width = this.tableRiskData[index].eventdetail[i]['Width (km)'];
  //             this.popupData.injuries = this.tableRiskData[index].eventdetail[i]['Injuries Reported'];
  //             this.popupData.fatalities = this.tableRiskData[index].eventdetail[i]['Fatalities Reported'];
  //           }
  //           console.log(this.popupData);
  //       } else if ( this.riskType == 'earthquake' ) {

  //       }
  //     }
  //   } else {
      
  //   }
  // }

  riskData(event){
    event.stopPropagation();
    this.riskDataPopup = !this.riskDataPopup;
  }
  onClick() {
    this.riskDataPopup = false;
  }

  showThisPopUp(i,idx) {
    // console.log('Trying to be shown!');
    // data.show = true;
    this.tableRiskData[i].visibility[idx] = 1;
    // for ( let i=0;i<this.tableRiskData.length;i++ ) {
    //   let visibilityArr = [];
    //   for ( let rd=0;rd<this.tableRiskData[i].riskData.length;rd++ ) {
    //     visibilityArr.push(0);
    //   }
    //   this.tableRiskData[i].visibility = visibilityArr;
    // }
  }

  onClickedOutside(i,idx) {
    this.tableRiskData[i].visibility[idx] = 0;
  }

}
