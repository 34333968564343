import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-weather-type-small',
  templateUrl: './weather-type-small.component.html',
  styleUrls: ['./weather-type-small.component.scss']
})
export class WeatherTypeSmallComponent implements OnInit {
    @Input() type: string = 'rain';
    constructor() { }

    ngOnInit(): void {
    }

}
