<div [ngClass]="(isAuthenticated && termcond)? 'w-full flex flex-wrap lg:flex-no-wrap' : ''">
    <app-sidebar [termcond]="termcond" *ngIf="isAuthenticated && !signpage && setpassword" [ngClass] ="(isAuthenticated && termcond ) ? 'app-sidebar lg:min-h-screen lg:rounded-tr-xl bg-primary w-full lg:w-auto flex-grow-0 block':''"></app-sidebar>
    <div [ngClass]="(isAuthenticated && termcond) ? 'w-auto flex-grow relative overflow-hidden' : ''">
        <div class="h-full" [@triggerName]="prepareRoute(outlet)">
          <router-outlet #outlet="outlet" (activate)="onActivate($event)"></router-outlet>
        </div>
    </div>
</div>
<div *ngIf="showLoader" class="loaderwrraper">
  <div class="login-loader">
    <span class="loaderImg"></span> 
  </div>
</div>
