import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'txtMonthToNum'
})
export class TxtMonthToNumPipe implements PipeTransform {
  datedata:any;
  constructor(){
    this.datedata = {"Jan":"01","Feb":"02","Mar":"03","Apr":"04","May":"05","Jun":"06","Jul":"07","Aug":"08","Sep":"09","Oct":"10","Nov":"11","Dec":"12"}
  }
  transform(value: any): any {
    try{
      return  value?.split(',')[0]?.split(' ')[0]+'/'+ this.datedata[value?.split(',')[0]?.split(' ')[1]]+'/'+ value.split(',')[1].trim();
      
    } 
    catch(err) {
      return value;
    }
  }

}
