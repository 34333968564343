import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-knowledge-base',
  templateUrl: './knowledge-base.component.html',
  styleUrls: ['./knowledge-base.component.scss']
})

export class KnowledgeBaseComponent implements OnInit {
  titleField : string = 'Knowledge base';

  constructor() { }

  ngOnInit(): void {
  }

}
