import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../services/user.service';
@Pipe({ name: 'UnitConvert' })
export class UnitConvertPipe implements PipeTransform {
    constructor(private userv: UserService){

    }
    convert(value: any, weatherVarType:any, unit: string, page?: string, weatherHeading?: any, moderate?:any, severe?:any, orderItem?:any ): any {
        if ( page == undefined) {
            if(unit!==undefined){
                if(unit=='F') //converting default C to F
                    value = (+value * 1.8 + 32);
                else if(unit == 'inches' && weatherHeading==1)//converting default mm to inches
                    value = (+value * 0.039);  
                else if(unit == 'inches' && weatherHeading==4)//converting default cm to inches                    
                    value = +value * 0.397;
                   // value = (+value * 0.393);                  
                else if(unit=='miles/h')    //converting default mps to mph
                    value = (+value * 2.24)     
                // else if(unit=='m/s')    //converting default mph to mps 
                //     value = (+value / 2.24)     
            }            
            return value;
        } else if ( page == 'report' ) {
            let realval;
            this.userv.curSevnadMod.subscribe(val=>{
                // if(val){
                //     value = val;
                // }
                realval = val;
                console.log(value)
                console.log(val)
            })
            // console.log('value = '+value);
            // console.log('weatherVarType ='+weatherVarType);
            // console.log('unit ='+unit);
            // console.log('page ='+page);
            // console.log('weather id ='+ weatherHeading);
            // console.log('moderate ='+moderate);
            // console.log('severe ='+severe);
            // console.log('Order item = '+orderItem);
            let dataLen = value.length;
            if (unit == 'mm') {
                realval.inches =[];
                for (let i = 0; i < dataLen; i++) {
                    let min:any = 0;
                    let inches:any = value[i];
                    realval.inches.push(value[i]);                    
                    min = parseFloat(inches) * 25.4;
                    if ( inches <= 0.2 ) {
                        min = parseInt(min);
                    } else {
                        let n:any = min/5;
                        min = parseInt(n)*5;
                    }
                    
                    if ( weatherVarType == 'daily' || weatherVarType == 'hourly') {
                        value[i] = min;    
                    } else if ( weatherVarType == 'weekly' ) {
                        value[i] = this.getLowerMin(Math.round(value[i] * 25.4),5);
                    } else if ( weatherVarType == 'monthly' ) {
                        value[i] = this.getLowerMin(Math.round(value[i] * 25.4),10);
                    }
                }
                if(realval.mm.length){
                    value = realval.mm;
                }
                this.checkIfThresholdsAreSame(value, unit, weatherVarType, weatherHeading, orderItem);
            } else if (unit == 'inches' && weatherHeading == 1 ) {
                realval.mm=[];
                for (let i = 0; i < dataLen; i++) {
                    let min:any = 0;
                    let mm:any = value[i];
                    realval.mm.push(value[i]); 
                    min = parseFloat(mm) / 25.4;
                    if ( mm <= 5 ) {
                        let n:any = min/0.1;
                        min = parseInt(n)*0.1
                    } else {
                        let nm:any = min/0.2;
                        min = parseInt(nm)*0.2;
                    }
                    // if ( !Number.isInteger(min) ) {
                    //     min = min.toFixed(1);
                    // }
                    //value[i] = min;
                    
                    if ( weatherVarType == 'daily' || weatherVarType == 'hourly') {
                        value[i] = min;    
                    } else if ( weatherVarType == 'weekly' ) {
                        value[i] = Math.round( value[i] * 0.039 );
                    } else if ( weatherVarType == 'monthly' ) {
                        value[i] = this.makeANumberDivisibleByTwo( Math.round(value[i] * 0.039) );
                    }
                }
                this.checkIfThresholdsAreSame(value, unit, weatherVarType, weatherHeading, orderItem);
                value[0] = value[0].toFixed(1);
                value[1] = value[1].toFixed(1);
                if(realval.inches.length){
                    value = realval.inches;
                }
            } else if (unit == 'm/s') {
                realval.milesh=[];
                for (let i = 0; i < dataLen; i++) {
                    realval.milesh.push(value[i]); 
                    if ( moderate > severe ) {
                        if ( weatherVarType == 'speed' || weatherVarType == 'hourly_speed') {
                            value[i] = Math.ceil( (value[i] * 0.447)/0.25 ) * 0.25;
                        } else {
                            value[i] = Math.ceil( (value[i] * 0.447)/0.25 ) * 0.25;   
                        }
                    } else {
                        if ( weatherVarType == 'speed' || weatherVarType == 'hourly_speed') {
                            value[i] = Math.floor( (value[i] * 0.447)/0.25 ) * 0.25;
                        } else {
                            value[i] = Math.floor( (value[i] * 0.447)/0.25 ) * 0.25;
                        }
                        
                    }
                    
                }
                this.checkIfThresholdsAreSame(value, unit, weatherVarType, weatherHeading, orderItem);
                value[0] = value[0].toFixed(2);
                value[1] = value[1].toFixed(2);
                if(realval.ms.length){
                    value = realval.ms;
                }
            } else if (unit == 'miles/h') {
                realval.ms=[];
                for (let i = 0; i < dataLen; i++) {
                    realval.ms.push(value[i]);
                    value[i] = Math.round(value[i] * 2.24);
                }
                this.checkIfThresholdsAreSame(value, unit, weatherVarType, weatherHeading, orderItem);
                if(realval.milesh.length){
                    value = realval.milesh;
                }
            } else if (unit == 'c' || unit == 'C' ) {
                realval.f=[];
                for (let i = 0; i < dataLen; i++) {
                    realval.f.push(value[i]);
                    value[i] = Math.round((value[i] - 32) * 5 / 9);
                }
                this.checkIfThresholdsAreSame(value, unit, weatherVarType, weatherHeading, orderItem);
                if(realval.c.length){
                    value = realval.c;
                }
            } else if (unit == 'f' || unit == 'F' ) {
                realval.c=[];
                for (let i = 0; i < dataLen; i++) {
                    realval.c.push(value[i]);
                    value[i] = this.makeANumberDivisibleByTwo( Math.round((value[i] * 1.8) + 32) );
                }
                this.checkIfThresholdsAreSame(value, unit, weatherVarType, weatherHeading, orderItem);
                if(realval.f.length){
                    value = realval.f;
                }
            } else if (unit == 'cm') {
                realval.inches=[];
                for (let i = 0; i < dataLen; i++) {
                    realval.inches.push(value[i]);
                    // value[i] = this.getLowerMin(Math.round(value[i] * 2.54),5);
                    // value.data.k1.data[i].unit = 'cm';
                    let cmValue = value[i]*2.54;
                    if ( cmValue < 10 ) {
                        if ( moderate > severe ) {
                            value[i] = 0.5*Math.ceil(cmValue/0.5)
                        } else {
                            value[i] = 0.5*Math.floor(cmValue/0.5)
                        }
                    } else {
                        if ( moderate > severe ) {
                            value[i] = 5*Math.ceil(cmValue/5)
                        } else {
                            value[i] = 5*Math.floor(cmValue/5)
                        }
                    }
                }
                this.checkIfThresholdsAreSame(value, unit, weatherVarType, weatherHeading, orderItem);
                if(realval.cm.length){
                    value = realval.cm;
                }
            } else if (unit == 'inches' && weatherHeading == 4) {
                realval.cm=[];
                for (let i = 0; i < dataLen; i++) {
                    realval.cm.push(value[i]);
                    //value[i] = Math.round( (value[i] / 2.54) * 2) / 2;
                    let a = value[i];
                    let b = a/2.54;
                    if (a <= 10) {
                        if ( moderate > severe ) {
                            b = 0.2*Math.ceil(b/0.2);   
                        } else {
                            b = 0.2*Math.floor(b/0.2);
                        }
                    } else {
                        if ( moderate > severe ) {
                            b = 2*Math.ceil(b/2);
                        } else {
                            b = 2*Math.floor(b/2);
                        }
                    }
                    value[i] = ( b > 0 ) ? b.toFixed(1) : 0;
                }
                this.checkIfThresholdsAreSame(value, unit, weatherVarType, weatherHeading, orderItem);
                if(realval.inches.length){
                    value = realval.inches;
                }
            }
            this.userv.setcurSevnadMod(realval);
        }
        
        return value;
    }
    
    transform(value: any, weatherVarType:any, unit: string, page?: string, weatherHeading?: any, moderate?:any, severe?:any, orderItem?:any  ): any {
        if ( page == undefined) {
            if(unit!==undefined){
                if(unit=='F') //converting default C to F
                    value = (+value * 1.8 + 32);
                else if(unit == 'inches' && weatherHeading==1)//converting default mm to inches
                    value = (+value * 0.039);  
                else if(unit == 'inches' && weatherHeading==4)//converting default cm to inches                    
                    value = +value * 0.397;
                   // value = (+value * 0.393);                  
                else if(unit=='miles/h')    //converting default mps to mph
                    value = (+value * 2.24)     
                // else if(unit=='m/s')    //converting default mph to mps 
                //     value = (+value / 2.24)  
            } else {
                if(weatherVarType=='F') //converting default C to F
                    value = (+value * 1.8 + 32);
                else if(weatherVarType == 'inches' && weatherHeading==1)//converting default mm to inches
                    value = (+value * 0.039);  
                else if(weatherVarType == 'inches' && weatherHeading==4)//converting default cm to inches                    
                    value = +value * 0.397;
                   // value = (+value * 0.393);                  
                else if(weatherVarType=='miles/h')    //converting default mps to mph
                    value = (+value * 2.24)
            }
            return value;
        } else if ( page == 'report' ) {
            let dataLen = value.length;
            if (unit == 'mm') {
                for (let i = 0; i < dataLen; i++) {
                    let min:any = 0;
                    let inches:any = value[i];
                    min = parseFloat(inches) * 25.4;

                    if ( inches <= 0.2 ) {
                        min = parseInt(min);
                    } else {
                        let n:any = min/5;
                        min = parseInt(n)*5;
                    }
                    
                    if ( weatherVarType == 'daily' || weatherVarType == 'hourly') {
                        value[i] = min;    
                    } else if ( weatherVarType == 'weekly' ) {
                        value[i] = this.getLowerMin(Math.round(value[i] * 25.4),5);
                    } else if ( weatherVarType == 'monthly' ) {
                        value[i] = this.getLowerMin(Math.round(value[i] * 25.4),10);
                    }
                }
                this.checkIfThresholdsAreSame(value, unit, weatherVarType, weatherHeading, orderItem);
            } else if (unit == 'inches' && weatherHeading == 1 ) {
                for (let i = 0; i < dataLen; i++) {
                    let min:any = 0;
                    let mm:any = value[i];
                    min = parseFloat(mm) / 25.4;
                    if ( mm <= 5 ) {
                        let n:any = min/0.1;
                        min = parseInt(n)*0.1
                    } else {
                        let nm:any = min/0.2;
                        min = parseInt(nm)*0.2;
                    }
                    if ( !Number.isInteger(min) ) {
                        min = min.toFixed(1);
                    }
                    value[i] = min;
                    if ( weatherVarType == 'daily' || weatherVarType == 'hourly') {
                        value[i] = min;    
                    } else if ( weatherVarType == 'weekly' ) {
                        value[i] = Math.round( value[i] * 0.039 );
                    } else if ( weatherVarType == 'monthly' ) {
                        value[i] = this.makeANumberDivisibleByTwo( Math.round(value[i] * 0.039) );
                    }
                }
                value = this.checkIfThresholdsAreSame(value, unit, weatherVarType, weatherHeading, orderItem);
            } else if (unit == 'm/s') {
                for (let i = 0; i < dataLen; i++) {
                    if ( moderate > severe ) {
                        if ( weatherVarType == 'speed' || weatherVarType == 'hourly_speed') {
                            value[i] = Math.ceil( (value[i] * 0.447)/0.25 ) * 0.25;
                        } else {
                            value[i] = Math.ceil( (value[i] * 0.447)/0.25 ) * 0.25;   
                        }
                    } else {
                        if ( weatherVarType == 'speed' || weatherVarType == 'hourly_speed') {
                            value[i] = Math.floor( (value[i] * 0.447)/0.25 ) * 0.25;
                        } else {
                            value[i] = Math.floor( (value[i] * 0.447)/0.25 ) * 0.25;
                        }
                        
                    }
                    
                }
                this.checkIfThresholdsAreSame(value, unit, weatherVarType, weatherHeading, orderItem);
            } else if (unit == 'miles/h') {
                for (let i = 0; i < dataLen; i++) {
                    value[i] = Math.round(value[i] * 2.24);
                }
                this.checkIfThresholdsAreSame(value, unit, weatherVarType, weatherHeading, orderItem);
            } else if (unit == 'c' || unit == 'C' ) {
                for (let i = 0; i < dataLen; i++) {
                    value[i] = Math.round((value[i] - 32) * 5 / 9);
                }
                this.checkIfThresholdsAreSame(value, unit, weatherVarType, weatherHeading, orderItem);
            } else if (unit == 'f' || unit == 'F' ) {
                for (let i = 0; i < dataLen; i++) {
                    value[i] = this.makeANumberDivisibleByTwo( Math.round((value[i] * 1.8) + 32) );
                }
                this.checkIfThresholdsAreSame(value, unit, weatherVarType, weatherHeading, orderItem);
            } else if (unit == 'cm') {
                for (let i = 0; i < dataLen; i++) {
                    value[i] = this.getLowerMin(Math.round(value[i] * 2.54),5);
                    // value.data.k1.data[i].unit = 'cm';
                }
                this.checkIfThresholdsAreSame(value, unit, weatherVarType, weatherHeading, orderItem);
            } else if (unit == 'inches' && weatherHeading == 4) {
                for (let i = 0; i < dataLen; i++) {
                    value[i] = Math.round( (value[i] / 2.54) * 2) / 2;
                }
                this.checkIfThresholdsAreSame(value, unit, weatherVarType, weatherHeading, orderItem);
            }
        }
        return value;
        // if ( page == undefined) {
        //     //Python API gives values in mm, if inches is required, changed using pipe
        //     if(unit!==undefined){
        //         if(unit=='F') //converting default C to F
        //             value = (+value * 1.8 + 32);
        //         else if(unit == 'inches' && weatherHeading==1)//converting default mm to inches
        //             value = (+value * 0.039);  
        //         else if(unit == 'inches' && weatherHeading==4)//converting default cm to inches                    
        //             value = +value * 0.397;
        //            // value = (+value * 0.393);                  
        //         else if(unit=='miles/h')    //converting default mps to mph
        //             value = (+value * 2.24)     
        //         // else if(unit=='m/s')    //converting default mph to mps 
        //         //     value = (+value / 2.24)     
        //     }            
        //     return value;
        // } else if ( page == 'insight-detail' ) {
        //     // if is on insight model detail page
        //     if (value.data.head.length == 3) {
        //         if (unit == 'mm') {
        //             for (let i = 0; i < dataLen; i++) {
        //                 let min:any = 0;
        //                 let inches:any = value.data.k1.data[i].min;
        //                 min = parseFloat(inches) * 25.4;

        //                 if ( inches <= 0.2 ) {
        //                     min = parseInt(min);
        //                 } else {
        //                     let n:any = min/5;
        //                     min = parseInt(n)*5;
        //                 }
                        
        //                 value.data.k1.data[i].min = min;
        //                 //value.data.k1.data[i].min = this.getLowerMin(Math.round(value.data.k1.data[i].min * 25.4),5);
        //                 value.data.k2.data[i].min = this.getLowerMin(Math.round(value.data.k2.data[i].min * 25.4),5);
        //                 value.data.k3.data[i].min = this.getLowerMin(Math.round(value.data.k3.data[i].min * 25.4),10);
        //                 value.data.k1.data[i].unit = 'mm';
        //                 value.data.k2.data[i].unit = 'mm';
        //                 value.data.k3.data[i].unit = 'mm';
        //             }
        //             this.checkIfThresholdsAreNotSame(value, unit, weatherHeading);
        //         } else if (unit == 'in') {
        //             for (let i = 0; i < dataLen; i++) {

        //                 let min:any = 0;
        //                 let mm:any = value.data.k1.data[i].min;
        //                 min = parseFloat(mm) / 25.4;
                        
        //                 if ( mm <= 5 ) {
        //                     let n:any = min/0.1;
        //                     min = parseInt(n)*0.1
        //                 } else {
        //                     let nm:any = min/0.2;
        //                     min = parseInt(nm)*0.2;
        //                 }
        //                 if ( !Number.isInteger(min) ) {
        //                     min = min.toFixed(1);
        //                 }
        //                 value.data.k1.data[i].min = min;
        //                 //value.data.k1.data[i].min = Math.round( (value.data.k1.data[i].min * 0.039) * 2) / 2;
        //                 value.data.k2.data[i].min = Math.round( value.data.k2.data[i].min * 0.039 );
        //                 value.data.k3.data[i].min = this.makeANumberDivisibleByTwo( Math.round(value.data.k3.data[i].min * 0.039) );
        //                 value.data.k1.data[i].unit = 'inches';
        //                 value.data.k2.data[i].unit = 'inches';
        //                 value.data.k3.data[i].unit = 'inches';
        //             }
        //             this.checkIfThresholdsAreNotSame(value, unit, weatherHeading);
        //         } else if (unit == 'ms') {
        //             for (let i = 0; i < dataLen; i++) {
        //                 if ( moderate > severe ) {
        //                     value.data.k1.data[i].min = Math.ceil( (value.data.k1.data[i].min * 0.447)/0.25 ) * 0.25;                            
        //                     value.data.k2.data[i].min = Math.ceil( (value.data.k2.data[i].min * 0.447)/0.25 ) * 0.25;
        //                     value.data.k3.data[i].min = Math.ceil( (value.data.k3.data[i].min * 0.447)/0.25 ) * 0.25;
        //                 } else {
        //                     value.data.k1.data[i].min = Math.floor( (value.data.k1.data[i].min * 0.447)/0.25 ) * 0.25;                            
        //                     value.data.k2.data[i].min = Math.floor( (value.data.k2.data[i].min * 0.447)/0.25 ) * 0.25;
        //                     value.data.k3.data[i].min = Math.floor( (value.data.k3.data[i].min * 0.447)/0.25 ) * 0.25;
        //                 }
        //                 value.data.k1.data[i].unit = 'm/s';
        //                 value.data.k2.data[i].unit = 'm/s';
        //                 value.data.k3.data[i].unit = 'm/s';
        //             }
        //             this.checkIfThresholdsAreNotSame(value, unit, weatherHeading);
        //         } else if (unit == 'mh') {
        //             for (let i = 0; i < dataLen; i++) {
        //                 value.data.k1.data[i].min = Math.round(value.data.k1.data[i].min * 2.24);
        //                 value.data.k2.data[i].min = Math.round(value.data.k2.data[i].min * 2.24);
        //                 value.data.k3.data[i].min = Math.round(value.data.k3.data[i].min * 2.24);
        //                 value.data.k1.data[i].unit = 'miles/h';
        //                 value.data.k2.data[i].unit = 'miles/h';
        //                 value.data.k3.data[i].unit = 'miles/h';
        //             }
        //             this.checkIfThresholdsAreNotSame(value, unit, weatherHeading);
        //         } else if (unit == 'c' || unit == 'C' ) {
        //             for (let i = 0; i < dataLen; i++) {
        //                 value.data.k1.data[i].min = Math.round((value.data.k1.data[i].min - 32) * 5 / 9);
        //                 value.data.k2.data[i].min = Math.round((value.data.k2.data[i].min - 32) * 5 / 9);
        //                 value.data.k3.data[i].min = Math.round((value.data.k3.data[i].min - 32) * 5 / 9);
        //                 value.data.k1.data[i].unit = 'C';
        //                 value.data.k2.data[i].unit = 'C';
        //                 value.data.k3.data[i].unit = 'C';
        //             }
        //             this.checkIfThresholdsAreNotSame(value, unit, weatherHeading);
        //         } else {
        //             for (let i = 0; i < dataLen; i++) {
        //                 value.data.k1.data[i].min = this.makeANumberDivisibleByTwo( Math.round((value.data.k1.data[i].min * 1.8) + 32) );
        //                 value.data.k2.data[i].min = this.makeANumberDivisibleByTwo( Math.round((value.data.k2.data[i].min * 1.8) + 32) );
        //                 value.data.k3.data[i].min = this.makeANumberDivisibleByTwo( Math.round((value.data.k3.data[i].min * 1.8) + 32) );
        //                 value.data.k1.data[i].unit = 'F';
        //                 value.data.k2.data[i].unit = 'F';
        //                 value.data.k3.data[i].unit = 'F';
        //             }
        //             this.checkIfThresholdsAreNotSame(value, unit, weatherHeading);
        //         }
        //     } else if (value.data.head.length == 2) {
        //         if (unit == 'mm') {
        //             for (let i = 0; i < dataLen; i++) {
        //                 value.data.k1.data[i].min = this.getLowerMin(Math.round(value.data.k1.data[i].min * 25.4),5);
        //                 value.data.k2.data[i].min = this.getLowerMin(Math.round(value.data.k2.data[i].min * 25.4),5);
        //                 value.data.k1.data[i].unit = 'mm';
        //                 value.data.k2.data[i].unit = 'mm';
        //             }
        //             this.checkIfThresholdsAreNotSame(value, unit, weatherHeading);
        //         } else if (unit == 'in') {
        //             for (let i = 0; i < dataLen; i++) {
        //                 value.data.k1.data[i].min = Math.round( (value.data.k1.data[i].min * 0.039) * 2) / 2;
        //                 value.data.k2.data[i].min = Math.round( value.data.k2.data[i].min * 0.039 );
        //                 value.data.k1.data[i].unit = 'inches';
        //                 value.data.k2.data[i].unit = 'inches';
        //             }
        //             this.checkIfThresholdsAreNotSame(value, unit, weatherHeading);
        //         } else if (unit == 'ms') {
        //             for (let i = 0; i < dataLen; i++) {
        //                 if ( moderate > severe ) {
        //                     value.data.k1.data[i].min = Math.ceil( (value.data.k1.data[i].min * 0.447)/0.25 ) * 0.25;
        //                     value.data.k2.data[i].min = Math.ceil( (value.data.k2.data[i].min * 0.447)/0.25 ) * 0.25;
        //                 } else {
        //                     value.data.k1.data[i].min = Math.floor( (value.data.k1.data[i].min * 0.447)/0.25 ) * 0.25;
        //                     value.data.k2.data[i].min = Math.floor( (value.data.k2.data[i].min * 0.447)/0.25 ) * 0.25;
        //                 }
        //                 value.data.k1.data[i].unit = 'm/s';
        //                 value.data.k2.data[i].unit = 'm/s';
        //             }
        //             this.checkIfThresholdsAreNotSame(value, unit, weatherHeading);
        //         } else if (unit == 'mh') {
        //             for (let i = 0; i < dataLen; i++) {
        //                 value.data.k1.data[i].min = Math.round(value.data.k1.data[i].min * 2.24);
        //                 value.data.k2.data[i].min = Math.round(value.data.k2.data[i].min * 2.24);
        //                 value.data.k1.data[i].unit = 'miles/h';
        //                 value.data.k2.data[i].unit = 'miles/h';
        //             }
        //             this.checkIfThresholdsAreNotSame(value, unit, weatherHeading);
        //         } else if (unit == 'c' || unit == 'C' ) {
        //             for (let i = 0; i < dataLen; i++) {
        //                 value.data.k1.data[i].min = Math.round((value.data.k1.data[i].min - 32) * 5 / 9);
        //                 value.data.k2.data[i].min = Math.round((value.data.k2.data[i].min - 32) * 5 / 9);
        //                 value.data.k1.data[i].unit = 'C';
        //                 value.data.k2.data[i].unit = 'C';
        //             }
        //             this.checkIfThresholdsAreNotSame(value, unit, weatherHeading);
        //         } else {
        //             for (let i = 0; i < dataLen; i++) {
        //                 value.data.k1.data[i].min = this.makeANumberDivisibleByTwo( Math.round((value.data.k1.data[i].min * 1.8) + 32) );
        //                 value.data.k2.data[i].min = this.makeANumberDivisibleByTwo( Math.round((value.data.k2.data[i].min * 1.8) + 32) );
        //                 value.data.k1.data[i].unit = 'F';
        //                 value.data.k2.data[i].unit = 'F';
        //             }
        //             this.checkIfThresholdsAreNotSame(value, unit, weatherHeading);
        //         }
        //     } else {
        //         if (unit == 'cm') {
        //             for (let i = 0; i < dataLen; i++) {
        //                 value.data.k1.data[i].min = this.getLowerMin(Math.round(value.data.k1.data[i].min * 2.54),5);
        //                 value.data.k1.data[i].unit = 'cm';
        //             }
        //             this.checkIfThresholdsAreNotSame(value, unit, weatherHeading);
        //         } else {
        //             for (let i = 0; i < dataLen; i++) {
        //                 value.data.k1.data[i].min = Math.round( (value.data.k1.data[i].min / 2.54) * 2) / 2;
        //                 value.data.k1.data[i].unit = 'inches';
        //             }
        //             this.checkIfThresholdsAreNotSame(value, unit, weatherHeading);
        //         }
        //     }
        // }
    }

    getLowerMin(minVal, divisor) {
        let remainder;
        if ( minVal > 0 ) {
            if ( minVal % divisor != 0 ) {
                remainder = ( minVal % divisor );
                if ( divisor == 5 ) {
                    if ( remainder < 3 ) {
                        minVal = minVal - remainder;
                    } else {
                        minVal = minVal + ( 5-remainder );
                    }
                } else if ( divisor == 10 ) {
                    if ( remainder < 6 ) {
                        minVal = minVal - remainder;
                    } else {
                        minVal = minVal + ( 10-remainder );
                    }
                } else if ( divisor == 20 ) {
                    if ( remainder < 11 ) {
                        minVal = minVal - remainder;
                    } else {
                        minVal = minVal + ( 20-remainder );
                    }
                }
            }
        }
        return minVal;
    }

    makeANumberDivisibleByTwo(n) {
        if ( n > 0 ) {
            let remainder = n % 2;
            if ( remainder > 0 ) {
                n = n - remainder;
            }
        }
        return n;
    }

    checkIfThresholdsAreSame(threshData, unit, weatherVarType, weatherHeading, orderItem) {
        if ( threshData[0] == threshData[1] ) {
            if ( unit == 'mm' ) {
                if ( weatherVarType == 'daily' || weatherVarType == 'hourly') {
                    threshData[1] = threshData[1]+5;
                } else if ( weatherVarType == 'weekly' ) {
                    threshData[1] = threshData[1]+5;
                } else {
                    threshData[1] = threshData[1]+10;
                }
            } else if ( unit == 'inches' && weatherHeading == 1 ) {
                if ( weatherVarType == 'daily' || weatherVarType == 'hourly') {
                    threshData[1] = parseFloat(threshData[1]);
                    if ( threshData[1] < 0.2 ) {
                        let n:any = threshData[1]+0.1;
                        threshData[1] = n;
                    } else {
                        let nm:any = threshData[1]+0.2;
                        threshData[1] = nm;
                    }
                } else if ( weatherVarType == 'weekly' ) {
                    threshData[1] = threshData[1]+1;
                } else {
                    if ( orderItem == 'below' ) {
                        threshData[0] = threshData[0]+2;
                    } else {
                        threshData[1] = threshData[1]+2;
                    }
                }
            } else if ( unit == 'm/s' || unit == 'miles/h' ) {
                if ( weatherVarType == 'speed' || weatherVarType == 'hourly_speed') {
                    threshData[1]++;
                } else {
                    threshData[1]++;
                }
            } else if ( unit == 'c' || unit == 'C'  ) {
                // For temp
                threshData[1] = threshData[1]+1;
            } else if (  unit == 'cm' && weatherHeading == 4 ) {
                threshData[1] = threshData[1]+5;
            } else if ( unit == 'inches' && weatherHeading == 4 ) {
                threshData[1] = threshData[1]+0.2;
            } else {
                threshData[1] = threshData[1]+2;
            }
        }
        return threshData;
    }

    checkIfThresholdsAreNotSame(threshData, unit, weatherHeading) {
        let headCount = threshData.data.head.length;
        if ( headCount == 3 ) {
            if ( unit == 'mm' ) {
                if ( threshData.data.k1.data[0].min == threshData.data.k1.data[1].min ) {
                    threshData.data.k1.data[1].min = threshData.data.k1.data[1].min+5; 
                }
                if ( threshData.data.k2.data[0].min == threshData.data.k2.data[1].min ) {
                    threshData.data.k2.data[1].min = threshData.data.k2.data[1].min+5; 
                }
                if ( threshData.data.k3.data[0].min == threshData.data.k3.data[1].min ) {
                    threshData.data.k3.data[1].min = threshData.data.k3.data[1].min+10; 
                }
            } else if ( unit == 'in' ) {
                if ( threshData.data.k1.data[0].min == threshData.data.k1.data[1].min ) {
                    threshData.data.k1.data[1].min = parseFloat(threshData.data.k1.data[1].min);
                    if ( threshData.data.k1.data[1].min < 0.2  ){
                        let n:any = threshData.data.k1.data[1].min+0.1;
                        threshData.data.k1.data[1].min = n;
                    }
                    else {
                        let nm:any = threshData.data.k1.data[1].min+0.2;
                        threshData.data.k1.data[1].min = nm;
                    }
                    //threshData.data.k1.data[1].min = threshData.data.k1.data[1].min+0.5; 
                }
                if ( threshData.data.k2.data[0].min == threshData.data.k2.data[1].min ) {
                    threshData.data.k2.data[1].min = threshData.data.k2.data[1].min+1;
                }
                if ( threshData.data.k3.data[0].min == threshData.data.k3.data[1].min ) {
                    threshData.data.k3.data[1].min = threshData.data.k3.data[1].min+2;
                }
            } else if ( unit == 'ms' || unit == 'mh' ) {
                if ( threshData.data.k1.data[0].min == threshData.data.k1.data[1].min ) {
                    threshData.data.k1.data[1].min++; 
                }
                if ( threshData.data.k2.data[0].min == threshData.data.k2.data[1].min ) {
                    threshData.data.k2.data[1].min++; 
                }
            } else if ( unit == 'c' || unit == 'C'  ) {
                if ( threshData.data.k1.data[0].min == threshData.data.k1.data[1].min ) {
                    threshData.data.k1.data[1].min = threshData.data.k1.data[1].min+1;
                }
                if ( threshData.data.k2.data[0].min == threshData.data.k2.data[1].min ) {
                    threshData.data.k2.data[1].min = threshData.data.k2.data[1].min+1; 
                }
                if ( threshData.data.k3.data[0].min == threshData.data.k3.data[1].min ) {
                    threshData.data.k3.data[1].min = threshData.data.k3.data[1].min+1; 
                }
            } else {
                if ( threshData.data.k1.data[0].min == threshData.data.k1.data[1].min ) {
                    threshData.data.k1.data[1].min = threshData.data.k1.data[1].min+2;
                }
                if ( threshData.data.k2.data[0].min == threshData.data.k2.data[1].min ) {
                    threshData.data.k2.data[1].min = threshData.data.k2.data[1].min+2; 
                }
                if ( threshData.data.k3.data[0].min == threshData.data.k3.data[1].min ) {
                    threshData.data.k3.data[1].min = threshData.data.k3.data[1].min+2; 
                }
            }
        } else if ( headCount == 2 ) {
            if ( unit == 'mm' ) {
                if ( threshData.data.k1.data[0].min == threshData.data.k1.data[1].min ) {
                    threshData.data.k1.data[1].min = threshData.data.k1.data[1].min+5; 
                }
                if ( threshData.data.k2.data[0].min == threshData.data.k2.data[1].min ) {
                    threshData.data.k2.data[1].min = threshData.data.k2.data[1].min+5; 
                }
            }  else if ( unit == 'in' ) {
                if ( threshData.data.k1.data[0].min == threshData.data.k1.data[1].min ) {
                    threshData.data.k1.data[1].min = threshData.data.k1.data[1].min+0.5; 
                }
                if ( threshData.data.k2.data[0].min == threshData.data.k2.data[1].min ) {
                    threshData.data.k2.data[1].min = threshData.data.k2.data[1].min+1;
                }
            } else if ( unit == 'ms' || unit == 'mh' ) {
                if ( threshData.data.k1.data[0].min == threshData.data.k1.data[1].min ) {
                    threshData.data.k1.data[1].min++; 
                }
                if ( threshData.data.k2.data[0].min == threshData.data.k2.data[1].min ) {
                    threshData.data.k2.data[1].min++; 
                }
            } else if ( unit == 'c' || unit == 'C' ) {
                if ( threshData.data.k1.data[0].min == threshData.data.k1.data[1].min ) {
                    threshData.data.k1.data[1].min = threshData.data.k1.data[1].min+1;
                }
                if ( threshData.data.k2.data[0].min == threshData.data.k2.data[1].min ) {
                    threshData.data.k2.data[1].min = threshData.data.k2.data[1].min+1; 
                }
            } else {
                if ( threshData.data.k1.data[0].min == threshData.data.k1.data[1].min ) {
                    threshData.data.k1.data[1].min = threshData.data.k1.data[1].min+2;
                }
                if ( threshData.data.k2.data[0].min == threshData.data.k2.data[1].min ) {
                    threshData.data.k2.data[1].min = threshData.data.k2.data[1].min+2; 
                }
            }
        } else {
            if ( unit == 'cm' ) {
                if ( threshData.data.k1.data[0].min == threshData.data.k1.data[1].min ) {
                    threshData.data.k1.data[1].min = threshData.data.k1.data[1].min+5; 
                }
            } else {
                if ( threshData.data.k1.data[0].min == threshData.data.k1.data[1].min ) {
                    threshData.data.k1.data[1].min = threshData.data.k1.data[1].min+0.5; 
                }
            }
        }
    }

}