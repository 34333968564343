<div class="lg:flex-grow p-4 md:p-6 lg:p-12 relative">
    <app-backgrounds></app-backgrounds>
    <div class="w-full relative z-10">
        <div class="flex flex-wrap -mx-4 lg:-mx-6 justify-between mb-3 md:mb-5 lg:mb-8">
            <div class="px-6 w-auto lg:w-1/2">
                <h1 class="font-semibold text-xl lg:text-2xl">{{ titleField }}</h1>
            </div>
            <div class="px-6 w-auto flex flex-wrap items-center">
                <a href="https://stf.metswift.live/#/login" target="_blank" class="btn btn-block btn-gradient">Login</a>
            </div>
        </div>
        <div class="w-full mb-3 lg:mb-6">
            <div class="border border-gray-300 rounded-lg p-6 lg:p-8 mt-4">
                <div class="text-primary font-bold text-2xl mb-10">
                    Hyper-Local Precision Platform
                    <span class="text-gray-500 text-sm font-medium ml-4 pt-2">powered by </span><span class="text-base font-delos text-orange-base ml-1 pt-2">DELOS</span>
                    <p class="text-primary text-gray-600 text-base font-medium">Dramatically improving short-term weather forecasting on a hyper-local basis</p>
                </div>
                <div class="page-content text-primary w-full">
                    <p>
                        The <b>Hyper-Local Precision platform</b> delivers forecasts with a far greater degree of accuracy than previously available at a hyper-local level. It uses our Delos model, powered by data from on-site weather stations, to vastly increase accuracy levels, improve the effectiveness of forecasting over time and to provide data that can be used with more confidence for operational planning and safety decision making.
                    </p>
                    <p>
                        <b>Delos</b> is the model behind our Hyper-Local Precision platform. Delos is a multi-faceted engine that combines site specific Numerical Weather Prediction (NWP)* mapping, meteorological and developmental optimisation, and targeted use of AI to deliver unparalleled levels of accuracy on a site-specific basis.
                    </p>
                    <p>
                        The Delos system combines the best available Numerical Weather Predictions (NWP)* with local topography to create custom-localised predictions. The model then uses AI neural networks trained on live observation streams from onsite weather stations to further localise the forecast, delivering more reliable and accurate weather forecasts for the site. Delos also uses this information to mitigate the errors that build up over time in traditional Numerical Weather Prediction (NWP)* models, leading to a significantly slower decay in accuracy in longer range forecasts. So not only does Delos deliver better results from the start, but their effectiveness continues for longer allowing for more reliable early decision making. Once up and running Delos will continue to gather and learn from new data every day, allowing it to continually improve its performance over the life time of the model.
                    </p>
                    <p>
                        •⁠  ⁠NWP - numerical weather prediction, which uses mathematical representations of the atmosphere to model the forward evolution of weather patterns. Extremely intensive, requiring power-hungry supercomputers to produce results within hours rather than days.
                    </p>
                    <br>
                    <p class="text-primarylighter">
                        To find out more about Metswift HLP <a routerLink="/contact-us" class="font-semibold">contact us</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="w-full mb-3 lg:mb-6">
            <div class="relative w-full block rounded-lg p-12 lg:p-16 overflow-hidden">
            <!--<div class="w-full block rounded-lg p-12 lg:p-16 bg-gray-300">-->
                <img src="assets/images/construction-background.jpg" class="image-background absolute inset-0 w-full h-full object-center object-cover" />
                <div class="relative page-content w-full">
                    <div class="flex w-full h-12 justify-between text-primary font-bold text-5xl"> 
                        <p>&#8220;</p><p>&#8221;</p>
                    </div>
                    <p class="text-primary font-bold text-xl text-center tracking-wide leading-snug"> 
                        The precision of metswift's weather forecasts allows us to plan activities more efficiently, avoiding weather-related delays and disruptions. This accuracy has translated into tangible cost reductions by minimising downtime and optimising resource allocation. Our investment in Metswift is invaluable. 
                    </p>
                    <div class="w-full text-right text-white text-lg mt-4">
                    <!--<div class="w-full text-right text-gray-600 text-lg mt-4">-->
                        <p class="font-semibold leading-none">
                            David Hall, Head of Lifting
                            <br>
                            <span class="font-medium">Sir Robert McAlpine</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>