import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
// import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

// import { User } from '../../models/user';
import { AppState, selectAuthState } from '../../store/app.states';
import { LogIn, LogOut } from '../../store/actions/auth.actions';
import { LoaderService } from '../../services/loader.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})

export class ForgotComponent implements OnInit {
  public _email: String;
    public _forgotError: boolean = false;
    public _errorMsg: string;
    public _success: boolean = false;
    public _successMsg: string;
    public loading = false;
    public disableSubmitFlag: boolean = false;
    public model:any={};
    destroy$: Subject<any> = new Subject();

    constructor(public userService: UserService,private loaderService: LoaderService) { }

    ngOnInit() {}

    ngAfterViewInit() {
        function getHeight() {
            var heights = document.body.scrollHeight + 50;
        }
        getHeight();
        window.onresize = function () {
            getHeight();
        };
    }

    trimEmail(val){
        this.model.email=val.trim();
    }
    
    onSubmit(formValue: any) {
        let email = formValue.email;
        let isEmailValid:boolean = this.checkIfEmailIsValid(email);
        if ( !email || !isEmailValid  )
            return;
        this.loading = true;
        this.loaderService.display(true);
        this._email = email;
       
        this.userService.forgot({ username: this._email })
        //.pipe(takeUntil(this.destroy$))
        .subscribe(
        data => {
            let successData = data;
            if (successData.status == 1) {
                this.loading = false;
                this.loaderService.display(false);
                this._forgotError = false;
                this._success = true;
                this._successMsg = successData.message;
                this.disableSubmitFlag = true;
                this.model.email = '';
            }
        },
        error => {
            this.loading = false;
            this.loaderService.display(false);
            let errordata = error.error;
            if (errordata.status == 0) {
                this._forgotError = true;
                this._success = false;
                this._errorMsg = errordata.message;
            }
        });
       
    }

    checkIfEmailIsValid(email) {
        if ( email ) {
            if (email.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
