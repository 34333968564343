import {
  transition,
  trigger,
  query,
  style,
  animate,
  group,
  animateChild
} from '@angular/animations';

export const routeTransitionAnimations = trigger('triggerName', [
  transition('* <=> *', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%'
      })
    ], { optional: true }),
    query(':enter', [style({ top: '-25px', opacity: 0 })], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':leave', [animate('.3s cubic-bezier(.19,1,.22,1)', style({ top: '25px', opacity: 0 }))], { optional: true }),
      query(':enter', [animate('.7s cubic-bezier(.19,1,.22,1)', style({ top: '0', opacity: 1 }))], { optional: true })
    ]),
    query(':enter', animateChild(), { optional: true })
  ]),
  // transition('Three => Two, Two => One, Three => One', [
  //   style({ position: 'relative' }),
  //   query(':enter, :leave', [
  //     style({
  //       position: 'absolute',
  //       top: 0,
  //       left: 0,
  //       width: '100%'
  //     })
  //   ]),
  //   query(':enter', [style({ left: '-100%', opacity: 0 })]),
  //   query(':leave', animateChild()),
  //   group([
  //     query(':leave', [animate('1s cubic-bezier(.19,1,.22,1)', style({ left: '100%', opacity: 0 }))]),
  //     query(':enter', [animate('1s cubic-bezier(.19,1,.22,1)', style({ left: '0%', opacity: 1 }))])
  //   ]),
  //   query(':enter', animateChild())
  // ])
]);

// export const routeTransitionAnimations = trigger('triggerName', [
//  transition('One => Two, Two => Three', [
//    style({ position: 'relative' }),
//    query(':enter, :leave', [
//      style({
//        position: 'absolute',
//        top: 0,
//        right: 0,
//        width: '100%'
//      })
//    ]),
//    query(':enter', [style({ right: '-100%', opacity: 0 })]),
//    query(':leave', animateChild()),
//    group([
//      query(':leave', [animate('1s cubic-bezier(.19,1,.22,1)', style({ right: '100%', opacity: 0 }))]),
//      query(':enter', [animate('1s cubic-bezier(.19,1,.22,1)', style({ right: '0%', opacity: 1 }))])
//    ]),
//    query(':enter', animateChild())
//  ])
//  // ,
//  // transition('* <=> FilterPage', [
//  //  style({ position: 'relative' }),
//  //  query(':enter, :leave', [
//  //    style({
//  //      position: 'absolute',
//  //      top: 0,
//  //      left: 0,
//  //      width: '100%'
//  //    })
//  //  ]),
//  //  query(':enter', [style({ left: '-100%' })]),
//  //  query(':leave', animateChild()),
//  //  group([
//  //    query(':leave', [animate('200ms cubic-bezier(.19,1,.22,1)', style({ left: '100%' }))]),
//  //    query(':enter', [animate('300ms cubic-bezier(.19,1,.22,1)', style({ left: '0%' }))])
//  //  ]),
//  //  query(':enter', animateChild())
//  // ])
// ]);
