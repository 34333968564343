<!-- Statistical starts here -->
<div *ngIf="type == 'stats'" class="w-full">
    <div *ngIf="data && searchState.userSector != 3" class="block">
        <ng-container *ngIf="weathertypeid == 1 || weathertypeid == 2 || weathertypeid == 3 || weathertypeid == 4 then showNonExtremeProbTemplate else showExtremeProbTemplate;"></ng-container>
        <ng-template #showNonExtremeProbTemplate>
            <div class="flex flex-wrap items-start -mx-4">
                <div class="w-1/2 px-4 border-r border-gray-200">
                    <div class="text-center">
                        <span class="block text-sm leading-snug">Probability for</span>
                        <span class="font-semibold text-lg block leading-snug">{{moderateThresholdHead}}<span [innerHtml]="thresholdHeadUnits"></span></span>
                    </div>

                    <div class="graph-container-circle relative">
                        <svg viewBox="0 0 36 36" class="circular-chart">
                        <path class="circle-bg"
                            d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <path class="circle circle--moderate"
                        attr.stroke-dasharray="{{moderateDashArray}}, 100"
                            d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        </svg>
                        <div class="circle-result circle-result--moderate absolute">
                            <span *ngIf="prob_T1>0.1 || prob_T1==0;else othert1" class="text-white font-bold md:text-xl lg:text-2xl">{{ ( prob_T1 != 100 && prob_T1 > 99.94 ) ? '>99.90' : prob_T1}} %</span>
                            <ng-template #othert1>
                                <span class="text-white font-bold md:text-xl lg:text-2xl">< 0.10 %</span>
                            </ng-template>
                        </div>
                    </div>
                    <!-- info icon and popup content-->
                    <!--<div *ngIf="prob_T1 != 100 && prob_T1 != 0" class="w-full text-center mt-3"> 
                        <span class="relative tooltipwrp ml-1 text-primary rounded-full w-5 h-5 inline-block text-center tlLeft">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-current moderate-info" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                </svg>
                            <div class="tooltip">
                                <p>{{data.msg_T1}}</p> 
                            </div>
                        </span>
                    </div>-->
                    <div class="w-full text-center mt-3"> 
                        <span class="relative tooltipwrp ml-1 text-primary rounded-full w-5 h-5 inline-block text-center tlLeft">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-current moderate-info" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                </svg>
                            <div class="tooltip">
                                <p>{{overviewToolTipMsgPrefix}}{{overviewToolTipMsgBody}}{{thresholds.alert}}<span [innerHtml]="thresholdHeadUnits"></span>{{overviewToolTipMsgSuffix}}</p>
                            </div>
                        </span>
                    </div>
                    <!-- info icon and popup content-->

                </div>
                <div class="w-1/2 px-4">
                    <div class="text-center">
                        <span class="block text-sm leading-snug">Probability for</span>
                        <span class="font-semibold text-lg block leading-snug">{{severeThresholdHead}}<span [innerHtml]="thresholdHeadUnits"></span></span>
                    </div>
                    <div class="graph-container-circle relative">
                        <svg viewBox="0 0 36 36" class="circular-chart">
                        <path class="circle-bg"
                            d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <path class="circle circle--severe"
                            attr.stroke-dasharray="{{severeDashArray}}, 100"
                            d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        </svg>
                        <div class="circle-result circle-result--severe absolute">
                            <span *ngIf="prob_T2>0.1 || prob_T2==0;else othert1" class="text-white font-bold md:text-xl lg:text-2xl">{{ ( prob_T2 != 100 && prob_T2 > 99.94 ) ? '>99.90' : prob_T2}} %</span>
                            <ng-template #othert1>
                                <span class="text-white font-bold md:text-xl lg:text-2xl">< 0.10 %</span>
                            </ng-template>
                        </div>
                    </div>
                    <!-- info icon and popup content-->
                    <!--<div *ngIf="prob_T2 != 100 && prob_T2 != 0" class="w-full text-center mt-3">
                        <span class="relative tooltipwrp ml-1 text-primary rounded-full w-5 h-5 inline-block text-center tlRight">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-current severe-info" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                </svg>
                            <div class="tooltip">
                                <p>{{data.msg_T2}}</p> 
                            </div>
                        </span>
                    </div>-->
                    <div class="w-full text-center mt-3">
                        <span class="relative tooltipwrp ml-1 text-primary rounded-full w-5 h-5 inline-block text-center tlRight">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-current severe-info" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                            </svg>
                            <div class="tooltip">
                                <p>{{overviewToolTipMsgPrefix}}{{overviewToolTipMsgBody}}{{thresholds.severe}}<span [innerHtml]="thresholdHeadUnits"></span>{{overviewToolTipMsgSuffix}}</p> 
                            </div>
                        </span>
                    </div>
                    <!-- info icon and popup content-->

                </div>
            </div>
        </ng-template>
        <ng-template #showExtremeProbTemplate>
            <div class="">
                <div class="text-center">
                    <span class="block text-sm leading-snug">Probability for</span>
                    <span class="font-semibold text-lg block leading-snug">{{weatherName | titlecase}}</span>
                </div>

                <div class="graph-container-circle relative">
                    <!-- If probability is not 0 -->
                    <svg *ngIf="extremeWeatherProb != 0" viewBox="0 0 36 36" class="circular-chart">
                    <path class="circle-bg"
                        d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path class="circle circle--severe"
                    attr.stroke-dasharray="{{extremeWeatherDashArray}}, 100"
                        d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    </svg>
                    <div *ngIf="extremeWeatherProb != 0" class="circle-result circle-result--severe absolute">
                        <span *ngIf="extremeWeatherProb>0.1 || extremeWeatherProb==0;else othert1" class="text-white font-bold md:text-xl lg:text-2xl">{{ ( extremeWeatherProb != 100 && extremeWeatherProb > 99.94 ) ? '>99.90' : extremeWeatherProb}} %</span>
                        <ng-template #othert1>
                            <span class="text-white font-bold md:text-xl lg:text-2xl">< 0.10 %</span>
                        </ng-template>
                    </div>
                    <!-- If probability is 0 -->
                    <svg *ngIf="extremeWeatherProb == 0" viewBox="0 0 36 36" class="circular-chart green">
                        <path class="circle-bg"
                            d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <path class="circle circle--severe circle-green"
                        attr.stroke-dasharray="{{extremeWeatherDashArray}}, 100"
                            d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        </svg>
                    <div *ngIf="extremeWeatherProb == 0" class="circle-result circle-result--severe circle-result-green absolute">
                        <span *ngIf="extremeWeatherProb>0.1 || extremeWeatherProb==0;else othert1" class="text-white font-bold md:text-xl lg:text-2xl">{{ ( extremeWeatherProb != 100 && extremeWeatherProb > 99.94 ) ? '>99.90' : extremeWeatherProb}} %</span>
                        <ng-template #othert1>
                            <span class="text-white font-bold md:text-xl lg:text-2xl">< 0.10 %</span>
                        </ng-template>
                    </div>
                </div>
                <!-- info icon and popup content-->
                <!--<div *ngIf="extremeWeatherProb != 100 && extremeWeatherProb != 0" class="w-full text-center mt-3"> 
                    <span class="relative tooltipwrp ml-1 text-primary rounded-full w-5 h-5 inline-block text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-current severe-info" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                        </svg>
                        <div class="tooltip">
                            <p>ii{{data.msg_T1}}</p> 
                        </div>
                    </span>
                </div>-->
                <div class="w-full text-center mt-3"> 
                    <span class="relative tooltipwrp ml-1 text-primary rounded-full w-5 h-5 inline-block text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-current severe-info" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                        </svg>
                        <div class="tooltip">
                            <p>{{overviewToolTipMsgPrefix}}{{overviewToolTipMsgBody}}{{overviewToolTipMsgSuffix}}</p> 
                        </div>
                    </span>
                </div>
                <!-- info icon and popup content-->

            </div>
        </ng-template>

    </div>
    <div *ngIf="searchState.userSector == 3" class="block">
        <ng-container *ngIf="weathertypeid == 1 || weathertypeid == 2 || weathertypeid == 3 || weathertypeid == 4 then showNonExtremeProbConstructionTemplate else showExtremeProbConstructionTemplate;"></ng-container>
        <ng-template #showNonExtremeProbConstructionTemplate>
            <div class="flex flex-wrap items-center -mx-2">
                <div class="text-center w-full sm:w-auto sm:text-left sm:w-32 mt-4 xl:my-6 px-2">
                    <span class="block text-sm leading-snug">Downtime for</span>
                    <span class="font-semibold text-lg block leading-snug">{{moderateThresholdHead}}<span [innerHtml]="thresholdHeadUnits"></span></span>
                </div>
                <div class="flex-1 px-2">
                    <div class="flex flex-wrap justify-center">
                        <div class="text-center text-yellow-dark font-semibold">{{ daysMod }} {{varype}}</div>
                        <!-- info icon and popup content-->
                        <!--<div *ngIf="data.msg_T1" class="w-auto text-center pt-1"> 
                           <span class="relative tooltipwrp ml-1 text-primary rounded-full w-5 h-5 inline-block text-center">
                               <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-current moderate-info" viewBox="0 0 20 20" fill="currentColor">
                                   <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                 </svg>
                               <div class="tooltip">
                                   <p>{{data.msg_T1.replace(data.msg_T1.split(' ')[3],daysMod+' '+varype).replace('probability','total downtime')}}</p> 
                               </div>
                           </span>
                       </div>-->
                       <div class="w-auto text-center pt-1"> 
                        <span class="relative tooltipwrp ml-1 text-primary rounded-full w-5 h-5 inline-block text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-current moderate-info" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                              </svg>
                            <div class="tooltip">
                                <p>{{overviewToolTipMsgPrefix}}{{overviewToolTipMsgBody}}{{thresholds.alert}}<span [innerHtml]="thresholdHeadUnits"></span>{{overviewToolTipMsgSuffix}}</p> 
                            </div>
                        </span>
                    </div>
                       <!-- info icon and popup content--> 
                    </div>
                    <div class="bar-days">
                        <span class="bar-days__bg"></span>
                        <span #barmod class="bar-days__elem bar-days__elem--mod"></span>
                    </div>
                    <div class="w-full flex flex-wrap justy-between">
                        <div class="text-sm mr-auto text-gray-700">{{ dayStart }}</div>
                        <div class="text-sm ml-auto text-gray-700">{{ dayEnd }}</div>
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap items-center -mx-2">
                <div class="text-center w-full sm:w-auto sm:text-left sm:w-32 mt-4 xl:my-6 px-2">
                    <span class="block text-sm leading-snug">Downtime for</span>
                    <span class="font-semibold text-lg block leading-snug">{{severeThresholdHead}}<span [innerHtml]="thresholdHeadUnits"></span></span>
                </div>
                <div class="flex-1 px-2">
                    <div class="flex flex-wrap justify-center">
                        <div class="text-center text-red-dark font-semibold">{{ daysSev }} {{varype}}</div>
                        <!-- info icon and popup content-->
                        <!--<div *ngIf="data.msg_T2" class="w-auto text-center pt-1">
                           <span class="relative tooltipwrp ml-1 text-primary rounded-full w-5 h-5 inline-block text-center">
                               <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-current severe-info" viewBox="0 0 20 20" fill="currentColor">
                                   <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                 </svg>
                               <div class="tooltip">
                                   <p>{{data.msg_T2.replace(data.msg_T2.split(' ')[3],daysSev+' '+varype).replace('probability','total downtime')}}</p> 
                               </div>
                           </span>
                        </div>-->
                        <div class="w-auto text-center pt-1">
                            <span class="relative tooltipwrp ml-1 text-primary rounded-full w-5 h-5 inline-block text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-current severe-info" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                </svg>
                                <div class="tooltip">
                                    <p>{{overviewToolTipMsgPrefix}}{{overviewToolTipMsgBody}}{{thresholds.severe}}<span [innerHtml]="thresholdHeadUnits"></span>{{overviewToolTipMsgSuffix}}</p> 
                                </div>
                            </span>
                        </div>
                        <!-- info icon and popup content-->
                    </div>
                    
                    <div class="bar-days">
                        <span class="bar-days__bg"></span>
                        <span #barsev class="bar-days__elem bar-days__elem--sev"></span>
                    </div>
                    <div class="w-full flex flex-wrap justy-between">
                        <div class="text-sm mr-auto text-gray-700">{{ dayStart }}</div>
                        <div class="text-sm ml-auto text-gray-700">{{ dayEnd }}</div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template #showExtremeProbConstructionTemplate>
            <div class="flex flex-wrap items-center -mx-2">
                <div class="text-center w-full sm:w-auto sm:text-left sm:w-32 mt-4 xl:my-6 px-2">
                    <span class="block text-sm leading-snug">Downtime for</span>
                    <span class="font-semibold text-lg block leading-snug">{{weatherName | titlecase}}</span>
                </div>
                <div class="flex-1 px-2">
                    <div class="flex flex-wrap justify-center">
                        <div class="text-center text-yellow-dark font-semibold">{{ downtimeForExtremeWeather }} {{varype}}</div>
                        <!-- info icon and popup content-->
                        <!--<div *ngIf="data.msg_T1" class="w-auto text-center pt-1"> 
                            <span class="relative tooltipwrp ml-1 text-primary rounded-full w-5 h-5 inline-block text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-current moderate-info" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                  </svg>
                                <div class="tooltip">
                                    <p>{{data.msg_T1.replace(data.msg_T1.split(' ')[3],downtimeForExtremeWeather+' '+varype).replace('probability','total downtime')}}</p> 
                                </div>
                            </span>
                        </div>-->
                        <div class="w-auto text-center pt-1"> 
                            <span class="relative tooltipwrp ml-1 text-primary rounded-full w-5 h-5 inline-block text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 fill-current moderate-info" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                </svg>
                                <div class="tooltip">
                                    <p>{{overviewToolTipMsgPrefix}}{{overviewToolTipMsgBody}}{{overviewToolTipMsgSuffix}}</p>  
                                </div>
                            </span>
                        </div>
                        <!-- info icon and popup content--> 
                    </div>
                    <div class="bar-days">
                            <span class="bar-days__bg"></span>
                            <span #barext [ngClass]="downtimeForExtremeWeatherInNumber == 0 ? 'bar-days__elem--ext' : 'bar-days__elem--sev'" class="bar-days__elem bar-days__elem--sev"></span>
                        </div>
                    <div class="w-full flex flex-wrap justy-between">
                        <div class="text-sm mr-auto text-gray-700">{{ dayStart }}</div>
                        <div class="text-sm ml-auto text-gray-700">{{ dayEnd }}</div>
                    </div>
                </div>
            </div>
        </ng-template>

    </div>

    <div *ngIf="weathertypeid == 1 || weathertypeid == 2 || weathertypeid == 3 || weathertypeid == 4" class="flex flex-wrap -mx-2 font-semibold mt-6">
        <div class="flex w-full sm:w-auto sm:flex-1 px-2 mb-1 sm:mb-0">
            <app-badge-result class="flex w-full" [innerClass]="classes.lowest">
                <div class="font-light pr-2">
                    Lowest
                </div>
                <div class="text-right leading-none">
                    <span *ngIf="lowest !='NA'" class="text-sm">{{ lowest | UnitConvert:unit:undefined:weathertypeid:undefined:undefined:undefined  | number: '1.2-2' }} </span>
                    <span *ngIf="lowest =='NA'" class="text-sm">{{ lowest | UnitConvert:unit  | number: '1.2-2' }} </span>
                    <span *ngIf="lowest !='NA'" class="text-sm" [innerHtml]="weathersymbol"></span><span class="text-sm">&#8202;{{unit}}</span>
                </div>
                <div class="text-primary text-center font-base font-light tracking-wide tooltip enqry lzng">
                    <p [innerHTML]="lowestLzngTooltip"></p>
                </div>
            </app-badge-result>
        </div>
        <div class="flex w-full sm:w-auto sm:flex-1 px-2 mb-1 sm:mb-0">
            <app-badge-result class="flex w-full" [innerClass]="classes.mean">
                <div class="font-light pr-2">
                    Mean
                </div>
                <div class="text-right leading-none">
                    <!-- <span class="text-sm">{{ mean }}</span><span class="text-sm">{{ unit }}</span> -->
                    <span *ngIf="mean !='NA'" class="text-sm">{{ mean | UnitConvert:unit:undefined:weathertypeid:undefined:undefined:undefined  | number: '1.2-2' }} </span> 
                    <span *ngIf="mean =='NA'" class="text-sm">{{ mean | UnitConvert:unit  | number: '1.2-2' }} </span>
                    <span *ngIf="mean !='NA'" class="text-sm" [innerHtml]="weathersymbol"></span><span class="text-sm">&#8202;{{unit}}</span>
                </div>
                <div class="text-primary text-center font-base font-light tracking-wide tooltip enqry lzng">
                    <p [innerHTML]="meanLzngTooltip"></p>
                </div>
            </app-badge-result>
        </div>
        <div class="flex w-full sm:w-auto sm:flex-1 px-2 mb-1 sm:mb-0">
            <app-badge-result class="flex w-full" [innerClass]="classes.highest">
                <div class="font-light pr-2">
                    Highest
                </div>
                <div class="text-right leading-none">
                    <!-- <span class="text-sm">{{ highest }}</span><span class="text-sm">{{ unit }}</span> -->
                    <span *ngIf="highest !='NA'" class="text-sm">{{ highest | UnitConvert:unit:undefined:weathertypeid:undefined:undefined:undefined  | number: '1.2-2' }} </span> 
                    <span *ngIf="highest =='NA'" class="text-sm">{{ highest | UnitConvert:unit  | number: '1.2-2' }} </span>
                    <span *ngIf="highest !='NA'" class="text-sm" [innerHtml]="weathersymbol"></span><span class="text-sm">&#8202;{{unit}}</span>
                </div>
                <div class="text-primary text-center font-base font-light tracking-wide tooltip enqry lzng">
                    <p [innerHTML]="highestLzngTooltip"></p>
                </div>
            </app-badge-result>
        </div>
    </div>
</div>
<!-- Statistical ends here -->

<!-- Prediction starts here -->
<div *ngIf="type == 'predict'" class="w-full">
    <div class="bg-white p-6 border border-gray-300 rounded-lg mb-4">
        <div class="relative width-64">
            <ngx-slick-carousel class="carousel" 
                                #slickModal="slick-carousel" 
                                [config]="slideConfig" 
                                (init)="slickInit($event)"
                                (breakpoint)="breakpoint($event)"
                                (afterChange)="afterChange($event)"
                                (beforeChange)="beforeChange($event)">
                <div ngxSlickItem *ngFor="let Probability of overviewPredData.data" class="slide">
                    <div class="w-full mx-auto px-4 text-center">
                        <div class="text-center">
                            <span class="block text-sm leading-snug">{{predDataFreq}} {{Probability.periodName+'-'}} {{Probability.year}}</span>
                            <span class="font-semibold text-lg block leading-snug">{{Probability.predictionName}}</span>
                        </div>
                        <div [ngClass]="colorLogic(Probability.predictionValue)" class="graph-container-circle graph-container-circle--wildfire relative">
                            <div class="circle-result circle-ai-height">
                            <span class="text-white font-bold text-2xl">{{Probability.predictionValue}} <span [innerHTML]="weathersymbol"></span>{{unit}}</span>
                        </div>
                        </div>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</div>
<!-- Prediction ends here -->
