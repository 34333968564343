import { Component, OnInit, Injectable, ViewChild, ElementRef, HostListener } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState, selectAuthState } from '../../../store/app.states';
import { LogOut } from '../../../store/actions/auth.actions';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LoaderService } from 'src/app/services/loader.service';
import { MenuSidebarComponent } from '../menu-sidebar/menu-sidebar.component';
import { LogoutService } from 'src/app/services/logout.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
@Injectable()

export class SidebarComponent implements OnInit {
  getState: Observable<any>;
  errorMessage: string | null;
  avatar: string | null;
  name: string | null;
  accountMenuOpened: boolean = false;
  @ViewChild('accountArea') accountArea: ElementRef;
  @ViewChild('accountButtonLg') accountButtonLg: ElementRef;
  @ViewChild('accountButtonSm') accountButtonSm: ElementRef;
  @ViewChild('menuButton') menuButton: ElementRef;
  @ViewChild('toggleMenu') toggleMenu: MenuSidebarComponent;
  @HostListener('document:mousedown', ['$event'])

  onGlobalClick(event) {
    if (this.accountMenuOpened) {
      if (!this.accountArea.nativeElement.contains(event.target) && !this.accountButtonLg.nativeElement.contains(event.target) && !this.accountButtonSm.nativeElement.contains(event.target)) {
        const duration = this.menuButton.nativeElement.contains(event.target) ? 145 : 0;
        setTimeout(() => {
            this.accountMenuOpened = false;
        }, duration)
      }
    }
  }
  closeProfile() {
    this.accountMenuOpened = false
  }
  public isImage: boolean = false;
  public showprofileLoader = false;
  public image: any;
  public userdetails: any;
  menuOpened: boolean = false;
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private userService: UserService,
    private sanitizer: DomSanitizer,
    private loader: LoaderService,
    private LS:LogoutService,
  ) {
    this.getState = this.store.select(selectAuthState);
    this.getProfileImage()
  }

  ngOnInit() {
    this.getState.subscribe((state) => {
      this.userdetails = state.user;
      if (!state.user) {
        return
      }
      let fullname = ''
      if (state.user.firstname) {
        fullname += state.user.firstname
      }
      if (state.user.lastname) {
        fullname += ' ' + state.user.lastname
      }
      this.name = fullname
      this.errorMessage = state.errorMessage;
    });
    this.avatar = '';

  }
  onClickAccount() {
    this.accountMenuOpened = !this.accountMenuOpened;
    this.userService.isImage.subscribe(data => {
      if (data) {
        this.image = data;
        this.isImage = true;
      } else {
        this.isImage = false;
      }
      if (this.toggleMenu && this.menuOpened) {
        this.toggleMenu.toggleView();
      }
    })
  }
  onClickMenuButton(event) {
    event.preventDefault()
    if (this.toggleMenu) {
      this.toggleMenu.toggleView();
    }
  }
  onClickSignOut(event) {
    event.preventDefault()
    this.LS.logOut({ title: "Log out", link: "#/dashboard" })
              .subscribe(
                data => {
                },
                error => {
                }
              );
    this.store.dispatch(new LogOut());
    this.router.navigateByUrl('/log-in');
  }
  getProfileImage() {
    //this.showprofileLoader = true;
    this.userService.getProfileImage({})
      .subscribe(
        data => {
          let successData = data;
          if (successData.isImage == '0') {
            this.isImage = false;
            //this.showprofileLoader = false;
          } else {
            this.image = this.sanitizer.bypassSecurityTrustResourceUrl(successData.image);
            this.isImage = true;
            this.userService.imagepath(this.image);
            //this.showprofileLoader = false;
          }
        },
        error => {
          let errordata = error;
        });
  }

  onToggledMenue(event) {
    this.menuOpened = event
  }
}
