import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-how-to-use-widget',
  templateUrl: './how-to-use-widget.component.html',
  styleUrls: ['./how-to-use-widget.component.scss']
})
export class HowToUseWidgetComponent implements OnInit {
    constructor(
        public ngxSmartModalService: NgxSmartModalService
    ) { }

    ngOnInit(): void {
    }

    public videoPlay() {
      setTimeout(() => {
        console.log('video play here')
        // this.searchReportsInput.nativeElement.focus()
      }, 300)
    }
}
