import { Component, OnChanges, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import * as map from 'lodash/map';
import { UnitConvertPipe } from './../../../../pipes/unit-convert.pipe';
import { ThresholdService } from './../../../../services/threshold.service';
import { EnquiriesService } from './../../../../services/enquiries.service';
import { LoaderService } from './../../../../services/loader.service';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-thresholds-item',
  templateUrl: './thresholds-item.component.html',
  styleUrls: ['./thresholds-item.component.scss']
})
export class ThresholdsItemComponent implements AfterViewInit, OnChanges {
    @Input() type: string = 'rain';
    @Input() thresholdsData: any;
    @Input() unitSelected: string = 'mm';
    ischangedunit:boolean=false;
    isChanged: boolean = false;
    isUnitChanged: boolean = false;
    units: any=[];
    order: string = 'ASC';
    orderItems: any = [
        {
            'slug': 'below',
            'name': 'Below',
            'active': 0,
        },
        {
            'slug': 'above',
            'name': 'Above',
            'active': 0,
        }
    ];
    counterModMinMax: number[] = [0, 250];
    counterModStep: number = 5;
    counterMod: number = 0;
    counterSevMinMax: number[] = [0, 300];
    counterSevStep: number = 5;
    counterSev: number = 10;
    weatherSymbol:any;
    selectedOrderItem:string;
    lastclicked = '';
    @Input() notify: Subject<boolean>;

    
    @Output() onUpdateThresholds: EventEmitter<any> = new EventEmitter();
    @Output() onUnitConvert: EventEmitter<any> = new EventEmitter();
    @Output() onThresholdsChangeMessage: EventEmitter<any> = new EventEmitter();

    constructor(
        private threshService :ThresholdService,
        private enquiriesService: EnquiriesService,
        private loader:LoaderService,
        private unitConvertPipe: UnitConvertPipe,
        private userserv:UserService
    ) { }

    ngOnInit(){
        this.notify.subscribe(v => { 
         this.setpreviousvalue()
           console.log("I am from child");
        });
     }
    ngOnChanges(): void {
        this.userserv.setcurSevnadMod();
        // if(this.type === 'rain'){
        //     this.units = ['mm', 'inches']
        //     this.unitSelected = 'mm'
        // }
        // else if(this.type === 'wind'){
        //     this.units = ['m/h', 'km/h']
        //     this.unitSelected = 'm/h'
        // }
    }

    setpreviousvalue(): void{
        
        if ( this.lastclicked=="lessmod") {
            this.counterMod = this.threshService.increaseMinData(this.counterMod, this.thresholdsData.unit, this.thresholdsData.weatherVarType, this.thresholdsData.WeatherTypeId);
        }
        if ( this.lastclicked == 'moremod') {
            this.counterMod = this.threshService.decreaseMinData(this.counterMod, this.thresholdsData.unit, this.thresholdsData.weatherVarType, this.thresholdsData.WeatherTypeId);
        }    
        if ( this.lastclicked=="lesssev") {
            this.counterSev = this.threshService.increaseMinData(this.counterSev, this.thresholdsData.unit, this.thresholdsData.weatherVarType, this.thresholdsData.WeatherTypeId);
        }
        if ( this.lastclicked == 'moresev') {
            this.counterSev = this.threshService.decreaseMinData(this.counterSev, this.thresholdsData.unit, this.thresholdsData.weatherVarType, this.thresholdsData.WeatherTypeId);
        } 
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.showSelectedOrderItme();
            this.counterMod = this.thresholdsData.alert;
            this.counterSev = this.thresholdsData.severe;
            for ( let [key, value] of Object.entries(this.thresholdsData.unitData) ) {
                this.units.push(value['name']);
            }
            this.unitSelected = this.units[0];
            this.unitSelected = this.thresholdsData.unit;
            this.weatherSymbol = this.thresholdsData.symbol;
        }, 1000);
       
    }

    onClickLessMod(){
        let updatedValue = this.threshService.decreaseMinData(this.counterMod, this.thresholdsData.unit, this.thresholdsData.weatherVarType, this.thresholdsData.WeatherTypeId);
        if ( this.selectedOrderItem == 'below' && updatedValue == this.counterSev ) {
            let msg = 'Moderate threshold value should be higher than the severe threshold.';
            this.counterMod = updatedValue;
            this.onThresholdsChangeMessage.emit(msg);
            this.lastclicked = 'lessmod';
        } else {
            this.userserv.setcurSevnadMod();
            this.counterMod = updatedValue;
            this.showUpdateButton();
        }
    }

    onClickMoreMod(){
        let updatedValue = this.threshService.increaseMinData(this.counterMod, this.thresholdsData.unit, this.thresholdsData.weatherVarType, this.thresholdsData.WeatherTypeId);
        if ( this.selectedOrderItem == 'above' && updatedValue == this.counterSev ) {
            let msg = 'Moderate threshold value should be less than the severe threshold.';
            this.counterMod = updatedValue;
            this.onThresholdsChangeMessage.emit(msg);
            this.lastclicked = 'moremod';
        } else {
            this.userserv.setcurSevnadMod();
            this.counterMod = updatedValue;
            this.showUpdateButton();
        }
    }

    onClickLessSev(){
        let updatedValue = this.threshService.decreaseMinData(this.counterSev, this.thresholdsData.unit, this.thresholdsData.weatherVarType, this.thresholdsData.WeatherTypeId);
        if ( this.selectedOrderItem == 'above' && updatedValue == this.counterMod ) {
            let msg = 'Moderate threshold value should be less than the severe threshold.';
            this.counterSev = updatedValue;
            this.onThresholdsChangeMessage.emit(msg);
            this.lastclicked = 'lesssev';

        } else {
            this.userserv.setcurSevnadMod();
            this.counterSev = updatedValue;
            this.showUpdateButton();
        }
    }

    onClickMoreSev(){
        let updatedValue = this.threshService.increaseMinData(this.counterSev, this.thresholdsData.unit, this.thresholdsData.weatherVarType,this.thresholdsData.WeatherTypeId);
        if ( this.selectedOrderItem == 'below' && updatedValue == this.counterMod ) {
            let msg = 'Moderate threshold value should be higher than the severe threshold.';
            this.counterSev = updatedValue;
            this.onThresholdsChangeMessage.emit(msg);
            this.lastclicked = 'moresev';
        } else {
            this.userserv.setcurSevnadMod();
            this.counterSev = updatedValue;
            this.showUpdateButton();
        }
    }

    onOrderClick(name) {
        this.userserv.setcurSevnadMod();
        map(this.orderItems, item => {
            item.active = item.slug === name
        })
        let moderate = this.counterMod;
        this.counterMod = this.counterSev;
        this.counterSev = moderate;
        this.selectedOrderItem = name;
        this.showUpdateButton();
    //   if(this.orderItems[0].active){
    //     this.order = 'ASC'
    //   } else {
    //     this.order = 'DESC'
    //   }
    }
    
    onUnitsClick(){
        this.isUnitChanged = true;
        this.ischangedunit = true;
        this.isChanged = false;
        const newUnits = [...this.units]
        newUnits.splice(newUnits.indexOf(this.unitSelected), 1)
        this.thresholdsData.unit = this.unitSelected = newUnits[0];
        let thresholdsArr = [this.counterMod, this.counterSev];
        let moderate = this.counterMod;
        let severe   = this.counterSev;
        let value:any = this.unitConvertPipe.convert(thresholdsArr, this.thresholdsData.weatherVarType, this.unitSelected, 'report', this.thresholdsData.WeatherTypeId, moderate, severe, this.selectedOrderItem);
        this.counterMod = Number(value[0]);
        this.counterSev = Number(value[1]);
        // LOGIC TO CHANGE MIN/MAX AND STEP BASED ON UNITS SELECTED
    }

    showUpdateButton() {
        this.isChanged = true;
        this.isUnitChanged = false;
    }

    updateUnitButtonClicked() {
        this.updateButtonClicked();
    }

    updateButtonClicked() {
        if(this.counterMod == this.counterSev){
            //alert('same')
            this.onUpdateThresholds.emit('sameval');
            return true;
        }
        let vartypedata = [];
        delete this.thresholdsData.allthresold[this.thresholdsData.weatherVarTypeId];
        for ( let [key, value] of Object.entries(this.thresholdsData.allthresold) ) {
            if(this.ischangedunit){
                let thresholdsArr = [value['moderate'], value['severe']];
                let moderate = value['moderate'];
                let severe   = value['severe'];
                let value1:any = this.unitConvertPipe.convert(thresholdsArr, value['type'], this.unitSelected, 'report', this.thresholdsData.WeatherTypeId, moderate, severe);
                vartypedata[key] = {'moderate':value1[0],'severe':value1[1]};
            }
        } 
        // update values
        this.loader.display(true);
        let body = JSON.stringify({
            "username": this.thresholdsData.username,
            "eventId": this.thresholdsData.eventId,
            "weatherType": this.thresholdsData.WeatherTypeId,
            "unit": this.unitSelected,
            "weatherVarType": this.thresholdsData.weatherVarTypeId,
            "lowerMin": this.counterMod,
            "lowerMax": this.counterSev,
            "orderItem": this.selectedOrderItem,
            "T1": this.counterMod,
            "T2": this.counterSev,
            "otherThreshold": vartypedata
        });
        
        this.enquiriesService.updateThresholds(body).
        subscribe(
            (data)=> {
                this.ischangedunit=false;
                this.userserv.setcurSevnadMod();
                this.loader.display(false);
                let successData = JSON.parse(JSON.stringify(data));
                successData.T1 = this.counterMod;
                successData.T2 = this.counterSev; 
                successData.selectedOrderItem = this.selectedOrderItem;
                this.onUpdateThresholds.emit(successData);
                      },
            (error)=> {
                    this.loader.display(false);
                    }          
                  );
        setTimeout(() => {
            this.isChanged = false;
        }, 300)
    }

    showSelectedOrderItme() {
        this.selectedOrderItem = this.thresholdsData.orderItem;
        for ( let i=0;i<2;i++ ) {
            if ( this.orderItems[i].slug == this.selectedOrderItem ) {
                this.orderItems[i].active = 1;
                break;
            }
        }
    }
}
