    <div class="lg:flex-grow p-4 md:p-6 lg:p-12 relative">
        <app-backgrounds></app-backgrounds>
        <div class="w-full relative z-10">
            <div class="flex flex-wrap -mx-4 lg:-mx-6 justify-between mb-3 md:mb-5 lg:mb-8">
                <div class="px-4 lg:px-6 w-auto lg:w-1/2">
                    <h1 class="font-semibold text-xl lg:text-2xl">{{ title }}</h1>
                </div>
                <div class="px-4 lg:px-6 w-auto flex flex-wrap items-center">
                    <app-search-reports-button></app-search-reports-button>
                </div>
            </div>
            <!-- welcome message -->
            <div class="w-full mb-3 lg:mb-6">
                <div class="rounded-lg p-6 lg:p-8 mt-4 bg-welcome">
                    <div class="flex items-center text-white font-bold text-2xl mb-2">
                        Welcome to Metswift ALR
                        <span class="text-gray-400 text-sm font-medium ml-4 pt-2">powered by </span>
                        <span class="text-lg font-claros text-secondarydarker ml-1 pt-2">Claros</span>
                    </div>
                    <div class="page-content text-white tracking-wide w-full max-w-5xl">
                        <p>
                            Metswift's Advanced Long-Range Platform, powered by our proprietary predictive model, Claros, provides unique global insights into future weather conditions and risk levels according to your parameters. To find out more about Metswift ALR contact us <a routerLink="/contact-us" class="font-semibold">here</a>.
                        </p>
                    </div>
                </div>

            </div>
            <!-- what insight -->
            <div class="w-full mb-3 lg:mb-6" *ngIf="unlimited != 1">
                <app-what-is-insight></app-what-is-insight>
            </div>

            <div class="flex flex-wrap -mx-4 lg:-mx-6">
                <app-newreport-widget showSearchButton='true' class="w-full mb-4 lg:mb-6 px-4 lg:px-6"></app-newreport-widget>
            </div>
            <div class="flex flex-wrap xl:-mx-6">
                <app-my-portfolio-widget class="xl:px-6 w-full xl:w-1/2 xl:order-2 mb-6 flex items-stretch"></app-my-portfolio-widget>
                <div class="xl:px-6 w-full xl:w-1/2 xl:order-1">
                    <div class="flex flex-wrap -mx-3">
                        <app-latest-news-widget class="w-full mb-6 px-3"></app-latest-news-widget>
                        <app-how-to-use-widget class="w-full sm:w-1/2 mb-6 px-3"></app-how-to-use-widget>
                        <app-data-sources-widget class="w-full sm:w-1/2 mb-6 px-3"></app-data-sources-widget>
                    </div>
                </div>
            </div>


        </div>
    </div>


