<div class="border border-gray-300 rounded-lg p-4 w-full bg-transparent bg-white table-risk-data">

    <ng-container *ngIf="riskType!='wildfire'">
        <table class="w-full flex flex-row flex-no-wrap relative">
            <thead class="header-table bg-primary text-white">
                <tr *ngFor="let tableData of tableRiskData;" class="flex flex-col flex-no-wrap border-b lg:border-b-0 border-gray-700 lg:table-row mb-0 ng-star-inserted">
                    <th *ngIf="riskType == 'earthquake' || riskType == 'volcano'" class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2 lg:py-2 sm:h-8"></th>
                    <th *ngIf="riskType == 'tornado' || riskType == 'hurricane'" class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2 lg:py-2">Week</th>
                    <ng-container *ngIf="riskType == 'hurricane'">
                        <th *ngFor="let tableHead of hurricaneCategoryLabel.slice(1);" class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2 lg:py-2">{{tableHead}}</th>
                    </ng-container>
                    <ng-container *ngIf="riskType != 'hurricane'">
                        <th *ngFor="let tableHead of risktableHead;" class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2 lg:py-2">{{tableHead}}</th>
                    </ng-container>
                    <!-- <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Best Estimate</th>
                    <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Lower Limit</th>
                    <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Upper Limit</th>
                    <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Range</th> -->
                </tr>
            </thead>
            <tbody class="flex-1 lg:flex-none bg-white border-gray-300 border-t-0 lg:border rounded rounded-t-none">
                <tr *ngFor="let tableData of tableRiskData;let i =index"
                    class="flex flex-col flex-no-wrap border-b border-gray-300 lg:table-row lg:mb-0">
                    <td class="text-xs xl:text-sm lg:border-r lg:border-l border-gray-300 font-semibold px-3 py-2 lg:py-2">
                        {{tableData.riskTitle}}
                    </td>
                    <!-- <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2 max-w-xxs truncate">
                            <span class="text-xs ml-2 text-gray-700">1</span></td>
                        <td class="text-xs xl:text-sm border-r border-yellow-light px-3 py-2 font-semibold">3</td>
                        <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2 font-semibold">2</td> -->
                    <td *ngFor="let data of tableData.riskData;let idx =index"
                        class="text-xs xl:text-sm lg:border-r border-gray-300 px-3 py-2 lg:py-2 font-semibold cursor-pointer">{{ ( riskMode == 'Count' ) ? data : (
                        data < 0.005 && data !=0 ) ? '<0.01' : data.toFixed(2)}} <span *ngIf="data>'0'"
                            (click)="showThisPopUp(i,idx)" (clickOutside)="onClickedOutside(i,idx)"
                            class="bg-primary text-white rounded-full w-4 h-4 inline-flex items-center justify-center ml-3 relative"
                            style="margin-top: -1px">
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 16 16"
                                class="relative fill-current">
                                <path d="M9,7c0-0.6-0.4-1-1-1H5v2h2v6H5v2h6v-2H9V7z"></path>
                                <circle cx="7.5" cy="2.5" r="1.5"></circle>
                            </svg>

                            <div *ngIf="tableData.visibility[idx] == 1 && riskType == 'tornado'"
                                class="data-tool-tip absolute z-10" id="content-tooltip">
                                <div class="data-scroll">
                                    <ng-container *ngFor="let eData of tableData.eventdetail;let ed=index;">
                                        <div class="divide-data" *ngIf="risktableHead[idx] == eData['category']">
                                            <div class="text-orange-base font-semibold text-2base mb-2">Magnitude
                                             
                                                (EF-{{eData['Magnitude (EF-Scale)']}}) occurred on {{
                                                (eData['monthname']!=undefined) ? eData['monthname'] :
                                                months[eData['Month']-1]+" "+eData['Day']+", "+eData['Year']}}.</div>
                                            <div class="mb-1">
                                                <span class="text-orange-base">Estimated Property Loss (Mn $):</span>
                                                {{eData['Estimated Property Loss (Mn $)']}}
                                            </div>
                                            <div class="mb-1">
                                                <span class="text-orange-base">Estimated Crop Loss (Mn $):</span>
                                                {{eData['Estimated Crop Loss (Mn $)']}}
                                            </div>
                                            <div class="mb-1">
                                                <span class="text-orange-base">Length (km):</span> {{eData['Length
                                                (km)']}}
                                            </div>
                                            <div class="mb-1">
                                                <span class="text-orange-base">Width (km):</span> {{eData['Width
                                                (km)']}}
                                            </div>
                                            <div class="mb-1">
                                                <span class="text-orange-base">Injuries Reported:</span>
                                                {{eData['Injuries Reported']}}
                                            </div>
                                            <div class="mb-1">
                                                <span class="text-orange-base">Fatalities Reported:</span>
                                                {{eData['Fatalities Reported']}}
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div *ngIf="tableData.visibility[idx] == 1 && riskType == 'earthquake'"
                                class="data-tool-tip absolute z-10" id="content-tooltip">
                                <div class="data-scroll">
                                    <ng-container *ngFor="let eData of tableData.eventdetail;let ed=index;">
                                        <div class="divide-data" *ngIf="risktableHead[idx] == eData['category']">
                                            <div class="text-orange-base font-semibold text-2base mb-2">Earthquake with
                                                Moment Magnitude {{eData['MEAN MAG']}} occurred on
                                                {{eData.Month+" "+eData.DAY+", "+eData.YEAR}}.</div>
                                            <div class="mb-1">
                                                <span class="text-orange-base">Focal depth:</span>
                                                {{eData['FOCAL_DEPTH']}} km
                                            </div>
                                            <div class="mb-1">
                                                <span class="text-orange-base">Location Name:</span>
                                                {{eData['LOCATION_NAME']}}
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div *ngIf="tableData.visibility[idx] == 1 && riskType == 'hurricane'"
                                class="data-tool-tip absolute z-10" id="content-tooltip">
                                <div class="data-scroll">
                                    <ng-container *ngFor="let eData of tableData.eventdetail;let ed=index;">
                                        <div class="divide-data" *ngIf="risktableHead[idx] == eData['category']">
                                            <div class="text-orange-base font-semibold text-2base mb-2">{{eData['Storm
                                                Name']}}({{hurricaneCategoryLabel[idx+1]}}) occurred
                                                on
                                                {{ eData.monthname ? eData.monthname : months[eData.Month-1]+" "+eData.Day+", "+eData.Year}}.</div>
                                            <div class="mb-1">
                                                <span class="text-orange-base">Source Region:</span> {{eData['Source
                                                Region']}}
                                            </div>
                                            <div class="mb-1">
                                                <span class="text-orange-base">Max Winds At Time (kt):</span>
                                                {{eData['Max Winds At Time (kt)']}}
                                            </div>
                                            <div class="mb-1">
                                                <span class="text-orange-base">Peak Storm Wind (kt):</span>
                                                {{eData['Peak Storm Wind (kt)']}}
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            </span>
                    </td>
                    <!-- <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2">35</td> -->
                </tr>
                <!-- <tr *ngFor="let i of [].constructor(3)" class="flex flex-col flex-no-wrap border-b border-gray-300 lg:table-row mb-2 lg:mb-0">
                        <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2">
                            2021
                        </td>
                        <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2 max-w-xxs truncate">
                            16 <span class="text-xs ml-2 text-gray-700"> (Apr 19 to Apr 25)</span></td>
                        <td class="text-xs xl:text-sm border-r border-yellow-light px-3 py-2 font-semibold text-yellow-dark">13.10</td>
                        <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2 font-semibold text-green-dark">9.80</td>
                        <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2 font-semibold text-yellow-dark">16.20</td>
                        <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2">6.40</td>
                    </tr>
                    <tr *ngFor="let i of [].constructor(4)" class="expanded-header flex flex-col flex-no-wrap border-b border-gray-300 lg:table-row mb-2 lg:mb-0">
                        <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2">
                            2021
                        </td>
                        <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2 max-w-xxs truncate">
                            7 <span class="text-xs ml-2 text-gray-700">(Feb 15 to Feb 21)</span></td>
                        <td class="text-xs xl:text-sm border-r border-yellow-light px-3 py-2 font-semibold text-yellow-dark">14.28</td>
                        <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2 font-semibold text-green-dark">9.78</td>
                        <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2 font-semibold text-yellow-dark">19.46</td>
                        <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2">9.68</td>
                    </tr> -->
            </tbody>

            <tfoot class="footer-table bg-primary text-white">
                <tr class="hidden lg:table-row lg:mb-0">
                    <th *ngIf="riskType == 'earthquake' || riskType == 'volcano'" class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2"></th>
                    <th *ngIf="riskType == 'tornado' || riskType == 'hurricane'" class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Week</th>
                    <ng-container *ngIf="riskType == 'hurricane'">
                        <th *ngFor="let tableHead of hurricaneCategoryLabel.slice(1);" class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">{{tableHead}}</th>
                    </ng-container>
                    <ng-container *ngIf="riskType != 'hurricane'">
                        <th *ngFor="let tableHead of risktableHead;" class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">{{tableHead}}</th>
                    </ng-container>
                    <!-- <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Year</th>
                    <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Week</th>
                    <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Best Estimate</th>
                    <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Lower Limit</th>
                    <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Upper Limit</th>
                    <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Range</th> -->
                </tr>
            </tfoot>
        </table>
        <div class="flex justify-center w-full mt-6">
            <ul class="pager flex items-center justify-center">

                <li (click)="previous()" class="activeprevious mr-3">
                    <a
                        class="border cursor-pointer border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700">
                        <svg class="w-3 h-3 text-gray-600 fill-current stroke-current"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <g stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                                <polyline fill="none" stroke-miterlimit="10" points="16,1 8,12 16,23 "></polyline>
                            </g>
                        </svg>
                    </a>
                </li>
                <li (click)="next()" class="activenext ml-3">
                    <a
                        class="border cursor-pointer border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700">
                        <svg xmlns="http://www.w3.org/2000/svg"
                            class="w-3 h-3 text-gray-600 fill-current stroke-current" viewBox="0 0 24 24">
                            <g stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                                <polyline fill="none" stroke-miterlimit="10" points="8,1 16,12 8,23 "></polyline>
                            </g>
                        </svg>
                    </a>
                </li>
            </ul>
        </div>
    </ng-container>

    <ng-container *ngIf="riskType=='wildfire'">
        No Table view for Wildfire.
    </ng-container>

</div>