import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-what-is-insight',
  templateUrl: './what-is-insight.component.html',
  styleUrls: ['./what-is-insight.component.scss']
})
export class WhatIsInsightComponent {

  constructor(public ngxSmartModalService: NgxSmartModalService) {
    
   }


}
