import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-results-map',
  templateUrl: './results-map.component.html',
  styleUrls: ['./results-map.component.scss']
})
export class ResultsMapComponent implements OnInit {

  @Input() imgPath;
  @Input() weekNo;
  @Input() currentType;
  @Input() riskImgNames;
  @Input() showLeftScroll;
  @Input() showRightScroll;
  @Input() riskindex;
  weekText;
  @Output() onNextPrevMap: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    if ( this.weekNo ) {
      this.weekText = 'Week '+this.weekNo;
    }
  }

  prev(t) {
    this.onNextPrevMap.emit('prev');
  }

  next(t){
    this.onNextPrevMap.emit('next');
  }
}
