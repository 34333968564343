import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../../store/app.states';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-create-bulk-events',
  templateUrl: './create-bulk-events.component.html',
  styleUrls: ['./create-bulk-events.component.scss']
})
export class CreateBulkEventsComponent implements OnInit {

  title: string = 'Upload CSV – Create Bulk Events'
  savedEvent
  incorrectFileFormat = false
  destroy$: Subject <any> = new Subject();
  errorMsg:any = ''
  isError = false
  getState: Observable<any>;
  username: any;
  system_event_data = {}
  error_report_file = null
  event_data=null
  overall_insight =0
  duplicate_event_db = null
  s3_file_name = ''
  original_file=''
  processdata = []
  pagedata = false
  disable_upload=false
  num_of_event_save = 0
  htmlval="<span class='text-orange-base'>All event(s) in the uploaded CSV have already been created, they can be found in <a class='rounded-full h-12 text-sm font-semibold error-button text-black inline-block' href='#/my-portfolio'>your portfolio</a>"
  noFileChoose:boolean = false;
  unlimited:number = 1;
  constructor(private loader: LoaderService, private portfolioService: PortfolioService, private store: Store, public ngxSmartModalService: NgxSmartModalService) {
    this.getState = this.store.select(selectAuthState);
    this.getState.subscribe((state) => {
      this.username = state.user.username;
      this.unlimited = state.user.unlimited;
    });
   }

  ngOnInit(): void {
    this.fetchdataprocess()
  }

  fetchdataprocess(){
    this.pagedata = false
    let data = {'username':this.username}
    this.loader.display(true);
    this.portfolioService.getuserbulkdata(data).pipe(takeUntil(this.destroy$))
            .subscribe(
              data =>{
                this.loader.display(false);
                this.pagedata = true
                if(data.error_file){
                  this.error_report_file = data.error_file
                }
                if(data.process_data){
                  this.processdata = data.process_data
                }
                if(this.processdata.length>0){
                  if((this.processdata[0].fail_event+this.processdata[0].success_event != this.processdata[0].total_event)){
                      this.disable_upload=true
                  }else{
                    this.disable_upload=false
                  }
                }
                
              },
              err => {
                this.loader.display(false);
              }
            )
  }

  refreshpage(){
    // this.fetchdataprocess()
    window.location.reload()
  }
  downloads3file(file){
    this.loader.display(true);
    this.portfolioService.downloads3file({'filename':file}).pipe(takeUntil(this.destroy$))
    .subscribe(
      data =>{
        this.loader.display(false);
        if(data.error_file){
          let a = document.createElement('a');
          a.href = data.error_file;
          a.download = file;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      },
      err => {
        this.loader.display(false);
      }
    )
  }

  saveFileUploadEvent(event) {
    console.log(event);
    
    this.savedEvent = event;
    this.uploadEventXlsxFile()
  }


  uploadEventXlsxFile() {
    this.uploadFile(this.savedEvent);
  }

  uploadFile(e) {
    this.errorMsg = ''
    console.log(e.target.files[0].type)
    this.noFileChoose = true;
    let et =e
    if (e.target.files.length > 0) {
      if (
        e.target.files[0].type == 
        "csv" || e.target.files[0].type == 
        "text/csv" || e.target.files[0].name.endsWith(".csv")
      ) {
        this.original_file = e.target.files[0].name;
        this.loader.display(true);
        this.incorrectFileFormat = false;
        let fileReader = new FileReader();
        let app = this
        fileReader.readAsText(e.target.files[0]);  
        fileReader.onload = e => {
          let arrayBuffer: any = fileReader.result;
          var data = (<string>arrayBuffer).split(/\r\n|\n/);  
          var arr = [];
          console.log(data)
          let headersRow = this.getHeaderArray(data); 
          let csvRecord = ['Location','Latitude','Longitude','Start Date','End Date',	'Rainfall',	'Wind',	'Temperature',	'Snow Depth',	'Hail',	'Dust',	'Fog',	'Storm',	'Earthquakes',	'Tornadoes',	'Tropical Cyclones',	'Wildifres',	'Rain Units',	'Wind Units',	'Temperature Units',	'Snow Units',	'Daily Rain Moderate',	'Daily Rain Severe',	'Weekly Rain Moderate',	'Weekly Rain Severe',	'Monthly Rain Moderate',	'Monthly Rain Severe',	'Wind Speed Moderate',	'Wind Speed Severe',	'Gust Speed Moderate',	'Gust Speed Severe',	'Minimum Temperature Moderate',	'Minimum Temperature Severe',	'Mean Temperature Moderate',	'Mean Temperature Severe',	'Maximum Temperature Moderate',	'Maximum Temperature Severe',	'Snow Depth Moderate',	'Snow Depth Severe']
          console.log(headersRow); console.log(csvRecord)
          let missingColumns = []
          csvRecord.forEach(element => {
            if(!headersRow.includes(element)){
              missingColumns.push(element)
            }
          });
          // if(missingColumns.length){
          //   console.log("Missing Columns:"+missingColumns.join())
          // } else{
            let file = et.target.files[0];
            let fileName = file.name;

            let payload = {
              file,
            }

            let formData: FormData = new FormData();
            formData.append('file',file,file.name);
            formData.append('username',this.username)
            this.portfolioService.bulkEventUpload(formData).pipe(takeUntil(this.destroy$))
            .subscribe(
              data =>{
                console.log(data)
                this.loader.display(false);
                if(data.error_report=='yes'){
                  this.errorMsg = "Errors have been found in the uploaded file, please download the error report for details and reupload once the changes have been made."
                  this.isError = true
                  this.error_report_file = data.error_url
                } else{
                  this.system_event_data = data
                  this.event_data = data.event_data
                  this.overall_insight = data.overall_insight
                  this.duplicate_event_db = data.duplicate_event_db
                  this.s3_file_name = data.file_name
                  this.num_of_event_save = data.even_to_create.length
                  console.log(this.system_event_data)
                  this.ngxSmartModalService.getModal('csvConfirmation').open()

                }
              },
              err =>{
                this.isError = true
                this.errorMsg = err.error.details;
                this.loader.display(false);
              }
            )
          // }
          
          fileReader.onerror = function () {  
            app.loader.display(false)
            console.log('error is occured while reading file!');  
          };
        };
      } else {
        this.errorMsg = "Only .csv format files are accepted."
        this.isError = true
        this.loader.display(false);
        this.incorrectFileFormat = true;
      }
    }
  }

  create_events(){

    
    if(this.event_data){
      this.loader.display(true);
      this.portfolioService.bulkEventCreate({'num_event_created':this.num_of_event_save,'event_data':this.event_data,'username':this.username, 's3_file_name':this.s3_file_name,'original_file':this.original_file}).pipe(takeUntil(this.destroy$))
            .subscribe(
              data =>{
                this.loader.display(false);
                console.log(data)
                window.location.reload()
              },
              err => {
                this.loader.display(false);
                console.log(err)
              }
            )
    }
    
  }
 

  getHeaderArray(csvRecordsArr: any) {  
    let headers = (<string>csvRecordsArr[0]).split(',');  
    let headerArray = [];  
    for (let j = 0; j < headers.length; j++) {  
      headerArray.push(headers[j]);  
    }  
    return headerArray;  
  }  

  closeOverlay() {
    this.isError = false;
  }

}

