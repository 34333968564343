import { Pipe, PipeTransform } from '@angular/core';
import { cleanSession } from 'selenium-webdriver/safari';
@Pipe({ name: 'ProbConvert' })
export class ProbConvertPipe implements PipeTransform {

    transform(prob, noOfDays): any {
        noOfDays = noOfDays + 1;
        let res = 0;
        prob = parseFloat(prob);
            if ( prob != 0 ) {
                res = ( prob / 100) * noOfDays;
            }
            //res = Math.ceil(res);
        return res.toFixed(1);
    }
}
