    <div class="lg:flex-grow p-4 md:p-6 lg:p-12 relative">
        <app-backgrounds></app-backgrounds>
        <div class="w-full relative z-10">
            <div class="flex flex-wrap -mx-4 lg:-mx-6 justify-between mb-3 md:mb-5 lg:mb-8">
                <div class="px-4 lg:px-6 w-auto lg:w-1/2">
                    <h1 class="font-semibold text-xl lg:text-2xl">{{ titleField }}</h1>
                </div>
                <div class="px-4 lg:px-6 w-auto flex flex-wrap items-center">
                    <app-search-reports-button></app-search-reports-button>
                </div>
            </div>
            <div class="flex flex-wrap -mx-4 lg:-mx-6 justify-between mb-3 md:mb-5 lg:mb-8">
                <div class="page-content w-full px-4 lg:px-6 max-w-5xl">
                    <p>The data downloaded from these organisations has been qualified, cleansed and stored securely. MetSwift’s proprietary software has processed and harmonised the data to provide accurate insights. Therefore the data displayed may vary from the data made available by these organisations.</p>
                    <h4>MetSwift Services Ltd. acknowledge and thank the following institutions for providing access to their data:</h4>
                </div>
                <div class="w-full px-4 lg:px-6">
                    <div class="my-4 lg:my-8 flex flex-wrap -mx-3">
                        <div class="w-full lg:w-1/2 px-3 mb-4 flex items-stretch">
                            <a href="http://www.noaa.gov" rel="noreferrer" target="_blank" class="w-full border link-elem p-4 lg:px-6 border-gray-200 rounded-lg">
                                <div class="flex justify-between -mx-2">
                                    <div class="w-auto mr-auto px-2">
                                        <h3 class="font-semibold mb-1 text-sm md:text-base">National Oceanic and Atmospheric Administration (NOAA)</h3>
                                        <div class="font-semibold text-orange-base text-sm md:text-base">www.noaa.gov</div>
                                    </div>
                                    <div class="px-2"><div class="sm:w-12 sm:h-12 rounded-full sm:border link-icon border-gray-300 flex items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mx-auto stroke-current fill-current text-orange-base sm:text-gray-700" viewBox="0 0 24 24"><g><path d="M14,11c-0.551,0-1,0.448-1,1c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3s1.346-3,3-3h2.705 c0.36-0.752,0.847-1.425,1.438-2H5c-2.757,0-5,2.243-5,5s2.243,5,5,5h5c2.757,0,5-2.243,5-5c0-0.342-0.035-0.677-0.101-1H14z"></path> <path d="M10,13c0.551,0,1-0.448,1-1c0-1.654,1.346-3,3-3h5c1.654,0,3,1.346,3,3s-1.346,3-3,3h-2.705 c-0.36,0.752-0.847,1.425-1.438,2H19c2.757,0,5-2.243,5-5s-2.243-5-5-5h-5c-2.757,0-5,2.243-5,5c0,0.342,0.035,0.677,0.101,1H10z"></path></g></svg>
                                    </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="w-full lg:w-1/2 px-3 mb-4 flex items-stretch">
                            <a href="http://www.weather.gov" rel="noreferrer" target="_blank" class="w-full border link-elem p-4 lg:px-6 border-gray-200 rounded-lg">
                                <div class="flex justify-between -mx-2">
                                    <div class="w-auto mr-auto px-2">
                                        <h3 class="font-semibold mb-1 text-sm md:text-base">National Weather Service (NWS)</h3>
                                        <div class="font-semibold text-orange-base text-sm md:text-base">www.weather.gov</div>
                                    </div>
                                    <div class="px-2"><div class="sm:w-12 sm:h-12 rounded-full sm:border link-icon border-gray-300 flex items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mx-auto stroke-current fill-current text-orange-base sm:text-gray-700" viewBox="0 0 24 24"><g><path d="M14,11c-0.551,0-1,0.448-1,1c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3s1.346-3,3-3h2.705 c0.36-0.752,0.847-1.425,1.438-2H5c-2.757,0-5,2.243-5,5s2.243,5,5,5h5c2.757,0,5-2.243,5-5c0-0.342-0.035-0.677-0.101-1H14z"></path> <path d="M10,13c0.551,0,1-0.448,1-1c0-1.654,1.346-3,3-3h5c1.654,0,3,1.346,3,3s-1.346,3-3,3h-2.705 c-0.36,0.752-0.847,1.425-1.438,2H19c2.757,0,5-2.243,5-5s-2.243-5-5-5h-5c-2.757,0-5,2.243-5,5c0,0.342,0.035,0.677,0.101,1H10z"></path></g></svg>
                                    </div>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div class="w-full lg:w-1/2 px-3 mb-4 flex items-stretch">
                            <a href="http://www.metoffice.gov.uk" rel="noreferrer" target="_blank" class="w-full border link-elem p-4 lg:px-6 border-gray-200 rounded-lg">
                                <div class="flex justify-between -mx-2">
                                    <div class="w-auto mr-auto px-2">
                                        <h3 class="font-semibold mb-1 text-sm md:text-base">The Meteorological Office (The MetOffice)</h3>
                                        <div class="font-semibold text-orange-base text-sm md:text-base">www.metoffice.gov.uk</div>
                                    </div>
                                    <div class="px-2"><div class="sm:w-12 sm:h-12 rounded-full sm:border link-icon border-gray-300 flex items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mx-auto stroke-current fill-current text-orange-base sm:text-gray-700" viewBox="0 0 24 24"><g><path d="M14,11c-0.551,0-1,0.448-1,1c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3s1.346-3,3-3h2.705 c0.36-0.752,0.847-1.425,1.438-2H5c-2.757,0-5,2.243-5,5s2.243,5,5,5h5c2.757,0,5-2.243,5-5c0-0.342-0.035-0.677-0.101-1H14z"></path> <path d="M10,13c0.551,0,1-0.448,1-1c0-1.654,1.346-3,3-3h5c1.654,0,3,1.346,3,3s-1.346,3-3,3h-2.705 c-0.36,0.752-0.847,1.425-1.438,2H19c2.757,0,5-2.243,5-5s-2.243-5-5-5h-5c-2.757,0-5,2.243-5,5c0,0.342,0.035,0.677,0.101,1H10z"></path></g></svg>
                                    </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="w-full lg:w-1/2 px-3 mb-4 flex items-stretch">
                            <a href="http://www.metservice.com" rel="noreferrer" target="_blank" class="w-full border link-elem p-4 lg:px-6 border-gray-200 rounded-lg">
                                <div class="flex justify-between -mx-2">
                                    <div class="w-auto mr-auto px-2">
                                        <h3 class="font-semibold mb-1 text-sm md:text-base">Meteorological Service of New Zealand Limited (MetService)</h3>
                                        <div class="font-semibold text-orange-base text-sm md:text-base">www.metservice.com</div>
                                    </div>
                                    <div class="px-2"><div class="sm:w-12 sm:h-12 rounded-full sm:border link-icon border-gray-300 flex items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mx-auto stroke-current fill-current text-orange-base sm:text-gray-700" viewBox="0 0 24 24"><g><path d="M14,11c-0.551,0-1,0.448-1,1c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3s1.346-3,3-3h2.705 c0.36-0.752,0.847-1.425,1.438-2H5c-2.757,0-5,2.243-5,5s2.243,5,5,5h5c2.757,0,5-2.243,5-5c0-0.342-0.035-0.677-0.101-1H14z"></path> <path d="M10,13c0.551,0,1-0.448,1-1c0-1.654,1.346-3,3-3h5c1.654,0,3,1.346,3,3s-1.346,3-3,3h-2.705 c-0.36,0.752-0.847,1.425-1.438,2H19c2.757,0,5-2.243,5-5s-2.243-5-5-5h-5c-2.757,0-5,2.243-5,5c0,0.342,0.035,0.677,0.101,1H10z"></path></g></svg>
                                    </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="w-full lg:w-1/2 px-3 mb-4 flex items-stretch">
                            <a href="http://weather.gc.ca" rel="noreferrer" target="_blank" class="w-full border link-elem p-4 lg:px-6 border-gray-200 rounded-lg">
                                <div class="flex justify-between -mx-2">
                                    <div class="w-auto mr-auto px-2">
                                        <h3 class="font-semibold mb-1 text-sm md:text-base">Meteorological Service of Canada (MSC)</h3>
                                        <div class="font-semibold text-orange-base text-sm md:text-base">weather.gc.ca</div>
                                    </div>
                                    <div class="px-2"><div class="sm:w-12 sm:h-12 rounded-full sm:border link-icon border-gray-300 flex items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mx-auto stroke-current fill-current text-orange-base sm:text-gray-700" viewBox="0 0 24 24"><g><path d="M14,11c-0.551,0-1,0.448-1,1c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3s1.346-3,3-3h2.705 c0.36-0.752,0.847-1.425,1.438-2H5c-2.757,0-5,2.243-5,5s2.243,5,5,5h5c2.757,0,5-2.243,5-5c0-0.342-0.035-0.677-0.101-1H14z"></path> <path d="M10,13c0.551,0,1-0.448,1-1c0-1.654,1.346-3,3-3h5c1.654,0,3,1.346,3,3s-1.346,3-3,3h-2.705 c-0.36,0.752-0.847,1.425-1.438,2H19c2.757,0,5-2.243,5-5s-2.243-5-5-5h-5c-2.757,0-5,2.243-5,5c0,0.342,0.035,0.677,0.101,1H10z"></path></g></svg>
                                    </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="w-full lg:w-1/2 px-3 mb-4 flex items-stretch">
                            <a href="http://www.bom.gov.au/" rel="noreferrer" target="_blank" class="w-full border link-elem p-4 lg:px-6 border-gray-200 rounded-lg">
                                <div class="flex justify-between -mx-2">
                                    <div class="w-auto mr-auto px-2">
                                        <h3 class="font-semibold mb-1 text-sm md:text-base">The Bureau of Meteorology (BOM)</h3>
                                        <div class="font-semibold text-orange-base text-sm md:text-base">www.bom.gov.au</div>
                                    </div>
                                    <div class="px-2"><div class="sm:w-12 sm:h-12 rounded-full sm:border link-icon border-gray-300 flex items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mx-auto stroke-current fill-current text-orange-base sm:text-gray-700" viewBox="0 0 24 24"><g><path d="M14,11c-0.551,0-1,0.448-1,1c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3s1.346-3,3-3h2.705 c0.36-0.752,0.847-1.425,1.438-2H5c-2.757,0-5,2.243-5,5s2.243,5,5,5h5c2.757,0,5-2.243,5-5c0-0.342-0.035-0.677-0.101-1H14z"></path> <path d="M10,13c0.551,0,1-0.448,1-1c0-1.654,1.346-3,3-3h5c1.654,0,3,1.346,3,3s-1.346,3-3,3h-2.705 c-0.36,0.752-0.847,1.425-1.438,2H19c2.757,0,5-2.243,5-5s-2.243-5-5-5h-5c-2.757,0-5,2.243-5,5c0,0.342,0.035,0.677,0.101,1H10z"></path></g></svg>
                                    </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="w-full lg:w-1/2 px-3 mb-4 flex items-stretch">
                            <a href="http://www.usgs.gov/" rel="noreferrer" target="_blank" class="w-full border link-elem p-4 lg:px-6 border-gray-200 rounded-lg">
                                <div class="flex justify-between -mx-2">
                                    <div class="w-auto mr-auto px-2">
                                        <h3 class="font-semibold mb-1 text-sm md:text-base">United States Geological Survey (USGS)</h3>
                                        <div class="font-semibold text-orange-base text-sm md:text-base">www.usgs.gov</div>
                                    </div>
                                    <div class="px-2"><div class="sm:w-12 sm:h-12 rounded-full sm:border link-icon border-gray-300 flex items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mx-auto stroke-current fill-current text-orange-base sm:text-gray-700" viewBox="0 0 24 24"><g><path d="M14,11c-0.551,0-1,0.448-1,1c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3s1.346-3,3-3h2.705 c0.36-0.752,0.847-1.425,1.438-2H5c-2.757,0-5,2.243-5,5s2.243,5,5,5h5c2.757,0,5-2.243,5-5c0-0.342-0.035-0.677-0.101-1H14z"></path> <path d="M10,13c0.551,0,1-0.448,1-1c0-1.654,1.346-3,3-3h5c1.654,0,3,1.346,3,3s-1.346,3-3,3h-2.705 c-0.36,0.752-0.847,1.425-1.438,2H19c2.757,0,5-2.243,5-5s-2.243-5-5-5h-5c-2.757,0-5,2.243-5,5c0,0.342,0.035,0.677,0.101,1H10z"></path></g></svg>
                                    </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="w-full lg:w-1/2 px-3 mb-4 flex items-stretch">
                            <a href="http://www.met.ie/" rel="noreferrer" target="_blank" class="w-full border link-elem p-4 lg:px-6 border-gray-200 rounded-lg">
                                <div class="flex justify-between -mx-2">
                                    <div class="w-auto mr-auto px-2">
                                        <h3 class="font-semibold mb-1 text-sm md:text-base">Met Éireann</h3>
                                        <div class="font-semibold text-orange-base text-sm md:text-base">www.met.ie</div>
                                    </div>
                                    <div class="px-2"><div class="sm:w-12 sm:h-12 rounded-full sm:border link-icon border-gray-300 flex items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mx-auto stroke-current fill-current text-orange-base sm:text-gray-700" viewBox="0 0 24 24"><g><path d="M14,11c-0.551,0-1,0.448-1,1c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3s1.346-3,3-3h2.705 c0.36-0.752,0.847-1.425,1.438-2H5c-2.757,0-5,2.243-5,5s2.243,5,5,5h5c2.757,0,5-2.243,5-5c0-0.342-0.035-0.677-0.101-1H14z"></path> <path d="M10,13c0.551,0,1-0.448,1-1c0-1.654,1.346-3,3-3h5c1.654,0,3,1.346,3,3s-1.346,3-3,3h-2.705 c-0.36,0.752-0.847,1.425-1.438,2H19c2.757,0,5-2.243,5-5s-2.243-5-5-5h-5c-2.757,0-5,2.243-5,5c0,0.342,0.035,0.677,0.101,1H10z"></path></g></svg>
                                    </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="page-content w-full px-4 lg:px-6 max-w-5xl">
                    <p>All weather observations remain the property of the relevant source institution.</p>
                </div>

            </div>

        </div>
    </div>



