import { Component, OnInit, ViewChildren, QueryList, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import tippy from 'tippy.js'
import * as map from 'lodash/map';
import { DashboardService } from '../../../services/dashboard.service';
import { Router } from '@angular/router';
import { LoaderService } from '../../../services/loader.service';
import { NewEnquiryService } from '../../../services/newenquiry.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LogoutService } from 'src/app/services/logout.service';
import { selectAuthState } from 'src/app/store/app.states';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-my-portfolio-widget',
  templateUrl: './my-portfolio-widget.component.html',
  styleUrls: ['./my-portfolio-widget.component.scss']
})
export class MyPortfolioWidgetComponent implements OnInit, AfterViewInit, OnDestroy {
  getState: Observable<any>;
  eventsList:any;
  showPopup:boolean=false;
  currentIndex:number;
  portfolioDataIndex = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19];
  public infoText: any;
  public countryWiseHurricaneName: any;
  public hurricaneCategories: any;
  public hurricaneName;
  unlimited:number = 1;
  @ViewChildren('contentitem') contentitem:QueryList<ElementRef>;
  destroy$: Subject<any> = new Subject();
  constructor(
    private LS:LogoutService,
    private elem: ElementRef,
    private dashboardService:DashboardService,
    private router: Router,
    private newEnquiryService:NewEnquiryService,
    private loader:LoaderService,
    private store: Store,
    ){
      this.getState = this.store.select(selectAuthState);
      this.getState.subscribe((state) => {
        this.unlimited = state.user.unlimited;
      });
    }

  ngOnInit(): void {
    this.dashboardService.getPortfolio()
    .subscribe( 
      data=> {
        this.eventsList = data.data.results;
        for (let i = 0; i < this.eventsList.length; i++) {
          this.getRiskIcon(this.eventsList[i]).then(a => {
            this.eventsList[i].infoColor = a;
          });
        }
        this.loader.display(false)
      },
      error => {
        this.loader.display(false)
      });
  }

  onClickedOutside(event, i) {
    if ( this.currentIndex == i ) {
      this.infoText = '';
      event.show = false;
    }
  }

  onClickToggleReportInfo(i): void {
    const items = this.contentitem.toArray()
    let len = items.length;
    for ( let p=0;p<len;p++ ) {
      if ( i == p ) {
        if(items[i] && items[i].nativeElement){
          items[p].nativeElement.classList.toggle('active')
        }
      } else {
        if(items[i] && items[i].nativeElement){
          items[p].nativeElement.classList.remove('active')
        }
      }
    }
  }

  onClickReportGo(eventId): void {
    this.router.navigate(['/report/'+eventId]);
  }

  ngAfterViewInit(): void {
    const app = this
    let elements = this.elem.nativeElement.querySelectorAll('.has-tooltip');
    map(elements, item => {
      tippy(item, {
          allowHTML: true,
          trigger: 'click',
          arrow: true,
          animation: 'shift-away',
          interactive: true,
          content(reference) {
            const id = reference.getAttribute('data-template');
            const template = document.getElementById(id);
            return template.innerHTML;
          },
      })
    })
  }

  showThisItem(event:any, i) {
    if ( this.currentIndex == i && event.show == true ) {
      event.show = false;
    } else {
      this.currentIndex = i;
      event.show = true;
      this.getRiskAlert(event,i);
    }

  }

  getRiskAlert(result, i) {
    if (navigator.onLine == true) {
      this.loader.display(true);
      this.getRiskJSON(result);
      let eventId = result.eventId;
      this.infoText = "";
      let riskType = "";
      let alert = 0;
      let severe = 0;
      // this.showInfo = false;
      this.dashboardService.getRiskIconsData({ data: [{ eventID: parseInt(eventId) }] })
        .subscribe(
          data => {
            let dd = JSON.parse(JSON.stringify(data));
            let successData = dd;
            let msg = "";
            if (
              successData.temperature &&
              successData.temperature.severe &&
              successData.temperature.severe.length > 0
            ) {
              msg = "Temp has crossed Severe. ";
              severe++;
            } else if (
              successData.temperature &&
              successData.temperature.alert &&
              successData.temperature.alert.length > 0
            ) {
              msg = "Temp has crossed Moderate. ";
              alert++;
            }
            if (
              successData.wind &&
              successData.wind.severe &&
              successData.wind.severe.length > 0
            ) {
              msg += "Wind has crossed Severe. ";
              severe++;
            } else if (
              successData.wind &&
              successData.wind.alert &&
              successData.wind.alert.length > 0
            ) {
              msg += "Wind has crossed Moderate. ";
              alert++;
            }
            if (
              successData.rain &&
              successData.rain.severe &&
              successData.rain.severe.length > 0
            ) {
              msg += "Rain has crossed Severe. ";
              severe++;
            } else if (
              successData.rain &&
              successData.rain.alert &&
              successData.rain.alert.length > 0
            ) {
              msg += "Rain has crossed Moderate. ";
              alert++;
            }
            if (msg == "") msg = "All values are within the safe thresholds. ";
            this.infoText = msg;
          },
          error => {
            let errordata = error;
            this.loader.display(false);
          },
          () => {
            this.getriksiconsImage(result);
          }
        );
    } else {
      // this.connectionService.display(true);
      this.loader.display(false);
      // this.getPopUpVal(false);
    }
  }

  getriksiconsImage(result) {
    if (navigator.onLine == true) {
      this.dashboardService
        .mapRisk({
          End_Date: result.endDate,
          Start_Date: result.startDate,
          country: result.country,
          delphiRec: result.delphiRec,
          lat: result.lat,
          lon: result.lon,
          postCode: result.postCode,
          weathers: "",
          categories: this.hurricaneCategories
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            this.LS.logOut({ title: "Get risk data for event id "+parseInt(result.eventId), link: "#/dashboard" })
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                data => {
                  
                },
                error => {
                  this.router.navigate(["/login"]);
                  // this.loader.display(false);
                }
              );
            let dd = JSON.parse(JSON.stringify(data));
            let successData = dd;
            let riskData = successData.Risk;
            let riskValues = successData.riskValue; 
            let msgRisk = "";
            if (riskData.earthquake > 0) {
              if ( riskValues.earthquake ) {
                msgRisk += '<li><img src="../../../assets/images/earthquake-red.png" alt = "earthquake"><span class="risktooltip">Earthquakes</span></li>'; 
              } else {
                msgRisk += '<li><img src="../assets/images/earthquake-yellow.png" alt = "earthquake"><span class="risktooltip">Earthquakes</span></li>';
              }
            }
            if (riskData.hurricanes > 0) {
              if ( riskValues.hurricanes ) {
                msgRisk += '<li><img src="../../../assets/images/hurricane-red.png" alt = "hurricane"><span class="risktooltip">Hurricanes</span></li>';
              } else {
                msgRisk += '<li><img src="../../../assets/images/hurricane-yellow.png" alt = "hurricane"><span class="risktooltip">Hurricanes</span></li>';
              }
            }
            if (riskData.tornadoes > 0) {
              if ( riskValues.tornadoes ) {
                msgRisk += '<li><img src="../../../assets/images/tornado-red.png" alt = "tornado"><span class="risktooltip">Tornadoes</span></li>';
              } else {
                msgRisk += '<li><img src="../../../assets/images/tornado-yellow.png" alt = "tornado"><span class="risktooltip">Tornadoes</span></li>';
              }
            }
            if (riskData.volcano > 0) {
              if ( riskValues.volcano ) {
                msgRisk += '<li><img src="../../../assets/images/volcano-red.png" alt = "volcano"><span class="risktooltip">Volcanoes</span></li>';
              } else {
                msgRisk += '<li><img src="../assets/images/volcano-yellow.png" alt = "volcano"><span class="risktooltip">Volcanoes</span></li>';
              }
            }
            if (riskData.wildfire > 0) {
              if ( riskValues.wildfire ) {
                msgRisk += '<li><img src="../../../assets/images/wildfire-red.png" alt = "wildfire"><span class="risktooltip">Wildfires</span></li>';
              } else {
                msgRisk += '<li><img src="../../../assets/images/wildfire-yellow.png" alt = "wildfire"><span class="risktooltip">Wildfires</span></li>';
              }
            }

            if (msgRisk == "") {
              this.infoText += "No local risks.";
            }
            else {
              this.infoText += "<br/> Local risks for your location are <ul>" + msgRisk + "</ul>";
            }
            // console.log(this.infoText);
            // this.showInfo = true;
            this.loader.display(false);
          },
          error => {
            this.loader.display(false);
            // let errordata = error;
            // this.loader.display(false);
            // let errObj: any = {};
            // (errObj.code = error.status),
            //   (errObj.eventId = sessionStorage.getItem("eventId")),
            //   (errObj.url = "stats_data/get_map_risk_data"),
            //   (errObj.frontUrl = "/brain"),
            //   (errObj.apiBody = {
            //     End_Date: result.End_Date,
            //     Start_Date: result.Start_Date,
            //     country: result.country,
            //     delphiRec: result.delphiRec,
            //     lat: result.lat,
            //     lon: result.lon,
            //     postCode: result.postCode,
            //     weathers: ""
            //   });
            // if (error.status == 404 || error.status == 500) {
            //   this.callErrorMail(errObj);
            // }
            // this.getPopUpVal(true);
          }
        );
    } else {
      // this.connectionService.display(true);
      this.loader.display(false);
    }
  }

  async getRiskJSON(result) {
    let res = await this.newEnquiryService.getJSON();
    let strData = JSON.stringify(res);
    this.countryWiseHurricaneName = JSON.parse(strData);
    this.getHurricaneName(result);
  }

  getHurricaneName(result) {
    let countryName = result.country;
    let len = this.countryWiseHurricaneName.length;
    this.hurricaneName = "Hurricanes";
    for (let i = 0; i < len; i++) {
      if (this.countryWiseHurricaneName[i].code == countryName) {
        this.hurricaneName = this.countryWiseHurricaneName[i].title;
        this.hurricaneCategories = this.countryWiseHurricaneName[
          i
        ].category.split(",");
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  async getRiskIcon(rowData) {
    this.loader.display(true);
    this.infoText = "";
    let riskType = "";
    // this.showInfo = false;
    // let data = await this.insightService.asyncGetRiskIconsDataForEvent({
    //   data: [{ eventID: eventId }]
    // });
    // let adata = JSON.stringify(data);
    // let successData = JSON.parse(adata);
    if (rowData.temperatureSevere && rowData.temperatureSevere > 0) {
      return "severe";
    } else if (rowData.windSevere && rowData.windSevere > 0) {
      return "severe";
    } else if (rowData.rainSevere && rowData.rainSevere > 0) {
      return "severe";
    } else if (rowData.windAlert && rowData.windAlert > 0) {
      return "alert";
    } else if (rowData.temperatureAlert && rowData.temperatureAlert > 0) {
      return "alert";
    } else if (rowData.rainAlert && rowData.rainAlert > 0) {
      return "alert";
    }
    return "safe";
  }

}