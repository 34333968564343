import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest,
  HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { Router } from '@angular/router';

import { AuthService } from './auth.service';
import { LoaderService } from '../services/loader.service';
import { Store } from '@ngrx/store';
import { AppState, selectAuthState } from '../store/app.states';
import { LogOut } from '../store/actions/auth.actions';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private authService: AuthService;
  constructor(private injector: Injector,private loader:LoaderService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.authService = this.injector.get(AuthService);
    const token: string = this.authService.getToken();
    if(token){
      if(request.body instanceof FormData){
        request = request.clone({
          setHeaders: {
            'Authorization': `Bearer ${token}`
          }
        });
      }else{
        let getcostcsv = request.url.substring(request.url.lastIndexOf('/') + 1);
        if(getcostcsv=='getquotecsv'){
          request = request.clone({
            setHeaders: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            responseType:'text'
          });
        } else{
          request = request.clone({
            setHeaders: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
        }
      }
    }

    return next.handle(request);
  }
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router,private store: Store<AppState>,) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: any) => {
        if (response instanceof HttpErrorResponse && response.status === 401) {
          localStorage.removeItem('token');
          this.store.dispatch(new LogOut());
          this.router.navigateByUrl('/log-in');
        }
        return throwError(response);
      }),
    )
  }
}
