import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState, selectAuthState } from '../../store/app.states';
import { LogIn, LogOut } from '../../store/actions/auth.actions';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})

export class SigninComponent implements OnInit {
  signinformsubmit:boolean = false;
  getState: Observable<any>;
  errorMessage: string | null;
  public show:boolean = false;
  constructor(
    private loder: LoaderService,
    public fb: FormBuilder,
    private store: Store<AppState>
  ) {
    loder.display(false);
    this.getState = this.store.select(selectAuthState);
  }
  
  signinForm = this.fb.group({
    "username": ['',[Validators.required,Validators.pattern("[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])")]],
    "password": ['',Validators.required]
  })
  ngOnInit() {
    this.getState.subscribe((state) => {
      this.errorMessage = state.errorMessage;
      this.loder.display(false);
    });
  }

  loginUser(): void {
    this.signinformsubmit = true;
    console.log(this.signinForm.valid)
    if(this.signinForm.valid){
      this.store.dispatch(new LogOut());
      const payload = this.signinForm.value;
      this.loder.display(true);
      localStorage.removeItem('sector');
      this.store.dispatch(new LogIn(payload));
    }else {
      this.errorMessage = null
    }
    
  }

   // Getter method to access formcontrols
   get loginForm() {
    return this.signinForm.controls;
  }

  hidePassword(){
    this.show = false;
}

showPassword(){
    this.show = true;
}

}
