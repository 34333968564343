<div class="border border-gray-300 rounded-lg p-4 w-full bg-transparent bg-white">

    <table class="w-full flex flex-row flex-no-wrap relative overflow-hidden rounded">
        <thead class="header-table bg-primary text-white">
            <tr *ngFor="let i of [].constructor(10)" class="flex flex-col flex-no-wrap lg:table-row mb-2 lg:mb-0">
                <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Year</th>
                <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Week</th>
                <th class="header-table__elem text-left text-white font-light border-r border-yellow-light px-3 py-2">Best Estimate</th>
                <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Lower Limit</th>
                <th class="header-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Upper Limit</th>
                <th class="header-table__elem text-left text-white font-light border-r border-primary px-3 py-2">Range</th>
            </tr>
        </thead>
        <tbody class="flex-1 lg:flex-none bg-white border-gray-300 border-t-0 border rounded rounded-t-none">
                <tr *ngFor="let data of predicttableData" class="flex flex-col flex-no-wrap border-b border-gray-300 lg:table-row mb-2 lg:mb-0">
                    <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2">
                        {{data.year}}
                    </td>
                    <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2 max-w-xxs truncate">
                        <span class="text-xs ml-2 text-gray-700">{{data.periodName}}</span></td>
                    <td class="text-xs xl:text-sm border-r border-yellow-light px-3 py-2 font-semibold {{data.p50Class}}">{{data.predicted}}</td>
                    <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2 font-semibold {{data.p1Class}}">{{data.lowLimit}}</td>
                    <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2 font-semibold {{data.p99Class}} relative has-tooltip cursor-pointer" data-template="content-tooltip">{{data.uppLimit}}
                        <!-- <span class="bg-primary text-white rounded-full w-4 h-4 inline-flex items-center justify-center ml-3" style="margin-top: -1px">
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 16 16" class="relative fill-current"><path d="M9,7c0-0.6-0.4-1-1-1H5v2h2v6H5v2h6v-2H9V7z"></path><circle cx="7.5" cy="2.5" r="1.5"></circle></svg>
                        </span>
                          <div style="display: none;" id="content-tooltip">
                            <div class="text-orange-base font-semibold text-xl mb-2">NOT_NAMED(Cat 1 Hu) occurred on Jun17, 1906.</div>
                            <div class="mb-1">
                                <span class="text-orange-base">Source Region:</span> Subtropical Western Atlantic
                            </div>
                            <div class="mb-1">
                                <span class="text-orange-base">Max Winds At Time (kt):</span> 75
                            </div>
                            <div class="mb-1">
                                <span class="text-orange-base">Peak Storm Wind (kt):</span> 90
                            </div>
                          </div> -->
                    </td>
                    <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2">{{data.range}}</td>
                </tr>
                <!-- <tr *ngFor="let i of [].constructor(3)" class="flex flex-col flex-no-wrap border-b border-gray-300 lg:table-row mb-2 lg:mb-0">
                    <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2">
                        2021
                    </td>
                    <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2 max-w-xxs truncate">
                        16 <span class="text-xs ml-2 text-gray-700"> (Apr 19 to Apr 25)</span></td>
                    <td class="text-xs xl:text-sm border-r border-yellow-light px-3 py-2 font-semibold text-yellow-dark">13.10</td>
                    <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2 font-semibold text-green-dark">9.80</td>
                    <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2 font-semibold text-yellow-dark">16.20</td>
                    <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2">6.40</td>
                </tr>
                <tr *ngFor="let i of [].constructor(4)" class="expanded-header flex flex-col flex-no-wrap border-b border-gray-300 lg:table-row mb-2 lg:mb-0">
                    <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2">
                        2021
                    </td>
                    <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2 max-w-xxs truncate">
                        7 <span class="text-xs ml-2 text-gray-700">(Feb 15 to Feb 21)</span></td>
                    <td class="text-xs xl:text-sm border-r border-yellow-light px-3 py-2 font-semibold text-yellow-dark">14.28</td>
                    <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2 font-semibold text-green-dark">9.78</td>
                    <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2 font-semibold text-yellow-dark">19.46</td>
                    <td class="text-xs xl:text-sm border-r border-gray-300 px-3 py-2">9.68</td>
                </tr> -->
        </tbody>

        <tfoot class="footer-table bg-primary text-white">
            <tr class="flex flex-col flex-no-wrap lg:table-row mb-2 lg:mb-0">
                <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Year</th>
                <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Week</th>
                <th class="footer-table__elem text-left text-white font-light border-r border-yellow-light px-3 py-2">Best Estimate</th>
                <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Lower Limit</th>
                <th class="footer-table__elem text-left text-white font-light border-r border-gray-700 px-3 py-2">Upper Limit</th>
                <th class="footer-table__elem text-left text-white font-light border-r border-primary px-3 py-2">Range</th>
            </tr>
        </tfoot>
    </table>
    <div class="flex justify-center w-full mt-6">
        <ul class="pager flex items-center justify-center">

            <li (click)="previous()" class="activeprevious mr-3">
                <a class="border cursor-pointer border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700">
                    <svg class="w-3 h-3 text-gray-600 fill-current stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polyline fill="none" stroke-miterlimit="10" points="16,1 8,12 16,23 "></polyline></g></svg>
                </a>
            </li>
            <li (click)="next()" class="activenext ml-3">
                <a class="border cursor-pointer border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-600 fill-current stroke-current" viewBox="0 0 24 24"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polyline fill="none" stroke-miterlimit="10" points="8,1 16,12 8,23 "></polyline></g></svg>
                </a>
            </li>
        </ul>
    </div>


    <div class="flex flex-wrap -mx-2 font-semibold mt-6">
        <div class="flex-1 px-2">
            <app-badge-result [innerClass]="fetchedpredictData.lowClass">
                <div class="font-light">
                    Lowest
                </div>
                <div><span class="text-sm">{{fetchedpredictData.lowestTable}}</span><span class="text-sm" [innerHTML]="fetchedpredictData.unit"></span></div>
            </app-badge-result>

        </div>
        <div class="flex-1 px-2">
            <app-badge-result [innerClass]="fetchedpredictData.meanClass">
                <div class="font-light">
                    Mean
                </div>
                <div><span class="text-sm">{{fetchedpredictData.meanTable}}</span><span class="text-sm" [innerHTML]="fetchedpredictData.unit"></span></div>
            </app-badge-result>
        </div>
        <div class="flex-1 px-2">
            <app-badge-result [innerClass]="fetchedpredictData.highClass">
                <div class="font-light">
                    Highest
                </div>
                <div><span class="text-sm">{{fetchedpredictData.highestTable}}</span><span class="text-sm" [innerHTML]="fetchedpredictData.unit"></span></div>
            </app-badge-result>
        </div>
    </div>
    <div class="text-center text-gray-600 text-sm mt-4">
        Last updated on {{fetchedpredictData.lastUpdatedDate}}
    </div>
</div>
