import { Component, OnInit, AfterViewInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import tippy from 'tippy.js'
import * as map from 'lodash/map'

@Component({
  selector: 'app-results-table-advanced',
  templateUrl: './results-table-advanced.component.html',
  styleUrls: ['./results-table-advanced.component.scss']
})
export class ResultsTableAdvancedComponent implements AfterViewInit {

  @Input() predicttableData;
  @Input() fetchedpredictData;
  @Output() onNextPrevious: EventEmitter<any> = new EventEmitter();
  currPage;
  more;

  constructor(private elem: ElementRef){}


  ngAfterViewInit(): void {
    const app = this

    let elements = this.elem.nativeElement.querySelectorAll('.has-tooltip');
    map(elements, item => {
      tippy(item, {
          allowHTML: true,
          trigger: 'click',
          arrow: true,
          animation: 'shift-away',
          interactive: true,
          content(reference) {
            const id = reference.getAttribute('data-template');
            const template = document.getElementById(id);
            return template.innerHTML;
          },
      })
    })
  }

  ngOnInit():void {
    //console.log(this.predicttableData);
    this.currPage = this.fetchedpredictData.page;
    this.more = this.fetchedpredictData.more;
  }

  next() {
    if (this.more == 1) {
      this.onNextPrevious.emit('next'); 
    }
  }

  previous() {
    if (this.currPage > 1 ) {
      this.onNextPrevious.emit('prev'); 
    }
  }


}
// tippy('button', {
//   content(reference) {
//     const id = reference.getAttribute('data-template');
//     const template = document.getElementById(id);
//     return template.innerHTML;
//   },
//   allowHTML: true,
// });
