<!-- <a (click)="ngxSmartModalService.getModal('videoHowToUse').open()" class="cursor-pointer relative how-to-block block rounded-lg overflow-hidden"> -->
<a routerLink="/contact-us" class="cursor-pointer relative how-to-block block rounded-lg overflow-hidden">
   
    <img src="assets/images/how-to-use-metswift.jpg" class="w-full h-full object-center object-cover" />
    <div class="absolute inset-0 z-10 p-6 flex flex-col items-end">
        <div class="w-full text-white font-semibold text-lg">
            Contact us
        </div>
        <div class="mt-auto w-full">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-white stroke-current fill-current" viewBox="0 0 48 48"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="3" transform="translate(0.5 0.5)"><line fill="none" stroke-miterlimit="10" x1="2" y1="24" x2="45" y2="24"></line> <polyline fill="none" stroke-miterlimit="10" points="31,10 45,24 31,38 "></polyline></g></svg>
        </div>
    </div>
</a>





<ngx-smart-modal [closable]="false" #videoHowToUse identifier="videoHowToUse">
    <div class="container max-w-4xl mx-auto">
        <div class="flex justify-end mb-4">
            <a (click)="ngxSmartModalService.getModal('videoHowToUse').close()" class="flex cursor-pointer items-center justify-center w-8 h-8 lg:w-12 lg:h-12 bg-white rounded-full shadow">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 lg:w-5 lg:h-5 mx-auto stroke-current fill-current" viewBox="0 0 16 16"><g stroke-width="1"><line fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="13.5" y1="2.5" x2="2.5" y2="13.5"></line> <line fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="2.5" y1="2.5" x2="13.5" y2="13.5"></line> </g></svg>
            </a>
        </div>
        <div class="embed-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/YuCAvBCUdS4?autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </div>
</ngx-smart-modal>
