import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

import * as moment from 'moment';
import { gsap } from 'gsap';
import { MenuSidebarComponent } from '../../menu-sidebar/menu-sidebar.component';
import { UnitConvertPipe } from './../../../../pipes/unit-convert.pipe';
import { ProbConvertPipe } from './../../../../pipes/prob-convert.pipe';
import { type } from 'os';



@Component({
  selector: 'app-results-overview-data',
  templateUrl: './results-overview-data.component.html',
  styleUrls: ['./results-overview-data.component.scss'],
  providers: [ProbConvertPipe,UnitConvertPipe],
})
export class ResultsOverviewDataComponent implements OnInit, AfterViewInit, OnChanges {

    @ViewChild('barsev', {static: false}) barsev: ElementRef;
    @ViewChild('barmod', {static: false}) barmod: ElementRef;
    @ViewChild('barext', {static: false}) barext: ElementRef;
    @Input() searchState: any = '';
    @Input() hourList: any;
    daysMod: any = '';
    daysSev: any = '';
    dayStart: any = null;
    dayEnd: any = null;
    thresholds: any;// = {"status":1,"eventId":"4613","alert":"4","severe":"5","unit":"mm","symbol":"","message":"Thresholds added"}
    unit: any;
    data: any;// = {"prob_T1": 17.75, "elevation": 25.0, "mean": 2.6, "highest": 43.0, "lowest": 0.0, "prob_T2": 13.12, "Risk": {}, "StationName": "Topcliffe"}
    weathertypeid:any;
    weatherName:any;
    lowest;
    mean;
    highest;
    prob_T1: any;
    prob_T2: any;
    moderateDashArray: any;
    severeDashArray: any;
    classes: any = {
        lowest: '',
        mean: '',
        highest: '',
    };
    weathersymbol:any;
    extremeWeatherDashArray:any;
    extremeWeatherProb:any;
    userSector:any;
    downtimeForExtremeWeather:any;
    downtimeForExtremeWeatherInNumber:any;
    type:any;
    overviewPredData:any;
    varype = 'daily'
    timelineBarDays = gsap.timeline({ paused:true, reversed: true});
    // Carousel
    public slideConfig;
    public Probabilities;
    predDataFreq:any;
    moderateThresholdHead:string;
    severeThresholdHead:string;
    thresholdHeadUnits:string;
    printWeatherVarTypeAndName:string;
    timePeriod:string;
    overviewToolTipMsgPrefix:string;
    overviewToolTipMsgBody:string;
    overviewToolTipMsgSuffix:string;
    lowestLzngTooltip:string;
    meanLzngTooltip:string;
    highestLzngTooltip:string;
    modelName:string;


    constructor(
            private unitConvertPipe: UnitConvertPipe,
            private probConvertPipe: ProbConvertPipe
        ) {
          
        setTimeout(() => {
          console.log(this.hourList)
            this.type = this.searchState.type;
            this.thresholds = this.searchState.thresholdData;
            this.userSector = this.searchState.userSector;
            this.unit = this.thresholds.unit;
            this.weathersymbol = this.thresholds.symbol;
            this.weathertypeid = this.thresholds.WeatherTypeId;
            this.weatherName = this.thresholds.weatherName;
            this.moderateThresholdHead = this.thresholds.orderItem.replace(/\b\w/g, char => char.toUpperCase())+' '+this.thresholds.alert;
            this.severeThresholdHead = this.thresholds.orderItem.replace(/\b\w/g, char => char.toUpperCase())+' '+this.thresholds.severe;
            this.thresholdHeadUnits = this.thresholds.symbol+'&nbsp;'+this.thresholds.unit;            

            if ( this.type == 'stats' ) {
              this.data = this.searchState.overviewData;
              if ( this.weathertypeid == 1 || this.weathertypeid == 2 || this.weathertypeid == 3 || this.weathertypeid == 4 ) {
                this.lowest = this.convertUnit(this.data.lowest, this.unit, 1);
                this.mean = this.convertUnit(this.data.mean, this.unit, 1);
                this.highest = this.convertUnit(this.data.highest, this.unit, 1);
                this.prob_T1 = this.data.prob_T1;
                this.moderateDashArray = this.prob_T1;
                this.prob_T2 = this.data.prob_T2;
                this.severeDashArray = this.prob_T2;
              } else {
                this.extremeWeatherDashArray = this.extremeWeatherProb = this.data.prob;
              }
            } else if ( this.type == 'predict' ) {
              this.overviewPredData = this.searchState.predOverviewData;
              this.predDataFreq = ( this.overviewPredData.frequency == 'monthly' ) ? 'Month' : 'Week';
              //this.overviewPredData.data = this.preparePredictionData(this.overviewPredData.data);
            } else {
              // risk type
            }

            this.modelName = sessionStorage.getItem('modelName');

            this.timePeriod = ['hourly','hourly_speed','daily','weekly','monthly'].includes(this.thresholds.weatherVarType) ? this.thresholds.weatherVarType.split("_")[0] : 'daily';

            if (this.weathertypeid === '1') this.printWeatherVarTypeAndName = `${this.weatherName}fall`;
            if (this.weathertypeid === '3') this.printWeatherVarTypeAndName = `${this.thresholds.weatherVarType != 'hourly' ? this.thresholds.weatherVarType : '' } ${this.weatherName}`;
            if (['2','4'].includes(this.weathertypeid)) this.printWeatherVarTypeAndName = `${this.weatherName} ${this.thresholds.weatherVarType.replace('hourly_','')}`;
            if (this.weathertypeid === '6') this.printWeatherVarTypeAndName = `${this.weatherName}s`;
            if (['5','7','8'].includes(this.weathertypeid)) this.printWeatherVarTypeAndName = `${this.weatherName}`;

            if (this.searchState.userSector != 3) {
              this.overviewToolTipMsgPrefix = `This represents the average ${this.timePeriod} probability for `;
            } else if (this.searchState.userSector == 3) {
              this.overviewToolTipMsgPrefix = `This represents the total downtime due to `;
            }
            this.overviewToolTipMsgBody = `${this.printWeatherVarTypeAndName} being ${this.thresholds.orderItem} `;
            if (['5','6','7','8'].includes(this.weathertypeid)) this.overviewToolTipMsgBody = `${this.printWeatherVarTypeAndName} `;
            this.overviewToolTipMsgSuffix =  ` between ${this.searchState.dateend} and ${this.searchState.datestart}`;


            this.lowestLzngTooltip = `The ${this.modelName == 'Standard' ? 'historical lowest observed' : 'lowest'} ${this.timePeriod} ${this.printWeatherVarTypeAndName} for the period ${this.searchState.datestart} to ${this.searchState.dateend}${this.modelName == 'Standard' ? '' : ' recorded under analogous conditions'}`;
            this.meanLzngTooltip = `The ${this.modelName == 'Standard' ? 'historical average observed' : 'average'} ${this.timePeriod} ${this.printWeatherVarTypeAndName} for the period ${this.searchState.datestart} to ${this.searchState.dateend}${this.modelName == 'Standard' ? '' : ' recorded under analogous conditions'}`;
            this.highestLzngTooltip = `The ${this.modelName == 'Standard' ? 'historical highest observed' : 'highest'} ${this.timePeriod} ${this.printWeatherVarTypeAndName} for the period ${this.searchState.datestart} to ${this.searchState.dateend}${this.modelName == 'Standard' ? '' : ' recorded under analogous conditions'}`;

        }, 1000);
    }

    // preparePredictionData(data) {
    //   let len = data.length;
    //   if ( len > 0 ) {
    //     for ( let i=0;i<len;i++ ) {
    //       data[i].predictionName = this.capitalizeWords(data[i].prediction[0].name);
    //       data[i].predictionValue = this.convertUnit(data[i].prediction[0].value, this.unit, 0);
    //     }
    //   }
    //   return data;
    // }

    convertUnit(value, unit, isString = 0) {
        let val = parseFloat(this.unitConvertPipe.transform(value, unit, undefined, undefined, this.weathertypeid));
        if (isString == 1) return val;
        return val.toFixed(2);
    }

    ngOnInit(): void {
        // setTimeout(() => {
        //     this.thresholds = this.searchState.thresholdData
        // }, 1000);
        // this.Probabilities = [
        //   {heading: "Probability for",title: "Wildfire", value: "0.39"},
        //   {heading: "Probability for",title: "Wildfire 1", value: "0.49"},
        //   {heading: "Probability for",title: "Wildfire 2", value: "0.79"},
        //   {heading: "Probability for",title: "Wildfire 3", value: "0.19"},
        // ];
    
        this.slideConfig = {
          "dots": false,
          "slidesToShow": 1, 
          "slidesToScroll": 1,
          "variableWidth": false,
          "infinite": false,
          "autoplay": false,
          "prevArrow":'<a class="border arrowprev cursor-pointer border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700"><svg class="w-3 h-3 text-gray-600 fill-current stroke-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polyline fill="none" stroke-miterlimit="10" points="16,1 8,12 16,23 "></polyline></g></svg></a>',
          "nextArrow": '<a class="border arrownxt cursor-pointer border-gray-400 rounded-full w-8 h-8 flex items-center justify-center hover:border-gray-700"><svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-gray-600 fill-current stroke-current" viewBox="0 0 24 24"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polyline fill="none" stroke-miterlimit="10" points="8,1 16,12 8,23 "></polyline></g></svg></a>',
        };
        
    }

    ngOnChanges(): void {
      if(this.hourList){
        if(this.hourList[0] == 'hourly'){
          this.varype = 'hours'
        }else{
          this.varype = 'days'
        }
      }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
          if ( this.type == 'stats' ) {
            this.dayStart = moment(this.searchState.datestart, 'DD/MM/YYYY').format('DD MMM')
            this.dayEnd = moment(this.searchState.dateend, 'DD/MM/YYYY').format('DD MMM')
            let days = moment(this.searchState.dateend, 'DD/MM/YYYY').diff(moment(this.searchState.datestart, 'DD/MM/YYYY'), 'days')
            // this.daysMod = Number(this.data.prob_T1 / 100 * days).toFixed(2)
            //this.daysSev = Number(this.data.prob_T2 / 100 * days).toFixed(2)
            if(this.hourList){
              if(this.hourList[0] == 'hourly'){
                this.varype = 'hours'
                days = ((days+1)*(this.hourList[2]-this.hourList[1]+1)) - 1
              }else{
                this.varype = 'days'
              }
            }
              
            if ( this.weathertypeid == 1 || this.weathertypeid == 2 || this.weathertypeid == 3 || this.weathertypeid == 4 ) {
              if ( this.data.prob_T1>0.1 || this.data.prob_T1==0 ) {
                this.daysMod = this.probConvertPipe.transform(this.data.prob_T1,days);
              } else {
                this.daysMod = this.probConvertPipe.transform(0.1,days);
              }
              if ( this.data.prob_T2>0.1 || this.data.prob_T2==0 ) {
                this.daysSev = this.probConvertPipe.transform(this.data.prob_T2,days);
              } else {
                this.daysSev = this.probConvertPipe.transform(0.1,days);
              }
            }

            if ( this.weathertypeid == 5 || this.weathertypeid == 6 || this.weathertypeid == 7 || this.weathertypeid == 8 ){
              this.downtimeForExtremeWeather = this.probConvertPipe.transform(this.data.prob,days);
              this.downtimeForExtremeWeatherInNumber = JSON.parse(this.downtimeForExtremeWeather);
            }

            if ( this.weathertypeid == 1 || this.weathertypeid == 2 || this.weathertypeid == 3 || this.weathertypeid == 4 ) {
                this.classes.lowest = this.colorLogic(this.lowest);
                this.classes.mean = this.colorLogic(this.mean);
                this.classes.highest = this.colorLogic(this.highest);
            } else {

            }
            
            days = days+1;
            if(this.barmod && this.barsev){
                let modPerc = ( this.daysMod != 0 ) ? ( this.daysMod/days )*100 : 0;
                let sevPerc = ( this.daysSev != 0 ) ? ( this.daysSev/days )*100 : 0;
                this.timelineBarDays.to(this.barmod.nativeElement, 1, { width: modPerc+'%', ease: "power3.out" }, 0)
                this.timelineBarDays.to(this.barsev.nativeElement, 1, { width: sevPerc+'%', ease: "power2.out" }, .5)
                setTimeout(()=> this.timelineBarDays.play(), 200)
            }
            
            if ( this.barext ) {
              let extPerc = ( this.downtimeForExtremeWeatherInNumber != 0 ) ? ( this.downtimeForExtremeWeatherInNumber/days )*100 : 0;
              this.timelineBarDays.to(this.barext.nativeElement, 1, { width: extPerc+'%', ease: "power3.out" }, .5) 
              setTimeout(()=> this.timelineBarDays.play(), 200) 
            }
          } else if ( this.type == 'predict' ) {

          } else {
            // risk type 
          }
        }, 1000);
    }

    colorLogic(value) {
        //value = this.convertUnit(value, this.weatherunit);
        let riskClass = "";
        let moderate = parseFloat(this.thresholds.alert);
        let severe = parseFloat(this.thresholds.severe);
        if (
          (severe > moderate && value >= severe) ||
          (severe < moderate && value <= severe)
        )
          riskClass = "result-red";
        else if (
          (severe > moderate && value < moderate) ||
          (severe < moderate && value > moderate)
        )
          riskClass = "result-green";
        else if (
          (severe > moderate && value < severe && value >= moderate) ||
          (severe < moderate && value > severe && value <= moderate)
        )
          riskClass = "result-yellow";
        else if (severe == moderate) riskClass = "resultEqualThresholds";
        return riskClass;
    }

    capitalizeWords(str) {
      return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    }

    slickInit(e) {
     }
     
    breakpoint(e) {
    }
     
    afterChange(e) {
    }
    
    beforeChange(e) {
    }

}
