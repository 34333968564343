<div class="h-full w-full flex flex-col">
    <div class="w-full">
        <div class="flex flex-wrap justify-between">
            <h3 class="uppercase text-sm text-primarylighter font-bold">My portfolio</h3>
            <!--<a href="javascript:void(0)" [routerLink]="['/create-bulk-events']" class="bg-primarylighter text-white flex items-center px-4 py-1 rounded-full text-xs font-bold">Create Bulk events</a>-->
            <a href="javascript:void(0)" [routerLink]="['/create-bulk-events']" class="hidden bg-primarylighter text-white flex items-center px-4 py-1 rounded-full text-xs font-bold">Create Bulk events</a>
        </div>
    </div>
    <div class="w-full rounded-lg bg-gray-200 p-4 md:p-6 mt-4 flex-1 flex items-end flex-col">
        <ul class="mb-auto w-full">
            <li *ngFor="let event of eventsList; let in = index" class="mb-3 md:mt-3 xl:mt-2 xl:mb-2">
                <div class="desktop-only">
                    <div class="flex items-center justify-between relative overflow-hidden min-w-0">
                        <div class="flex-1 min-w-0">
                            <div class="w-full lg:flex lg:flex-no-wrap">
                                <div class="folio-lft" (click)="onClickToggleReportInfo(in, event.eventId)">
                                    <div class="folio-lft-1">
                                        <div class="w-auto flex-1 font-semibold text-sm mr-auto min-w-0 cursor-pointer">
                                            <div class="flex items-center min-w-0">
                                                <div class="truncate whitespace-nowrap overflow-hidden w-auto flex-1">
                                                    <span>{{event.contractName}}</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="folio-lft-2 pt-2 cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="ml-2 flex-shrink-0" width="8.136"
                                            height="4.568" viewBox="0 0 8.136 4.568">
                                            <g id="select-84" transform="translate(-266.572 239.068)">
                                                <path id="Path_133" data-name="Path 133"
                                                    d="M20.721,34l-3.361,3.361L14,34"
                                                    transform="translate(253.279 -272.361)" fill="none" stroke="#a8a8a8"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    stroke-miterlimit="10" stroke-width="1" />
                                            </g>
                                        </svg>
                                    </div>

                                </div>
                                <div class="folio-rgt cursor-pointer" (click)="onClickReportGo(event.eventId)">
                                    <div class="folio-rgt-1">{{event.startDate}}</div>
                                    <div class="folio-rgt-2 pt-2"><span
                                            class="circle-separator bg-orange-base rounded-full mx-2 flex"></span></div>
                                    <div class="folio-rgt-1">{{event.endDate}}</div>
                                    <div class="folio-rgt-3">
                                        <div class="flex w-6 h-6 rounded-full btn-gradient items-center justify-between text-white font-light">
                                            <!--<span class="mx-auto">+</span>-->
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mx-auto w-4 h-4 py-px">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                            </svg>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="mobile-only">
                    <div class="relative">
                        <div class="flex-1 min-w-0">
                            <div class="w-full lg:flex lg:flex-no-wrap">
                                <div class="mb-full">
                                    <div class="mb-full font-semibold text-sm cursor-pointer"
                                        (click)="onClickToggleReportInfo(in, event.eventId)">
                                        <div class="mb-full-left">
                                            <span>{{event.contractName}}</span>
                                        </div>
                                        <div class="mb-full-right">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 float-right" width="8.136"
                                                height="4.568" viewBox="0 0 8.136 4.568">
                                                <g id="select-84" transform="translate(-266.572 239.068)">
                                                    <path id="Path_133" data-name="Path 133"
                                                        d="M20.721,34l-3.361,3.361L14,34"
                                                        transform="translate(253.279 -272.361)" fill="none" stroke="#a8a8a8"
                                                        stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-miterlimit="10" stroke-width="1" />
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="cursor-pointer w-48 lg:w-auto text-xs lg:text-sm font-light flex items-center lg:ml-4 lg:flex-shrink-0"
                                    (click)="onClickReportGo(event.eventId)">
                                    {{event.startDate}} <span
                                        class="circle-separator bg-orange-base rounded-full mx-2"></span>
                                    {{event.endDate}}
                                    <div
                                        class="flex ml-auto w-6 h-6 rounded-full btn-gradient items-center justify-between text-white font-light">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mx-auto w-4 h-4 py-px">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                        </svg> 
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="content-report-widget bg-white rounded" #contentitem>
                    <div class="flex flex-wrap justify-start mb-2">
                        <div *ngIf="event.selectedWeather.includes('rain')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'rain'"></app-weather-type-small>
                        </div>
                        <div *ngIf="event.selectedWeather.includes('wind')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'wind'"></app-weather-type-small>
                        </div>
                        <div *ngIf="event.selectedWeather.includes('temperature')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'temperature'"></app-weather-type-small>
                        </div>
                        <div *ngIf="event.selectedWeather.includes('snow')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'snow'"></app-weather-type-small>
                        </div>
                        <div *ngIf="event.selectedWeather.includes('hail')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'hail'"></app-weather-type-small>
                        </div>
                        <div *ngIf="event.selectedWeather.includes('storm')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'storm'"></app-weather-type-small>
                        </div>
                        <div *ngIf="event.selectedWeather.includes('dust')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'dust'"></app-weather-type-small>
                        </div>
                        <div *ngIf="event.selectedWeather.includes('fog')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'fog'"></app-weather-type-small>
                        </div>
                        <div *ngIf="event.selectedWeather.includes('tornado')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'tornado'"></app-weather-type-small>
                        </div>
                        <div *ngIf="event.selectedWeather.includes('hurricane')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'hurricane'"></app-weather-type-small>
                        </div>
                        <div *ngIf="event.selectedWeather.includes('earthquake')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'earthquake'"></app-weather-type-small>
                        </div>
                        <div *ngIf="event.selectedWeather.includes('wildfire')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'wildfire'"></app-weather-type-small>
                        </div>
                    </div>
                    <div class="mb-1 text-xs">
                        Event Name <strong class="ml-3 text-sm">{{event.eventName}}</strong>
                    </div>
                    <div class="mb-1 text-xs">
                        Contract ID <strong class="ml-3 text-sm">{{event.contractName}}</strong>
                    </div>
                    <div class="mb-1 text-xs">
                        Period <strong class="ml-3 text-sm">{{event.startDate}} - {{event.endDate}}</strong>
                    </div>
                    <div class="mb-1 text-xs">
                        Risk <strong class="ml-3 text-sm">&pound; {{event.eventCost}}</strong>
                    </div>
                    <div class="mb-1 text-xs" *ngIf="unlimited != 1">
                        Usage <strong class="ml-3 text-sm"><span
                                class="btn-gradient rounded-full text-white py-1 text-xs px-3 inline-block mx-auto font-semibold">{{event.insightCount}}</span></strong>
                    </div>
                    <div class="lg:mb-1 text-xs">
                        Summary <strong class="ml-3 text-sm">
                            <button class="has-tooltip ml-3" class="{{event.infoColor}}" data-template="content-tooltip">
                                <span
                                    class="bg-gray-300 text-primary relative rounded-full w-5 h-5 inline-flex items-center justify-center"
                                    style="margin-top: -1px">
                                    <div (clickOutside)="onClickedOutside(event, in)">
                                        <svg (click)="showThisItem(event,in)" xmlns="http://www.w3.org/2000/svg"
                                            width="9" height="9" viewBox="0 0 16 16" class="relative fill-current">
                                            <path d="M9,7c0-0.6-0.4-1-1-1H5v2h2v6H5v2h6v-2H9V7z"></path>
                                            <circle cx="7.5" cy="2.5" r="1.5"></circle>
                                        </svg>

                                        <div *ngIf="event.show && in==currentIndex" class="tooltips"
                                            id="content-tooltip">
                                            <div [innerHtml]="infoText" class="text-white tooltip_wrp mb-2"></div>

                                        </div>
                                    </div>
                                </span>
                            </button>
                        </strong>
                    </div>

                    <div class="mt-4">
                        <button (click)="onClickReportGo(event.eventId)"
                            class="bg-primary block rounded-full text-sm font-semibold text-white px-3 py-2 w-full hover:bg-orange-base">
                            View report
                        </button>
                    </div>
                </div>
            </li>


            <!-- <li *ngFor="let event of eventsList; let in = index" class="mb-3 md:mt-3 xl:mt-2 xl:mb-2">
                <div class="flex items-center justify-between relative overflow-hidden min-w-0">
                        
                        <div class="flex-1 min-w-0">
                            <div class="w-full lg:flex lg:flex-no-wrap">
                                <div class="w-auto flex-1 font-semibold text-sm mr-auto min-w-0 cursor-pointer">
                                    <div (click)="onClickToggleReportInfo(in, event.eventId)" class="flex items-center min-w-0">
                                        <div class="truncate whitespace-nowrap overflow-hidden w-auto flex-1"><span>{{event.contractName}}</span></div>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="ml-2 flex-shrink-0" width="8.136" height="4.568" viewBox="0 0 8.136 4.568">
                                          <g id="select-84" transform="translate(-266.572 239.068)">
                                            <path id="Path_133" data-name="Path 133" d="M20.721,34l-3.361,3.361L14,34" transform="translate(253.279 -272.361)" fill="none" stroke="#a8a8a8" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                                          </g>
                                        </svg>
                                    </div>
                                </div>

                                <div (click)="onClickReportGo(event.eventId)" class="cursor-pointer w-full lg:w-auto text-xs lg:text-sm font-light flex items-center lg:ml-4 lg:flex-shrink-0">
                                    {{event.startDate}} <span class="circle-separator bg-orange-base rounded-full mx-2"></span> {{event.endDate}}
                                    <div class="flex ml-2 w-6 h-6 rounded-full btn-gradient items-center justify-between text-white font-light">
                                            <span class="mx-auto">+</span>
                                    </div>
                                </div>

                            </div>
                        </div>

                </div>
                <div class="content-report-widget bg-white rounded" #contentitem>
                    <div class="flex flex-wrap justify-start mb-2">
                        <div *ngIf="event.selectedWeather.includes('rain')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'rain'"></app-weather-type-small>
                        </div>
                        <div *ngIf="event.selectedWeather.includes('wind')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'wind'"></app-weather-type-small>
                        </div>
                        <div *ngIf="event.selectedWeather.includes('temperature')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'temperature'"></app-weather-type-small>
                        </div>
                        <div *ngIf="event.selectedWeather.includes('snow')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'snow'"></app-weather-type-small>
                        </div>
                        <div *ngIf="event.selectedWeather.includes('hail')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'hail'"></app-weather-type-small>
                        </div>
                        <div *ngIf="event.selectedWeather.includes('storm')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'storm'"></app-weather-type-small>
                        </div>
                        <div *ngIf="event.selectedWeather.includes('dust')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'dust'"></app-weather-type-small>
                        </div>
                        <div *ngIf="event.selectedWeather.includes('fog')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'fog'"></app-weather-type-small>
                        </div>
                        <div *ngIf="event.selectedWeather.includes('tornado')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'tornado'"></app-weather-type-small>
                        </div>
                        <div *ngIf="event.selectedWeather.includes('hurricane')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'hurricane'"></app-weather-type-small>
                        </div>
                        <div *ngIf="event.selectedWeather.includes('earthquake')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'earthquake'"></app-weather-type-small>
                        </div>
                        <div *ngIf="event.selectedWeather.includes('wildfire')" class="w-10 h-10 mr-2 flex-shrink-0">
                            <app-weather-type-small [type]="'wildfire'"></app-weather-type-small>
                        </div>
                    </div>
                    <div class="mb-1 text-xs">
                        Group <strong class="ml-3 text-sm">{{event.groupName}}</strong>
                    </div>
                    <div class="mb-1 text-xs">
                        Contract ID <strong class="ml-3 text-sm">{{event.contractName}}</strong>
                    </div>
                    <div class="mb-1 text-xs">
                        Period <strong class="ml-3 text-sm">{{event.startDate}} - {{event.endDate}}</strong>
                    </div>
                    <div class="mb-1 text-xs">
                        Risk <strong class="ml-3 text-sm">&pound; {{event.eventCost}}</strong>
                    </div>
                    <div class="mb-1 text-xs">
                        Usage <strong class="ml-3 text-sm"><span class="btn-gradient rounded-full text-white py-1 text-xs px-3 inline-block mx-auto font-semibold">{{event.insightCount}}</span></strong>
                    </div>
                    <div class="lg:mb-1 text-xs">
                        Summary <strong class="ml-3 text-sm">
                            <button class="has-tooltip ml-3" data-template="content-tooltip">
                                <span class="bg-gray-300 text-primary relative rounded-full w-5 h-5 inline-flex items-center justify-center" style="margin-top: -1px">
                                    <div (clickOutside)="onClickedOutside(event, in)">
                                        <svg (click)="showThisItem(event,in)" xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 16 16" class="relative fill-current"><path d="M9,7c0-0.6-0.4-1-1-1H5v2h2v6H5v2h6v-2H9V7z"></path><circle cx="7.5" cy="2.5" r="1.5"></circle></svg>

                                        <div *ngIf="event.show && in==currentIndex" class="tooltips" id="content-tooltip">
                                            <div [innerHtml]="infoText" class="text-white tooltip_wrp mb-2"></div>
                                            
                                        </div>
                                    </div>
                                </span>
                            </button>
                        </strong>
                    </div>
                    
                    <div class="mt-4">
                        <button (click)="onClickReportGo(event.eventId)" class="bg-primary block rounded-full text-sm font-semibold text-white px-3 py-2 w-full hover:bg-orange-base">
                            View report
                        </button>
                    </div>
                </div>
            </li> -->
        </ul>
        <div class="w-full mt-4">
            <a routerLink="/my-portfolio"
                class="rounded-full py-2 px-4 bg-white text-primarylighter block text-sm font-bold text-center">View
                all</a>
        </div>
    </div>
</div>