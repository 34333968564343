import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-badge-result',
  templateUrl: './badge-result.component.html',
  styleUrls: ['./badge-result.component.scss']
})
export class BadgeResultComponent implements OnInit {
    @Input() innerClass: string;
    constructor() { }

    ngOnInit(): void {
    }

}
