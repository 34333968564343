import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
// import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

// import { User } from '../../models/user';
import { AppState, selectAuthState } from '../../store/app.states';
import { LogIn, LogOut } from '../../store/actions/auth.actions';
import { LoaderService } from '../../services/loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { LogoutService } from '../../services/logout.service';

@Component({
  selector: 'app-setpassword',
  templateUrl: './setpassword.component.html',
  styleUrls: ['./setpassword.component.scss']
})

export class SetpasswordComponent implements OnInit {
  public _forgotError: boolean = false;
    public _errorMsg: string;
    public _success: boolean = false;
    public _successMsg: string;
    public loading = false;
    public token: any;
    public tokenFlag:boolean=false;
    public isPassValid:boolean=true;
    public formData:any;
    destroy$: Subject<any> = new Subject();
    public show1:boolean = false;
    public show2:boolean = false;
    constructor(private store: Store<AppState>,public _router: Router, public route: ActivatedRoute, public userService: UserService , private LS: LogoutService, private loaderService: LoaderService) { }

    ngOnInit() {        
        this.token = this.route.snapshot.queryParams["token"];
        if(this.token == undefined){
            
            this.tokenFlag=true;
        }else{
            this.tokenFlag=false;
        }
        
    }

    ngAfterViewInit() {
        function getHeight() {
            var heights = document.body.scrollHeight + 50;
        }
        getHeight();
        window.onresize = function() {
            getHeight();
        };
    }

    onSubmit(formValue: any) {
        this.formData = formValue;
        this.isPassValid = this.checkIfPassIsValid(formValue.newpassword, formValue.confirmpassword);
        if ( !this.isPassValid )
            return;
        this.loaderService.display(true);
        this.userService.setpassword({ newpassword: formValue.newpassword, cnpassword: formValue.confirmpassword })
        //.pipe(takeUntil(this.destroy$))
            .subscribe(
            data => {
                let successData = data;
                if (successData.status == 1) {
                    this.loaderService.display(false);
                    this._forgotError = false;
                    this._success = true;
                    this._successMsg = successData.message;
                }
                setTimeout(() => {
                    this.store.dispatch(new LogOut());
                    this._router.navigate(['/login'])
                },2000);
            },
            error => {
                this.loaderService.display(false);
                let errordata = error.error;
                if (errordata.status == 0) {
                    this._forgotError = true;
                    this._success = false;
                    this._errorMsg = errordata.message;

                }
            });
    }

    checkIfPassIsValid(newpassword, confirmpassword) {
        if ( !newpassword || !confirmpassword ) {
            return false;
        } else {
            return true;
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    hidePassword1(){
        this.show1 = false;
    }
    
    showPassword1(){
        this.show1 = true;
    }
    hidePassword2(){
        this.show2 = false;
    }
    
    showPassword2(){
        this.show2 = true;
    }
}
