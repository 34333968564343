import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SigninComponent } from './components/signin/signin.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NewReportComponent } from './components/new-report/new-report.component';
import { ReportComponent } from './components/report/report.component';
import { ReportAllComponent } from './components/report-all/report-all.component';
//TL import { DataSourcesComponent } from './components/data-sources/data-sources.component';
import { NewsComponent } from './components/news/news.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { MyPortfolioComponent } from './components/my-portfolio/my-portfolio.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { EditReportComponent } from './components/edit-report/edit-report.component';
//TL import { MetswiftAnalyticsComponent } from './components/metswift-analytics/metswift-analytics.component';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { ForgotComponent } from './components/forgot/forgot.component';
import { ResetComponent } from './components/reset/reset.component';
import { SetpasswordComponent } from './components/setpassword/setpassword.component';
import { CreateBulkEventsComponent } from './components/create-bulk-events/create-bulk-events.component';
import { KnowledgeBaseComponent } from './components/knowledge-base/knowledge-base.component';
import { MetswiftHLPComponent } from './components/metswift-hlp/metswift-hlp.component';

const routes: Routes = [
    { path: 'log-in', component: SigninComponent, data: { animationState: 'SignIn'} },
    // { path: 'sign-up', component: SignupComponent, data: { animationState: 'HomePage'} },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: { animationState: 'Dashboard'} },
    { path: 'new-report', component: NewReportComponent, canActivate: [AuthGuard], data: { animationState: 'NewReport'} },
    { path: 'report/:eventId', component: ReportComponent, canActivate: [AuthGuard], data: { animationState: 'Report'} },
    { path: 'edit-report/:eventId', component: EditReportComponent, canActivate: [AuthGuard], data: { animationState: 'EditReport'} },
    { path: 'report-all', component: ReportAllComponent, canActivate: [AuthGuard], data: { animationState: 'ReportAll'} },
    { path: 'my-account', component: MyAccountComponent, canActivate: [AuthGuard], data: { animationState: 'MyAccount'} },
    //TL { path: 'data-sources', component: DataSourcesComponent, canActivate: [AuthGuard], data: { animationState: 'DataSources'} },
    //TL { path: 'metswift-analytics', component: MetswiftAnalyticsComponent, canActivate: [AuthGuard], data: { animationState: 'MetswiftAnalytics'} },
    { path: 'news', component: NewsComponent, canActivate: [AuthGuard], data: { animationState: 'News'} },
    { path: 'news/category/:category', component: NewsComponent, canActivate: [AuthGuard], data: { animationState: 'News'} },
    { path: 'contact-us', component: ContactUsComponent, canActivate: [AuthGuard], data: { animationState: 'ContactUs'} },
    { path: 'my-portfolio', component: MyPortfolioComponent, canActivate: [AuthGuard], data: { animationState: 'MyPortfolio'} },
    { path: 'termandcondition', component: TermsAndConditionsComponent, canActivate: [AuthGuard], data: { animationState: 'TermAndCondition'} },
    { path: 'forgot', component: ForgotComponent,  data: { animationState: 'Forgot'} },
    { path: 'reset', component: ResetComponent,  data: { animationState: 'Forgot'} },
    { path: 'setpassword', component: SetpasswordComponent ,canActivate: [AuthGuard],  data: { animationState: 'Setpassword'} },
    { path: 'create-bulk-events', component: CreateBulkEventsComponent, canActivate: [AuthGuard], data: { animationState: 'CreateBulkEvents'} },
    { path: 'knowledge-base', component: KnowledgeBaseComponent, canActivate: [AuthGuard], data: { animationState: 'KnowledgeBase'} },
    { path: 'metswift-hlp', component: MetswiftHLPComponent, canActivate: [AuthGuard], data: { animationState: 'MetswiftHLP'} },
    { path: '', component: DashboardComponent, canActivate: [AuthGuard], data: { animationState: 'Dashboard'} },
    

    // { path: 'user-profile/:id', component: UserProfileComponent }
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
