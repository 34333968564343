import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/angular';
import * as last from 'lodash/last';
import * as first from 'lodash/first';
import * as moment from 'moment';

@Component({
  selector: 'app-calendar-results',
  templateUrl: './calendar-results.component.html',
  styleUrls: ['./calendar-results.component.scss']
})
export class CalendarResultsComponent implements OnInit,AfterViewInit {
    @Input() eventData: any;
    @Input() thresholdMod: any; // thresholds passed by parent to display colour
    @Input() thresholdSev: any; // thresholds passed by parent to display colour
    @Input() calmonths:any;
    // thresholdMod: string = '1.0 mm';
    // thresholdSev: string = '5.0 mm';
    @Input() weatherName:any;
    events;
    lastResultDate:any = new Date();

    datacalendar: any = {
        "2015": [{start:"2015-07-17",value:"7.0 mm",title:"7.0 mm"},{start:"2015-07-18",value:"0.3 mm",title:"0.3 mm"},{start:"2015-07-19",value:"0.8 mm",title:"0.8 mm"},{start:"2015-07-20",value:"0.0 mm",title:"0.0 mm"},{start:"2015-07-21",value:"0.0 mm",title:"0.0 mm"},{start:"2015-07-22",value:"0.0 mm",title:"0.0 mm"},{start:"2015-07-23",value:"0.0 mm",title:"0.0 mm"},{start:"2015-07-24",value:"0.0 mm",title:"0.0 mm"},{start:"2015-07-25",value:"0.0 mm",title:"0.0 mm"},{start:"2015-07-26",value:"0.0 mm",title:"0.0 mm"},{start:"2015-07-27",value:"0.0 mm",title:"0.0 mm"},{start:"2015-07-28",value:"0.0 mm",title:"0.0 mm"},{start:"2015-07-29",value:"0.0 mm",title:"0.0 mm"},{start:"2015-07-30",value:"0.0 mm",title:"0.0 mm"},{start:"2015-07-31",value:"0.0 mm",title:"0.0 mm"}],
        "2016": [{start:"2016-07-17",value:"7.0 mm",title:"7.0 mm"},{start:"2016-07-18",value:"0.3 mm",title:"0.3 mm"},{start:"2016-07-19",value:"0.8 mm",title:"0.8 mm"},{start:"2016-07-20",value:"0.0 mm",title:"0.0 mm"},{start:"2016-07-21",value:"0.0 mm",title:"0.0 mm"},{start:"2016-07-22",value:"0.0 mm",title:"0.0 mm"},{start:"2016-07-23",value:"0.0 mm",title:"0.0 mm"},{start:"2016-07-24",value:"0.0 mm",title:"0.0 mm"},{start:"2016-07-25",value:"0.0 mm",title:"0.0 mm"},{start:"2016-07-26",value:"0.0 mm",title:"0.0 mm"},{start:"2016-07-27",value:"0.0 mm",title:"0.0 mm"},{start:"2016-07-28",value:"0.0 mm",title:"0.0 mm"},{start:"2016-07-29",value:"0.0 mm",title:"0.0 mm"},{start:"2016-07-30",value:"0.0 mm",title:"0.0 mm"},{start:"2016-07-31",value:"0.0 mm",title:"0.0 mm"}],
        "2017": [{start:"2017-07-17",value:"7.0 mm",title:"7.0 mm"},{start:"2017-07-18",value:"0.3 mm",title:"0.3 mm"},{start:"2017-07-19",value:"0.8 mm",title:"0.8 mm"},{start:"2017-07-20",value:"0.0 mm",title:"0.0 mm"},{start:"2017-07-21",value:"0.0 mm",title:"0.0 mm"},{start:"2017-07-22",value:"0.0 mm",title:"0.0 mm"},{start:"2017-07-23",value:"0.0 mm",title:"0.0 mm"},{start:"2017-07-24",value:"0.0 mm",title:"0.0 mm"},{start:"2017-07-25",value:"0.0 mm",title:"0.0 mm"},{start:"2017-07-26",value:"0.0 mm",title:"0.0 mm"},{start:"2017-07-27",value:"0.0 mm",title:"0.0 mm"},{start:"2017-07-28",value:"0.0 mm",title:"0.0 mm"},{start:"2017-07-29",value:"0.0 mm",title:"0.0 mm"},{start:"2017-07-30",value:"0.0 mm",title:"0.0 mm"},{start:"2017-07-31",value:"0.0 mm",title:"0.0 mm"}],
        "2018": [{start:"2018-07-17",value:"7.0 mm",title:"7.0 mm"},{start:"2018-07-19",value:"0.8 mm",title:"0.8 mm"},{start:"2018-07-20",value:"0.0 mm",title:"0.0 mm"},{start:"2018-07-21",value:"0.0 mm",title:"0.0 mm"},{start:"2018-07-22",value:"0.0 mm",title:"0.0 mm"},{start:"2018-07-23",value:"0.0 mm",title:"0.0 mm"},{start:"2018-07-24",value:"0.0 mm",title:"0.0 mm"},{start:"2018-07-25",value:"0.0 mm",title:"0.0 mm"},{start:"2018-07-26",value:"0.0 mm",title:"0.0 mm"},{start:"2018-07-27",value:"0.0 mm",title:"0.0 mm"},{start:"2018-07-28",value:"0.0 mm",title:"0.0 mm"},{start:"2018-07-29",value:"0.0 mm",title:"0.0 mm"},{start:"2018-07-30",value:"0.0 mm",title:"0.0 mm"},{start:"2018-07-31",value:"0.0 mm",title:"0.0 mm"}]
    };

    itemActive: string = "";
    indexActive: number = Object.keys(this.datacalendar).length -1;
    calendarOptions:CalendarOptions;

    constructor() {
       
    }

    ngOnInit(): void {
        
    }

    ngAfterViewInit():void{
        console.log(this.eventData);
        this.indexActive = Object.keys(this.eventData).length -1;
        this.itemActive = Object.keys(this.eventData)[Object.keys(this.eventData).length -1]
        console.log(moment(first(this.eventData[this.itemActive]).start,'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD'))
        console.log(moment(last(this.eventData[this.itemActive]).start,'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD'))
        this.setThresholdsCalendarDate();
        this.loadNewOptions();

        console.log(this.itemActive)
    }

    loadNewOptions(){
        const app = this
        this.calendarOptions = {
            themeSystem: 'CalendarResultsTheme',
            initialView: 'dayGridMonth',
            headerToolbar: {
              start: 'customTitle,title',
              center: '',
            //   end: 'prevYear,prev,next,nextYear',
              end: 'customPrevYear,prev,next,customNextYear',
            },
            validRange: {
                start: moment(first(app.eventData[app.itemActive]).start,'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD'),
                end: moment(last(app.eventData[app.itemActive]).start,'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD 23:59:00'),
            },
            defaultAllDay: true,
            eventDidMount: (obj) =>{
                let changedDate:any = obj.view.currentStart;
                changedDate = moment(changedDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
                sessionStorage.setItem('savedThresholdCalDate', changedDate);
                const element = obj.el.parentNode.parentNode.parentNode as HTMLElement;
                const string = parseFloat(obj.event._def.title)
                if( isNaN(string) ){
                    
                    element.classList.add('disabled-event')
                
                } else{
                    if(this.weatherName=="fog" || this.weatherName=="hail" || this.weatherName=="dust" || this.weatherName=="storm"){
                        if(string != 0){
                            element.classList.add('severe-event')
                        } else {
                            element.classList.add('good-event')
                        }
                    } else{
                        if(parseFloat(this.thresholdSev) < parseFloat(this.thresholdMod)){ 
                            if(string <= parseFloat(this.thresholdSev)){
                                element.classList.add('severe-event')
                            } else if(string <= parseFloat(this.thresholdMod)){
                                element.classList.add('moderate-event')
                            } else {
                                element.classList.add('good-event')
                            }
                        } else{
                            if(string >= parseFloat(this.thresholdSev)){
                                element.classList.add('severe-event')
                            } else if(string >= parseFloat(this.thresholdMod)){
                                element.classList.add('moderate-event')
                            } else {
                                element.classList.add('good-event')
                            }
                        }
                        
                    }
                }
                
            },
            // eventChange: (obj) => {
            //     console.log(obj)
            // },
            titleFormat: { year: 'numeric', month: 'long' } ,
            customButtons: {
                customNextYear: {
                    text: 'Next',
                    click: function() {
                      app.clickedNext()
                    },
                  },
                  customPrevYear: {
                    text: 'Prev',
                    click: function() {
                      app.clickedPrev()
                    },
                  },
                customTitle: {
                    text: 'Historical',
                },
            },
            showNonCurrentDates: false,
            //initialDate: moment(last(this.eventData).start,'YYYY-MM-DD').toDate(),
            
            initialDate: this.lastResultDate,
            // events: this.eventData,
            // initialDate: moment(last(app.eventData[app.itemActive]).start,'YYYY-MM-DD').toDate(),
            events: app.eventData[app.itemActive],
        };
    }

    

    clickedNext() {
        if(this.indexActive < (Object.keys(this.eventData).length -1)){
          this.itemActive = Object.keys(this.eventData)[this.indexActive +1]
          this.indexActive = this.indexActive +1
          this.lastResultDate= moment(first(this.eventData[this.itemActive]).start,'YYYY-MM-DD').toDate()
          this.loadNewOptions();
        }
    }
    clickedPrev() {
        if(this.indexActive > 0){
          this.itemActive = Object.keys(this.eventData)[this.indexActive -1]
          this.indexActive = this.indexActive -1
          this.loadNewOptions();
        }

    }

    setThresholdsCalendarDate() {
        let savedCalendarDate:any = sessionStorage.getItem('savedThresholdCalDate');
        // sessionStorage.removeItem('savedThresholdCalDate');
        if ( savedCalendarDate != null && savedCalendarDate != undefined  ) {
            // If date is saved in the sessionStorage
            let dtavail = false;
            let yrindex=0;
            for (var propName in this.eventData) {
                for ( let d=this.eventData[propName].length-1;d>=0;d--) {
                    let dates:any = this.eventData[propName][d].start;
                    // console.log(dates)
                    // console.log(savedCalendarDate)
                    if ( savedCalendarDate == dates ) {
                        this.lastResultDate = moment(dates , 'YYYY-MM-DD').toDate();
                        dtavail=true;
                        break;
                    } 
                    if(propName==savedCalendarDate.split('-')[0]){
                        // this.itemActive=propName;
                        // this.indexActive=yrindex;
                    }
                    dates = '';
                }
                yrindex++;
            }
            if(!dtavail){
                this.lastResultDate = moment(last(this.eventData[this.itemActive]).start,'YYYY-MM-DD').toDate();
            }
        } else {
            this.lastResultDate = moment(last(this.eventData[this.itemActive]).start,'YYYY-MM-DD').toDate()
        }
    }

}
