import { Component, OnInit, ViewChild, AfterViewInit, ViewChildren, QueryList, ElementRef, OnDestroy } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Router, RouterStateSnapshot, ActivatedRoute } from '@angular/router'
import { Store } from '@ngrx/store'
import { AppState, selectAuthState } from '../../store/app.states';
import { LoaderService } from './../../services/loader.service';
import { EnquiriesService } from './../../services/enquiries.service';
import { Subject ,  Observable, from } from 'rxjs';
import { takeUntil } from 'rxjs/operators'; 

import tippy from 'tippy.js'
import * as map from 'lodash/map'
import * as filter from 'lodash/filter'
import * as first from 'lodash/first'
//import Litepicker from '../../litepicker'
import Litepicker from '../../litepicker2';
import '../../litepicker2/plugins/mobilefriendly';
import * as moment from 'moment'
import { EventService } from 'src/app/services/event.service'
import { ProbConvertPipe } from 'src/app/pipes/prob-convert.pipe';
import { compareNumbers } from '@fullcalendar/angular';
import { NewEnquiryService } from 'src/app/services/newenquiry.service'
import { UnitConvertPipe } from 'src/app/pipes/unit-convert.pipe'
import { DateTime } from 'src/app/litepicker/datetime';
import { ThresholdsItemComponent } from '../partials/reports/thresholds-item/thresholds-item.component';
// declare let d3: any;
import * as d3 from 'd3';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { LogoutService } from 'src/app/services/logout.service';
import { join } from 'path';
import { Console } from 'console';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  providers: [UnitConvertPipe,ProbConvertPipe]
})


export class ReportComponent implements OnInit, AfterViewInit, OnDestroy{
    getState: Observable<any>;
    currentType: string;
    currentTitleSelected: string;
    currentModelSelected: string;
    currentWeatherWithVarType: string;
    selectedTypes: any;
    selectedTypesObj: any;
    searchState: any;
    showEditableTitle: boolean = true;
    showReportType: string = 'Statistical Standard';
    title : string = 'Your enquiries';
    showAdvancedType: string = 'graph';
    amountData: string = 'All data';
    numberInsights: number = 0;
    datepickerInput: any = false;
    showHideDropdown:boolean = false;
    showHideDropdownHurricane:boolean = false;
    hurricaneModels = ['Standard Risk','Advanced Risk'];
    eventId:number;// = 3753;
    username:any;
    eventDetails:any;
    overviewData:any;
    overviewPythonData:any;
    curentWeatherData:any;
    curentWeather:string='rain';
    modelDropDown:any=[];
    modelData:any;
    currentModelData:any;
    currentModelName:any;
    hurricaneCurrentModelName:any='';
    currentModelNameHead:any;
    currentModelNameUpper:any;
    currentModelNameLower:any;
    currentUnitData:any;
    currentUnit:any;
    curentDurationData:any;
    curentWeatherVarData:any;
    curentWeatherVarName:string;
    curentWeatherVarDisplayName:string;
    userSector:any;
    weatherUnitData:any;
    currentWeatherUnitData:any;
    currentWeatherUnit:any;
    currentWeatherUnitSlug:any;
    getThresholdsData:any;
    stationNameAndHeight:any;
    public eventRes: any;
    userName = JSON.parse(localStorage.getItem("auth"))['user']['username'];
    public sId="IN0592B";
    public showcal=false;
    public weatherunit: any='mm';
    public weathervartypeid: any=1;
    public weathertypeid: any=1;
    public weatherS: any;
    public weatherA: any;
    public alert: any;
    public severe: any;
    public wName = 'rain';
    public weatherType='daily';
    public reportStartDate: any="1/7/2021";
    public reportEndDate: any="31/7/2021";
    public graphStartDate: any="1/7/2021";
    public graphEndDate: any="31/7/2021";
    public overviewStartDate: any = "2021-07-01";
    public overviewEndDate: any = "2021-07-31";
    public locationName: any;
    public sector:string ="3";
    errorMsg:any;
    isError:boolean=false;
    //graph variables
    public showNext: boolean;
    public showPrevious: boolean;
    public graphObj: any = {};
    public stdate;
    public edate;
    public totalNoOfDaysInPeriod: any;
    public numYears: any="end";
    public dataObj;
    public weatherId: any;
    public graphInd;
    type = 'stats';
    graphStatic;
    public graphModelRes;
    public statPredictData;
    public idx;
    public weathervarId: any;
    public freqId: any;
    public freqName: any;
    public outputName = "Output";
    public modelName = "Model";
    public statsWeek = "Week";
    public predYear = "Year";
    public periodName = "Period";
    public typeName = "Percentage";
    public graphTable = "Graph";
    public graphTablePred = "Graph";
    public predClass: string = "";
    public weekMonthClass: string = "";
    public outputData;
    public modelAnalogue: any;
    public periods;
    public modelType ='MA';
    public variableName = "Variable";
    public p1Color;
    public p50Color;
    public p25Color;
    public p75Color;
    public p99Color;
    public predictiongraphTypes = [{ id: 2, name: "Spread" }];
    public graphId = 1;
    public graphName: any = "Best Estimate";
    public graphpage=1;
    public riskGraphPage=1
    public showGraph = false;
    public statNoOfRecord: number = 10;
    public workingHourValue;
    public workingHourData = [{ id: 1, name: "All Hours" }];
    public fetchedData;
    public tableData: any = [];
    public tableRiskData: any = [];
    public predicttableData: any = [];
    public graphdata;
    public country;
    public showgraphdefault=false;
    public riskSummary;
    public pdfGraphData;
    public fetchedPDFData;
    showSidebarMobile: boolean = false;
    notify: Subject<boolean> = new Subject();
    modelnameonly='Advanced';
    customizedStartDate:any;
    customizedEndDate:any;
    public countryWiseHurricaneName:any;
    hurricaneTitle;
    hurricaneCategories:any;
    public hurricaneCategoryLabel: any;
    countselectedwtr;
    // LTF graph
    public lastUpdatedDate;
    public months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    public predictgraphObj: any = {};
    public currentPredicPage = 1;
    public predNoOfRecord: number = 10;
    public riskNoOfRecord: number = 10;
    public fetchedpredictData;
    public meanValArrTable:any=[];
    public viewPredictGraph: boolean = true;
    public viewPredictTableButton: boolean = false;
    public disablePrevious: boolean;
    public disableNext: boolean;
    public disableViewMore: boolean;
    public showHideRisk:boolean;
    public showCSVDownloadIcon:boolean=false;
    public historicalData:any;
    public showupdatebutton = false;
    public timeresolusion = [{'value':'daily', 'name': 'Daily'},{'value':'hourly', 'name': 'Hourly'}]
    public hourslist = [{"value":"0", name:"12 AM"},{"value":"1", name:"1 AM"},{"value":"2", name:"2 AM"},{"value":"3", name:"3 AM"},{"value":"4", name:"4 AM"},{"value":"5", name:"5 AM"},{"value":"6", name:"6 AM"},{"value":"7", name:"7 AM"},{"value":"8", name:"8 AM"},{"value":"9", name:"9 AM"},{"value":"10", name:"10 AM"},{"value":"11", name:"11 AM"},{"value":"12", name:"12 PM"},{"value":"13", name:"1 PM"},{"value":"14", name:"2 PM"},{"value":"15", name:"3 PM"},{"value":"16", name:"4 PM"},{"value":"17", name:"5 PM"},{"value":"18", name:"6 PM"},{"value":"19", name:"7 PM"},{"value":"20", name:"8 PM"},{"value":"21", name:"9 PM"},{"value":"22", name:"10 PM"},{"value":"23", name:"11 PM"}]
    public hourstoname = {0:"12 AM",1:"1 AM",2:"2 AM",3:'3 AM', 4:"4 AM", 5:"5 AM", 6:"6 AM", 7:"7 AM", 8:"8 AM", 9:"9 AM",10:"10 AM",11:"11 AM",12:"12 PM",13:"1 PM", 14:"2 PM", 15:"3 PM", 16:"4 PM",17:"5 PM",18:"6 PM",19:"7 PM", 20:"8 PM",21:"9 PM",22:"10 PM",23:"11 PM"}
    @ViewChildren('tooltipWeatherElem') tooltipWeatherElem:QueryList<ElementRef>;
    @ViewChild('tooltipAddWeatherType', {static: false}) tooltipAddWeatherType: ElementRef;
    @ViewChild('changeAdvanceType', {static: false }) changeAdvanceType: ElementRef;
    @ViewChild('changeReportType', {static: false }) changeReportType: ElementRef;

    @ViewChild('dateStart', {static: false}) dateStartInput: ElementRef;
    @ViewChild('dateEnd', {static: false}) dateEndInput: ElementRef;
    @ViewChild('containerDates', {static: false}) containerDates: ElementRef;
    @ViewChild('tooltipdishourly', {static: false}) tooltipdishourly: ElementRef;
    @ViewChild('starthours', { static: false}) starthoursui: ElementRef
    @ViewChild('endhours', { static: false}) endhoursui: ElementRef
    @ViewChild(ThresholdsItemComponent) thresholdupdate: ThresholdsItemComponent;

    destroy$: Subject<any> = new Subject();

    head: any = [
       {
          "id":1,
          "name":"Daily",
          "displayName":"Daily",
          "active": 1
       },
       {
          "id":2,
          "name":"Weekly",
          "displayName":"Weekly",
          "active": 0
       },
       {
          "id":8,
          "name":"Monthly",
          "displayName":"Monthly",
          "active": 0
       }
    ];

    public predFrequency: any;
    public currFreq: any;
    public freqidx;
    predRainMonthlyFrequency = [{ name: "Monthly", dispName: "Month", active: 1 }];
    public commonFrequency = [
      { name: "Weekly", dispName: "Week", active: 1 },
      { name: "Monthly", dispName: "Month", active: 0 }
    ];
    public totalMonths;
    public eventStartedMonth:any;
    public eventStartedYear:any;
    public completedTweleveMonths: boolean = false;
    public lowestTable:any=[];
    public meanTable:any=[];
    public highestTable:any=[];
    discretedata;
    discreteoptions;
    public bestEstimate: any = { yScale: "", margin: "" };
    public boxplot: any = { yScale: "", margin: "" };
    public boxplotdata: any;
    public lowest:any=[];
    public mean:any=[];
    public highest:any=[];
    public lowColor: any;
    public highColor: any;
    public meanColor: any;
    plotlyGraphLayout;
    plotlyGraphData;
    public boxplotoptions;
    currRiskData;
    riskMode;
    currRiskModeData;
    riskYear;
    currRiskYearData;
    overviewRiskdataResponse: any;
    public fetchedRiskData: any;
    riskDataToBePassed: any;
    public riskMoreData;
    public riskImgNames: any;
    public imgPath: any;
    public weekNo: any;
    public weekText: any;
    public risktableHead;
    public catKeyArr: any = [];
    public colorArr: any = [];
    public riskId;
    public riskindex = 0;
    public riskImageData:any;
    public showRightScroll: boolean = true;
    public showleftScroll: boolean = true;
    public closepopup:boolean = false;
    unlimited:number = 1;
    public modelDefText:any=`<p>Our statistical models analyse historical data to ascertain the risk that conditions cross your moderate or severe impact thresholds. They also determine the lowest, mean, and highest observed values.</p>
    <p> In standard guise, all available location-specific historical data is assessed. This provides a ‘climate baseline’, which is useful for establishing the typical range of conditions but has relatively limited predictive value.</p>`;
    public calmonths=[];
    public hourlyData :any;
    public isHoursAvailable: any = false;
    public hourlySelected: any = 'daily'; 
    public startHours:any = 0;
    public endHours:any = 23;
    public hourvalidate:boolean = false
    public isHourlyAvailable:boolean = false
    xlsxhourdata=[]
    desableloader = true;
    public monthlist = {1:'Jan',2:'Feb',3:'Mar',4:'April',5:'May',6:'Jun',7:'Jul',8:'Aug',9:'Sep',10:'Oct',11:'Nov',12:'Dec'}
    public weatherloadhourly = {'rain': 12, 'wind': 25, 'temperature': 12, 'snow': 10, 'fog': 3, 'hail': 3, 'dust': 3, 'storm': 3}
    public weatherloadhourlygraph = {'rain': 17, 'wind': 30, 'temperature': 17, 'snow': 15, 'fog': 3, 'hail': 3, 'dust': 3, 'storm': 3}
    public loaderhtimeoverview:any = 0
    public loaderhtimegraph:any = 0
    intervaloverview;
    intervalgraph;
    showloaderoverview = false;
    showloadergraph = false;
    overviewmulti = 0.003
    graphmulti = 0.0015
    intervallist = []
    hourlypage = 1
    constructor(
      private titleService:Title,
      private router: Router,
      private route: ActivatedRoute,
      private store: Store,
      private loader:LoaderService,
      private enquiriesService: EnquiriesService,
      private eventService: EventService,
      private newenquiryService:NewEnquiryService,
      private unitConvertPipe: UnitConvertPipe,
      private probConvertPipe:ProbConvertPipe,
      private LS:LogoutService,
    ) {
      if(window.location.hostname == 'tatras-ux-uat.metswift.live' || window.location.hostname == 'www.tatras-ux-uat.metswift.live'){
        this.weatherloadhourly = {'rain': 7, 'wind': 15, 'temperature': 7, 'snow': 5, 'fog': 3, 'hail': 3, 'dust': 3, 'storm': 3}
        this.weatherloadhourlygraph = {'rain': 12, 'wind': 20, 'temperature': 12, 'snow': 10, 'fog': 3, 'hail': 3, 'dust': 3, 'storm': 3}
      }
      this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
        this.eventId = +params["eventId"];
      });
            this.getState = this.store.select(selectAuthState);
            this.getState.subscribe((state) => {
              this.username = state.user.username;
              this.userSector = state.user.sector;
              this.unlimited = state.user.unlimited;
            });
            if(localStorage.getItem('sector')){
              this.userSector = localStorage.getItem('sector');
            }
            this.getRiskJSON();
            this.fetchOverviewData();
            
            // this.selectedTypes = ['rain', 'wind', 'fog', 'dust']
            // this.currentType = this.selectedTypes[0]
            this.searchState = {
              userSector: this.userSector,
              // datestart: "09/02/2021",
              // dateend: "11/03/2021",
              location: {
                bbox: [-1.800362, 53.719309, -1.161587, 53.955164],
                center: [-1.54917, 53.79972],
                id: "place.6194974472628170",
                place_name: "Leeds, West Yorkshire, England, United Kingdom",
              }
            }
            // const getActiveView = first(filter(this.head, item => item.active))
            // this.currentTitleSelected = `${getActiveView.name} results for ${this.currentType} <span class="font-light text-xs">-</span> ${this.searchState.datestart} <span class="font-light text-xs">to</span> ${this.searchState.dateend}`
            // this.numberInsights = 3


        // const navigation = this.router.getCurrentNavigation();
        // if(navigation.extras.state){
        //   console.log(navigation.extras.state)
        //       const arrayTypes = []
        //       this.selectedTypesObj = navigation.extras.state.selectedTypes
        //       map(navigation.extras.state.selectedTypes, (item, key) => {
        //         arrayTypes.push(key)
        //       })
        //       this.currentType = arrayTypes[0]
        //       this.selectedTypes = arrayTypes
        //       this.searchState = navigation.extras.state.searchState
        //       this.numberInsights = navigation.extras.state.numberInsights
        //       this.title = `${this.searchState.location.place_name}`
        //       this.titleService.setTitle(`${this.searchState.location.place_name} - ${this.searchState.datestart} / ${this.searchState.dateend}`);
        //       const getActiveView = first(filter(this.head, item => item.active))
        //       this.currentTitleSelected = `${getActiveView.name} results for ${this.currentType} <span class="font-light text-xs">-</span> ${this.searchState.datestart} <span class="font-light text-xs">to</span> ${this.searchState.dateend}`

        // } else {
        //   this.router.navigate(['/dashboard'])
        // }
    }

    ngOnInit(): void {
      // this.fetchOverviewData();
      // this.getRiskJSON();
    }

    ngAfterViewInit(): void {
      setTimeout(() => {
        const app = this
      if(!this.datepickerInput){
        this.datepickerInput = new Litepicker({
            parentEl: this.containerDates.nativeElement,
            element: this.dateStartInput.nativeElement,
            elementEnd: this.dateEndInput.nativeElement,
            allowRepick: true,
            startDate: moment(app.searchState.datestart, 'DD/MM/YYYY').toDate(),
            endDate: moment(app.searchState.dateend, 'DD/MM/YYYY').toDate(),
            minDate: moment(app.searchState.datestart, 'DD/MM/YYYY').toDate(),
            maxDate: moment(app.searchState.dateend, 'DD/MM/YYYY').toDate(),
            scrollToDate: true,
            isCapped: true,
            setup: (picker) => {
            picker.on('selected',(start, end) => {
              let endmonth = (end.getMonth()+1)<10? '0'+parseInt(end.getMonth()+1):end.getMonth()+1;
              let startmonth = (start.getMonth()+1)<10? '0'+parseInt(start.getMonth()+1):start.getMonth()+1;
              //app.customizedStartDate = moment(start).format('DD/MM/YYYY');
              app.customizedStartDate = start.getDate()+'/'+ startmonth +'/'+start.getFullYear();
              //app.customizedEndDate = moment(end).format('DD/MM/YYYY');
              app.customizedEndDate = end.getDate()+'/'+ endmonth +'/'+end.getFullYear();
              // console.log('to integrate')
              this.showupdatebutton = true;
              this.hourlypage = 1
            })
          },
          //plugins: ['mobilefriendly'],
        })
        // this.datepickerInput.setDateRange(app.searchState.datestart, app.searchState.dateend)
      }
      if(this.tooltipAddWeatherType){
        tippy(this.tooltipAddWeatherType.nativeElement, {
          allowHTML: true,
          theme: 'light-border',
          placement: 'bottom-start',
          arrow: true,
          animation: 'shift-away',
          interactive: true,
          content: `<div class="px-4 py-2 max-w-xs font-semibold text-primary text-sm">Add weather report</div>`,
        })
      }
      // if(this.changeAdvanceType.nativeElement){
      //   tippy(this.changeAdvanceType.nativeElement, {
      //       allowHTML: true,
      //       theme: 'light-border',
      //       placement: 'bottom-start',
      //       trigger: 'click',
      //       arrow: true,
      //       animation: 'shift-away',
      //       interactive: true,
      //       content: `
      //           <button class="px-10 py-3 text-sm font-semibold text-primary w-full block border-b border-gray-300 hover:text-gray-600 select-graph">Graph</button>
      //           <button class="px-10 py-3 text-sm font-semibold text-primary w-full block hover:text-gray-600 select-table">Table</button>
      //           <button class="px-10 py-3 text-sm font-semibold text-primary w-full block hover:text-gray-600 select-map">Map</button>
      //       `,
      //       onMount(instance) {
      //         const selectorGraph = instance.popper.querySelector('.select-graph')
      //         if(selectorGraph){
      //           selectorGraph.addEventListener('click', () => {
      //             app.onChangeViewAdvanced('graph', instance)
      //           })
      //         }
      //         const selectorTable = instance.popper.querySelector('.select-table')
      //         if(selectorTable){
      //           selectorTable.addEventListener('click', () => {
      //             app.onChangeViewAdvanced('table', instance)
      //           })
      //         }
      //         const selectorMap = instance.popper.querySelector('.select-map')
      //         if(selectorMap){
      //           selectorMap.addEventListener('click', () => {
      //             app.onChangeViewAdvanced('map', instance)
      //           })
      //         }
      //       },
      //   });
      // }

      map(this.tooltipWeatherElem.toArray(), item => {
        if ( item.nativeElement.dataset.type == 'hurricane' )
          item.nativeElement.dataset.type = this.hurricaneTitle;
          tippy(item.nativeElement, {
            allowHTML: true,
            theme: 'light-border',
            placement: 'bottom-start',
            arrow: true,
            animation: 'shift-away',
            interactive: true,
            content: `<div class="px-4 py-2 max-w-xs font-semibold text-primary text-sm">View ${item.nativeElement.dataset.type} report</div>`,
          })
      })
      if(this.tooltipdishourly){
        tippy(this.tooltipdishourly.nativeElement, {
          allowHTML: true,
          theme: 'light-border',
          placement: 'bottom-start',
          arrow: true,
          animation: 'shift-away',
          interactive: true,
          content: `<div class="px-4 py-2 max-w-xs font-semibold text-primary text-sm">An hourly report could not be generated for this location</div>`,
        })
      }
      
      }, 2000);
      document.onclick = (args: any) : void => {
        let currentTarget = args.target.closest('.report_dropdown');
        if(currentTarget === null) {
            this.showHideDropdown=false;
        }
      }
      document.onclick = (args: any) : void => {
        let currentTarget = args.target.closest('.report_dropdown_hurricane');
        if(currentTarget === null) {
            this.showHideDropdownHurricane=false;
        }
      }
      this.extractLocationData();
    }

    modifyHeading() {
      console.log(this.currentType)
      if(this.currentType=='hurricane' || this.currentType=='tornado'){
          this.modelDefText=`<p>Historical impacts are aggregated and reported as the risk of at least one occurring within the week(s) of your enquiry. Tropical cyclone hits are graded according to locally accepted classifications (e.g. <span>Saffir-Simpson Scale</span> in the US), while the <span>Enhanced Fujita Scale</span> is applied to tornadoes.</p>
          <p> Tropical cyclone data extends back to between 1857 and 1949, depending on the region.</p> 
          <p>US Tornado data reaches back to 1950.</p>
          <p> Such long datasets warrant a high level of confidence to your risk assessment.</p>`
      } else if(this.currentType=='earthquake'){
        this.modelDefText=`<p>Aggregated across all historical years and reported as the risk of at least one occurring within the week(s) relating to your enquiry. The severity is graded according to the <span>Moment Magnitude Scale</span>.</p>
         <p>Data extends back to 2150 BC and is filtered to ‘significant’ earthquakes: Magnitude 4 and above.</p>`
      } else if(this.currentType=='wildfire'){
          this.modelDefText=`<p>Risk values have been estimated worldwide using a model derived from the relation of historical US wildfires of at least 10 acres in size, to weather conditions and topographical factors.</p>
          <p>This risk value represents the likelihood of an existing fire developing into a wildfire event (at least 10 acres). This does not provide any estimation of the risk of a fire starting, only of a localised fire developing into a wildfire.</p>`
      } else if(this.modelnameonly=='Standard'){
          this.modelDefText=`<p>Our statistical models analyse historical data to ascertain the risk that conditions cross your moderate or severe impact thresholds. They also determine the lowest, mean, and highest observed values.</p>
          <p> In standard guise, all available location-specific historical data is assessed. This provides a ‘climate baseline’, which is useful for establishing the typical range of conditions but has relatively limited predictive value.</p>`
      } else if(this.modelnameonly=='Advanced'){ 
          this.modelDefText=`<p>The advanced version weights the analysis toward ‘most useful’ historical years, determined through assessment of large-scale phenomena that drive global weather patterns.</p>
           <p>Specialist climatological research has been employed to create a model that has proven skill out to long lead times:</p>
           <p>“In May 2020, testing found that out to two year’s lead time, our Advanced Statistical model predictions are <b>more accurate overall for 98% of 153 countries</b> when tested against a 2000-2020 average for predictions initialised in each month of 2001-2019”.</p>
            <p>This provides you with a more accurate risk perception, when compared to the standard model.</p>`
      } else if(this.modelnameonly=='AI'){ 
          this.modelDefText=`<p>MetSwift have developed a distinctive artificial intelligence that understands hyperlocal weather behaviour and how it relates to both climate trends and shorter-term cycles.</p>
          <p>This continually learning neutral network has been developed as a combined effort by meteorologists, data practitioners, and business professionals. Through self-assessment, it repeatedly refines itself until the best performance is achieved.</p>
           <p>Predictions are available on a weekly basis out to 52 weeks ahead, and monthly basis out to 60 months.</p>`
      } else if(this.modelnameonly=='Advanced AI'){ 
          this.modelDefText=`<p>Here, the Advanced AI model meets the research underpinning our Advanced Statistical model. With this additional information, predictions achieve even greater accuracy.</p>`  
      }
      
      if (this.type == 'risk') {
        //this.currentTitleSelected = `Standard Risk ${this.riskYear} results for ${this.currentType.replace(/\b\w/g, l => l.toUpperCase())} <span class="font-light text-xs">1-</span> ${this.customizedStartDate} <span class="font-light text-xs">to</span> ${this.customizedEndDate}`;
        this.currentTitleSelected = `Risk results for ${this.currentType.replace(/\b\w/g, l => l.toUpperCase())} <br><span class="font-medium text-sm">${this.customizedStartDate} <span class="font-light text-xs">to</span> ${this.customizedEndDate}</span>`;
        this.currentModelSelected = `Standard Historical<br class="hidden sm:block"> <span class="font-medium text-sm">${this.riskYear}</span>`;
        if ( this.currentType == 'hurricane' && this.hurricaneTitle != undefined )
          //this.currentTitleSelected = `Standard Risk ${this.riskYear} results for ${this.hurricaneTitle.replace(/\b\w/g, l => l.toUpperCase())} <span class="font-light text-xs">2-</span> ${this.customizedStartDate} <span class="font-light text-xs">to</span> ${this.customizedEndDate}`;
          this.currentTitleSelected = `Risk results for ${this.hurricaneTitle.replace(/\b\w/g, l => l.toUpperCase())} <br><span class="font-medium text-sm">${this.customizedStartDate} <span class="font-light text-xs">to</span> ${this.customizedEndDate}</span>`;
          this.currentModelSelected = `Standard Historical<br class="hidden sm:block"> <span class="font-medium text-sm">${this.riskYear}</span>`;
      } else {
        let currentWeatherId = this.curentWeatherVarData.weathertypeid;
        const getActiveView = first(filter(this.head, item => item.active))
        let duration = this.modelnameonly=='Standard'?this.amountData:''; 
        if ( currentWeatherId == 2 ) {
          //this.currentTitleSelected = `${this.currentModelNameHead} ${duration} results for ${this.currentType.replace(/\b\w/g, l => l.toUpperCase())} ${getActiveView.displayName} <span class="font-light text-xs">-</span> ${this.customizedStartDate} <span class="font-light text-xs">to</span> ${this.customizedEndDate}`;
          this.currentTitleSelected = `Results for ${this.currentType.replace(/\b\w/g, l => l.toUpperCase())} ${getActiveView.displayName} <br><span class="font-medium text-sm">${this.customizedStartDate} <span class="font-light text-xs">to</span> ${this.customizedEndDate}</span>`;
          this.currentModelSelected = `${this.currentModelNameUpper}<br class="hidden sm:block"> <span class="font-medium text-sm">${duration}</span> ${this.currentModelNameLower}`;
        } else if ( currentWeatherId == 3 ) {
          //this.currentTitleSelected = `${this.currentModelNameHead} ${duration} results for  ${getActiveView.displayName} ${this.currentType.replace(/\b\w/g, l => l.toUpperCase())} <span class="font-light text-xs">-</span> ${this.customizedStartDate} <span class="font-light text-xs">to</span> ${this.customizedEndDate}`;
          this.currentTitleSelected = `Results for ${getActiveView.displayName} ${this.currentType.replace(/\b\w/g, l => l.toUpperCase())} <br><span class="font-medium text-sm">${this.customizedStartDate} <span class="font-light text-xs">to</span> ${this.customizedEndDate}</span>`;
          this.currentModelSelected = `${this.currentModelNameUpper}<br class="hidden sm:block"> <span class="font-medium text-sm">${duration}</span> ${this.currentModelNameLower}`;
        } else if ( currentWeatherId == 4 ) {
          //this.currentTitleSelected = `${this.currentModelNameHead} ${duration} results for ${this.currentType.replace(/\b\w/g, l => l.toUpperCase())} ${getActiveView.displayName}  <span class="font-light text-xs">5-</span> ${this.customizedStartDate} <span class="font-light text-xs">to</span> ${this.customizedEndDate}`;
          this.currentTitleSelected = `Results for ${this.currentType.replace(/\b\w/g, l => l.toUpperCase())} ${getActiveView.displayName} <br><span class="font-medium text-sm">${this.customizedStartDate} <span class="font-light text-xs">to</span> ${this.customizedEndDate}</span>`;
          this.currentModelSelected = `${this.currentModelNameUpper}<br class="hidden sm:block"> <span class="font-medium text-sm">${duration}</span> ${this.currentModelNameLower}`;
        } else {
          //this.currentTitleSelected = `${this.currentModelNameHead} ${duration} results for  ${getActiveView.displayName} ${this.currentType.replace(/\b\w/g, l => l.toUpperCase())} <span class="font-light text-xs">-</span> ${this.customizedStartDate} <span class="font-light text-xs">to</span> ${this.customizedEndDate}`;
          this.currentTitleSelected = `Results for ${getActiveView.displayName} ${this.currentType.replace(/\b\w/g, l => l.toUpperCase())} <br><span class="font-medium text-sm">${this.customizedStartDate} <span class="font-light text-xs">to</span> ${this.customizedEndDate}</span>`;
          this.currentModelSelected = `${this.currentModelNameUpper}<br class="hidden sm:block"> <span class="font-medium text-sm">${duration}</span> ${this.currentModelNameLower}`;
        }
        if(this.hourlySelected == 'hourly'){
          this.currentTitleSelected = this.currentTitleSelected + `<span class="font-medium text-sm"><span class="font-light text-xs"> between </span>${this.hourstoname[this.startHours]}<span class="font-light text-xs"> and </span>${this.hourstoname[this.endHours]}</span`;
        }
      }
    }

    fetchOverviewData() {
      this.loader.display(true);
      let data = JSON.stringify({
        eventId: this.eventId,
        username: this.username
      });
      this.enquiriesService
        .getOverviewData(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            this.desableloader = false
            this.loader.display(false);
            let dd = JSON.parse(JSON.stringify(data));
            let successData = dd;
            this.overviewData = successData.data;
            this.eventDetails = successData.data.event;
            let enddate = moment(this.eventDetails.endDate, 'DD-MM-YYYY');
            let currentdate = moment(new Date().getDate()+'/'+(new Date().getMonth() + 1)+'/'+new Date().getFullYear(), 'DD-MM-YYYY');
            let endDtObj = new Date(JSON.parse(JSON.stringify(enddate)));
            let currDtObj = new Date(JSON.parse(JSON.stringify(currentdate)));
            this.eventDetails.dayasdiff = (endDtObj.getTime()-currDtObj.getTime())/ (1000 * 3600 * 24)
            this.title = this.eventDetails.event_name?this.eventDetails.event_name:this.eventDetails.locationName;
            //if ((this.eventDetails.country != "GB" && this.eventDetails.country != "US") && this.eventDetails.dayasdiff > 730 && this.type =="stats") {
            if (this.eventDetails.country != "GB" && this.eventDetails.dayasdiff > 730 && this.type =="stats") {
              this.modelnameonly='Standard';
            }
            this.getEventDetails();
            this.getHurricaneName();
            this.selectedTypes = this.getSelectedTypesInTheEvent(this.overviewData);
            this.onClickTypeWeather(this.selectedTypes[0]);
            // this.getEvents();
            // this.getModelsData();
          },
          error => {
            this.loader.display(false);
            // let errordata = error;
            // let errObj: any = {};
            // (errObj.code = error.status),
            //   (errObj.eventId = sessionStorage.getItem("eventId")),
            //   (errObj.url = "insight/getoverview"),
            //   (errObj.frontUrl = "/insight-overview"),
            //   (errObj.apiBody = { username: this.userName, eventId: eventId });
            // if (error.status == 404 || error.status == 500) {
            //   this.callErrorMail(errObj);
            // }
            // if (errordata.error == "auth required") {
            //   this.router.navigate(["/login"]);
            // }
            // this.getPopUpVal(true);
          });
    }

    extractLocationData() {
      // this.getHurricaneName();
      if ( this.eventDetails.startDate != undefined  && this.eventDetails.endDate != undefined && this.eventDetails.locationName != undefined ) {
          let requestData = JSON.stringify({
              country: this.eventDetails.country,
              postCode: this.eventDetails.postcode,
              username: this.username,
              state: this.eventDetails.state,
              countryName: this.eventDetails.countryName,
              uri: '/stats_data/get_c_station',
              lat: this.eventDetails.latitude,
              lon: this.eventDetails.longitude,
              delphiRec: 1,
              Start_Date: this.eventDetails.startDate,
              End_Date: this.eventDetails.endDate,
              categories: this.hurricaneCategories,
              weathers: ""
          });
          this.newenquiryService.getCStationLite(requestData).subscribe((data)=> {
              let weathercount = this.makeWeatherArray(data.keys);
              let riskcount = this.getRiskArray(data.Risk);  
              this.countselectedwtr = weathercount.length+riskcount.length;
          },

          (error) => {
          }
          
          
          );
      }
    }

    makeWeatherArray(weatherKeys) {
      let len = weatherKeys.length;
      let weatherarr =[];
      if ( len > 0) {
          for ( let i=0;i<len;i++) {
            weatherarr.push(weatherKeys[i].itemName);
          }
      }
      return weatherarr;
    }

    getRiskArray(riskObject) {
        let availblRiskArr = [];
        Object.keys(riskObject).forEach(function(key){
            let value = riskObject[key];
            if ( value == 1 ) {
                availblRiskArr.push(key);
            }
        });
        return availblRiskArr;
    }

    async getRiskJSON() {
      let res = await this.newenquiryService.getJSON();
      let strData = JSON.stringify(res);
      this.countryWiseHurricaneName = JSON.parse(strData);
    }

    getHurricaneName() {
        let len = this.countryWiseHurricaneName.length;
        for ( let i=0;i<len;i++ ) {
          if ( this.countryWiseHurricaneName[i].code == this.eventDetails.country ) {
            this.hurricaneTitle = this.countryWiseHurricaneName[i].title;
            this.hurricaneCategories = this.countryWiseHurricaneName[i].category.split(',');
            this.hurricaneCategoryLabel = this.countryWiseHurricaneName[i].categoryLabel.split(",");
          }
        }
    }

    getEventDetails() {
      this.customizedStartDate = this.dateStartInput = this.searchState.datestart = this.eventDetails.startDate //moment(, 'YYYY-MM-dD').format('DD/MM/YYYY')
      this.customizedEndDate = this.dateEndInput = this.searchState.dateend   = this.eventDetails.endDate 
      let eventStartDate = moment(this.eventDetails.startDate, 'DD/MM/YYYY');
      // Event started month and Year
      this.eventStartedMonth = Number(eventStartDate.format('M'));
      this.eventStartedYear = Number(eventStartDate.format('YYYY'));
      this.setTotalNumberOfMonths();
      this.checkIf12MonthsCompleted();
      // Event started month and Year
      this.numberInsights = this.eventDetails.totalInsight;
      this.searchState.location.place_name = this.eventDetails.locationName
      this.searchState.location.postcode = this.eventDetails.postcode
      this.searchState.location.latitude = this.eventDetails.latitude
      this.searchState.location.longitude = this.eventDetails.longitude
      this.searchState.location.state = this.eventDetails.state
      this.searchState.location.countryName = this.eventDetails.countryName
      this.searchState.location.countryCode = this.eventDetails.country
    }

    setTotalNumberOfMonths() {
      let d = new Date();
      let currentMonth: any = d.getMonth() + 1;
      let currentYear: any = d.getFullYear();
      this.totalMonths = (this.eventStartedYear - currentYear) * 12 + (this.eventStartedMonth - currentMonth + 1);
      this.setPredFrequency();
    }

    setPredFrequency() {
      this.predFrequency = this.commonFrequency;
      //this.predRainMonthlyFrequency = [{ name: "Monthly", dispName: "Month", active: 1 }];
      if (this.totalMonths < 61) {
        this.commonFrequency = [
          { name: "Weekly", dispName: "Week", active: 1 },
          { name: "Monthly", dispName: "Month", active: 0 }
        ];
        this.predFrequency = this.commonFrequency;
      } else {
        this.commonFrequency = [{ name: "Monthly", dispName: "Month", active: 1 }];
        this.predFrequency = this.commonFrequency;
        // sessionStorage.setItem("graphfreq", this.predFrequency[0].name);
      }
      //this.predRainMonthlyFrequency = [{ name: "Monthly", dispName: "Month", active: 1 }];
      // let graphfreq = sessionStorage.getItem("graphfreq");
      // if (graphfreq) {
      //   for (let i = 0; i < this.predFrequency.length; i++) {
      //     if (this.predFrequency[i].name == graphfreq) {
      //       this.freqidx = i;
      //       break;
      //     }
      //   }
      // } else {
      //   this.freqidx = 0;
      // }
      this.currFreq = this.predFrequency[0];
      // if ( this.currFreq != undefined )
      //   sessionStorage.setItem("graphfreq", this.currFreq.name);
    }

    getSelectedTypesInTheEvent(data) {
      let availableItems = [];
      if ( data != '' ) {
        Object.keys(data).forEach(function(key){
            if ( key != 'event' && key != 'risk' && key != 'isRiskModelPurchased' )
              availableItems.push(key);
        });
        if ( data.risk != null ) {
          Object.keys(data.risk).forEach(function(key){
              if ( data.risk[key].risk_name != 'volcanoes' )
                availableItems.push(data.risk[key].risk_name);
          });
        }
      }
      return availableItems;
    }

    onClickTypeWeather(weatherType){
      this.showHideRisk = false;
      this.hourlySelected = 'daily';
      this.isHourlyAvailable = false;
      this.showAdvancedType = 'graph';
      if ( weatherType == 'hurricane' || weatherType == 'tornado' || weatherType == 'earthquake' || weatherType == 'wildfire' ) {
        if(weatherType != 'hurricane'){
          this.hurricaneCurrentModelName = '';
        }
        this.type = 'risk';
        this.riskindex = 0;
        this.riskGraphPage = 1;
        this.currentType = weatherType;
        let riskLen = this.overviewData.risk.length;
        if ( riskLen == 1 ) {
          this.currRiskData = this.overviewData.risk[0];
        } else {
          for ( let r=0;r<riskLen;r++ ) {
            if ( this.currentType == this.overviewData.risk[r].risk_name ) {
              this.currRiskData = this.overviewData.risk[r];
              break;
            }
          }
        }
         
        this.currentTitleSelected = `Standard Risk ${this.riskYear} results for ${this.currentType.replace(/\b\w/g, l => l.toUpperCase())} <span class="font-light text-xs">-</span> ${this.searchState.datestart} <span class="font-light text-xs">to</span> ${this.searchState.dateend}`;
        if ( this.currentType == 'hurricane' && this.hurricaneTitle != undefined )
          this.currentTitleSelected = `Standard Risk ${this.riskYear} results for ${this.hurricaneTitle.replace(/\b\w/g, l => l.toUpperCase())} <span class="font-light text-xs">-</span> ${this.searchState.datestart} <span class="font-light text-xs">to</span> ${this.searchState.dateend}`;  
        
        this.currRiskData.mode.sort((a,b)=>  b.id -a.id );
        this.onRiskModeClick(this.currRiskData.mode[0]);
        this.onRiskYearClick(this.currRiskData.year[0]);
        this.overviewRiskData();
        this.getRiskGraph();
      } else {
        this.currentType = weatherType
        this.curentWeatherData = this.overviewData[weatherType];
        console.log(this.curentWeatherData);
        this.weatherUnitData = this.curentWeatherData.unit 
        this.currentWeatherUnitData = weatherType;
        this.currentWeatherUnit = weatherType.name;
        this.currentWeatherUnitSlug = weatherType.slug;
        let weatherModelLen = this.curentWeatherData.length;
        if ( this.currentType == "fog" || this.currentType == "hail" || this.currentType == "dust" || this.currentType == "storm" || this.currentType == "snow" ) {
          this.curentWeatherData.sort((a,b)=>  b.id -a.id );
        }
        //if (this.eventDetails.country != "GB" && this.eventDetails.country != "US") {
        if (this.eventDetails.country != "GB") {
          if(this.curentWeatherData[0].model_name=='Standard' && this.eventDetails.dayasdiff <= 730 && this.type=='stats'){
            let temp = this.curentWeatherData[0];
            this.curentWeatherData[0]=this.curentWeatherData[1];
            this.curentWeatherData[1]=temp;
          }
        }else{
          if(this.curentWeatherData[0].model_name=='Standard'){
            let temp = this.curentWeatherData[0];
            this.curentWeatherData[0]=this.curentWeatherData[1];
            this.curentWeatherData[1]=temp;
          }
        }
        
        let modelIndex=0;
        let previoulySelectedModel = sessionStorage.getItem('modelName');
        //if ((this.eventDetails.country != "GB" && this.eventDetails.country != "US" ) && this.eventDetails.dayasdiff > 730 && this.type=="stats") {
        if (this.eventDetails.country != "GB" && this.eventDetails.dayasdiff > 730 && this.type=="stats") {
          previoulySelectedModel = "Standard"
        }
        if ( previoulySelectedModel ) {
          for ( let m=0;m<weatherModelLen;m++ ) {
            if ( this.curentWeatherData[m].model_name == previoulySelectedModel ) {
              modelIndex = m;
              break;
            }
          }
        }

        let heads = this.curentWeatherData[modelIndex].weathers
        let headLen = heads.length;
        for ( let h=0;h<headLen;h++ ) {
          if(["GB","US"].includes(this.eventDetails.country)){
            if(heads[h].name == "hourly" || heads[h].name == "hourly_speed"){
              if(heads[h].stationId){
                this.isHourlyAvailable = true
              }    
            }
          }
        }

        // this.changeModelData(this.curentWeatherData[0]);
        this.changeModelData(this.curentWeatherData[modelIndex]);
        
        setTimeout(() => {this.reloadTooltips()
        // this.getEvents();
        // this.getStatisticalGraph();
        }, 300)
      }
    }
    modeldatashow(model) {
      //if ((this.eventDetails.country != "GB" && this.eventDetails.country != "US") && this.eventDetails.dayasdiff > 730 && this.type=="stats") {
      if (this.eventDetails.country != "GB" && this.eventDetails.dayasdiff > 730 && this.type=="stats") {
        return model[0];
      } else {
        return model;
      }
    }
    changeModelDataHurricane(model) {
      this.showHideDropdownHurricane = false;
      this.hurricaneCurrentModelName = model;
      if(this.hurricaneCurrentModelName =='Advanced Risk'){
        this.showAdvancedType = 'graph';
        this.onRiskYearClick(this.currRiskData.year[0]);
        this.modifyHeading();
        this.currentTitleSelected = this.currentTitleSelected.replace('Standard', 'Advanced')
      } else {
        this.onRiskModeClick(this.currRiskData.mode[0]);
        this.onRiskYearClick(this.currRiskData.year[0]);
        this.currentTitleSelected = this.currentTitleSelected.replace('Advanced','Standard')
      }
      this.overviewRiskData();
      this.getRiskGraph();
    }
    changeModelData(model) {
      const suffixes = { 'Advanced': 'Predictive', 'Standard': 'Historical' };
      this.hourlypage = 1
      this.showHideDropdown = false;
      this.type = model.slug;
      this.modelType = model.mType;
      this.currentModelData = model;
      this.currentUnitData = model.unit;
      //this.currentModelName = model.model_type+' '+model.model_name;
      this.currentModelName = model.model_name+' '+suffixes[model.model_name];
      this.currentModelNameHead = model.model_type+': '+model.model_name;
      this.currentModelNameUpper = `${model.model_name} ${suffixes[model.model_name] || ''}`;
      this.currentModelNameLower = model.model_name === "Advanced" ? '<span class="text-xs font-light align-middle">Powered by</span>  <span class="font-claros text-secondarydarker align-middle"> Claros</span>' : '';
      this.modelnameonly = model.model_name;
      sessionStorage.setItem('modelName', model.model_name);
      let modelLen = model.weathers.length;
      if ( modelLen > 0 ) {
        for ( let i=0;i<modelLen;i++ ) {
          if ( i == 0 ) {
            model.weathers[i]['active'] = 1;
          } else {
            model.weathers[i]['active'] = 0;
          }
        }
      }
      //this.removeSpecificWeatherVarTypes(model);
      this.head = model.weathers;

      // Set last selected weather var type starts here
      let weatherId = this.currentModelData.weather_type_id;
      let selectedItemsData = JSON.parse(sessionStorage.getItem('selectedItems'));
      if (this.hourlySelected == 'hourly' && ['GB','US'].includes(this.eventDetails.country)){
        selectedItemsData = JSON.parse(sessionStorage.getItem('selectedItemshourly'));
      } 
      
      let headLen = this.head.length;
      if ( selectedItemsData != undefined && selectedItemsData != null && weatherId != 4 && weatherId != 5 && weatherId != 6 && weatherId != 7 && weatherId != 8 ) {
        let isExists = this.checkIfWeatherTypeExistsInStorageData(selectedItemsData);
        if ( isExists ) {
          let selectedItemLen = selectedItemsData.length;
          let savedHead = '';
          for ( let i=0;i<selectedItemLen;i++ ) {
            if ( selectedItemsData[i].weatherType == this.currentType ) {
              savedHead = selectedItemsData[i].headName;
              break;
            } 
          }
          
          for ( let h=0;h<headLen;h++ ) {
            if ( this.head[h].name == savedHead ) {
              this.onHeadSelectClick(this.head[h]);
              break;
            } else {
              if(this.hourlySelected == 'hourly'){
                console.log(this.head[h].name == "hourly",h)
                if(this.head[h].name == "hourly"|| this.head[h].name == "hourly_speed" || this.head[h].name == "hourly_gust"){
                  this.onHeadSelectClick(this.head[h]);
                }
              }else{
                this.onHeadSelectClick(this.head[0]);
              }
            }
          }
        } else {
          for ( let h=0;h<headLen;h++ ) {
            if(this.hourlySelected == 'hourly'){
              if(this.head[h].name == "hourly"|| this.head[h].name == "hourly_speed"){
                this.onHeadSelectClick(this.head[h]);
              }
            }else{
              this.onHeadSelectClick(this.head[0]);
            }
          }
        }
      

      } else {
        if(weatherId != 4 || weatherId != 5 && weatherId != 6 && weatherId != 7 && weatherId != 8){
          for ( let h=0;h<headLen;h++ ) {
            if(this.hourlySelected == 'hourly'){
              if(this.head[h].name == "hourly" || this.head[h].name == "hourly_speed"){
                this.onHeadSelectClick(this.head[h]);
              }
            }else{
              this.onHeadSelectClick(this.head[0]);
            }
          }
        } else{
          if(this.hourlySelected == 'hourly'){
            this.onHeadSelectClick(this.head[1]);
          } else {
            this.onHeadSelectClick(this.head[0]);
          }
        }
      }
      // Set last selected weather var type starts here

      // this.onHeadSelectClick(this.head[0]);

      let durationIndex = 0;
      let durationLen = model.duration.length;
      let savedDurationName = sessionStorage.getItem('durationName');
      if ( savedDurationName ) {
        for ( let du=0;du<durationLen;du++ ) {
          if ( model.duration[du].name == savedDurationName ) {
            durationIndex = du;
            break;
          }
        }
      }
      this.onAmountDataClick(model.duration[durationIndex]);

      setTimeout(()=>{this.getThresholds()},200);
      // this.getEvents();
      //this.getModelsData(); 
    }

    onAmountDataClick(select:any,type=0): void {
      this.hourlypage = 1
      this.curentDurationData = select;
      this.amountData = select.name;
      console.log(this.amountData)
      this.currentPredicPage=1;
      this.modifyHeading();
      sessionStorage.setItem('durationName', this.amountData);
      if (type) {
        if (this.type=='stats') {
          this.getDataOverview(); 
          this.lastFiveYearData();
        } else if (this.type=='predict') {
          this.overviewPredictData();
          this.getPredictionGraph();
          this.getEvents();
        } 
      } 
      // if(type){
      //   this.getThresholds();
      // }
    }

    onFreqSelectClick(frequency) {
      this.currentPredicPage = 1;
      let name = frequency.name;
      map(this.predFrequency, freq => {
        freq.active = freq.name === name
      })
      this.currFreq = frequency;
      // sessionStorage.setItem("graphfreq", frequency.name);
      this.overviewPredictData();
      this.getPredictionGraph();
      this.getEvents();	
    }

    // onChangeViewReportType(changeTo: Object, instance: any): void {
    //   this.currentModelData = changeTo
    //   let modelName = changeTo.model_type+' '+changeTo.model_name
    //   this.showReportType = modelName
    //   // instance.hide()
    // }

    onHeadSelectClick(select:any,type=0): void {
      this.graphpage = 1;
      this.currentPredicPage=1;
      console.log(this.currentType);
      if(this.graphpage==1){
        console.log(this.currentModelData);
      }
      // let weatherType = this.currentType;
      console.log(this.currentModelData);
      console.log(select);
      let weatherId = this.currentModelData.weather_type_id;
      if (  weatherId == 1 || weatherId == 2 || weatherId == 3 ) {
        let savedSelectedItem = JSON.parse(sessionStorage.getItem('selectedItems'));
        if (this.hourlySelected == 'hourly' && ['GB','US'].includes(this.eventDetails.country)){
          savedSelectedItem = JSON.parse(sessionStorage.getItem('selectedItemshourly'));
        }
        
        if ( savedSelectedItem == undefined || savedSelectedItem == null )
          savedSelectedItem = [];
          let x =this;
          savedSelectedItem.forEach(function(item, ind) {
            if ( item.weatherType === x.currentType ) {
              savedSelectedItem.splice(ind, 1);
            }
          });
          
        let item = {};
        item = {
          "weatherType": this.currentType,
          "modelName": this.currentModelData.model_name,
          "modelType": this.currentModelData.model_type,
          "headName": select.name
        }
        savedSelectedItem.push(item);
        if(this.hourlySelected == 'hourly' && ['GB','US'].includes(this.eventDetails.country)){
          sessionStorage.setItem('selectedItemshourly',JSON.stringify(savedSelectedItem));
        }else{
          sessionStorage.setItem('selectedItems', JSON.stringify(savedSelectedItem));
        }
        
      }
      
      if ( this.type == 'predict' ) {
        if ( select.name == "monthly" && weatherId == 1 ) {
          this.predFrequency = this.predRainMonthlyFrequency; //if predict, and monthly rain->remove weekly from frequency drop-down
          this.currFreq = this.predFrequency[0];
        } else {
          this.predFrequency = this.commonFrequency;
          if (this.completedTweleveMonths) {
            for (let fr = 0; fr < this.commonFrequency.length; fr++) {
              if (this.commonFrequency[fr].name == "Weekly") {
                this.commonFrequency.splice(fr, 1);
              }
            }
            this.predFrequency = this.commonFrequency;
            this.currFreq = this.predFrequency[0];
            this.currFreq.active = 1;
          } else if (this.totalMonths < 61) {
            this.commonFrequency = [
              { name: "Weekly", dispName: "Week", active: 1 },
              { name: "Monthly", dispName: "Month", active: 0 }
            ];
            this.predFrequency = this.commonFrequency;
          } else {
            this.commonFrequency = [{ name: "Monthly", dispName: "Month", active: 1 }];
            this.predFrequency = this.commonFrequency;
          }
          this.currFreq = this.predFrequency[0];
        }
      }
      
      this.curentWeatherVarData = select;
      this.curentWeatherVarName = select.name;
      this.curentWeatherVarDisplayName = select.displayName;
      let selected = select.displayName;
      let currentWeatherId = select.weathertypeid;
      map(this.head, item => {
        item.active = item.displayName === selected
      })
      const getActiveView = first(filter(this.head, item => item.active))
      let duration = this.modelnameonly=='Standard'?this.amountData:'';
      if(this.currentType=='hurricane' || this.currentType=='tornado'){
        this.modelDefText=`<p>Historical impacts are aggregated and reported as the risk of at least one occurring within the week(s) of your enquiry. Tropical cyclone hits are graded according to locally accepted classifications (e.g. <span>Saffir-Simpson Scale</span> in the US), while the <span>Enhanced Fujita Scale</span> is applied to tornadoes.</p>
        <p> Tropical cyclone data extends back to between 1857 and 1949, depending on the region.</p> 
        <p>US Tornado data reaches back to 1950.</p>
        <p> Such long datasets warrant a high level of confidence to your risk assessment.</p>`
      } else if(this.currentType=='earthquake'){
        this.modelDefText=`<p>Aggregated across all historical years and reported as the risk of at least one occurring within the week(s) relating to your enquiry. The severity is graded according to the <span>Moment Magnitude Scale</span>.</p>
        <p>Data extends back to 2150 BC and is filtered to ‘significant’ earthquakes: Magnitude 4 and above.</p>`
      } else if(this.currentType=='wildfire'){
          this.modelDefText=`<p>Risk values have been estimated worldwide using a model derived from the relation of historical US wildfires of at least 10 acres in size, to weather conditions and topographical factors.</p>
          <p>This risk value represents the likelihood of an existing fire developing into a wildfire event (at least 10 acres). This does not provide any estimation of the risk of a fire starting, only of a localised fire developing into a wildfire.</p>`
      } else if(this.modelnameonly=='Standard'){
          this.modelDefText=`<p>Our statistical models analyse historical data to ascertain the risk that conditions cross your moderate or severe impact thresholds. They also determine the lowest, mean, and highest observed values.</p>
          <p> In standard guise, all available location-specific historical data is assessed. This provides a ‘climate baseline’, which is useful for establishing the typical range of conditions but has relatively limited predictive value.</p>`
      } else if(this.modelnameonly=='Advanced'){ 
          this.modelDefText=`<p>The advanced version weights the analysis toward ‘most useful’ historical years, determined through assessment of large-scale phenomena that drive global weather patterns.</p>
          <p>Specialist climatological research has been employed to create a model that has proven skill out to long lead times:</p>
          <p>“In May 2020, testing found that out to two year’s lead time, our Advanced Statistical model predictions are <b>more accurate overall for 98% of 153 countries</b> when tested against a 2000-2020 average for predictions initialised in each month of 2001-2019”.</p>
           <p>This provides you with a more accurate risk perception, when compared to the standard model.</p>`
      } else if(this.modelnameonly=='AI'){ 
          this.modelDefText=`<p>MetSwift have developed a distinctive artificial intelligence that understands hyperlocal weather behaviour and how it relates to both climate trends and shorter-term cycles.</p>
           <p>This continually learning neutral network has been developed as a combined effort by meteorologists, data practitioners, and business professionals. Through self-assessment, it repeatedly refines itself until the best performance is achieved.</p>
            <p>Predictions are available on a weekly basis out to 52 weeks ahead, and monthly basis out to 60 months.</p>`
      } else if(this.modelnameonly=='Advanced AI'){ 
          this.modelDefText=`<p>Here, the Advanced AI model meets the research underpinning our Advanced Statistical model. With this additional information, predictions achieve even greater accuracy.</p>`
      }
      if ( currentWeatherId == 2 ) {
        this.currentTitleSelected = `${this.currentModelNameHead} ${duration}  results for ${this.currentType.replace(/\b\w/g, l => l.toUpperCase())} ${getActiveView.displayName} <span class="font-light text-xs">-</span> ${this.searchState.datestart} <span class="font-light text-xs">to</span> ${this.searchState.dateend}`;
        this.currentWeatherWithVarType = this.currentType.replace(/\b\w/g, l => l.toUpperCase())+'_'+getActiveView.displayName;
      } else if ( currentWeatherId == 3 ) {
        this.currentTitleSelected = ` ${this.currentModelNameHead} ${duration}  results for ${getActiveView.displayName} ${this.currentType.replace(/\b\w/g, l => l.toUpperCase())} <span class="font-light text-xs">-</span> ${this.searchState.datestart} <span class="font-light text-xs">to</span> ${this.searchState.dateend}`;
        this.currentWeatherWithVarType = getActiveView.displayName+'_'+this.currentType.replace(/\b\w/g, l => l.toUpperCase());
      } else if ( currentWeatherId == 4 ) {
        this.currentTitleSelected = `${this.currentModelNameHead} ${duration}  results for ${this.currentType.replace(/\b\w/g, l => l.toUpperCase())} ${getActiveView.displayName} <span class="font-light text-xs">-</span> ${this.searchState.datestart} <span class="font-light text-xs">to</span> ${this.searchState.dateend}`;
        this.currentWeatherWithVarType = this.currentType.replace(/\b\w/g, l => l.toUpperCase())+'_'+getActiveView.displayName;
      } else {
        this.currentTitleSelected = `${this.currentModelNameHead} ${duration}  results for ${getActiveView.displayName} ${this.currentType.replace(/\b\w/g, l => l.toUpperCase())} <span class="font-light text-xs">-</span> ${this.searchState.datestart} <span class="font-light text-xs">to</span> ${this.searchState.dateend}`;
        this.currentWeatherWithVarType = getActiveView.displayName+'_'+this.currentType.replace(/\b\w/g, l => l.toUpperCase());
      }
      if(this.hourlySelected == 'hourly'){
        this.currentTitleSelected = this.currentTitleSelected + ' <span class="font-light text-xs">between</span> '+ this.hourstoname[this.startHours]+' <span class="font-light text-xs">and</span> '+this.hourstoname[this.endHours]
      }
      if(type){        
        this.getThresholds();
      }

      // this.getThresholds();
      
    }
    
    getThresholds(errsh = null) {
      // this.loader.display(true);
      this.searchState.thresholdData = this.getThresholdsData = '';
      let data = JSON.stringify({
        "username": this.username,
        "eventId": this.eventId,
        "weatherType": this.curentWeatherData[0].weather_type_id,
        "unit": this.curentWeatherVarData.unit,
        "weatherVarType":this.curentWeatherVarData.id
        });
      this.enquiriesService
        .getThresholdsData(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            this.loader.display(false);
            // if(this.desableloader){
            //   this.loader.display(false);
            // }            
            let dd = JSON.parse(JSON.stringify(data));
            // Format integer thresholds to 1 decimal place in case of rain-inches, 2 decimal place in wind-m/s
            if ( this.curentWeatherData[0].weather_type_id == 1 && dd.unit == 'inches' ) {
              if (Number.isInteger(dd.alert))
                dd.alert = dd.alert.toFixed(1);
              if (Number.isInteger(dd.severe)) 
                dd.severe = dd.severe.toFixed(1);
            } else if ( this.curentWeatherData[0].weather_type_id == 2 && dd.unit == 'm/s' ) {
              if (Number.isInteger(dd.alert))
                dd.alert = dd.alert.toFixed(2);
              if (Number.isInteger(dd.severe)) 
                dd.severe = dd.severe.toFixed(2);
            }

            this.searchState.thresholdData = this.getThresholdsData = dd;
            this.searchState.thresholdData.weatherName  = this.currentType; 
            this.getThresholdsData.unitData = this.currentUnitData;
            this.getThresholdsData.weatherVarType = this.curentWeatherVarData.name;
            this.getThresholdsData.weatherVarTypeId = this.curentWeatherVarData.id;
            this.getThresholdsData.WeatherTypeId = this.curentWeatherData[0].weather_type_id;
            this.getThresholdsData.username = this.username; 
            this.getThresholdsData.eventId = this.eventId;

            // if(errsh){
              
            // } else{
            //   this.getDataOverview();
            // }
            if ( this.type == "stats" ) {
              this.getDataOverview(); 
              this.lastFiveYearData();
            } else if ( this.type == "predict" ) {
              // Get overview details for the Prediction models
              this.overviewPredictData();
              this.getPredictionGraph();
              this.getEvents();
            }
          },
          error => {
            this.loader.display(false);
            // let errordata = error;
            // let errObj: any = {};
            // (errObj.code = error.status),
            //   (errObj.eventId = sessionStorage.getItem("eventId")),
            //   (errObj.url = "insight/getoverview"),
            //   (errObj.frontUrl = "/insight-overview"),
            //   (errObj.apiBody = { username: this.userName, eventId: eventId });
            // if (error.status == 404 || error.status == 500) {
            //   this.callErrorMail(errObj);
            // }
            // if (errordata.error == "auth required") {
            //   this.router.navigate(["/login"]);
            // }
            // this.getPopUpVal(true);
          });
    }

    getPythonOverviewDataWithUpdatedThresholds(updatedThreshData) {  
      // this.LS.logOut({
      //   title:
      //     "Graph - (" +
      //     (this.eventName ? this.eventName + " " : "") +
      //     this.locationName +
      //     ") - " +
      //     'Save Overview' +
      //     " Threshold",
      //   description:
      //     "Alert:" +
      //     lowerMin +
      //     ", Severe:" +
      //     lowerMax +
      //     ", Unit:" +
      //     this.weatherunit,
      //   link: "#/insight-overview",
      //   eventId: eventId,
      //   modelId: sessionStorage.getItem('modelid')
      // }).pipe(takeUntil(this.destroy$)).subscribe(data => {}, error => {});
      if(updatedThreshData =="sameval"){
        this.errorMsg = "Please make sure that the limits you have set for Threshold 1 and Threshold 2 are different";
        this.isError =true;
        this.getThresholds('sameval');
        return true;
      }
      this.searchState.thresholdData.alert = updatedThreshData.T1;
      this.searchState.thresholdData.severe = updatedThreshData.T2;
      this.searchState.thresholdData.orderItem = updatedThreshData.selectedOrderItem;
      if ( this.type == 'stats' ) {
        this.pageaccorddate(this.graphpage)
        this.getDataOverview(); 
        this.lastFiveYearData();
      } else if ( this.type == 'predict' ) {
        this.overviewPredictData();
        this.getPredictionGraph();
        this.getEvents();
      }
    }

    onRiskModeClick(mode, option?:any) {
      this.currRiskModeData = mode;
      this.riskMode = mode.itemName;
      if (option) {
        this.overviewRiskData();
        this.getRiskGraph();
      }
    }

    onRiskYearClick(year, option?:any) {
      this.currRiskYearData = year;
      this.riskYear = year.itemName;
      this.modifyHeading();
      if (option) {
        this.overviewRiskData();
        this.getRiskGraph();
      }
    }

    saveUnitConversionData(unitConversionData) {
      console.log('unitConversionData');
      console.log(unitConversionData);
    }

    convertOverviewThresholds(value, unit, isString = 0, weatherId) {
      let t = value;    
      if (unit == "miles/h") return t * 0.447;
      if (unit == "F") return ((t - 32) / 1.8);
      if (unit == "inches" && weatherId == 1) {
        return t * 25.4;
      }
      if (unit == "inches" && weatherId == 4) {
        return t * 2.54;
      }
      return value;
    }

    getDataOverview() {
      this.modifyHeading();
      this.showupdatebutton = false;
      console.log(this.intervallist)
      for( let i=0; i < this.intervallist.length; i++){
        clearInterval(this.intervallist[i]);
      }
      this.intervallist=[]
      this.loaderhtimeoverview = 0
      this.loaderhtimegraph = 0
      this.gethourlyloadertime()
      this.gethourlyloadertimegraph(20)
      // this.loader.display(true);
      this.overviewPythonData = '';
      let unit = this.searchState.thresholdData.unit;
      let t1 = this.convertOverviewThresholds(this.searchState.thresholdData.alert,unit,1,this.curentWeatherVarData.weathertypeid);
      let t2 = this.convertOverviewThresholds(this.searchState.thresholdData.severe,unit,1,this.curentWeatherVarData.weathertypeid);
      let overviewStartDate:any;
      let overviewEndDate:any;
      if ( this.searchState.datestart == this.customizedStartDate && this.searchState.dateend == this.customizedEndDate ) {
        overviewStartDate = this.searchState.datestart;
        overviewEndDate = this.searchState.dateend;
      } else {
        this.searchState.datestart = overviewStartDate = this.customizedStartDate;
        this.searchState.dateend = overviewEndDate = this.customizedEndDate;
      }
      let stationid  = this.curentWeatherVarData.stationId;
      let type = this.curentWeatherVarData.name;
      // if(this.hourlySelected=='hourly'){
      //   stationid = this.currentModelData.weathers[0].stationId
      //   let change = {"hourly":'daily',"temperature-hourly":'minimum',"snow-hourly":'depth',  "hourly_speed":'speed', "hourly_gust":'gust'}
      //   if(this.currentType == 'temperature' || this.currentType == 'snow'){
      //     type = this.currentType+"-"+'hourly'
      //   }
      //   type = change[type]
      // }
      let payload = {
        "weatherVarType":this.curentWeatherVarData.id,
        "Analogue": this.currentModelData.analogue,
        "StationID": stationid,
        "Country": this.eventDetails.country,
        "Start_Date": overviewStartDate,
        "End_Date": overviewEndDate,
        "Num_Years": ( this.currentModelData.model_name == 'Advanced' && ( this.curentWeatherData[0].weather_type_id == 5 || this.curentWeatherData[0].weather_type_id == 6 || this.curentWeatherData[0].weather_type_id == 7 || this.curentWeatherData[0].weather_type_id == 8 ) ) ? 'end' : this.curentDurationData.id ,
        "Wparam": this.currentType,
        "Type": type,
        "T1": t1,
        "T2": t2,
        "unit": unit,//this.curentWeatherVarData.unit,
        "uri":"/stats_data/overview_data",
        "username": this.userName
        }
        if(this.hourlySelected == 'hourly' && ['GB','US'].includes(this.eventDetails.country)){
          payload['hour_list'] = [this.startHours, this.endHours]
        }
        this.showloaderoverview = true;
        this.showloadergraph = true;
        let body = JSON.stringify(payload);
        this.LS.logOut({
          title:
            "Report Overview - Statistical - (" +
            (this.eventDetails.event_name ? this.eventDetails.event_name + " " : "") +
            this.eventDetails.locationName +
            ")",
          description:
            "Alert:" +
            this.searchState.thresholdData.alert +
            ", Severe:" +
            this.searchState.thresholdData.severe +
            ", Unit:" +
            unit +
            "<br/>" +
            this.currentModelData.model_name +
            ", StationId - " +
            this.curentWeatherVarData.stationId +
            ", " +
            this.currentType +
            ", " +
            this.curentWeatherVarName +
            ", Analogue =" +
            this.currentModelData.analogue +
            ", From " +
            overviewStartDate +
            " to " +
            overviewEndDate,
          link: "#/report",
          eventId: this.eventId,
          modelId: this.currentModelData.id
        }).pipe(takeUntil(this.destroy$)).subscribe(
          data => {},
          error => {
            this.router.navigate(["/login"]);
          }
        );
      this.enquiriesService
        .overviewData(body)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          data => {
            this.showloaderoverview = false;
            this.loaderhtimeoverview = 0
            // this.loader.display(false);
            this.desableloader == false
            let dd = JSON.parse(JSON.stringify(data));
            this.overviewPythonData = dd;
            //this.searchState.thresholdData = this.getThresholdsData;
            this.searchState.overviewData = dd;
            this.searchState.predOverviewData = "";
            this.searchState.type = this.type;
            this.stationNameAndHeight = dd.StationName+' at '+dd.elevation+'m above Sea Level';
              this.showGraph=false;
          this.graphpage = 1;
          this.getModelsData();
          this.getEvents();
          },
          error => {
            this.showloadergraph = false
            this.showloaderoverview = false;
            this.loader.display(false);
            this.isError = true;

            let errordata = error.error?error.error:error;
            this.errorMsg = errordata.error;
            if(this.hourlySelected=='hourly'){
              if(!errordata.error){
                this.errorMsg = "Connection Timed Out: Please use the calendar to reduce the duration of your event to 150 days or fewer to view detailed statistics";
              }
            }
            
          });
    }

    overviewPredictData() {
      if (navigator.onLine == true) {
        this.loader.display(true);
        this.overviewPythonData = '';
        
        // if (
        //   this.startDate != this.overviewStartDate ||
        //   this.endDate != this.overviewEndDate
        // ) {
        //   let ovrStDtObj = new Date(this.overviewStartDate);
        //   let ovrEnddtObj = new Date(this.overviewEndDate);
        //   let ovrStDt =
        //     ovrStDtObj.getDate() +
        //     "/" +
        //     (ovrStDtObj.getMonth() + 1) +
        //     "/" +
        //     ovrStDtObj.getFullYear();
        //   let ovrEnddt =
        //     ovrEnddtObj.getDate() +
        //     "/" +
        //     (ovrEnddtObj.getMonth() + 1) +
        //     "/" +
        //     ovrEnddtObj.getFullYear();
        //   this.stDt = ovrStDt;
        //   this.endDt = ovrEnddt;
        // }
        // this.currFreq = this.predFrequency[0];
        let unit = this.searchState.thresholdData.unit;
        let t1 = this.convertOverviewThresholds(this.searchState.thresholdData.alert,unit,1,this.curentWeatherVarData.weathertypeid);
        let t2 = this.convertOverviewThresholds(this.searchState.thresholdData.severe,unit,1,this.curentWeatherVarData.weathertypeid);
        let graphStartDate:any;
        let graphEndDate:any;
        if ( this.searchState.datestart == this.customizedStartDate && this.searchState.dateend == this.customizedEndDate ) {
          graphStartDate = this.searchState.datestart;
          graphEndDate = this.searchState.dateend;
        } else {
          this.searchState.datestart = graphStartDate = this.customizedStartDate;
          this.searchState.dateend = graphEndDate = this.customizedEndDate;
        }
        this.LS.logOut({
          title:
            "Report - Prediction - (" +
            (this.eventDetails.event_name ? this.eventDetails.event_name + " " : "") +
            this.eventDetails.locationName +
            ")",
          description:
            "Alert:" +
            this.searchState.thresholdData.alert +
            ", Severe:" +
            this.searchState.thresholdData.severe +
            ", Unit:" +
            unit +
            "<br/>" +
            this.currentModelData.mType +
            ", StationId - " +
            this.curentWeatherVarData.stationId +
            ", " +
            this.currFreq.name +
            ", " +
            this.currentType +
            ", From " +
            this.searchState.datestart +
            " to " +
            this.searchState.dateend,
          link: "#/report",
          eventId: this.eventId,
          modelId: this.currentModelData.id
        }).pipe(takeUntil(this.destroy$)).subscribe(
          data => {},
          error => {
            this.router.navigate(["/login"]);
            this.loader.display(false);
          }
        );
        this.enquiriesService.overviewPredictGet({
              StationID: this.curentWeatherVarData.stationId,
              Model: this.currentModelData.mType,
              PType: "LTF",
              Sdate: graphStartDate,
              Edate: graphEndDate,
              Wparam: this.currentType,
              Type: this.curentWeatherVarData.name,
              T1: t1,
              T2: t2,
              unit: unit,
              Freq: this.currFreq.name,
              height: 0,
              uri: '/predict_data/get_prediction_low_mean_high'
            }).pipe(takeUntil(this.destroy$))
            .subscribe(
              data => {
                // this.showPredDown = true;
                let dd = JSON.parse(JSON.stringify(data));
                let successData = dd;
                successData.data = this.getFilteredPredictionData(successData.data);
                this.searchState.type = this.type;
                this.searchState.overviewData = '';
                successData.data = this.preparePredictionData(successData.data);
                this.searchState.predOverviewData = successData;
                this.overviewPythonData = successData;
                
                // this.overviewDataResponse = successData;
                // this.overviewDataResponse.data = this.getFilteredPredictionData(this.overviewDataResponse.data);
                // this.overviewflag = true;
                this.loader.display(false);
                // setTimeout(() => {
                //   this.updateBorderColor(
                //     "predict"
                //   );
                // }, 700);
              },
              error => {
                this.loader.display(false);
                // this.showPredDown = false;
                // this.overviewDataResponse = null;
                // let errordata = error;
                // this.errorFound = true;
                // this.errorMsg = errordata.error.error;
                // this.loaderService.display(false);
                // let errObj: any = {};
                // (errObj.code = error.status),
                //   (errObj.eventId = sessionStorage.getItem("eventId")),
                //   (errObj.url = "predict_data/get_prediction_low_mean_high");
                // (errObj.frontUrl = "/insight-overview"),
                //   (errObj.apiBody = {
                //     StationID: data.stationId,
                //     Model: this.modelType,
                //     PType: "LTF",
                //     Sdate: this.stDt,
                //     Edate: this.endDt,
                //     Num_Years: this.durationId,
                //     Wparam: data.name,
                //     Type: data.type,
                //     T1: this.convertThresholds(this.alert, this.weatherunit, 1),
                //     T2: this.convertThresholds(this.severe, this.weatherunit, 1),
                //     unit: this.weatherunit,
                //     Freq: freq.name,
                //     height: 0
                //   });
                // if (error.status == 404 || error.status == 500) {
                //   this.callErrorMail(errObj);
                // }
                // this.getPopUpVal(true);
              }
            );
      } else {
        //this.connectionService.display(true);
        this.loader.display(false);
        // this.overviewDataResponse = null;
      }
    }

    preparePredictionData(data) {
      let len = data.length;
      if ( len > 0 ) {
        for ( let i=0;i<len;i++ ) {
          let predLen = data[i].prediction.length;
          for ( let j=0;j<predLen;j++ ) {
            data[i].prediction[j].value = this.convertUnit(data[i].prediction[j].value, this.searchState.thresholdData.unit, 0);
          }
          // data[i].predictionName = this.capitalizeWords(data[i].prediction[0].name);
          // data[i].predictionValue = this.convertUnit(data[i].prediction[0].value, this.searchState.thresholdData.unit, 0);
          data[i].predictionName = this.capitalizeWords(data[i].prediction[0].name);
          data[i].predictionValue = data[i].prediction[0].value;
        }
      }
      return data;
    }

    overviewRiskData() {
      this.modifyHeading();
      if (navigator.onLine == true) {
        this.loader.display(true);
        this.overviewRiskdataResponse = '';
        let overviewStartDate:any;
        let overviewEndDate:any;
        if ( this.searchState.datestart == this.customizedStartDate && this.searchState.dateend == this.customizedEndDate ) {
          overviewStartDate = this.searchState.datestart;
          overviewEndDate = this.searchState.dateend;
        } else {
          this.searchState.datestart = overviewStartDate = this.customizedStartDate;
          this.searchState.dateend = overviewEndDate = this.customizedEndDate;
        }
        // console.log(this.eventDetails);
        if(this.currRiskYearData.itemName){
          let dataalltype = this.currRiskYearData.itemName
        } else{
          let dataalltype ='';
        }
        this.LS.logOut({
          title:
            "Report - Risk - (" +
            (this.eventDetails.event_name ? this.eventDetails.event_name + " " : "") +
            this.eventDetails.locationName +
            ")",
          description:
            "Country:" +
            this.eventDetails.country +
            ", Postcode - " +
            this.eventDetails.postcode +
            ", " +
            this.currRiskData.itemName.toLowerCase() +
            " (" +
            this.currRiskModeData.itemName.toLowerCase() +
            ") " +
            this.currRiskYearData.itemName +
            " From " +
            overviewStartDate +
            " to " +
            this.overviewEndDate,
          link: "#/report",
          eventId: this.eventId,
          //modelId: this.currentModelData.id
        }).pipe(takeUntil(this.destroy$)).subscribe(
          data => {},
          error => {
            this.router.navigate(["/login"]);
          }
        );
        let key ={
          country: this.eventDetails.country,
          postcode: this.eventDetails.postcode,
          years: this.currRiskYearData.slug,
          causes: this.currRiskData.itemName.toLowerCase(),
          start_date: overviewStartDate,
          end_date: overviewEndDate,
          mode: this.currRiskModeData.itemName.toLowerCase(),
          lat: this.eventDetails.latitude,
          lon: this.eventDetails.longitude,
          categories: this.hurricaneCategories
        }
        if(this.currentType =='hurricane' && this.hurricaneCurrentModelName=='Advanced Risk'  && this.userSector==2){
          key['modeltype'] = 'advanced';
          key['years']="Ana";
          key['mode']="percentage"
          //this.currRiskModeData.itemName = 'Percentage';
        }
        this.enquiriesService
          .overviewRiskGet(key).pipe(takeUntil(this.destroy$))
          .subscribe(
            data => {
              //this.showRiskDown = true;
              let dd = JSON.parse(JSON.stringify(data));
              let successData = dd;
              this.loader.display(false);
              if (successData.data.length > 0) {
                // this.showRiskData = true;
                // this.errorFound = false;
                if ( this.currRiskData.itemName.toLowerCase() == 'hurricanes' && this.hurricaneCategoryLabel != undefined ) {
                  if ( successData.data.length == this.hurricaneCategoryLabel.length ) {
                    for ( let hurr=0;hurr<successData.data.length;hurr++ ) {
                      successData.data[hurr].title = this.hurricaneCategoryLabel[hurr];
                    }
                  }
                }
                this.overviewRiskdataResponse = successData.data;
              }


              // if (successData.data.length > 0) {
              //   this.showRiskData = true;
              //   this.errorFound = false;
              //   if ( this.riskName == 'Hurricanes' && this.hurricaneCategoryLabel != undefined ) {
              //     if ( successData.data.length == this.hurricaneCategoryLabel.length ) {
              //       for ( let hurr=0;hurr<successData.data.length;hurr++ ) {
              //         successData.data[hurr].title = this.hurricaneCategoryLabel[hurr];
              //       }
              //     }
              //   }
              //   this.overviewRiskdataResponse = successData.data;
              //   this.overviewDataResponse = successData.data;
              // } else {
              //   this.showRiskData = false;
              //   this.errorFound = true;
              //   this.errorMsg = "An error has occurred in fetching your "+this.riskName+" data. Our support team has been notified.";
              //   this.getPopUpVal(true);
              // }
              // this.overviewflag = false;
            },
            error => {
              this.loader.display(false);
              // this.showRiskDown = false;
              // this.errorFound = true;
              // this.showRiskData = false;
              // this.errorMsg = "An error has occurred in fetching your "+this.riskName+" data. Our support team has been notified.";
              // this.loaderService.display(false);
              // let errObj: any = {};
              // (errObj.code = error.status),
              //   (errObj.eventId = sessionStorage.getItem("eventId")),
              //   (errObj.url = "risk_data/get_risk_overview");
              // (errObj.frontUrl = "/insight-overview"),
              //   (errObj.apiBody = {
              //     country: this.overviewRes.event.country,
              //     postcode: this.overviewRes.event.postcode,
              //     years: this.riskYearSlug,
              //     causes: this.riskName.toLowerCase(),
              //     start_date: this.stDt,
              //     end_date: this.endDt,
              //     mode: this.riskModeName.toLowerCase()
              //   });
              // if (error.status == 404 || error.status == 500) {
              //   this.callErrorMail(errObj);
              // }
              // this.getPopUpVal(true);
            }
          );
      } else {
        // this.connectionService.display(true);
        this.loader.display(false);
        // this.showRiskData = false;
      }
    }

    capitalizeWords(str) {
      return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    }

    getFilteredPredictionData(data) {
      let len = data.length;
      if ( this.curentWeatherVarData.id == 3 || this.curentWeatherVarData.id == 7 ) {
        for ( let i=0;i<len;i++ ) {
          data[i].prediction.splice(0,2);
        }
      } else if ( this.curentWeatherVarData.id == 5 ) {
        for ( let i=0;i<len;i++ ) {
          data[i].prediction.splice(1,2);
        }
      } else if ( this.curentWeatherVarData.id == 6 ) {
        for ( let i=0;i<len;i++ ) {
          data[i].prediction.splice(0,1);
          data[i].prediction.splice(1,1);
        }
      }
      return data;
    }

    closeOverlay() {
      this.isError = false;
      //this.thresholdcomp.setpreviousvalue();
      
      this.notify.next(true);
    }

    closeOverlaynew() {
      this.closepopup = false;
      this.hourvalidate = false;
    }

    onChangeViewAdvanced(changeTo: string): void {
      // if ( ( this.type != 'risk' && changeTo == 'map' ) || ( this.currentType == 'wildfire' && ( changeTo == 'table' || changeTo == 'map' ) ) ) 
      //   return;
      this.showHideRisk = false;
      if(this.showAdvancedType != changeTo){
        this.showGraph=false;
        if(this.hourlySelected=="daily"){
          this.graphpage = 1;
        }
        this.currentPredicPage=1;
        if (this.type == 'stats') {
          this.showloadergraph = true
          this.loaderhtimegraph = 0
          this.gethourlyloadertimegraph(0)
          this.getStatisticalGraph();
        } else if (this.type == 'predict') {
          this.loader.display(true)
          this.getPredictionGraph();
        } else {
          this.loader.display(true)
          this.getRiskGraph();
        }
      }
      this.showAdvancedType = changeTo
      // instance.hide()
    }

    changeInsight(data) {
      // console.log(data)
    }

    onClickAddTypeWeather() {
      this.router.navigate(['/edit-report/'+this.eventId], { state: { searchResults: this.searchState, selectedTypes: this.selectedTypesObj } })
    }

    onChangedReportTitle(title){
      // Change title report here and save
      this.eventService
          .updateEventName({
            eventId: this.eventId,
            eventName: title,
            username: this.userName
          })
          .subscribe(
            data => {
              this.title = title
              this.eventDetails.event_name = title
              console.log(data)
            }
          )
    }

    reloadTooltips() {
      map(this.tooltipWeatherElem.toArray(), item => {
        if ( item.nativeElement.dataset.type == 'hurricane' )
          item.nativeElement.dataset.type = this.hurricaneTitle; 
          tippy(item.nativeElement, {
            allowHTML: true,
            theme: 'light-border',
            placement: 'bottom-start',
            arrow: true,
            animation: 'shift-away',
            interactive: true,
            content: `<div class="px-4 py-2 max-w-xs font-semibold text-primary text-sm">View ${item.nativeElement.dataset.type} report</div>`,
          })
      })
    }

    toogleDropdown(){
      this.showHideDropdown = !this.showHideDropdown;
    }

    toogleDropdownHurricane(){
      this.showHideDropdownHurricane = !this.showHideDropdownHurricane;
    }


    ngOnDestroy() {
      this.destroy$.next(true);
      this.destroy$.complete();
    }

    getEvents() {
      this.showcal=false;
      // this.loader.display(true);
        let sD = new Date(this.overviewStartDate);
        let eD = new Date(this.overviewEndDate);
        let stDt = sD.getDate() + "/" + (sD.getMonth() + 1) + "/" + sD.getFullYear();
        let endDt = eD.getDate() + "/" + (eD.getMonth() + 1) + "/" + eD.getFullYear();
        let ftype = ( this.currentType == 'snow' ) ? 'depth' : 'daily';

        let overviewStartDate:any;
        let overviewEndDate:any;
        if ( this.eventDetails.startDate == this.customizedStartDate && this.eventDetails.endDate == this.customizedEndDate ) {
          overviewStartDate = this.eventDetails.startDate;
          overviewEndDate = this.eventDetails.endDate;
        } else {
          overviewStartDate = this.customizedStartDate;
          overviewEndDate = this.customizedEndDate;
        }
        let stationid = this.curentWeatherVarData.stationId
        if(this.hourlySelected=='hourly'){
          stationid = this.currentModelData.weathers[0].stationId
        }
        this.eventService
          .fetchEvents({
            StationID: stationid,
            Analogue: this.currentModelData.analogue,
            Num_Years: this.curentDurationData.id,
            Wparam:this.currentType,
            Ftype: ftype,
            Type: this.curentWeatherVarData.name,
            unit: this.searchState.thresholdData.unit,
            startDate: overviewStartDate,
            endDate: overviewEndDate
          })
          .subscribe(
            data => {
              let dd = JSON.parse(JSON.stringify(data));
              let successData = dd;
              let monthlist=[];
              let yrvalstart = parseInt(this.searchState.datestart.split('/')[2]);
              let mnvalstart = parseInt(this.searchState.datestart.split('/')[1]);
              let yrvalend = parseInt(this.searchState.dateend.split('/')[2]);
              let mnvalend = parseInt(this.searchState.dateend.split('/')[1]);
              let strtmonth1 = [];
              let endmonth1 = [];
              if(yrvalend - yrvalstart == 0){
                for(let mn=mnvalstart;mn<=mnvalend;mn++){
                  monthlist.push(mn)
                }
              }
              if(yrvalend - yrvalstart ==1){
                for(let mn=mnvalstart;mn<=12;mn++){
                  strtmonth1.push(mn)
                }
                for(let mn=1;mn<=mnvalend;mn++){
                  endmonth1.push(mn)
                }
                
              }
              if(yrvalend - yrvalstart > 1){
                monthlist.push(1,2,3,4,5,6,7,8,9,10,11,12)
              }
              console.log(monthlist)
              //this.eventRes = successData.result;
              let curryear = new Date().getFullYear()
              if(curryear > parseInt(successData.result[successData.result.length-1].start.split('-')[0])){
                curryear -= 1;
              }
              let y = {};//{2017:[],2018:[],2019:[],2020:[],2021:[],2022:[]};
              for(let yr=curryear-5;yr<=curryear;yr++){
                  y[String(yr)] = [] 
              }
//              y[yr].push(successData.result[i])

              for(let i=0;i<successData.result.length;i++){
                if(yrvalend - yrvalstart ==1){
                  if((strtmonth1.length+endmonth1.length) < 12){
                    for(let yr=curryear;yr>=curryear-4;yr--){
                      if(strtmonth1.includes(parseInt(successData.result[i].start.split('-')[1])) && parseInt(successData.result[i].start.split('-')[0]) ==yr-1 ){
                        y[yr].push(successData.result[i])
                      }
                      if(endmonth1.includes(parseInt(successData.result[i].start.split('-')[1])) && parseInt(successData.result[i].start.split('-')[0]) == yr){
                          y[yr].push(successData.result[i])
                      }
                    }
                    
                  } else{
                      y[parseInt(successData.result[i].start.split('-')[0])].push(successData.result[i])
                  }
                } else {
                  if(monthlist.includes(parseInt(successData.result[i].start.split('-')[1]))){
                    y[parseInt(successData.result[i].start.split('-')[0])].push(successData.result[i])
                  }   
                }

              }
              for (var propName in y) {
                if (y[propName].length<1) {

                  delete y[propName];
                }
              }
              this.calmonths=monthlist;

              this.eventRes=y;
              
              console.log(y)
              
              this.showcal=true;
              if(this.desableloader){
                this.loader.display(false);
              }
            },
            error => {

              this.loader.display(false);
              let errordata = error;
              let errObj: any = {};
              this.eventRes = [];
              if (error.status == 404 || error.status == 500) {
                //this.callErrorMail(errObj);
              }
            }
          );
      }

//Graph data start from here

      getModelsData() {     
        // this.loader.display(true);
        let intialWeather = 'rain';
        let userName = this.userName;
        let eventid = this.eventId;
        //this.checkIf12MonthsCompleted();
        this.newenquiryService
          .getGraph({ username: userName, eventId: eventid })
          .subscribe(
            data => {
              let dd = JSON.parse(JSON.stringify(data));
              let successData = dd;
              // this.lat = successData.data.latitude;
              // this.lon = successData.data.longitude;
              // this.groupId = successData.data.groupId;
              this.graphModelRes = successData.data;
              // this.isRiskAvailable = this.graphModelRes.risk;
              
              if (this.type == "stats") {
                  sessionStorage.setItem("statpredictmodelid", "1");
                  this.workingHourValue = ( sessionStorage.getItem('workingHours') != undefined ) ? sessionStorage.getItem('workingHours') : 'All Hours';
                  this.statPredictData = this.graphModelRes.statistical;
                  this.shuffleIdsOfExtremeWeatherModels(this.statPredictData.weathers);
                  this.removeExpModelMoreThan24Months(this.statPredictData.weathers);
                  this.statPredictData.weathers = this.getAllWeathersWithWeathrVarType(this.statPredictData.weathers);
                  // //this.statPredictData.weathers = this.removeMeanHeadForTemp(this.statPredictData.weathers);
                  //this.graphPredic = false;
                  this.graphStatic = true;
                  //this.graphRisks = false;
              }
              let initialWeatherType = null;//sessionStorage.getItem("graphweatherType");
              let weatherheadid = sessionStorage.getItem("weatherheadid");
              if (this.type == "stats" || this.type == "predict") {
                if (initialWeatherType != null) {
                    let weatrLen = this.statPredictData.weathers.length;
                    for (let i = 0; i < weatrLen; i++) {
                    if ( this.statPredictData.weathers[i].weatherVarTypeName.toLowerCase() == initialWeatherType.toLowerCase() && this.statPredictData.weathers[i].weatherVarTypeId == weatherheadid ) {
                        this.idx = i;
                        break;
                    } else {
                        this.idx = 0;
                    }
                    }
                } else {
                    this.idx = 0;
                }
              this.onVariable(this.statPredictData.weathers[this.idx]);
              }
              //this.activedata = true;
            },
            error => {
              this.loader.display(false);
              let errordata = error;
              let errObj: any = {};
              (errObj.code = error.status),
                (errObj.eventId = sessionStorage.getItem("eventId")),
                (errObj.url = "insight/getgraphdata");
              (errObj.frontUrl = "/insightgraph"),
                (errObj.apiBody = { username: userName, eventId: eventid });
              if (error.status == 404 || error.status == 500) {
                //this.callErrorMail(errObj);
              }
            }
          );
    }

    onVariable(val) {
      
        this.graphInd = undefined;
        sessionStorage.setItem("weathername", val.name.toLowerCase());
        this.weatherId = val.id;
        // let initialWeatherType = sessionStorage.getItem("graphweatherType");
        // let index;
        this.variableName = val.name;
        // this.combinedVariableName = val.newCombinedName;
        // this.outputData = val;
        this.graphObj.StationID = val.stationId;
        this.onOutput(val);
    }

    onOutput(val) {
      if (val.weatherVarTypeId == 8) {
        //this.frequency = [{ id: 2, name: "Monthly" }];
        //this.freqFlag = true;
      } else {
        // if ( this.totalMonths < 61 && !this.completedTweleveMonths ) {
        //   this.frequency = [
        //     { id: 1, name: "Weekly" },
        //     { id: 2, name: "Monthly" }
        //   ];
        //   this.freqFlag = false;
        // } else {
        //   this.frequency = [{ id: 2, name: "Monthly" }];
        //   this.freqFlag = true;
        // }
      }
      // if (this.completedTweleveMonths) {
      //   let freqLen = this.frequency.length;
      //   for (let fr = 0; fr < freqLen; fr++) {
      //     if (this.frequency[fr].name == "Weekly") {
      //       this.frequency.splice(fr, 1);
      //     }
      //   }
      // }
      this.weathervarId = val.weatherVarTypeId;
      sessionStorage.setItem("weatherheadid", val.weatherVarTypeId);
      if ( val.id != 2 && val.id != 3 )
        this.freqName = val.weatherVarTypeName;
      //sessionStorage.setItem("graphweatherType",val.weatherVarTypeName.toLowerCase());
      this.outputName = val.weatherVarTypeName;
      if (this.outputName == this.freqName) {
        this.graphInd = undefined;
      }
      if (this.outputName == "Daily") {
        this.predClass = "predtableadvDaily";
      } else if (this.outputName == "Weekly") {
        this.predClass = "predtableadvWeekly";
      } else {
        this.predClass = "predtableadvMonthly";
      }
      this.outputData = val;
      if (this.type == "stats") {
        if ( this.outputName == "Daily" || this.outputName == "Speed" || this.outputName == "Minimum" || this.outputName == "Depth" ) {
          this.dataObj = this.outputData.k1.data;
        } else if ( this.outputName == "Weekly" || this.outputName == "Gust" || this.outputName == "Mean" ) {
          this.dataObj = this.outputData.k2.data;
        } else {
          this.dataObj = this.outputData.k3.data;
        }
      } else {
        if ( this.outputName == "Minimum" || this.outputName == "Weekly" || this.outputName == "Speed" ) {
          this.dataObj = this.outputData.k1.data;
        } else if ( this.outputName == "Mean" || this.outputName == "Monthly" || this.outputName == "Speed" ) {
          this.dataObj = this.outputData.k2.data;
        } else if (this.outputName == "Maximum") {
          this.dataObj = this.outputData.k3.data;
        } else {
          this.dataObj = this.outputData.k2.data;
        }
      }
      let graphanalogue = sessionStorage.getItem("graphAnalogue");
      let graphmodelId = sessionStorage.getItem("overviewid");
      let mId = 0;
      let outputDataModlLen = this.outputData.models.length;
      // if (graphanalogue == "true") {
      //   if (outputDataModlLen == 1) {
      //     this.onModel(this.outputData.models[0]);
      //   } else {
      //     this.onModel(this.outputData.models[1]);
      //   }
      // } else {
      //   for (let i = 0; i < outputDataModlLen; i++) {
      //     if (graphmodelId == this.outputData.models[i].id) {
      //       mId = i;
      //       break;
      //     }
      //   }
      //   this.onModel(this.outputData.models[mId]);
      // }
      for (let i = 0; i < outputDataModlLen; i++) {
          if (graphmodelId == this.outputData.models[i].id) {
            mId = i;
            break;
          }
        }
        
      this.onModel(this.outputData.models[mId]);
      if (this.dataObj[0].unit == "C" || this.dataObj[0].unit == "F") {
        this.dataObj[0].symbol = "&deg ";
      } else {
        this.dataObj[0].symbol = " ";
      }
      this.dataObj[0].min = parseFloat(this.dataObj[0].min);
      this.dataObj[1].min = parseFloat(this.dataObj[1].min);
  }

  onModel(val) {
    this.graphInd = undefined;
    // if (val.id == 6) {
    //   this.typeId = 1;
    // }
    this.modelName = val.model_name;
    this.modelAnalogue = val.analogue;
    // this.modelType = val.mType;
    // //stf
    // if ( this.modelType && ["s", "g"].indexOf(this.modelType.toLowerCase()) >= 0 ) {
    //   this.stfHeightName.reset = 1;
    //   this.stfInterval.reset == 1;
    // }
    sessionStorage.setItem("overviewid", val.id);
    sessionStorage.setItem("modelid", val.id);
    if (val.analogue == "true") {
      // this.showPeriod = false;
      // this.graphDataTypes = [{ id: 1, name: "Percentage" }];
      // this.typeName = "Percentage";
      // this.numYears = "end";
      // this.changeType(this.graphDataTypes[0]);
    } else {
      let duration = sessionStorage.getItem("graphDuration");
      // this.showPeriod = true;
      this.periods = val.years;
      // this.graphDataTypes = [{ id: 1, name: "Percentage" }];
      // this.numYears = "10";
      let index;
      let periodLen = this.periods.length;
      for (let i = 0; i < periodLen; i++) {
        if (this.periods[i].name == duration) {
          index = i;
        }
      }
      if (this.type == "stats") {
        index = ( index ) ? index : 0;
        this.onPeriod(this.periods[index]);
        // if (index) {
        //   this.onPeriod(this.periods[index]);
        // } else {
        //   this.onPeriod(this.periods[0]);
        // }
      } else {
      //   if (this.freqFlag) {
      //     this.changeFreq({ id: 2, name: "Monthly" });
      //   } else {
      //     if (this.freqName == "Monthly") {
      //       this.changeFreq({ id: 2, name: "Monthly" });
      //     } else {
      //       this.changeFreq({ id: 1, name: "Weekly" });
      //     }
      //   }
      }
    }
  }
    onPeriod(val) {
      this.periodName = val.name;
      //sessionStorage.setItem("durationId", val.id);
      this.numYears = val.id;
      this.go();
    }

    go(updateThresh?:any) {
      // if ( updateThresh )
      //   this.updateGraphThreshold(this.dataObj, "Save Thresholds");
      if (this.modelType == "MA") {
        if (this.variableName == "Temperature") {
          this.predictiongraphTypes = [
            { id: 1, name: "Lowest" },
            { id: 2, name: "Mean" },
            { id: 3, name: "Highest" }
          ];
        } else if (this.variableName == "Wind") {
          this.predictiongraphTypes = [
            { id: 1, name: "Lowest" },
            { id: 2, name: "Average" },
            { id: 3, name: "Highest" }
          ];
        } else {
          if (this.variableName == "Rain") {
            this.predictiongraphTypes = [{ id: 1, name: "Total" }];
          } else if (this.outputName == "Daily" && this.freqName == "Weekly") {
            this.predictiongraphTypes = [
              { id: 1, name: "Lowest" },
              { id: 2, name: "Mean" },
              { id: 3, name: "Highest" }
            ];
          } else if (this.outputName == "Daily" && this.freqName == "Monthly") {
            this.predictiongraphTypes = [
              { id: 1, name: "Lowest" },
              { id: 2, name: "Mean" },
              { id: 3, name: "Highest" }
            ];
          } else if (this.outputName == "Weekly" && this.freqName == "Weekly") {
            this.predictiongraphTypes = [{ id: 1, name: "Total" }];
          } else if (this.outputName == "Weekly" && this.freqName == "Monthly") {
            this.predictiongraphTypes = [
              { id: 1, name: "Lowest" },
              { id: 2, name: "Mean" },
              { id: 3, name: "Highest" }
            ];
          } else if (this.outputName == "Monthly" && this.freqName == "Monthly") {
            this.predictiongraphTypes = [{ id: 1, name: "Total" }];
          }
        }
  
        this.graphName = this.predictiongraphTypes[0].name;
        this.graphId = 1;
      } else {
        this.predictiongraphTypes = [{ id: 2, name: "Spread" }];
        this.graphName = this.predictiongraphTypes[0].name;
        this.graphId = 2;
      }
      // this.predicttableData = [];
      // this.stftableData = [];
      this.tableData = [];
      // this.currentPredicPage = 1;
      // this.currentSTFPage = 1;
      this.graphpage = 1;
      //this.currentRiskPage = 1;
      if (this.type == "stats" || this.type == "predict") {
        if ( parseFloat(this.dataObj[0].min) == parseFloat(this.dataObj[1].min) && this.weatherId != 5 && this.weatherId != 6 && this.weatherId != 7 && this.weatherId != 8 ) {
          // this.errorFlag = true;
          this.showGraph = false;
          // this.errmsg = "Please make sure that the limits you have set for Severe and Moderate are different.";
          //this.getPopUpVal(true);
          this.loader.display(false);
          return;
        } else {
          //this.showGraph = true;
          // this.loader.display(true);
        }
        if (this.dataObj[0].unit == "C" || this.dataObj[0].unit == "F") {
          this.dataObj[0].symbol = "&deg ";
        } else {
          this.dataObj[0].symbol = " ";
        }
        if (this.type == "stats") {
          this.getStatisticalGraph();
          sessionStorage.setItem("statpredictmodelid", "1");
        } else if (this.type == "predict") {
          //this.predicttableData = [];
          //this.stftableData = [];
          this.getPredictionGraph();
          sessionStorage.setItem("statpredictmodelid", "2");
        }
        this.dataObj[0].min = parseFloat(this.dataObj[0].min);
        this.dataObj[1].min = parseFloat(this.dataObj[1].min);
      } else {
        if (this.type == "risk") {
          //this.showGraph = true;
          this.getRiskGraph();
        }
      }
    }

    getStatisticalGraph() {
      
      
        //if (navigator.onLine == true) {
              let stationid  = this.curentWeatherVarData.stationId;
              let type = this.curentWeatherVarData.name;
              // if(this.hourlySelected=='hourly'){
              //   stationid = this.currentModelData.weathers[0].stationId
              //   let change = {"hourly":'daily',"temperature-hourly":'minimum',"snow-hourly":'depth',  "hourly_speed":'speed', "hourly_gust":'gust'}
              //   if(this.currentType == 'temperature' || this.currentType == 'snow'){
              //     type = this.currentType+"-"+'hourly'
              //   }
              //   type = change[type]
              // }
              this.showGraph = false;
              // this.loader.display(true);
              this.plotlyGraphData='';
              let stDtMoment = moment(this.searchState.datestart, 'DD-MM-YYYY');
              let endDtMoment = moment(this.searchState.dateend, 'DD-MM-YYYY');
              let stdtObj = new Date(JSON.parse(JSON.stringify(stDtMoment)));
              let endDtObj = new Date(JSON.parse(JSON.stringify(endDtMoment)));;
              this.graphStartDate = stdtObj;
              this.graphEndDate = endDtObj;
              let stDate = this.graphStartDate;
              let endDt = this.graphEndDate;
              let diffc = this.graphEndDate.getTime() - this.graphStartDate.getTime();
              this.totalNoOfDaysInPeriod = diffc / (1000 * 3600 * 24);
              this.showNext = false;
              this.showPrevious = false;
              this.graphObj.StationID = stationid;
              this.graphObj.Country = this.eventDetails.country
              this.graphObj.Start_Date = this.eventDetails.startDate;//staticdata
              this.graphObj.End_Date = this.eventDetails.endDate;//staticdata
              let strtDtt = new Date(this.stdate);
              let enddDtt = new Date(this.edate);
              if (
                strtDtt < this.graphStartDate ||
                strtDtt > this.graphStartDate ||
                enddDtt < this.graphEndDate ||
                enddDtt > this.graphEndDate
              ) {
                this.graphObj.Start_Date =
                  this.graphStartDate.getDate() +
                  "/" +
                  (this.graphStartDate.getMonth() + 1) +
                  "/" +
                  this.graphStartDate.getFullYear();
                this.graphObj.End_Date =
                  this.graphEndDate.getDate() +
                  "/" +
                  (this.graphEndDate.getMonth() + 1) +
                  "/" +
                  this.graphEndDate.getFullYear();
              } else if (
                this.graphObj.Start_Date == null ||
                this.graphObj.End_Date == null
              ) {
                // For the past event
                this.graphObj.Start_Date =
                  strtDtt.getDate() +
                  "/" +
                  (strtDtt.getMonth() + 1) +
                  "/" +
                  strtDtt.getFullYear();
                this.graphObj.End_Date =
                  enddDtt.getDate() +
                  "/" +
                  (enddDtt.getMonth() + 1) +
                  "/" +
                  enddDtt.getFullYear();
              }
              
              let graphStartDate:any;
              let graphEndDate:any;
              if ( this.graphObj.Start_Date == this.customizedStartDate && this.graphObj.End_Date == this.customizedEndDate ) {
                graphStartDate = this.graphObj.Start_Date;
                graphEndDate = this.graphObj.End_Date;
              } else {
                graphStartDate = this.customizedStartDate;
                graphEndDate = this.customizedEndDate;
              }
              let numofyrs = ( this.currentModelData.model_name == 'Advanced' && ( this.curentWeatherData[0].weather_type_id == 5 || this.curentWeatherData[0].weather_type_id == 6 || this.curentWeatherData[0].weather_type_id == 7 || this.curentWeatherData[0].weather_type_id == 8 ) ) ? 'end' : this.curentDurationData.id;
              this.graphObj.Start_Date = graphStartDate;
              this.graphObj.End_Date = graphEndDate;
              this.graphObj.Num_Years = ( this.currentModelData.model_name == 'Advanced' && ( this.curentWeatherData[0].weather_type_id == 5 || this.curentWeatherData[0].weather_type_id == 6 || this.curentWeatherData[0].weather_type_id == 7 || this.curentWeatherData[0].weather_type_id == 8 ) ) ? 'end' : this.curentDurationData.id;
              this.graphObj.Wparam = this.currentType;
              this.graphObj.Type = type;
              let dur = numofyrs;
              let unit = this.searchState.thresholdData.unit;
              let t1 = this.convertOverviewThresholds(this.searchState.thresholdData.alert,unit,1,this.curentWeatherVarData.weathertypeid);
              let t2 = this.convertOverviewThresholds(this.searchState.thresholdData.severe,unit,1,this.curentWeatherVarData.weathertypeid);
              this.graphObj.T1 = t1;//this.convertThresholds(this.searchState.thresholdData.alert,this.curentWeatherVarData.unit,1);//this.searchState.thresholdData.alert;//this.convertThresholds(this.searchState.thresholdData.alert,this.dataObj[0].unit,1);
              this.graphObj.T2 = t2;this.convertThresholds(this.searchState.thresholdData.severe,this.curentWeatherVarData.unit,1);//this.convertThresholds(this.dataObj[1].min,this.dataObj[0].unit,1);
              this.graphObj.unit = this.searchState.thresholdData.unit;
              this.graphObj.Analogue = this.currentModelData.analogue;
              this.graphObj.page = this.graphpage;
              this.graphObj.noOfRecord = this.statNoOfRecord;
              if ( this.workingHourValue == 'Working Hours' ) {
                this.graphObj.working_hours = true;
              }
              if(this.hourlySelected == 'hourly' && ['GB','US'].includes(this.eventDetails.country)){
                this.graphObj.hour_list = [this.startHours, this.endHours]
                if(this.hourlypage > 1 && this.hourlypage > this.graphpage){
                  this.graphObj.page = this.hourlypage;
                  this.graphpage = this.hourlypage;
                }
              }
              this.newenquiryService.fetchgraphdata(this.graphObj).subscribe(
                data => {
                  this.showloadergraph = false;
                  let dd = JSON.parse(JSON.stringify(data));
                  let successData = dd;
                  if ( successData.working_hours_data_available == true ) {
                    if ( this.workingHourData.length < 2 ) { //If 'Working Hours' has not been added to the drop-down 
                      this.workingHourData.push({ id: 2, name: "Working Hours" });  
                    }
                  }
                  this.showGraph = true;
        
                  this.fetchedData = successData;
                  this.fetchedData.low = this.convertUnit(this.fetchedData.low,this.dataObj[0].unit);
                  this.fetchedData.mean = this.convertUnit(this.fetchedData.mean,this.dataObj[0].unit);
                  this.fetchedData.high = this.convertUnit(this.fetchedData.high, this.dataObj[0].unit);
        
                  this.riskSummary = successData.summary;
                  // this.statsprob = successData.overall;
                  // this.statsprobFlag = true;
                  // this.lowhighmeanFlag = true;
                  // this.summeryFlag = true;
                  let fetchDataCountLen = this.fetchedData.count.length;
                  this.tableData=[];
                  if ( this.curentWeatherVarData.weathertypeid != 5 && this.curentWeatherVarData.weathertypeid != 6 && this.curentWeatherVarData.weathertypeid != 7 && this.curentWeatherVarData.weathertypeid != 8 ) {
                    for (let i = 0; i < fetchDataCountLen; i++) {
                      this.tableData.push({
                        dateval: this.fetchedData.count[i].Date,
                        date: this.fetchedData.count[i].title,
                        alertCount:this.fetchedData.count[i].t1years == "All"? "All": this.fetchedData.count[i].T1,
                        severeCount:this.fetchedData.count[i].t2years == "All"? "All": this.fetchedData.count[i].T2,
                        alertYears: this.fetchedData.count[i].t1years,
                        severeYears: this.fetchedData.count[i].t2years,
                        alertPercentage: this.fetchedData.percentage[i].T1,
                        severePercentage: this.fetchedData.percentage[i].T2
                      });
                    }
                  } else {
                    for (let i = 0; i < fetchDataCountLen; i++) {
                      this.tableData.push({
                        dateval: this.fetchedData.count[i].Date,
                        date: this.fetchedData.count[i].title,
                        extremeCount:this.fetchedData.count[i].t2years == "All"? "All": this.fetchedData.count[i].T2,
                        extremeYears: this.fetchedData.count[i].t2years,
                        extremePercentage: this.fetchedData.percentage[i].T2,
                      });
                    }
                  }
                  console.log(this.tableData);

                  if (this.fetchedData.more == 1) {
                    this.showNext = true;
                    this.showPrevious = true;
                    //this.disableViewMoreStatic = false;
                  } else {
                    this.showNext = false;
                    this.showPrevious = true;
                    //this.disableViewMoreStatic = true;
                  }
                  if (this.graphpage == 1) {
                    this.showPrevious = false;
                  }
        
                  if (this.showAdvancedType == "table") {
                    // if (this.viewGraph) {
                    //   this.viewTableButton = true;
                    // } else {
                    //   this.viewTableButton = false;
                    // }
                    this.graphdata = this.fetchedData.count;
                  } else {
                    // if (this.viewGraph) {
                    //   this.viewTableButton = true;
                    // } else {
                    //   this.viewTableButton = false;
                    // }
                    this.graphdata = this.fetchedData.percentage;
                  }
                  // this.statsWeek = this.fetchedData.period;
        
                  this.loader.display(false);
                },
                error => {
                  this.showloadergraph = false;
                  if ( error.name == 'HttpErrorResponse' && error.status == 0 && error.statusText == 'Unknown Error' ) {
                    this.loader.display(false);
                    //this.connectionService.display(true);
                  } else {
                    let errordata = error.error?error.error:error;
                    this.loader.display(false);
                    //this.errorFlag = true;
                    this.isError=true;
                    //this.getPopUpVal(true);
                    this.showGraph = false;
                    this.errorMsg = errordata.error;
                    let errObj: any = {};
                    (errObj.code = error.status),
                      (errObj.eventId = sessionStorage.getItem("eventId")),
                      (errObj.url = "stats_data/get_graph_data");
                    (errObj.frontUrl = "/insightgraph"), (errObj.apiBody = this.graphObj);
                    if (error.status == 404 || error.status == 500) {
                      //this.callErrorMail(errObj);
                    }
                  }
                }
              );

              this.LS.logOut({
                title:
                  "Report Graph (" +
                  (this.eventDetails.event_name ? this.eventDetails.event_name + " " : "") +
                  this.eventDetails.locationName +
                  ")",
                link: "#/report",
                description:
                  "Statistical Data - " +
                  this.currentModelData.model_name +
                  ", " +
                  this.amountData +
                  ", Alert: " +
                  this.searchState.thresholdData.alert +
                  ", Severe: " +
                  this.searchState.thresholdData.severe +
                  ", Unit:" +
                  this.searchState.thresholdData.unit,
                  eventId: this.eventId,
                  modelId: this.currentModelData.id
              }).pipe(takeUntil(this.destroy$)).subscribe(
                data => {},
                error => {
                  this.router.navigate(["/login"]);
                }
              );
            
    }

    async getPredictionGraph() {
      if (["ma", "ai", "ca"].indexOf(this.modelType.toLowerCase()) >= 0)
        this.getLTFgraph();
    }

    getLTFgraph() {
      if (navigator.onLine == true) {
        this.graphdata = '';
        this.plotlyGraphData = '';
        this.predicttableData = [];
        this.graphStartDate = new Date(this.graphStartDate);
        this.graphEndDate = new Date(this.graphEndDate);
        d3.selectAll("nvd3 svg > *").remove();
        // setTimeout(() => {
        //   let alert = this.convertOverviewThresholds(this.searchState.thresholdData.alert,unit,1,this.curentWeatherVarData.weathertypeid);
        //   let severe = this.convertOverviewThresholds(this.searchState.thresholdData.severe,unit,1,this.curentWeatherVarData.weathertypeid);
        //   if (this.variableName == "Temperature") {
        //     if (severe == 0 || alert == 0 ) {
        //       if ( alert < severe )
        //         d3.selectAll(".nvd3 .nv-axis line").style("opacity", 0);
        //     }
        //   } else if (this.variableName == "Wind") {
        //     if (severe == 0 || alert == 0 ) {
        //       if ( alert < severe )
        //         d3.selectAll(".nvd3 .nv-axis line").style("opacity", 0);
        //     }
        //   } else if (this.variableName == "Rain") {
        //     if (severe == 0 || alert == 0 ) {
        //       if ( alert < severe )
        //         d3.selectAll(".nvd3 .nv-axis line").style("opacity", 0);
        //     }
        //   }
        // }, 2000);
        this.predictiongraphTypes = [{ id: 2, name: "Spread" }];
        this.graphName = this.predictiongraphTypes[0].name;
        this.graphId = 2;

        let unit = this.searchState.thresholdData.unit;
        let t1 = this.convertOverviewThresholds(this.searchState.thresholdData.alert,unit,1,this.curentWeatherVarData.weathertypeid);
        let t2 = this.convertOverviewThresholds(this.searchState.thresholdData.severe,unit,1,this.curentWeatherVarData.weathertypeid);
        let graphStartDate:any;
        let graphEndDate:any;
        if ( this.searchState.datestart == this.customizedStartDate && this.searchState.dateend == this.customizedEndDate ) {
          graphStartDate = this.searchState.datestart;
          graphEndDate = this.searchState.dateend;
        } else {
          this.searchState.datestart = graphStartDate = this.customizedStartDate;
          this.searchState.dateend = graphEndDate = this.customizedEndDate;
        }
        let yMin = 1000;
        let yMax = -80;
        // this.freqName = sessionStorage.getItem("graphfreq");
        this.predictgraphObj.StationID = this.curentWeatherVarData.stationId;
        this.predictgraphObj.Model = this.currentModelData.mType;
        this.predictgraphObj.PType = "LTF";
        this.predictgraphObj.Sdate = graphStartDate;
        this.predictgraphObj.Edate = graphEndDate;
        this.predictgraphObj.Wparam = this.currentType;
        this.predictgraphObj.Type = this.curentWeatherVarData.name;
        this.predictgraphObj.T1 = Number(t1);
        this.predictgraphObj.T2 = Number(t2);
        // this.predictgraphObj.T2 = parseFloat(this.dataObj[1].min);
        this.predictgraphObj.unit = unit;
        if (this.predictgraphObj.Wparam == "rain") {
          if (this.predictgraphObj.Type == "weekly") this.freqName = "Weekly";
          else if (this.predictgraphObj.Type == "monthly")
            this.freqName = "Monthly";
        } else {
          this.freqName = this.currFreq.name;
        }
        
        this.predictgraphObj.Freq = this.freqName;
        this.predictgraphObj.height = "0";
        this.predictgraphObj.Analogue = this.currentModelData.analogue;
        this.predictgraphObj.page = this.currentPredicPage;
        this.predictgraphObj.output = this.graphName.toLowerCase();
        this.predictgraphObj.noOfRecord = this.predNoOfRecord;
        this.loader.display(true);
        this.enquiriesService.getltfGraph(this.predictgraphObj).pipe(takeUntil(this.destroy$)).subscribe(
          data => {
            this.LS.logOut({
              title:
                "Report Graph (" +
                (this.eventDetails.event_name ? this.eventDetails.event_name + " " : "") +
                this.eventDetails.locationName +
                ")",
              link: "#/report",
              description:
                "Prediction Data - " +
                this.currentModelData.model_name +
                ", " +
                this.freqName +
                ", " +
                this.currentType +
                ", Alert: " +
                this.searchState.thresholdData.alert +
                ", Severe: " +
                this.searchState.thresholdData.severe +
                ", Unit:" +
                unit,
                eventId: this.eventId,
                modelId: this.currentModelData.id
            }).pipe(takeUntil(this.destroy$)).subscribe(
              data => {},
              error => {
                this.router.navigate(["/login"]);
              }
            );
            // let oldPredictData = this.fetchedpredictData;
            // let oldMeanArrTable = this.meanValArrTable;
            let dd = JSON.parse(JSON.stringify(data));
            let successData = dd;
            this.showGraph = true;
            this.loader.display(false);
            if (this.viewPredictGraph) {
              this.viewPredictTableButton = true;
            } else {
              this.viewPredictTableButton = false;
            }
            this.fetchedpredictData = successData;
            this.fetchedpredictData.page = this.currentPredicPage;
            this.fetchedpredictData.unit = ' '+this.getThresholdsData.symbol+this.getThresholdsData.unit;
            this.predYear = this.fetchedpredictData.year;
            //console.log('success data',successData);
            // if (this.fetchedpredictData.more == 1) {
            //   this.disablePrevious = false;
            //   this.disableNext = false;
            //   this.disableViewMore = false;
            // } else {
            //   this.disableNext = true;
            //   this.disablePrevious = false;
            //   this.disableViewMore = true;
            // }
            // if (this.currentPredicPage == 1) {
            //   this.disablePrevious = true;
            // }
  
            this.fetchedpredictData.minimum = this.convertUnit(
              this.fetchedpredictData.minimum,
              unit
            );
  
            this.fetchedpredictData.maximum = this.convertUnit(
              this.fetchedpredictData.maximum,
              unit
            );
            this.fetchedpredictData.mean = this.convertUnit(
              this.fetchedpredictData.mean,
              unit
            );

            // console.log(this.fetchedpredictData);
            if ( this.modelType == 'MA' ) {
              let minCheckArr = [];
              let maxCheckArr = [];
              let meanCheckArr = [];
              minCheckArr.push(parseFloat(this.fetchedpredictData.minimum));
              maxCheckArr.push(parseFloat(this.fetchedpredictData.maximum));
              meanCheckArr.push(parseFloat(this.fetchedpredictData.mean));
              // if (
              //   typeof oldPredictData !== "undefined" &&
              //   this.currentPredicPage > 1
              // ) {
              //   minCheckArr.push(parseFloat(oldPredictData.tableMinimum));
              //   maxCheckArr.push(parseFloat(oldPredictData.tableMaximum));
              //   meanCheckArr.push(parseFloat(oldPredictData.tableMean));
              // }
              let reducer = (accumulator, currentValue) => parseFloat(accumulator + currentValue);
              this.fetchedpredictData.tableMinimum = Math.min(...minCheckArr);
              this.fetchedpredictData.tableMean = meanCheckArr.reduce(reducer)/meanCheckArr.length;
              this.fetchedpredictData.tableMaximum = Math.max(...maxCheckArr);  
            }
            
            // this.lowhighmeanPredictFlag = true;
            let labels = [];
            let t2 = parseFloat(this.searchState.thresholdData.alert); //Alert
            let t1 = parseFloat(this.searchState.thresholdData.severe); //Severe
            let alertt2 = parseFloat(this.searchState.thresholdData.alert);
            let severet1 = parseFloat(this.searchState.thresholdData.severe);
            let dataLen = successData.data.length;
  
            let lowArr:any=[];
            let highArr:any=[];
            let meanArr:any=[];
            this.predicttableData = [];
  
            for (let i = 0; i < dataLen; i++) {
              successData.data[i].percentiles.p1 = this.convertUnit(
                successData.data[i].percentiles.p1,
                unit
              );
              successData.data[i].percentiles.p25 = this.convertUnit(
                successData.data[i].percentiles.p25,
                unit
              );
              successData.data[i].percentiles.p50 = this.convertUnit(
                successData.data[i].percentiles.p50,
                unit
              );
              successData.data[i].percentiles.p75 = this.convertUnit(
                successData.data[i].percentiles.p75,
                unit
              );
              successData.data[i].percentiles.p99 = this.convertUnit(
                successData.data[i].percentiles.p99,
                unit
              );
              successData.data[i].predicted = this.convertUnit(
                successData.data[i].predicted,
                unit
              );

              if (this.modelType == "MA") {
                if (successData.data[i].predictedma.length == 3) {
                  successData.data[i].predictedma[0].value = this.convertUnit(
                    successData.data[i].predictedma[0].value,
                    this.dataObj[0].unit
                  );
                  successData.data[i].predictedma[1].value = this.convertUnit(
                    successData.data[i].predictedma[1].value,
                    this.dataObj[0].unit
                  );
                  successData.data[i].predictedma[2].value = this.convertUnit(
                    successData.data[i].predictedma[2].value,
                    this.dataObj[0].unit
                  );
                  if (t1 - t2 > 0) {
                    if (
                      parseFloat(successData.data[i].predictedma[0].value) >=
                      severet1
                    ) {
                      successData.data[i].predictedma[0].color = "#f00"; //red
                    } else if (
                      parseFloat(successData.data[i].predictedma[0].value) <
                      alertt2
                    ) {
                      successData.data[i].predictedma[0].color = "#008000"; //Green
                    } else if (
                      alertt2 <=
                        parseFloat(successData.data[i].predictedma[0].value) &&
                      parseFloat(successData.data[i].predictedma[0].value) <
                        severet1
                    ) {
                      successData.data[i].predictedma[0].color = "#ff0"; //yellow
                    }
  
                    if (
                      parseFloat(successData.data[i].predictedma[1].value) >=
                      severet1
                    ) {
                      successData.data[i].predictedma[1].color = "#f00"; //red
                    } else if (
                      parseFloat(successData.data[i].predictedma[1].value) <
                      alertt2
                    ) {
                      successData.data[i].predictedma[1].color = "#008000"; //Green
                    } else if (
                      alertt2 <=
                        parseFloat(successData.data[i].predictedma[1].value) &&
                      parseFloat(successData.data[i].predictedma[1].value) <
                        severet1
                    ) {
                      successData.data[i].predictedma[1].color = "#ff0"; //yellow
                    }
  
                    if (
                      parseFloat(successData.data[i].predictedma[2].value) >=
                      severet1
                    ) {
                      successData.data[i].predictedma[2].color = "#f00"; //red
                    } else if (
                      parseFloat(successData.data[i].predictedma[2].value) <
                      alertt2
                    ) {
                      successData.data[i].predictedma[2].color = "#008000"; //Green
                    } else if (
                      alertt2 <=
                        parseFloat(successData.data[i].predictedma[2].value) &&
                      parseFloat(successData.data[i].predictedma[2].value) <
                        severet1
                    ) {
                      successData.data[i].predictedma[2].color = "#ff0"; //yellow
                    }
                  } else {
                    if (
                      parseFloat(successData.data[i].predictedma[0].value) <=
                      severet1
                    ) {
                      successData.data[i].predictedma[0].color = "#f00"; //red
                    } else if (
                      parseFloat(successData.data[i].predictedma[0].value) >
                      alertt2
                    ) {
                      successData.data[i].predictedma[0].color = "#008000"; //Green
                    } else if (
                      alertt2 >=
                        parseFloat(successData.data[i].predictedma[0].value) &&
                      parseFloat(successData.data[i].predictedma[0].value) >
                        severet1
                    ) {
                      successData.data[i].predictedma[0].color = "#ff0"; //yellow
                    }
  
                    if (
                      parseFloat(successData.data[i].predictedma[1].value) <=
                      severet1
                    ) {
                      successData.data[i].predictedma[1].color = "#f00"; //red
                    } else if (
                      parseFloat(successData.data[i].predictedma[1].value) >
                      alertt2
                    ) {
                      successData.data[i].predictedma[1].color = "#008000"; //Green
                    } else if (
                      alertt2 >=
                        parseFloat(successData.data[i].predictedma[1].value) &&
                      parseFloat(successData.data[i].predictedma[1].value) >
                        severet1
                    ) {
                      successData.data[i].predictedma[1].color = "#ff0"; //yellow
                    }
  
                    if (
                      parseFloat(successData.data[i].predictedma[2].value) <=
                      severet1
                    ) {
                      successData.data[i].predictedma[2].color = "#f00"; //red
                    } else if (
                      parseFloat(successData.data[i].predictedma[2].value) >
                      alertt2
                    ) {
                      successData.data[i].predictedma[2].color = "#008000"; //Green
                    } else if (
                      alertt2 >=
                        parseFloat(successData.data[i].predictedma[2].value) &&
                      parseFloat(successData.data[i].predictedma[2].value) >
                        severet1
                    ) {
                      successData.data[i].predictedma[2].color = "#ff0"; //yellow
                    }
                  }
                } else {
                  successData.data[i].predictedma[0].value = this.convertUnit(
                    successData.data[i].predictedma[0].value,
                    this.dataObj[0].unit
                  );
                  if (t1 - t2 > 0) {
                    if (
                      parseFloat(successData.data[i].predictedma[0].value) >=
                      severet1
                    ) {
                      successData.data[i].predictedma[0].color = "#f00"; //red
                    } else if (
                      parseFloat(successData.data[i].predictedma[0].value) <
                      alertt2
                    ) {
                      successData.data[i].predictedma[0].color = "#008000"; //Green
                    } else if (
                      alertt2 <=
                        parseFloat(successData.data[i].predictedma[0].value) &&
                      parseFloat(successData.data[i].predictedma[0].value) <
                        severet1
                    ) {
                      successData.data[i].predictedma[0].color = "#ff0"; //yellow
                    }
                  } else {
                    if (
                      parseFloat(successData.data[i].predictedma[0].value) <=
                      severet1
                    ) {
                      successData.data[i].predictedma[0].color = "#f00"; //red
                    } else if (
                      parseFloat(successData.data[i].predictedma[0].value) >
                      alertt2
                    ) {
                      successData.data[i].predictedma[0].color = "#008000"; //Green
                    } else if (
                      alertt2 >=
                        parseFloat(successData.data[i].predictedma[0].value) &&
                      parseFloat(successData.data[i].predictedma[0].value) >
                        severet1
                    ) {
                      successData.data[i].predictedma[0].color = "#ff0"; //yellow
                    }
                  }
                }
                this.predicttableData.push({
                  year: successData.data[i].year,
                  periodName: successData.data[i].periodName,
                  predictMA: successData.data[i].predictedma
                });
              } else {
                let bestEst;
                let range;
                let uppLim;
                let lowLim;
                if ( this.curentWeatherVarData.id != 6 ) {
                  bestEst = parseFloat(successData.data[i].predicted);
                  range = parseFloat(successData.data[i].percentiles.p99) - parseFloat(successData.data[i].percentiles.p1);
                  uppLim = parseFloat(successData.data[i].predicted) + ( parseFloat(successData.data[i].percentiles.p99) - parseFloat(successData.data[i].percentiles.p50) );
                  if ( this.curentWeatherVarData.weathertypeid != 1 || (2*( parseFloat(successData.data[i].percentiles.p99) - parseFloat(successData.data[i].percentiles.p75) )) < successData.data[i].predicted ) {
                    lowLim = parseFloat(successData.data[i].predicted) - ( parseFloat(successData.data[i].percentiles.p50) - parseFloat(successData.data[i].percentiles.p1) );
                  } else {
                    lowLim = 0.0;
                  }
                } else {
                  bestEst = successData.data[i].percentiles.p50;
                  range = parseFloat(successData.data[i].percentiles.p99) - parseFloat(successData.data[i].percentiles.p1);
                  uppLim = successData.data[i].percentiles.p99;
                  lowLim = successData.data[i].percentiles.p1;
                }
                lowLim = parseFloat(lowLim).toFixed(2);
                uppLim = parseFloat(uppLim).toFixed(2);
                bestEst = parseFloat(bestEst).toFixed(2);
                range = parseFloat(range).toFixed(2);
                lowArr.push(lowLim);
                highArr.push(uppLim);
                meanArr.push(bestEst); 

                let p1Class;
                let p25Class;
                let p50Class;
                let p75Class;
                let p99Class;
    
                if (t1 - t2 > 0) {
                  if (parseFloat(lowLim) >= severet1) {
                    p1Class = 'text-orange-dark';
                  } else if (
                    parseFloat(lowLim) < alertt2
                  ) {
                    p1Class = 'text-green-dark';
                  } else if (
                    alertt2 <= parseFloat(lowLim) &&
                    parseFloat(lowLim) < severet1
                  ) {
                    p1Class = 'text-yellow-dark';
                  }
    
                  if (parseFloat(successData.data[i].percentiles.p25) >= severet1) {
                    p25Class = 'text-orange-dark';
                  } else if (
                    parseFloat(successData.data[i].percentiles.p25) < alertt2
                  ) {
                    p25Class = 'text-green-dark';
                  } else if (
                    alertt2 <= parseFloat(successData.data[i].percentiles.p25) &&
                    parseFloat(successData.data[i].percentiles.p25) < severet1
                  ) {
                    p25Class = 'text-yellow-dark';
                  }
    
                  if (parseFloat(bestEst) >= severet1) {
                    p50Class = 'text-orange-dark';
                  } else if (
                    parseFloat(bestEst) < alertt2
                  ) {
                    p50Class = 'text-green-dark';
                  } else if (
                    alertt2 <= parseFloat(bestEst) &&
                    parseFloat(bestEst) < severet1
                  ) {
                    p50Class = 'text-yellow-dark';
                  }
    
                  if (parseFloat(successData.data[i].percentiles.p75) >= severet1) {
                    p75Class = 'text-orange-dark';
                  } else if (
                    parseFloat(successData.data[i].percentiles.p75) < alertt2
                  ) {
                    p75Class = 'text-green-dark';
                  } else if (
                    alertt2 <= parseFloat(successData.data[i].percentiles.p75) &&
                    parseFloat(successData.data[i].percentiles.p75) < severet1
                  ) {
                    p75Class = 'text-yellow-dark';
                  }
                  if (parseFloat(uppLim) >= severet1) {
                    p99Class = 'text-orange-dark';
                  } else if (
                    parseFloat(uppLim) < alertt2
                  ) {
                    p99Class = 'text-green-dark';
                  } else if (
                    alertt2 <= parseFloat(uppLim) &&
                    parseFloat(uppLim) < severet1
                  ) {
                    p99Class = 'text-yellow-dark';
                  }
                } else {
                  if (parseFloat(lowLim) <= severet1) {
                    p1Class = 'text-orange-dark';
                  } else if (
                    parseFloat(lowLim) > alertt2
                  ) {
                    p1Class = 'text-green-dark';
                  } else if (
                    alertt2 >= parseFloat(lowLim) &&
                    parseFloat(lowLim) > severet1
                  ) {
                    p1Class = 'text-yellow-dark';
                  }
    
                  if (parseFloat(successData.data[i].percentiles.p25) <= severet1) {
                    p25Class = 'text-orange-dark';
                  } else if (
                    parseFloat(successData.data[i].percentiles.p25) > alertt2
                  ) {
                    p25Class = 'text-green-dark';
                  } else if (
                    alertt2 >= parseFloat(successData.data[i].percentiles.p25) &&
                    parseFloat(successData.data[i].percentiles.p25) > severet1
                  ) {
                    p25Class = 'text-yellow-dark';
                  }
    
                  if (parseFloat(bestEst) <= severet1) {
                    p50Class = 'text-orange-dark';
                  } else if (
                    parseFloat(bestEst) > alertt2
                  ) {
                    p50Class = 'text-green-dark';
                  } else if (
                    alertt2 >= parseFloat(bestEst) &&
                    parseFloat(bestEst) > severet1
                  ) {
                    p50Class = 'text-yellow-dark';
                  }
    
                  if (parseFloat(successData.data[i].percentiles.p75) <= severet1) {
                    p75Class = 'text-orange-dark';
                  } else if (
                    parseFloat(successData.data[i].percentiles.p75) > alertt2
                  ) {
                    p75Class = 'text-green-dark';
                  } else if (
                    alertt2 >= parseFloat(successData.data[i].percentiles.p75) &&
                    parseFloat(successData.data[i].percentiles.p75) > severet1
                  ) {
                    p75Class = 'text-yellow-dark';
                  }
                  if (parseFloat(uppLim) <= severet1) {
                    p99Class = 'text-orange-dark';
                  } else if (
                    parseFloat(uppLim) > alertt2
                  ) {
                    p99Class = 'text-green-dark';
                  } else if (
                    alertt2 >= parseFloat(uppLim) &&
                    parseFloat(uppLim) > severet1
                  ) {
                    p99Class = 'text-yellow-dark';
                  }
                }

                this.predicttableData.push({
                  year: successData.data[i].year,
                  periodName: successData.data[i].periodName,
                  percentile1: successData.data[i].percentiles.p1,
                  percentile25: successData.data[i].percentiles.p25,
                  percentile50: successData.data[i].percentiles.p50,
                  percentile75: successData.data[i].percentiles.p75,
                  percentile99: successData.data[i].percentiles.p99,
                  rangediff: (
                    successData.data[i].percentiles.p99 -
                    successData.data[i].percentiles.p1
                  ),
                  predicted: bestEst,
                  range: range,
                  uppLimit: uppLim,
                  lowLimit: lowLim,
                  p1Class: p1Class,
                  p25Class: p25Class,
                  p50Class: p50Class,
                  p75Class: p75Class,
                  p99Class: p99Class
                });
              }
  
              labels.push(successData.data[i].periodGraph);
              //console.log(successData.data[i].period);
              // if (window.innerWidth <= 800) {
              //   labels.push(successData.data[i].period);
              // } else {
              //   labels.push(successData.data[i].periodGraph);
              // }
            }
  
            if (this.modelType == "MA") {
              this.lowestTable = this.fetchedpredictData.tableMinimum;
              this.highestTable = this.fetchedpredictData.tableMaximum;
              this.meanTable =  this.fetchedpredictData.tableMean;
            } else {
              // if ( typeof oldPredictData !== "undefined" && this.currentPredicPage > 1 ) {
              //   lowArr.push(this.lowestTable);
              //   highArr.push(this.highestTable);
              //   let newArr:any[] = this.meanValArrTable.concat(meanArr);
              //   meanArr = newArr;
              // }
    
              this.meanValArrTable = meanArr;
              
              this.lowestTable = Math.min(...lowArr);
              this.highestTable = Math.max(...highArr);
              this.meanTable =  meanArr.reduce((a, b) =>  parseFloat(a) + parseFloat(b), 0) / meanArr.length;
              this.fetchedpredictData.lowestTable = this.lowestTable.toFixed(2);
              this.fetchedpredictData.meanTable = this.meanTable.toFixed(2);
              this.fetchedpredictData.highestTable = this.highestTable.toFixed(2);
            }
  
            let graphUnit;
            if (unit == "C") {
              graphUnit = "Celsius";
            } else if (unit == "F") {
              graphUnit = "Fahrenheit";
            } else {
              graphUnit = unit;
            }
            if (this.searchState.thresholdData.alert < this.searchState.thresholdData.severe) {
              yMin = parseFloat(this.searchState.thresholdData.alert);
              yMax = parseFloat(this.searchState.thresholdData.severe);
            } else if (this.searchState.thresholdData.alert > this.searchState.thresholdData.severe) {
              yMax = parseFloat(this.searchState.thresholdData.alert);
              yMin = parseFloat(this.searchState.thresholdData.severe);
            }
            let expandviewHeight;
            if (screen.width < 768) {
              expandviewHeight = window.screen.height - 400;
            } else {
              expandviewHeight = window.screen.height - 400;
            }
            let values: any = [];
            this.discretedata = [];
            if (t1 < t2) {
              for (let i = 0; i < dataLen; i++) {
                // successData.data[i].predicted = this.convertUnit(
                //   successData.data[i].predicted,
                //   this.dataObj[0].unit
                // );
                let dataArr = [
                  successData.data[i].predicted,
                  this.searchState.thresholdData.alert,
                  this.searchState.thresholdData.severe,
                  yMin,
                  yMax
                ];
                
                yMin = Math.floor(Math.min(...dataArr));
                yMax = Math.ceil(Math.max(...dataArr));
                if (successData.data[i].predicted <= t1) {
                  values.push({
                    label: labels[i],
                    color: "red", //RED
                    value: successData.data[i].predicted
                  });
                } else if (successData.data[i].predicted > t2) {
                  values.push({
                    label: labels[i],
                    color: "green", //GREEN
                    value: successData.data[i].predicted
                  });
                } else if (
                  t1 < successData.data[i].predicted &&
                  successData.data[i].predicted <= t2
                ) {
                  values.push({
                    label: labels[i],
                    color: "yellow", //YELLOW
                    value: successData.data[i].predicted
                  });
                }
              }
            } else {
              for (let i = 0; i < dataLen; i++) {
                // successData.data[i].predicted = this.convertUnit(
                //   successData.data[i].predicted,
                //   this.dataObj[0].unit
                // );
                let dataArr = [
                  successData.data[i].predicted,
                  this.searchState.thresholdData.alert,
                  this.searchState.thresholdData.severe,
                  yMin,
                  yMax
                ];
                yMin = Math.floor(Math.min(...dataArr));
                yMax = Math.ceil(Math.max(...dataArr));
                if (successData.data[i].predicted < t2) {
                  values.push({
                    label: labels[i],
                    color: "green",
                    value: successData.data[i].predicted
                  });
                } else if (successData.data[i].predicted >= t1) {
                  values.push({
                    label: labels[i],
                    color: "red",
                    value: successData.data[i].predicted
                  });
                } else if (
                  t1 > successData.data[i].predicted &&
                  successData.data[i].predicted >= t2
                ) {
                  values.push({
                    label: labels[i],
                    color: "yellow",
                    value: successData.data[i].predicted
                  });
                }
              }
            }
            setTimeout(() => {
              if (yMin == severet1 || yMin == alertt2) {
                d3.selectAll(".nvd3 .nv-axis line").style("opacity", 0);
              }
            }, 2000);
            yMin = this.setMinYDomain(yMin);
            yMax = this.setMaxYDomain(yMax);
            
            this.discretedata.push({ values: values });
            this.discreteoptions = {
              chart: {
                type: "discreteBarChart",
                height: 300,
                "max-width": 1000,
                showLegend: false,
                margin: {
                  top: 20,
                  right: 20,
                  bottom: 50,
                  left: 70
                },
                x: function(d) {
                  return d.label;
                },
                y: function(d) {
                  return d.value;
                },
                yDomain: [yMin, yMax],
                showValues: true,
                valueFormat: function(d) {
                  return d3.format(",.2f")(d);
                },
                duration: 500,
                xAxis: {
                  axisLabel: this.predYear + " (" + this.freqName + ")",
                  tickFormat: function(d) {
                    return d;
                  }
                },
                yAxis: {
                  showMaxMin: false,
                  axisLabel: graphUnit,
                  axisLabelDistance: -10
                },
                callback: chart => {
                  this.bestEstimate.yScale = chart.yAxis.scale();
                  this.bestEstimate.margin = chart.margin();
                },
                tooltip: {
                  enabled: true,
                  valueFormatter: function(d, i) {
                    return d3.format(",.2f")(d);
                  }
                }
              }
            };
  
            if (screen.width < 768) {
              this.discreteoptions.chart.showValues = false;
            }
            
            let boxvalues: any = {};
            this.boxplotdata = [];
            let boxYMax = -80;
            let boxYMin = 1000;
            let boxColor: any;
            let plotGrphColor: any;
            let plotgrphColorArr=[];
            if (t1 < t2) {
              boxYMin = t1;
              boxYMax = t2;
            } else if (t1 > t2) {
              boxYMax = t1;
              boxYMin = t2;
            }
            let bestEstArr = [];
            let plusArr = [];
            let minusArr = [];
            let lowestValueArr = [];
            let highestValueArr = [];
            for (let j = 0; j < dataLen; j++) {
              boxvalues = {};
              bestEstArr.push(parseFloat(successData.data[j].predicted));
              plusArr.push(parseFloat(successData.data[j].percentiles.p99)-parseFloat(successData.data[j].percentiles.p50));
              if ( this.curentWeatherVarData.weathertypeid != 1 || ( 2*(parseFloat(successData.data[j].percentiles.p99)-parseFloat(successData.data[j].percentiles.p75)) < parseFloat(successData.data[j].predicted )) ) {
                minusArr.push(parseFloat(successData.data[j].percentiles.p50)-parseFloat(successData.data[j].percentiles.p1));
              } else {
                minusArr.push(parseFloat(successData.data[j].predicted));
              }
  
              lowestValueArr.push(bestEstArr[j] - minusArr[j]);
              highestValueArr.push(bestEstArr[j] + plusArr[j]);
              boxvalues.Q1 = successData.data[j].percentiles.p25;
              boxvalues.Q2 = successData.data[j].predicted;
              boxvalues.Q3 = successData.data[j].percentiles.p75;
              boxvalues.whisker_low = successData.data[j].percentiles.p1;
              boxvalues.whisker_high = successData.data[j].percentiles.p99;
              let dataArr = [
                boxvalues.whisker_low,
                boxvalues.whisker_high,
                t1,
                t2,
                boxYMin,
                boxYMax
              ];
              boxYMin = Math.floor(Math.min(...dataArr));
              boxYMax = Math.ceil(Math.max(...dataArr));
              if (t1 < t2) {
                if (boxvalues.Q2 <= t1) {
                  plotGrphColor = boxColor = "#C64B11"; //RED
                } else if (boxvalues.Q2 > t2) {
                  plotGrphColor = boxColor = "#5FA78B"; //GREEN
                } else if (t1 < boxvalues.Q2 && boxvalues.Q2 <= t2) {
                  plotGrphColor = boxColor = "#FEB000"; //YELLOW
                }
              } else {
                if (boxvalues.Q2 < t2) {
                  plotGrphColor = boxColor = "#5FA78B"; //GREEN
                } else if (boxvalues.Q2 >= t1) {
                  plotGrphColor = boxColor = "#C64B11"; //RED
                } else if (t1 > boxvalues.Q2 && boxvalues.Q2 >= t2) {
                  plotGrphColor = boxColor = "#FEB000"; //yellow
                }
              }
              this.boxplotdata.push({
                label: labels[j],
                color: boxColor,
                values: boxvalues
              });
              plotgrphColorArr.push(plotGrphColor);
            }
            if ( this.modelType == 'MA' ) {
              this.lowest = this.fetchedpredictData.minimum;
              this.mean = this.fetchedpredictData.mean;
              this.highest = this.fetchedpredictData.maximum;
            } else {
              this.lowest = Math.min(...lowestValueArr);
              this.highest = Math.max(...highestValueArr);
              this.mean = parseFloat( bestEstArr.reduce((a, b) =>  parseFloat(a) + parseFloat(b), 0) ) / bestEstArr.length;
            }

            let lowClass;
            let highClass;
            let meanClass;
            if ( this.graphTablePred == 'Graph' ) {
              if ( parseFloat(this.searchState.thresholdData.severe) - parseFloat(this.searchState.thresholdData.alert) > 0) {
                if ( this.lowest >= parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.lowColor = "#C64B11"; //red
                  lowClass = 'result-red'
                } else if ( this.lowest < parseFloat(this.searchState.thresholdData.alert) ) {
                  // this.lowColor = "#5FA78B"; //green
                  lowClass = 'result-green';
                } else if ( parseFloat(this.searchState.thresholdData.alert) <= this.lowest && this.lowest < parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.lowColor = "#FEB000"; //yellow
                  lowClass = 'result-yellow'
                }
    
                if ( this.highest >= parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.highColor = "#C64B11"; //red
                  highClass = 'result-red';
                } else if ( this.highest < parseFloat(this.searchState.thresholdData.alert) ) {
                  // this.highColor = "#5FA78B"; //green
                  highClass = 'result-green';
                } else if ( parseFloat(this.searchState.thresholdData.alert) <= this.highest && this.highest < parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.highColor = "#FEB000"; //yellow
                  highClass = 'result-yellow';
                }
    
                if ( this.mean >= parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.meanColor = "#C64B11"; //red
                  meanClass = 'result-red';
                } else if ( this.mean < parseFloat(this.searchState.thresholdData.alert) ) {
                  // this.meanColor = "#5FA78B"; //green
                  meanClass = 'result-green';
                } else if ( parseFloat(this.searchState.thresholdData.alert) <= this.mean && this.mean < parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.meanColor = "#FEB000"; //yellow
                  meanClass = 'result-yellow';
                }
              } else {
                if ( this.lowest <= parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.lowColor = "#C64B11"; //red
                  lowClass = 'result-red'
                } else if ( this.lowest > parseFloat(this.searchState.thresholdData.alert) ) {
                  // this.lowColor = "#5FA78B"; //green
                  lowClass = 'result-green'
                } else if ( parseFloat(this.searchState.thresholdData.alert) >= this.lowest && this.lowest > parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.lowColor = "#FEB000"; //yellow
                  lowClass = 'result-yellow'
                }
    
                if ( this.highest <= parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.highColor = "#C64B11"; //red
                  highClass = 'result-red';
                } else if ( this.highest > parseFloat(this.searchState.thresholdData.alert) ) {
                  // this.highColor = "#5FA78B"; //green
                  highClass = 'result-green';
                } else if ( parseFloat(this.searchState.thresholdData.alert) >= this.highest && this.highest > parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.highColor = "#FEB000"; //yellow
                  highClass = 'result-yellow';
                }
    
                if ( this.mean <= parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.meanColor = "#C64B11"; //red
                  meanClass = 'result-red';
                } else if ( this.mean > parseFloat(this.searchState.thresholdData.alert) ) {
                  // this.meanColor = "#5FA78B"; //green
                  meanClass = 'result-green';
                } else if ( parseFloat(this.searchState.thresholdData.alert) >= this.mean && this.mean > parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.meanColor = "#FEB000"; //yellow
                  meanClass = 'result-yellow';
                }
              }
            } else {
              if ( parseFloat(this.searchState.thresholdData.severe) - parseFloat(this.searchState.thresholdData.alert) > 0) {
                if ( this.lowestTable >= parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.lowColor = "#C64B11"; //red
                  lowClass = 'result-red'
                } else if ( this.lowestTable < parseFloat(this.searchState.thresholdData.alert) ) {
                  // this.lowColor = "#5FA78B"; //green
                  lowClass = 'result-green'
                } else if ( parseFloat(this.searchState.thresholdData.alert) <= this.lowestTable && this.lowestTable < parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.lowColor = "#FEB000"; //yellow
                  lowClass = 'result-yellow'
                }
    
                if ( this.highestTable >= parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.highColor = "#C64B11"; //red
                  highClass = 'result-red';
                } else if ( this.highestTable < parseFloat(this.searchState.thresholdData.alert) ) {
                  // this.highColor = "#5FA78B"; //green
                  highClass = 'result-green';
                } else if ( parseFloat(this.searchState.thresholdData.alert) <= this.highestTable && this.highestTable < parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.highColor = "#FEB000"; //yellow
                  highClass = 'result-yellow';
                }
    
                if ( this.meanTable >= parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.meanColor = "#C64B11"; //red
                  meanClass = 'result-red';
                } else if ( this.meanTable < parseFloat(this.searchState.thresholdData.alert) ) {
                  // this.meanColor = "#5FA78B"; //green
                  meanClass = 'result-green';
                } else if ( parseFloat(this.searchState.thresholdData.alert) <= this.meanTable && this.meanTable < parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.meanColor = "#FEB000"; //yellow
                  meanClass = 'result-yellow';
                }
              } else {
                if ( this.lowestTable <= parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.lowColor = "#C64B11"; //red
                  lowClass = 'result-red'
                } else if ( this.lowestTable > parseFloat(this.searchState.thresholdData.alert) ) {
                  // this.lowColor = "#5FA78B"; //green
                  lowClass = 'result-green'
                } else if ( parseFloat(this.searchState.thresholdData.alert) >= this.lowestTable && this.lowestTable > parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.lowColor = "#FEB000"; //yellow
                  lowClass = 'result-yellow'
                }
    
                if ( this.highestTable <= parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.highColor = "#C64B11"; //red
                  highClass = 'result-red';
                } else if ( this.highestTable > parseFloat(this.searchState.thresholdData.alert) ) {
                  // this.highColor = "#5FA78B"; //green
                  highClass = 'result-green';
                } else if ( parseFloat(this.searchState.thresholdData.alert) >= this.highestTable && this.highestTable > parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.highColor = "#FEB000"; //yellow
                  highClass = 'result-yellow';
                }
    
                if ( this.meanTable <= parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.meanColor = "#C64B11"; //red
                  meanClass = 'result-red';
                } else if ( this.meanTable > parseFloat(this.searchState.thresholdData.alert) ) {
                  // this.meanColor = "#5FA78B"; //green
                  meanClass = 'result-green';
                } else if ( parseFloat(this.searchState.thresholdData.alert) >= this.meanTable && this.meanTable > parseFloat(this.searchState.thresholdData.severe) ) {
                  // this.meanColor = "#FEB000"; //yellow
                  meanClass = 'result-yellow';
                }
              }
            }

            this.fetchedpredictData.meanClass = meanClass;
            this.fetchedpredictData.highClass = highClass;
            this.fetchedpredictData.lowClass = lowClass;
  
            if (boxYMax == -80) boxYMax = 1;
            if (boxYMin == 1000) boxYMin = 0;
            boxYMin = this.setMinYDomain(boxYMin);
            boxYMax = this.setMaxYDomain(boxYMax);
            setTimeout(() => {
              if (boxYMin == severet1 || boxYMin == alertt2) {
                d3.selectAll(".nvd3 .nv-axis line").style("opacity", 0);
              }
            }, 2000);
            this.plotlyGraphData = this.getPlotlyGraphData(labels, bestEstArr, plusArr, minusArr, plotgrphColorArr),
            
            this.plotlyGraphLayout = {
                                        autosize: true, 
                                        // title: 'Best Estimate',
                                        // barmode: 'group',
                                        // paper_bgcolor: '#464646',
                                        // plot_bgcolor: '#464646', 
                                        // font: {color: 'white'},
                                        showlegend: false,
                                        margin: {
                                          l: 25,
                                          r: 35,
                                          b: 80,
                                          t: 25,
                                          pad: 10
                                        },
                                        
                                        font: {
                                          family: 'neue-haas-grotesk-display',
                                        },
                                        hoverlabel: {
                                            bgcolor: "#1B242E",
                                            bordercolor: "#1B242E",
                                            font: {
                                                family: 'neue-haas-grotesk-display',
                                                color: '#fff',
                                            },
                                        },
                                        // xaxis: {showgrid: false, showline: true, linecolor: '#636363', title: this.predYear + " (" + this.freqName + ")",tickfont: {family: '',size: 15}},
                                        // yaxis: {showgrid: false, showline: true, linecolor: '#636363', zeroline: false, title: graphUnit,tickfont: {family: '',size: 15}},
                                        yaxis: {fixedrange: true,zeroline: false },
                                        xaxis : {fixedrange: true},
                                        shapes: this.getThresholdsLines(t1,t2)
                                     };
                                     
            this.boxplotoptions = {
              chart: {
                type: "boxPlotChart",
                height: 300,
                autoSize: true,
                responsive:true,
                // "max-width": 1000,
                margin: {
                  top: 20,
                  right: 20,
                  bottom: 50,
                  left: 70
                },
                maxBoxWidth: 55,
                yDomain: [boxYMin, boxYMax],
                xAxis: { axisLabel: this.predYear + " (" + this.freqName + ")" },
                yAxis: { showMaxMin: false, axisLabel: graphUnit },
                callback: chart => {
                  this.boxplot.yScale = chart.yAxis.scale();
                  this.boxplot.margin = chart.margin();
                },
                tooltip: {
                  keyFormatter: function(d, i) {
                    if (d == "Q2") return "Best Estimate";
                    else if (d == "Q1") return "Lower 25%";
                    else if (d == "Q3") return "Upper 75%";
                    else return "Limit " + d;
                  }
                }
              }
            };
            setTimeout(() => {
              //<<<---    using ()=> syntax
              if (this.viewPredictGraph) {
                if (this.graphId == 1)
                  this.drawThresholdLines(
                    "predicchart",
                    this.bestEstimate.yScale,
                    this.bestEstimate.margin
                  );
                // if (this.graphId == 2)
                //   this.drawThresholdLines(
                //     "boxplot",
                //     this.boxplot.yScale,
                //     this.boxplot.margin
                //   );
              }
            }, 700);
            if (this.modelType == "MA" || this.modelType == "AI") {
              let d = new Date();
              d.setDate(1);
              d.setHours(-1);
              this.lastUpdatedDate =
                this.months[d.getMonth()] +
                " " +
                d.getDate() +
                " , " +
                d.getFullYear();
            } else {
              let d = new Date();
              if (d.getDate() <= 15) {
                d.setDate(1);
                d.setHours(-1);
                this.lastUpdatedDate =
                  this.months[d.getMonth()] +
                  " " +
                  d.getDate() +
                  " , " +
                  d.getFullYear();
              } else {
                d.setDate(15);
                this.lastUpdatedDate =
                  this.months[d.getMonth()] +
                  " " +
                  d.getDate() +
                  " , " +
                  d.getFullYear();
              }
            }
            this.fetchedpredictData.lastUpdatedDate = this.lastUpdatedDate
            // if (this.modelType == "MA")
            //   this.setLowestMeanHighestForLTF(this.predictgraphObj.output);
          },
          error => {
            this.loader.display(false);
            this.isError = true;
            let errordata = error.error?error.error:error;
            this.errorMsg = errordata.error;
            // this.errorFlag = true;
            // this.showGraph = false;
            // this.errmsg = errordata.error;
            // let errObj: any = {};
            // (errObj.code = error.status),
            //   (errObj.eventId = sessionStorage.getItem("eventId")),
            //   (errObj.url = "predict_data/get_ltf_graph");
            // (errObj.frontUrl = "/insightgraph"),
            //   (errObj.apiBody = this.predictgraphObj);
            // if (error.status == 404 || error.status == 500) {
            //   this.callErrorMail(errObj);
            // }
            // this.getPopUpVal(true);
          }
        );
      } else {
        //this.connectionService.display(true);
        this.loader.display(false);
      }
    }

    getRiskGraph() {
      if (navigator.onLine == true) {
        this.loader.display(true);
        this.fetchedRiskData = '';
        this.riskDataToBePassed = '';
        let graphStartDate:any;
        let graphEndDate:any;
        if ( this.searchState.datestart == this.customizedStartDate && this.searchState.dateend == this.customizedEndDate ) {
          graphStartDate = this.searchState.datestart;
          graphEndDate = this.searchState.dateend;
        } else {
          this.searchState.datestart = graphStartDate = this.customizedStartDate;
          this.searchState.dateend = graphEndDate = this.customizedEndDate;
        }
        let key = {
          years: this.currRiskYearData.slug,
          causes: this.currRiskData.itemName.toLowerCase(),
          country: this.eventDetails.country,
          postcode: this.eventDetails.postcode,
          mode: this.currRiskModeData.itemName.toLowerCase(),
          page: this.riskGraphPage,
          lat: this.eventDetails.latitude,
          lon: this.eventDetails.longitude,
          categories: this.hurricaneCategories,
          noOfRecord: this.riskNoOfRecord,
          start_date: graphStartDate,
          end_date: graphEndDate,
        }
        if(this.currentType =='hurricane' && this.hurricaneCurrentModelName=='Advanced Risk' && this.userSector==2){
          key['modeltype'] = 'advanced';
          key['years']="Ana";
          key['mode']="percentage";
          this.riskMode = 'Percentage'
        }
        let body = JSON.stringify(key);
        
        // this.graphObj.years = this.riskYearSlug;
        // this.graphObj.causes = this.riskName.toLowerCase();
        // this.graphObj.country = this.country;
        // this.graphObj.postcode = this.postcode;
        // this.graphObj.mode = this.riskModeName.toLowerCase();
        // this.graphObj.page = this.currentRiskPage;
        // this.graphObj.lat = this.lat;
        // this.graphObj.lon = this.lon;
        // this.graphObj.categories = this.hurricaneCategories;
        // this.graphObj.noOfRecord = this.riskNoOfRecord;
        this.enquiriesService.fetchRiskgraphdata(body).pipe(takeUntil(this.destroy$)).subscribe(
          data => {
            this.LS.logOut({
              title:
                "Report Graph (" +
                (this.eventDetails.event_name ? this.eventDetails.event_name + " " : "") +
                this.eventDetails.locationName +
                ")",
              link: "#/report",
              description:
                "Risk Data -" +
                this.currRiskData.itemName.toLowerCase() +
                "- Country:" +
                this.eventDetails.country +
                ", Postcode:" +
                this.eventDetails.postcode +
                ", Mode:" +
                this.currRiskModeData.itemName.toLowerCase(),
                eventId: this.eventId,
                //modelId: this.currentModelData.id
            }).pipe(takeUntil(this.destroy$)).subscribe(
              data => {},
              error => {
                this.router.navigate(["/login"]);
              }
            );
            this.riskImgNames = [];
            this.imgPath = "";
            this.weekNo = "";
            this.loader.display(false);
            let dd = JSON.parse(JSON.stringify(data));
            let successData = dd;
            this.riskDataToBePassed = successData;
            this.riskDataToBePassed.page = this.riskGraphPage;
            // if (this.dataViewId == 1) {
            //   this.viewGraph = true;
            // } else if (this.dataViewId == 2) {
            //   this.viewGraph = false;
            // }
            // this.getPopUpVal(false);
            let categories = [];
            if (successData.data.length > 0) {
               this.fetchedRiskData = successData.data;
              // console.log(this.fetchedRiskData);
              // this.fetchedRiskData[0].markers[0] = 2;
              // this.fetchedRiskData[1].markers = [1];
              // this.fetchedRiskData[2].markers = [5];
              // this.fetchedRiskData[3].markers = [2];
              // this.fetchedRiskData[5].markers = [3];
              let categoriesLen = this.fetchedRiskData[0].markers.length;
              this.riskMoreData = successData.more;
              // if (successData.more == 1) {
              //   this.disablePreviousRisk = false;
              //   this.disableNextRisk = false;
              //   this.disableViewMoreRisk = false;
              // } else {
              //   this.disableNextRisk = true;
              //   this.disablePreviousRisk = false;
              //   this.disableViewMoreRisk = true;
              // }
              // if (this.currentRiskPage == 1) {
              //   this.disablePreviousRisk = true;
              // }
              this.riskImgNames = successData.images;
              if (this.riskImgNames.length > 0) {
                // this.showRightScroll = true;
                this.changeRiskImage(this.riskindex);
              }
              this.risktableHead = this.fetchedRiskData[0].markerLabels;
              this.catKeyArr = this.fetchedRiskData[0].markerLabels;
              this.colorArr = [];
              if (this.currRiskData.itemName.toLowerCase() == "hurricanes") {
                this.riskId = 1;
                this.colorArr = [
                  "#88e8ff",
                  "#4fd6f7",
                  "#1fc7f1 ",
                  "#04b2de",
                  "#0391b5",
                  "#0A718A"
                ];
              } else if (this.currRiskData.itemName.toLowerCase() == "tornadoes") {
                this.riskId = 2;
                this.colorArr = [
                  "#F3A5F3",
                  "#F988F9",
                  "#F961F9",
                  "#D839D8",
                  "#C720C7",
                  "#AB02AB"
                ];
              } else if (this.currRiskData.itemName.toLowerCase() == "earthquakes") {
                this.riskId = 3;
                this.colorArr = [
                  "#F3D9B9",
                  "#F1CFA5",
                  "#F3C486",
                  "#F7B864",
                  "#F99F28",
                  "#d87b00"
                ];
              } else if (this.currRiskData.itemName.toLowerCase() == "volcanoes") {
                this.riskId = 4;
                this.colorArr = [
                  "#E67373",
                  "#CA5454",
                  "#B13F3F",
                  "#A52B2B",
                  "#A71616",
                  "#960000"
                ];
              } else if (this.currRiskData.itemName.toLowerCase() == "wildfire") {
                this.riskId = 5;
                this.colorArr = [
                  "#E67373",
                  "#CA5454",
                  "#B13F3F",
                  "#A52B2B",
                  "#A71616",
                  "#960000"
                ];
              }
              let ymax = 1;
              let riskDataLen = this.fetchedRiskData.length;
              this.tableRiskData = [];
              for (let i = 0; i < riskDataLen; i++) {
                let prefix =
                  this.currRiskData.itemName.toLowerCase() == "tornadoes" ||
                  this.currRiskData.itemName.toLowerCase() == "hurricanes" || this.currRiskData.itemName.toLowerCase() == "wildfire"
                    ? "Week "
                    : "";
                for (let j = 0; j < categoriesLen; j++) {
                  typeof categories[j] == "undefined" ? (categories[j] = []) : "";
                  categories[j].push({
                    x: prefix + this.fetchedRiskData[i].title,
                    y: this.fetchedRiskData[i].markers[j]
                  });
                }
                this.tableRiskData.push({
                  riskData: this.fetchedRiskData[i].markers,
                  riskTitle: this.fetchedRiskData[i].title,
                  eventdetail: this.fetchedRiskData[i].eventdetail
                    ? this.fetchedRiskData[i].eventdetail
                    : []
                });
              }
              // if (this.riskModeName == "Percentage") {
              //   if (screen.width < 768) {
              //     this.riskoptions = {
              //       chart: {
              //         type: "multiBarChart",
              //         transitionDuration: 500,
              //         stacked: true,
              //         showControls: false,
              //         showLegend: false,
              //         height: "300",
              //         reduceXTicks: false,
              //         //  yDomain: [0, ymax],
              //         yAxis: {
              //           showMaxMin: false,
              //           tickFormat: function(d) {
              //             return d;
              //           }
              //         },
              //         xAxis: {
              //           tickFormat: function(d) {
              //             let splitdStr = d.split(" ");
              //             return splitdStr[1];
              //           }
              //         }
              //       }
              //     };
              //   } else {
              //     this.riskoptions = {
              //       chart: {
              //         type: "multiBarChart",
              //         transitionDuration: 500,
              //         stacked: true,
              //         showControls: false,
              //         showLegend: false,
              //         height: "300",
              //         reduceXTicks: false,
              //         //  yDomain: [0, ymax],
              //         yAxis: {
              //           showMaxMin: false,
              //           tickFormat: function(d) {
              //             return parseFloat(d).toFixed(2);
              //           }
              //         }
              //       }
              //     };
              //   }
              // } else {
              //   if (screen.width < 768) {
              //     this.riskoptions = {
              //       chart: {
              //         type: "multiBarChart",
              //         transitionDuration: 500,
              //         stacked: true,
              //         showControls: false,
              //         showLegend: false,
              //         height: "300",
              //         reduceXTicks: false,
              //         //  yDomain: [0, ymax],
              //         yAxis: {
              //           showMaxMin: false,
              //           tickFormat: function(d) {
              //             let val = parseInt(d);
              //             let dec = d - val;
              //             if (dec == 0) return d3.format("")(d);
              //             else return "";
              //           }
              //         },
              //         xAxis: {
              //           tickFormat: function(d) {
              //             let splitdStr = d.split(" ");
              //             return splitdStr[1];
              //           }
              //         }
              //       }
              //     };
              //   } else {
              //     this.riskoptions = {
              //       chart: {
              //         type: "multiBarChart",
              //         transitionDuration: 500,
              //         stacked: true,
              //         showControls: false,
              //         showLegend: false,
              //         height: "300",
              //         reduceXTicks: false,
              //         //  yDomain: [0, ymax],
              //         yAxis: {
              //           showMaxMin: false,
              //           tickFormat: function(d) {
              //             let val = parseInt(d);
              //             let dec = d - val;
              //             if (dec == 0) return d3.format("")(d);
              //             else return "";
              //           }
              //         }
              //       }
              //     };
              //   }
              // }
              this.fetchedRiskData = [];
              for (let k = 0; k < categoriesLen; k++) {
                this.fetchedRiskData.push({
                  key: this.catKeyArr[k],
                  color: this.colorArr[k],
                  values: categories[k]
                });
              }
              this.showGraph = true;
            } else {
              this.loader.display(false);
              this.isError = true;
              this.errorMsg = "An error has occurred in fetching your "+this.currRiskData.itemName+" data. Our support team has been notified.";
            }
          },
          error => {
            this.loader.display(false);
            this.isError = true;
            let errordata = error.error?error.error:error;
            this.errorMsg = errordata.error;
            // this.loaderService.display(false);
            // this.errorFlag = true;
            // this.showGraph = false;
            // this.errmsg = errordata.error;
            // let errObj: any = {};
            // (errObj.code = error.status),
            //   (errObj.eventId = sessionStorage.getItem("eventId")),
            //   (errObj.url = "risk_data/get_risk_detail");
            // (errObj.frontUrl = "/insightgraph"), (errObj.apiBody = this.graphObj);
            // if (error.status == 404 || error.status == 500) {
            //   this.callErrorMail(errObj);
            // }
            // this.getPopUpVal(true);
          }
        );
      } else {
        //this.connectionService.display(true);
        this.loader.display(false);
      }
    }

    getNextPrevRiskMapData(action) {
      if (action=='next') {
        this.rightScroll();
      } else {
        this.leftScroll();
      }
    }

    leftScroll() {
      let min = 0;
      if (min == this.riskindex) {
        this.riskindex = 0;
        this.showleftScroll = false;
        this.showRightScroll = true;
      } else {
        this.riskindex--;
        if (min == this.riskindex) {
          this.showleftScroll = false;
          this.showRightScroll = true;
        } else {
          this.showleftScroll = true;
          this.showRightScroll = true;
        }
      }
      this.changeRiskImage(this.riskindex);
    }
  
    rightScroll() {
      let max = this.riskImgNames.length - 1;
      if (max == this.riskindex) {
        this.riskindex = max;
        this.showRightScroll = false;
        this.showleftScroll = true;
      } else {
        this.riskindex++;
        if (max == this.riskindex) {
          this.showleftScroll = true;
          this.showRightScroll = false;
        } else {
          this.showleftScroll = true;
          this.showRightScroll = true;
        }
      }
      this.changeRiskImage(this.riskindex);
    }

    changeRiskImage(index) {
      if (navigator.onLine == true) {
        this.riskImageData = '';
        this.loader.display(true);
        this.enquiriesService
          .getImage({ filename: this.riskImgNames[index].imagename })
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            data => {
              let dd = JSON.parse(JSON.stringify(data));
              let successData = dd;
              this.riskImageData = successData;
              this.imgPath = successData.image;
              let imgStr = successData.imagename;
              let splitdArr = imgStr.split("/");
              let lastIndexArr = splitdArr[splitdArr.length - 1].split("_");
              let fullWeekNameArr = lastIndexArr[lastIndexArr.length - 1].split(
                "."
              );
              let weekNameArr = fullWeekNameArr[0].split("Week");
              this.weekNo = weekNameArr[1];
              this.weekText = this.weekNo ? "Week" : "";
              this.loader.display(false);
            },
            error => {
              this.loader.display(false);
              // let errordata = error.error?error.error:error;
              // this.loaderService.display(false);
              // this.errorFlag = true;
              // this.showGraph = false;
              // this.errmsg = errordata.error;
              // let errObj: any = {};
              // (errObj.code = error.status),
              //   (errObj.eventId = sessionStorage.getItem("eventId")),
              //   (errObj.url = "risk_data/get_risk_image");
              // (errObj.frontUrl = "/insightgraph"),
              //   (errObj.apiBody = {
              //     filename: this.riskImgNames[index].imagename
              //   });
              // if (error.status == 404 || error.status == 500) {
              //   this.callErrorMail(errObj);
              // }
              // this.getPopUpVal(true);
            }
          );
      } else {
        //this.connectionService.display(true);
        this.loader.display(false);
      }
    }
    
    convertThresholds(value, unit, isString = 0) {
      let t = value;    
      if (unit == "miles/h") return t * 0.447;
      if (unit == "F") return ((t - 32) / 1.8);
      if (unit == "inches" && this.weatherId == 1) {
        return t * 25.4;
      }
      if (unit == "inches" && this.weatherId == 4) {
        return t * 2.54;
      }
      return value;
    }

    convertUnit(value, unit, isString = 0) {
      let weatherId = this.curentWeatherVarData.weathertypeid;
      let val = parseFloat(this.unitConvertPipe.transform(value, unit, undefined, undefined, weatherId));
      if (isString == 1) return val;
      return val.toFixed(2);
    }
    
    shuffleIdsOfExtremeWeatherModels(data) {
      let len = data.length;
      for (let w = 0; w < len; w++) {
        let modLen = data[w].data.models.length;
        let models:any=[];
        if ( data[w].id == 4 || data[w].id == 5 || data[w].id == 6 || data[w].id == 7 || data[w].id == 8 ) {
          if ( modLen > 1 ) {
            models.push(data[w].data.models[1]);
            models.push(data[w].data.models[0]);
            data[w].data.models = models;
          }
        }
      }
    }

    removeExpModelMoreThan24Months(data) {
      let len = data.length;
      let todaysDate = new Date();
      //let nOfMonths = ( this.country == "GB" || this.country == "US" ) ? 59 : 23;
      let nOfMonths = this.country == "GB" ? 59 : 23;
      todaysDate.setMonth(todaysDate.getMonth() + nOfMonths);
      let yr = todaysDate.getFullYear();
      let month = todaysDate.getMonth();
      let lastDateOfMonth = this.getLastDayOfMonth(yr, month);
      todaysDate.setDate(lastDateOfMonth);
      let eventStartDate = new Date(this.stdate);
      let eventEndDate = new Date(this.edate);
      if (eventStartDate >= todaysDate || eventEndDate >= todaysDate) {
        for (let w = 0; w < len; w++) {
          let modLen = data[w].data.models.length;
          for (let m = 0; m < modLen; m++) {
            if (
              data[w].data.models[m].id == 2 ||
              data[w].data.models[m].id == 4 ||
              data[w].data.models[m].id == 6 ||
              data[w].data.models[m].id == 27 ||
              data[w].data.models[m].id == 29 ||
              data[w].data.models[m].id == 31 ||
              data[w].data.models[m].id == 33 ||
              data[w].data.models[m].id == 35
            ) {
                data[w].data.models.splice(m, 1);
              }
            }
          }
      }
    }

    getLastDayOfMonth(y, m) {
      return new Date(y, m + 1, 0).getDate();
    }
    
    getAllWeathersWithWeathrVarType(weatherData) {
      let newWeatherData: Array<{
        id: number;
        name: string;
        weatherVarTypeId: number;
        weatherVarTypeName: string;
        combinedName: string;
        newCombinedName: string;
        models: any;
        k1: any;
        k2: any;
        k3: any;
        unit: any;
        stationId: string;
        stationLat: any;
        stationLon: any;
      }> = [];
      let ind = 0;
      let weatherLen = weatherData.length;
      for (let w = 0; w < weatherLen; w++) {
        let weatherId = weatherData[w].id;
        let weatherName = weatherData[w].name;
        let weatherDisplayName = weatherData[w].displayName;
        let weatherModels = weatherData[w].data.models;
        let k1 = weatherData[w].data.k1;
        let k2 = weatherData[w].data.k2;
        let k3 = weatherData[w].data.k3;
        let unit = weatherData[w].data.unit;
        let stationId = weatherData[w].stationId;
        let stationLat = weatherData[w].stationLat;
        let stationLon = weatherData[w].stationLon;
        let headLen = weatherData[w].data.head.length;
        for (let h = 0; h < headLen; h++) {
          let weatherVarTypeId = weatherData[w].data.head[h].id;
          let weatherVarTypeName = weatherData[w].data.head[h].name;
          let weatherVarTypeDisplayName = weatherData[w].data.head[h].displayName;
          let combinedName = "";
          let newCombinedName = "";
          if (weatherVarTypeId == 3 || weatherVarTypeId == 4 || weatherVarTypeId == 9 ) {
            combinedName = weatherDisplayName + " " + weatherVarTypeName;
            newCombinedName = weatherDisplayName + " " + weatherVarTypeDisplayName;
          } else {
            combinedName = weatherVarTypeName + " " + weatherDisplayName;
            newCombinedName = weatherVarTypeDisplayName + " " + weatherDisplayName;
          }
          newWeatherData.push({
            id: weatherId,
            name: weatherName,
            weatherVarTypeId: weatherVarTypeId,
            weatherVarTypeName: weatherVarTypeName,
            combinedName: combinedName,
            newCombinedName: newCombinedName,
            models: weatherModels,
            k1: k1,
            k2: k2,
            k3: k3,
            unit: unit,
            stationId: stationId,
            stationLat: stationLat,
            stationLon: stationLon
          });
          ind++;
        }
      }
      return newWeatherData;
    }

    nextprevgraph(type){
      this.hourlypage = 1
      if(type=="next") {
        this.showloadergraph = true
        this.loaderhtimegraph = 0
        this.gethourlyloadertimegraph(0)
        this.graphpage++;
        this.showGraph=false;
        this.getStatisticalGraph();
      }
      if(type=="prev") {
        this.showloadergraph = true
        this.loaderhtimegraph = 0
        this.gethourlyloadertimegraph(0)
        this.graphpage--;
        this.showGraph=false;
        this.getStatisticalGraph();
      }
    }

    getNextPreviousPlotlyData(type){
      if(type=="next") {
        this.currentPredicPage++;
        this.showGraph=false;
        this.getPredictionGraph();
      }
      if(type=="prev") {
        this.currentPredicPage--;
        this.showGraph=false;
        this.getPredictionGraph();
      }
    }

    getNextPreviousRiskGraphData(type){
      if(type=="next") {
        this.riskGraphPage++;
        this.getRiskGraph();
      }
      if(type=="prev") {
        this.riskGraphPage--;
        this.getRiskGraph();
      }
    }


    //New combine pdf creation for graph and overview
    downloadreportzip(){
      //this.loader.display(true);
      setTimeout(()=>{
        if (this.type=='stats') {
            this.getStatisticalGraphForPDF(); 
        } else if ( this.type=='predict' ) {
          this.downloadOverviewPredDataInPDF();
        } else {
          this.downloadOverviewRiskDataInPDF();
        }
      },500)
    }

    downloadOverviewPredDataInPDF() {
      if (navigator.onLine == true) {
        let startdate = this.formateDate(new Date(`${this.customizedStartDate.split('/')[2]}/${this.customizedStartDate.split('/')[1]}/${this.customizedStartDate.split('/')[0]}`))
        let enddate = this.formateDate(new Date(`${this.customizedEndDate.split('/')[2]}/${this.customizedEndDate.split('/')[1]}/${this.customizedEndDate.split('/')[0]}`));
        this.LS.logOut({
          title:
            "Download PDF - Prediction - (" +
            (this.eventDetails.enent_name ? this.eventDetails.enent_name + " " : "") +
            this.eventDetails.locationName +
            ")",
          description: this.currentModelData.model_name+' '+this.curentWeatherVarName+' '+this.currentType+' , Unit:'+this.getThresholdsData.unit+" Alert:" +this.searchState.thresholdData.alert +", Severe:" +this.searchState.thresholdData.severe +", Analogue : " +this.currentModelData.analogue+", From " +this.customizedStartDate +" to " +this.customizedEndDate,
          link: "#/report",
          eventId: this.eventId,
          modelId: this.currentModelData.id
        }).pipe(takeUntil(this.destroy$)).subscribe(data => {}, error => {});
        this.loader.display(true);
        // console.log(this.eventDetails);
        // console.log(this.searchState);
        let fileName = 'DPH'+this.eventId+'_Prediction_'+this.currentModelData.model_name+'_'+this.currentWeatherWithVarType+'_';
        if(this.currentType=='wind' || this.currentType=='temperature'){
          fileName = 'DPH'+this.eventId+'_Prediction_'+this.currentModelData.model_name+'_'+this.currFreq.name+'_'+this.currentWeatherWithVarType+'_';
        }
        let body = JSON.stringify({
          StationName: this.fetchedpredictData.StationName,
          alert: this.searchState.thresholdData.alert,
          severe: this.searchState.thresholdData.severe,
          elevation: this.fetchedpredictData.elevation,
          data: this.searchState.predOverviewData.data,
          freqDispName: ( this.currFreq.name == 'Monthly' ) ? 'Month' : 'Week',
          frequency: this.currFreq.name,
          overviewflag: true,
          modelType: this.modelType,
          modelName: this.currentModelData.model_name,
          printType: 'predict',
          eventId: this.eventId,
          eventName: this.eventDetails.event_name,
          groupName: '',
          identifier: this.eventDetails.identifier,
          "startDate":this.formateDate(new Date(`${this.customizedStartDate.split('/')[2]}/${this.customizedStartDate.split('/')[1]}/${this.customizedStartDate.split('/')[0]}`)),
          "endDate":this.formateDate(new Date(`${this.customizedEndDate.split('/')[2]}/${this.customizedEndDate.split('/')[1]}/${this.customizedEndDate.split('/')[0]}`)),
          weatherName: this.curentWeatherVarName+' '+this.currentType,
          weatherSymbol: this.getThresholdsData.symbol,
          weatherUnit: this.getThresholdsData.unit,
          wthrNm: this.currentType,
          filedownloadtime: moment(new Date()).format('ddd')+'_'+moment(new Date()).format('MMM_DD_YYYY_HH:mm:ss'),
          fileName: fileName,
          locationName: this.eventDetails.locationName,
          filedatetime:moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          whternamepdf: this.currentWeatherWithVarType.replace('_',' ')
        });
        
        let filename = fileName+'Graph_'+moment(new Date()).format('ddd')+'_'+moment(new Date()).format('MMM_DD_YYYY_HH:mm:ss');
        this.enquiriesService.downloadoverviewPredPDF(body).subscribe(
          data => {
            // let dd = JSON.parse(JSON.stringify(data));
            // let res = dd;
            // let str = res.slice(167);
            // this.downloadnew(str, filename);
            this.loader.display(false);
            this.closepopup = true;
          },
          error => {
            this.loader.display(false);
          }
        );
      } else {
        //this.connectionService.display(true);
        this.loader.display(false);
      }
    }

    downloadOverviewRiskDataInPDF() {
      if (navigator.onLine == true) {
        
        let riskName = this.currRiskData.itemName
        if ( this.currentType == 'hurricane' ) 
          riskName = this.hurricaneTitle;

          this.LS.logOut({
            title:
              "Download PDF - Risk - (" +
              (this.eventDetails.event_name ? this.eventDetails.event_name + " " : "") +
              this.eventDetails.locationName +
              ")",
            description: "Report Screen - "+riskName +" Risk PDF download",
            link: "#/report",
            eventId: this.eventId,
            //modelId: this.currentModelData.id
          }).pipe(takeUntil(this.destroy$)).subscribe(data => {}, error => {});  
        this.loader.display(true);
        let model = 'Standard';
        if(this.currentType =='hurricane' && this.userSector==2){
          if(this.hurricaneCurrentModelName=='Advanced Risk'){
            model = 'Advanced';
          } 
        }
        let fileName = 'DPH'+this.eventId+'_Risk_'+model+'_'+riskName+'_'+this.currRiskYearData.itemName+'_';
        let key = {
          printType: 'risk',
          riskModeName: this.currRiskModeData.itemName,
          riskYearName: this.currRiskYearData.itemName,
          overviewRiskdataResponse: this.overviewRiskdataResponse,
          riskName: riskName,
          eventId: this.eventId,
          eventName: this.eventDetails.event_name,
          groupName: '',
          identifier: this.eventDetails.identifier,
          startDate:this.formateDate(new Date(`${this.customizedStartDate.split('/')[2]}/${this.customizedStartDate.split('/')[1]}/${this.customizedStartDate.split('/')[0]}`)),
          endDate:this.formateDate(new Date(`${this.customizedEndDate.split('/')[2]}/${this.customizedEndDate.split('/')[1]}/${this.customizedEndDate.split('/')[0]}`)),
          wthrNm: this.currentType,
          filedownloadtime: moment(new Date()).format('ddd')+'_'+moment(new Date()).format('MMM_DD_YYYY_HH:mm:ss'),
          fileName: fileName,
          locationName: this.eventDetails.locationName,
          modelName:model,
          filedatetime:moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        }
        if(this.currentType =='hurricane' && this.userSector==2){
          if(this.hurricaneCurrentModelName=='Advanced Risk'){
            key['modelnamehurricane'] = 'Advanced';
          } else{
            key['modelnamehurricane'] = 'Standard';
          }
        } else{
          key['modelnamehurricane']='';
        }
        let body = JSON.stringify(key);
        this.enquiriesService.downloadoverviewRiskPDF(body).subscribe(
          data => {
            // let dd = JSON.parse(JSON.stringify(data));
            // let res = dd;
            // let str = res.slice(167);
            // this.download(str, "Risk_overview_");
            this.loader.display(false);
            this.closepopup = true;
          },
          error => {
            this.loader.display(false);
          }
        );
      } else {
        //this.connectionService.display(true);
        this.loader.display(false);
      }
    }

    // download(base64Data, fn) {
    //   if (window.navigator.msSaveOrOpenBlob) {
    //     base64Data = base64Data.replace(/\s/g, "");
    //   }
    //   let arrBuffer = this.base64ToArrayBuffer(base64Data);
    //   let newBlob = new Blob([arrBuffer], { type: "application/pdf" });
    //   let filename = fn + new Date() + ".pdf";
    //   saveAs(newBlob, filename);
    // }
    // downloadnew(base64Data, fn) {
    //   if (window.navigator.msSaveOrOpenBlob) {
    //     base64Data = base64Data.replace(/\s/g, "");
    //   }
    //   let arrBuffer = this.base64ToArrayBuffer(base64Data);
    //   let newBlob = new Blob([arrBuffer], { type: "application/pdf" });
    //   let filename = fn +".pdf";
    //   saveAs(newBlob, filename);
    // }

    // base64ToArrayBuffer(data) {
    //   var binaryString = window.atob(data);
    //   var binaryLen = binaryString.length;
    //   var bytes = new Uint8Array(binaryLen);
    //   for (var i = 0; i < binaryLen; i++) {
    //     var ascii = binaryString.charCodeAt(i);
    //     bytes[i] = ascii;
    //   }
    //   return bytes;
    // }

    getStatisticalGraphForPDF() {
        this.loader.display(true);
        this.graphStartDate = new Date(this.eventDetails.startDate);
        this.graphEndDate = new Date(this.eventDetails.endDate);
        let stDate = this.eventDetails.startDate;
        let endDt = this.eventDetails.endDate;
        let diffc = this.graphEndDate.getTime() - this.graphStartDate.getTime();
        let graphObjPDF = JSON.parse(JSON.stringify(this.graphObj));
        graphObjPDF.Start_Date = this.eventDetails.startDate;//sessionStorage.getItem("startDate");
        graphObjPDF.End_Date = this.eventDetails.endDate;//sessionStorage.getItem("endDate");
        let strtDtt = new Date(this.eventDetails.startDate);
        let enddDtt = new Date(this.eventDetails.endDate);
        if (
          strtDtt < this.graphStartDate ||
          strtDtt > this.graphStartDate ||
          enddDtt < this.graphEndDate ||
          enddDtt > this.graphEndDate
        ) {
          graphObjPDF.Start_Date =
            this.graphStartDate.getDate() +
            "/" +
            (this.graphStartDate.getMonth() + 1) +
            "/" +
            this.graphStartDate.getFullYear();
          graphObjPDF.End_Date =
            this.graphEndDate.getDate() +
            "/" +
            (this.graphEndDate.getMonth() + 1) +
            "/" +
            this.graphEndDate.getFullYear();
        } else if (
          graphObjPDF.Start_Date == null ||
          graphObjPDF.End_Date == null
        ) {
          // For the past event
          graphObjPDF.Start_Date =
            strtDtt.getDate() +
            "/" +
            (strtDtt.getMonth() + 1) +
            "/" +
            strtDtt.getFullYear();
          graphObjPDF.End_Date =
            enddDtt.getDate() +
            "/" +
            (enddDtt.getMonth() + 1) +
            "/" +
            enddDtt.getFullYear();
        }

        if ( graphObjPDF.Start_Date == this.customizedStartDate && graphObjPDF.End_Date == this.customizedEndDate ) {

        } else {
          graphObjPDF.Start_Date = this.customizedStartDate;
          graphObjPDF.End_Date = this.customizedEndDate;
        }
        graphObjPDF.Num_Years = this.curentDurationData.id;
        graphObjPDF.Wparam = this.currentType;
        graphObjPDF.Type = this.curentWeatherVarData.name;
        graphObjPDF.StationID = this.curentWeatherVarData.stationId;
        //graphObjPDF.T1 = this.convertThresholds(this.dataObj[0].min,this.dataObj[0].unit,1);
        //graphObjPDF.T2 = this.convertThresholds(this.dataObj[1].min,this.dataObj[0].unit,1);
        // this.graphObj.T1 = this.convertThresholds(this.searchState.thresholdData.alert,this.curentWeatherVarData.unit,1);//this.searchState.thresholdData.alert;//this.convertThresholds(this.searchState.thresholdData.alert,this.dataObj[0].unit,1);
        // this.graphObj.T2 = this.convertThresholds(this.searchState.thresholdData.severe,this.curentWeatherVarData.unit,1);//this.convertThresholds(this.dataObj[1].min,this.dataObj[0].unit,1);
        let unit = this.searchState.thresholdData.unit;
        let t1 = this.convertOverviewThresholds(this.searchState.thresholdData.alert,unit,1,this.curentWeatherVarData.weathertypeid);
        let t2 = this.convertOverviewThresholds(this.searchState.thresholdData.severe,unit,1,this.curentWeatherVarData.weathertypeid);
        
        graphObjPDF.T1 = t1;//this.convertThresholds(this.searchState.thresholdData.alert,this.curentWeatherVarData.unit,1);
        graphObjPDF.T2 = t2;//this.convertThresholds(this.searchState.thresholdData.severe,this.curentWeatherVarData.unit,1);
        graphObjPDF.unit = this.searchState.thresholdData.unit;
        graphObjPDF.Analogue = this.currentModelData.analogue;
        graphObjPDF.page = null;
        // graphObjPDF.noOfRecord = this.statNoOfRecord;
        this.fetchedPDFData =null;
        if(this.hourlySelected == 'hourly' && ['GB','US'].includes(this.eventDetails.country)){
          graphObjPDF.hour_list = [this.startHours, this.endHours]
        }
        this.newenquiryService.fetchgraphdata(graphObjPDF).subscribe(
          data => {
            let dd = JSON.parse(JSON.stringify(data));
            let successData = dd;
            this.fetchedPDFData = successData;
            this.xlsxhourdata = [];

              if(this.hourlySelected == 'hourly'){
                this.fetchedPDFData.percentage.forEach(element => {

                  let dtnm = parseInt(element.Date.substring(0,2))+' '+ this.monthlist[parseInt(element.Date.substring(3,5))]+ ' ' + element.Date.substring(6,10)
                  this.xlsxhourdata.push({
                      date: dtnm,
                      title: element.title,
                      alertPercentage: element.T1+'%',
                      severePercentage: element.T2+'%'
                    });
                  });
              }
              
            if (this.showAdvancedType == "table") {
              this.pdfGraphData = successData.count;
            } else {
              this.pdfGraphData = successData.percentage;
            }
            this.preparePDF();
            //this.loader.display(false);
          },
          error => {
            this.preparePDF();
            if ( error.name == 'HttpErrorResponse' && error.status == 0 && error.statusText == 'Unknown Error' ) {
              this.loader.display(false);
            } else {
              let errordata = error.error?error.error:error;
              this.loader.display(false);
              
            }
          }
        );
        
    }

    preparePDF(){
      // console.log(this.curentWeatherVarData);
      let currWeatherId = this.curentWeatherVarData.weathertypeid;
      let unit = this.searchState.thresholdData.unit;
      let highest = this.convertUnit(this.searchState.overviewData.highest, unit);
      let lowest = this.convertUnit(this.searchState.overviewData.lowest,unit);
      let mean = this.convertUnit(this.searchState.overviewData.mean,unit);
      let weatherName ='';
      if (this.curentWeatherVarData.id == 3 || this.curentWeatherVarData.id == 4 || this.curentWeatherVarData.id == 9) {
        weatherName = `${this.currentType[0].toUpperCase()+this.currentType.slice(1)} ${this.curentWeatherVarData.name[0].toUpperCase()+this.curentWeatherVarData.name.slice(1)}`;
      } else {
        weatherName = `${this.curentWeatherVarData.name[0].toUpperCase()+this.curentWeatherVarData.name.slice(1)} ${this.currentType[0].toUpperCase()+this.currentType.slice(1)}`;
      }
      let symbol = this.curentWeatherVarData.symbol?this.curentWeatherVarData.symbol.trim():this.curentWeatherVarData.symbol;
      let statsProbT1=0;
      let statsProbT2=0;
      if ( currWeatherId != 5 && currWeatherId != 6 && currWeatherId != 7 && currWeatherId != 8 ) {
        statsProbT1 = this.overviewPythonData.prob_T1;
        statsProbT2 = this.overviewPythonData.prob_T2;
      }

      if ( this.userSector == 3 ) {
        if ( currWeatherId != 5 && currWeatherId != 6 && currWeatherId != 7 && currWeatherId != 8 ) {
          if ( this.overviewPythonData.prob_T1>0.1 || this.overviewPythonData.prob_T1==0 ) {
            statsProbT1 = this.probConvertPipe.transform(statsProbT1,this.totalNoOfDaysInPeriod);
          } else {
            statsProbT1 = this.probConvertPipe.transform(0.1,this.totalNoOfDaysInPeriod);
          }
          if ( this.overviewPythonData.prob_T2>0.1 || this.overviewPythonData.prob_T2==0 ) {
            statsProbT2 = this.probConvertPipe.transform(statsProbT2,this.totalNoOfDaysInPeriod);
          } else {
            statsProbT2 = this.probConvertPipe.transform(0.1,this.totalNoOfDaysInPeriod);
          }
        }
      }
      
      let base64data = this.exportHistoricalXLSX();
      let overviewDataN = {
      "type":this.type,
      "eventName":this.eventDetails.event_name,///api/insight/getoverview(event_name)
      "highest":highest,//api/v1/stats_data/overview_data
      "lowest":lowest,//api/v1/stats_data/overview_data
      "StationName":this.overviewPythonData.StationName,//api/v1/stats_data/overview_data
      "identifier":this.eventDetails.identifier,//"DPH4011--Vidhan Sabha, मुद्रिका मार्ग, ख्य्बेर पास, सिविल लाइंस, Khyber Pass, Civil Lines, नई दिल्ली, Delhi 110054, India",//api/v1/stats_data/overview_data
      "startDate":this.formateDate(new Date(`${this.customizedStartDate.split('/')[2]}/${this.customizedStartDate.split('/')[1]}/${this.customizedStartDate.split('/')[0]}`)),
      "endDate":this.formateDate(new Date(`${this.customizedEndDate.split('/')[2]}/${this.customizedEndDate.split('/')[1]}/${this.customizedEndDate.split('/')[0]}`)),
      "elevation":this.overviewPythonData.elevation,//api/v1/stats_data/overview_data
      "probForAlert":statsProbT1,///api/v1/stats_data/overview_data(prob_T1)
      "probForSevere":statsProbT2,///api/v1/stats_data/overview_data(prob_T1)
      "mean":mean,///api/v1/stats_data/overview_data
      "weatherUnit":unit,
      "alert":this.searchState.thresholdData.alert,
      "severe":this.searchState.thresholdData.severe,
      "modelName":this.modelnameonly,///api/insight/getoverview
      "locationName":this.eventDetails.locationName,//"Vidhan Sabha, मुद्रिका मार्ग, ख्य्बेर पास, सिविल लाइंस, Khyber Pass, Civil Lines, नई दिल्ली, Delhi 110054, India",///api/insight/getoverview
      "groupName":"Group", // /api/insight/getoverview
      "weatherName":weatherName,// /api/insight/getoverview
      "duration":this.amountData,
      "working_hours_data_available":this.overviewPythonData.working_hours_data_available,//false,///api/v1/stats_data/overview_data
      "Risk":{},
      "prob":this.overviewPythonData.prob,
      "weatherSymbol":symbol,
      "wthrNm":this.currentType,
      "eventId":this.eventId,
      "outputName":`${this.curentWeatherVarData.name[0].toUpperCase()+this.curentWeatherVarData.name.slice(1)}`,
      "temp":this.currentType[0].toUpperCase()+this.currentType.slice(1),
      "periodName":this.periodName,
      "typeName":this.typeName,
      "filedownloadtime":moment(new Date()).format('ddd')+'_'+moment(new Date()).format('MMM_DD_YYYY_HH:mm:ss'),
      "base64data":base64data,
      "whternamepdf": this.currentWeatherWithVarType.replace('_',' '),
      "filedatetime":moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      //"data":[{"title":"1 Jul","markerLabels":["Alert Percentage","Severe Percentage"],"Safe":100,"ranges":[16,15,100],"prob":16,"rangeLabels":["Alert","Severe","Safe"],"T1":16,"measures":[100],"T2":15,"t1years":"2017,2016,2009,2000,1998,1990,1986,1980,1978","t2years":"2017,2016,2000,1998,1990,1986,1980,1978","subtitle":null,"Date":null,"titleS":"Week 26 - 30","markers":[16,15]}];
      //"data": this.pdfGraphData
    };
    if(this.hourlySelected == 'hourly'){
      overviewDataN['hourly'] =  true;
      overviewDataN['hour_list'] =  this.hourstoname[this.startHours] + ' - ' + this.hourstoname[this.endHours]
    }
    let fileName:any;
    if ( overviewDataN['type'] == 'stats' ) {
      let type_resol = ''
      if(this.currentType=='wind' || this.currentType=='snow'){
        type_resol = this.hourlySelected=='hourly'?'Hourly_':'Daily_'
      } 
      if ( overviewDataN['modelName'] == 'Advanced' ) {
        fileName = 'DPH'+overviewDataN['eventId']+'_Statistical_'+overviewDataN['modelName']+'_'+  type_resol +this.currentWeatherWithVarType+'_';
      } else {
        fileName = 'DPH'+overviewDataN['eventId']+'_Statistical_'+overviewDataN['modelName']+'_'+  type_resol +this.currentWeatherWithVarType+'_'+this.curentDurationData.name+'_';
      }
    }
    
    overviewDataN['fileName'] = fileName;
    if ( currWeatherId == 5 || currWeatherId == 6 || currWeatherId == 7 || currWeatherId == 8 ){
      if ( this.userSector == 3 ) {
        overviewDataN['prob'] = this.probConvertPipe.transform(this.overviewPythonData.prob,this.totalNoOfDaysInPeriod);
      } else {
        overviewDataN['prob'] = this.overviewPythonData.prob;
      }
    }
    
    if(this.showAdvancedType !="table" && this.fetchedPDFData){

      if(this.hourlySelected == 'hourly'){
        overviewDataN['hourly'] =  true;
      } else{
        overviewDataN['data'] =  this.pdfGraphData;
      }
        
      overviewDataN['statsWeek'] = this.fetchedPDFData.period
      
      if(this.userSector == 3){
        overviewDataN['probType'] = "downtime";
      }
    } else if(this.fetchedPDFData){
      if(this.hourlySelected == 'hourly'){
        overviewDataN['hourly'] =  true;
      } 
    }
    this.LS.logOut({
      title:
        "Download PDF - Statistical - (" +
        (this.eventDetails.event_name ? this.eventDetails.event_name + " " : "") +
        this.eventDetails.locationName +
        ")",
      description: this.currentModelData.model_name+' '+weatherName+' , Unit:'+unit+" Alert:" +this.searchState.thresholdData.alert +", Severe:" +this.searchState.thresholdData.severe +", Analogue : " +this.currentModelData.analogue+", From " +this.customizedStartDate +" to " +this.customizedEndDate,
      link: "#/report",
      eventId: this.eventId,
      modelId: this.currentModelData.id
    }).pipe(takeUntil(this.destroy$)).subscribe(data => {}, error => {});
    this.newenquiryService.downloadGraphAndOverviewPdf(overviewDataN).subscribe(
        data => {
          let dd = JSON.parse(JSON.stringify(data));
            let res = dd;
            let str = res.slice(161);
            let filename = fileName+'Graph_'+moment(new Date()).format('ddd')+'_'+moment(new Date()).format('MMM_DD_YYYY_HH:mm:ss');
            //this.downloadnew(str, filename);
            this.loader.display(false);
            this.closepopup = true;
          
        },
        error => {
          this.loader.display(false);
        }
      );
      
    }

    formateDate(date) {
      let mons = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
      return (
        mons[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear()
      );
    }

    toggleReportSidebar() {
      this.showSidebarMobile = !this.showSidebarMobile
    }

    showThresholdsMessage(msg) {
      if ( msg ) {
        this.isError = true;
        this.errorMsg = msg;
      }
    }

    checkIfWeatherTypeExistsInStorageData(selectedItemsData) {
      let exists = false;
      let len = selectedItemsData.length;
      for ( let i=0;i<len;i++ ) {
        if ( selectedItemsData[i].weatherType == this.currentType ) {
          exists = true;
          break;
        }
      }
      return exists;
    }

    checkIf12MonthsCompleted() {
      let currentDate;
      let afterTwelveMonths;
      //let eventStartDate =  new Date(moment(this.eventDetails.startDate, 'MM/DD/YYYY').format('DD/MM/YYYY'));
      let eventStartDate =  new Date(this.eventDetails.startDateFormat);
      afterTwelveMonths = currentDate = new Date();
      afterTwelveMonths.setMonth(afterTwelveMonths.getMonth() + 12);
      if (afterTwelveMonths < eventStartDate) {
        this.completedTweleveMonths = true;
      } else {
        this.completedTweleveMonths = false;
      }
    }

    setMaxYDomain(val) {
      return Math.ceil(val);
    }
  
     setMinYDomain(val) {
      return Math.floor(val)
    }

    getPlotlyGraphData(labels, bestEstArr, plusArr, minusArr, plotgrphColorArr) {
      let len = labels.length;
      let data:any=[];
      for ( let i=0; i<len;i++ ) {
          data.push({
            x: [labels[i]],
            y: [bestEstArr[i]],
            hoverinfo: 'x+y',
            error_y: {
              type: 'data',
              array: [plusArr[i]],
              arrayminus: [minusArr[i]],
              visible: true,
              color: plotgrphColorArr[i]
            },
            marker: {
              color: plotgrphColorArr[i],
              size: 8
            }
          });
      }
      return data;
    }

    getThresholdsLines(t1,t2) {
      let shapeLines:any=[];
      shapeLines.push({type: 'line',xref: 'paper',x0: 0,y0: t2,x1: 1,y1: t2,line:{color: '#FEB000',width: 1,dash:'solid'}});
      shapeLines.push({type: 'line',xref: 'paper',x0: 0,y0: t1,x1: 1,y1: t1,line:{color: '#C64B11',width: 1,dash:'solid'}});
      return shapeLines;
    }

    drawThresholdLines(id, yScale, margin) {
      let rootSvg = d3.select("#" + id).select("svg");
      let width = rootSvg.style("width").split("px")[0];
      rootSvg
        .append("line")
        .style("stroke", "#FF0000")
        .style("stroke-width", "1px")
        .attr("id", id)
        .attr("x1", margin.left)
        .attr("y1", yScale(this.dataObj[1].min) + margin.top)
        .attr("x2", +width - margin.right)
        .attr("y2", yScale(this.dataObj[1].min) + margin.top);
      rootSvg
        .append("line")
        .style("stroke", "#FFFF00")
        .style("stroke-width", "1px")
        .attr("id", "chart1")
        .attr("x1", margin.left)
        .attr("y1", yScale(this.dataObj[0].min) + margin.top)
        .attr("x2", +width - margin.right)
        .attr("y2", yScale(this.dataObj[0].min) + margin.top);
    }

    removeSpecificWeatherVarTypes(data) {
      if (this.type == "predict") {
        for (let rw = 0; rw < data.weathers.length; rw++) {
          if (data.weathers[rw].name == "daily") {
            data.weathers.splice(rw, 1);
          }
        }
      }

      if (this.type == "predict") {
        for (let rw = 0; rw < data.weathers.length; rw++) {
          if (data.weathers[rw].name == "gust") {
            data.weathers.splice(rw, 1);
          }
        }
      }
      // if current date is > 12 month old from start date
      if (this.completedTweleveMonths && this.type == "predict") {
        for (let rw = 0; rw < data.weathers.length; rw++) {
          if (data.weathers[rw].name == "weekly") {
            data.weathers.splice(rw, 1);
          }
        }
      }
    }

    // risk dropdown toggle
    riskDropdown(){
      this.showHideRisk = !this.showHideRisk;
    }

    hideDropDown() {
      this.showHideRisk = false;
    }

    exportHistoricalXLSX() {
      if (navigator.onLine == true) {
        this.loader.display(true);
        let overviewTabData = this.getXLSXStatOverviewData();
        let current = new Date();
        let fileName = 'Statistical_Overview_';
        let sheetName1 = 'Statistical Overview';
        let sheetName2 = '5 year observations';
        let sheetName3 = "Statistical Risk";
        let arr = [
          { name: '', B: '' },
          { name: '', B: '' },
          { name: '', B: '' },
          { name: '', B: '' },
          { name: '', B: '' },
          { name: '', B: '' },
          { name: '', B: '' },
          { name: '', B: '' },
          { name: '', B: '' },
          { name: '', B: '' },
          { name: '', B: '' },
          { name: '', B: '' },
          { name: '', B: '' },
          { name: '', B: '' },
          { name: '', B: '' },
          { name: '', B: '' }
        ];
        const ws1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(arr);
        let hourly = this.hourlySelected;
        if(hourly == 'hourly'){
          if ( this.curentWeatherVarData.weathertypeid == 1 || this.curentWeatherVarData.weathertypeid == 2 || this.curentWeatherVarData.weathertypeid == 3 || this.curentWeatherVarData.weathertypeid == 4 ) {
          
            ws1.A1.v = 'Enquiry ID';
            ws1.A2.v = 'Location';
            ws1.A3.v = 'Date range';
            ws1.A4.v = 'Time range';
            
            ws1.A5.v = 'Model Name ';
            ws1.A6.v = '';
            ws1.A7.v = overviewTabData.weatherName+' - '+overviewTabData.duration;
            ws1.A8.v = 'Unit';
            ws1.A9.v = 'Moderate';
            ws1.A10.v = 'Severe';
            ws1.A11.v = '';
            ws1.A12.v = 'Lowest';
            ws1.A13.v = 'Mean';
            ws1.A14.v = 'Highest';
            ws1.A15.v = '';
            if ( overviewTabData.probType == 'downtime' ) {
              ws1.A16.v = 'Downtime of Moderate';
              ws1.A17.v = 'Downtime of Severe';  
            } else {
              ws1.A16.v = 'Probability for Moderate';
              ws1.A17.v = 'Probability for Severe ';
            } 
            let identifierArr = overviewTabData.identifier.split('--');
            ws1.B1.v = identifierArr[0];
            ws1.B2.v = identifierArr[1];
            ws1.B3.v = overviewTabData.startDate+'   '+overviewTabData.endDate;
            ws1.B4.v = this.hourstoname[this.startHours]+'   '+this.hourstoname[this.endHours];
            ws1.B5.v = overviewTabData.modelName;
            ws1.B6.v = '';
            ws1.B7.v = '';
            ws1.B8.v = overviewTabData.weatherUnit;
            ws1.B9.v = overviewTabData.alert;
            ws1.B10.v = overviewTabData.severe;
            ws1.B12.v = JSON.parse(overviewTabData.lowest).toFixed(2);
            ws1.B13.v = JSON.parse(overviewTabData.mean).toFixed(2);
            ws1.B14.v = JSON.parse(overviewTabData.highest).toFixed(2);
            ws1.B15.v = '';
            if ( overviewTabData.probType == 'downtime' ) {
              ws1.B16.v = overviewTabData.prob_T1+' hour(s)';
              ws1.B17.v = overviewTabData.prob_T2+' hour(s)';  
            } else {
              ws1.B16.v = overviewTabData.prob_T1+' %';
              ws1.B17.v = overviewTabData.prob_T2+' %';
            }
    
          } else {
    
            ws1.A1.v = 'Enquiry ID';
          ws1.A2.v = 'Location';
          ws1.A3.v = 'Date range';
          ws1.A4.v = 'Time range';
          ws1.A5.v = 'Model Name ';
          ws1.A6.v = '';
          ws1.A7.v = overviewTabData.weatherName+' - '+overviewTabData.duration;
          ws1.A8.v = '';
          ws1.A9.v = '';
          ws1.A10.v = '';
          ws1.A11.v = '';
          ws1.A12.v = '';
          ws1.A13.v = '';
          ws1.A14.v = '';
          ws1.A15.v = '';
          if ( overviewTabData.probType == 'downtime' ) {
            ws1.A9.v = 'Downtime of '+overviewTabData.wthrNm.charAt(0).toUpperCase() + overviewTabData.wthrNm.slice(1);
          } else {
            ws1.A9.v = 'Probability for '+overviewTabData.wthrNm.charAt(0).toUpperCase() + overviewTabData.wthrNm.slice(1);
          }
          ws1.A16.v = '';
          ws1.A17.v = '';
          let identifierArr = overviewTabData.identifier.split('--');
          ws1.B1.v = identifierArr[0];
          ws1.B2.v = identifierArr[1];
          ws1.B3.v = overviewTabData.startDate+'   '+overviewTabData.endDate;
          ws1.B4.v = this.hourstoname[this.startHours]+'   '+this.hourstoname[this.endHours];
          ws1.B5.v = overviewTabData.modelName;
          ws1.B6.v = '';
          ws1.B7.v = '';
          ws1.B8.v = '';
          ws1.B9.v = '';
          ws1.B10.v = '';
          if ( overviewTabData.probType == 'downtime' ) {
            ws1.B9.v = overviewTabData.prob+' hour(s)';
          } else {
            ws1.B9.v = overviewTabData.prob+' %';
          }
        } 
      } else {

        if ( this.curentWeatherVarData.weathertypeid == 1 || this.curentWeatherVarData.weathertypeid == 2 || this.curentWeatherVarData.weathertypeid == 3 || this.curentWeatherVarData.weathertypeid == 4 ) {
          
          ws1.A1.v = 'Enquiry ID';
          ws1.A2.v = 'Location';
          ws1.A3.v = 'Date range';
          ws1.A4.v = 'Model Name ';
          ws1.A5.v = '';
          ws1.A6.v = overviewTabData.weatherName+' - '+overviewTabData.duration;
          ws1.A7.v = 'Unit';
          ws1.A8.v = 'Moderate';
          ws1.A9.v = 'Severe';
          ws1.A10.v = '';
          ws1.A11.v = 'Lowest';
          ws1.A12.v = 'Mean';
          ws1.A13.v = 'Highest';
          ws1.A14.v = '';
          if ( overviewTabData.probType == 'downtime' ) {
            ws1.A15.v = 'Downtime of Moderate';
            ws1.A16.v = 'Downtime of Severe';  
          } else {
            ws1.A15.v = 'Probability for Moderate';
            ws1.A16.v = 'Probability for Severe ';
          } 
          let identifierArr = overviewTabData.identifier.split('--');
          ws1.B1.v = identifierArr[0];
          ws1.B2.v = identifierArr[1];
          ws1.B3.v = overviewTabData.startDate+'   '+overviewTabData.endDate;
          ws1.B4.v = overviewTabData.modelName;
          ws1.B5.v = '';
          ws1.B6.v = '';
          ws1.B7.v = overviewTabData.weatherUnit;
          ws1.B8.v = overviewTabData.alert;
          ws1.B9.v = overviewTabData.severe;
          ws1.B11.v = JSON.parse(overviewTabData.lowest).toFixed(2);
          ws1.B12.v = JSON.parse(overviewTabData.mean).toFixed(2);
          ws1.B13.v = JSON.parse(overviewTabData.highest).toFixed(2);
          ws1.B14.v = '';
          if ( overviewTabData.probType == 'downtime' ) {
            ws1.B15.v = overviewTabData.prob_T1+' day(s)';
            ws1.B16.v = overviewTabData.prob_T2+' day(s)';  
          } else {
            ws1.B15.v = overviewTabData.prob_T1+' %';
            ws1.B16.v = overviewTabData.prob_T2+' %';
          }
  
        } else {
  
          ws1.A1.v = 'Enquiry ID';
          ws1.A2.v = 'Location';
          ws1.A3.v = 'Date range';
          ws1.A4.v = 'Model Name ';
          ws1.A5.v = '';
          ws1.A6.v = overviewTabData.weatherName+' - '+overviewTabData.duration;
          ws1.A7.v = '';
          ws1.A8.v = '';
          ws1.A9.v = '';
          ws1.A10.v = '';
          ws1.A11.v = '';
          ws1.A12.v = '';
          ws1.A13.v = '';
          ws1.A14.v = '';
          if ( overviewTabData.probType == 'downtime' ) {
            ws1.A8.v = 'Downtime of '+overviewTabData.wthrNm.charAt(0).toUpperCase() + overviewTabData.wthrNm.slice(1);
          } else {
            ws1.A8.v = 'Probability for '+overviewTabData.wthrNm.charAt(0).toUpperCase() + overviewTabData.wthrNm.slice(1);
          }
          ws1.A15.v = '';
          ws1.A16.v = '';
          let identifierArr = overviewTabData.identifier.split('--');
          ws1.B1.v = identifierArr[0];
          ws1.B2.v = identifierArr[1];
          ws1.B3.v = overviewTabData.startDate+'   '+overviewTabData.endDate;
          ws1.B4.v = overviewTabData.modelName;
          ws1.B5.v = '';
          ws1.B6.v = '';
          ws1.B7.v = '';
          ws1.B8.v = '';
          ws1.B9.v = '';
          if ( overviewTabData.probType == 'downtime' ) {
            ws1.B8.v = overviewTabData.prob+' day(s)';
          } else {
            ws1.B8.v = overviewTabData.prob+' %';
          }
          
  
        }
      }

        const ws2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.historicalData);
        ws2.A1.v = 'Weather risk';
        ws2.B1.v = 'Result type';
        ws2.C1.v = 'date';
        ws2.D1.v = 'value';
        ws2.E1.v = 'unit';
  
        let wb: XLSX.WorkBook = XLSX.utils.book_new();
        
        XLSX.utils.book_append_sheet(wb, ws1, sheetName1);
        XLSX.utils.book_append_sheet(wb, ws2, sheetName2);

        if(hourly == 'hourly'){
          console.log(this.xlsxhourdata)
          const ws3: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.xlsxhourdata);
          ws3.A1.v = 'Date';
          ws3.B1.v = 'Hour';
          ws3.C1.v = 'Moderate Risk';
          ws3.D1.v = 'Severe Risk';
          XLSX.utils.book_append_sheet(wb, ws3, sheetName3);
        }
        
  
        let wbout = XLSX.write(wb, {bookType:'xlsx', type:'array'});
        let base64 = this.arrayBufferToBase64(wbout);
        let wthrNm = this.currentType;
        let body = JSON.stringify({
                    'eventId': this.eventId,
                    'base64': base64,
                    'modelType': this.modelName,
                    'wthrNm': wthrNm,
                    'weatherName': overviewTabData.weatherName+' '+this.modelnameonly,
                    'combinedWeatherSubType': ( this.curentWeatherVarData.weathertypeid == 5 || this.curentWeatherVarData.weathertypeid == 6 || this.curentWeatherVarData.weathertypeid == 7 || this.curentWeatherVarData.weathertypeid == 8 ) ? wthrNm : overviewTabData.weatherName,
                    'fileName': fileName
                  });
        this.newenquiryService.saveXLSXFileBase64IntoDB(body)
        .subscribe((data)=> {
          this.LS.logOut({
            title: "Download XLSX - Statistical - (" + (this.eventDetails.event_name ? this.eventDetails.event_name + " " : "") + this.eventDetails.locationName,
            description:
            this.currentModelData.model_name+' '+overviewTabData.weatherName+', Unit:'+overviewTabData.weatherUnit+" Alert:" +overviewTabData.alert +", Severe:" +overviewTabData.severe +", Analogue : " +this.currentModelData.analogue+", From " +this.customizedStartDate +" to " +this.customizedEndDate,
              
            link: "#/insight-overview",
            eventId: this.eventId,
            modelId: this.currentModelData.id
          }).pipe(takeUntil(this.destroy$)).subscribe(
            data => {},
            error => {
              this.router.navigate(["/login"]);
              this.loader.display(false);
            }
          );  
        })
        return base64;
      }
  
    }

    getXLSXStatOverviewData() {
      let overviewDataN = JSON.parse(JSON.stringify(this.overviewPythonData));
      let currWeatherId = this.curentWeatherVarData.weathertypeid;
      let unit = this.searchState.thresholdData.unit;
      let highest = this.convertUnit(this.searchState.overviewData.highest, unit);
      let lowest = this.convertUnit(this.searchState.overviewData.lowest,unit);
      let mean = this.convertUnit(this.searchState.overviewData.mean,unit);
      let weatherName ='';
      if (this.curentWeatherVarData.id == 3 || this.curentWeatherVarData.id == 4 || this.curentWeatherVarData.id == 9 || this.curentWeatherVarData.id == 18 || this.curentWeatherVarData.id == 15 || this.curentWeatherVarData.id == 16) {
        weatherName = `${this.currentType[0].toUpperCase()+this.currentType.slice(1)} ${this.curentWeatherVarDisplayName}`;
      } else {
        weatherName = `${this.curentWeatherVarDisplayName} ${this.currentType[0].toUpperCase()+this.currentType.slice(1)}`;
      }
      let symbol = this.curentWeatherVarData.symbol?this.curentWeatherVarData.symbol.trim():this.curentWeatherVarData.symbol;
      let statsProbT1=0;
      let statsProbT2=0;
      if ( currWeatherId != 5 && currWeatherId != 6 && currWeatherId != 7 && currWeatherId != 8 ) {
        statsProbT1 = this.overviewPythonData.prob_T1;
        statsProbT2 = this.overviewPythonData.prob_T2;
      }

     
      let wthrNm = this.currentType;
        // if ( this.weathertypeid == 2 || this.weathertypeid == 3 ) {
      overviewDataN.modelName = this.modelnameonly;
      overviewDataN.weatherName = weatherName;
      overviewDataN.alert = this.searchState.thresholdData.alert;
      overviewDataN.severe = this.searchState.thresholdData.severe;
      if (this.currentModelData.analogue == "false") 
        overviewDataN.duration = this.amountData;
      else 
        overviewDataN.duration = "";
      if ( currWeatherId == 5 || currWeatherId == 6 || currWeatherId == 7 || currWeatherId == 8 )
        overviewDataN.duration = this.amountData;
      overviewDataN.weatherUnit = unit;
      overviewDataN.weatherSymbol = symbol;  
      overviewDataN.lowest = lowest;
      overviewDataN.mean = mean;
      overviewDataN.highest = highest;
      overviewDataN.wthrNm = this.currentType;
        // }
        //if ((this.eventDetails.country == 'GB' || this.eventDetails.country == 'US') && (this.userSector == '6') && (this.modelnameonly == 'Advanced' || this.modelnameonly == 'Standard') ) {
        if (this.eventDetails.country == 'GB' && (this.userSector == '6') && (this.modelnameonly == 'Advanced' || this.modelnameonly == 'Standard') ) {
          overviewDataN.workingHours = this.workingHourValue;
          overviewDataN.printType = "stats";
          if (this.userSector == 3 ) {
            overviewDataN.probType = 'downtime';
            let totdays = this.totalNoOfDaysInPeriod
            if(this.hourlySelected == 'hourly'){
              totdays = ((this.totalNoOfDaysInPeriod+1)*(this.endHours-this.startHours+1)) - 1
            }
            
            if ( currWeatherId == 5 || currWeatherId == 6 || currWeatherId == 7 || currWeatherId == 8 ) {
              if ( overviewDataN.prob>0.1 || overviewDataN.prob==0 ) {
                overviewDataN.prob = this.probConvertPipe.transform(overviewDataN.prob, totdays);
              } else {
                overviewDataN.prob = this.probConvertPipe.transform(0.1, totdays);
              }
            } else {
              if ( overviewDataN.prob_T1>0.1 || overviewDataN.prob_T1==0 ) {
                overviewDataN.prob_T1 = this.probConvertPipe.transform(overviewDataN.prob_T1, totdays);
              } else {
                overviewDataN.prob_T1 = this.probConvertPipe.transform(0.1, totdays);
              }
              if ( overviewDataN.prob_T2>0.1 || overviewDataN.prob_T2==0 ) {
                overviewDataN.prob_T2 = this.probConvertPipe.transform(overviewDataN.prob_T2, totdays);
              } else {
                overviewDataN.prob_T2 = this.probConvertPipe.transform(0.1, totdays);
              }
            }
          }
        }
      overviewDataN.eventId = sessionStorage.getItem("eventId");
      overviewDataN.eventName = this.eventDetails.event_name;
      overviewDataN.groupName = this.eventDetails.groupName;
      overviewDataN.identifier = this.eventDetails.identifier;   
      overviewDataN.startDate = this.formateDate(new Date(`${this.customizedStartDate.split('/')[2]}/${this.customizedStartDate.split('/')[1]}/${this.customizedStartDate.split('/')[0]}`));
      overviewDataN.endDate = this.formateDate(new Date(`${this.customizedEndDate.split('/')[2]}/${this.customizedEndDate.split('/')[1]}/${this.customizedEndDate.split('/')[0]}`));
      return overviewDataN;
    }
    arrayBufferToBase64( buffer ) {
      var binary = '';
      var bytes = new Uint8Array( buffer );
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
      }
      return window.btoa( binary );
    }

    lastFiveYearData() {
      if (navigator.onLine == true) {
        // this.loader.display(true);
        let overviewStartDate:any;
      let overviewEndDate:any;
      if ( this.searchState.datestart == this.customizedStartDate && this.searchState.dateend == this.customizedEndDate ) {
        overviewStartDate = this.searchState.datestart;
        overviewEndDate = this.searchState.dateend;
      } else {
        overviewStartDate = this.customizedStartDate;
        overviewEndDate = this.customizedEndDate;
      }
        let reqData = {
                  StationID: this.curentWeatherVarData.stationId,
                  country: this.eventDetails.country,
                  postcode: this.eventDetails.postcode,
                  start_date: overviewStartDate,
                  end_date: overviewEndDate,
                  lat: this.eventDetails.latitude,
                  lon: this.eventDetails.longitude,
                  Wparam: this.currentType,
                  unit: this.searchState.thresholdData.unit,
                  Type: this.curentWeatherVarData.name,
                  uri: '/risk_data/get_risk_historical',
                }
          if(this.hourlySelected=='hourly'){
            reqData['StationID'] = this.currentModelData.weathers[0].stationId
          }
        this.newenquiryService
          .lastFiveYearDataGet(reqData).pipe(takeUntil(this.destroy$))
          .subscribe(
            data => {
              let len = data.data.length;
              let sortedData:any=[];
              if ( len > 0 ) {
                this.showCSVDownloadIcon = true;
                for ( let i=0;i<len;i++ ) {
                  sortedData.push({'weather-risk': data.data[i]['weather-risk'], 'result-type': data.data[i]['result-type'], 'Date': data.data[i]['Date'], 'Value': data.data[i]['Value'], 'Unit': data.data[i]['Unit']});
                }
              }
              this.historicalData = sortedData;
            },
            error => {
              
            }
          );
      } else {
        this.loader.display(false);
      }
    }

    filterOhurlyHead(headdata){
      let type = this.hourlySelected;
      if(['GB','US'].includes(this.eventDetails.country)){
        if (type == 'hourly') {
          return headdata.filter(x => ["hourly", "hourly_speed"].includes(x.name));
        } else {
          return headdata.filter(x =>  !["hourly", "hourly_speed", "hourly_gust"].includes(x.name));
        }
      } else {
        return headdata.filter(x =>  !["hourly", "hourly_speed", "hourly_gust"].includes(x.name));
      }
        
    }
    
    onResolutionClick(value){
      if(value == 'hourly'){
        this.hourlySelected = 'hourly'
        
      } else{
        this.hourlySelected = 'daily'
      }
      this.modifyHeading()
      
      let change = {"hourly":'daily', "hourly_speed":'speed', "hourly_gust":'gust'}
      let model = this.currentModelData;
      let modelLen = model.weathers.length;
      let selectedItemsData = JSON.parse(sessionStorage.getItem('selectedItems'));
      if(this.hourlySelected == 'hourly'){
        selectedItemsData = JSON.parse(sessionStorage.getItem('selectedItemshourly'));
      }
      console.log(selectedItemsData);
      let weatherId = model.weather_type_id;
      this.head = model.weathers;
      let headLen = this.head.length;
      console.log(this.head)
      
      if ( selectedItemsData != undefined && selectedItemsData != null && weatherId != 4 && weatherId != 5 && weatherId != 6 && weatherId != 7 && weatherId != 8 ) {
        let isExists = this.checkIfWeatherTypeExistsInStorageData(selectedItemsData);
        if ( isExists ) {
          let selectedItemLen = selectedItemsData.length;
          let savedHead = '';
          for ( let i=0;i<selectedItemLen;i++ ) {
            if ( selectedItemsData[i].weatherType == this.currentType ) {
              savedHead = selectedItemsData[i].headName;
              break;
            } 
          }
          
          for ( let h=0;h<headLen;h++ ) {
            console.log(this.head[h].name )
            if ( this.head[h].name == savedHead ) {
              this.onHeadSelectClick(this.head[h], 1);
              break;
            } else {
              if(this.hourlySelected == 'hourly'){
                console.log(this.head[h].name == "hourly",h)
                if(this.head[h].name == "hourly"|| this.head[h].name == "hourly_speed" || this.head[h].name == "hourly_gust"){
                  this.onHeadSelectClick(this.head[h], 1);
                }
              }else{
                this.onHeadSelectClick(this.head[0], 1);
              }
            }
            
          }
        } else {
          for ( let h=0;h<headLen;h++ ) {
            console.log(this.head[h].name )
            if(this.hourlySelected == 'hourly'){
              if(this.head[h].name == "hourly"|| this.head[h].name == "hourly_speed"){
                this.onHeadSelectClick(this.head[h], 1);
              }
            }else{
              this.onHeadSelectClick(this.head[0], 1);
            }
          }
        }
      }else{
        
        if(weatherId != 4 || weatherId != 5 && weatherId != 6 && weatherId != 7 && weatherId != 8){
          for ( let h=0;h<headLen;h++ ) {
            console.log(this.head[h].name )
            if(this.hourlySelected == 'hourly'){
              if(this.head[h].name == "hourly" || this.head[h].name == "hourly_speed"){
                this.onHeadSelectClick(this.head[h], 1);
              }
            }else{
              this.onHeadSelectClick(this.head[0], 1);
            }
          }
        } else{
          if(this.hourlySelected == 'hourly'){
            this.onHeadSelectClick(this.head[1], 1);
          } else {
            this.onHeadSelectClick(this.head[0], 1);
          }
        }
      } 
      
    }

    changehours(hourval, type){
      if(type == 'start'){
        if(hourval > parseInt(this.endHours)){
          this.hourvalidate = true
          this.starthoursui.nativeElement.value = parseInt(this.startHours)
        } else{
          this.pageaccorddate(this.graphpage)
          this.hourvalidate = false
          this.startHours = hourval
          this.showupdatebutton = true;
        }
        
      } else {
        if(hourval < parseInt(this.startHours)){
          this.hourvalidate = true
          this.endhoursui.nativeElement.value = parseInt(this.endHours)
        } else{
          this.pageaccorddate(this.graphpage)
          this.hourvalidate = false
          this.endHours = hourval
          this.showupdatebutton = true;
        }
        
      }
      
      
    }

    updatetimedate(){
      this.modifyHeading();
      this.showupdatebutton = false;
      if(this.currentType == 'rain' || this.currentType == 'temperature' || this.currentType == 'wind' || this.currentType == 'snow'){
        if(this.thresholdupdate.isChanged || this.thresholdupdate.isUnitChanged){
          this.thresholdupdate.updateButtonClicked()
        }
        else{
          if (this.type=='stats') {
            this.getDataOverview(); 
          } else if (this.type=='predict') {
            this.currentPredicPage=1;
            this.overviewPredictData();
            this.getPredictionGraph();
            this.getEvents();
          } else {
            this.riskGraphPage=1;
            this.overviewRiskData();
            this.getRiskGraph();
          }
        }
      }
      else{
        if (this.type=='stats') {
          this.getDataOverview(); 
        } else if (this.type=='predict') {
          this.currentPredicPage=1;
          this.overviewPredictData();
          this.getPredictionGraph();
          this.getEvents();
        } else {
          this.riskGraphPage=1;
          this.overviewRiskData();
          this.getRiskGraph();
        }
      }
      
    }

    getnodays(typev='over'){
      let stDtMoment = moment(this.searchState.datestart, 'DD-MM-YYYY');
      let endDtMoment = moment(this.searchState.dateend, 'DD-MM-YYYY');
      let stdtObj = new Date(JSON.parse(JSON.stringify(stDtMoment)));
      let endDtObj = new Date(JSON.parse(JSON.stringify(endDtMoment)));
      let diffc = endDtObj.getTime() - stdtObj.getTime();
      let daysa = diffc / (1000 * 3600 * 24);
      let more = 0
      
      if(this.currentType == 'rain' || this.currentType == 'temperature' || this.currentType == 'wind' || this.currentType == 'snow'){
        let multi = 0.0001
        if(daysa > 150 && daysa < 350){
          if(typev=='over'){
            multi = 0.00025
            daysa = daysa+(daysa*0.06)
          }
        } else if(daysa > 350){
          if(typev=='over'){
            multi = 0.00045
            daysa = daysa+(daysa*0.07)
          }
        } else{
          daysa = daysa+(daysa*0.03)
        }
        more = (daysa*daysa*multi)

      } else{
        if(daysa > 150 && daysa < 350){
          daysa = daysa*0.05;
        } else if(daysa > 350){
          daysa = daysa*0.08;
        }
        
      }
      if(window.location.hostname == 'tatras-ux-uat.metswift.live' || window.location.hostname == 'www.tatras-ux-uat.metswift.live'){
        this.overviewmulti = 0.0015
        this.graphmulti = 0.00075
      }
      console.log(daysa + more)
      if(typev == 'over'){
        return (daysa + more)*(this.endHours-this.startHours+1)*this.overviewmulti
  
      } else
      {
        return (daysa + more)*(this.endHours-this.startHours+1)*this.graphmulti
      }
    }

    gethourlyloadertime(){
      let noofdays = this.getnodays('over')
      let totdays = noofdays
      let seconds = 1;
      if(this.hourlySelected == 'hourly'){
        seconds = this.weatherloadhourly[this.currentType] + totdays
      }

      let timers = seconds*10
      this.intervaloverview = setInterval(() => {
        if(this.loaderhtimeoverview < 99) {
          this.loaderhtimeoverview++;
        } else {
          clearInterval(this.intervaloverview);
        }
      }, timers)
      this.intervallist.push(this.intervaloverview)
      
    }

    gethourlyloadertimegraph(extra=0){
      let noofdays = this.getnodays('graph')
      let noofdaysover = this.getnodays('over')
      let totdays = noofdays
      if(extra > 0){
        extra = this.weatherloadhourly[this.currentType] + noofdaysover
      }
      let seconds = 1;
      if(this.hourlySelected == 'hourly'){
        seconds = this.weatherloadhourlygraph[this.currentType] + totdays + extra
      }
      let timers = seconds*10
      
      this.intervalgraph = setInterval(() => {
        if(this.loaderhtimegraph < 99) {
          this.loaderhtimegraph++;
        } else {
          clearInterval(this.intervalgraph);
        }
      }, timers)
      this.intervallist.push(this.intervalgraph)
      
    }
    
    loadGraphOnDate(dateval){
      console.log(this.searchState.datestart, dateval)
      let stDtMoment = moment(this.searchState.datestart, 'DD-MM-YYYY');
      let endDtMoment = moment(dateval, 'DD-MM-YYYY');
      let stdtObj = new Date(JSON.parse(JSON.stringify(stDtMoment)));
      let endDtObj = new Date(JSON.parse(JSON.stringify(endDtMoment)));
      let diffc = endDtObj.getTime() - stdtObj.getTime();
      let daysa = Math.round(diffc / (1000 * 3600 * 24));
      if(this.graphpage != (daysa+1)){
        this.showloadergraph = true
        this.loaderhtimegraph = 0
        this.gethourlyloadertimegraph(0)
        this.graphpage = (daysa+1);
        this.showGraph=false;
        this.getStatisticalGraph();
      }
      
    }

    pageaccorddate(hpage){
      if(this.hourlySelected=="hourly"){
        this.hourlypage = hpage
      }
    }

}
