import { Component, OnInit, Input, Output, ViewChild, AfterViewInit, EventEmitter, ElementRef } from '@angular/core';

@Component({
  selector: 'app-editable-title',
  templateUrl: './editable-title.component.html',
  styleUrls: ['./editable-title.component.scss']
})
export class EditableTitleComponent implements OnInit, AfterViewInit {
    @Input() title: string;
    @Input() titleval: string
    @Input() eventname: string
    @ViewChild('inputTitle', {static: false}) inputTitle: ElementRef;
    @ViewChild('hiddenValue', { static: false }) hiddenValue: ElementRef;
    @Output() changedTitle: EventEmitter<any> = new EventEmitter();
    isEditing: boolean = false;
    saveIt: boolean = false;
    constructor() { }

    ngOnInit(): void {
    }
    ngAfterViewInit(): void {
        this.resizeInput()
    }
    blurInput() {
        this.isEditing = false;
        this.saveIt = false;
        this.title = this.inputTitle.nativeElement.value
        if(!this.title || this.title.trim() ==''){
            this.title = this.inputTitle.nativeElement.value = this.titleval
        } else if(this.title.trim() == this.eventname){
            this.title = this.inputTitle.nativeElement.value = this.title.trim()
        } else{
            this.changedTitle.emit(this.inputTitle.nativeElement.value.trim());
        }
    }
    resizeInput() {
        this.hiddenValue.nativeElement.textContent = this.inputTitle.nativeElement.value;
        this.inputTitle.nativeElement.style.width = this.hiddenValue.nativeElement.offsetWidth + "px";
        // this.inputTitle.nativeElement.style.maxWidth = this.hiddenValue.nativeElement.offsetWidth + 12 + "px";
    }
    onClickEdit(){
        this.isEditing = true;
        this.saveIt = true;        
        this.inputTitle.nativeElement.focus()
        // this.inputTitle.nativeElement.select()
    }

}
