import { Component, OnInit, AfterViewInit } from '@angular/core';
import Plotly from 'plotly.js-dist'
import * as map from 'lodash/map'

@Component({
  selector: 'app-results-vertical-bars',
  templateUrl: './results-vertical-bars.component.html',
  styleUrls: ['./results-vertical-bars.component.scss']
})
export class ResultsVerticalBarsComponent implements OnInit, AfterViewInit {

    layout: any = {
        autosize: true,
        margin: {
          l: 25,
          r: 25,
          b: 30,
          t: 25,
          pad: 4
        },
        yaxis: {fixedrange: true, rangemode: 'tozero'},
        xaxis : {fixedrange: true},
        showlegend: false,
        font: {
            family: 'neue-haas-grotesk-display',
        },
        hoverlabel: {
            bgcolor: "#1B242E",
            bordercolor: "#1B242E",
            font: {
                family: 'neue-haas-grotesk-display',
                color: '#fff',
            },
        },
    }

    config: any = {
        responsive: true,
        displayModeBar:false,
    }


    constructor() { }

    interpolateColor(color1, color2, factor) {
        if (arguments.length < 3) {
            factor = 0.5;
        }
        var result = color1.slice();
        for (var i = 0; i < 3; i++) {
            result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
        }
        return result;
    };

    interpolateColors(color1, color2, steps) {
        var stepFactor = 1 / (steps - 1),
            interpolatedColorArray = [];

        color1 = color1.match(/\d+/g).map(Number);
        color2 = color2.match(/\d+/g).map(Number);

        for(var i = 0; i < steps; i++) {
            interpolatedColorArray.push(this.interpolateColor(color1, color2, stepFactor * i));
        }

        return interpolatedColorArray;
    }
    ngOnInit(): void {
        var trace1 = {
            x: ['Week 30', 'Week 31','Week 32','Week 33','Week 34','Week 35','Week 36'],
            y: [0,0,0,0,0,0,0],
            type: 'bar',
            marker: {
                color: '#EB8B38',
            },
          }
        ;
        // WILDFIRE
        const colours = this.interpolateColors('rgb(255,65,108)','rgba(255,118,0)', trace1.y.length)
        // STORM
        // const colours = this.interpolateColors('rgb(31,28,44)','rgba(146,141,171)', trace1.y.length)
        // HAIL
        // const colours = this.interpolateColors('rgb(100,43,115)','rgba(198,66,110)', trace1.y.length)

        trace1.marker.color = map(trace1.y, (v, index) => {
            return 'rgb(' + colours[index][0] + ',' + colours[index][1] + ',' + colours[index][2] + ')'
        })
        var data = [trace1]

        Plotly.newPlot('bars-plot', data, this.layout, this.config)
    }
    animateChart() {
        var dataLoad = [{ y: [1.16, 0.77, 0.81,0.74,0.7,0.4,0.31] } ]
          Plotly.animate('bars-plot', {
            data: dataLoad,
            traces: [0],
            layout: this.layout
          }, {
            transition: {
              duration: 500,
              easing: 'cubic-in-out'
            },
            frame: {
              duration: 500
            }
          })
    }
    ngAfterViewInit(){
        this.animateChart()
    }
}
