import { Component, ElementRef, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, selectAuthState } from '../../store/app.states';
import { Observable } from 'rxjs';
import { NewEnquiryService } from './../../services/newenquiry.service';
import * as moment from 'moment';
import { threadId } from 'worker_threads';
import { LoaderService } from './../../services/loader.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ENV } from '../../app.config';
import { LogoutService } from 'src/app/services/logout.service';
import { UserService } from 'src/app/services/user.service';
import { error } from 'console';

@Component({
  selector: 'app-new-report',
  templateUrl: './new-report.component.html',
  styleUrls: ['./new-report.component.scss']
})

export class NewReportComponent implements OnInit {
    getState: Observable<any>;
    searchState: any = null;
    title : string = 'New enquiry';
    infoEarthquake: string = '<span class="text-sm w-full block">Aggregated on an annual binary basis, and reported as an annual occurrence. Hyper local data, reaching back to 1900, means our statistics achieve highly specific local relevance. We also grade the severity of the natural catastrophe, based on this detailed data. Attain probability percentages of risk in response to your parameters. Moment magnitude severity M4 – M9 classification.</span>';

    infoWildfire: string  =  '<span class="text-sm w-full block">Hyperlocal wildfire occurrence risk model using historical wildfire event data since 1950, utilizing both atmospheric data (inc. dry days, high daytime temperatures, mean wind and humidity) and high resolution land type data with applied latitudinal, seasonal and slope factor adjustments. Two outputs will be available, the ‘All data’ (1950-2019) and ‘Since 2000’ (2000-2019) options providing a localised,weekly, wildfire development risk score. </span>';
    
    wildfn="";
    
    infoTornadoes: string = '<span class="text-sm w-full block">Aggregated on a weekly basis, and reported as a weekly occurrence. Hyper local data, reaching back to 1950, means your results achieve highly specific local relevance. Attain risk probability percentages in response to your parameters. We also grade the severity of the natural catastrophe, based on this detailed data. Only available for locations in the United States of America</span>';
    infoHurricane: string = '';
    infoRain: string = '<span class="text-sm w-full block">The risk for the selected period from the accumulated rainfall total, aggregated on a daily, weekly, or monthly basis. Available units are mm and inches. All Data and Since 2011 options are available in the Standard Model. The Advanced model provides the risk with greater influence from similar years (more information found in the MetSwift Analytics page)</span>';
    infoWind: string = '<span class="text-sm w-full block">The risk for the selected period from wind speed (10-minute averaged wind) or wind gust (2-10 second interval instantaneous wind) reports. Available units are miles per hour and metres per second. All Data and Since 2011 options are available in the Standard Model. The Advanced model provides the risk with greater influence from similar years (more information found in the MetSwift Analytics page)</span>';
    infoTemperature: string = '<span class="text-sm w-full block">The risk for the selected period from the maximum, mean, and minimum temperature reports. Available units are degrees Celsius and degrees Fahrenheit. All Data and Since 2011 options are available in the Standard Model. The Advanced model provides the risk with greater influence from similar years (more information found in the MetSwift Analytics page)</span>';
    infoSnow: string = '<span class="text-sm w-full block">The risk for the selected period from the total accumulated snow amount. Available units are cm and inches. All Data and Since 2011 options are available in the Standard Model. The Advanced model provides the risk with greater influence from similar years (more information found in the MetSwift Analytics page)</span>';
    infoHail: string = '<span class="text-sm w-full block">The risk for the selected period from hail reports by the weather station. If at any point in the day hail is reported, it will count as a hit. Multiple observations in a single day will count as a single hit for the risk calculations. Additionally, this includes other forms of frozen precipitation such as freezing rain. All Data and Since 2011 options are available in the Standard Model. The Advanced model provides the risk with greater influence from similar years (more information found in the MetSwift Analytics page)</span>';
    infoDust: string = '<span class="text-sm w-full block">The risk for the selected period from duststorm or sandstorm reports by the weather station. If at any point in the day a duststorm or sandstorm is reported, it will count as a hit. Multiple observations in a single day will count as a single hit for the risk calculations. Visibility must be reduced to less than 1km to be counted as a duststorm or sandstorm. All Data and Since 2011 options are available in the Standard Model. The Advanced model provides the risk with greater influence from similar years (more information found in the MetSwift Analytics page)</span>';
    infoFog: string = '<span class="text-sm w-full block">The risk for the selected period from fog reports by the weather station. If at any point in the day fog is reported, it will count as a hit. Multiple observations in a single day will count as a single hit for the risk calculations. Visibility must be reduced to less than 1km for fog to be reported, and this includes instances of freezing fog, mist, and haze if visibility is sufficiently reduced. All Data and Since 2011 options are available in the Standard Model. The Advanced model provides the risk with greater influence from similar years (more information found in the MetSwift Analytics page)</span>'
    infoStorm: string = '<span class="text-sm w-full block">The risk for the selected period from thunderstorm reports by the weather station. if at any point in the day a thunderstorm is reported, it will count as a hit. Multiple observations in a single day will count as a single hit for the risk calculations. A thunderstorm report is given if thunder is heard at the station, whether lightning or precipitation are present or not. All Data and Since 2011 options are available in the Standard Model. The Advanced model provides the risk with greater influence from similar years (more information found in the MetSwift Analytics page) Typically, we advise a 1/10 ratio to account for locality. </span>'
    selectedTypes: any = {};
    selectedDates: boolean = false;
    hasTypeSelected: number = 0;
    numberInsights: number = 0;
    datesInsights: number = 0;
    riskDatesInsights: number = 0;
    selectedWeatherCount: number = 0;
    selectedRiskCount: number = 0;
    locationName: string = '';
    datesName: string = '';
    countryName:string='';
    countryCode:string='';
    state:any;
    postcode:string='';
    lati:any;
    longi:any;
    delphiRec:number=1;
    startDate:any;
    endDate:any;
    username:any;
    place:any;
    weatherData:any;
    weatherArray=[];
    riskArray=[];
    riskclasess;
    stDate:any;
    endDt:any;
    selectedWeatherTypes=[];
    insightCount:number=0;
    weatherArr:any=[];
    weatherExistsStr:any=[];
    riskDataNotFoundMessage:any;
    fetchedLocationData: any;
    dropdownList=[];
    overlayFlag: boolean = false;
    dataErrorMessage:any;
    showWeathers: boolean = false;
    public countryWiseHurricaneName:any;
    hurricaneName:any;
    hurricaneCategories:any;
    riskValues:any;
    riskExposureArray=[];
    eventData:any;
    getCStationAPIErrorCode:any;
    maxInsightAllowed:number;
    public profileData;
    unlimited:number = 1;
    prev_weather = ""
    prev_incomplete = {}
    station_data = {}
    total_days = 0
    incompletew = false
    missingw = false
    missing_w_arr = []
    incomplt_w_arr = []
    weather_labels = {'rain':'Rainfall', 'wind':'Wind', 'temperature':'Temperature', 'snow': 'Snow Depth', 'hail': 'Hail', 'dust': 'Dust', 'fog': 'Fog', 'storm': 'Storm'}
    constructor(
        private userService :UserService,
        private titleService:Title,
        private router: Router,
        private store: Store<AppState>,
        private newenquiryService:NewEnquiryService,
        private loader:LoaderService,
        public ngxSmartModalService: NgxSmartModalService,
        private LS:LogoutService,
        private elementRef:ElementRef
    ) {
        this.fetchProfile()
        this.getState = this.store.select(selectAuthState)
        const navigation = this.router.getCurrentNavigation();
        if(navigation.extras.state){
            this.searchState = navigation.extras.state.searchResults ? navigation.extras.state.searchResults : null;
            this.selectedTypes = navigation.extras.state.selectedTypes ? navigation.extras.state.selectedTypes : {};
            if(this.selectedTypes){
                this.hasTypeSelected = Object.keys(this.selectedTypes).length
            }
        }

        this.titleService.setTitle('New Report - MetSwift');
    }
    ngOnInit(): void{
        this.getState.subscribe((state) => {
            this.username = state.user.username;
            this.unlimited = state.user.unlimited;
        });
    }

    typeSelected(e){
        if(this.selectedTypes[e.type]){
            delete this.selectedTypes[e.type]
            this.numberInsights -= 1
        } else {
            this.selectedTypes[e.type] = true
            this.numberInsights += 1
        }
        this.hasTypeSelected = Object.keys(this.selectedTypes).length;
        this.selectedWeatherTypes = Object.keys(this.selectedTypes);
        this.selectedWeatherCount = this.getTypeSelectedLength('weather');
        this.selectedRiskCount = this.getTypeSelectedLength('risk');
        let weatherInsightCount:number = this.selectedWeatherCount*this.datesInsights;
        let riskInsightCount:number = this.selectedRiskCount*this.riskDatesInsights;
        this.insightCount = weatherInsightCount+riskInsightCount;
    }

    getTypeSelectedLength(type) {
      let typeCount:number=0;
      let selectedTypes = Object.keys(this.selectedTypes);
      let len = selectedTypes.length;
      for ( let i=0;i<len;i++ ) {
        if ( type == 'risk' ) {
          if ( selectedTypes[i] == 'earthquake'  || selectedTypes[i] == 'tornado' || selectedTypes[i] == 'hurricane' || selectedTypes[i] == 'wildfire' ) {
            typeCount++;
          }
        } else {
          if ( selectedTypes[i] == 'rain'  || selectedTypes[i] == 'wind' || selectedTypes[i] == 'temperature' || selectedTypes[i] == 'snow' || selectedTypes[i] == 'hail' || selectedTypes[i] == 'dust' || selectedTypes[i] == 'fog' || selectedTypes[i] == 'storm' ) {
            typeCount++;
          }
        }
      }
      return typeCount;
    }

    calculateInsightsDates(days){
        let noofdays = days+1;
        this.total_days = noofdays
        this.selectedDates = true;
        this.datesInsights = Math.ceil(noofdays/31);
        this.riskDatesInsights = Math.ceil(noofdays/7);
        
        this.weatherArray = [];
        this.riskArray = [];
        this.selectedWeatherTypes = [];
        this.hasTypeSelected = 0;
        this.riskExposureArray=[];
        this.selectedTypes = {}
        this.insightCount = 0;
        this.numberInsights = 0;
        this.prev_incomplete = {}
        this.prev_weather = ''
        this.station_data = {}
        this.incompletew = false
        this.missingw = false
        this.missing_w_arr = []
        this.incomplt_w_arr = []
    }

    changeSearchStatus(data){
        this.searchState = data
        if(data.location && data.place){
            this.locationName = data.place

        }
        if(data.datestart && data.dateend){
            this.datesName = '<span class="font-medium text-sm">' + data.datestart + '</span> to <span class="font-medium text-sm">' + data.dateend + '</span>'
        }

        if ( data.location )
            this.extractLocationData(data);

    }

    getCountryNameByLatLong(lati, longi) {
        let x = this;
        let geocoder = new google.maps.Geocoder;
        let latlng = { lat: lati, lng: longi };
        geocoder.geocode({ 'location': latlng }, function (results, status) {
          if (status === 'OK') {
            if (results.length > 0) {
              for (let r = 0; r < results.length; r++) {
                for (let i = 0; i < results[r].address_components.length; i++) {
                  let postCodeInd = results[r].address_components[i].types.indexOf('postal_code');
                  let countryInd = results[r].address_components[i].types.indexOf('country');
                  let stateInd = results[r].address_components[i].types.indexOf('administrative_area_level_1');

                  if (postCodeInd > -1) {
                    x.postcode = results[r].address_components[i].long_name;
                    x.place = results[0];
                  } else {
                    x.postcode = 'xxxxxx';
                    x.place = results[0];
                  }

                  if (countryInd > -1) {
                    x.countryCode = results[r].address_components[i].short_name;
                    x.countryName = results[r].address_components[i].long_name;
                  }
                  if (stateInd > -1) {
                    x.state = results[r].address_components[i].long_name;
                  }
                  x.place = x.place.formatted_address;
                }
              }
            }
          }
        });
      }

    extractLocationData(data) {
        this.startDate = data.datestart;
        this.stDate  = moment(data.datestart, 'DD/MM/YYYY').format('MMM DD')
        this.endDt   = moment(data.dateend, 'DD/MM/YYYY').format('MMM DD')
        this.endDate = data.dateend;
        if ( typeof data.location.geometry.location.lat === 'function' ) {
          this.lati    = data.location.geometry.location.lat();
          this.longi   = data.location.geometry.location.lng();
        } else {
          this.lati    = data.location.geometry.location.lat;
          this.longi   = data.location.geometry.location.lng;
        }
        this.place   = data.place;
        let location = data.location.address_components;

        let len = location.length;
        for ( let i=0;i<len;i++ ) {
            if ( location[i].types[0] == 'postal_code' ) {
                this.postcode = location[i].long_name;
            }
            if ( location[i].types[0] == 'country' ) {
                this.countryName = location[i].long_name;
                this.countryCode = location[i].short_name;
            }
            if ( location[i].types[0] == 'administrative_area_level_1') {
                this.state = location[i].long_name;
            }
        }
        if ( !this.postcode ) {
            this.getCountryNameByLatLong(this.lati, this.longi);
        }

        this.getRiskJSON().then(d=> {

          this.getHurricaneName();

          if ( this.startDate != undefined  && this.endDate != undefined && this.locationName != undefined ) {
              this.loader.display(true);
              let requestData = JSON.stringify({
                  country: this.countryCode,
                  postCode: this.postcode,
                  username: this.username,
                  state: this.state,
                  countryName: this.countryName,
                  uri: '/stats_data/get_c_station',
                  lat: this.lati,
                  lon: this.longi,
                  delphiRec: this.delphiRec,
                  Start_Date: this.startDate,
                  End_Date: this.endDate,
                  categories: this.hurricaneCategories,
                  weathers: ""
              });
              this.infoHurricane = '';
              this.infoTornadoes ='';
              this.infoEarthquake ='';
              this.infoWildfire ='';
              this.newenquiryService.getCStation(requestData).subscribe((data)=> {
                this.LS.logOut({ "title": "New enquiry - Location - Search for " + this.place + " (" + this.lati + "," + this.longi + ") for duration from " + this.startDate + " to " + this.endDate, "link": "#/new-report" })
                  .subscribe(
                    data => {
                    },
                    error => {
                      this.router.navigate(['/login']);
                    }
                  );
                  this.loader.display(false);
                  let successData = data;
                  this.riskValues = data.riskValue;
                  this.riskExposureArray = this.getRiskArray(data.Risk_exposure);
                  this.riskArray = this.getRiskArray(data.Risk);
                  // Change the risk message if there was exposure in past
                  if ( !this.riskArray.includes('earthquake') && this.riskExposureArray.includes('earthquake') ) {
                    this.infoEarthquake = "No risk for Earthquake detected in your search window, however, this location is at risk of Earthquake during the year.";
                  } else{
                    this.infoEarthquake = '<span class="text-sm w-full block">Aggregated on an annual binary basis, and reported as an annual occurrence. Hyper local data, reaching back to 1900, means our statistics achieve highly specific local relevance. We also grade the severity of the natural catastrophe, based on this detailed data. Attain probability percentages of risk in response to your parameters. Moment magnitude severity M4 – M9 classification.</span>';

                  }

                  if ( !this.riskArray.includes('tornadoes') && this.riskExposureArray.includes('tornadoes') ) {
                    this.infoTornadoes = "No risk for Tornado detected in your search window, however, this location is at risk of Tornado during the year.";
                  } else{
                    this.infoTornadoes = '<span class="text-sm w-full block">Aggregated on a weekly basis, and reported as a weekly occurrence. Hyper local data, reaching back to 1950, means your results achieve highly specific local relevance. Attain risk probability percentages in response to your parameters. We also grade the severity of the natural catastrophe, based on this detailed data. Only available for locations in the United States of America</span>';

                  }
                  let wilfd = "";

                  
                  if ( !this.riskArray.includes('hurricanes') && this.riskExposureArray.includes('hurricanes') ) {
                    this.infoHurricane = "No risk for "+this.hurricaneName+" detected in your search window, however, this location is at risk of "+this.hurricaneName+" during the year.";
                  } else {
                    this.infoHurricane = '<span class="text-sm w-full block">Aggregated on a weekly basis, and reported as a weekly occurrence. Hyper local data, reaching back to 1850, means your results achieve highly specific local relevance. Attain risk probability percentages in response to your parameters. We also grade the severity of the natural catastrophe, based on this detailed data. Locally accepted classifications.</span>';
                  }
                  if ( !this.riskArray.includes('wildfire') && this.riskExposureArray.includes('wildfire') ) {
                    
                    this.infoWildfire = "No risk for Wildfire detected in your search window, however, this location is at risk of Wildfire during the year.";
                  
                  } else{

                    this.infoWildfire = '<span class="text-sm w-full block">Hyperlocal wildfire occurrence risk model using historical wildfire event data since 1950, utilizing both atmospheric data (inc. dry days, high daytime temperatures, mean wind and humidity) and high resolution land type data with applied latitudinal, seasonal and slope factor adjustments. Two outputs will be available, the ‘All data’ (1950-2019) and ‘Since 2000’ (2000-2019) options providing a localised, weekly, wildfire development risk score. </span>';
                  
                  }
                  // Change the risk message if there was exposure in past
                  this.makeWeatherArray(data.keys);
                  this.riskclasess = data.riskCategories;
  
                    this.fetchedLocationData = successData;
                    this.dropdownList = this.fetchedLocationData.keys;
                    let len = this.dropdownList.length;
                    let weatherExistsArr:any=[];
                    for ( let we=0;we < len;we++ ) {
                      if ( this.dropdownList[we].itemName != 'snow' ) {
                        weatherExistsArr.push(this.dropdownList[we].itemName);
                      }
                    }
                    let weaLen = weatherExistsArr.length;
                    for ( let wea=0;wea<weaLen;wea++ ) {
                      if ( wea == 0 ) {
                        this.weatherExistsStr = '<strong>' +this.capitalizeWords(weatherExistsArr[wea])+'</strong>';
                      } else if ( wea == weaLen-1 ) {
                        this.weatherExistsStr += ' and <strong>'+this.capitalizeWords(weatherExistsArr[wea])+'</strong>';
                      } else {
                        this.weatherExistsStr += ', <strong>'+this.capitalizeWords(weatherExistsArr[wea])+'</strong>';
                      }
                    }
              },

              (error) => {
                this.LS.logOut({ "title": "New enquiry - Location - Error for " + this.place + " (" + this.lati + "," + this.longi + ") for duration from " + this.startDate + " to " + this.endDate, "link": "#/new-report" })
                .subscribe(
                  data => {
                  },
                  error => {
                    this.router.navigate(['/login']);
                  }
                );
                  error = JSON.parse(JSON.stringify(error));
                  this.loader.display(false);
                  this.overlayFlag = true;
                  this.getCStationAPIErrorCode = error.status;
                  console.log(error)
                  this.showWeathers = false;
                    let errordata = error;
                    let errObj: any = {};
                    errObj.code = 'No Data',
                      errObj.eventId = -1,
                      errObj.url = "stats_data/get_c_station",
                      errObj.frontUrl = "/insight",
                      errObj.apiBody = { "place": this.locationName, "lat": this.lati, "lon": this.longi, "postCode": this.postcode , "country": this.countryCode,
                      "state": this.state,
                       "countryName": this.countryName,
                        "startDate": this.startDate,
                         "endDate": this.endDate,
                          "Start_Date": "2022-2-16", "End_Date": "2022-2-16", "uri": "/stats_data/get_c_station" };
                    if (error.status == 400 ||error.status == 404 || error.status == 500) {
                      this.callErrorMail(errObj);
                    }
              }


              );
          }
        });

    }

    removeSelection(){
        this.weatherArray = [];
        this.riskArray = [];
        this.startDate = '';
        this.endDate = '';
        this.datesName ='';
        this.datesInsights =0;
        this.selectedWeatherTypes = [];
        this.hasTypeSelected =0;
        this.selectedTypes = {}
        this.insightCount = 0;
        this.numberInsights = 0;
        this.stDate = '';
        this.endDt = '';
        this.riskExposureArray = [];
        this.prev_weather = ''
        this.prev_incomplete = {}
        this.station_data = {}
        this.missingw = false
        this.incompletew = false
        this.missing_w_arr = []
        this.incomplt_w_arr = []
    }

    onClickBuyInsights(){
        this.ngxSmartModalService.getModal('reportLimit').close();
        this.loader.display(true);
        let selectedWeatherTypes = Object.keys(this.selectedTypes).toString();
        let checkGapBody = JSON.stringify({
          lat: this.lati,
          lon: this.longi,
          postCode: this.postcode,
          country: this.countryCode,
          username: this.username,
          state: this.state,
          countryName: this.countryName,
          startDate: moment(this.startDate, 'DD/MM/YYYY').format('DD/MM/YYYY'),
          endDate: moment(this.endDate, 'DD/MM/YYYY').format('DD/MM/YYYY'),
          delphiRec: 1,
          fsi: 0,
          earthquakes: ( this.riskArray.includes('earthquake') ) ? 1 : 0,
          hurricanes: ( this.riskArray.includes('hurricanes') ) ? 1 : 0,
          tornadoes: ( this.riskArray.includes('tornadoes') ) ? 1 : 0,
          volcanoes: ( this.riskArray.includes('volcanoes') ) ? 1 : 0,
          wildfire: ( this.riskArray.includes('wildfire') ) ? 1 : 0,
          eventName: '',
          locationName: this.locationName,
          eventId: -1,
          groupId: -1,
          groupName: null,
          selectedTypes: selectedWeatherTypes,
          totalInsights: this.insightCount,
          weathers: selectedWeatherTypes,
          prev_weathers: this.prev_weather,
          prev_incomplete: this.prev_incomplete,
          station_data: this.station_data
        });
        
        this.newenquiryService.checkgapdata(checkGapBody).subscribe((data)=> {
          this.prev_incomplete = data.prev_incomplete
          this.prev_weather = data.prev_weathers.join(',')
          this.station_data = data.station_data
          this.prepareTheWeatherDataForTheEvent(data.station_data);
          for(let val in data.incomplete_weathers){
            if(data.incomplete_weathers[val] == 0){
              const index = this.weatherArray.indexOf(val);
              if (index > -1) {
                this.gapsdatacheck(val, data.incomplete_weathers[val], this.total_days)
                this.weatherArray.splice(index, 1);
              }
              this.missingw = true
              this.missing_w_arr.push(this.weather_labels[val])
            } else if(data.incomplete_weathers[val] < this.total_days){
              this.gapsdatacheck(val, data.incomplete_weathers[val], this.total_days)
              this.incompletew = true
              this.incomplt_w_arr.push(this.weather_labels[val])
            } else{
              this.gapsdatacheck(val, data.incomplete_weathers[val], this.total_days,true)
            }
          }
          if (this.missing_w_arr.length >= 8) {
            let errObj: any = {};
            (errObj.code = 'No Data'),
              (errObj.eventId = -1),
              (errObj.url = 'stats_data/get_c_station'),
              (errObj.frontUrl = '/insight'),
              (errObj.apiBody = {
                place: this.locationName,
                lat: this.lati,
                lon: this.longi,
                postCode: this.postcode,
                country: this.countryCode,
                state: this.state,
                countryName: this.countryName,
                startDate: this.startDate,
                endDate: this.endDate,
                Start_Date: '2022-2-16',
                End_Date: '2022-2-16',
                uri: '/stats_data/get_c_station',
              });
            this.callErrorMail(errObj);
          }
          let addEventBody = JSON.stringify({
            lat: this.lati,
            lon: this.longi,
            postCode: this.postcode,
            country: this.countryCode,
            username: this.username,
            state: this.state,
            countryName: this.countryName,
            startDate: moment(this.startDate, 'DD/MM/YYYY').format('YYYY/MM/DD'),
            endDate: moment(this.endDate, 'DD/MM/YYYY').format('YYYY/MM/DD'),
            delphiRec: 1,
            fsi: 0,
            earthquakes: ( this.riskArray.includes('earthquake') ) ? 1 : 0,
            hurricanes: ( this.riskArray.includes('hurricanes') ) ? 1 : 0,
            tornadoes: ( this.riskArray.includes('tornadoes') ) ? 1 : 0,
            volcanoes: ( this.riskArray.includes('volcanoes') ) ? 1 : 0,
            wildfire: ( this.riskArray.includes('wildfire') ) ? 1 : 0,
            eventName: '',
            locationName: this.locationName,
            eventId: -1,
            groupId: -1,
            groupName: null,
            weathers: this.weatherData,
            selectedTypes: selectedWeatherTypes,
            totalInsights: this.insightCount,
            missingweathers: this.prev_incomplete
        });
        if(data.event_save == 1){
          this.newenquiryService.addNewEvent(addEventBody).subscribe((data)=> {
          this.LS.logOut({ "title": "New report - Created Report for " + this.locationName + " (" + this.lati + "," + this.longi + ") for duration from " + this.startDate + " to " + this.endDate, "link": "#/new-report" })
            .subscribe(
              data => {
              },
              error => {
                this.router.navigate(['/login']);
              }
            );
            this.eventData = data;
            let getModelDetailsData = JSON.stringify({
                username: this.username,
                eventId: this.eventData.eventId,
                startDate: moment(this.startDate, 'DD/MM/YYYY').format('DD-MM-YYYY'),
                endDate: moment(this.endDate, 'DD/MM/YYYY').format('DD-MM-YYYY'),
                hurriCategory: '',
                weathers: this.weatherData,
                selectedWeatherTypes: selectedWeatherTypes,
                numberInsights: this.insightCount,
                hurricanename:'hurricanes'
            });
            this.newenquiryService.getModels(getModelDetailsData).subscribe((modelsData)=> {
                this.router.navigate(['/report/'+this.eventData.eventId], { state: {
                    selectedTypes: this.selectedTypes,
                    //searchState: this.searchState,
                    numberInsights: this.insightCount,
                    eventId: this.eventData.eventId
                } });
            },
            (error) => {
                this.loader.display(false);
            });
          });
        } else {
          this.loader.display(false);

        }
        
      },
      error =>{
        this.loader.display(false);
      });
    }

    gapsdatacheck(type, avaidays, totaldays, edit=false){
      let rain = <HTMLElement>this.elementRef.nativeElement.querySelector(`.button-type-weather--${type}`);
      let htmldata = document.createElement('div')

      htmldata.classList.add("text", "flex", "items-center", "justify-center", "gap-font")
      htmldata.textContent = avaidays +' of '+ totaldays +' days available'

      htmldata.style.display = "flex"
      htmldata.style.justifyContent = "centre"
      htmldata.style.color = "#ffffff" 
      htmldata.style.alignItems = "center"
      //htmldata.style.fontSize = "0.8rem"
      if(!edit){
        rain.click();
      }
      
      rain.firstElementChild.firstElementChild.appendChild(htmldata)
      let divlist = this.elementRef.nativeElement.querySelectorAll(`app-button-type .button-type-weather`)
      for (const box of divlist) {
        box.classList.add('gapdata');
      }
    }

    makeWeatherArray(weatherKeys) {
        let len = weatherKeys.length;
        if ( len > 0 && this.weatherArray.length < 1 ) {
            for ( let i=0;i<len;i++) {
                this.weatherArray.push(weatherKeys[i].itemName);
            }
        }
    }

    getRiskArray(riskObject) {
        let availblRiskArr = [];
        Object.keys(riskObject).forEach(function(key){
            let value = riskObject[key];
            if ( value == 1 ) {
                availblRiskArr.push(key);
            }
        });
        return availblRiskArr;
    }

    prepareTheWeatherDataForTheEvent(weather) {
              let insightWeatherStations = [];
              let i=0
              for (let x in weather) {
                  insightWeatherStations[i] = {};
                  insightWeatherStations[i]["itemName"] = x;
                  insightWeatherStations[i]["stationId"] = weather[x]["best"]["stationId"];
                  insightWeatherStations[i]["stationLat"] = weather[x]["best"]["stationData"]["Lat"];
                  insightWeatherStations[i]["stationLon"] = weather[x]["best"]["stationData"]["Lon"];
                  insightWeatherStations[i]["stationName"] = weather[x]["best"]["stationData"]["StationName"];
                  insightWeatherStations[i]["isRecommended"] = 1;
                  i++
              }
              this.weatherData = insightWeatherStations;
    }

    locationChanged(e) {
        this.selectedTypes = {}
        this.insightCount = 0;
        this.locationName = '';
        this.datesName = '';
        this.datesInsights = 0;
        this.numberInsights = 0;
        this.weatherArray = [];
        this.riskArray = [];
        this.startDate = '';
        this.endDate = '';
        this.stDate = '';
        this.endDt = '';
        this.selectedWeatherTypes = [];
        this.hasTypeSelected = 0;
        this.riskExposureArray=[];
        this.prev_incomplete = {}
        this.prev_weather = ''
        this.station_data = {}
        this.incompletew = false
        this.missingw = false
        this.missing_w_arr = []
        this.incomplt_w_arr = []
    }

    capitalizeWords(str) {
        return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    }

    closeOverlay() {
        this.riskDataNotFoundMessage = false;
        this.overlayFlag = false;
        this.weatherExistsStr = [];
        this.weatherArr = [];
        this.dataErrorMessage = '';
    }

    async getRiskJSON() {
        let res = await this.newenquiryService.getJSON();
        let strData = JSON.stringify(res);
        this.countryWiseHurricaneName = JSON.parse(strData);
    }

    getHurricaneName() {
        let len = this.countryWiseHurricaneName.length;
        this.hurricaneName = 'Hurricanes';
        for ( let i=0;i<len;i++ ) {
          if ( this.countryWiseHurricaneName[i].code == this.countryCode ) {
            this.hurricaneName = this.countryWiseHurricaneName[i].title;
            this.hurricaneCategories = this.countryWiseHurricaneName[i].category.split(',');
          }
        }
    }

    checkIfInsightCountIsUnderLimit() {
      this.maxInsightAllowed = Number(this.profileData.insightThreshold);
      if ( this.insightCount > this.maxInsightAllowed && this.profileData.insightThreshold) {
        if(this.unlimited != 1){
          this.ngxSmartModalService.getModal('reportLimit').open();
        } else{
          this.onClickBuyInsights();
        }
        
      } else {
        this.onClickBuyInsights();
      }
    }

    closePopup() {
      this.ngxSmartModalService.getModal('reportLimit').close();
      return;
    }

    callErrorMail(errObj) {
      this.newenquiryService.mailOnErr(errObj)
        .subscribe(
          data => {
          },
          error => {
            let errordata = error;
          });
    }

    fetchProfile() {
      this.loader.display(true);
      this.userService.fetchuserProfile({})
        .subscribe(
          data => {
            this.loader.display(false);
            let successData = data;
            this.profileData = successData.profile;
          },
          error => {
            let errordata = error;
          });

  }


}
