import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENV } from './../app.config';

@Injectable()
export class PortfolioService {
    constructor(
        private http: HttpClient
      ) { }

  getFilters(data: Object): Observable<any> {
    return this.http.post(`${ENV.BASE_API}/api/insight/getrecommfilters`, data);
  }

  statusBid(data: Object): Observable<any> {
    return this.http.post(`${ENV.BASE_API}/api/insight/eventstatus`, data);
  }

  brainBid(data: Object): Observable<any> {
    return this.http.post(`${ENV.BASE_API}/api/insight/bid`, data);
  }

  bulkEventUpload(data:Object): Observable<any> {
    return this.http.post(`${ENV.WEATHER_API}/insight/bulk_event_upload`, data);
  }

  bulkEventCreate(data:Object): Observable<any> {
    return this.http.post(`${ENV.WEATHER_API}/insight/bulk_event_create`, data);
  }

  getuserbulkdata(data:Object): Observable<any> {
    return this.http.post(`${ENV.WEATHER_API}/insight/getuserbulkdata`, data);
  }

  downloads3file(data:Object): Observable<any> {
    return this.http.post(`${ENV.WEATHER_API}/insight/downloads3file`, data);
  }
  

  mapRisk(data: Object): Observable<any> {
    Object.defineProperty(data, "uri", {
        value: '/stats_data/get_map_risk_data',
        writable: true,
        enumerable: true,
        configurable: true
    });
    return this.http.post(`${ENV.WEATHER_API}/stats_data/get_map_risk_data`, data);
  }

}