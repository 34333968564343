import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';
import { UserService } from 'src/app/services/user.service';
import { LogoutService } from '../../services/logout.service'
import { AcceptTerm, LogInSuccess, LogOut } from '../../store/actions/auth.actions';
import { AppState, selectAuthState } from '../../store/app.states';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  titleField : string = 'Terms and conditions';
  getState: Observable<any>;
  dephiLogo = 'assets/images/metswift-logo-new.png';
  userdata:any;
  public termConditionOvelay: boolean = false;
  
  constructor(private LS: LogoutService,
    private store: Store<AppState>,
    private _router: Router, private loaderService: LoaderService, private userService: UserService,public ngxSmartModalService: NgxSmartModalService ) {
      this.getState = this.store.select(selectAuthState);
      this.getState.subscribe((state) => {
        this.userdata = state.user;
      })
     }

  ngOnInit(): void {
    this.LS.logOut({"title":"Terms and Conditions","link":"#/termcondition"})
        .subscribe(
          data => {
            let successData = data;
            if (successData.termsAndCond != null) {
              //this._router.navigate(['/dashboard']);
            }
          },
          error => {
          }
        );
  }

  acceptTerm() {
    this.loaderService.display(true);
    this.getState.subscribe((state) => {
      let updatedUser = Object.assign({}, state.user);
      updatedUser.termsAndCond = 1;   
      this.store.dispatch(new AcceptTerm(updatedUser));
    })
    

    // this.userService.termsAndCondition(body)
    // //.pipe(takeUntil(this.destroy$))
    //   .subscribe(
    //     (data) => {
    //       this.getState.subscribe((state) => {
    //         let updatedUser = Object.assign({}, state.user);
    //         updatedUser.termsAndCond = 1;   
    //         this.store.dispatch(new AcceptTerm({user:updatedUser,termsAndCond:true}));
    //         this._router.navigateByUrl('/dashboard');
    //       })
    //     },
    //     (error) => {
    //       this.loaderService.display(false);
    //     }
    //     )
  }
  signOut(event) {
    //event.preventDefault()
    this.store.dispatch(new LogOut());
    this._router.navigateByUrl('/log-in');
  }
  declineTerm() {
    //this.termConditionOvelay = true;
    this.ngxSmartModalService.getModal('termDecline').open();
    this.getPopUpVal(true);
  }
  closeTermOverlay() {
    this.ngxSmartModalService.getModal('termDecline').close();
    this.getPopUpVal(false);
  }

  getPopUpVal(val) {
    // if (val) {
    //   document.body.style.position = 'fixed';
    //   document.body.style.width = '100%';
    // } else {
    //   document.body.style.position = 'relative';
    // }
  }
  termNotAccept() {
    this.loaderService.display(true);
    let body = JSON.stringify({
      terms_condition: false
    });
    this.LS.logOut({ "title": "Log Out", "link": "#/home" })
            .subscribe(
              data => {
                this.userService.termsAndCondition(body)
                .subscribe(
                  (data) => {
                    this.loaderService.display(false);
                    this.signOut(data)
                  },
                  (error) => {
                    this.loaderService.display(false);
                  }
                );
              },
              error => {
              }
            );
  }
  

}
