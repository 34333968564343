
<div (click)="ngxSmartModalService.getModal('whatInsight').open()" class="btn-what-is-insight lg:flex flex-wrap justify-between cursor-pointer">
    <div class="lg:ml-4 text-center lg:text-left text-sm lg:flex flex-wrap items-center">
        <span class="font-bold">What is an insight?</span>
        <span class="ml-3 bg-gray-200 text-primary rounded-full w-5 h-5 inline-flex lg:flex items-center justify-center">
            <svg class="relative fill-current" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 16 16"><path d="M9,7c0-0.6-0.4-1-1-1H5v2h2v6H5v2h6v-2H9V7z"></path> <circle cx="7.5" cy="2.5" r="1.5"></circle></svg>
        </span>
    </div>
    <!-- <div class="ml-auto mt-2 lg:mt-0 btn-warning">
        View info
    </div> -->


</div>
<ngx-smart-modal [closable]="true" #whatInsight identifier="whatInsight">
<div class="container max-w-3xl mx-auto my-12">
  <h3 class="mb-2 lg:mb-5 text-white font-medium text-xl lg:text-2xl text-center">What is an insight?</h3>
  <div class="bg-white rounded lg:rounded-lg p-4 lg:p-8">
    <div class="page-content">
        <!-- <h2>Title here</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam egestas massa sed mauris feugiat, eget venenatis urna dapibus. Integer porttitor nisi tempor nunc lobortis ultricies. Morbi vehicula, nisi in tincidunt mollis, dui felis ullamcorper tellus, vel accumsan magna nibh vulputate ligula. Donec pulvinar justo a enim aliquam, eu molestie ligula vestibulum. Sed molestie placerat mauris, ac dignissim ligula iaculis id. Praesent vitae nulla magna. Pellentesque nisi lacus, rhoncus rutrum purus vitae, congue maximus risus.</p>
        <h3>Title here too</h3> -->
        <p>An insight is how we provide information as units of data for a specific type, duration, location and option. We have 2 types of insights based upon the model type - 31 days for our 'Weather' selection and 7 days for our 'NatCat' selection. Depending upon the time of year and location - you may be presented with 8 Weather risks and 4 NatCat risk options - currently. We will be adding new insights in the future.</p>
    </div>
  </div>
</div>
</ngx-smart-modal>
