import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
    @Input() class: string;
    @Input() click: any;
    // text: string;
    // constructor() { }

    ngOnInit(): void {

    }

}
