import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { AppState, selectAuthState } from 'src/app/store/app.states';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-metswift-analytics',
  templateUrl: './metswift-analytics.component.html',
  styleUrls: ['./metswift-analytics.component.scss']
})
export class MetswiftAnalyticsComponent {
    titleField : string = 'MetSwift analytics';
    getState: Observable<any>;
    unlimited:number = 1;
    constructor(private titleService:Title, private store: Store<AppState>,) {
        this.getState = this.store.select(selectAuthState);
        this.getState.subscribe((state) => {
          this.unlimited = state.user.unlimited;
        });
        this.titleService.setTitle('Metswift analytics - MetSwift');
    }


}
